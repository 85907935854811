import React, { Component } from "react";
import moment from "moment";
import {
  decodeComputedString,
  escapeJSONNewLine,
  getApplicableItems,
  getConvertedFeatureArea,
  getExplicitValidationRulesForField,
  getFormattedDefaultValue,
  isNullOrUndefined,
  isValid,
  isValidJSON,
  toLocale
} from "../../App/utils";
import {
  FeatureDetailsContainer,
  FeatureDetailsForm,
  FeatureDetailsWrapper
} from "../../AgBoxUIKit/plugin/layout";
import { Loader } from "../../AgBoxUIKit/core";
import { FeatureDetailsComponent } from "../../AgBoxUIKit/plugin";
import Attachments from "../Attachments/Attachments";
import { FEATURE_AREA_ATTRIBUTE, TOTAL_AREA_ATTRIBUTE } from "../../constants";

const ConditionalFormWrap = ({ condition, children }) =>
  condition ? (
    <FeatureDetailsForm id="details-form">{children}</FeatureDetailsForm>
  ) : (
    <FeatureDetailsContainer>{children}</FeatureDetailsContainer>
  );

const disabledAttributes = [
  "objectId",
  "OBJECTID",
  "orgId",
  "propId",
  "customData",
  "setId"
];

export default class FeatureDetails extends Component {
  constructor(props) {
    super(props);
    this.parentContainer = React.createRef();
  }
  componentDidMount() {
    this.focusFirstFormField();
    this.setFeatureFilter();
  }
  componentDidUpdate(prevProps) {
    const { editable, loading } = prevProps;
    if (!editable && this.props.editable) {
      this.handleUpdateInvalidCustomData();
      this.focusFirstFormField();
    }
    if (loading && !this.props.loading) {
      this.focusFirstFormField();
    }
    if (!prevProps.feature && this.props.feature !== null) {
      this.setFeatureFilter();
    }
  }
  setFeatureFilter = async () => {
    const { feature, mapView, objectIdField } = this.props;
    if (!feature) return false;
    const { layer } = feature;
    if (!layer || layer.type === "graphics") return false;
    const layerView = await mapView.whenLayerView(layer);
    if (!layerView) return false;
    layerView.filter = {
      objectIds: feature.attributes[objectIdField]
        ? [feature.attributes[objectIdField]]
        : []
    };
    layerView.featureEffect = null;
  };

  focusFirstFormField = () => {
    if (!this.props.editable || this.props.loading) return false;
    const parentElement = this.parentContainer.current;
    const firstEditableInput = parentElement.querySelectorAll(
      "input, select, button"
    );
    if (!firstEditableInput || firstEditableInput.length === 0) return false;
    firstEditableInput[0].focus();
  };

  validationRules = (attr) => {
    if (!attr) return false;
    const { validationRules, feature, geometryType } = this.props;
    if (!validationRules || !validationRules[attr]) return {};
    return getExplicitValidationRulesForField(
      feature,
      validationRules[attr],
      geometryType
    );
  };

  handleBlankCustomData = () => {
    const { feature, customAttributes, onChange } = this.props;
    let newFeature = feature.clone ? feature.clone() : { ...feature };
    if (!customAttributes) return feature;
    const customData = customAttributes
      ? Object.keys(customAttributes).reduce((result, attribute) => {
          return {
            ...result,
            [attribute]: {
              ...customAttributes[attribute],
              value: null
            }
          };
        }, {})
      : {};

    newFeature.attributes.customData = JSON.stringify(customData);
    newFeature = this.updateDefaultValues(newFeature);
    if (onChange) onChange(newFeature);
    return newFeature;
  };

  handleUpdateInvalidCustomData = () => {
    const { feature, customAttributes } = this.props;
    if (!feature) return;
    const { attributes } = feature;
    const { customData } = attributes;
    if (!customData) return;
    const parsedCustomData = isValidJSON(customData)
      ? JSON.parse(escapeJSONNewLine(customData))
      : false;
    if (!parsedCustomData) return;
    const customDataToUpdate = Object.keys(parsedCustomData).filter(
      (key) =>
        !Object.prototype.hasOwnProperty.call(parsedCustomData[key], "value") ||
        parsedCustomData[key].value === "undefined" ||
        parsedCustomData[key].value === undefined
    );
    if (customDataToUpdate.length === 0) return;
    customDataToUpdate.forEach((key) => {
      parsedCustomData[key] = {
        ...customAttributes[key],
        value: null
      };
    });

    let newFeature = feature.clone ? feature.clone() : { ...feature };
    newFeature.attributes.customData = JSON.stringify(parsedCustomData);
    newFeature = this.updateDefaultValues(newFeature);
    const { onChange } = this.props;
    if (onChange) onChange(newFeature);
  };

  getDisplayOrder = () => {
    const { displayOrder } = this.props;
    return displayOrder ? displayOrder : [];
  };
  getDateFormat = () => {
    const { dateFormat } = this.props;
    return dateFormat ? dateFormat : "DD/MM/YYYY";
  };

  getOpenToDate = (attribute) => {
    const { openToDate } = this.props;
    if (!openToDate || !attribute || !openToDate[attribute]) return null;
    const formattedDate = moment(openToDate[attribute]).format(
      this.getDateFormat()
    );
    const date = moment(formattedDate, this.getDateFormat()).toDate();
    return moment(date).isValid() ? date : null;
  };

  formatItemTitle = (attribute) => {
    const {
      displayTitles,
      labels: { FEATURE_AREA_LABEL }
    } = this.props;
    if (
      displayTitles &&
      Object.prototype.hasOwnProperty.call(displayTitles, attribute)
    ) {
      return displayTitles[attribute];
    } else if (attribute === FEATURE_AREA_ATTRIBUTE) return FEATURE_AREA_LABEL;
    return attribute;
  };

  generateHelpText = (attribute) => {
    const rules = this.validationRules(attribute);
    if (!rules) return null;
    const { min, max, minLength, maxLength } = rules;
    if (
      isNullOrUndefined(min) &&
      isNullOrUndefined(max) &&
      isNullOrUndefined(minLength) &&
      isNullOrUndefined(maxLength)
    )
      return null;

    if (!isNullOrUndefined(min) || !isNullOrUndefined(max)) {
      return !isNullOrUndefined(min) && isNullOrUndefined(max)
        ? this.getLanguageLabel("MIN_VALUE_HELPTEXT", { min })
        : isNullOrUndefined(min) && !isNullOrUndefined(max)
        ? this.getLanguageLabel("MAX_VALUE_HELPTEXT", { max })
        : this.getLanguageLabel("MIN_AND_MAX_VALUE_HELPTEXT", { min, max });
    } else if (!isNullOrUndefined(minLength) || !isNullOrUndefined(maxLength)) {
      return !isNullOrUndefined(minLength) && isNullOrUndefined(maxLength)
        ? this.getLanguageLabel("MINLENGTH_VALUE_HELPTEXT", { minLength })
        : isNullOrUndefined(minLength) && !isNullOrUndefined(maxLength)
        ? this.getLanguageLabel("MAXLENGTH_VALUE_HELPTEXT", { maxLength })
        : this.getLanguageLabel("MINLENGTH_AND_MAXLENGTH_VALUE_HELPTEXT", {
            minLength,
            maxLength
          });
    } else {
      return null;
    }
  };

  getHelpText = (attribute) => {
    const { helpTexts } = this.props;
    if (!helpTexts || !attribute || !helpTexts[attribute])
      return this.generateHelpText(attribute);
    return helpTexts[attribute];
  };

  whenEditing = () => {
    const { editable } = this.props;
    return editable;
  };

  showGenericAttachmentArea = () => {
    if (!this.props.attachmentInfos) return false;
    const { showGeneric } = this.props.attachmentInfos;
    return !isNullOrUndefined(showGeneric) ? showGeneric : true;
  };

  customStatistics = () => {
    const { customStatistics } = this.props;
    return customStatistics;
  };

  calculateStatistics = () => {
    const customStats = this.customStatistics();
    if (!customStats) return;
    const { feature } = this.props;
    const { attributes } = feature;
    const customData =
      attributes.customData && isValidJSON(attributes.customData)
        ? JSON.parse(escapeJSONNewLine(attributes.customData))
        : {};
    Object.keys(customStats).forEach((key) => {
      const { fields, statisticType } = customStats[key];
      let stat;
      const fieldValues = fields
        .map((field) => {
          //can't use object prototype method on esri objects
          if (attributes.hasOwnProperty(field))
            return parseFloat(attributes[field]);
          else if (Object.prototype.hasOwnProperty.call(customData, field))
            return customData[field].type === "float"
              ? parseFloat(customData[field].value)
              : customData[field].type === "integer"
              ? parseInt(customData[field].value)
              : customData[field].value;
          else return false;
        })
        .filter((field) => field !== false);
      switch (statisticType) {
        case "avg":
          stat = this.calculateAverage(fieldValues);
          break;
        case "sum":
          stat = this.calculateSum(fieldValues);
          break;
        default:
          stat = this.calculateAverage(fieldValues);
      }
      feature.attributes[key] = stat;
    });
  };

  calculateSum = (values) => {
    return values.reduce((a, b) => a + b, 0);
  };

  calculateAverage = (values) => {
    return values.reduce((a, b) => a + b, 0) / values.length;
  };

  hiddenAttributes = () => {
    const { hiddenAttributes, showFeatureArea, feature } = this.props;
    const allHiddenAttributes = hiddenAttributes
      ? [...hiddenAttributes, ...disabledAttributes]
      : disabledAttributes;
    if (
      !showFeatureArea ||
      !feature ||
      !feature.geometry ||
      feature.geometry.type !== "polygon"
    )
      return [...allHiddenAttributes, FEATURE_AREA_ATTRIBUTE];
    return allHiddenAttributes;
  };

  whenLoading = () => {
    const { loading } = this.props;
    return loading === true;
  };

  handleResetCustomContextualAttributes = (feature) => {
    //if there are any attributes that are contextual, and should now be hidden, reset them to null
    let featureCopy = feature.clone ? feature.clone() : { ...feature };
    const { contextualAttributes, geometryType } = this.props;
    if (!contextualAttributes) return featureCopy;
    const customData =
      featureCopy.attributes.customData &&
      isValidJSON(featureCopy.attributes.customData)
        ? JSON.parse(escapeJSONNewLine(featureCopy.attributes.customData))
        : {};

    Object.keys(contextualAttributes).forEach((attr) => {
      const item = contextualAttributes[attr];
      if (!item || getApplicableItems(featureCopy, item, geometryType)) return;
      if (Object.prototype.hasOwnProperty.call(featureCopy.attributes, attr))
        featureCopy.attributes[attr] = null;
      else if (customData[attr]) {
        customData[attr].value = null;
      }
    });

    featureCopy.attributes.customData = JSON.stringify(customData);
    return featureCopy;
  };

  attachmentInfos = () => {
    const { attachmentInfos } = this.props;
    return attachmentInfos ? attachmentInfos : {};
  };

  getUniqueAttachmentInfos = (attribute) => {
    const attachmentInfos = this.attachmentInfos();
    const { filesToAdd, savedFiles } = attachmentInfos;
    const uniqueAttachmentInfos = {
      ...this.attachmentInfos(),
      attribute,
      importFiles:
        filesToAdd && filesToAdd[attribute] ? [filesToAdd[attribute]] : [],
      attachments:
        savedFiles && savedFiles[attribute] ? [savedFiles[attribute]] : []
    };
    return uniqueAttachmentInfos;
  };

  attributeDomainValues = (attribute, featureCopy) => {
    const { domainValues, feature, geometryType } = this.props;
    if (!domainValues || !domainValues[attribute]) return [];
    const attributeDomainValues = domainValues[attribute];
    return getApplicableItems(
      featureCopy ? featureCopy : feature,
      attributeDomainValues,
      geometryType
    ).sort((a, b) => {
      if (a.order && b.order) {
        return a.order > b.order ? 1 : -1;
      } else if (a.title && b.title) {
        return a.title.localeCompare(b.title);
      } else if (a.value && b.value) {
        return a.value > b.value ? 1 : -1;
      } else {
        return 1;
      }
    });
  };

  isNonEditableAttribute = (attribute) => {
    const { nonEditableAttributes, feature, geometryType } = this.props;
    if (!attribute || !nonEditableAttributes) return false;
    else if (attribute === FEATURE_AREA_ATTRIBUTE) return true;
    else if (Array.isArray(nonEditableAttributes)) {
      return nonEditableAttributes.includes(attribute);
    } else if (nonEditableAttributes[attribute]) {
      const applicableItems = getApplicableItems(
        feature,
        nonEditableAttributes[attribute],
        geometryType
      );
      if (!applicableItems) return false;
      else if (Array.isArray(applicableItems)) {
        if (!applicableItems.length) return false;
        return !isNullOrUndefined(applicableItems[0].value)
          ? applicableItems[0].value
          : true;
      } else if (
        Object.prototype.hasOwnProperty.call(applicableItems, "value")
      ) {
        return applicableItems.value;
      } else return applicableItems ? true : false;
    }
    return false;
  };

  sortDetailsItems = (detailsItems) => {
    const displayOrder = this.getDisplayOrder();

    return detailsItems
      .map((a) => ({
        ...a,
        index:
          displayOrder.indexOf(a.key) !== -1
            ? displayOrder.indexOf(a.key)
            : 999999
      }))
      .sort((a, b) => a.index - b.index);
  };

  getCompoundAttributes = () => {
    const { compoundAttributes } = this.props;
    if (!compoundAttributes) return {};
    return compoundAttributes;
  };

  getAllCompoundAttributesFields = () => {
    const compoundAttributes = this.getCompoundAttributes();
    return Object.keys(compoundAttributes).reduce(
      (result, key) => [...result, ...compoundAttributes[key].fields],
      []
    );
  };

  displayAttributes = () => {
    if (this.whenLoading()) return [];
    const allAttributes = this.allAttributes();
    const hiddenAttributes = this.hiddenAttributes();
    const compoundAttributeFields = this.getAllCompoundAttributesFields();
    const displayAttributes = allAttributes.filter(
      (attribute) =>
        !hiddenAttributes.includes(attribute.key) &&
        !compoundAttributeFields.includes(attribute.key)
    );

    return this.filterContextual(displayAttributes);
  };

  getDefaultValueForAttribute = (key, featureCopy) => {
    const { defaultValues, feature, geometryType, editable } = this.props;
    if (!defaultValues || !key || !defaultValues[key] || !editable) return null;

    const domainValues = this.attributeDomainValues(key, featureCopy);
    return getFormattedDefaultValue(
      featureCopy ? featureCopy : feature,
      defaultValues[key],
      geometryType,
      domainValues
    );
  };

  allAttributes = () => {
    if (this.whenLoading()) return [];
    const { feature, customAttributes, editable } = this.props;
    if (!feature) return [];
    const customStats = this.customStatistics();
    if (customStats) this.calculateStatistics();
    let newFeature = feature;
    if (
      editable &&
      (!newFeature.attributes ||
        !newFeature.attributes.customData ||
        !isValidJSON(newFeature.attributes.customData)) &&
      customAttributes
    ) {
      newFeature = this.handleBlankCustomData();
    }
    const { attributes } = newFeature;
    const attributeKeys = Object.keys(attributes);

    let attributeDetails = attributeKeys.map((key) => ({
      key,
      editable,
      value: attributes[key]
    }));

    let customAttributeDetails = [];

    if (
      //can't use object prototype methods on esri objects
      attributes.hasOwnProperty("customData") &&
      attributes.customData !== null &&
      attributes.customData !== "" &&
      isValidJSON(attributes.customData)
    ) {
      const { customData } = attributes;
      try {
        const parsedCustomData = JSON.parse(escapeJSONNewLine(customData));
        customAttributeDetails = Object.keys(parsedCustomData)
          .filter((key) => parsedCustomData[key].type !== "settings")
          .map((key) => ({
            key,
            editable,
            value: parsedCustomData[key].value
          }));
      } catch (e) {
        console.error("error", e);
      }
    }

    const customWorkflowDetails = customAttributes
      ? Object.keys(customAttributes)
          .filter((key) => customAttributes[key].type !== "settings")
          .filter((attribute) => {
            return !customAttributeDetails.find(
              (item) => item.key === attribute
            );
          })
          .reduce((result, attribute) => {
            const value = this.getDefaultValueForAttribute(attribute);
            return [
              ...result,
              {
                key: attribute,
                editable,
                value
              }
            ];
          }, [])
      : [];

    const compoundAttributes = Object.keys(this.getCompoundAttributes()).map(
      (item) => ({
        key: item,
        value: null,
        editable
      })
    );

    let sortedDetails = this.sortDetailsItems([
      ...attributeDetails,
      ...customAttributeDetails,
      ...customWorkflowDetails,
      ...compoundAttributes
    ]);

    sortedDetails = sortedDetails.reduce((result, item) => {
      if (!result.find((resultItem) => resultItem.key === item.key))
        return [...result, item];
      else return result;
    }, []);

    return sortedDetails;
  };

  getFieldType = (field, feature) => {
    if (!feature || !field) return "string";
    const {
      layer,
      attributes: { customData }
    } = feature;
    const { customAttributes, attributeTypes } = this.props;
    let options = this.attributeDomainValues(field);
    let type;
    const parsedCustomData =
      customData && isValidJSON(customData) ? JSON.parse(customData) : {};
    if (
      customAttributes &&
      customAttributes[field] &&
      customAttributes[field].type
    )
      type = customAttributes[field].type;
    else if (parsedCustomData[field] && parsedCustomData[field].type)
      type = parsedCustomData[field].type;
    else if (attributeTypes && attributeTypes[field])
      type = attributeTypes[field];
    else {
      const layerField =
        layer && layer.fields
          ? layer.fields.find(
              (fieldItem) =>
                fieldItem.name === field || fieldItem.alias === field
            )
          : null;
      type = layerField ? layerField.type : "string";
    }
    if (
      type !== "boolean" &&
      type !== "troolean" &&
      type !== "checkbox" &&
      options.length > 0
    ) {
      type = "select";
    }
    return type;
  };

  getConvertToLocaleString = (attr) => {
    //These are fields that should not be converted to locale string
    const { notConvertedToLocaleString } = this.props;
    return (
      !notConvertedToLocaleString || !notConvertedToLocaleString.includes(attr)
    );
  };

  getFieldValue = (attr, editable, value, type) => {
    if (
      editable ||
      !this.getConvertToLocaleString(attr) ||
      type === "color" ||
      attr === TOTAL_AREA_ATTRIBUTE
    ) {
      return value;
    }

    return toLocale(value);
  };

  getFormattedArea = (area) => {
    const { areaUnit } = this.props;
    return getConvertedFeatureArea(area, areaUnit.unit);
  };

  getField = (item) => {
    const {
      editable,
      feature,
      labels: {
        FEATURE_ATTRIBUTE_NO_VALUE_LABEL,
        DEFAULT_OPTION_VALUE_LABEL,
        LABEL_YES,
        LABEL_NO,
        LABEL_NA
      }
    } = this.props;
    const { key, value } = item;

    let options = this.attributeDomainValues(key);
    const type = this.getFieldType(key, feature);

    if (type === "boolean" && options.length === 0) {
      options = [
        {
          value: true,
          title: LABEL_YES
        },
        {
          value: false,
          title: LABEL_NO
        }
      ];
    }

    if (type === "troolean" && options.length === 0) {
      options = [
        {
          value: true,
          title: LABEL_YES
        },
        {
          value: false,
          title: LABEL_NO
        },
        {
          value: null,
          title: LABEL_NA
        }
      ];
    }

    if (type === "checkbox" && options.length > 0) {
      options = options.map((option) => ({
        ...option,
        title: option.title ? option.title : option.value,
        checked: !isNullOrUndefined(value)
          ? value.indexOf(option.value) !== -1
          : false
      }));
    }

    const relatedDomainValue =
      options.length > 0
        ? options.filter((option) =>
            Array.isArray(value)
              ? value.indexOf(option.value) !== -1
              : option.value == value
          )
        : null;

    const isSingleSelect =
      type === "select" ||
      ((type === "troolean" || type === "boolean") && options.length > 0);

    const isMultiSelect = type === "checkbox";

    //handle updates for single select items that have domain values that don't match the saved value or when there is only one domain value present
    if (editable && isSingleSelect) {
      if (
        (!relatedDomainValue || relatedDomainValue.length === 0) &&
        !isNullOrUndefined(value) &&
        options.length > 1
      ) {
        this.handleUpdateValue(key, null);
      } else if (
        options.length === 1 &&
        (options[0].value !== value || value === "" || isNullOrUndefined(value))
      ) {
        this.handleUpdateValue(key, options[0].value);
      }
    }

    //handle updates for multiselect domain values with domainValues that don't match the saved value
    if (isMultiSelect && editable) {
      if (
        (!relatedDomainValue || relatedDomainValue.length === 0) &&
        !isNullOrUndefined(value) &&
        value.length > 0 &&
        options.length > 1
      ) {
        this.handleUpdateValue(key, []);
      } else if (
        Array.isArray(value) &&
        relatedDomainValue &&
        relatedDomainValue.length > 0 &&
        relatedDomainValue.length !== value.length
      ) {
        const applicableValues = value.filter(
          (valueItem) =>
            relatedDomainValue.find(
              (domainValue) => domainValue.value === valueItem
            ) !== undefined
        );
        this.handleUpdateValue(key, applicableValues);
      }
    }

    const attrValidationRules = this.validationRules(key);

    const placeholder = this.getPlaceholder(key);
    const isEditable =
      isSingleSelect && editable && options.length <= 1
        ? false
        : editable && !this.isNonEditableAttribute(key);

    let actualValue =
      key === FEATURE_AREA_ATTRIBUTE
        ? this.getFormattedArea(value)
        : isEditable
        ? type === "select" && isNullOrUndefined(value)
          ? ""
          : isMultiSelect && isNullOrUndefined(value)
          ? []
          : value
        : isNullOrUndefined(value) ||
          value === "" ||
          (Array.isArray(value) && value.length === 0)
        ? placeholder
          ? placeholder
          : FEATURE_ATTRIBUTE_NO_VALUE_LABEL
        : relatedDomainValue && relatedDomainValue.length > 0
        ? relatedDomainValue
            .map((domainValue) =>
              domainValue.title ? domainValue.title : domainValue.value
            )
            .join(", ")
        : type === "date"
        ? moment(value).isValid()
          ? moment(value).format(this.getDateFormat())
          : "-"
        : value;
    const details = {
      ...item,
      ...attrValidationRules,
      isEditable,
      value: this.getFieldValue(key, isEditable, actualValue, type),
      isValid: isValid(value, attrValidationRules, type),
      type,
      label: this.formatItemTitle(key),
      dateFormat: this.getDateFormat(),
      helpText: toLocale(this.getHelpText(key)),
      options,
      isHighlighted: this.isHighlightedField(key),
      placeholder: toLocale(placeholder),
      attribute: key,
      unit: this.getFieldUnit(key, isEditable, value),
      openToDate: isEditable
        ? this.getOpenToDate(key)
        : toLocale(this.getOpenToDate(key))
    };
    if (type === "select") {
      details.defaultOptionLabel = DEFAULT_OPTION_VALUE_LABEL;
    }

    return type === "attachment"
      ? { ...details, ...this.getUniqueAttachmentInfos(key) }
      : details;
  };

  isCompound = (item) => {
    const compoundAttributes = this.getCompoundAttributes();
    return Object.prototype.hasOwnProperty.call(compoundAttributes, item.key);
  };

  getCompoundItem = (item) => {
    const { key } = item;
    const compoundAttributes = this.getCompoundAttributes();
    return compoundAttributes[key];
  };

  getAttrValue = (attr) => {
    const allAttributes = this.allAttributes();
    const attribute = allAttributes.find((item) => item.key === attr);
    return attribute ? attribute.value : null;
  };

  getFieldUnit = (attr, isEditable, value) => {
    if (
      isNullOrUndefined(value) ||
      (!isEditable && attr !== FEATURE_AREA_ATTRIBUTE)
    )
      return null;
    const { units, areaUnit } = this.props;
    if (attr === FEATURE_AREA_ATTRIBUTE) return areaUnit.shortname;
    else if (!attr || !units || !units[attr]) return null;
    return units[attr];
  };

  getFields = () => {
    const detailsItems = this.displayAttributes();
    const { feature } = this.props;
    if (!feature) return [];

    const detailsFields = detailsItems.reduce((result, item) => {
      const isCompound = this.isCompound(item);
      if (isCompound) {
        const { label, fields } = this.getCompoundItem(item);
        const inputs = fields.map((field) => {
          const details = this.getField({
            key: field,
            value: this.getAttrValue(field)
          });
          return {
            ...details,
            helpText: null,
            label: ""
          };
        });
        return [
          ...result,
          {
            label,
            isCompound: true,
            helpText: this.getHelpText(item.key),
            inputs,
            isValid: inputs.every((item) => item.isValid === true)
          }
        ];
      }
      const itemDetails = this.getField(item);
      return [...result, itemDetails];
    }, []);
    return detailsFields;
  };

  getPlaceholder = (attr) => {
    const { placeholders } = this.props;
    if (!placeholders || !attr) return null;
    return Object.prototype.hasOwnProperty.call(placeholders, attr)
      ? placeholders[attr]
      : null;
  };

  handleUpdateValue = (key, value) => {
    const { customAttributes } = this.props;
    let feature = this.props.feature.clone
      ? this.props.feature.clone()
      : { ...this.props.feature };
    if (Object.prototype.hasOwnProperty.call(feature.attributes, key)) {
      feature.attributes = {
        ...feature.attributes,
        [key]: value
      };
    } else if (
      feature.attributes.customData &&
      feature.attributes.customData !== null &&
      isValidJSON(feature.attributes.customData)
    ) {
      try {
        const customData = JSON.parse(
          escapeJSONNewLine(feature.attributes.customData)
        );
        feature.attributes = {
          ...feature.attributes,
          customData: JSON.stringify({
            ...customData,
            [key]: {
              value,
              type:
                customAttributes && customAttributes[key]
                  ? customAttributes[key].type
                  : customData[key].type
            }
          })
        };
      } catch (e) {}
    } else {
      try {
        const { customAttributes } = this.props;
        const customData = customAttributes
          ? Object.keys(customAttributes).reduce((result, attribute) => {
              return {
                ...result,
                [attribute]: {
                  ...customAttributes[attribute],
                  value: null
                }
              };
            }, {})
          : {};

        customData[key] = {
          value,
          type:
            customAttributes && customAttributes[key]
              ? customAttributes[key].type
              : customData[key].type
        };
        feature.attributes.customData = JSON.stringify(customData);
      } catch (e) {}
    }
    feature = this.updateDefaultValues(
      this.handleResetCustomContextualAttributes(feature)
    );
    const { onChange } = this.props;
    if (onChange) onChange(feature);

    this.forceUpdate();
  };

  updateDefaultValues = (feature) => {
    let featureCopy = feature.clone ? feature.clone() : { ...feature };
    const { attributes } = featureCopy;
    const { customAttributes, defaultValues } = this.props;

    if (!defaultValues || Object.keys(defaultValues).length === 0)
      return feature;

    const customData =
      attributes.customData && isValidJSON(attributes.customData)
        ? JSON.parse(attributes.customData)
        : {};

    Object.keys(defaultValues).forEach((key) => {
      let value = this.getDefaultValueForAttribute(key, featureCopy);
      if (
        Object.prototype.hasOwnProperty.call(featureCopy.attributes, key) &&
        isNullOrUndefined(featureCopy.attributes[key])
      )
        featureCopy.attributes[key] = value;
      else if (
        Object.prototype.hasOwnProperty.call(customAttributes, key) &&
        (!customData[key] || isNullOrUndefined(customData[key].value))
      )
        customData[key] = {
          ...customAttributes[key],
          value: value
        };
    });

    featureCopy.attributes.customData = JSON.stringify(customData);

    return featureCopy;
  };

  filterContextual = (sortedDetails) => {
    const { contextualAttributes, feature, geometryType } = this.props;
    if (!contextualAttributes) return sortedDetails;
    return sortedDetails.filter((displayField) => {
      const { key } = displayField;
      if (!key) return false;
      return Object.prototype.hasOwnProperty.call(contextualAttributes, key)
        ? getApplicableItems(feature, contextualAttributes[key], geometryType)
        : true;
    });
  };

  isHighlightedField = (field) => {
    const { highlightedFields } = this.props;
    if (!highlightedFields || !field) return false;
    return (
      highlightedFields.find((fieldName) => fieldName === field) !== undefined
    );
  };

  getLanguageLabel = (stringConstant, data) => {
    const { labels } = this.props;
    const label = labels[stringConstant];
    if (!label) return stringConstant;
    return decodeComputedString(label, data);
  };

  childContent = () => {
    const { childContent } = this.props;
    return childContent;
  };

  aboveChildContent = () => {
    const childContent = this.childContent();
    if (!childContent || !childContent.above) return null;
    return childContent.above;
  };

  belowChildContent = () => {
    const childContent = this.childContent();
    if (!childContent || !childContent.below) return null;
    return childContent.below;
  };

  render() {
    return (
      <FeatureDetailsWrapper
        data-name={"FeatureDetailsWrapper"}
        ref={this.parentContainer}
      >
        {this.whenLoading() ? (
          <Loader visible={true} />
        ) : (
          <ConditionalFormWrap condition={this.whenEditing()}>
            {this.aboveChildContent()}
            <FeatureDetailsComponent
              detailsItems={this.getFields()}
              editable={this.props.editable}
              requiredLabel={this.props.labels.REQUIRED_FIELD_LABEL}
              datePlaceholderLabel={
                this.props.labels.DATE_PICKER_PLACEHOLDER_LABEL
              }
              handleUpdateValue={this.handleUpdateValue}
              dateResetLabel={this.props.labels.RESET_DATEPICKER_LABEL}
            />
            {this.showGenericAttachmentArea() && (
              <Attachments
                {...this.attachmentInfos()}
                isEditing={this.whenEditing()}
              />
            )}
            {this.belowChildContent()}
          </ConditionalFormWrap>
        )}
        {this.props.children}
      </FeatureDetailsWrapper>
    );
  }
}
