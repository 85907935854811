import React from "react";
import { TableCellContentWrap } from "../../..";
import DeleteCellValueButton from "../Buttons/DeleteCellValueButton";
import { Input } from "../../../../../core/components";
import PropTypes from "prop-types";

const StringCell = (props) => {
  return (
    <TableCellContentWrap>
      <Input
        type="text"
        value={props.cellValue}
        aria-labelledby={props.headerId}
        required={props.required}
        id={props.id}
        invalid={props.invalid}
        aria-invalid={props.invalid}
        aria-describedby={props.describedBy}
        onChange={(e) => props.handleOnChangeValue(props, e.target.value)}
      />
      {props.showDeleteButton && <DeleteCellValueButton {...props} />}
    </TableCellContentWrap>
  );
};

StringCell.propTypes = {
  /** The id that is on the corresponding header to the cell */
  headerId: PropTypes.string,
  /** If the field is required */
  required: PropTypes.bool,
  /** An id that refers to a node that has a description for the field. Is important for a11y */
  describedBy: PropTypes.string,
  /** Whether to show the delete value button */
  showDeleteButton: PropTypes.bool,
  /** The id to put on the input */
  id: PropTypes.string,
  /** If the value is invalid */
  invalid: PropTypes.bool,
  /** Is called on change of value */
  handleOnChangeValue: PropTypes.func
};

export default StringCell;
