import { createActions } from "redux-actions";
import {
  LOAD_APP,
  APP_BUSY,
  ABORT_SIGNAL_LOADED,
  SET_LOADING_TEXT,
  ABORT_REQUEST,
  OPEN_WIDGET,
  CLOSE_WIDGET,
  OPEN_SIDEBAR,
  CLOSE_SIDEBAR,
  OPEN_PROFILE_DROP_DOWN,
  CLOSE_PROFILE_DROP_DOWN,
  CLEAR_ERRORS,
  DISABLE_CACHE,
  SETUP_INTERCEPTORS,
  SET_ATTRIBUTION,
  START_REQUEST,
  DO_REQUEST,
  COMPLETED_REQUEST,
  SET_REQUEST_ERROR,
  SET_DOC_TITLE,
  SET_GENERIC_ERROR,
  SEND_ACTION_LOG
} from "../constants";

export const {
  loadApp,
  appBusy,
  abortSignalLoaded,
  setLoadingText,
  abortRequest,
  openWidget,
  closeWidget,
  openSidebar,
  closeSidebar,
  openProfileDropDown,
  closeProfileDropDown,
  clearErrors,
  disableCache,
  setupInterceptors,
  setAttribution,
  startRequest,
  doRequest,
  completedRequest,
  setRequestError,
  setDocTitle,
  setGenericError,
  sendActionLog
} = createActions({
  [LOAD_APP]: (modules) => modules,
  [APP_BUSY]: (isBusy) => isBusy,
  [ABORT_SIGNAL_LOADED]: (controller) => ({ controller }),
  [SET_LOADING_TEXT]: (loadingText) => ({ loadingText }),
  [ABORT_REQUEST]: () => {},
  [OPEN_WIDGET]: (widget) => ({ widget }),
  [CLOSE_WIDGET]: () => {},
  [OPEN_SIDEBAR]: () => {},
  [CLOSE_SIDEBAR]: () => {},
  [OPEN_PROFILE_DROP_DOWN]: () => {},
  [CLOSE_PROFILE_DROP_DOWN]: () => {},
  [CLEAR_ERRORS]: () => {},
  [DISABLE_CACHE]: (shouldDisableCache) => ({ shouldDisableCache }),
  [SETUP_INTERCEPTORS]: (token) => ({
    token
  }),
  [SET_ATTRIBUTION]: (attribution) => attribution,
  [START_REQUEST]: (requestName, ...args) => ({
    requestName,
    params: [...args]
  }),
  [DO_REQUEST]: (requestName, requestMethod, params) => ({
    requestName,
    requestMethod,
    params
  }),
  [COMPLETED_REQUEST]: (requestName, results) => ({
    requestName,
    results
  }),
  [SET_REQUEST_ERROR]: (requestName, error) => ({ requestName, error }),
  [SET_DOC_TITLE]: (docTitle) => ({ docTitle }),
  [SET_GENERIC_ERROR]: (error) => error,
  [SEND_ACTION_LOG]: (orgId, actionType, data) => ({ orgId, actionType, data })
});
