import React from "react";
import {
  Input,
  InputContainer,
  InputText,
  InputWrap,
  Label
} from "../../../../../core/components";
import RequiredSymbol from "../RequiredSymbol";
import PropTypes from "prop-types";
import {
  FormItemFieldContainer,
  FormItemUnitContainer,
  FormItemWrapper
} from "../../..";
const PercentageInput = (props) => {
  function onChange(e) {
    const { handleUpdateValue, attribute } = props;
    handleUpdateValue(attribute, e.target.value);
  }

  return (
    <FormItemWrapper>
      <FormItemFieldContainer>
        <Label
          htmlFor={`${props.attribute}-input`}
          type={!props.isValid ? "formError" : ""}
          styletype="form-label"
        >
          {props.label}
        </Label>
        <InputContainer>
          <Input
            max={props.max ? props.max : 100}
            min={props.min ? props.min : 0}
            id={`${props.attribute}-input`}
            type={"number"}
            step={"0.01"}
            value={props.value}
            placeholder={props.placeholder}
            onChange={onChange}
            invalid={!props.isValid}
            disabled={props.disabled}
            required={props.required}
            aria-invalid={!props.isValid}
            aria-describedby={
              props.helpText
                ? `InputWrapper-${props.attribute}-help-text`
                : null
            }
          />
          {!props.isValid && props.required && <RequiredSymbol />}
        </InputContainer>
      </FormItemFieldContainer>
      <FormItemUnitContainer>
        <Label>%</Label>
      </FormItemUnitContainer>
    </FormItemWrapper>
  );
};

PercentageInput.propTypes = {
  attribute: PropTypes.string,
  isValid: PropTypes.bool,
  label: PropTypes.string,
  max: PropTypes.number,
  min: PropTypes.number,
  value: PropTypes.number,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  handleUpdateValue: PropTypes.func,
  required: PropTypes.bool
};

export default PercentageInput;
