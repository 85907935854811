import { createActions } from "redux-actions";
import { LOADED_LANGUAGE, LOAD_LANGUAGE } from "../constants";

export const { loadedLanguage, loadLanguage } = createActions({
  [LOADED_LANGUAGE]: (language, languageFile) => ({
    language,
    languageFile
  }),
  [LOAD_LANGUAGE]: (language, languageFile) => ({
    language,
    languageFile
  })
});
