import React, { Component } from "react";
import { defaultTheme } from "../AgBoxUIKit";
import { Icon, Loader } from "../AgBoxUIKit/core";
import { Button, InputContainer, P } from "../AgBoxUIKit/core/components";
import {
  AuthenticationContainer,
  AuthenticationContentWrap,
  AuthenticationForm,
  AuthenticationHeading,
  AuthenticationPasswordText,
  AuthenticationWrapper,
  FormValidationText,
  InvitationContentContainer,
  LogoImage,
  PasswordIconButton,
  PasswordInput,
  PasswordLabel
} from "../AgBoxUIKit/core/layout";
import { FormItemFieldContainer } from "../AgBoxUIKit/plugin/components";
import RequiredSymbol from "../AgBoxUIKit/plugin/components/FeatureDetailsComponent/subcomponents/RequiredSymbol";
import { decodeComputedString, getUrlSearchParamByKey } from "../App/utils";
import {
  PASSWORD_REGEXP_PATTERN,
  INVITATION_ALREADY_ACCEPTED_ERROR
} from "../constants";

export default class AcceptInvitation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      password: "",
      confirmPassword: "",
      showPassword: false,
      showConfirmPassword: false,
      validPassword: true,
      validConfirmPassword: true
    };
  }
  componentDidMount() {
    this.loadInvitation();
  }
  loadInvitation = () => {
    const { inviteId, orgId, inviteToken, loadInvitation, location } =
      this.props;
    const emailAddress = getUrlSearchParamByKey(location, "emailAddress");
    loadInvitation(inviteId, orgId, inviteToken, emailAddress);
  };
  getLoadingInvitation = () => {
    const { loadingInvitation } = this.props;
    return loadingInvitation;
  };
  getInvitation = () => {
    const { invitation } = this.props;
    return invitation;
  };
  getAgreement = () => {
    const termsOfService = (
      <a
        href="https://landkind.com/termsofservice.html"
        target="_blank"
        rel="noreferrer"
      >
        {this.getLabel("INVITATION_TERMS_OF_SERVICE_LABEL")}
      </a>
    );
    const privacyPolicy = (
      <a
        href="https://landkind.com/privacypolicy.html"
        target="_blank"
        rel="noreferrer"
      >
        {this.getLabel("INVITATION_PRIVACY_POLICY_LABEL")}
      </a>
    );
    return {
      termsOfService,
      privacyPolicy
    };
  };
  getNotFoundContent = () => {
    const { userError } = this.props;
    if (userError && userError === INVITATION_ALREADY_ACCEPTED_ERROR) {
      return (
        <InvitationContentContainer>
          <AuthenticationHeading>
            {this.getLabel("INVITATION_ALREADY_ACCEPTED_TITLE_LABEL")}
          </AuthenticationHeading>
          <P>{this.getLabel("INVITATION_ALREADY_ACCEPTED_LOGIN")}</P>
          <P>{this.getLabel("INVITATION_FORGOT_PASSWORD")}</P>
          <AuthenticationForm>
            <Button onClick={this.gotoHomePage} styletype="primary">
              {this.getLabel("LOGIN_PAGE_BUTTON")}
            </Button>
          </AuthenticationForm>
        </InvitationContentContainer>
      );
    }

    return (
      <InvitationContentContainer>
        <AuthenticationHeading>
          {this.getLabel("INVITATION_NOT_FOUND_TITLE_LABEL")}
        </AuthenticationHeading>
        <P>{this.getLabel("INVITATION_NOT_FOUND_EXPIRED_LABEL")}</P>
        <P>{this.getLabel("INVITATION_NOT_FOUND_LINK_LABEL")}</P>
        <P>{this.getLabel("INVITATION_NOT_FOUND_CONTACT_LABEL")}</P>
      </InvitationContentContainer>
    );
  };

  gotoHomePage = () => {
    const { navigate } = this.props;
    navigate("/");
  };

  getLabel = (labelConstant, data) => {
    const { labels } = this.props;
    const label = labels[labelConstant];
    if (!label) return labelConstant;
    return decodeComputedString(label, data);
  };

  getInvitationContent = () => {
    const invitation = this.getInvitation();
    if (!invitation) return null;
    const agreement = this.getAgreement();
    const { firstName } = invitation;
    const {
      password,
      confirmPassword,
      showPassword,
      showConfirmPassword,
      validPassword,
      validConfirmPassword
    } = this.state;
    return (
      <InvitationContentContainer>
        <AuthenticationHeading>
          {this.getLabel("INVITATION_GREETING_NAME", {
            firstName
          })}
        </AuthenticationHeading>
        <AuthenticationHeading>
          {this.getLabel("INVITATION_GREETING_LABEL")}
        </AuthenticationHeading>

        <P type="invitation-content">
          {this.getLabel("INVITATION_DESCRIPTION_LABEL", invitation)}
        </P>
        <P type="invitation-content">
          {this.getLabel("INVITATION_INSTRUCTIONS_LABEL")}
        </P>
        <P type="invitation-content">
          {this.getLabel("INVITATION_AGREEMENT_LABEL")}
          {agreement.termsOfService} &&nbsp;
          {/* {this.getLabel("INVITATION_AGREEMENT_JOIN_LABEL")} */}
          {agreement.privacyPolicy}.
        </P>
        <AuthenticationPasswordText>
          <strong>
            {this.getLabel("INVITATION_PASSWORD_REQUIREMENTS_TITLE_LABEL")}:
          </strong>{" "}
          {this.getLabel("INVITATION_PASSWORD_REQUIREMENTS_LABEL")}
        </AuthenticationPasswordText>
        <AuthenticationForm novalidate>
          <FormItemFieldContainer>
            <PasswordLabel
              htmlFor={"password"}
              type={validPassword ? "" : "formError"}
            >
              {this.getLabel("PASSWORD_LABEL")}
            </PasswordLabel>
            <InputContainer>
              <PasswordInput
                value={password}
                onChange={this.updatePassword}
                id={"password"}
                type={showPassword ? "text" : "password"}
                required={true}
                pattern={PASSWORD_REGEXP_PATTERN}
                aria-invalid={!validPassword}
              />
              {!validPassword ? <RequiredSymbol /> : ""}
              <PasswordIconButton
                onClick={this.toggleShowPassword}
                title={this.getLabel("SHOW_CONTENT_LABEL")}
              >
                <Icon
                  type={showPassword ? "eye-slash" : "eye"}
                  iconWidth="20px"
                  iconHeight="20px"
                  iconColor={defaultTheme.agGreen}
                />
              </PasswordIconButton>
            </InputContainer>
          </FormItemFieldContainer>
          <FormItemFieldContainer>
            <PasswordLabel
              htmlFor={"confirmPassword"}
              type={validConfirmPassword ? "" : "formError"}
            >
              {this.getLabel("CONFIRM_PASSWORD_LABEL")}
            </PasswordLabel>
            <InputContainer>
              <PasswordInput
                value={confirmPassword}
                onChange={this.updateConfirmPassword}
                id={"confirmPassword"}
                required={true}
                type={showConfirmPassword ? "text" : "password"}
                aria-invalid={!validConfirmPassword}
              />
              {!validConfirmPassword ? <RequiredSymbol /> : ""}
              <PasswordIconButton
                onClick={this.toggleShowConfirmPassword}
                title={this.getLabel("SHOW_CONTENT_LABEL")}
              >
                <Icon
                  type={showConfirmPassword ? "eye-slash" : "eye"}
                  iconWidth="20px"
                  iconHeight="20px"
                  iconColor={defaultTheme.agGreen}
                />
              </PasswordIconButton>
            </InputContainer>
          </FormItemFieldContainer>
          {!validPassword ? (
            <FormValidationText role="alert">
              {this.getLabel("INVALID_PASSWORD_LABEL")}
            </FormValidationText>
          ) : (
            ""
          )}
          {validPassword && !validConfirmPassword ? (
            <FormValidationText role="alert">
              {this.getLabel("INVALID_CONFIRM_PASSWORD_LABEL")}
            </FormValidationText>
          ) : (
            ""
          )}
          <Button onClick={this.submitForm} styletype="primary">
            {this.getLabel("SUBMIT_PASSWORD_LABEL")}
          </Button>
        </AuthenticationForm>
      </InvitationContentContainer>
    );
  };
  submitForm = (e) => {
    e.preventDefault();
    const validPassword = this.validPassword();
    const validConfirmPassword = this.validConfirmPassword();
    this.setState({
      validPassword,
      validConfirmPassword
    });
    if (!validPassword || !validConfirmPassword) return;
    const { acceptInvitationPublic, inviteId, orgId, inviteToken, location } =
      this.props;
    const emailAddress = getUrlSearchParamByKey(location, "emailAddress");
    const { password } = this.state;
    acceptInvitationPublic(
      inviteId,
      orgId,
      inviteToken,
      emailAddress,
      password
    );
  };

  toggleShowPassword = (e) => {
    e.preventDefault();
    const { showPassword } = this.state;
    this.setState({
      showPassword: !showPassword
    });
  };

  toggleShowConfirmPassword = (e) => {
    e.preventDefault();
    const { showConfirmPassword } = this.state;
    this.setState({
      showConfirmPassword: !showConfirmPassword
    });
  };

  validPassword = () => {
    const { password } = this.state;
    let validPassword = true;
    const passwordRegexp = new RegExp(PASSWORD_REGEXP_PATTERN);
    if (!password || !passwordRegexp.test(password)) validPassword = false;
    return validPassword;
  };

  updatePassword = (e) => {
    this.setState({
      password: e.target.value
    });
  };

  updateConfirmPassword = (e) => {
    this.setState({
      confirmPassword: e.target.value
    });
  };

  validConfirmPassword = () => {
    const { password, confirmPassword } = this.state;
    let validConfirmPassword = true;
    if (confirmPassword && confirmPassword !== password)
      validConfirmPassword = false;
    return validConfirmPassword;
  };

  getAcceptingInvitation = () => {
    const { acceptingInvitation } = this.props;
    return acceptingInvitation;
  };

  showLoader = () => {
    return this.getLoadingInvitation() || this.getAcceptingInvitation();
  };

  getLoadingText = () =>
    this.getLabel(
      this.getLoadingInvitation()
        ? "LOADING_INVITATION_LABEL"
        : "ACCEPTING_INVITATION_LABEL"
    );
  render() {
    return (
      <AuthenticationWrapper>
        <AuthenticationContainer wide={true}>
          <AuthenticationContentWrap>
            <LogoImage
              alt="Landkind logo"
              src={
                "https://agbox-assets.s3-us-west-2.amazonaws.com/logos/landkind.svg"
              }
            />
            {this.showLoader() ? (
              <Loader visible={true} loadingText={this.getLoadingText()} />
            ) : this.getInvitation() ? (
              this.getInvitationContent()
            ) : (
              this.getNotFoundContent()
            )}
          </AuthenticationContentWrap>
        </AuthenticationContainer>
      </AuthenticationWrapper>
    );
  }
}
