import { connect } from "react-redux";
import App from "./App";
import {
  getIsAuthenticated,
  getIsMapViewReady,
  getShowMap,
  getHasMapViewExtent,
  getLoadingText,
  getLabel,
  getWorkflowSize,
  getOrganisationPreferences,
  getHas3D,
  getLoggingOut,
  getMapView,
  getSelectedWorkflowTitle
} from "../selectors";
import * as actions from "../actions";

export default connect(
  (state) => ({
    showMap: getShowMap(state),
    hasMapViewExtent: getHasMapViewExtent(state),
    isAuthenticated: getIsAuthenticated(state),
    mapViewReady: getIsMapViewReady(state),
    selectedWorkflowTitle: getSelectedWorkflowTitle(state),
    loadingText: getLoadingText(state),
    labels: getLabel(state),
    workflowSize: getWorkflowSize(state),
    orgPreferences: getOrganisationPreferences(state),
    has3D: getHas3D(state),
    isLoggingOut: getLoggingOut(state),
    mapView: getMapView(state)
  }),
  actions
)(App);
