import { connect } from "react-redux";
import Measure from "./Measure";
import {
  getWebMapLoading,
  getActiveWidget,
  getWebMap,
  getOrganisationPreferences,
  getDefaultSpatialReference,
  getPropertySpatialReference,
  getLabel
} from "../../selectors";
import * as actions from "../../actions";

export default connect(
  (state) => ({
    webMapLoading: getWebMapLoading(state),
    activeWidget: getActiveWidget(state),
    webMap: getWebMap(state),
    preferences: getOrganisationPreferences(state),
    orgSpatialReference: getDefaultSpatialReference(state),
    propertySpatialReference: getPropertySpatialReference(state),
    labels: getLabel(state)
  }),
  actions
)(Measure);
