import React, { Component } from "react";
import { getResponsiveFontSize } from "@agBoxSource/App/utils";
import {
  MAX_FONT_SIZE_HEADER,
  MIN_FONT_SIZE_HEADER
} from "@agBoxSource/constants";
import { PackageHeadingStyle } from "../index";

export default class PackageHeading extends Component {
  constructor(props) {
    super(props);
    this.packageHeadingRef = React.createRef();
  }

  getFontSize = () => {
    const container = this.packageHeadingRef.current;
    const string = this.props.children;
    if (!container || !string) return;

    return getResponsiveFontSize(
      container,
      string,
      MAX_FONT_SIZE_HEADER,
      MIN_FONT_SIZE_HEADER
    );
  };

  render() {
    return (
      <PackageHeadingStyle
        ref={this.packageHeadingRef}
        responsiveFontSize={this.getFontSize()}
      >
        {this.props.children}
      </PackageHeadingStyle>
    );
  }
}

PackageHeading.displayName = "PackageHeading";
