export const getMapViewExtent = (state) => state.mapView.extent;
export const getHasMapViewExtent = (state) =>
  state.mapView && state.mapView.extent ? true : false;
export const getDefaultVisibleLayers = (state) =>
  state.mapView.defaultVisibleLayers;
export const getMapViewHandler = (state) => state.mapView.mapClickHandler;
export const getShowMap = (state) => state.mapView.showMap;
export const getDateFilterText = (state) => state.mapView.dateFilterText;
export const getShowElevation = (state) => state.mapView.showElevation;

export const getImageryDatesForProperty = (state, propId) =>
  state.mapView.imageryDates && state.mapView.imageryDates[propId]
    ? state.mapView.imageryDates[propId].results
    : false;

export const getImageryLoadingForProperty = (state, propId) =>
  state.mapView.imageryDates && state.mapView.imageryDates[propId]
    ? state.mapView.imageryDates[propId].isLoading
    : false;
