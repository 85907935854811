import React, { Component } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import PropTypes from "prop-types";
import "react-big-calendar/lib/css/react-big-calendar.css";
import {
  BigCalendarEvent,
  BigCalendarWrapper,
  BigCalendarEventTitle,
  BigCalendarEventDescription
} from "..";

// Setup the localizer by providing the moment (or globalize) Object
// to the correct localizer.
const localizer = momentLocalizer(moment); // or globalizeLocalizer

export default class BigCalendar extends Component {
  static propTypes = {
    eventsList: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        start: PropTypes.instanceOf(Date).isRequired,
        end: PropTypes.instanceOf(Date),
        allDay: PropTypes.bool,
        resource: PropTypes.any
      })
    ),
    onSelectDate: PropTypes.func,
    onSelectEvent: PropTypes.func,
    defaultView: PropTypes.oneOf([
      "month",
      "week",
      "work_week",
      "day",
      "agenda"
    ])
  };
  static defaultProps = {
    defaultView: "month"
  };
  eventsList = () => {
    const { eventsList } = this.props;
    return eventsList ? eventsList : [];
  };

  onSelectEvent = (event) => {
    const { onSelectEvent } = this.props;
    if (!onSelectEvent) return;
    onSelectEvent(event);
  };

  defaultView = () => {
    const { defaultView } = this.props;
    return defaultView;
  };

  onSelectDate = (event) => {
    const { onSelectDate } = this.props;
    if (!onSelectDate) return;
    onSelectDate(event);
  };
  render() {
    return (
      <BigCalendarWrapper>
        <Calendar
          localizer={localizer}
          events={this.eventsList()}
          startAccessor="start"
          endAccessor="end"
          onSelectEvent={this.onSelectEvent}
          defaultView={this.defaultView()}
          onSelectSlot={this.onSelectDate}
          components={{
            event: ({ event }) => {
              return (
                <BigCalendarEvent color={event.color}>
                  <BigCalendarEventTitle>{event.title}</BigCalendarEventTitle>
                  {event.description && (
                    <BigCalendarEventDescription>
                      {event.description}
                    </BigCalendarEventDescription>
                  )}
                </BigCalendarEvent>
              );
            }
          }}
        />
      </BigCalendarWrapper>
    );
  }
}
