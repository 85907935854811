import { handleActions } from "redux-actions";
import { LOADED_LANGUAGE, DEFAULT_LANGUAGE } from "../constants";
import english from "../english.json";

export const initialState = {
  language: DEFAULT_LANGUAGE,
  languageFile: english
};

export default handleActions(
  {
    [LOADED_LANGUAGE]: (state, { payload }) => ({
      ...state,
      language: payload.language,
      languageFile: { ...state.languageFile, ...payload.languageFile }
    })
  },
  initialState
);
