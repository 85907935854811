import React from "react";
import { TableViewButtonWrap } from "../../..";
import { Icon } from "../../../../../core";
import { defaultTheme } from "../../../../..";
import { Button, ButtonLabel } from "../../../../../core/components";
import PropTypes from "prop-types";

const AddButton = (props) => {
  return (
    <TableViewButtonWrap>
      <Button styletype="iconButton" onClick={props.onClick}>
        <Icon
          type="add"
          iconColor={defaultTheme.agDarkBlue}
          iconHeight="50px"
          iconWidth="50px"
        />
        <ButtonLabel>{props.label}</ButtonLabel>
      </Button>
    </TableViewButtonWrap>
  );
};

AddButton.propTypes = {
  /** Called on click of the button */
  onClick: PropTypes.func,
  /** The label visible on the button */
  label: PropTypes.string
};

export default AddButton;
