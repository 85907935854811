import React, { Component } from "react";
import { WidgetWrapper, WidgetContainer } from "../../AgBoxUIKit/core/layout";
import { Button } from "../../AgBoxUIKit/core/components";
import { Icon } from "../../AgBoxUIKit/core";
import { defaultTheme } from "../../AgBoxUIKit";

export default class ElevationToggle extends Component {
  showElevation = () => {
    const { showElevation } = this.props;
    return showElevation === true;
  };
  toggleElevation = () => {
    const { setElevationOn } = this.props;
    setElevationOn(!this.showElevation());
  };

  elevationIcon = () => {
    return this.showElevation() ? "3d" : "2d";
  };
  render() {
    const { ELEVATION_WIDGET_TITLE } = this.props.labels;
    return (
      <WidgetWrapper
        data-name={"WidgetWrapper"}
        type="elevation"
        disabled={this.props.disabled}
      >
        <WidgetContainer>
          <Button
            onClick={this.toggleElevation}
            title={ELEVATION_WIDGET_TITLE}
            type="button"
            styletype="widgetButton"
            tabIndex={0}
          >
            <Icon
              type={this.elevationIcon()}
              data-name={"Icon"}
              iconColor={defaultTheme.agWhite}
              iconHeight={"2em"}
              iconWidth={"2em"}
            />
          </Button>
        </WidgetContainer>
      </WidgetWrapper>
    );
  }
}
