import { connect } from "react-redux";
import FeatureSplit from "./FeatureSplit";
import {
  getWebMap,
  getDefaultSpatialReference,
  getMapView,
  getPropertySpatialReference,
  getLabel,
  getOrganisationPreferences
} from "../../selectors";

export default connect((state) => ({
  webMap: getWebMap(state),
  orgSpatialReference: getDefaultSpatialReference(state),
  propertySpatialReference: getPropertySpatialReference(state),
  mapView: getMapView(state),
  orgPreferences: getOrganisationPreferences(state),
  labels: getLabel(state)
}))(FeatureSplit);
