import React, { Component } from "react";
import { PaginationContainer } from "../../layout";
import { PaginationPageList } from "..";
import { defaultTheme } from "../../..";
import PaginationPageButton from "./subcomponents/PaginationPageButton";
import PaginationIconButton from "./subcomponents/PaginationIconButton";
import PropTypes from "prop-types";
import DropDown from "../FormComponents/DropDown";

export default class PaginationComponent extends Component {
  static propTypes = {
    /** Page numbers that are visible */
    visiblePages: PropTypes.arrayOf(PropTypes.number),
    /** Whether the 'back to first page' button is disabled */
    firstPageDisabled: PropTypes.bool,
    /** Whether the 'skip to last page' button is disabled */
    lastPageDisabled: PropTypes.bool,
    /** Called on click of a page button */
    handleSelectPage: PropTypes.func,
    /** What color the button text and icons are */
    buttonColor: PropTypes.string,
    /** Label used for screen readers on the first page button */
    firstPageLabel: PropTypes.string,
    /** Label used for screen readers on last page button */
    lastPageLabel: PropTypes.string,
    /** Called on click of first page button */
    handleSelectFirstPage: PropTypes.func,
    /** Called on click of last page button */
    handleSelectLastPage: PropTypes.func,
    /** Returns boolean of whether the page is active or not */
    isActivePage: PropTypes.func,
    /** Options for the pagination dropdown */
    limitOptions: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        value: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
      })
    ),
    /** Called on change of the limit dropdown value */
    onChangeLimit: PropTypes.func,
    /** The label for the limit dropdown */
    limitLabel: PropTypes.string,
    /** The selected value for the limit dropdown */
    limit: PropTypes.number,
    /** Whether to show the limit dropdown */
    showLimitDropdown: PropTypes.bool,
    /** Max page available */
    pageCount: PropTypes.number
  };
  visiblePages = () => {
    const { visiblePages } = this.props;
    return visiblePages ? visiblePages : [];
  };
  firstPageDisabled = () => {
    const { firstPageDisabled } = this.props;
    return firstPageDisabled;
  };
  lastPageDisabled = () => {
    const { lastPageDisabled } = this.props;
    return lastPageDisabled;
  };
  handleSelectPage = (e) => {
    const { handleSelectPage } = this.props;
    if (handleSelectPage)
      handleSelectPage(Number(e.currentTarget.dataset.page));
  };
  buttonColor = () => {
    const { buttonColor } = this.props;
    return buttonColor ? buttonColor : defaultTheme.agWhite;
  };
  firstPageLabel = () => {
    const { firstPageLabel } = this.props;
    return firstPageLabel ? firstPageLabel : "";
  };
  lastPageLabel = () => {
    const { lastPageLabel } = this.props;
    return lastPageLabel ? lastPageLabel : "";
  };
  handleSelectFirstPage = () => {
    const { handleSelectFirstPage } = this.props;
    if (handleSelectFirstPage) handleSelectFirstPage();
  };
  handleSelectLastPage = () => {
    const { handleSelectLastPage } = this.props;
    if (handleSelectLastPage) handleSelectLastPage();
  };
  isActivePage = (page) => {
    const { isActivePage } = this.props;
    return isActivePage ? isActivePage(page) : false;
  };
  showLimitDropdown = () => {
    const { showLimitDropdown } = this.props;
    return showLimitDropdown === true && this.limitOptions().length > 1
      ? true
      : false;
  };
  limitOptions = () => {
    const { limitOptions } = this.props;
    return limitOptions || [];
  };

  onChangeLimit = (e) => {
    if (!e || !e.currentTarget || !e.currentTarget.value) return false;
    const { onChangeLimit } = this.props;
    if (onChangeLimit) onChangeLimit(Number(e.currentTarget.value));
  };

  limitLabel = () => {
    const { limitLabel } = this.props;
    return limitLabel;
  };

  limit = () => {
    const { limit } = this.props;
    return limit;
  };
  showPageNumbers = () => {
    const { pageCount } = this.props;
    return pageCount ? pageCount > 1 : false;
  };
  render() {
    return (
      <PaginationContainer data-name={"PaginationContainer"}>
        {this.showPageNumbers() && (
          <PaginationPageList data-name={"PaginationPageList"}>
            <PaginationIconButton
              disabled={this.firstPageDisabled()}
              onClick={this.handleSelectFirstPage}
              label={this.firstPageLabel()}
              color={this.buttonColor()}
              type={"beginning"}
            />
            {this.visiblePages().map((page) => (
              <PaginationPageButton
                key={page}
                page={page}
                onClick={this.handleSelectPage}
                active={this.isActivePage(page)}
                color={this.buttonColor()}
              />
            ))}
            <PaginationIconButton
              disabled={this.lastPageDisabled()}
              onClick={this.handleSelectLastPage}
              label={this.lastPageLabel()}
              color={this.buttonColor()}
              type={"end"}
            />
          </PaginationPageList>
        )}
        {this.showLimitDropdown() && (
          <DropDown
            label={this.limitLabel()}
            value={this.limit()}
            onChange={this.onChangeLimit}
            options={this.limitOptions()}
            id={"pagination-limit"}
            direction={"horizontal"}
          />
        )}
      </PaginationContainer>
    );
  }
}
