import React, { Component } from "react";
import { Router } from "@reach/router";
import { PackageWrapper } from "@agBoxUiKit/plugin/layout";
import Workflows from "../Workflows";

/**
 * Holds the routing for rendering a workflow. If a property or property group is selected, and the URL contains a workflow id then the component will render the workflow view.
 */
class Pack extends Component {
  componentDidMount() {
    const { propId, groupId } = this.props;
    if (propId) {
      this.handleSelectProperty();
    }
    if (groupId) {
      this.handleSelectPropertyGroup();
    }
  }
  componentWillUnmount() {
    const {
      propertyGroupError,
      propertyError,
      errorProperty,
      errorPropertyGroup
    } = this.props;
    if (propertyGroupError) {
      errorPropertyGroup(false);
    } else if (propertyError) {
      errorProperty(false);
    }
    this.unloadProperty();
    this.unloadPropertyGroup();
  }

  unloadProperty = () => {
    const { propId, unloadProperty, selectingProperty, abortRequest } =
      this.props;
    if (propId) {
      if (selectingProperty) abortRequest();
      unloadProperty();
    }
  };
  unloadPropertyGroup = () => {
    const { groupId, unloadPropertyGroup } = this.props;
    if (groupId) {
      unloadPropertyGroup();
    }
  };

  componentDidUpdate(prevProps) {
    const { propId, groupId } = this.props;
    if (propId && propId !== prevProps.propId) {
      this.handleSelectProperty();
    }

    if (groupId && groupId !== prevProps.groupId) {
      this.handleSelectPropertyGroup();
    }
  }

  handleSelectProperty = () => {
    const { selectProperty, orgId, propId, propertyGroupError, propertyError } =
      this.props;
    if (propertyGroupError || propertyError || !propId || !orgId) return;
    selectProperty(orgId, propId);
  };

  handleSelectPropertyGroup = () => {
    const {
      selectPropertyGroup,
      orgId,
      groupId,
      propertyGroupError,
      propertyError
    } = this.props;
    if (!orgId || !groupId || propertyGroupError || propertyError) return;
    selectPropertyGroup(orgId, groupId);
  };

  render() {
    const { workflowPanelOpen } = this.props;
    return (
      <PackageWrapper
        data-name="PackageWrapper"
        workflowPanelOpen={workflowPanelOpen}
      >
        <Router>
          <Workflows path="/workflow/:workflowId/*" data-name="Workflows" />
        </Router>
      </PackageWrapper>
    );
  }
}

export default Pack;
