import React, { Component } from "react";
import { LegendList, LegendListItem, AccordionItemButton } from "../..";
import PropTypes from "prop-types";

export default class AccordionList extends Component {
  static propTypes = {
    onClickListItem: PropTypes.func,
    accordionName: PropTypes.string,
    listItems: PropTypes.array
  };

  getAccordionName = () => {
    const { accordionName } = this.props;
    return accordionName;
  };

  onClickListItem = (item) => {
    const { onClickListItem } = this.props;
    onClickListItem(item);
  };

  getListItems = () => {
    const { listItems } = this.props;
    return listItems ? listItems : [];
  };

  render() {
    return (
      <div>
        {this.getListItems().length > 0 ? (
          <LegendList data-name={"LegendList"} type={"packageContainer"}>
            {this.getListItems().map((item, i) => (
              <LegendListItem
                data-name={"LegendListItem"}
                type={"packageContainer"}
                key={`${this.getAccordionName()}-list-item-${i}`}
                onClick={
                  typeof item.content === "string"
                    ? null
                    : () => this.onClickListItem(item)
                }
              >
                {typeof item.content === "string" ? (
                  <AccordionItemButton
                    onClick={() => this.onClickListItem(item)}
                  >
                    {item.content}
                  </AccordionItemButton>
                ) : (
                  item.content
                )}
              </LegendListItem>
            ))}
          </LegendList>
        ) : null}
      </div>
    );
  }
}
