import React, { Component } from "react";
import {
  DropdownButton,
  DropdownMenu,
  DropdownMenuAction,
  DropdownMenuLink,
  DropdownMenuTitle,
  ButtonLabel,
  DropdownMenuForm,
  DropdownMenuListInputs,
  DropdownMenuListInputItem
} from "../../../core/components";
import { Icon } from "../../../core";
import { defaultTheme } from "../../..";
import PropTypes from "prop-types";
import { SEARCH_TYPE_TEXT } from "../../../../constants";
import CheckboxInput from "../../../plugin/components/FeatureDetailsComponent/subcomponents/Inputs/CheckboxInput";

export default class ContextDropdownComponent extends Component {
  static propTypes = {
    /** This is called every time the text checkbox selections are changed, and passes the boolean value. */
    onToggleTextCheckbox: PropTypes.func,
    menuItems: PropTypes.oneOfType([
      PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string,
          disabled: PropTypes.bool,
          onClick: PropTypes.func
        })
      ),
      PropTypes.object
    ]),
    isOpen: PropTypes.bool,
    showTextCheckbox: PropTypes.bool
  };
  constructor(props) {
    super(props);
    this.state = {
      filterExpiredFeatures: false,
      maxWidth: null
    };
    this.dropdownRef = React.createRef();
  }

  componentDidMount() {
    window.addEventListener("resize", this.updateWidths);
    this.updateWidths();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWidths);
  }

  componentDidUpdate(prevProps) {
    if (this.props.isOpen && !prevProps.isOpen) {
      this.updateWidths();
    }
  }

  updateWidths = () => {
    if (this.props.isOpen) {
      const SearchInputWrapper = document.querySelector(".SearchInputWrapper");
      const DropdownWrapper = document.querySelector(".DropdownWrapper");

      if (SearchInputWrapper && DropdownWrapper) {
        const SearchInputWrapperWidth = SearchInputWrapper.offsetWidth;
        const DropdownWrapperWidth = DropdownWrapper.offsetWidth;
        this.setState({
          maxWidth: SearchInputWrapperWidth + DropdownWrapperWidth + 4
        });
      }
    }
  };

  getMaxWidth = () => {
    const { maxWidth } = this.state;
    return maxWidth;
  };

  getMenuItems = () => {
    const { menuItems } = this.props;
    if (!menuItems) return [];
    return menuItems;
  };

  getMenuIconType = () => {
    const { menuIconType } = this.props;
    return menuIconType ? menuIconType : "horizontal-handle";
  };

  menuButtonDisabled = () => {
    const { menuButtonDisabled } = this.props;
    return menuButtonDisabled === true;
  };

  handleToggleMenu = () => {
    const { handleToggleMenu } = this.props;
    if (!handleToggleMenu) return;
    handleToggleMenu();
  };

  isOpen = () => {
    const { isOpen } = this.props;
    return isOpen === true;
  };

  handleClickMenuItem = (e) => {
    const title = e.currentTarget.dataset.title;
    const menuItems = this.getMenuItems();
    const selectedMenuItem = menuItems.find(
      (menuItem) => menuItem.title === title
    );
    const { handleClickMenuItem } = this.props;
    if (!handleClickMenuItem) return;
    handleClickMenuItem(selectedMenuItem);
  };

  getMenuLabel = () => {
    const { menuLabel } = this.props;
    return menuLabel ? menuLabel : "";
  };

  setFilterExpiredFeatures = (e) => {
    const { onToggleTextCheckbox } = this.props;
    onToggleTextCheckbox(!this.state.filterExpiredFeatures);
    this.setState({ filterExpiredFeatures: !this.state.filterExpiredFeatures });
  };

  getFilterExpiredFeatures = () => {
    return this.state.filterExpiredFeatures === true;
  };

  getTextCheckBox = (row) => {
    const { showTextCheckbox } = this.props;
    if (showTextCheckbox && row.title === SEARCH_TYPE_TEXT && row.active) {
      return (
        <DropdownMenuForm>
          <DropdownMenuListInputs>
            <DropdownMenuListInputItem>
              <CheckboxInput
                attribute={"filterExpiredFeatures"}
                isValid={true}
                options={[
                  {
                    value: "true",
                    title: "Exclude expired",
                    checked: this.getFilterExpiredFeatures()
                  }
                ]}
                value={[this.getFilterExpiredFeatures()]}
                handleUpdateValue={this.setFilterExpiredFeatures}
              />
            </DropdownMenuListInputItem>
          </DropdownMenuListInputs>
        </DropdownMenuForm>
      );
    }
  };

  render() {
    return (
      <React.Fragment>
        {this.getMenuItems().length > 0 ? (
          <DropdownButton
            data-name={"DropdownButton"}
            styletype="contextMenu"
            type="button"
            onClick={this.handleToggleMenu}
            disabled={this.menuButtonDisabled()}
          >
            <Icon
              type={this.getMenuIconType()}
              iconColor={defaultTheme.agBlue}
              iconWidth="1.6em"
              iconHeight="1.6em"
            />
            <ButtonLabel type={"sr-only"}>{this.getMenuLabel()}</ButtonLabel>
          </DropdownButton>
        ) : null}
        <DropdownMenu
          ref={this.dropdownRef}
          data-name={"DropdownMenu"}
          type="contextMenu"
          isOpen={this.isOpen()}
          maxWidth={this.getMaxWidth()}
        >
          {this.getMenuItems().map((row) =>
            row.isTitle === undefined || !row.isTitle ? (
              row.onClick && row.title ? (
                <>
                  <DropdownMenuAction
                    key={row.title}
                    data-title={row.title}
                    onClick={this.handleClickMenuItem}
                    type="button"
                    styletype="dropdownAction"
                    disabled={row.disabled}
                    active={row.active}
                  >
                    {row.title}
                  </DropdownMenuAction>
                  {this.getTextCheckBox(row)}
                </>
              ) : row.link && row.title ? (
                <DropdownMenuLink
                  key={row.title}
                  to={row.link}
                  type="button"
                  styletype="dropdownAction"
                  disabled={row.disabled}
                  active={row.active}
                >
                  {row.title}
                </DropdownMenuLink>
              ) : null
            ) : (
              <DropdownMenuTitle key={row.title}>{row.title}</DropdownMenuTitle>
            )
          )}
        </DropdownMenu>
      </React.Fragment>
    );
  }
}
