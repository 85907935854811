import React from "react";
import { TableCellButton } from "../..";
import Icon from "../../Icon/Icon";
import PropTypes from "prop-types";

const CellButton = ({ button, cellItem }) => {
  const handleClickButton = () => {
    const { onClick } = button;
    if (!onClick) return;
    onClick(cellItem);
  };
  return (
    <TableCellButton onClick={handleClickButton} title={button.title}>
      {button.label}
      {button.icon && <Icon {...button.icon} />}
    </TableCellButton>
  );
};

CellButton.propTypes = {
  button: PropTypes.shape({
    /** Called on click of button */
    onClick: PropTypes.func,
    /** The title string for the button. This is useful if the button doesn't have a label (e.g. an icon only is visible) but still need some sort of accessible description for the button */
    title: PropTypes.string,
    /** The label to display in the button (if any) */
    label: PropTypes.string,
    /** The icon to display in the button (if any) */
    icon: PropTypes.shape({
      type: PropTypes.string,
      /** The css color (rgb, hex, color name) for the background of the icon */
      bgColor: PropTypes.string,
      /** The shape around the icon, e.g. round */
      bgShape: PropTypes.string,
      /** The width, in pixels, of the background */
      bgWidth: PropTypes.string,
      /** The height, in pixels, of the background */
      bgHeight: PropTypes.string,
      /** The height, in pixels, of the icon */
      iconHeight: PropTypes.string,
      /** The width, in pixels, of the icon */
      iconWidth: PropTypes.string,
      /** The css color (rgb, hex, color name) for the icon. Can also be a function that returns a string */
      iconColor: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
      /** If the icon should be spinning or not */
      spinning: PropTypes.bool,
      /** The path to the icon file, if not the standard. If the file does not exist, should default to a generic error icon*/
      path: PropTypes.string,
      /** the rotation number in degrees for the icon */
      rotation: PropTypes.number
    })
  }),
  /** The cell/cell value item related to the button */
  cellItem: PropTypes.object
};

export default CellButton;
