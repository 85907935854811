import appLogic from "./app";
import languageLogic from "./language";
import loginLogic from "./login";
import mapViewLogic from "./mapView";
import organisationsLogic from "./organisations";
import propertiesLogic from "./properties";
import propertyGroupsLogic from "./propertyGroups";
import userLogic from "./user";
import workflowsLogic from "./workflows";

export default [
  ...appLogic,
  ...languageLogic,
  ...loginLogic,
  ...mapViewLogic,
  ...organisationsLogic,
  ...propertiesLogic,
  ...propertyGroupsLogic,
  ...userLogic,
  ...workflowsLogic
];
