import React, { Component } from "react";
import {
  DEFAULT_SKETCH_POLYGON_SYMBOL,
  DEFAULT_SKETCH_POINT_SYMBOL,
  DEFAULT_SKETCH_POLYLINE_SYMBOL,
  TEMP_LAYER_PREFIX,
  ELEVATION_OFFSET,
  SKETCH_ELEVATION_MODE
} from "../../constants";
import { DrawingToolComponent } from "../../AgBoxUIKit/core";
import PropTypes from "prop-types";
import GraphicsLayer from "@arcgis/core/layers/GraphicsLayer";
import SketchViewModel from "@arcgis/core/widgets/Sketch/SketchViewModel";
import { isNullOrUndefined } from "../../App/utils";

/** A drawing tool component for sketching graphics. Makes use of the [ESRI SketchViewModel](https://developers.arcgis.com/javascript/latest/api-reference/esri-widgets-Sketch-SketchViewModel.html) */

export default class DrawingTool extends Component {
  static propTypes = {
    /** Whether to remove the graphics from the sketchLayer on mount */
    removeGraphicsOnUnmount: PropTypes.bool,
    /** The layer to attach the sketchTool to. If not provided, creates a temporary graphics layer */
    layer: PropTypes.object,
    /** The title for the sketch layer. */
    sketchLayerTitle: PropTypes.string,
    /** The drawing tools the user can choose from */
    tools: PropTypes.arrayOf(
      PropTypes.shape({
        /** The geometry type for the tool */
        param: PropTypes.oneOf([
          "polygon",
          "point",
          "polyline",
          "circle",
          "rectangle"
        ]),
        /** The label for the sketch tool selection button */
        label: PropTypes.string,
        /** Help text for the user to know when to use this type of tool */
        helpText: PropTypes.string,
        /** Custom action to run on click of the button */
        customAction: PropTypes.func
      })
    ),
    /** Custom [symbols](https://developers.arcgis.com/javascript/latest/api-reference/esri-symbols-Symbol.html) for the sketchTool  */
    customSymbols: PropTypes.shape({
      polygonSymbol: PropTypes.object,
      pointSymbol: PropTypes.object,
      lineSymbol: PropTypes.object
    }),
    /** Whether the user can update the graphic on click */
    canUpdate: PropTypes.bool,
    /** Whether the user can rotate the graphic. Only applicable if editing is enabled */
    canRotate: PropTypes.bool,
    /** Whether the user can scale the graphic. Only applicable if editing is enabled */
    canScale: PropTypes.bool,
    /** The default tool update type. Defaults to "reshape" */
    defaultUpdateTool: PropTypes.string,
    /** Whether the user can toggle between update tool types on click - only applicable if editing is enabled */
    toggleToolOnClick: PropTypes.bool,
    /** A function that is called when the sketch tool is first created */
    onSketchToolCreate: PropTypes.func,
    /** Event states to watch and call onComplete and onUpdate for */
    watchStates: PropTypes.arrayOf(PropTypes.string),
    /** Function called when sketch is completed */
    onComplete: PropTypes.func,
    /** Whether the user can draw multiple shapes at a time */
    canDrawMultiple: PropTypes.bool,
    /** Function called when sketch is cancelled */
    onCancel: PropTypes.func,
    /** Function called when sketch is updated */
    onUpdate: PropTypes.func,
    /** Function called when sketch is reset */
    onReset: PropTypes.func,
    /** Whether to keep the tool type selection on resetting the sketch tool*/
    keepSelectionOnReset: PropTypes.bool,
    /** Function called when geometry is reset */
    onGeometryReset: PropTypes.func,
    /** an array of custom button elements */
    customButtons: PropTypes.arrayOf(PropTypes.object),
    /** Whether to show the default reset button */
    showResetButton: PropTypes.bool,
    /** Text for the reset button */
    customResetText: PropTypes.string,
    /** If reset button is disabled */
    customResetDisabled: PropTypes.bool,
    defaultUpdateOptions: PropTypes.object,
    /** Whether to allow deleting of features from the sketch */
    canDelete: PropTypes.bool,
    /** Called on press of 'delete', 'backspace' or delete button, when a graphic is selected and canDelete is true */
    onDelete: PropTypes.func,
    /** Whether to show the delete button when deleting is allowed. If you want to make a custom button for deleting, set this to false */
    showDeleteButton: PropTypes.bool,
    /** Whether to show an 'add another' button */
    showAddButton: PropTypes.bool,
    /** Whether to enable snapping */
    snappingOptions: PropTypes.object
  };
  static defaultProps = {
    removeGraphicsOnUnmount: true,
    sketchLayerTitle: "TEMP_LAYER_SKETCH",
    canUpdate: true,
    canRotate: true,
    canScale: true,
    defaultUpdateTool: "reshape",
    toggleToolOnClick: false,
    watchStates: ["complete"],
    canDrawMultiple: false,
    keepSelectionOnReset: false,
    showResetButton: true,
    defaultUpdateOptions: {},
    canDelete: false,
    snappingOptions: {
      featureSources: [],
      featureEnabled: true,
      selfEnabled: true,
      enabled: true
    }
  };
  constructor(props) {
    super(props);
    this.state = {
      sketchTool: null,
      sketchLayer: null,
      sketchToolType: null,
      sketchGraphic: null,
      createEvent: null,
      updateEvent: null,
      cancelEvent: null,
      hitTestClickFunction: null
    };
  }
  componentDidMount = () => {
    this.setupSketchLayer();
  };
  componentWillUnmount() {
    const {
      sketchTool,
      cancelEvent,
      createEvent,
      updateEvent,
      deleteEvent,
      hitTestClickFunction
    } = this.state;
    if (cancelEvent) {
      cancelEvent.remove();
    }
    if (updateEvent) {
      updateEvent.remove();
    }
    if (createEvent) {
      createEvent.remove();
    }
    if (deleteEvent) {
      deleteEvent.remove();
    }
    if (hitTestClickFunction) {
      hitTestClickFunction.remove();
    }
    const { webMap, sketchLayerTitle } = this.props;
    if (sketchTool) {
      sketchTool.cancel();
      sketchTool.destroy();
    }

    const existingSketchLayer = webMap.layers.items
      .filter((layer) => {
        return layer && layer.title;
      })
      .find((layer) =>
        sketchLayerTitle
          ? layer.title === sketchLayerTitle
          : layer.title === `${TEMP_LAYER_PREFIX}_SKETCH`
      );
    if (existingSketchLayer && this.removeGraphicsOnUnmount()) {
      existingSketchLayer.removeAll();
    }
    this.removeKeyListener();
  }

  setupKeyListener = () => {
    document.addEventListener("keydown", this.handleKeydown);
  };

  removeKeyListener = () => {
    const { canDelete } = this.props;
    if (!canDelete) return;
    document.removeEventListener("keydown", this.handleKeydown);
  };

  handleDeleteSketch = () => {
    const { sketchTool, sketchLayer } = this.state;
    const { onDelete } = this.props;
    if (!sketchTool || !sketchLayer) return;
    const { updateGraphics } = sketchTool;
    if (!updateGraphics.items.length) return;
    sketchLayer.remove(updateGraphics.items[0]);
    if (onDelete) onDelete();
    sketchTool.delete();
    this.forceUpdate();
  };

  handleKeydown = (e) => {
    if (e.key !== "Delete" && e.key !== "Backspace") return;
    this.handleDeleteSketch();
  };
  removeGraphicsOnUnmount = () => {
    const { removeGraphicsOnUnmount } = this.props;
    return !isNullOrUndefined(removeGraphicsOnUnmount)
      ? removeGraphicsOnUnmount
      : true;
  };
  getWatchStates = () => {
    const { watchStates } = this.props;
    return watchStates ? watchStates : ["complete"];
  };
  handleReset = () => {
    const { onReset, tools, keepSelectionOnReset } = this.props;
    const { sketchLayer, sketchTool, sketchToolType } = this.state;
    if (sketchLayer.graphics.items.length > 0) {
      sketchLayer.removeAll();
    }
    let newSketchToolType = sketchToolType;
    if (tools && tools.length === 1) {
      sketchTool.create(tools[0].param);
    } else if (keepSelectionOnReset && sketchToolType) {
      sketchTool.create(sketchToolType);
    } else {
      newSketchToolType = null;
      sketchTool.cancel();
    }
    this.setState({
      sketchToolType: newSketchToolType,
      sketchGraphic: null
    });
    if (!onReset) return;
    onReset();
  };
  handleSketchCancel = async (event) => {
    if (!event.aborted || event.state !== "cancel") return;
    this.forceUpdate();
    this.setState({
      sketchGraphic: null
    });
    const { onCancel } = this.props;
    if (onCancel) {
      onCancel(event);
    }
    const { resetAfterCancel } = this.props;
    if (resetAfterCancel || resetAfterCancel === undefined) {
      this.handleReset();
    }
  };
  handleSketchUpdate = (event) => {
    if (event.state === "start") {
      this.forceUpdate();
    }
    if (event.aborted) {
      return this.handleSketchCancel(event);
    }
    const watchStates = this.getWatchStates();
    if (watchStates.indexOf(event.state) === -1) return;
    const { onUpdate } = this.props;
    if (onUpdate) {
      onUpdate(event);
    }
    if (event.state === "complete") {
      this.setState({
        sketchGraphic: event.graphics[0]
      });
    }
  };
  handleSketchComplete = (event) => {
    const watchStates = this.getWatchStates();
    if (event.aborted || event.state === "cancel") {
      return this.handleSketchCancel(event);
    }
    if (watchStates.indexOf(event.state) === -1) return;
    const { onComplete, canDrawMultiple } = this.props;
    const { sketchTool, sketchToolType } = this.state;
    onComplete(event);
    if (event.state === "complete") {
      const { graphic } = event;
      this.setState({
        sketchGraphic: graphic
      });

      if (canDrawMultiple) {
        sketchTool.create(sketchToolType);
      }
    }
  };
  setupSketchTool = () => {
    const {
      mapView,
      tools,
      customSymbols,
      onSketchToolCreate,
      onCreate,
      canDelete,
      onDelete,
      defaultCreateOptions = {},
      snappingOptions
    } = this.props;
    const { sketchLayer } = this.state;
    const sketchTool = new SketchViewModel({
      view: mapView,
      layer: sketchLayer,
      polygonSymbol:
        customSymbols && customSymbols.polygonSymbol
          ? customSymbols.polygonSymbol
          : DEFAULT_SKETCH_POLYGON_SYMBOL,
      pointSymbol:
        customSymbols && customSymbols.pointSymbol
          ? customSymbols.pointSymbol
          : DEFAULT_SKETCH_POINT_SYMBOL,
      polylineSymbol:
        customSymbols && customSymbols.lineSymbol
          ? customSymbols.lineSymbol
          : DEFAULT_SKETCH_POLYLINE_SYMBOL,
      updateOnGraphicClick: false,
      defaultCreateOptions: { ...defaultCreateOptions, hasZ: false },
      defaultUpdateOptions: {
        ...this.props.defaultUpdateOptions,
        enableZ: false,
        enableRotation: this.props.canRotate ? this.props.canRotate : true,
        enableScaling: this.props.canScale ? this.props.canScale : true,
        tool: this.props.defaultUpdateTool
          ? this.props.defaultUpdateTool
          : "reshape",
        toggleToolOnClick:
          this.props.toggleToolOnClick !== undefined
            ? this.props.toggleToolOnClick
            : false
      },
      snappingOptions: {
        ...snappingOptions
      }
    });
    const createEvent = sketchTool.on("create", this.handleSketchComplete);
    const updateEvent = sketchTool.on("update", this.handleSketchUpdate);
    const cancelEvent = sketchTool.on("cancel", this.handleSketchCancel);
    const deleteEvent = onDelete ? sketchTool.on("delete", onDelete) : null;
    const hitTestClickFunction =
      this.props.canUpdate === true || canDelete === true
        ? mapView.on("click", (event) => {
            if (sketchLayer.graphics.length <= 0) return;
            mapView.hitTest(event).then((response) => {
              var result = response.results.filter((item) => {
                return item.layer === sketchLayer;
              })[0];

              if (result) {
                sketchTool.update(result.graphic, {
                  tool:
                    result.graphic.geometry.type === "point"
                      ? "transform"
                      : this.props.defaultUpdateTool
                      ? this.props.defaultUpdateTool
                      : "reshape"
                });
              } else if (
                sketchTool.state === "active" &&
                !result &&
                (!tools ||
                  !tools.find((tool) => tool.param === sketchTool.activeTool))
              ) {
                sketchTool.complete();
              }
            });
          })
        : null;
    if (
      tools &&
      tools.length === 1 &&
      isNullOrUndefined(this.props.existingSearchGraphic)
    ) {
      sketchTool.create(tools[0].param);
      this.setState({
        sketchToolType: tools[0].param
      });
      if (onCreate) onCreate(tools[0].param);
    }
    this.setState({
      sketchTool,
      createEvent,
      updateEvent,
      cancelEvent,
      deleteEvent,
      hitTestClickFunction
    });
    if (onSketchToolCreate) {
      onSketchToolCreate(sketchTool);
    }
    if (canDelete) this.setupKeyListener();
  };
  setupSketchLayer = () => {
    let sketchLayer;
    const { layer, has3D } = this.props;
    if (layer) {
      sketchLayer = layer;
    } else {
      const { webMap, sketchLayerTitle } = this.props;
      const existingSketchLayer = webMap.layers.items.find((layer) =>
        sketchLayerTitle
          ? layer.title === sketchLayerTitle
          : layer.title === `${TEMP_LAYER_PREFIX}_SKETCH`
      );
      sketchLayer = existingSketchLayer
        ? existingSketchLayer
        : new GraphicsLayer({
            title: sketchLayerTitle
              ? sketchLayerTitle
              : `${TEMP_LAYER_PREFIX}_SKETCH`
          });

      if (!existingSketchLayer) {
        webMap.add(sketchLayer);
      } else {
        sketchLayer.removeAll();
        sketchLayer.visible = true;
      }
    }
    if (has3D) {
      sketchLayer.elevationInfo = {
        mode: SKETCH_ELEVATION_MODE,
        offset: ELEVATION_OFFSET
      };
    }

    if (this.props.saveSearchGraphic && this.props.existingSearchGraphic) {
      sketchLayer.add(this.props.existingSearchGraphic);
    }

    this.setState({ sketchLayer }, () => {
      this.setupSketchTool();
    });
  };
  handleSelectSketchTool = (e) => {
    const tool = e.currentTarget.dataset.id;
    const { onCreate } = this.props;
    const { sketchLayer, sketchTool } = this.state;
    if (sketchLayer) {
      sketchLayer.removeAll();
    }
    if (sketchTool) {
      sketchTool.cancel();
    }

    this.setState({ sketchToolType: tool }, () => {
      if (sketchTool) sketchTool.create(tool);
      if (onCreate) onCreate(tool);
    });
  };
  hasToolPermission = (toolType) => {
    const { permissions } = this.props;
    if (!permissions) return true;
    return permissions.some((item) => item === toolType);
  };
  handleResetEdits = () => {
    const { onGeometryReset } = this.props;
    if (!onGeometryReset) return;
    onGeometryReset();
  };
  whenNoDrawing = () => {
    const { sketchLayer } = this.state;
    if (!sketchLayer) return true;
    const { webMap } = this.props;
    const currentSketchLayer = webMap.layers.items.find(
      (layer) => layer.title === sketchLayer.title
    );
    return currentSketchLayer
      ? currentSketchLayer.graphics.items.length === 0
      : true;
  };
  getResetText = () => {
    const {
      customResetText,
      labels: { RESET_SKETCH_LABEL }
    } = this.props;
    return customResetText ? customResetText : RESET_SKETCH_LABEL;
  };

  getDeleteText = () => {
    const {
      labels: { DELETE_SKETCH_LABEL },
      deleteLabel
    } = this.props;
    return deleteLabel || DELETE_SKETCH_LABEL;
  };

  getAvailableTools = () => {
    const { tools } = this.props;
    if (!tools) return [];
    const { sketchToolType } = this.state;
    const availableTools = tools
      .map((tool) => ({
        label: tool.label || tool.param,
        action: tool.customAction || this.handleSelectSketchTool,
        param: tool.param,
        styletype: tool.styletype
          ? tool.styletype
          : sketchToolType === tool.param
          ? "primary"
          : "tertiary",
        helpText: tool.helpText
      }))
      .filter((tool) => this.hasToolPermission(tool.param));
    return availableTools;
  };
  getHelpTexts = () => {
    const helpTexts = this.getAvailableTools()
      .map((tool) => tool.helpText)
      .filter((tool) => tool != undefined);
    return helpTexts;
  };
  resetDisabled = () => {
    const { customResetDisabled } = this.props;
    return !isNullOrUndefined(customResetDisabled)
      ? customResetDisabled
      : this.whenNoDrawing();
  };
  getShowDeleteButton = () => {
    const { canDelete, showDeleteButton } = this.props;
    const { sketchTool } = this.state;
    if (!canDelete || !sketchTool || !showDeleteButton) return false;
    return sketchTool.updateGraphics && sketchTool.updateGraphics.items.length
      ? true
      : false;
  };

  getAddButtonText = () => {
    const { GENERIC_ADD_MULTIPLE_TEXT_LABEL } = this.props.labels;
    return GENERIC_ADD_MULTIPLE_TEXT_LABEL;
  };

  handleAddSketch = () => {
    const { showAddButton } = this.props;
    const { sketchTool, sketchToolType } = this.state;
    if (!showAddButton || !sketchToolType || !sketchTool) return;
    sketchTool.create(sketchToolType);
  };

  getShowAddButton = () => {
    const { showAddButton } = this.props;
    const { sketchLayer } = this.state;
    if (
      !showAddButton ||
      !sketchLayer ||
      !sketchLayer.graphics ||
      !sketchLayer.graphics.items ||
      !sketchLayer.graphics.items.length
    )
      return false;
    return true;
  };
  getDrawingToolProps = () => {
    const { showResetButton, customButtons, toolHeading } = this.props;
    return {
      showResetButton,
      customButtons,
      toolHeading,
      tools: this.getAvailableTools(),
      helpTexts: this.getHelpTexts(),
      resetDisabled: this.resetDisabled(),
      resetText: this.getResetText(),
      handleReset: this.handleReset,
      showDeleteButton: this.getShowDeleteButton(),
      deleteText: this.getDeleteText(),
      handleDelete: this.handleDeleteSketch,
      showAddButton: this.getShowAddButton(),
      addText: this.getAddButtonText(),
      handleAddSketch: this.handleAddSketch
    };
  };

  render() {
    return <DrawingToolComponent {...this.getDrawingToolProps()} />;
  }
}
