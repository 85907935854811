import React, { Component } from "react";
import {
  FeatureSearchWrapper,
  FeatureSearchContainer,
  FeatureSearchButtonsWrap
} from "../../AgBoxUIKit/plugin/layout";
import {
  SEARCH_TYPE_TEXT,
  FEATURE_TEXT_SEARCH,
  FEATURE_AREA_SEARCH,
  SEARCH_TYPE_AREA,
  SEARCH_TYPE_BLOCK,
  FEATURE_BLOCK_SEARCH,
  SEARCH_TYPE_CROP,
  FEATURE_CROP_SEARCH,
  SEARCH_TYPE_LOCATION,
  FEATURE_LOCATION_SEARCH,
  DELETEDDATE_QUERY_EXPRESSION,
  SEARCH_TYPE_DATE
} from "../../constants";
import moment from "moment";
import TextSearch from "./TextSearch/TextSearch";
import SelectGeometrySearch from "./SelectGeometrySearch";
import AreaSearch from "./AreaSearch";
import LocationSearch from "./LocationSearch";
import ContextDropdown from "../ContextDropdown";
import FeatureFilter from "../FeatureFilter";

export default class FeatureSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchGeometry: null,
      textSearch: this.props.activeTextSearch,
      filterExpiredFeatures: false
    };
    this.controller = new AbortController();
    this.searchTimer = null;
  }

  componentDidMount() {
    this.setInitialSearchType();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.activeTextSearch !== this.props.activeTextSearch ||
      (prevProps.selectedSearchType !== this.props.selectedSearchType &&
        this.props.selectedSearchType === FEATURE_TEXT_SEARCH)
    ) {
      this.handleOnSearchStart({ geometry: null, dateExpression: null });
    }
    if (prevProps.activeTextSearch !== this.props.activeTextSearch) {
      this.setState({
        textSearch: this.props.activeTextSearch
      });
    }
  }

  setInitialSearchType = () => {
    const searchTypes = this.allowedSearchTypes();
    const selectedSearchType = this.getSelectedSearchType();
    if (
      searchTypes.length === 0 ||
      (selectedSearchType &&
        searchTypes.find((searchType) => searchType === selectedSearchType))
    )
      return;
    const initialType = searchTypes[0];
    const { updateSearchType } = this.props;
    if (updateSearchType) updateSearchType(initialType);
  };
  getLanguageLabel = (label) => {
    const { labels } = this.props;
    return labels[label] ? labels[label] : "";
  };
  customSearches = () => {
    const { customSearches } = this.props;
    return customSearches ? customSearches : [];
  };

  searchTypes = () => {
    const customSearches = this.customSearches();
    const searchTypes = [
      {
        title: this.getLanguageLabel("SEARCH_TYPE_TEXT"),
        id: SEARCH_TYPE_TEXT,
        type: FEATURE_TEXT_SEARCH
      },
      {
        title: this.getLanguageLabel("SEARCH_TYPE_AREA"),
        id: SEARCH_TYPE_AREA,
        type: FEATURE_AREA_SEARCH
      },
      {
        title: this.getLanguageLabel("SEARCH_TYPE_BLOCK"),
        id: SEARCH_TYPE_BLOCK,
        type: FEATURE_BLOCK_SEARCH
      },
      {
        title: this.getLanguageLabel("SEARCH_TYPE_CROP"),
        id: SEARCH_TYPE_CROP,
        type: FEATURE_CROP_SEARCH
      },
      {
        title: this.getLanguageLabel("SEARCH_TYPE_LOCATION"),
        id: SEARCH_TYPE_LOCATION,
        type: FEATURE_LOCATION_SEARCH
      },
      ...customSearches
    ];

    const allowedSearchTypes = this.allowedSearchTypes().map((type) =>
      type.toLowerCase()
    );
    return searchTypes.filter(
      (type) => allowedSearchTypes.indexOf(type.id.toLowerCase()) !== -1
    );
  };

  allowedSearchTypes = () => {
    const { searchTypes } = this.props;
    return searchTypes ? searchTypes : [FEATURE_TEXT_SEARCH];
  };

  activeTextSearch = () => {
    const { textSearch } = this.state;
    return textSearch;
  };

  getHelpText = (type) => {
    if (!type) return "";
    if (type === FEATURE_AREA_SEARCH) {
      return this.getLanguageLabel("DEFAULT_SEARCH_AREA_TOOLTIP_LABEL");
    } else if (type === FEATURE_BLOCK_SEARCH) {
      return this.getLanguageLabel("DEFAULT_SEARCH_BLOCK_TOOLTIP_LABEL");
    } else if (type === FEATURE_CROP_SEARCH) {
      return this.getLanguageLabel("DEFAULT_SEARCH_CROP_TOOLTIP_LABEL");
    }
  };

  filterDateIsNotToday = (value) => {
    const { activeDateSearchValue } = this.props;
    if (!value && !activeDateSearchValue) return false;
    const dateValue = value ? value : activeDateSearchValue;
    const today = moment(new Date()).format("DD/MM/YYYY");
    const formattedDateFilterValue = moment(dateValue).format("DD/MM/YYYY");
    return today !== formattedDateFilterValue;
  };

  getSelectedSearchType = () => {
    const { selectedSearchType } = this.props;
    return selectedSearchType;
  };

  handleSelectSearchType = (searchType) => {
    const searchTypes = this.searchTypes();
    const activeSearch = this.getSelectedSearchType();
    if (searchType === activeSearch) {
      if (searchType === FEATURE_TEXT_SEARCH && !this.activeTextSearch())
        return;
      else if (searchType === FEATURE_TEXT_SEARCH && this.activeTextSearch()) {
        const { updateTextSearch } = this.props;
        if (updateTextSearch) return updateTextSearch("");
      } else if (!this.state.searchGeometry) return;
    }
    const currentSearch = searchTypes.find(
      (search) => search.type === searchType
    );
    if (!currentSearch) return;

    const { updateSearchType } = this.props;
    if (updateSearchType) updateSearchType(searchType);

    this.setState({ searchGeometry: null });
  };

  createEventSearchDate = () => {
    const { activeDateSearchValue } = this.props;
    const eventDate = moment(activeDateSearchValue)
      .hour(0)
      .minute(0)
      .second(0)
      .format("YYYY-MM-DD HH:mm:ss");
    return eventDate;
  };

  createExpirySearchDate = () => {
    const { activeDateSearchValue } = this.props;
    const expiryDate = moment(activeDateSearchValue)
      .hour(23)
      .minute(59)
      .second(59)
      .format("YYYY-MM-DD HH:mm:ss");
    return expiryDate;
  };

  createDateQueryExpression = () => {
    const eventDate = this.createEventSearchDate();
    const expiryDate = this.createExpirySearchDate();
    const queryExpression = `${DELETEDDATE_QUERY_EXPRESSION} AND eventDate <= '${eventDate}' AND (expiryDate IS NULL OR expiryDate >= '${expiryDate}')`;
    return queryExpression;
  };

  hasDateSearch = () => {
    const { searchTypes } = this.props;
    if (!searchTypes) return false;
    return searchTypes.some(
      (type) => type.toLowerCase() === SEARCH_TYPE_DATE.toLowerCase()
    );
  };

  handleClearSearch = () => {
    const { onSearchCleared } = this.props;
    if (!onSearchCleared) return false;
    return onSearchCleared();
  };

  handleOnSearchStart = ({ geometry, dateExpression }) => {
    const { onSearchStart, activeDateSearchValue } = this.props;
    if (!onSearchStart) return false;
    let dateQueryExpression = dateExpression;
    if (activeDateSearchValue && !dateExpression) {
      dateQueryExpression = this.createDateQueryExpression();
    }

    let searchGeometry = this.state.searchGeometry;
    if (geometry !== undefined) {
      this.setState({
        searchGeometry: geometry
      });
      searchGeometry = geometry;
    }

    onSearchStart(searchGeometry, dateQueryExpression);
  };

  contextMenuItems = () => {
    const activeSearch = this.getSelectedSearchType();
    const searchTypes = this.searchTypes();
    return {
      Search: searchTypes.map((search) => ({
        title: search.title,
        onClick: () => this.handleSelectSearchType(search.type),
        disabled: search.type === activeSearch,
        active: search.type === activeSearch
      })),
      Actions: [
        {
          title: this.getLanguageLabel("RESET_LABEL"),
          onClick: () => {
            this.handleSelectSearchType(searchTypes[0].type);
          },
          disabled: false
        }
      ]
    };
  };

  showLocationSearch = () => {
    const searchTypes = this.searchTypes();
    const activeSearch = this.getSelectedSearchType();
    return (
      searchTypes.find(
        (searchType) => searchType.type === FEATURE_LOCATION_SEARCH
      ) !== undefined && activeSearch === FEATURE_LOCATION_SEARCH
    );
  };

  updateTextSearch = (textSearch) => {
    this.setState({
      textSearch
    });
    if (this.searchTimer) {
      clearTimeout(this.searchTimer);
    }
    const { updateTextSearch } = this.props;
    if (!updateTextSearch) return;
    this.searchTimer = setTimeout(() => {
      updateTextSearch(textSearch);
    }, 500);
  };

  textSearchProps = () => ({
    activeTextSearch: this.activeTextSearch(),
    onSearchCleared: this.handleClearSearch,
    onSearchStart: this.handleOnSearchStart,
    updateTextSearch: this.updateTextSearch,
    loading: this.whenLoading()
  });

  locationSearchProps = () => ({
    onSearchCleared: this.handleClearSearch,
    onSearchStart: this.handleOnSearchStart,
    onSearchResult: this.props.onSearchResult
  });

  geometrySearchProps = () => {
    const activeSearch = this.getSelectedSearchType();
    const { getLayerName } = this.props;
    return {
      searchDescription:
        activeSearch === FEATURE_CROP_SEARCH
          ? this.getLanguageLabel("CROP_SEARCH_DESC_LABEL")
          : this.getLanguageLabel("BLOCK_SEARCH_DESC_LABEL"),
      layerTitle:
        activeSearch === FEATURE_CROP_SEARCH
          ? getLayerName("crops")
          : getLayerName("basePoly"),
      onSearchCleared: this.handleClearSearch,
      onSearchStart: this.handleOnSearchStart,
      loading: this.whenLoading(),
      helpText: this.getHelpText(activeSearch),
      clearLabel:
        activeSearch === FEATURE_CROP_SEARCH
          ? this.getLanguageLabel("CROP_SEARCH_CLEAR_LABEL")
          : this.getLanguageLabel("BLOCK_SEARCH_CLEAR_LABEL")
    };
  };

  areaSearchProps = () => ({
    saveSearchGraphic: this.props.saveSearchGraphic === false ? false : true,
    updateWorkflowState: this.props.updateWorkflowState,
    workflowState: this.props.workflowState,
    onSearchStart: this.handleOnSearchStart,
    onSearchCleared: this.handleClearSearch,
    loading: this.whenLoading()
  });

  getAriaLabel = () => {
    return this.getLanguageLabel("SEARCH_FOR_FEATURES_LABEL");
  };

  searchForm = () => {
    const activeSearch = this.getSelectedSearchType();
    switch (activeSearch) {
      case FEATURE_CROP_SEARCH:
      case FEATURE_BLOCK_SEARCH:
        return (
          <SelectGeometrySearch
            data-name="GeometrySearch"
            {...this.geometrySearchProps()}
          />
        );
      case FEATURE_AREA_SEARCH:
        return (
          <AreaSearch data-name="AreaSearch" {...this.areaSearchProps()} />
        );
      case FEATURE_LOCATION_SEARCH:
        return (
          <LocationSearch
            data-name="LocationSearch"
            {...this.locationSearchProps()}
          />
        );
      case FEATURE_TEXT_SEARCH:
        return (
          <TextSearch
            data-name="TextSearch"
            {...this.textSearchProps()}
            ariaLabel={this.getAriaLabel()}
          />
        );
      default:
        return null;
    }
  };

  whenLoading = () => {
    const { loading } = this.props;
    return loading === true;
  };

  displayItems = () => {
    const { features } = this.props;
    return features ? features : [];
  };

  whenShowFeatureFilter = () => {
    const { showFeatureFilter, filterOptions } = this.props;
    return showFeatureFilter && filterOptions.length > 0 ? true : false;
  };

  allActiveFilters = () => {
    const { activeFilters } = this.props;
    return activeFilters ? activeFilters : [];
  };

  onChangeFilter = (activeFilters) => {
    this.setState({
      activeFilters
    });
  };

  showTextCheckbox = () => {
    const { showTextCheckbox } = this.props;
    return showTextCheckbox === true;
  };

  onToggleTextCheckbox = (filterExpiredFeatures) => {
    const { updateWorkflowState, handleToggleTextCheckbox } = this.props;
    handleToggleTextCheckbox();
    this.setState({
      filterExpiredFeatures
    });
    updateWorkflowState({
      filterExpiredFeatures: filterExpiredFeatures === true
    });
  };

  render() {
    return (
      (this.searchForm() || this.whenShowFeatureFilter()) && (
        <FeatureSearchWrapper data-name={"FeatureSearchWrapper"}>
          <FeatureSearchContainer data-name={"FeatureSearchContainer"}>
            {this.searchForm()}
            <FeatureSearchButtonsWrap>
              {this.searchForm() && this.searchTypes().length > 1 && (
                <ContextDropdown
                  menuItems={this.contextMenuItems()}
                  loading={this.whenLoading()}
                  showTextCheckbox={this.showTextCheckbox()}
                  onToggleTextCheckbox={this.onToggleTextCheckbox}
                />
              )}
              {!this.whenLoading() && this.whenShowFeatureFilter() && (
                <FeatureFilter
                  activeFilters={this.allActiveFilters()}
                  filterOptions={this.props.filterOptions}
                  handleResetFilter={this.props.handleResetFilter}
                  onChangeFilter={this.onChangeFilter}
                />
              )}
            </FeatureSearchButtonsWrap>
          </FeatureSearchContainer>
        </FeatureSearchWrapper>
      )
    );
  }
}
