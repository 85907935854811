import styled from "styled-components/macro";
import { Link } from "@reach/router";
import { deviceHeight, loaderSizeRegular } from "../../";
import checkboxUnselected from "../../icons/checkbox-unselected-icon.svg";
import checkboxSelected from "../../icons/checkbox-selected-icon.svg";
import checkboxIndeterminate from "../../icons/checkbox-indeterminate-icon.svg";
// @ts-ignore
export const IconSymbol = styled.span`
  background-color: ${(props) => props.iconColor || props.theme.agBlack};
  mask-image: ${(props) => {
    try {
      const icon = `${props.type.replace(/ /g, "-")}-icon.svg`;
      const url = props.path
        ? `${props.path}${icon}`
        : `${require(`../../icons/${icon}`)}`;
      return `url("${url}")`;
    } catch (e) {
      return `url("${require(`../../icons/error-generic-icon.svg`)}")`;
    }
  }};
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  width: ${(props) => props.width || props.theme.iconWidth};
  height: ${(props) => props.height || props.theme.iconHeight};
  animation-name: ${(props) => {
    if (props.spinning) return `ckw`;
    return "none";
  }};
  animation-duration: ${(props) => {
    if (props.spinning) return `3s`;
    return "none";
  }};
  animation-iteration-count: infinite;
  transform-origin: ${(props) => {
    if (props.spinning) return `50% 50%`;
    return "none";
  }};
  display: ${(props) => {
    if (props.spinning) return `inline-block`;
    return "block";
  }};
  transform: ${(props) => {
    if (props.rotation !== null) return `rotate(${props.rotation}deg)`;
  }};
  transition: ${(props) => {
    if (props.rotation !== null) return "transform 0.2s ease-in-out";
  }};
`;
IconSymbol.displayName = "IconSymbol";

export const IconBackground = styled.span`
  background-color: ${(props) => props.backgroundColor || "transparent"};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: ${(props) => (props.shape === "round" ? "50%" : "0")};
  width: ${(props) =>
    props.invalid ? "0" : props.width || props.theme.iconWidth};
  height: ${(props) => props.height || props.theme.iconHeight};
  flex-shrink: 0;
`;
IconBackground.displayName = "IconBackground";
export const ButtonLabel = styled.span`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: ${(props) =>
    props.type === "pagination" ? "center" : "flex-start"};
  color: ${(props) => {
    if (props.type === "accordianButton") return props.theme.agOffBlack;
    if (props.type === "packageContainer") return props.theme.agOffBlack;
    return props.theme.agWhite;
  }};
  text-align: ${(props) => {
    if (
      props.type === "sideBar" ||
      props.type === "org-heading" ||
      props.type === "accordianButton" ||
      props.type === "packageContainer"
    )
      return "left";
    return "center";
  }};
  font-weight: ${(props) => {
    if (props.type === "sideBar") return props.theme.baseFontWeight;
    if (props.type === "org-heading") return props.theme.baseFontWeightHeavy;
    return props.theme.baseFontWeight;
  }};
  white-space: ${(props) => {
    if (props.type === "sideBar") return "normal";
    return "nowrap";
  }};
  padding-left: ${(props) => {
    if (
      props.type === "sideBar" ||
      props.type === "org-heading" ||
      props.type === "accordianButton"
    )
      return "10px";
    if (props.type === "packageContainer") return "5px";
    return "0";
  }};
  position: ${(props) => {
    if (props.type === "sr-only") return "absolute";
    return "initial";
  }};
  left: ${(props) => {
    if (props.type === "sr-only") return "-10000px";
    return "initial";
  }};

  top: ${(props) => {
    if (props.type === "sr-only") return "0";
    return "initial";
  }};
  ${IconBackground} {
    margin-left: 1em;
  }
`;
ButtonLabel.displayName = "ButtonLabel";

export const SidebarControllerWrapper = styled.div`
  display: flex;
`;
SidebarControllerWrapper.displayName = "SidebarControllerWrapper";

export const Button = styled.button`
  position: ${(props) => {
    if (props.styletype === "sidebarCloseButton") return "fixed";
    return "relative";
  }};

  top: ${(props) => {
    if (props.styletype === "sidebarCloseButton") return "0em";
  }};

  left: ${(props) => {
    if (props.styletype === "sidebarCloseButton") return "18.75em";
  }};

  display: ${(props) => {
    if (props.styletype === "orgItem") return "flex";
    if (props.styletype === "sideBar") return "flex";
    if (props.styletype === "profile") return "flex";
    if (props.styletype === "iconButton") return "flex";
    if (props.styletype === "homeLink") return "flex";
    if (props.styletype === "orgSubMenuController") return "flex";
    if (props.styletype === "propertyListing") return "flex";
    if (props.styletype === "accordianButton") return "flex";
    if (props.styletype === "property-favourite") return "flex";
    return "inline-block";
  }};

  padding: ${(props) => {
    if (props.size === "large") return "0.5rem 1rem";
    if (props.styletype === "orgItem") return "1em";
    if (props.styletype === "sideBar") return "0 0 0 5px";
    if (props.styletype === "profile") return "0";
    if (props.styletype === "orgSubMenuController") return "0";
    if (props.styletype === "iconButton") return "0";
    if (props.styletype === "homeLink") return "0";
    if (props.styletype === "clearSearch") return "0";
    if (props.styletype === "propertyListing") return "0.5rem 0.3rem";
    if (props.styletype === "accordianButton") return "0.5rem 0.4rem";
    if (props.styletype === "filter") return "0.375rem 0.275rem";
    if (props.styletype === "contextMenu") return "0";
    if (props.styletype === "property-favourite") return "0";
    if (props.styletype === "inline") return "0";
    if (props.styletype === "sidebarIcon") return "0";
    if (props.styletype === "measureWidgetButton") return "0.375rem 0rem";
    return "0.375rem 1rem";
  }};

  width: ${(props) => {
    if (props.styletype === "orgSubMenuController") return "100%";
    if (props.styletype === "propertyListing") return "100%";
    if (props.styletype === "sideBar") return "100%";
    if (props.styletype === "profile") return "100%";
    if (props.styletype === "homeLink")
      return `calc(${props.theme.menuWidthOpen} - ${props.theme.imgWidth})`;
    if (props.styletype === "accordianButton") return "100%";
    if (props.styletype === "pagination") return "100%";
    if (props.styletype === "property-favourite") return "2em";
    if (props.styletype === "sidebarIcon") return "4em";
    return "auto";
  }};

  max-width: ${(props) => {
    if (props.styletype === "pagination") return "32px";
    if (props.styletype === "pagination-first") return "32px";
    if (props.styletype === "orgItem") return "30%";
    return "auto";
  }};

  min-width: ${(props) => {
    if (props.styletype === "measureWidgetButton") return "0";
    return "auto";
  }};

  color: ${(props) => {
    if (props.styletype === "selectOrgCancelButton") return props.theme.agWhite;
    if (props.styletype === "primary") return props.theme.agWhite;
    if (props.styletype === "secondary") return props.theme.agBlue;
    if (props.styletype === "tertiary") return props.theme.agBlue;
    if (props.styletype === "measureWidgetButton") return props.theme.agBlue;
    if (props.styletype === "sideBar") return props.theme.agWhite;
    if (props.styletype === "profile") return props.theme.agWhite;
    if (props.styletype === "orgItem") return props.theme.agWhite;
    if (props.styletype === "propertyListing") return props.theme.agWhite;
    if (props.styletype === "accordianButton") return props.theme.agOffBlack;
    return props.theme.agBlue;
  }};

  border: ${(props) => {
    if (props.styletype === "primary") return `1px solid ${props.theme.agBlue}`;
    if (props.styletype === "secondary")
      return `1px solid ${props.theme.agBlue}`;
    if (props.styletype === "tertiary")
      return `1px solid ${props.theme.agBlue}`;
    if (props.styletype === "measureWidgetButton")
      return `1px solid ${props.theme.agBlue}`;
    if (props.styletype === "orgItem")
      return `1px solid ${props.theme.agTransparentGray}`;
    return "none";
  }};
  border-radius: ${(props) => {
    if (props.styletype === "orgItem")
      return `${props.theme.baseBorderRadiusStandard}`;
    if (props.styletype === "orgSubMenuController") return "0.05em";
    return 0;
  }};

  background-color: ${(props) => {
    if (props.styletype === "selectOrgCancelButton") return props.theme.agBlue;
    if (props.styletype === "primary") return props.theme.agBlue;
    if (props.styletype === "secondary") return props.theme.agWhite;
    if (props.styletype === "tertiary") return props.theme.agWhite;
    if (props.styletype === "measureWidgetButton") return props.theme.agWhite;
    if (props.styletype === "orgSubMenuController") return props.theme.agBlue;
    if (props.styletype === "sideBar" && props.active)
      return props.theme.agGreen;
    if (props.styletype === "accordianButton") return props.theme.agOffWhite;
    if (props.styletype === "pagination") return "transparent";
    if (props.styletype === "sidebarCloseButton") return "rgba(0,0,0,0.4)";
    return "none";
  }};
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all 0.05s linear;
  transition: all 0.05s linear;
  cursor: pointer;
  white-space: ${(props) => {
    if (props.styletype === "orgItem") return "unset";
    if (props.styletype === "accordianButton") return "unset";
    return "normal";
  }};

  font-size: ${(props) => {
    if (props.styletype === "primary") return props.theme.fontSizeBase;
    if (props.styletype === "secondary") return props.theme.fontSizeSmall;
    if (props.styletype === "tertiary") return props.theme.fontSizeSmall;
    if (props.styletype === "measureWidgetButton")
      return props.theme.fontSizeSmall;
    if (props.styletype === "sideBar") return props.theme.fontSizeBase;
    if (props.styletype === "pagination") return props.theme.fontSizeSmall;
    if (props.styletype === "orgSubMenuController")
      return props.theme.fontSizeSmall;
    return props.theme.fontSizeBase;
  }};

  letter-spacing: ${(props) => {
    if (props.styletype === "sideBar" || props.styletype === "profile")
      return "0.02em";
  }};

  height: ${(props) => {
    if (props.styletype === "selectOrgCancelButton") return "2.5em";
    if (props.styletype === "primary") return "auto";
    if (props.styletype === "secondary") return "auto";
    if (props.styletype === "tertiary") return "auto";
    if (props.styletype === "measureWidgetButton") return "auto";
    if (props.styletype === "orgItem") return "15.6em";
    if (props.styletype === "profile") return "auto";
    if (props.styletype === "orgSubMenuController") return "2.5em";
    if (props.styletype === "propertyListing") return "auto";
    if (props.styletype === "clearSearch") return "2.5em";
    if (props.styletype === "iconButton") return "auto";
    if (props.styletype === "sidebarCloseButton") return "3.75em";
    if (props.styletype === "homeLink") return "auto";
    if (props.styletype === "contextMenu") return "2.25em";
    if (props.styletype === "accordianButton") return "auto";
    if (props.styletype === "pagination") return "2em";
    if (props.styletype === "pagination-first") return "2em";
    if (props.styletype === "property-favourite") return "auto";
    if (props.styletype === "inline") return "auto";
    if (props.styletype === "sidebarIcon") return "auto";

    return props.theme.imgWidth;
  }};

  line-height: ${(props) => props.theme.lineHeightBase};
  opacity: ${(props) => (props.appearsdisabled || props.disabled ? ".5" : "1")};
  margin: ${(props) => {
    const { styletype } = props;
    if (styletype === "orgSubMenuController") return "0 0 5px 0";
    if (styletype === "orgItem") return "0 0 15px 0";
    if (styletype === "property-favourite") return "0 0.5em 0 0";
    if (styletype === "profile") return "0 0 0.5em 0";
    return 0;
  }};

  justify-content: ${(props) => {
    if (props.styletype === "orgItem") return "center";
    if (props.styletype === "property-favourite") return "center";
    if (props.styletype === "sideBar") return "flex-start";
    if (props.styletype === "profile") return "flex-start";
    if (props.styletype === "propertyListing") return "flex-start";
    if (props.styletype === "accordianButton") return "flex-start";
  }};

  align-items: ${(props) => {
    if (props.styletype === "orgSubMenuController") return "center";
    if (props.styletype === "homeLink") return "center";
    if (props.styletype === "sideBar" || props.styletype === "profile")
      return "center";
    if (props.styletype === "accordianButton") return "center";
    if (props.styletype === "orgItem") return "center";
    if (props.styletype === "property-favourite") return "center";
  }};

  flex-grow: ${(props) => {
    if (props.styletype === "homeLink") return "99";
    if (props.styletype === "orgItem") return "1";
  }};

  flex-basis: ${(props) => {
    if (props.styletype === "orgItem") return "30%";
  }};

  flex-direction: ${(props) =>
    props.styletype === "orgItem" ? "column" : "row"};

  &:hover {
    color: ${(props) => {
      if (props.styletype === "primary") return props.theme.agWhite;
      if (props.styletype === "secondary") return props.theme.agDarkBlue;
      if (props.styletype === "tertiary") return props.theme.agDarkBlue;
      if (props.styletype === "measureWidgetButton")
        return props.theme.agDarkBlue;
      if (props.styletype === "orgItem") return props.theme.agLightBlue;
      if (props.styletype === "propertyListing") return props.theme.agLightBlue;
      if (props.styletype === "accordianButton") return props.theme.agOffBlack;
      if (props.styletype === "pagination") return props.theme.agDarkBlue;
      if (props.styletype === "copy") return props.theme.agDarkBlue;
      if (props.disabled) return "initial";
      return props.theme.agWhite;
    }};
    background-color: ${(props) => {
      if (props.styletype === "primary") return props.theme.agDarkBlue;
      if (props.styletype === "secondary") return props.theme.agWhite;
      if (props.styletype === "tertiary") return props.theme.agWhite;
      if (props.styletype === "measureWidgetButton") return props.theme.agWhite;
      if (props.styletype === "orgItem") return props.theme.agWhite;
      if (props.styletype === "sideBar" || props.styletype === "profile")
        return props.theme.agBlue;
      if (props.styletype === "orgSubMenuController")
        return props.theme.agDarkBlue;
      if (props.styletype === "propertyListing") return "transparent";
      if (props.styletype === "iconButton") return props.theme.agBlue;
      if (props.styletype === "clearSearch") return "transparent";
      if (props.styletype === "accordianButton")
        return props.theme.agLightestGray;
      if (props.styletype === "filter") return props.theme.agLightestGray;
      if (props.styletype === "copy") return "transparent";
      if (props.styletype === "property-favourite") return "transparent";
      if (props.disabled) return "initial";
      return props.theme.agBlue;
    }};

    border: ${(props) => {
      if (props.styletype === "orgSubMenuController") return "none";
      if (props.styletype === "primary")
        return `1px solid ${props.theme.agDarkBlue}`;
      if (props.styletype === "secondary")
        return `1px solid ${props.theme.agDarkBlue}`;
      if (props.styletype === "tertiary")
        return `1px solid ${props.theme.agDarkBlue}`;
      if (props.styletype === "measureWidgetButton")
        return `1px solid ${props.theme.agDarkBlue}`;
      if (props.styletype === "orgItem")
        return `1px solid ${props.theme.agDarkBlue}`;
      if (props.disabled) return "initial";
      return `none`;
    }};

    opacity: ${(props) => {
      if (props.styletype === "orgItem") return "0.8";
      if (props.styletype === "property-favourite") return "0.7";
    }};

    cursor: pointer;
    &[disabled] {
      opacity: ${(props) => (props.styletype === "profile" ? "1" : "0.4")};
      pointer-events: none;
      cursor: initial;
    }
  }

  &[disabled] {
    opacity: ${(props) => (props.styletype === "profile" ? "1" : "0.4")};
    pointer-events: none;
    cursor: initial;
  }

  &[hidden] {
    display: none;
  }

  :focus {
    outline-color: ${(props) => {
      if (
        props.styletype === "primary" ||
        props.styletype === "sideBar" ||
        props.styletype === "profile" ||
        props.styletype === "orgSubMenuController" ||
        (props.styletype === "iconButton" && props.type !== "reset") ||
        props.styletype === "homeLink" ||
        props.styletype === "property-favourite"
      )
        return `${props.theme.agWhite}`;
      if (props.disabled || props.appearsdisabled) return "transparent";
    }};
    outline-offset: ${(props) => {
      if (
        props.styletype === "widgetButton" ||
        props.styletype === "formReset" ||
        props.styletype === "property-favourite"
      )
        return "0px";
    }};
    outline-width: ${(props) => {
      if (props.styletype === "widgetButton") return "2px";
    }};
  }
  > ${ButtonLabel} {
    white-space: ${(props) => {
      if (props.styletype === "accordianButton") return "unset";
    }};
  }
`;
Button.displayName = "Button";

export const ButtonLink = styled(Link)`
  position: relative;
  text-align: center;
  text-decoration: none;
  display: ${(props) => {
    if (props.type === "orgItem") return "flex";
    if (props.type === "sideBar") return "flex";
    if (props.type === "iconButton") return "flex";
    if (props.type === "homeLink") return "flex";
    if (props.type === "orgSubMenuController") return "flex";
    if (props.type === "sidebarLink") return "flex";
    if (props.type === "propertyListing") return "flex";
    if (props.type === "accordianButton") return "flex";
    return "inline-block";
  }};

  padding: ${(props) => {
    if (props.size === "large") return "0.5em 1em";
    if (props.type === "orgItem") return "1em";
    if (props.type === "sideBar") return "0";
    if (props.type === "orgSubMenuController") return "0";
    if (props.type === "sidebarLink") return "0";
    if (props.type === "iconButton") return "0";
    if (props.type === "homeLink") return "0";
    if (props.type === "clearSearch") return "0";
    if (props.type === "propertyListing") return "0";
    if (props.type === "accordianButton") return "0.5em 0.4em";
    if (props.type === "filter") return "0.375em 0.275em";
    if (props.type === "contextMenu") return "0";
    return "0.375em 1em";
  }};

  width: ${(props) => {
    if (props.type === "orgSubMenuController") return "100%";
    if (props.type === "sidebarLink") return "100%";
    if (props.type === "propertyListing") return "100%";
    if (props.type === "sideBar") return "100%";
    if (props.type === "homeLink")
      return `calc(${props.theme.menuWidthOpen} - ${props.theme.imgWidth})`;
    if (props.type === "accordianButton") return "100%";
    if (props.type === "pagination") return "100%";
    return "auto";
  }};

  max-width: ${(props) => {
    if (props.type === "pagination") return "46px";
    if (props.type === "orgItem") return "30%";
    return "auto";
  }};

  color: ${(props) => {
    if (props.type === "primary") return props.theme.agWhite;
    if (props.type === "secondary") return props.theme.agBlue;
    if (props.type === "tertiary") return props.theme.agBlue;
    if (props.type === "sideBar") return props.theme.agWhite;
    if (props.type === "orgItem") return props.theme.agWhite;
    if (props.type === "propertyListing") return props.theme.agWhite;
    if (props.type === "accordianButton") return props.theme.agOffBlack;
    return props.theme.agBlue;
  }};

  border: ${(props) => {
    if (props.type === "primary") return `1px solid ${props.theme.agBlue}`;
    if (props.type === "secondary") return `1px solid ${props.theme.agBlue}`;
    if (props.type === "tertiary") return `1px solid ${props.theme.agBlue}`;
    if (props.type === "orgItem")
      return `1px solid ${props.theme.agTransparentGray}`;
    return "none";
  }};
  border-radius: ${(props) => {
    if (props.type === "orgItem")
      return `${props.theme.baseBorderRadiusStandard}`;
    if (props.type === "orgSubMenuController") return "0.05em";

    return 0;
  }};

  background-color: ${(props) => {
    if (props.type === "primary") return props.theme.agBlue;
    if (props.type === "secondary") return props.theme.agWhite;
    if (props.type === "tertiary") return props.theme.agWhite;
    if (props.type === "orgSubMenuController") return props.theme.agBlue;
    if (props.type === "sideBar" && props.activestyle === "true")
      return props.theme.agGreen;
    if (props.type === "accordianButton") return props.theme.agOffWhite;
    if (props.type === "pagination") return "transparent";

    return "transparent";
  }};
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all 0.05s linear;
  transition: all 0.05s linear;
  cursor: pointer;
  white-space: ${(props) => {
    if (props.type === "orgItem") return "unset";
    if (props.type === "accordianButton") return "unset";
    return "nowrap";
  }};

  font-size: ${(props) => {
    if (props.type === "primary") return props.theme.fontSizeBase;
    if (props.type === "secondary") return props.theme.fontSizeSmall;
    if (props.type === "tertiary") return props.theme.fontSizeSmall;
    if (props.type === "sideBar") return props.theme.fontSizeBase;
    if (props.type === "orgSubMenuController") return props.theme.fontSizeSmall;
    if (props.type === "sidebarLink") return props.theme.fontSizeSmall;
    return props.theme.fontSizeBase;
  }};

  font-size: ${(props) => {
    if (props.type === "sideBar") return props.theme.baseFontWeight;
  }};

  font-weight: ${(props) => {
    if (props.type === "primary") return props.theme.baseFontWeightHeavy;
    return props.theme.baseFontWeightRegular;
  }};

  letter-spacing: ${(props) => {
    if (props.type === "sideBar") return "0.02em";
  }};

  height: ${(props) => {
    if (props.type === "primary") return "auto";
    if (props.type === "secondary") return "auto";
    if (props.type === "tertiary") return "auto";
    if (props.type === "orgItem") return "15.6em";
    if (props.type === "orgSubMenuController") return "2.5em";
    if (props.type === "sidebarLink") return "2.5em";
    if (props.type === "propertyListing") return "auto";
    if (props.type === "clearSearch") return "2.5em";
    if (props.type === "iconButton") return "auto";
    if (props.type === "homeLink") return "auto";
    if (props.type === "contextMenu") return "2.25em";
    if (props.type === "accordianButton") return "auto";
    if (props.type === "pagination") return "2.5em";
    if (props.type === "sideBar") return props.theme.menuWidthClose;
    return props.theme.imgWidth;
  }};

  line-height: ${(props) => props.theme.lineHeightBase};
  opacity: ${(props) => (props.disabled ? ".5" : "1")};
  margin: 0;

  margin-bottom: ${(props) => {
    if (props.type === "orgSubMenuController") return "5px";
    if (props.type === "sidebarLink") return "5px";
    if (props.type === "orgItem") return "15px";
    return 0;
  }};

  justify-content: ${(props) => {
    if (props.type === "orgItem") return "center";
    if (props.type === "sideBar") return "flex-start";
    if (props.type === "propertyListing") return "flex-start";
    if (props.type === "accordianButton") return "flex-start";
  }};

  align-items: ${(props) => {
    if (props.type === "orgSubMenuController") return "center";
    if (props.type === "sidebarLink") return "center";
    if (props.type === "homeLink") return "center";
    if (props.type === "sideBar") return "center";
    if (props.type === "accordianButton") return "center";
    if (props.type === "orgItem") return "center";
  }};

  flex-grow: ${(props) => {
    if (props.type === "homeLink") return "99";
    if (props.type === "orgItem") return "1";
  }};

  flex-basis: ${(props) => {
    if (props.type === "orgItem") return "30%";
  }};

  flex-direction: ${(props) => (props.type === "orgItem" ? "column" : "row")};

  &:hover {
    color: ${(props) => {
      if (props.type === "primary") return props.theme.agWhite;
      if (props.type === "secondary") return props.theme.agDarkBlue;
      if (props.type === "tertiary") return props.theme.agDarkBlue;
      if (props.type === "orgItem") return props.theme.agLightBlue;
      if (props.type === "propertyListing") return props.theme.agLightBlue;
      if (props.type === "accordianButton") return props.theme.agOffBlack;
      if (props.type === "pagination") return props.theme.agDarkBlue;
      if (props.disabled) return "initial";
      return props.theme.agWhite;
    }};
    background-color: ${(props) => {
      if (props.type === "primary") return props.theme.agDarkBlue;
      if (props.type === "secondary") return props.theme.agWhite;
      if (props.type === "tertiary") return props.theme.agWhite;
      if (props.type === "orgItem") return props.theme.agWhite;
      if (props.type === "sideBar") return props.theme.agBlue;
      if (props.type === "orgSubMenuController") return props.theme.agDarkBlue;
      if (props.type === "propertyListing") return "transparent";
      if (props.type === "iconButton") return props.theme.agBlue;
      if (props.type === "clearSearch") return "transparent";
      if (props.type === "accordianButton") return props.theme.agLightestGray;
      if (props.type === "filter") return props.theme.agLightestGray;
      if (props.disabled) return "initial";
      return props.theme.agBlue;
    }};

    text-decoration: none;

    border: ${(props) => {
      if (props.type === "orgSubMenuController") return "none";
      if (props.type === "primary")
        return `1px solid ${props.theme.agDarkBlue}`;
      if (props.type === "secondary")
        return `1px solid ${props.theme.agDarkBlue}`;
      if (props.type === "tertiary")
        return `1px solid ${props.theme.agDarkBlue}`;
      if (props.type === "orgItem")
        return `1px solid ${props.theme.agDarkBlue}`;
      if (props.disabled) return "initial";
      return `none`;
    }};

    opacity: ${(props) => {
      if (props.type === "orgItem") return `0.8`;
    }};

    cursor: pointer;
    &[disabled] {
      opacity: 0.4;
      pointer-events: none;
      cursor: initial;
    }
  }

  &[disabled] {
    opacity: 0.4;
    pointer-events: none;
    cursor: initial;
  }
  &[hidden] {
    display: none;
  }

  :focus {
    outline-color: ${(props) => {
      if (
        props.type === "primary" ||
        props.type === "sideBar" ||
        props.type === "orgSubMenuController" ||
        (props.type === "iconButton" && props.type !== "reset") ||
        props.type === "homeLink"
      )
        return `${props.theme.agWhite}`;
      if (props.disabled) return "transparent";
    }};
    text-decoration: none;
    outline-offset: ${(props) => {
      if (props.type === "widgetButton" || props.type === "formReset")
        return "0px";
    }};
    outline-width: ${(props) => {
      if (props.type === "widgetButton") return "2px";
    }};
  }
  > ${ButtonLabel} {
    white-space: ${(props) => {
      if (props.type === "accordianButton") return "unset";
    }};
    padding-left: ${(props) => {
      if (props.type === "propertyListing") return "0";
    }};
  }
`;
ButtonLink.displayName = "ButtonLink";

export const ExternalLink = styled.a`
  position: relative;
  text-align: center;
  text-decoration: none;
  display: flex;
  color: white;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all 0.05s linear;
  transition: all 0.05s linear;
  cursor: pointer;
  white-space: nowrap
  font-size: ${(props) => props.theme.fontSizeSmall};
  line-height: ${(props) => props.theme.lineHeightBase};
  opacity: ${(props) => (props.disabled ? ".5" : "1")};
  margin: 0;
  margin-bottom: 5px;
  align-items: center;
  width: 100%;
  &:hover {
    background-color: ${(props) => props.theme.agDarkBlue};
    text-decoration: none;
    cursor: pointer;
    &[disabled] {
      opacity: 0.4;
      pointer-events: none;
      cursor: initial;
    }
  }

  &[disabled] {
    opacity: 0.4;
    pointer-events: none;
    cursor: initial;
  }
  &[hidden] {
    display: none;
  }

  :focus {
    outline-color: ${(props) => {
      if (props.disabled) return "transparent";
      return props.theme.agWhite;
    }};
    text-decoration: none;
    outline-offset: 0px;
  }
`;
ExternalLink.displayName = "ExternalLink";

export const WidgetCloseButton = styled.button`
  position: absolute;
  right: 19px;
  top: 65px;
  border: none;
  padding: 0;
  background-color: transparent;

  :hover {
    background-color: transparent;
  }
`;
WidgetCloseButton.displayName = "WidgetCloseButton";

//BASE HEADING STYLES USED TO EXTEND OTHER ONES
export const Heading1 = styled.h1`
  padding: 0;
  font-family: ${(props) => props.theme.baseFont};

  font-weight: ${(props) => {
    if (props.type === "select-org" || props.type === "org-heading")
      return props.theme.baseFontWeightHeavy;
    if (props.type === "organisation") return props.theme.baseFontWeight;
    return props.theme.baseFontWeight;
  }};

  line-height: ${(props) => {
    if (props.type === "org-heading") return "";
    if (props.type === "organisation") return props.theme.lineHeightBase;
    return props.theme.lineHeightLargest;
  }};

  font-size: ${(props) => {
    if (props.type === "select-org" || props.type === "org-heading")
      return props.theme.fontSizeOne;
    if (props.type === "organisation") return props.theme.fontSizeOne;
    return props.theme.fontSizeFive;
  }};

  color: ${(props) => {
    if (props.type === "organisation" || props.type === "org-heading")
      return props.theme.agWhite;
    return props.theme.agBlack;
  }};
  margin: ${(props) => {
    if (props.type === "select-org" || props.type === "org-heading") return "0";
    if (props.type === "organisation") return "0 0 0 5px";
    return "0 0 2rem 0";
  }};
  text-align: ${(props) => {
    if (props.type === "org-heading") return "left";
    if (props.type === "select-org") return "center";
  }};
  white-space: ${(props) => {
    if (props.type === "sideBar" || props.type === "org-heading")
      return "normal";
    return "nowrap";
  }};
  padding-left: ${(props) => {
    if (props.type === "org-heading") return "10px";
  }};
  position: ${(props) => {
    if (props.type === "org-heading") return "initial";
    if (props.type === "sr-only") return "absolute";
  }};
  left: ${(props) => {
    if (props.type === "org-heading") return "initial";
    if (props.type === "sr-only") return "-10000px";
  }};

  top: ${(props) => {
    if (props.type === "org-heading") return "initial";
    if (props.type === "sr-only") return "0";
  }};
  text-index: 0px;

  width: ${(props) => {
    if (props.type === "org-heading") return "100%";
  }};

  @media ${deviceHeight.medium} {
    font-weight: ${(props) => {
      if (props.type !== "org-heading")
        return props.theme.baseFontWeightRegular;
    }};

    font-size: ${(props) => {
      if (props.type === "organisation" || props.type == "org-heading")
        return props.theme.fontSizeOne;
      return props.theme.fontSizeThree;
    }};
    margin: ${(props) => {
      if (props.type == "org-heading") return 0;
      if (props.type === "organisation") return "0 0 0 5px";
      return "0 0 calc(.2rem + 1vh) 0";
    }};
    line-height: ${(props) => {
      if (props.type !== "org-heading") return props.theme.lineHeightBase;
    }};
  }
`;

export const Heading2 = styled.h2`
  margin: 0;
  padding: ${(props) => {
    if (props.type === "list-heading") return "0 0.3em";
    else if (props.type !== "error") return 0;
    return "20px 0 0 20px";
  }};
  font-family: ${(props) => props.theme.baseFont};
  font-size: ${(props) => {
    if (props.type === "list-heading") return props.theme.fontSizeBase;
    return props.theme.fontSizeThree;
  }};
  font-weight: ${(props) => {
    if (props.type !== "list-heading") return props.theme.baseFontWeightRegular;
  }};
  line-height: ${(props) => {
    if (props.type === "list-heading") return props.theme.lineHeightBase;
    return props.theme.lineHeightSmallest;
  }};
  width: ${(props) =>
    props.type !== "error" ? "auto" : `${props.theme.menuWidthOpen}`};

  color: ${(props) => {
    if (props.type === "list-heading") return props.theme.agWhite;
  }};

  @media ${deviceHeight.medium} {
    font-size: ${(props) => {
      if (props.type === "list-heading") return props.theme.fontSizeBase;
      return props.theme.fontSizeTwo;
    }};
    font-weight: ${(props) => {
      if (props.type !== "list-heading") return props.theme.baseFontWeight;
    }};
  }
`;

export const Heading3 = styled.h3`
  margin: 0;
  padding: 0;
  margin-bottom: ${(props) => (props.type === "select-org" ? "0.8rem" : "")};
  text-align: ${(props) => (props.type === "select-org" ? "center" : "")};
  font-family: ${(props) => props.theme.baseFont};
  font-size: ${(props) => props.theme.fontSizeThree};
  font-weight: ${(props) => props.theme.baseFontWeightHeavy};
  line-height: ${(props) => props.theme.lineHeightLarger};
`;

export const Heading4 = styled.h4`
  margin: 0;
  padding: 0;
  font-family: ${(props) => props.theme.baseFont};
  font-size: ${(props) => props.theme.fontSizeTwo};
  line-height: ${(props) => props.theme.lineHeightBase};
`;

export const Heading5 = styled.h5`
  margin: 0;
  padding: 0;
  font-family: ${(props) => props.theme.baseFont};
  line-height: ${(props) => props.theme.lineHeightBase};
  font-weight: ${(props) => props.theme.baseFontWeightHeavy};
  color: ${(props) => props.theme.agBlack};
`;

export const Heading6 = styled.h6`
  margin: 0;
  padding: ${(props) => {
    if (props.type === "list-heading") return "0 0 0 9px";
    return "0";
  }};
  font-family: ${(props) => props.theme.baseFont};
  font-size: ${(props) => props.theme.fontSizeBase};
  line-height: ${(props) => props.theme.lineHeightBase};
  color: ${(props) => {
    if (props.type === "list-heading") return props.theme.agWhite;
    return props.theme.agDarkestGray;
  }};
`;

export const P = styled.p`
  color: ${(props) => props.theme.agDarkestGray};
  font-family: ${(props) => props.theme.baseFont};
  margin: 0;
  padding: 0;
  line-height: ${(props) => props.theme.lineHeightBase};
  font-size: ${(props) => {
    if (props.type === "invitation-content") return "calc(.8em + 0.3vw)";
    return props.theme.fontSizeBase;
  }};

  @media ${deviceHeight.medium} {
    font-size: ${(props) => props.theme.fontSizeSmall};
  }
`;

export const Span = styled.span`
  color: ${(props) => props.theme.agDarkestGray};
  font-family: ${(props) => props.theme.baseFont};
  margin: 0;
  padding: 0;
  font-size: ${(props) => props.theme.fontSizeBase};
`;

export const List = styled.ul`
  margin: 0;
  list-style: none;
  width: auto;
  padding: ${(props) => (props.type === "propertyListing" ? "0 0.4em" : "0")};
`;
List.displayName = "List";

export const ListItem = styled.li`
  background-color: ${(props) =>
    props.active && props.type === "sideBar" ? props.theme.active : ""};
  display: flex;
  padding: ${(props) => (props.type === "propertyListing" ? "0.5em 0" : "0")};
  & ${P} {
    color: ${(props) =>
      props.type === "error" ? props.theme.agErrorRed : props.theme.agWhite};
  }
`;
ListItem.displayName = "ListItem";

export const OrganisationList = styled.div`
  align-items: center;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
  padding: 2em 0;
  width: 100%;
  @media all and (max-height: 800px) {
    padding: 2em 0 0;
    justify-content: flex-start;
    ${Span} {
      font-size: ${(props) => props.theme.fontSizeSmallest};
      line-height: ${(props) => props.theme.lineHeightSmaller};
    }
  }
`;
OrganisationList.displayName = "OrganisationList";

export const OrganisationImage = styled.div`
  background-image: url(${(props) => props.backgroundImage});
  height: 30%;
  margin-bottom: 0.5em;
  width: 100%;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
`;
OrganisationImage.displayName = "OrganisationImage";

export const OrganisationDescription = styled(P)`
  font-size: ${(props) => props.theme.fontSizeSmallest};
  line-height: ${(props) => props.theme.lineHeightSmallest};
  margin-top: 0;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
  @media (min-width: 1500px) {
    -webkit-line-clamp: 7;
  }
`;
OrganisationDescription.displayName = "OrganisationDescription";

export const OrganisationDescriptionWrap = styled.div`
  margin-top: 0.4em;
`;
OrganisationDescriptionWrap.displayName = "OrganisationDescriptionWrap";

export const OrganisationExpiredMessage = styled(P)`
  color: ${(props) => props.theme.agErrorRed};
  font-weight: ${(props) => props.theme.baseFontWeightHeavy};
  margin-top: 0.5em;
`;
OrganisationExpiredMessage.displayName = "OrganisationExpiredMessage";

export const Legend = styled.legend`
  padding: 0.5em 0;
  font-weight: ${(props) => (props.styletype === "label" ? "bold" : "normal")};
  color: ${(props) => {
    if (props.type === "formError") return props.theme.agErrorRed;
    else if (props.styletype === "label") return props.theme.agBlack;
  }};
  opacity: ${(props) => (props.appearsdisabled ? 0.5 : 1)};
`;

export const FormInputMessage = styled.p`
  font-style: italic;
  font-size: ${(props) => props.theme.fontSizeSmall};
  margin-top: 0;
`;
export const Label = styled.label`
  &.capitalize {
    text-transform: capitalize;
  }
  display: flex;
  justify-content: space-between;
  opacity: ${(props) => (props.appearsdisabled ? 0.5 : 1)};
  color: ${(props) => {
    if (props.type === "error") return props.theme.agRed;
    if (props.type === "formError") return props.theme.agErrorRed;
    if (props.type === "accordion") return props.theme.agGreen;
    return props.theme.agBlack;
  }};
  font-style: ${(props) => {
    if (props.type === "error") return "italic";
  }};
  margin-bottom: ${(props) => {
    if (props.styletype === "form-label") return "0.25rem";
  }};

  width: ${(props) => {
    if (props.styletype === "form-label") return "auto";
  }};
  font-family: ${(props) => {
    if (props.styletype === "form-label") return props.theme.baseFont;
  }};
  font-weight: ${(props) => {
    if (props.styletype === "form-label") return "bold";
  }};
  font-size: ${(props) => {
    if (props.styletype === "form-label") return props.theme.fontSizeBase;
  }};
  line-height: ${(props) => {
    if (props.styletype === "form-label") return props.theme.lineHeightBase;
  }};
  align-items: ${(props) => {
    if (props.styletype === "form-label") return "center";
  }};
`;
Label.displayName = "Label";

export const LabelText = styled.span`
  width: 100%;
  + ${IconBackground} {
    margin-left: 1em;
  }
`;
LabelText.displayName = "LabelText";

export const Input = styled.input`
  &[type="text"],
  &[type="time"],
  &[type="number"],
  &[type="password"] {
    height: 2.25em;
    width: 100%;
    max-width: 100%;
    margin: 0.25rem 0 0 0;
    padding: 0 0.5rem;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    font-size: ${(props) => props.theme.fontSizeSmall};
    line-height: ${(props) => props.theme.lineHeightSmallest};
    color: ${(props) => props.theme.agOffBlack};
    vertical-align: baseline;
    outline: none;
    outline-offset: 0;
    background-color: ${(props) => props.theme.agWhite};
    border: 1px solid
      ${(props) =>
        props.invalid ? props.theme.agErrorRed : props.theme.agLighterGray};
    border-radius: 0.15rem;
    -webkit-transition: border-color 150ms linear;
    transition: border-color 150ms linear;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
    opacity: ${(props) => (props.disabled ? "0.3" : "1")};
    cursor: ${(props) => (props.disabled ? "default" : "text")};
  }
  &[type="color"] {
    width: 100%;
  }
  &[type="text"]:focus,
  &[type="number"]:focus,
  &[type="time"]:focus,
  &[type="password"]:focus {
    border-color: ${(props) =>
      props.invalid ? props.theme.agErrorRed : props.theme.agDarkBlue};
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075),
      0 0 5px
        ${(props) =>
          props.invalid ? props.theme.agErrorRed : props.theme.agDarkBlue};
  }
  &[type="checkbox"] {
    appearance: none;
    background-color: ${(props) => props.theme.agBlack};
    mask-image: url(${checkboxUnselected});
    -webkit-mask-size: cover;
    display: block;
    width: 1.5em;
    height: 1.5em;
    padding: 0;
    margin: 0;
    margin-right: 0.25em;
    opacity: ${(props) => (props.disabled ? "0.3" : "1")};
    cursor: ${(props) => (props.disabled ? "default" : "pointer")};
    font-size: ${(props) => props.theme.fontSizeBase};
    &.checked,
    &:checked {
      background-color: ${(props) => props.theme.agBlue};
      mask-image: url(${checkboxSelected});
    }

    &[indeterminate="true"] {
      mask-image: url(${checkboxIndeterminate});
    }
    &.focus,
    &:focus {
      background-color: ${(props) => props.theme.agBlue};
    }
    ${Label}:hover & {
      background-color: ${(props) => props.theme.agBlue};
    }
    + span {
      flex-grow: 1;
      flex-shrink: 99;
      width: calc(100% - 1.5em);
    }
  }
  &[type="radio"] {
    float: left;
    width: 1.2em;
    height: 1.2em;
    margin-top: 0;
    margin-right: 0.5em;
    margin-left: 0.125em;
    line-height: ${(props) => props.theme.lineHeightSmallest};
    box-shadow: none;
    box-sizing: content-box;
    cursor: ${(props) => (props.disabled ? "default" : "pointer")};
    font-size: ${(props) => props.theme.fontSizeBase};
    position: relative;
    &:before {
      content: "";
      position: absolute;
      width: 1em;
      height: 1em;
      top: 0.1em;
      left: 0.05em;
      border-radius: 50%;
      box-shadow: ${(props) => {
        if (props.invalid) return `0 0 2px 2px ${props.theme.agErrorRed}`;
        else return "none";
      }};
    }
    &:focus {
      outline: none;
      &:before {
        box-shadow: ${(props) => `0 0 2px 2px ${props.theme.agBlue}`};
      }
    }
    ${Label} & {
      font-weight: normal;
      + span {
        width: calc(100% - 1.2em);
      }
    }
  }
  + ${IconBackground} {
    position: absolute;
    right: ${(props) =>
      props.type === "number"
        ? "0.5em"
        : props.type === "time"
        ? "1.8em"
        : "0.5em"};
    top: 35%;
  }
`;
Input.displayName = "Input";

export const InputWrap = styled.div`
  width: 100%;
  ${Input} {
    width: 90%;
    margin-right: 0.2rem;
  }
  ${IconBackground} {
    right: 3rem;
  }
`;

export const InputContainer = styled.div`
  position: relative;
  display: flex;
`;
InputContainer.displayName = "InputContainer";

export const InputText = styled.label`
  width: 10%;
`;

export const InputGroup = styled.div`
  position: relative;
  display: flex;
  flex-direction: ${(props) =>
    props.flexDirection ? props.flexDirection : "column"};
  opacity: ${(props) => (props.disabled ? "0.3" : "1")};
  pointer-events: ${(props) => (props.disabled ? "none" : "all")};
  margin-top: ${(props) => {
    if (props.type === "featureSearch") return "1rem";
  }};
  & [data-name="IconBackground"][type="error"] {
    -webkit-mask-position: right;
    mask-position: right;
  }
  & [data-name="IconBackground"]:nth-of-type(2) {
    right: 25px;
  }
  @media all and (min-width: 600px) {
    width: ${(props) => (props.widthSize ? props.widthSize : "")};
  }
  & > * {
    ${(props) => (props.flexDirection ? "margin: 0 0.5rem" : "")}
  }
`;
InputGroup.displayName = "InputGroup";

export const Fieldset = styled.fieldset`
  border: none;
  padding: 0;
  margin: 0;
  & ${Label} {
    display: flex;
    position: relative;
  }
  ${InputGroup} & {
    position: relative;
    display: flex;
    flex-direction: column;
  }
`;
export const Textarea = styled.textarea`
  font-family: ${(props) => props.theme.baseFont};
  min-height: 4rem;
  width: 100%;
  max-width: 100%;
  margin: 0.25rem 0 0 0;
  padding: 0.5rem;
  font-size: ${(props) => props.theme.fontSizeSmall};
  line-height: ${(props) => props.theme.lineHeightSmallest};
  color: ${(props) => props.theme.agOffBlack};
  vertical-align: baseline;
  outline: none;
  outline-offset: 0;
  background-color: ${(props) => props.theme.agWhite};
  border: 1px solid
    ${(props) =>
      props.invalid ? props.theme.agErrorRed : props.theme.agLighterGray};
  border-radius: 0.15rem;
  -webkit-transition: border-color 150ms linear;
  transition: border-color 150ms linear;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  opacity: ${(props) => (props.disabled ? "0.3" : "1")};
  cursor: ${(props) => (props.disabled ? "default" : "text")};
  &:focus {
    border-color: ${(props) =>
      props.invalid ? props.theme.agErrorRed : props.theme.agDarkBlue};
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075),
      0 0 5px
        ${(props) =>
          props.invalid ? props.theme.agErrorRed : props.theme.agDarkBlue};
  }
  ${InputContainer} & {
    width: 94%;
  }
  + [data-name="IconBackground"][type="error"] {
    position: absolute;
    top: 0.8em;
    right: 0.4em;
  }
`;

export const Select = styled.select`
  appearance: none;
  height: 2.25em;
  width: 100%;
  max-width: 100%;
  margin: 0.25rem 0 0 0;
  padding: 0 0.3rem;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  font-size: ${(props) => props.theme.fontSizeSmall};
  line-height: ${(props) => props.theme.lineHeightSmallest};
  color: ${(props) => props.theme.agOffBlack};
  vertical-align: baseline;
  outline: none;
  outline-offset: 0;
  background-color: ${(props) => props.theme.agWhite};
  border: 1px solid
    ${(props) =>
      props.invalid ? props.theme.agErrorRed : props.theme.agLighterGray};

  border-radius: 0;
  -webkit-transition: border-color 150ms linear;
  transition: border-color 150ms linear;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);

  opacity: ${(props) => (props.disabled ? "0.3" : "1")};
  cursor: ${(props) => (props.disabled ? "default" : "text")};

  &:focus {
    border-color: ${(props) => props.theme.agDarkBlue};
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075),
      0 0 5px rgba(81, 167, 232, 0.5);
  }

  & ~ ${IconBackground} {
    position: absolute;
    right: 10px;
    top: 2.1rem;
    pointer-events: none;
  }
`;
Select.displayName = "Select";

export const SelectWrap = styled.div`
  position: relative;
  ${IconBackground} {
    top: 36%;
  }
`;
SelectWrap.displayName = "SelectWrap";

export const DropdownButton = styled(Button)`
  padding: ${(props) => {
    if (props.styletype === "contextMenu") return "0";
    else if (props.styletype === "filter") return "0.25rem";
  }};
  height: 2.3em;
  color: ${(props) => props.theme.agBlue};
  border: ${(props) => `1px solid ${props.theme.agLightGray}`};
  background-color: ${(props) => props.theme.agWhite};
  font-size: ${(props) => props.theme.fontSizeBase};
  line-height: ${(props) => props.theme.lineHeightSmallest};
  border-radius: 0.15rem;
  transition: background-color 0.3s ease-in-out;
  display: ${(props) => (props.disabled ? "none" : "flex")};
  justify-content: center;
  align-items: center;
  &:hover {
    color: ${(props) => props.theme.agDarkBlue};
    background-color: ${(props) => props.theme.agWhite};
    border: ${(props) => {
      if (props.styletype === "contextMenu" || props.styletype === "filter")
        return `1px solid ${props.theme.agDarkBlue}`;
    }};
  }
  &:focus {
    border-color: ${(props) => props.theme.agBlue};
    outline-offset: ${(props) => {
      if (props.styletype === "dropdownAction") return "-2px";
    }};
  }

  &:hover > ${IconBackground} {
    color: ${(props) => props.theme.agDarkBlue};
  }
`;
DropdownButton.displayName = "DropdownButton";

export const DropdownMenu = styled.nav`
  display: ${(props) => (props.isOpen ? "block" : "none")};
  box-sizing: border-box;
  padding: 0;
  border: 1px solid ${(props) => props.theme.agLightestGray};
  border-top: none;
  font-weight: 400;
  font-style: normal;
  background-color: ${(props) => props.theme.agWhite};
  position: absolute;
  width: calc(165px + 4.3vw);
  box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.05);
  z-index: 1000;
  overflow: auto;
  right: ${(props) => (props.type === "contextMenu" ? "0px" : "")};
  max-width: ${(props) => (props.maxWidth ? `${props.maxWidth}px` : "auto")};
`;
DropdownMenu.displayName = "DropdownMenu";

export const DropdownMenuForm = styled.form``;
DropdownMenuForm.displayName = "DropdownMenuForm";

export const DropdownMenuWrap = styled.div`
  display: ${(props) => (props.isOpen ? "block" : "none")};
  box-sizing: border-box;
  padding: 0;
  border: 1px solid ${(props) => props.theme.agLightestGray};
  border-top: none;
  font-weight: 400;
  font-style: normal;
  background-color: ${(props) => props.theme.agWhite};
  position: absolute;
  min-width: 200px;
  box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.05);
  z-index: 1000;
  overflow: auto;
  right: ${(props) => (props.type === "contextMenu" ? "0px" : "")};
`;
DropdownMenuWrap.displayName = "DropdownMenuWrap";

export const DropdownMenuList = styled.ul`
  list-style-type: none;
`;
DropdownMenuList.displayName = "DropdownMenuList";

export const DropdownMenuListItem = styled.li`
  font-size: ${(props) => props.theme.fontSizeSmaller};
  & ${Label} {
    padding: 0.5rem 0;
    display: flex;
    align-items: center;

    &:hover {
      cursor: pointer;
      color: ${(props) => props.theme.agLightBlue};
    }
  }
`;
DropdownMenuListItem.displayName = "DropdownMenuListItem";

export const DropdownMenuListInputs = styled.fieldset`
  border: none;
`;
DropdownMenuListInputs.displayName = "DropdownMenuListInputs";

export const DropdownMenuListInputItem = styled.div`
  font-size: ${(props) => props.theme.fontSizeSmaller};
  & ${Label} {
    padding: 0.5rem 0;
    display: flex;
    align-items: center;

    &:hover {
      cursor: pointer;
      color: ${(props) => props.theme.agLightBlue};
    }
  }
`;
DropdownMenuListInputItem.displayName = "DropdownMenuListInputItem";

export const DropdownMenuTitle = styled.span`
  position: relative;
  display: block;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0.5rem;
  font-size: ${(props) => props.theme.fontSizeSmaller};
  line-height: ${(props) => props.theme.lineHeightBase};
  color: ${(props) => props.theme.agDarkestGray};
  background-color: ${(props) => props.theme.agWhite};
  border-bottom: none;
  text-align: left;
  border-left: none;
  border-right: none;
  border-top: 1px solid ${(props) => props.theme.agLightestGray};
  white-space: nowrap;
  cursor: pointer;
  width: 100%;
  background-color: ${(props) => props.theme.agOffWhite};
  cursor: auto;
`;
DropdownMenuTitle.displayName = "DropdownMenuTitle";

export const DropdownMenuLegend = styled.legend`
  position: relative;
  display: block;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0.5rem;
  font-size: ${(props) => props.theme.fontSizeSmaller};
  line-height: ${(props) => props.theme.lineHeightBase};
  color: ${(props) => props.theme.agDarkestGray};
  background-color: ${(props) => props.theme.agWhite};
  border-bottom: none;
  text-align: left;
  border-left: none;
  border-right: none;
  border-top: 1px solid ${(props) => props.theme.agLightestGray};
  white-space: nowrap;
  cursor: pointer;
  width: 100%;
  background-color: ${(props) => props.theme.agOffWhite};
  cursor: auto;
`;
DropdownMenuLegend.displayName = "DropdownMenuLegend";

export const DropdownMenuAction = styled.button`
  position: relative;
  display: block;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0.5rem;
  font-size: ${(props) => props.theme.fontSizeSmaller};
  line-height: ${(props) => props.theme.lineHeightBase};
  color: ${(props) => props.theme.agDarkestGray};
  background-color: ${(props) => props.theme.agWhite};
  border-bottom: none;
  text-align: left;
  border-right: none;
  border-top: 1px solid ${(props) => props.theme.agLightestGray};
  white-space: nowrap;
  cursor: pointer;
  width: 100%;
  border-left: ${(props) => {
    if (props.active) return `3px solid ${props.theme.agBlue}`;
  }};

  &:hover {
    color: ${(props) => props.theme.agDarkerGray};
    background-color: ${(props) => props.theme.agOffWhite};
  }

  &:focus {
    text-indent: -3px;
    border-left: 3px solid ${(props) => props.theme.agBlue};
    outline-offset: -1px;
  }

  &:focus-within {
    text-indent: -3px;
    border-left: 3px solid ${(props) => props.theme.agBlue};
  }

  &:active {
    text-indent: -3px;
    border-left: 3px solid ${(props) => props.theme.agBlue};
  }

  &[disabled] {
    opacity: 0.5;
    pointer-events: none;
    border-left: ${(props) => {
      if (props.active) return `3px solid ${props.theme.agBlue}`;
    }};
  }
`;
DropdownMenuAction.displayName = "DropdownMenuAction";

export const DropdownMenuLink = styled(Link).withConfig({
  shouldForwardProp: (prop) => !["active"].includes(prop)
})`
  position: relative;
  display: block;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0.5rem;
  font-size: ${(props) => props.theme.fontSizeSmaller};
  line-height: ${(props) => props.theme.lineHeightBase};
  color: ${(props) => props.theme.agDarkestGray};
  background-color: ${(props) => props.theme.agWhite};
  border-bottom: none;
  text-align: left;
  border-right: none;
  border-top: 1px solid ${(props) => props.theme.agLightestGray};
  white-space: nowrap;
  cursor: pointer;
  width: 100%;
  border-left: ${(props) => {
    if (props.active) return `3px solid ${props.theme.agBlue}`;
  }};
  text-decoration: none;

  &:hover {
    color: ${(props) => props.theme.agDarkerGray};
    background-color: ${(props) => props.theme.agOffWhite};
    text-decoration: none;
  }

  &:focus {
    text-indent: -3px;
    border-left: 3px solid ${(props) => props.theme.agBlue};
    outline-offset: -1px;
    text-decoration: none;
  }

  &:focus-within {
    text-indent: -3px;
    border-left: 3px solid ${(props) => props.theme.agBlue};
    text-decoration: none;
  }

  &:active {
    text-indent: -3px;
    border-left: 3px solid ${(props) => props.theme.agBlue};
    text-decoration: none;
  }

  &[disabled] {
    opacity: 0.5;
    pointer-events: none;
    border-left: ${(props) => {
      if (props.active) return `3px solid ${props.theme.agBlue}`;
    }};
  }
`;
DropdownMenuLink.displayName = "DropdownMenuLink";

export const ToolTipWrapper = styled.div`
  display: inline-block;
  position: relative;
`;
ToolTipWrapper.displayName = "ToolTipWrapper";

export const ToolTipTitle = styled.div`
  color: ${(props) => props.theme.agDarkBlue};
  font-size: ${(props) => props.theme.fontSizeBase};
`;
ToolTipTitle.displayName = "ToolTipTitle";

export const ToolTipButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2em;
  height: 2em;

  &:hover ${IconSymbol} {
    background-color: ${(props) => props.theme.agDarkBlue};
  }
`;
ToolTipButton.displayName = "ToolTipButton";

export const ToolTipContainer = styled.div`
  display: ${(props) => (props.isOpen ? "block" : "none")};
  opacity: ${(props) => (props.isOpen ? "1" : "0")};
  -webkit-transition: all 0.5s linear;
  transition: all 0.5s linear;
  padding: 0.8rem;
  background-color: ${(props) => props.theme.agLightYellow};
  border-radius: ${(props) => props.theme.baseBorderRadiusStandard};
  position: absolute;
  top: 36px;
  line-height: ${(props) => props.theme.lineHeightBase};
  left: ${(props) =>
    props.floatDirection === "left" || props.floatDirection === undefined
      ? "0"
      : ""};
  right: ${(props) => (props.floatDirection === "right" ? "0em" : "")};
  z-index: 1000;
  min-width: 7em;
  max-width: 250px;
  animation: fadeIn 0.3s ease-out;

  &:before {
    content: "";
    position: absolute;
    top: 0px;
    left: ${(props) =>
      props.floatDirection === "left" || props.floatDirection === undefined
        ? "26px"
        : ""};
    right: ${(props) => (props.floatDirection === "right" ? "-11px" : "")};
    width: 0px;
    height: 0px;
    transform: translate(-1rem, -100%);
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid ${(props) => props.theme.agLightYellow};
  }
`;
ToolTipContainer.displayName = "ToolTipContainer";

export const ToolTipMessage = styled.p`
  font-size: ${(props) => props.theme.fontSizeSmaller};
  margin: 0;
  line-height: ${(props) => props.theme.lineHeightSmallest};
`;
ToolTipMessage.displayName = "ToolTipMessage";

export const ToolTipLink = styled.a`
  font-size: ${(props) => props.theme.fontSizeSmaller};
  margin: 0;
  display: block;
`;
ToolTipLink.displayName = "ToolTipLink";

export const PrinterList = styled.ul`
  ${Heading5} + & {
    margin-top: 0.5rem;
  }
`;
PrinterList.displayName = "PrinterList";

export const PrinterListItem = styled.li`
  & > a {
    display: flex;
    flex-direction: row;
    font-size: ${(props) => props.theme.fontSizeBase};
    padding: 0.3rem;
    align-items: center;
    text-decoration: none;
  }

  & > a:hover {
    background-color: ${(props) => props.theme.agOffWhite};
  }
`;
PrinterListItem.displayName = "PrinterListItem";

export const LayerList = styled.ul`
  display: flex;
  flex-direction: column;
`;
LayerList.displayName = "LayerList";

export const LayerListItem = styled.li`
  border-radius: ${(props) => props.theme.baseBorderRadiusStandard};
  font-size: ${(props) => props.theme.fontSizeTwo};
  margin-bottom: 0.1rem;
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: ${(props) =>
    props.active ? props.theme.agOffWhite : "transparent"};

  label {
    align-items: center;
    display: flex;
    justify-content: space-around;
    padding: 0.6rem;

    span {
      display: inline-block;
      width: calc(100% - (20px + (0.6rem * 2)));
    }

    &:hover {
      background-color: ${(props) => props.theme.agLightestGray};
    }

    &:nth-child(2) {
      padding-top: 0.3rem;
      opacity: ${(props) => (props.active ? "1" : ".5")};
      pointer-events: ${(props) => (props.active ? "all" : "none")};
    }

    &:nth-child(2):hover {
      cursor: ${(props) => (props.active ? "pointer" : "default")};
    }

    &:nth-child(2) span {
      font-size: ${(props) => props.theme.fontSizeSmaller};
      margin-left: 0.5rem;
    }
  }
`;
LayerListItem.displayName = "LayerListItem";

export const LayerLabelItem = styled.div`
  border-radius: ${(props) => props.theme.baseBorderRadiusStandard};
  font-size: ${(props) => props.theme.fontSizeSmaller};
  margin-bottom: 0.1rem;
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: ${(props) =>
    props.active ? props.theme.agOffWhite : "transparent"};

  label {
    align-items: center;
    display: flex;
    justify-content: space-around;
    padding: 0.6rem;

    span {
      display: inline-block;
      width: calc(100% - (20px + (0.6rem * 2)));
    }

    &:hover {
      background-color: ${(props) => props.theme.agLightestGray};
    }
  }
`;
LayerLabelItem.displayName = "LayerListItem";

export const CustomLayerList = styled.ul`
  position: relative;
`;
CustomLayerList.displayName = "CustomLayerList";

export const CustomLayerListItem = styled.li`
  margin-bottom: 0.4rem;

  & a {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    transition: opacity 0.5s;
    align-items: center;
    pointer-events: ${(props) =>
        props.disabledAppearance ? "none" : "default"}
      img {
      width: 80px;
      height: ~"calc(80px * .68)";
    }

    span:first-child {
      margin-left: 0.4rem;
      width: calc(100% - 0.4rem);
    }
  }
`;
CustomLayerListItem.displayName = "CustomLayerListItem";

export const LegendList = styled.ul`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  padding: ${(props) => (props.type === "packageContainer" ? "0" : "1rem")};
`;
LegendList.displayName = "LegendList";

export const LegendListItem = styled.li`
  background-color: ${(props) =>
    props.active ? props.theme.agOffWhite : "transparent"};
  display: flex;
  align-items: baseline;
  padding: ${(props) =>
    props.type === "packageContainer" ? "0.5rem 1rem" : "0.3rem "};
  border-bottom: ${(props) =>
    props.type === "packageContainer"
      ? `1px solid ${props.theme.agLightestGray}`
      : "none"};
  cursor: ${(props) =>
      props.type === "packageContainer" ? "pointer" : "context-menu"}
    & span {
    display: ${(props) =>
      props.type === "packageContainer" ? "flex" : "inline-flex"};
    flex-direction: ${(props) =>
      props.type === "packageContainer" ? "column" : "row"};
    & svg {
      width: 25px;
      height: 25px;
      & image {
        width: 100%;
        height: 100%;
      }
    }
  }
  & p {
    margin: ${(props) =>
      props.type === "packageContainer" ? "0 0 0.8rem" : "0.5rem 0.7rem"};
    padding-top: ${(props) =>
      props.type === "packageContainer" ? "0 !important" : "0.5em"};
    font-size: ${(props) => props.theme.fontSizeSmaller};
  }
`;
LegendListItem.displayName = "LegendListItem";

export const MeasureWidgetText = styled(P)`
  font-weight: bold;
  &:last-child {
    margin-bottom: 0;
  }
`;

export const MeasureWidgetLabel = styled.p`
  font-size: ${(props) => props.theme.fontSizeSmallest} !important;
  margin-bottom: 0 !important;
`;

export const PaginationPageList = styled.ul`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 0.5em;
`;
PaginationPageList.displayName = "PaginationPageList";

export const PaginationPageNumber = styled.li`
  flex-grow: 99;
  display: flex;
  justify-content: center;
  opacity: ${(props) => (props.active ? "0.5" : "1")};
  pointer-events: ${(props) => (props.active ? "none" : "all")};
  max-width: 46px;
  &:first-child {
    justify-content: flex-start;
  }
  &:last-child {
    justify-content: flex-end;
  }

  & > ${Button} {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    > ${ButtonLabel} {
      color: ${(props) =>
        props.buttonColor ? props.buttonColor : props.theme.agWhite};
    }
  }
`;
PaginationPageNumber.displayName = "PaginationPageNumber";

export const OrganisationSelectorList = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  height: 100%;
  ${ButtonLink}, ${Button} {
    height: 200px;
    line-height: ${(props) => props.theme.lineHeightSmallest};
    margin-right: 0;
    flex-basis: 100%;
    max-width: 100%;
    padding: 0.5rem;
    &[disabled] {
      opacity: 1;
      ${OrganisationDescription}, ${OrganisationImage}, ${Span} {
        opacity: 0.5;
      }
    }
  }
  @media all and (min-width: 420px) {
    ${ButtonLink}, ${Button} {
      padding: 1em;
      flex-basis: 47%;
      max-width: 47%;
    }
  }
  @media all and (min-width: 750px) {
    justify-content: ${(props) => {
      if (props.itemLength <= 3) {
        return "space-between";
      } else return "flex-start";
    }};
    ${ButtonLink}, ${Button} {
      margin-right: ${(props) => {
        if (props.itemLength <= 3) {
          return "0";
        } else return "5%";
      }};
      max-width: ${(props) => {
        if (props.itemLength > 3) {
          return "30%";
        } else {
          return `${100 / props.itemLength - 2}%`;
        }
      }};
      flex-basis: ${(props) => {
        if (props.itemLength > 3) {
          return "30%";
        } else {
          return `${100 / props.itemLength - 2}%`;
        }
      }};
      &:nth-child(3n) {
        margin-right: 0;
      }
      ${Span} {
        font-size: ${(props) => props.theme.fontSizeBase};
      }
    }
  }

  @media all and (min-width: 1000px) {
    justify-content: ${(props) => {
      if (props.itemLength <= 4) {
        return "space-between";
      } else return "flex-start";
    }};
    ${ButtonLink}, ${Button} {
      margin-right: ${(props) => {
        if (props.itemLength <= 4) {
          return "0";
        } else return "2.6%";
      }};
      max-width: ${(props) => {
        if (props.itemLength > 4) {
          return "23%";
        } else {
          return `${100 / props.itemLength - 2}%`;
        }
      }};
      flex-basis: ${(props) => {
        if (props.itemLength > 4) {
          return "23%";
        } else {
          return `${100 / props.itemLength - 2}%`;
        }
      }};
      &:nth-child(3n) {
        margin-right: ${(props) => {
          if (props.itemLength <= 4) {
            return "0";
          } else return "2.6%";
        }};
      }
      &:nth-child(4n) {
        margin-right: 0;
      }
      ${Span} {
        font-size: ${(props) => props.theme.fontSizeBase};
      }
    }
  }
`;
OrganisationSelectorList.displayName = "OrganisationSelectorList";

export const LoadingText = styled.p`
  color: ${(props) => props.textColor || props.theme.agBlack};
  font-size: ${(props) =>
    props.size === loaderSizeRegular ? "1em" : "0.85em"};
  text-align: center;
`;
LoadingText.displayName = "LoadingText";

export const NotificationErrorLink = styled.a`
  color: ${(props) => props.theme.agWhite};
`;
NotificationErrorLink.displayName = "NotificationErrorLink";

export const NotificationMessageText = styled.p`
  color: ${(props) => props.theme.agWhite}
  font-size: ${(props) => props.theme.fontSizeBase};
  margin: 0.5rem 0;
`;
NotificationMessageText.displayName = "NotificationErrorText";

export const LayerListButton = styled.button`
  font-weight: bold;
  color: ${(props) => props.theme.agBlue};
  padding-top: 0;
  opacity: ${(props) => (props.disabled ? "0.5" : "1")};
  &:hover {
    opacity: ${(props) => (props.disabled ? "0.5" : "1")};
  }
`;
LayerListButton.displayName = "LayerListButton";

export const LayerListFormItemContainer = styled.span`
  display: flex;
  align-items: center;
  flex-direction: row;
  opacity: ${(props) => (props.appearsdisabled ? "0.5" : "1")};
  cursor: ${(props) => (props.disabled ? "default" : "pointer")};
`;
export const LayerListFormItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
  > label {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    > span {
      flex-direction: row;
    }
  }
  h3 {
    font-size: ${(props) => props.theme.fontSizeBase};
  }
`;
LayerListFormItem.displayName = "LayerListFormItem";

export const LayerListFormItemGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: -1rem;
  width: 100%;

  ${LayerListFormItem} {
    border-bottom: 1px solid ${(props) => props.theme.agLightestGray};
    padding: 0.5rem 1rem;

    &:last-child {
      border-bottom: none;
      padding-bottom: 0;
    }
  }
`;
LayerListFormItemGroup.displayName = "LayerListFormItemGroup";

export const LabelTextContainer = styled.span`
  max-width: 85%;
`;
LabelTextContainer.displayName = "LabelTextContainer";

export const AccordionItemButton = styled(Button)`
  width: 100%;
  text-align: left;
  color: ${(props) => props.theme.agBlack};
  padding: 0;
  &:hover {
    background-color: transparent;
    color: ${(props) => props.theme.agBlack};
  }
  &:focus {
    background-color: transparent;
    color: ${(props) => props.theme.agBlack};
    outline-offset: 0;
  }
`;
AccordionItemButton.displayName = "AccordionItemButton";

export const AccordionContentWrapper = styled.div`
  padding: 0.5em 1em;
  border: 1px solid ${(props) => props.borderColor};
  border-top: 0;
`;
AccordionContentWrapper.displayName = "AccordionContentWrapper";

export const DropDownContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  width: ${(props) => (props.direction === "horizontal" ? "50%" : "100%")};
  ${Select}  {
    width: 100%;
  }
  ${IconBackground}  {
    top: 40%;
  }
`;
DropDownContainer.displayName = "DropDownContainer";

export const DropDownWrapper = styled.div`
  width: 100%;
  ${InputGroup}  {
    flex-direction: ${(props) =>
      props.direction === "horizontal" ? "row" : "column"};
    justify-content: space-between;
    width: 100%;
    align-items: ${(props) =>
      props.direction === "horizontal" ? "center" : "flex-start"};
    ${Label}  {
      width: ${(props) => (props.direction === "horizontal" ? "50%" : "100%")};
      padding-right: ${(props) =>
        props.direction === "horizontal" ? "0.5em" : "0"};
    }
  }
  & + & {
    margin-top: 1rem;
  }
`;
DropDownWrapper.displayName = "DropDownWrapper";

export const TabsWrapper = styled.div`
  background-color: #fff;
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 300px;
`;

export const Tabs = styled.div`
  display: flex;
  position: relative;
`;

export const Tab = styled.button`
  font-weight: 300;
  outline: none;
  cursor: pointer;
  position: relative;
  color: black;
  border-width: 0;
  border: 1px solid #ccc;
  border-bottom: ${(props) =>
    props.active ? "1px solid #fff" : "1px solid #ccc"};
  border-radius: 4px;
  font-size: ${(props) => props.theme.fontSizeOne};
  background-color: ${(props) => (props.active ? "#e6e6e6" : "white")};
  box-shadow: ${(props) =>
    props.active ? "inset 0 3px 5px rgba(0, 0, 0, 0.125)" : "none"};
  border-color: #adadad;
  z-index: 5;

  transition: background-color 0.5s ease-in-out;
  padding: 0.3rem 1.5rem;
  :hover {
    background-color: white;
  }
  :focus {
    outline: none;
  }
  &:first-child {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }
  &:not(:first-child):not(:last-child) {
    border-radius: 0px;
    border-radius: 0px;
  }
  &:last-child {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }
  & + & {
    margin-left: -1px;
  }
`;
Tab.displayName = "Tab";

export const TabContentContainer = styled.div`
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 300px;
  table {
    border-collapse: collapse;
    width: 100%;
  }
`;

export const TabTableTd = styled.td`
  text-align: left;
  padding: 8px;
`;

export const TabTableTr = styled.tr`
  :nth-child(even) {
    background-color: #f2f2f2;
  }
`;
export const BigCalendarWrapper = styled.div`
  width: 100%;
  height: 80vh;
`;
BigCalendarWrapper.displayName = "BigCalendarWrapper";

export const BigCalendarEvent = styled.div`
  background-color: ${(props) =>
    props.color ? props.color : props.theme.agGreen};
  padding: 0.2rem;
`;
BigCalendarEvent.displayName = "BigCalendarEvent";

export const BigCalendarEventTitle = styled(P)`
  font-weight: ${(props) => props.theme.baseFontWeightHeavy};
  color: ${(props) => props.theme.agWhite};
  text-align: center;
`;
BigCalendarEventTitle.displayName = "BigCalendarEventTitle";

export const BigCalendarEventDescription = styled(P)`
  color: ${(props) => props.theme.agWhite};
  text-align: center;
`;
BigCalendarEventDescription.displayName = "BigCalendarEventDescription";

export const CalendarLegendWrapper = styled.div`
  width: 30%;
  padding-left: 2rem;
  ${Heading2} {
    font-size: ${(props) => props.theme.fontSizeTwo};
    margin-bottom: 1rem;
    margin-top: 0.3rem;
  }
`;
CalendarLegendWrapper.displayName = "CalendarLegendWrapper";

export const SliderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
SliderWrapper.displayName = "SliderWrapper";

export const SliderTitle = styled(Label)`
  margin-bottom: 1rem;
`;
SliderTitle.displayName = "SliderTitle";

export const SliderInput = styled.input.attrs({ type: "range" })`
  -webkit-appearance: none;
  background-color: transparent;
  width: 100%;
  ::-webkit-slider-runnable-track {
    height: 0.3rem;
    -webkit-appearance: none;
    background-color: ${(props) => props.theme.agGray};
    border-radius: 5px;
  }
  ::-moz-range-track {
    height: 0.3rem;
    -webkit-appearance: none;
    background-color: ${(props) => props.theme.agGray};
    border-radius: 5px;
  }
  &::-webkit-slider-thumb {
    width: 1.2rem;
    margin-top: -0.5rem;
    -webkit-appearance: none;
    height: 1.2rem;
    cursor: pointer;
    background: ${(props) => props.theme.agWhite};
    border-radius: 50%;
    border: ${(props) => `3px solid ${props.theme.agBlue}`};
  }
  &::-moz-range-thumb {
    width: 1.2rem;
    margin-top: -0.5rem;
    -webkit-appearance: none;
    height: 1.2rem;
    cursor: pointer;
    background: ${(props) => props.theme.agWhite};
    border-radius: 50%;
    border: ${(props) => `3px solid ${props.theme.agBlue}`};
    &:focus {
      background: ${(props) => props.theme.agBlue};
    }
  }
  &:focus,
  &:hover {
    &::-webkit-slider-thumb {
      border-color: ${(props) => props.theme.agDarkBlue};
    }
    ,
    &::-moz-range-thumb {
      border-color: ${(props) => props.theme.agDarkBlue};
    }
  }
`;
SliderInput.displayName = "SliderInput";

export const SliderInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;
SliderInputWrapper.displayName = "SliderInputWrapper";

export const SliderLabel = styled(Label)``;
SliderLabel.displayName = "SliderLabel";

export const SliderLabelWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 98%;
  margin-top: 0.5rem;
`;
SliderLabelWrapper.displayName = "SliderLabelWrapper";

export const SliderManualInputField = styled(Input).attrs({
  type: "number"
})`
  width: auto !important;
`;
SliderManualInputField.displayName = "SliderManualInputField";

export const SliderManualInputWrapper = styled.div`
  display: flex;
  justify-content: center;
`;
SliderManualInputWrapper.displayName = "SliderManualInputWrapper";

export const SliderButton = styled(Button)`
  &:hover,
  &:focus {
    background-color: transparent;
    color: ${(props) => props.theme.agBlue};
    text-decoration: underline;
  }
`;
SliderButton.displayName = "SliderButton";

export const FormSubLabel = styled.p`
  font-size: ${(props) => props.theme.fontSizeSmallest};
  margin: 0.2rem 0 0.8rem 0;
`;
FormSubLabel.displayName = "FormSubLabel";

export const RadioSubitem = styled.div`
  margin-left: ${(props) => props.indent || "1.8em"};
  padding-bottom: 0.5em;
`;
RadioSubitem.displayName = "RadioSubitem";

export const TableHead = styled.thead``;
TableHead.displayName = "TableHead";

export const TableHeadRow = styled.tr`
  background-color: ${(props) => props.theme.agGreen};
`;
TableHeadRow.displayName = "TableHeadRow";

export const TableHeadCell = styled.th`
  color: ${(props) => props.theme.agWhite};
  width: ${(props) => props.width || "auto"};
  text-align: left;
  font-weight: ${(props) => props.theme.baseFontWeightRegular};
  padding: 0.8em 0.6em;
`;
TableHeadCell.displayName = "TableHeadCell";

export const TableCellButton = styled(Button)`
  padding: 0;
  height: auto;
  margin-top: 0.5rem;
  font-size: ${(props) => props.theme.fontSizeSmallest};
  &:hover {
    background-color: transparent;
    color: ${(props) => props.theme.agDarkBlue};
    text-decoration: underline;
  }
  &:focus {
    outline: none;
    text-decoration: underline;
  }
`;
TableCellButton.displayName = "TableCellButton";

export const TableBodyCell = styled.td`
  vertical-align: ${(props) => props.verticalAlign};
  width: ${(props) => props.width || "auto"};
  padding: 0.8em 0.6em;
  [data-name="FeatureDetailsItemContainer"] {
    padding: 0;
    margin: 0;
  }
  ${Label} {
    font-weight: ${(props) => props.theme.baseFontWeightRegular};
    margin-bottom: 0;
  }
`;
TableBodyCell.displayName = "TableBodyCell";

export const Table = styled.table`
  border-collapse: collapse;
  table-layout: fixed;
  width: 100%;
  min-width: ${(props) => props.minWidth || "800px"};
  background-color: ${(props) => props.theme.agLightestGray};
`;
Table.displayName = "Table";

export const TableBody = styled.tbody``;
TableBody.displayName = "TableBody";

export const TableBodyRow = styled.tr`
  &:not(:last-child) {
    border-bottom: ${(props) =>
      props.showBorder ? `3px solid ${props.theme.agWhite}` : "none"};
  }
`;
TableBodyRow.displayName = "TableBodyRow";

export const TableTrashButtonCell = styled(TableBodyCell)`
  vertical-align: middle;
  width: ${(props) => props.width || "auto"};
`;
TableTrashButtonCell.displayName = "TableTrashButtonCell";

export const TableBodyCellItem = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  ${TableCellButton} {
    margin-left: 1em;
  }
  > div {
    width: 100%;
  }
`;
TableBodyCellItem.displayName = "TableBodyCellItem";
