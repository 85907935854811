import React, { Component } from "react";
import ReactDatePicker from "react-datepicker";
import moment from "moment";
import { Icon } from "../../../core";
import { DatePickerWrapper, DatePickerContainer, DatePickerIconWrapper } from "..";
import { defaultTheme } from "../../..";
import { Button } from "../../../core/components";
import PropTypes from "prop-types";
import "react-datepicker/dist/react-datepicker.css";

/**
 * This component utilizes [react-datepicker](https://reactdatepicker.com)
 */
class DatePicker extends Component {
  static propTypes = {
    /** The selected value in the datepicker */
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
      PropTypes.number,
      PropTypes.instanceOf(Date)
    ]),
    /** Called when a date is selected */
    onChange: PropTypes.func,
    /** If the selected date is invalie */
    invalid: PropTypes.bool,
    /** An icon for when the field is required */
    requiredIcon: PropTypes.shape({
      type: PropTypes.string,
      iconColor: PropTypes.string,
      iconWidth: PropTypes.string,
      iconHeight: PropTypes.string
    }),
    /** Whether to show reset button */
    showResetButton: PropTypes.bool,
    /** If reset button is disabled */
    resetDisabled: PropTypes.bool,
    /** Called on click of reset button */
    onReset: PropTypes.func,
    minDate: PropTypes.string,
    maxDate: PropTypes.string,
    /** The date the calendar opens to */
    openToDate: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
      PropTypes.number,
      PropTypes.instanceOf(Date)
    ])
  };
  minDate = () => {
    const { minDate } = this.props;
    return minDate ? moment(minDate).toDate() : null;
  };
  maxDate = () => {
    const { maxDate } = this.props;
    return maxDate ? moment(maxDate).toDate() : null;
  };

  validDate = (value) => {
    if (value === null) return; //IF NO VALUE IS PASSED
    const isUnix = moment(Number(value), ["x", "X"]).isValid(); // check if is unix string
    return isUnix
      ? moment(Number(value), ["x", "X"]).toDate()
      : moment(moment(value).toDate()).isValid()
      ? moment(value).toDate()
      : null;
  };

  openToDate = () => {
    const { openToDate, value } = this.props;
    return value
      ? this.validDate(value)
      : openToDate
      ? this.validDate(openToDate)
      : null;
  };

  dateValue = () => {
    const { value } = this.props;
    return value ? this.validDate(value) : null;
  };

  dateFormat = () => {
    return "dd/MM/yyyy";
  };

  handleDateChange = (event) => {
    const { onChange } = this.props;
    onChange(event);
  };

  isInvalid = () => {
    const { invalid } = this.props;
    return invalid;
  };

  requiredIcon = () => {
    const { requiredIcon } = this.props;
    return requiredIcon ? requiredIcon : null;
  };

  showResetButton = () => {
    const { showResetButton } = this.props;
    return showResetButton;
  };

  resetDisabled = () => {
    const { resetDisabled } = this.props;
    return resetDisabled;
  };

  onReset = () => {
    const { onReset } = this.props;
    if (onReset) onReset();
  };

  resetLabel = () => {
    const { resetLabel } = this.props;
    return resetLabel;
  };

  placeholderLabel = () => {
    const { placeholderLabel } = this.props;
    return placeholderLabel;
  };

  render() {
    return (
      <DatePickerWrapper data-name={"DatePickerWrapper"}>
        <DatePickerContainer
          data-name={"DatePickerContainer"}
          invalid={this.isInvalid()}
        >
          <DatePickerIconWrapper>
            {this.requiredIcon() && <Icon {...this.requiredIcon()} />}
            {this.showResetButton() && (
              <Button
                disabled={this.resetDisabled()}
                aria-label={this.resetLabel()}
                type="reset"
                title={this.resetLabel()}
                styletype={"iconButton"}
                onClick={this.onReset}
              >
                <Icon
                  data-name={"Icon"}
                  iconColor={defaultTheme.agRed}
                  type={"close"}
                  bgHeight={"2em"}
                  bgWidth={"2em"}
                  iconHeight={"1em"}
                  iconWidth={"1em"}
                />
              </Button>
            )}
            <Icon
              type="calendar"
              iconColor="gray"
              bgWidth="2.25em"
              bgHeight="2.25em"
              iconWidth="1.8em"
              iconHeight="1.8em"
            />
          </DatePickerIconWrapper>
          <ReactDatePicker
            placeholderText={this.placeholderLabel()}
            dateFormat={this.dateFormat()}
            selected={this.dateValue()}
            onChange={this.handleDateChange}
            minDate={this.minDate()}
            maxDate={this.maxDate()}
            openToDate={this.openToDate()}
          />
        </DatePickerContainer>
      </DatePickerWrapper>
    );
  }
}

export default DatePicker;
