import React, { Component } from "react";
import { defaultTheme } from "../../..";
import { Button, ButtonLabel } from "../../../core/components";
import { Icon } from "../../../core";
import { PanelControlButtonWrap } from "..";

export default class PanelControlButton extends Component {
  onClickButton = () => {
    const { onClickButton } = this.props;
    if (onClickButton) onClickButton();
  };

  isOpen = () => {
    const { isOpen } = this.props;
    return isOpen === true;
  };

  isFullWidth = () => {
    const { isFullWidth } = this.props;
    return isFullWidth === true;
  };

  getLabelText = () => {
    const { labelText } = this.props;
    return labelText ? labelText : "";
  };

  render() {
    return (
      <PanelControlButtonWrap
        isOpen={this.isOpen()}
        isFullWidth={this.isFullWidth()}
      >
        <Button styletype="iconButton" onClick={this.onClickButton}>
          <Icon
            type={`${this.isOpen() ? "close" : "open"}-menu`}
            iconHeight="1.5em"
            iconWidth="1.5em"
            bgHeight="2.7em"
            bgWidth="2.7em"
            iconColor={defaultTheme.agBlue}
          />
          <ButtonLabel type={"sr-only"}>{this.getLabelText()}</ButtonLabel>
        </Button>
      </PanelControlButtonWrap>
    );
  }
}
