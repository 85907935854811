import React, { Component } from "react";
import ReactModal from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import "./Modal.css";

import {
  ModalWrapper,
  ModalContainer,
  ModalBody,
  ModalFooter
} from "../../layout";

import { Button, ButtonLink } from "../../components";
import PropTypes from "prop-types";
import { isNullOrUndefined } from "../../../../App/utils";
import { PackageHeading } from "../../../plugin/components";

/**
 * A resuable modal used throughout the app for errors and information. Renders at full screen size with a shaded background over the app.
 */
class Modal extends Component {
  static propTypes = {
    /** If the modal is open */
    isOpen: PropTypes.bool,
    /** The heading/title for the modal */
    title: PropTypes.string,
    /**Instead of a title string in the default header, can have a custom header */
    header: PropTypes.element,
    /** The body content for the modal */
    body: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    /** The text on the primary button */
    primaryButtonTitle: PropTypes.string,
    /** Called when the primary button is clicked */
    primaryButtonAction: PropTypes.func,
    /** If the primary button is disabled */
    primaryButtonDisabled: PropTypes.bool,
    /** The text on the secondary button */
    secondaryButtonTitle: PropTypes.string,
    /** Called when the secondary button is clicked */
    secondaryButtonAction: PropTypes.func,
    /** I the secondary button is disabled */
    secondaryButtonDisabled: PropTypes.bool,
    /** Called when the modal close button is clicked */
    onClose: PropTypes.func,
    /** The button type for the primary button */
    primaryButtonType: PropTypes.oneOf(["button", "reset", "submit"]),
    /** The button type for the secondary button */
    secondaryButtonType: PropTypes.oneOf(["button", "reset", "submit"]),
    primaryButtonLink: PropTypes.shape({
      url: PropTypes.string,
      docTitle: PropTypes.string
    }),
    secondaryButtonLink: PropTypes.shape({
      url: PropTypes.string,
      docTitle: PropTypes.string
    }),
    footer: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    closeOnEsc: PropTypes.bool,
    closeOnOverlayClick: PropTypes.bool
  };

  static defaultProps = {
    isOpen: false,
    primaryButtonDisabled: false,
    secondaryButtonDisabled: false,
    primaryButtonType: "button",
    secondaryButtonType: "button",
    closeOnEsc: true,
    closeOnOverlayClick: true
  };
  whenOpen = () => {
    const { isOpen } = this.props;
    return isOpen ? isOpen : false;
  };

  modalHeader = () => {
    const { title, header } = this.props;
    if (!title && !header) return null;
    return header || <PackageHeading>{title}</PackageHeading>;
  };

  modalBody = () => {
    const { body } = this.props;
    return body ? body : "";
  };

  primaryButtonTitle = () => {
    const { primaryButtonTitle } = this.props;
    return primaryButtonTitle ? primaryButtonTitle : "";
  };

  secondaryButtonTitle = () => {
    const { secondaryButtonTitle } = this.props;
    return secondaryButtonTitle ? secondaryButtonTitle : "";
  };

  whenPrimaryButton = () => {
    const { primaryButtonAction, primaryButtonTitle, primaryButtonLink } =
      this.props;
    return primaryButtonAction && primaryButtonTitle && !primaryButtonLink
      ? true
      : false;
  };

  whenSecondaryButton = () => {
    const { secondaryButtonAction, secondaryButtonTitle, secondaryButtonLink } =
      this.props;
    return secondaryButtonAction && secondaryButtonTitle && !secondaryButtonLink
      ? true
      : false;
  };

  whenSecondaryButtonLink = () => {
    const { secondaryButtonLink, secondaryButtonTitle } = this.props;
    return secondaryButtonLink && secondaryButtonTitle ? true : false;
  };

  whenPrimaryButtonDisabled = () => {
    const { primaryButtonDisabled } = this.props;
    return primaryButtonDisabled ? true : false;
  };

  whenSecondaryButtonDisabled = () => {
    const { secondaryButtonDisabled } = this.props;
    return secondaryButtonDisabled ? true : false;
  };

  handleCloseModal = () => {
    const { onClose } = this.props;
    if (onClose) onClose();
  };

  handlePrimaryAction = () => {
    const { primaryButtonAction } = this.props;
    if (primaryButtonAction) primaryButtonAction();
  };

  handleSecondaryAction = () => {
    const { secondaryButtonAction } = this.props;
    if (secondaryButtonAction) secondaryButtonAction();
  };

  secondaryButtonType = () => {
    const { secondaryButtonType } = this.props;
    return this.whenSecondaryButtonLink()
      ? "tertiary"
      : secondaryButtonType
      ? secondaryButtonType
      : "button";
  };

  primaryButtonType = () => {
    const { primaryButtonType } = this.props;
    return this.whenPrimaryButtonLink()
      ? "primary"
      : primaryButtonType
      ? primaryButtonType
      : "button";
  };

  whenPrimaryButtonLink = () => {
    const { primaryButtonLink, primaryButtonTitle } = this.props;
    return primaryButtonLink && primaryButtonTitle ? true : false;
  };

  primaryButtonLink = () => {
    const { primaryButtonLink } = this.props;
    return primaryButtonLink;
  };

  secondaryButtonLink = () => {
    const { secondaryButtonLink } = this.props;
    return secondaryButtonLink;
  };

  primaryButtonLinkDocTitle = () => {
    const { primaryButtonDocTitle } = this.props;
    return primaryButtonDocTitle ? primaryButtonDocTitle : "";
  };

  customFooter = () => {
    const { footer } = this.props;
    return footer ? footer : null;
  };

  customButtons = () => {
    const { customButtons } = this.props;
    return customButtons ? customButtons : [];
  };

  footerButtons = () => {
    const customButtons = this.customButtons();
    let allButtons = [];

    if (this.whenSecondaryButton() || this.whenSecondaryButtonLink()) {
      allButtons.push({
        styletype: "tertiary",
        type: this.secondaryButtonType(),
        label: this.secondaryButtonTitle(),
        disabled: this.whenSecondaryButtonDisabled(),
        onClick: this.handleSecondaryAction,
        to: this.whenSecondaryButtonLink()
          ? this.secondaryButtonLink().url
          : null,
        state: this.whenSecondaryButtonLink()
          ? {
              docTitle: this.secondaryButtonLink().docTitle
            }
          : null
      });
    }
    if (this.whenPrimaryButton() || this.whenPrimaryButtonLink()) {
      allButtons.push({
        styletype: "primary",
        type: this.primaryButtonType(),
        label: this.primaryButtonTitle(),
        disabled: this.whenPrimaryButtonDisabled(),
        onClick: this.handlePrimaryAction,
        to: this.whenPrimaryButtonLink() ? this.primaryButtonLink().url : null,
        state: this.whenPrimaryButtonLink()
          ? {
              docTitle: this.primaryButtonLink().docTitle
            }
          : null
      });
    }

    if (customButtons.length > 0) {
      customButtons.forEach((button) => {
        if (!isNullOrUndefined(button.order))
          allButtons.splice(button.order, 0, button);
        else allButtons.push(button);
      });
    }

    return allButtons.map((button) =>
      button.to ? (
        <ButtonLink key={button.to} {...button}>
          {button.label}
        </ButtonLink>
      ) : (
        <Button key={button.label} {...button}>
          {button.label}
        </Button>
      )
    );
  };

  render() {
    return (
      <ReactModal
        open={this.whenOpen()}
        onClose={this.handleCloseModal}
        center
        showCloseIcon={this.props.showCloseButton}
        closeOnEsc={this.props.closeOnEsc}
        closeOnOverlayClick={this.props.closeOnOverlayClick}
        classNames={{
          modal: "customModal"
        }}
      >
        <ModalWrapper data-name={"ModalWrapper"} id="agbox-modal">
          <ModalContainer data-name={"ModalContainer"}>
            {this.modalHeader()}
            <ModalBody>{this.modalBody()}</ModalBody>
            <ModalFooter>
              {this.customFooter() ? (
                this.customFooter()
              ) : (
                <React.Fragment>{this.footerButtons()}</React.Fragment>
              )}
            </ModalFooter>
          </ModalContainer>
        </ModalWrapper>
      </ReactModal>
    );
  }
}

export default Modal;
