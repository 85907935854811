import React, { Component } from "react";
import { NotificationManager } from "react-notifications";
import {
  decodeComputedString,
  projectGeometries,
  roundNumber
} from "../../App/utils";
import {
  ABORT_ERROR_NAME,
  DEFAULT_ELEVATION_MODE,
  DELETEDDATE_QUERY_EXPRESSION,
  ELEVATION_OFFSET,
  FEATURE_HIGHLIGHT_EXCLUDEDEFFECT,
  FEATURE_HIGHLIGHT_NON_BASEPOLYEFFECT,
  GEOMETRY_SERVICE_URL,
  MERGE_RENDERER,
  POINTER_MOVE_EVENT,
  QUERY_EXPRESSION,
  TEMP_LAYER_PREFIX,
  TEXT_SYMBOL_CHEMICALS,
  UNIT_HECTARES
} from "../../constants";
import FeatureLayer from "@arcgis/core/layers/FeatureLayer";
import GraphicsLayer from "@arcgis/core/layers/GraphicsLayer";
import Graphic from "@arcgis/core/Graphic";
import { labelPoints } from "@arcgis/core/rest/geometryService";
import { union, planarArea } from "@arcgis/core/geometry/geometryEngine";
import { debounce, isAbortError } from "@arcgis/core/core/promiseUtils";

export default class FeatureMerge extends Component {
  constructor(props) {
    super(props);
    this.controller = new AbortController();
    this.state = {
      selectedFeatures: [],
      mergeLayer: null,
      mergeLabelsLayer: null,
      clickListener: null,
      hoverListener: null,
      mouseMoveListener: null,
      selectableFeatures: []
    };
  }
  componentDidMount() {
    this.setupMergeFeatureLayer();
  }
  componentWillUnmount() {
    this.removeEventListeners();
    this.removeTempLayers();
  }
  removeEventListeners = () => {
    const { clickListener, mouseMoveListener } = this.state;
    if (clickListener) clickListener.remove();
    if (mouseMoveListener) mouseMoveListener.remove();
  };
  removeTempLayers = () => {
    const { mergeLayer, mergeLabelsLayer } = this.state;
    const { webMap } = this.props;
    const layersToRemove = [mergeLayer, mergeLabelsLayer].filter(
      (layer) => layer
    );
    if (!layersToRemove.length) return;
    webMap.removeMany(layersToRemove);
  };
  getSelectableFeatures = async () => {
    try {
      const {
        layer,
        originalFeature,
        queryExpression,
        outFields,
        filterSelectableFeatures
      } = this.props;
      if (!layer) return [];
      const query = layer.createQuery();
      query.where = `(${
        queryExpression
          ? queryExpression
          : layer.fields.find((field) => field.name === "expiryDate")
          ? QUERY_EXPRESSION
          : DELETEDDATE_QUERY_EXPRESSION
      }) AND objectId <> ${originalFeature.attributes.objectId}`;
      query.outFields = outFields ? outFields : ["objectId", "title"];
      query.returnGeometry = true;

      const { features, error, message } = await layer.queryFeatures(query, {
        signal: this.controller.signal
      });
      if (error) {
        throw new Error(message ? message : error);
      } else {
        let selectableFeatures = features ? features : [];
        if (filterSelectableFeatures)
          selectableFeatures = filterSelectableFeatures(selectableFeatures);
        this.setState({
          selectableFeatures
        });
        return selectableFeatures;
      }
    } catch (e) {
      const {
        labels: { ERROR_FETCHING_MERGE_FEATURES_LABEL }
      } = this.props;
      NotificationManager.error(
        e.message,
        ERROR_FETCHING_MERGE_FEATURES_LABEL,
        0
      );
    }
  };
  setupMergeFeatureLayer = async () => {
    const { layer, webMap, onMergeSetup, has3D } = this.props;
    if (!layer) return;

    layer.when(async () => {
      let mergeLayer = webMap.layers.items.find(
        (layer) => layer.title === `${TEMP_LAYER_PREFIX}_Merge`
      );
      const selectableFeatures = await this.getSelectableFeatures();
      if (!mergeLayer) {
        const { fields, geometryType } = layer;

        mergeLayer = new FeatureLayer({
          source: selectableFeatures,
          fields: [
            ...fields,
            {
              name: "selected",
              type: "integer"
            }
          ],
          title: `${TEMP_LAYER_PREFIX}_Merge`,
          visible: true,
          renderer: MERGE_RENDERER,
          objectIdField: "objectId",
          geometryType
        });
        if (has3D) {
          mergeLayer.elevationInfo = {
            mode: DEFAULT_ELEVATION_MODE,
            offset: ELEVATION_OFFSET
          };
        }
        webMap.add(mergeLayer);
      }
      this.setupMapViewEvents();
      this.setState({
        mergeLayer
      });
      this.setupMergeLabelsLayer();
      if (onMergeSetup) onMergeSetup(mergeLayer, selectableFeatures);
    });
  };

  handleMouseClick = async (e) => {
    const { selectableFeatures } = this.state;
    const { mapView } = this.props;
    const { results } = await mapView.hitTest(e);
    const features = results
      .filter(
        ({ layer }) => layer && layer.title === `${TEMP_LAYER_PREFIX}_Merge`
      )
      .map(({ graphic }) => graphic);
    if (!features.length) return;
    this.handleClickFeatures(
      features.reduce((result, feature) => {
        const actualFeature = selectableFeatures.find(
          (selectableFeature) =>
            selectableFeature.attributes.objectId ===
            feature.attributes.objectId
        );
        if (!actualFeature) return result;
        return [...result, actualFeature];
      }, [])
    );
  };

  setupMapViewEvents = () => {
    const { mapView } = this.props;
    const clickListener = mapView.on("click", this.handleMouseClick);

    const debouncedMouseMove = debounce((event) => {
      return this.handleMouseMoveEvent(event);
    });
    const mouseMoveListener = mapView.on(POINTER_MOVE_EVENT, (event) => {
      debouncedMouseMove(event).catch(function (err) {
        if (!isAbortError(err)) {
          throw err;
        }
      });
    });
    this.setState({
      clickListener,
      mouseMoveListener
    });
  };

  handleMouseMoveEvent = async (e) => {
    const { mapView } = this.props;
    const { results } = await mapView.hitTest(e);
    const features = results
      .filter(
        (item) =>
          item.layer && item.layer.title === `${TEMP_LAYER_PREFIX}_Merge`
      )
      .map((item) => item.graphic);
    const { mergeLayer } = this.state;
    if (!mergeLayer) return;
    const layerView = await mapView.whenLayerView(mergeLayer);
    if (!layerView) return;
    layerView.featureEffect = features.length
      ? {
          includedEffect: FEATURE_HIGHLIGHT_NON_BASEPOLYEFFECT,
          filter: {
            where: `${features
              .map((feature) => `objectId = ${feature.attributes.objectId}`)
              .join(" OR ")}`
          },
          excludedLabelsVisible: true,
          excludedEffect: FEATURE_HIGHLIGHT_EXCLUDEDEFFECT
        }
      : null;
  };

  setupMergeLabelsLayer = () => {
    const { webMap } = this.props;
    const mergeLabelsLayer = new GraphicsLayer({
      title: `${TEMP_LAYER_PREFIX}_MERGE_LABELS`,
      visible: true
    });
    webMap.add(mergeLabelsLayer);
    this.setState(
      {
        mergeLabelsLayer
      },
      () => this.updateMergeLabels()
    );
  };

  featureIsSelected = (feature) => {
    const { selectedFeatures } = this.state;
    if (
      !feature ||
      !feature.attributes ||
      !selectedFeatures ||
      !Array.isArray(selectedFeatures) ||
      !selectedFeatures.length
    )
      return false;
    return selectedFeatures.find(
      (selectedFeature) =>
        selectedFeature.attributes &&
        selectedFeature.attributes.objectId === feature.attributes.objectId
    )
      ? true
      : false;
  };
  handleUpdateFeatures = (features, selected) => {
    const { mergeLayer, selectedFeatures } = this.state;
    const { onUpdateSelectedFeatures } = this.props;
    features.forEach((feature) => {
      if (feature.attributes) {
        feature.attributes.selected = selected ? 1 : 0;
      }
    });
    mergeLayer.applyEdits({
      updateFeatures: features
    });
    const newSelectedFeatures = selected
      ? [...selectedFeatures, ...features]
      : selectedFeatures.filter(
          (selectedFeature) =>
            !features.find(
              (feature) =>
                selectedFeature.attributes &&
                selectedFeature.attributes.objectId ===
                  feature.attributes.objectId
            )
        );
    this.setState(
      {
        selectedFeatures: newSelectedFeatures
      },
      () => {
        this.updateMergeLabels();
      }
    );
    if (onUpdateSelectedFeatures) onUpdateSelectedFeatures(newSelectedFeatures);
  };

  handleClickFeatures = (features) => {
    if (!features || !features.length) return;
    const { selectedFeatures, unselectedFeatures } = features.reduce(
      (result, feature) => {
        if (this.featureIsSelected(feature))
          return {
            ...result,
            unselectedFeatures: [...result.unselectedFeatures, feature]
          };
        else
          return {
            ...result,
            selectedFeatures: [...result.selectedFeatures, feature]
          };
      },
      { selectedFeatures: [], unselectedFeatures: [] }
    );
    if (selectedFeatures.length)
      this.handleUpdateFeatures(selectedFeatures, true);
    if (unselectedFeatures.length)
      this.handleUpdateFeatures(unselectedFeatures, false);
  };

  resetSelection = () => {
    const { selectedFeatures } = this.state;
    this.handleUpdateFeatures(selectedFeatures, false);
  };

  getSelectedFeatures = () => {
    const { selectedFeatures, selectableFeatures } = this.state;

    if (!selectedFeatures || !selectableFeatures) return [];

    return selectedFeatures.map((feature) => {
      return selectableFeatures.find(
        (selectableFeature) =>
          selectableFeature.attributes.objectId === feature.attributes.objectId
      );
    });
  };

  getLanguageLabel = (stringConstant, data) => {
    const { labels } = this.props;
    const label = labels[stringConstant];
    if (!label) return stringConstant;
    return decodeComputedString(label, data);
  };

  projectGeometry = async (geometry) => {
    const { orgSpatialReference, propertySpatialReference } = this.props;
    const { outGeometries, warning, error } = await projectGeometries(
      [geometry],
      propertySpatialReference,
      orgSpatialReference
    );
    if (warning) {
      NotificationManager.warning(
        `${this.getLanguageLabel(warning.message)}.\n${this.getLanguageLabel(
          "SPATIAL_REFERENCE_CALC_MESSAGE_LABEL",
          warning
        )}`,
        this.getLanguageLabel("UNABLE_TO_PROJECT_LABEL"),
        2000
      );
    } else if (error) {
      NotificationManager.error(
        this.getLanguageLabel(error.message),
        this.getLanguageLabel("ERROR_PROJECTING_SPATIAL_REFERENCE_LABEL"),
        0
      );
    }
    return outGeometries[0];
  };
  updateMergeLabels = async () => {
    try {
      const { showAreaLabels, areaUnit, displayUnit, originalFeature } =
        this.props;
      const { mergeLabelsLayer } = this.state;
      const selectedFeatures = this.getSelectedFeatures();
      if (!showAreaLabels || !mergeLabelsLayer) return;
      this.controller.abort();
      this.controller = new AbortController();
      mergeLabelsLayer.removeAll();
      const selectedGraphics = [originalFeature, ...selectedFeatures];
      const selectedGeometry =
        selectedGraphics.length > 1
          ? union(selectedGraphics.map((feature) => feature.geometry))
          : selectedGraphics[0].geometry;
      const labelGeometries = await labelPoints(
        GEOMETRY_SERVICE_URL,
        [selectedGeometry],
        {
          signal: this.controller.signal
        }
      );
      const projectedArea = await this.projectGeometry(selectedGeometry);
      const calcUnit = areaUnit ? areaUnit : UNIT_HECTARES;
      const area = planarArea(projectedArea, calcUnit);
      const label = new Graphic({
        geometry: labelGeometries[0],
        symbol: {
          ...TEXT_SYMBOL_CHEMICALS,
          text: `${roundNumber(area, 2)} ${
            displayUnit ? displayUnit : calcUnit
          }`
        }
      });
      mergeLabelsLayer.add(label);
    } catch (e) {
      if (e.name && e.name === ABORT_ERROR_NAME) {
        const { mergeLabelsLayer } = this.state;
        if (mergeLabelsLayer) mergeLabelsLayer.removeAll();
      }
    }
  };
  render() {
    return <></>;
  }
}
