import { connect } from "react-redux";
import DrawingTool from "./DrawingTool";
import { getWebMap, getMapView, getLabel, getHas3D } from "../../selectors";
import * as actions from "../../actions";

export default connect(
  (state) => ({
    mapView: getMapView(state),
    webMap: getWebMap(state),
    labels: getLabel(state),
    has3D: getHas3D(state)
  }),
  actions,
  null,
  {
    forwardRef: true
  }
)(DrawingTool);
