import { handleActions } from "redux-actions";
import {
  SUCCESSFUL_AUTHENTICATION,
  ERROR_AUTHENTICATION,
  INVALIDATE_AUTHENTICATION,
  CLEAR_ERRORS,
  CLEAR_AUTHENTICATION,
  RESET_LOGGING_OUT,
  SET_AUTH0_CLIENT
} from "../constants";

export const initialState = {
  isAuthenticated: false,
  userId: null,
  token: null,
  error: false,
  loggingOut: false,
  auth0Client: false
};

// @ts-ignore
export default handleActions(
  {
    [SUCCESSFUL_AUTHENTICATION]: (state, { payload }) => ({
      ...state,
      isAuthenticated: true,
      userId: payload.userId,
      token: payload.token,
      error: false
    }),
    [ERROR_AUTHENTICATION]: (state, { payload }) => ({
      ...state,
      isAuthenticated: false,
      userId: null,
      token: null,
      error: payload
    }),
    [CLEAR_AUTHENTICATION]: (state, { payload }) => ({
      ...state,
      isAuthenticated: false,
      userId: null,
      token: null,
      error: false
    }),
    [INVALIDATE_AUTHENTICATION]: (state, { payload }) => ({
      ...state,
      loggingOut: true
    }),
    [RESET_LOGGING_OUT]: (state, { payload }) => ({
      ...state,
      loggingOut: false
    }),
    [CLEAR_ERRORS]: (state, { payload }) => ({
      ...state,
      error: false
    }),
    [SET_AUTH0_CLIENT]: (state, { payload }) => ({
      ...state,
      auth0Client: payload.auth0Client
    })
  },
  initialState
);
