import React, { Component } from "react";
import { Router, globalHistory } from "@reach/router";
import { NotificationContainer } from "react-notifications";
import { ThemeProvider } from "styled-components";
import { GlobalStyle, defaultTheme } from "@agBoxUiKit";
import OrganisationSelector from "../OrganisationSelector";
import Errors from "../Errors";
import MapView from "../MapView";
import Measure from "../widgets/Measure";
import { Loader } from "@agBoxUiKit/core";
import LayerController from "../widgets/LayerController";

import "react-notifications/lib/notifications.css";

import {
  AppWrapper,
  AppContainer,
  LoadingContainer,
  WidgetOuterWrapper,
  LogoImage,
  LogoImageWrapper
} from "@agBoxUiKit/core/layout";
import {
  PLUGIN_SIZE_FULL,
  AGBOX_DOC_TITLE,
  BASE64_LOGO,
  LANDSCAPE,
  PORTRAIT,
  SQUARE,
  URL_DELIMITER_SELECT_ORG
} from "../constants";
import ElevationToggle from "../widgets/ElevationToggle";
import Logout from "../Logout";
import AcceptInvitation from "../AcceptInvitation";
import Login from "../Login";
import Main from "../Main";
/**
 * The highest level component of the app. Holds routing, error modal, styles, widgets, and notifications. Routing is controlled by [Reach Router](https://reach.tech/router).
 *
 * Widgets are rendered based on whether the organisation preferences declare them to be visible.
 *
 * Errors will show anytime an error is added to the redux state.
 */
class App extends Component {
  constructor(props) {
    super(props);
    this.extentTimer = null;
    this.state = {
      viewHeight: window.innerHeight,
      ratio: 1,
      aspect: LANDSCAPE,
      width: 0,
      height: 0,
      showOrgSelector: false
    };
  }
  componentDidMount() {
    this.setShowOrgSelector(globalHistory.location);
    window.addEventListener("resize", () => {
      return this.resizeScreen();
    });
    globalHistory.listen(({ location, action }) => {
      this.setShowOrgSelector(location);
      if (action === "PUSH") {
        this.setDocumentTitle(location.state.docTitle);
      }
    });
    this.resizeScreen();
    this.setupMapViewHandlers();
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.mapView && this.props.mapView) {
      this.setupMapViewHandlers();
    }
  }

  setDocumentTitle = (docTitle) => {
    if (!docTitle) return;
    document.title = `${docTitle}${AGBOX_DOC_TITLE}`;
  };

  setShowOrgSelector = (location) => {
    const showOrgSelector = location.hash === URL_DELIMITER_SELECT_ORG;
    if (this.getShowOrgSelector() !== showOrgSelector) {
      this.setState({
        showOrgSelector
      });
    }
  };

  resizeScreen = () => {
    let aspect = LANDSCAPE;
    let ratio = window.innerWidth / window.innerHeight;
    if (ratio < 3 / 4) {
      aspect = PORTRAIT;
    } else if (ratio > 4 / 3) {
      aspect = LANDSCAPE;
    } else {
      aspect = SQUARE;
    }
    this.setState({
      viewHeight: window.innerHeight,
      ratio,
      aspect
    });
  };
  selectedWorkflowIsPrinting = () => {
    const { selectedWorkflowTitle } = this.props;
    return selectedWorkflowTitle === "Printing";
  };
  widgetIsVisible = (widgetName) => {
    const { orgPreferences } = this.props;
    if (!orgPreferences) return true;
    const { widgets } = orgPreferences;
    if (widgets === undefined) return true;
    return widgets.indexOf(widgetName) !== -1;
  };

  hasExtent = () => {
    const { hasMapViewExtent, showMap } = this.props;
    return hasMapViewExtent && showMap;
  };

  getLoadingText = () => {
    const {
      labels: { LOADING_DATA_LABEL }
    } = this.props;
    const { loadingText } = this.props;
    if (loadingText) return loadingText;

    return LOADING_DATA_LABEL;
  };

  isLoading = () => {
    const { isAuthenticated, loadedUser } = this.props;

    if (!isAuthenticated || !loadedUser || !this.hasExtent()) return true;
    return false;
  };

  isFullWidth = () => {
    const { workflowSize } = this.props;
    return workflowSize === PLUGIN_SIZE_FULL;
  };

  workflowPanelOpen = () => {
    const { workflowPanelOpen } = this.props;
    return workflowPanelOpen === true;
  };

  workflowSize = () => {
    const { workflowSize } = this.props;
    return workflowSize;
  };

  has3D = () => {
    const { has3D } = this.props;
    return has3D === true;
  };

  logoLoader = () => {
    return (
      <LoadingContainer data-name={"loading-container"}>
        <LogoImageWrapper data-name={"LogoImageWrapper"}>
          <LogoImage alt="Landkind logo" src={BASE64_LOGO} />
          <Loader
            loadingText={this.getLoadingText()}
            visible={this.isLoading()}
          />
        </LogoImageWrapper>
      </LoadingContainer>
    );
  };

  getShowOrgSelector = () => {
    const { showOrgSelector } = this.state;
    return showOrgSelector;
  };

  setupMapViewHandlers = () => {
    const { mapView, mapViewClick } = this.props;
    if (!mapView) return;
    mapView.when(() => {
      mapView.on("click", (e) => mapViewClick(e));
    });
  };

  render() {
    const { isAuthenticated, mapViewReady, isLoggingOut } = this.props;
    const { ratio, aspect } = this.state;
    const themed = { ...defaultTheme, ratio, aspect };
    return (
      <ThemeProvider theme={themed}>
        <AppWrapper
          id={"app-container"}
          data-name={"AppWrapper"}
          height={this.state.viewHeight}
        >
          <GlobalStyle />
          {/* If not authenticated then show the login screen */}
          {!isAuthenticated ? (
            <Router>
              {!isLoggingOut && (
                <>
                  <Login default logoLoader={this.logoLoader()} />
                  <AcceptInvitation
                    path={
                      "/invites/:inviteId/organisations/:orgId/inviteToken/:inviteToken"
                    }
                  />
                </>
              )}
              <Logout path="logout" />
            </Router>
          ) : (
            <AppContainer data-name={"AppContainer"}>
              {!this.hasExtent() && this.logoLoader()}
              {/* This is where notifications will be displayed! */}
              <NotificationContainer />
              <Errors />
              {this.getShowOrgSelector() && <OrganisationSelector />}
              {/* This will contain the sidebar and needs to be above the map */}
              {mapViewReady && (
                <Router data-name="Router">
                  <Main path="/org/:orgId/*" />
                </Router>
              )}
              {/* Contains the map */}
              <MapView />
              {this.hasExtent() && !this.isFullWidth() && (
                <WidgetOuterWrapper
                  workflowSize={this.workflowSize()}
                  has3D={this.has3D()}
                >
                  {mapViewReady && this.widgetIsVisible("layers") && (
                    <LayerController
                      disabled={this.selectedWorkflowIsPrinting()}
                    />
                  )}
                  {mapViewReady && this.widgetIsVisible("measure") && (
                    <Measure disabled={this.selectedWorkflowIsPrinting()} />
                  )}
                  {mapViewReady && this.has3D() && (
                    <ElevationToggle
                      disabled={this.selectedWorkflowIsPrinting()}
                    />
                  )}
                </WidgetOuterWrapper>
              )}
            </AppContainer>
          )}
        </AppWrapper>
      </ThemeProvider>
    );
  }
}

export default App;
