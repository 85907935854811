import { connect } from "react-redux";
import {
  getMapView,
  getWebMap,
  getPropertySpatialReference,
  getLabel,
  getOrganisationPreferences
} from "../../../selectors";
import LocationSearch from "./LocationSearch";
import * as actions from "../../../actions";

export default connect(
  (state) => ({
    mapView: getMapView(state),
    webMap: getWebMap(state),
    orgPreferences: getOrganisationPreferences(state),
    propertySpatialReference: getPropertySpatialReference(state),
    labels: getLabel(state)
  }),
  actions
)(LocationSearch);
