import React from "react";
import { TableDateWrap, TableCellContentWrap } from "../../..";
import { InputGroup } from "../../../../../core/components";
import { DatePicker } from "../../../../";
import DeleteCellValueButton from "../Buttons/DeleteCellValueButton";
import PropTypes from "prop-types";

const DateCell = (props) => {
  return (
    <TableDateWrap aria-labelledby={props.headerId}>
      <TableCellContentWrap>
        <InputGroup>
          <DatePicker
            id={props.id}
            dateFormat={props.dateFormat}
            minDate={props.minDate}
            maxDate={props.maxDate}
            value={props.cellValue}
            required={props.required}
            invalid={props.invalid}
            aria-invalid={props.invalid}
            aria-describedby={props.describedBy}
            requiredIcon={props.requiredIcon}
            onChange={(date) => props.handleOnChangeValue(props, date)}
            placeholderLabel={props.placeholderLabel}
            resetLabel={props.resetLabel}
          />
        </InputGroup>
        {props.showDeleteButton && <DeleteCellValueButton {...props} />}
      </TableCellContentWrap>
    </TableDateWrap>
  );
};

DateCell.propTypes = {
  /** The id that is on the corresponding header to the cell */
  headerId: PropTypes.string,
  /** If the field is required */
  required: PropTypes.bool,
  /** An id that refers to a node that has a description for the field. Is important for a11y */
  describedBy: PropTypes.string,
  /** Whether to show the delete value button */
  showDeleteButton: PropTypes.bool,
  /** The format for the datepicker to use */
  dateFormat: PropTypes.string,
  /** The id of the input */
  id: PropTypes.string,
  /** The min date in the available range - does not need to be set*/
  minDate: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  /** The max date in the available range - does not need to be set */
  maxDate: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  /** The date value */
  cellValue: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.string
  ]),
  /** Icon to show when date field is required */
  requiredIcon: PropTypes.shape({
    type: PropTypes.string,
    iconColor: PropTypes.string,
    iconWidth: PropTypes.string,
    iconHeight: PropTypes.string
  }),
  /** Called on change of date value */
  handleOnChangeValue: PropTypes.func
};

export default DateCell;
