import React from "react";
import { Fieldset, Input, Label, Legend } from "../../../../../core/components";
import PropTypes from "prop-types";

const CheckboxInput = (props) => {
  function onChange(e) {
    const { handleUpdateValue, attribute, value } = props;
    const newValue = getValue(e.target.value);
    handleUpdateValue(
      attribute,
      e.target.checked
        ? [...value, newValue]
        : value.filter((value) => value !== newValue)
    );
  }

  function getValue(value) {
    const { options } = props;
    const matchingValue = value
      ? options.find((option) => option.value.toString() === value.toString())
      : value;
    return matchingValue ? matchingValue.value : value;
  }
  return (
    <Fieldset>
      {props.label && (
        <Legend styletype="label" type={!props.isValid ? "formError" : ""}>
          {props.label}
        </Legend>
      )}
      {props.options.map((option) => {
        return (
          <Label
            key={`${props.attribute}-input-${option.value}`}
            htmlFor={`${props.attribute}-input-${option.value}`}
            type={!props.isValid ? "formError" : ""}
            labelStyle={props.labelStyle ? props.labelStyle : ""}
          >
            <Input
              id={`${props.attribute}-input-${option.value}`}
              type={"checkbox"}
              name={props.attribute}
              value={option.value}
              fieldLabel={option.title}
              required={props.required}
              checked={option.checked}
              onChange={onChange}
              disabled={props.disabled}
              aria-invalid={!props.isValid}
              aria-label={option.ariaLabel}
              aria-describedby={
                props.helpText
                  ? `InputWrapper-${props.attribute}-help-text`
                  : null
              }
            />
            {option.title && <span>{option.title}</span>}
          </Label>
        );
      })}
    </Fieldset>
  );
};

CheckboxInput.propTypes = {
  labelStyle: PropTypes.string,
  attribute: PropTypes.string,
  isValid: PropTypes.bool,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  /** Object type is just for testing purposes */
  handleUpdateValue: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  required: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool
      ]),
      title: PropTypes.string,
      checked: PropTypes.bool,
      /** populates the aria-label attribute. Useful if no title should be displayed */
      ariaLabel: PropTypes.string
    })
  )
};

export default CheckboxInput;
