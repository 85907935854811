import React, { Component } from "react";
import CheckboxInput from "../../../plugin/components/FeatureDetailsComponent/subcomponents/Inputs/CheckboxInput";
import DateInput from "../../../plugin/components/FeatureDetailsComponent/subcomponents/Inputs/DateInput";
import NonEditableInput from "../../../plugin/components/FeatureDetailsComponent/subcomponents/Inputs/NonEditableInput";
import NumberInput from "../../../plugin/components/FeatureDetailsComponent/subcomponents/Inputs/NumberInput";
import RadioSetInput from "../../../plugin/components/FeatureDetailsComponent/subcomponents/Inputs/RadioSetInput";
import SelectInput from "../../../plugin/components/FeatureDetailsComponent/subcomponents/Inputs/SelectInput";
import StringInput from "../../../plugin/components/FeatureDetailsComponent/subcomponents/Inputs/StringInput";
import Header from "./subcomponents/Header";
import CellButton from "./subcomponents/CellButton";
import Cell from "./subcomponents/Cell";
import { Table, TableBody, TableBodyCellItem, TableBodyRow } from "..";
import PropTypes from "prop-types";
import TimeInput from "../../../plugin/components/FeatureDetailsComponent/subcomponents/Inputs/TimeInput";
import { isNullOrUndefined } from "../../../../App/utils";

export default class TableComponent extends Component {
  static propTypes = {
    /** A min width for the table. Optional. */
    minWidth: PropTypes.string,
    /** Called when a cell value is changed in editable cells */
    handleOnChangeValue: PropTypes.func,
    headers: PropTypes.arrayOf(
      PropTypes.shape({
        /** The attribute or other item name for the header. E.g. if this was displaying features in a table, the key would be the attribute name */
        attribute: PropTypes.string,
        /** The colSpan for the header cell */
        colSpan: PropTypes.number,
        /** The width of the header cell. Defaults to 'auto'. */
        width: PropTypes.string,
        /** The label for the header */
        label: PropTypes.string
      })
    ),
    /** An array of rows, each of which is an array of cell objects */
    rows: PropTypes.arrayOf(
      PropTypes.shape({
        /** Whether to have a border at the bottom of the row. Useful when you have subrows */
        showBorder: PropTypes.bool,
        cells: PropTypes.arrayOf(
          PropTypes.shape({
            /** The colSpan for the cell */
            colSpan: PropTypes.number,
            /** The rowSpan for the cell */
            rowSpan: PropTypes.number,
            /** Domain values for select, boolean, or troolean fields */
            options: PropTypes.arrayOf(
              PropTypes.shape({
                value: PropTypes.any,
                title: PropTypes.string
              })
            ),
            /** Validation for max length of string fields */
            maxLength: PropTypes.number,
            /** Validation for min length of string fields */
            minLength: PropTypes.number,
            /** Validation for pattern of string fields */
            pattern: PropTypes.string,
            /** Validation for max of number fields */
            max: PropTypes.number,
            /** Validation for min of number fields */
            min: PropTypes.number,
            /** Whether field is required */
            required: PropTypes.bool,
            /** Validation for minDate for date fields */
            minDate: PropTypes.string,
            /** Validation for maxDate for date fields */
            maxDate: PropTypes.string,
            /** Validation for minTime for time fields */
            minTime: PropTypes.string,
            /** Validation for maxTime for time fields */
            maxTime: PropTypes.string,
            /** The field type of the cell */
            fieldType: PropTypes.oneOf([
              "string",
              "boolean",
              "troolean",
              "select",
              "integer",
              "single",
              "double",
              "float",
              "percentage",
              "checkbox",
              "date",
              "time",
              "node"
            ]),
            /** The attribute or id for the cell. E.g. in a table of features, this would be the attribute it represents */
            attribute: PropTypes.string,
            /** Whether the cell values are editable */
            editable: PropTypes.bool,
            /** The values for the cell*/
            values: PropTypes.arrayOf(PropTypes.any),
            /** Any buttons that will be visible in the cell */
            buttons: PropTypes.arrayOf(
              PropTypes.shape({
                /** Whether the button will be directly below all cell values (vertical) or have one next to each value (horizontal) */
                align: PropTypes.oneOf(["vertical", "horizontal"]),
                /** Called on click of button */
                onClick: PropTypes.func,
                /** The title string for the button. This is useful if the button doesn't have a label (e.g. an icon only is visible) but still need some sort of accessible description for the button */
                title: PropTypes.string,
                /** The label to display in the button (if any) */
                label: PropTypes.string,
                /** The icon to display in the button (if any) */
                icon: PropTypes.shape({
                  type: PropTypes.string,
                  /** The css color (rgb, hex, color name) for the background of the icon */
                  bgColor: PropTypes.string,
                  /** The shape around the icon, e.g. round */
                  bgShape: PropTypes.string,
                  /** The width, in pixels, of the background */
                  bgWidth: PropTypes.string,
                  /** The height, in pixels, of the background */
                  bgHeight: PropTypes.string,
                  /** The height, in pixels, of the icon */
                  iconHeight: PropTypes.string,
                  /** The width, in pixels, of the icon */
                  iconWidth: PropTypes.string,
                  /** The css color (rgb, hex, color name) for the icon. Can also be a function that returns a string */
                  iconColor: PropTypes.oneOfType([
                    PropTypes.string,
                    PropTypes.func
                  ]),
                  /** If the icon should be spinning or not */
                  spinning: PropTypes.bool,
                  /** The path to the icon file, if not the standard. If the file does not exist, should default to a generic error icon*/
                  path: PropTypes.string,
                  /** the rotation number in degrees for the icon */
                  rotation: PropTypes.number
                })
              })
            )
          })
        )
      })
    )
  };
  getCellValueItem = (cellItem) => {
    const { handleOnChangeValue } = this.props;
    const {
      fieldType,
      valueIndex,
      editable,
      value,
      buttons = [],
      id,
      options
    } = cellItem;
    const inputKey = `${id}-${valueIndex}`;
    if (isNullOrUndefined(value)) return null;
    const props = {
      ...cellItem,
      attribute: inputKey,
      handleUpdateValue: (attr, value) => handleOnChangeValue(cellItem, value)
    };

    if (!editable && fieldType !== "checkbox" && fieldType !== "node")
      return (
        <NonEditableInput
          {...{ ...cellItem, useListItem: false }}
          key={inputKey}
        />
      );
    const inlineButtons = buttons.reduce((result, button, i) => {
      if (button.align !== "horizontal") return result;
      return [
        ...result,
        <CellButton
          key={`${inputKey}-button-inline-${i}`}
          button={button}
          cellItem={cellItem}
        />
      ];
    }, []);

    let input = null;

    switch (fieldType) {
      case "node":
        input = value;
        break;
      case "boolean":
      case "troolean":
        input = <RadioSetInput {...props} />;
        break;
      case "integer":
      case "single":
      case "double":
      case "float":
      case "percentage":
        input = <NumberInput {...props} />;
        break;
      case "select":
        input = <SelectInput {...props} />;
        break;
      case "date":
        input = <DateInput {...props} />;
        break;
      case "time":
        input = <TimeInput {...props} />;
        break;
      case "checkbox":
        input = (
          <CheckboxInput
            {...{
              ...props,
              disabled: !editable,
              options: options.map((option) => ({
                ...option,
                checked: Array.isArray(value)
                  ? value.includes(option.value)
                  : false
              }))
            }}
          />
        );
        break;
      case "string":
      default:
        input = <StringInput {...props} />;
        break;
    }

    return (
      <TableBodyCellItem key={inputKey}>
        {input}
        {inlineButtons}
      </TableBodyCellItem>
    );
  };

  getCellInfo = (cell) => {
    const { attribute, rowIndex, cellIndex, buttons = [], values = [] } = cell;
    const id = `${attribute}-row-${rowIndex}-cell-${cellIndex}`;
    const items = values.map((value, valueIndex) =>
      this.getCellValueItem({
        ...cell,
        id,
        value,
        valueIndex
      })
    );
    const verticalButtons = buttons.reduce((result, button) => {
      if (button.align !== "vertical") return result;
      return [...result, button];
    }, []);

    return {
      ...cell,
      items,
      buttons: verticalButtons,
      id
    };
  };

  getTableBodyContent = () => {
    const { rows, noDataLabel, headers = [] } = this.props;
    if (rows && rows.length)
      return rows.map((row, rowIndex) => (
        <TableBodyRow key={`row-${rowIndex}`} showBorder={row.showBorder}>
          {row.cells.map((cell, cellIndex) => (
            <Cell
              key={`${cell.attribute}-row-${rowIndex}-cell-${cellIndex}`}
              {...this.getCellInfo({ ...cell, cellIndex, rowIndex })}
            />
          ))}
        </TableBodyRow>
      ));
    else if (noDataLabel)
      return (
        <TableBodyRow>
          <Cell colSpan={headers.length || 1} items={[noDataLabel]} />
        </TableBodyRow>
      );
    else return null;
  };

  render() {
    return (
      <Table minWidth={this.props.minWidth}>
        <Header headers={this.props.headers} />
        <TableBody>{this.getTableBodyContent()}</TableBody>
      </Table>
    );
  }
}
