import React from "react";
import PropTypes from "prop-types";
import {
  FeatureDetailsItem,
  FeatureDetailsItemInputHelpText,
  FeatureDetailsItemValue,
  FeatureDetailsItemWrap,
  FormItemFieldContainer,
  FormItemWrapper,
  FeatureDetailsItemNonEditableTitle,
  FeatureDetailsItemContainer
} from "../../..";

const NonEditableInput = (props) => {
  const FeatureDetailsContainer = ({ children }) =>
    props.useListItem ? (
      <FeatureDetailsItem isHighlighted={props.isHighlighted}>
        {children}
      </FeatureDetailsItem>
    ) : (
      <FeatureDetailsItemContainer
        isHighlighted={props.isHighlighted}
        data-name="FeatureDetailsItemContainer"
      >
        {children}
      </FeatureDetailsItemContainer>
    );

  return (
    <FeatureDetailsContainer>
      <FeatureDetailsItemWrap>
        <FormItemWrapper>
          <FormItemFieldContainer>
            <FeatureDetailsItemNonEditableTitle>
              {props.label}
            </FeatureDetailsItemNonEditableTitle>
            <FeatureDetailsItemValue>
              {props.value}
              {props.unit ? ` ${props.unit}` : ""}
            </FeatureDetailsItemValue>
          </FormItemFieldContainer>
        </FormItemWrapper>
        {props.helpText && (
          <FeatureDetailsItemInputHelpText
            id={`NonEditableInput-${props.label}-help-text`}
            invalid={false}
            aria-live="assertive"
          >
            {props.helpText}
          </FeatureDetailsItemInputHelpText>
        )}
      </FeatureDetailsItemWrap>
    </FeatureDetailsContainer>
  );
};

NonEditableInput.propTypes = {
  helpText: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.number
  ]),
  isHighlighted: PropTypes.bool,
  unit: PropTypes.string
};

export default NonEditableInput;
