import { connect } from "react-redux";
import FeatureDetails from "./FeatureDetails";
import {
  getMapView,
  getLabel,
  getObjectIdField,
  getAreaUnit
} from "../../selectors";

export default connect((state) => ({
  mapView: getMapView(state),
  labels: getLabel(state),
  objectIdField: getObjectIdField(state),
  areaUnit: getAreaUnit(state)
}))(FeatureDetails);
