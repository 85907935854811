import { connect } from "react-redux";
import LayerControllerWidget from "./LayerController";
import {
  getActiveWidget,
  getSelectedOrg,
  getWebMap,
  getBasemapId,
  getLabel,
  getImageryDatesForProperty,
  getSelectedPropId,
  getImageryLoadingForProperty,
  getUserOrgRoles,
  getSelectedProperty,
  getSelectedPropertyGroup
} from "../../selectors";
import * as actions from "../../actions";

export default connect(
  (state) => ({
    selectedPropertyGroup: getSelectedPropertyGroup(state),
    selectedProperty: getSelectedProperty(state),
    userOrgRoles: getUserOrgRoles(state),
    activeWidget: getActiveWidget(state),
    selectedOrg: getSelectedOrg(state),
    webMap: getWebMap(state),
    basemapId: getBasemapId(state),
    labels: getLabel(state),
    getImageryDatesForProperty: (propId) =>
      getImageryDatesForProperty(state, propId),
    propId: getSelectedPropId(state),
    getImageryLoadingForProperty: (propId) =>
      getImageryLoadingForProperty(state, propId)
  }),
  actions
)(LayerControllerWidget);
