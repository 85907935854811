import React from "react";
import PropTypes from "prop-types";
import { Label } from "../../../../../core/components";
import DatePicker from "../../../DatePicker/DatePicker";
import { defaultTheme } from "../../../../..";

const DateInput = (props) => {
  function onChange(date) {
    const { handleUpdateValue, attribute } = props;
    handleUpdateValue(attribute, date);
  }
  return (
    <React.Fragment>
      <Label
        htmlFor={`${props.attribute}-input`}
        type={!props.isValid ? "formError" : ""}
        styletype="form-label"
      >
        {props.label}
      </Label>
      <DatePicker
        id={`${props.attribute}-input`}
        dateFormat="DD/MM/YYYY"
        minDate={props.minDate}
        maxDate={props.maxDate}
        value={props.value}
        onChange={onChange}
        required={props.required}
        invalid={!props.isValid}
        requiredIcon={
          props.required && !props.isValid
            ? {
                type: "error",
                iconColor: defaultTheme.agErrorRed,
                iconWidth: "1.5em",
                iconHeight: "1em",
                bgWidth: "1.5em",
                bgHeight: "2.25em"
              }
            : null
        }
        resetLabel={props.resetLabel}
        placeholderLabel={props.placeholderLabel}
        openToDate={props.openToDate}
      />
    </React.Fragment>
  );
};

DateInput.propTypes = {
  attribute: PropTypes.string,
  isValid: PropTypes.bool,
  label: PropTypes.string,
  minDate: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  maxDate: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  value: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  required: PropTypes.bool,
  handleUpdateValue: PropTypes.func,
  resetLabel: PropTypes.string,
  placeholderLabel: PropTypes.string
};

export default DateInput;
