import { connect } from "react-redux";
import Login from "./Login";
import * as actions from "../actions";
import { getLabel } from "../selectors";

export default connect(
  (state) => ({
    labels: getLabel(state)
  }),
  actions
)(Login);
