import { createActions } from "redux-actions";
import {
  LOADED_USER,
  ERROR_USER,
  LOAD_USER,
  LOAD_INVITATIONS,
  LOADED_INVITATIONS,
  ACCEPT_INVITATION,
  ACCEPTED_INVITATION,
  LOAD_INVITATION,
  LOADED_INVITATION,
  ACCEPT_INVITATION_PUBLIC,
  UPDATE_USER_PREFERENCES,
  UPDATED_USER_PREFERENCES
} from "../constants";

export const {
  loadedUser,
  errorUser,
  loadUser,
  loadInvitations,
  loadedInvitations,
  acceptInvitation,
  acceptedInvitation,
  loadInvitation,
  loadedInvitation,
  acceptInvitationPublic,
  updateUserPreferences,
  updatedUserPreferences
} = createActions({
  [LOADED_USER]: (user) => user,
  [ERROR_USER]: (error) => error,
  [LOAD_USER]: (userId) => ({ userId }),
  [LOAD_INVITATIONS]: (userId) => ({ userId }),
  [LOADED_INVITATIONS]: ({ meta, items }) => ({ meta, items }),
  [ACCEPT_INVITATION]: (orgId, inviteId) => ({ orgId, inviteId }),
  [ACCEPTED_INVITATION]: (orgId, inviteId) => ({ orgId, inviteId }),
  [LOAD_INVITATION]: (inviteId, orgId, inviteToken, emailAddress) => ({
    inviteId,
    orgId,
    inviteToken,
    emailAddress
  }),
  [LOADED_INVITATION]: (invitation) => invitation,
  [ACCEPT_INVITATION_PUBLIC]: (
    inviteId,
    orgId,
    inviteToken,
    emailAddress,
    password
  ) => ({
    inviteId,
    orgId,
    inviteToken,
    emailAddress,
    password
  }),
  [UPDATE_USER_PREFERENCES]: (userId, preferences) => ({
    userId,
    preferences
  }),
  [UPDATED_USER_PREFERENCES]: (userDetails) => userDetails
});
