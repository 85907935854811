import React, { Component } from "react";
import {
  SPLIT_CROP_SYMBOL,
  TEMP_LAYER_PREFIX,
  GEOMETRY_TYPE_POLY,
  SPLIT_DRAW_SYMBOL,
  GEOMETRY_SERVICE_URL,
  CROP_SPLIT_EDGE_LABEL,
  MEASURE_WIDGET_LINE_SYMBOL,
  ABORT_ERROR_NAME,
  SPLIT_CROP_LABEL_SYMBOL,
  DEFAULT_UNIT_HECTARES
} from "../../constants";

import DrawingTool from "../DrawingTool";
import { NotificationManager } from "react-notifications";
import {
  roundNumber,
  toLocale,
  decodeComputedString,
  projectGeometries,
  isNullOrUndefined
} from "../../App/utils";
import PropTypes from "prop-types";

import { Label, Input } from "../../AgBoxUIKit/core/components";
import { PrinterPreviewControlContainer } from "../../AgBoxUIKit/core/layout";
import GraphicsLayer from "@arcgis/core/layers/GraphicsLayer";
import Graphic from "@arcgis/core/Graphic";
import { labelPoints } from "@arcgis/core/rest/geometryService";
import {
  contains,
  cut,
  intersect,
  intersects,
  overlaps,
  planarArea,
  planarLength,
  union
} from "@arcgis/core/geometry/geometryEngine";
import Polygon from "@arcgis/core/geometry/Polygon";
import Polyline from "@arcgis/core/geometry/Polyline";
import Point from "@arcgis/core/geometry/Point";

/** This component is used to handle the splitting of features. It does the work of splitting, and passes the results back to its parent component. It converts all measurements to the provided property or organistion spatial references, and if these are invalid it defaults back to standard 102100, and shows a notification to the user. */
export default class FeatureSplit extends Component {
  static propTypes = {
    /** The spatial reference set on the property */
    propertySpatialReference: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]),
    /** The spatial reference set on the organisation */
    orgSpatialReference: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]),
    /** The organsation preferences for units, as well as general information */
    orgPreferences: PropTypes.object,
    /** The [graphics](https://developers.arcgis.com/javascript/latest/api-reference/esri-Graphic.html) drawn as a result of the split */
    graphics: PropTypes.array,
    /** The [starting graphic](https://developers.arcgis.com/javascript/latest/api-reference/esri-Graphic.html) before the split */
    feature: PropTypes.object,
    /** Called when the graphic has been split or reset, and passes the new graphics as a parameter. */
    updateGraphics: PropTypes.func
  };
  constructor(props) {
    super(props);
    this.state = {
      explodeResults: false,
      graphics: [],
      cutGeometries: null,
      saving: false,
      resetting: false,
      loading: false,
      startingPoint: null,
      vertexIndex: 0,
      sketchTool: null
    };

    this.geometryServiceController = new AbortController();
    this.timeout = null;
  }
  componentDidMount() {
    this.setupSplit();
    this.setupEdgeLabels();
  }

  componentWillUnmount() {
    this.geometryServiceController.abort();
    const { webMap } = this.props;
    const layers = webMap.layers.items.filter(
      (layer) =>
        layer.title === `${TEMP_LAYER_PREFIX}_SPLIT_LABELS` ||
        layer.title === `${TEMP_LAYER_PREFIX}_SPLIT` ||
        layer.title === `${TEMP_LAYER_PREFIX}_SPLIT_EDGE` ||
        layer.title === `${TEMP_LAYER_PREFIX}_LINE_CLIP`
    );
    webMap.removeMany(layers);
  }

  setupEdgeLabels = () => {
    const { webMap } = this.props;

    const splitEdgeLayer = new GraphicsLayer({
      title: `${TEMP_LAYER_PREFIX}_SPLIT_EDGE`,
      visible: true
    });
    const splitLineClipLayer = new GraphicsLayer({
      title: `${TEMP_LAYER_PREFIX}_LINE_CLIP`,
      visible: true
    });

    webMap.addMany([splitEdgeLayer, splitLineClipLayer]);

    this.renderSplitLabels();
  };

  setupSplit = () => {
    const { webMap } = this.props;
    const originalFeature = this.originalFeature();
    const { attributes, geometry } = originalFeature;
    const featureGraphic = new Graphic({
      attributes,
      geometry,
      symbol: SPLIT_CROP_SYMBOL,
      visible: true
    });

    const splitLayer = new GraphicsLayer({
      title: `${TEMP_LAYER_PREFIX}_SPLIT`,
      graphics: [featureGraphic],
      visible: true
    });

    const splitLabelsLayer = new GraphicsLayer({
      title: `${TEMP_LAYER_PREFIX}_SPLIT_LABELS`,
      visible: true
    });

    webMap.addMany([splitLayer, splitLabelsLayer]);
  };

  edgeLabelLayer = () => {
    const { webMap } = this.props;
    const edgeLayer = webMap.layers.items.find(
      (layer) => layer.title === `${TEMP_LAYER_PREFIX}_SPLIT_EDGE`
    );
    return edgeLayer;
  };

  edgeLineLayer = () => {
    const { webMap } = this.props;
    const edgeLayer = webMap.layers.items.find(
      (layer) => layer.title === `${TEMP_LAYER_PREFIX}_LINE_CLIP`
    );
    return edgeLayer;
  };

  renderEdgeLabel = (index) => {
    const edgeLayer = this.edgeLabelLayer();
    const labelGraphic = new Graphic({
      attributes: {
        name: index
      },
      geometry: null,
      symbol: {
        ...CROP_SPLIT_EDGE_LABEL,
        text: ""
      },
      visible: true
    });
    edgeLayer.add(labelGraphic);
  };

  splitLayer = () => {
    const { webMap } = this.props;
    const splitLayer = webMap.layers.items.find(
      (layer) => layer.title === `${TEMP_LAYER_PREFIX}_SPLIT`
    );
    return splitLayer;
  };

  splitLabelsLayer = () => {
    const { webMap } = this.props;
    const splitLabelsLayer = webMap.layers.items.find(
      (layer) => layer.title === `${TEMP_LAYER_PREFIX}_SPLIT_LABELS`
    );
    return splitLabelsLayer;
  };

  renderSplitGraphics = () => {
    const graphics = this.getGraphics();
    if (graphics.length === 0) return;

    const splitLayer = this.splitLayer();

    splitLayer.removeAll();

    graphics.forEach((graphic) => {
      splitLayer.add(
        new Graphic({
          geometry: graphic.geometry,
          symbol: SPLIT_CROP_SYMBOL,
          attributes: {
            title: graphic.attributes.title
          }
        })
      );
    });
  };

  calculateArea = async (geometry) => {
    const projectedGeometry = await this.projectToSpatialReference([geometry]);
    if (!projectedGeometry) return;
    const areaUnit = this.areaUnit();
    const area = planarArea(projectedGeometry[0], areaUnit);
    return roundNumber(area);
  };

  calculateLength = async (geometry) => {
    const projectedGeometry = await this.projectToSpatialReference([geometry]);
    if (!projectedGeometry) return;
    const length = planarLength(projectedGeometry[0], "meters");
    return roundNumber(length);
  };

  getMatchingUnit = () => {
    const { orgPreferences } = this.props;
    const { units } = orgPreferences;
    const { preferredAreaUnit, areaUnits } = units;
    const matchingUnit =
      preferredAreaUnit !== ""
        ? areaUnits.find((unit) => unit.unit === preferredAreaUnit)
        : null;
    return matchingUnit;
  };

  areaUnit = () => {
    const { orgPreferences } = this.props;
    const { units } = orgPreferences;
    if (!units) return DEFAULT_UNIT_HECTARES;
    return units.preferredAreaUnit && this.getMatchingUnit()
      ? this.getMatchingUnit().unit
      : units.areaUnits && units.areaUnits.length > 0
      ? units.areaUnits[0].unit
      : DEFAULT_UNIT_HECTARES;
  };

  renderSplitLabels = async () => {
    try {
      const graphics = this.getGraphics();

      if (graphics.length === 0) return;
      const splitLabelsLayer = this.splitLabelsLayer();

      splitLabelsLayer.removeAll();

      await Promise.all(
        graphics.map(async (graphic) => {
          if (graphic.geometry) {
            const labelGeometries = await labelPoints(
              GEOMETRY_SERVICE_URL,
              [graphic.geometry],
              {
                signal: this.geometryServiceController.signal
              }
            );
            const area = await this.calculateArea(graphic.geometry);
            if (!area) return;
            const labelGraphic = new Graphic({
              geometry: labelGeometries[0],
              symbol: {
                ...SPLIT_CROP_LABEL_SYMBOL,
                text: `${toLocale(area)} ${DEFAULT_UNIT_HECTARES}`
              },
              attributes: {
                name: area
              }
            });
            splitLabelsLayer.add(labelGraphic);
          }
        })
      );
    } catch (e) {
      if (e.name && e.name === ABORT_ERROR_NAME) {
        const splitLabelsLayer = this.splitLabelsLayer();

        splitLabelsLayer.removeAll();
      }
    } finally {
      if (this.whenReseting()) {
        this.clearEdgeLabels();
      }
    }
  };

  updateEdgeLabelPosition = (coordinates) => {
    const { startingPoint, vertexIndex } = this.state;
    if (!startingPoint) return;
    const { mapView } = this.props;
    const layer = this.edgeLabelLayer();

    const midX = (startingPoint.x + coordinates[0]) / 2;
    const midY = (startingPoint.y + coordinates[1]) / 2;

    const labelPoint = new Point({
      x: midX,
      y: midY,
      spatialReference: mapView.spatialReference
    });
    const { items } = layer.graphics;
    if (items.length > 0 && items[vertexIndex]) {
      items[vertexIndex].geometry = labelPoint;
    }
    return labelPoint;
  };

  clearEdgeLabels = () => {
    const layer = this.edgeLabelLayer();
    const lineLayer = this.edgeLineLayer();
    layer.removeAll();
    lineLayer.removeAll();
  };

  getGraphics = () => {
    const { graphics } = this.props;
    return graphics;
  };

  getCutGeometries = () => {
    const { cutGeometries } = this.state;
    return cutGeometries;
  };

  getIntersectingLinePart = (polyline) => {
    const { vertexIndex } = this.state;
    const graphics = this.getGraphics();
    const layer = this.edgeLineLayer();
    const { items } = layer.graphics;
    let graphic;

    const graphicExists = items.length === vertexIndex + 1;

    if (graphicExists) {
      graphic = items[vertexIndex];
    }

    let newGeometry;
    const isWithin = contains(graphics[0].geometry, polyline);

    if (!intersects(polyline, graphics[0].geometry) || isWithin) {
      newGeometry = null;
    } else {
      newGeometry = cut(polyline, {
        type: "polyline",
        paths: graphics[0].geometry.rings,
        spatialReference: graphics[0].geometry.spatialReference
      })[1];
    }
    if (graphicExists) {
      graphic.geometry = newGeometry;
    } else {
      const newLine = new Graphic({
        geometry: newGeometry,
        symbol: MEASURE_WIDGET_LINE_SYMBOL,
        visible: true
      });
      layer.add(newLine);
    }
    return isWithin ? polyline : newGeometry;
  };

  getLanguageLabel = (stringConstant, data) => {
    const { labels } = this.props;
    const label = labels[stringConstant];
    if (!label) return stringConstant;
    return decodeComputedString(label, data);
  };

  projectToSpatialReference = async (geometries) => {
    if (!geometries || geometries.length === 0) return [];

    const { orgSpatialReference, propertySpatialReference } = this.props;

    const { outGeometries, warning, error } = await projectGeometries(
      geometries,
      propertySpatialReference,
      orgSpatialReference
    );

    if (warning) {
      NotificationManager.warning(
        `${this.getLanguageLabel(warning.message)}.\n${this.getLanguageLabel(
          "SPATIAL_REFERENCE_CALC_MESSAGE_LABEL",
          warning
        )}`,
        this.getLanguageLabel("UNABLE_TO_PROJECT_LABEL"),
        2000
      );
    } else if (error) {
      NotificationManager.error(
        this.getLanguageLabel(error.message),
        this.getLanguageLabel("ERROR_PROJECTING_SPATIAL_REFERENCE_LABEL"),
        0
      );
    }

    return outGeometries;
  };

  handleUpdateEdgeDistance = async (coordinates) => {
    try {
      this.geometryServiceController.abort();
      this.geometryServiceController = new AbortController();
      const { startingPoint, vertexIndex } = this.state;
      if (!startingPoint) return;
      const { mapView } = this.props;
      const layer = this.edgeLabelLayer();
      const line = new Polyline({
        paths: [coordinates, [startingPoint.x, startingPoint.y]],
        spatialReference: mapView.spatialReference
      });

      let symbolText;

      //find the part of the line that actually intersects
      const overlappingLine = this.getIntersectingLinePart(line);
      if (!overlappingLine) {
        symbolText = "";
      } else {
        const length = await this.calculateLength(overlappingLine);
        symbolText = !isNullOrUndefined(length) ? `${toLocale(length)}m` : "";
      }

      if (
        layer.graphics.items.length > 0 &&
        layer.graphics.items[vertexIndex]
      ) {
        const symbol = layer.graphics.items[vertexIndex].symbol.clone();
        symbol.text = symbolText;
        layer.graphics.items[vertexIndex].symbol = symbol;
      }
    } catch (e) {
      if (e.name === ABORT_ERROR_NAME) {
        console.log(e);
      }
    }
  };

  handleCreateSketchComplete = (graphic) => {
    this.setState({
      cutGeometries: graphic,
      loading: true
    });
    this.createSplitGraphics(graphic);
  };

  checkOverlap = (graphic) => {
    if (!graphic) return false;

    const graphics = this.getGraphics();

    const overlap = graphics.some((graphicItem) => {
      return (
        overlaps(graphicItem.geometry, graphic.geometry) ||
        contains(graphicItem.geometry, graphic.geometry)
      );
    });
    return overlap;
  };

  cutoutIntersectsOriginalGeometry = (cutout) => {
    const originalFeature = this.originalFeature();
    return intersects(cutout, originalFeature.geometry);
  };

  isMultipartPolygon = () => {
    const originalFeature = this.originalFeature();
    return originalFeature.geometry.rings.length > 1;
  };

  handleSplitMultiPartPolygon = (graphic, cutterLine) => {
    const { mapView } = this.props;
    const originalFeature = this.originalFeature();
    //explode rings and trun into separate polygons
    const explodedRings = originalFeature.geometry.rings.map((ring) => {
      return new Polygon({
        spatialReference: mapView.spatialReference,
        rings: ring
      });
    });

    //cut the graphic from each poly part
    const polysWithGraphicsRemoved = explodedRings
      .map((poly) => {
        if (
          !contains(graphic.geometry, poly) &&
          !overlaps(graphic.geometry, poly)
        )
          return poly;
        const cutout = cut(poly, cutterLine);
        //if the cutout length is 1 this means that that part is the same as the cut graphic, so we ignore it because we will add it in later
        return cutout && cutout.length > 1 ? cutout[0] : false;
      })
      .filter((cut) => cut !== false);
    //put the newly cut poly parts back together
    const unionedPolys = union(polysWithGraphicsRemoved);
    return unionedPolys;
  };

  createSplitGraphics = async (graphic) => {
    try {
      const originalFeature = this.originalFeature();
      const cutGraphic = await this.cutOverlappingSketchGraphic(graphic);
      let cutterLine = {
        type: "polyline",
        paths: cutGraphic.geometry.rings,
        spatialReference: cutGraphic.geometry.spatialReference
      };

      let cutt;
      if (this.isMultipartPolygon()) {
        const multipartPolysSplit = this.handleSplitMultiPartPolygon(
          graphic,
          cutterLine
        );
        cutt = [multipartPolysSplit, cutGraphic.geometry];
      } else {
        cutt = cut(originalFeature.geometry, cutterLine);
      }
      const newGraphics = [];
      cutt.forEach((cutGeometry) => {
        if (this.getExplodeResults() && cutGeometry.rings.length > 1) {
          cutGeometry.rings.forEach((ring) => {
            const newGeometry = new Polygon();
            newGeometry.addRing(ring);
            newGeometry.spatialReference =
              originalFeature.geometry.spatialReference;
            newGraphics.push({
              geometry: newGeometry,
              attributes: {
                title: originalFeature.attributes.title
              }
            });
          });
        } else {
          newGraphics.push({
            geometry: cutGeometry,
            attributes: {
              title: originalFeature.attributes.title
            }
          });
        }
      });

      const { updateGraphics } = this.props;
      await updateGraphics(newGraphics);
      this.renderSplitGraphics();
      this.renderSplitLabels();
    } catch (e) {
      if (process.env.NODE_ENV === "development") console.error(e);
    }
  };

  onSketchToolCreate = (sketchTool) => {
    this.setState({
      sketchTool
    });
  };

  sketchLayer = () => {
    const { webMap } = this.props;
    const sketchLayer = webMap.layers.items.find(
      (layer) => layer.title === `${TEMP_LAYER_PREFIX}_SKETCH`
    );
    return sketchLayer;
  };

  handleSketchReset = () => {
    const { sketchTool } = this.state;
    const sketchLayer = this.sketchLayer();
    sketchLayer.removeAll();

    sketchTool.create("polygon");
  };

  cutOverlappingSketchGraphic = async (graphic) => {
    try {
      const { mapView } = this.props;
      const originalFeature = this.originalFeature();

      const intersection = intersect(
        graphic.geometry,
        originalFeature.geometry
      );
      const layer = this.sketchLayer();
      const { items } = layer.graphics;
      const newGraphic = items[0].clone();
      layer.removeAll();
      newGraphic.geometry = intersection;
      newGraphic.symbol = SPLIT_DRAW_SYMBOL;
      layer.add(newGraphic);
      const edgeLabelsLayer = this.edgeLabelLayer();
      edgeLabelsLayer.removeAll();
      const ringSets = [].concat(...intersection.rings);
      const edgeLines = ringSets
        .map((coordinates, index) => {
          if (index === ringSets.length - 1) return false;
          const point1 = coordinates;
          const point2 = ringSets[index + 1];
          return new Polyline({
            paths: [point1, point2],
            spatialReference: mapView.spatialReference
          });
        })
        .filter((item) => item !== false);
      const edgeMeasurements = await Promise.all(
        edgeLines.map(async (line, index) => {
          const length = await this.calculateLength(line);
          const points = line.paths[0];
          const labelCoordinates = {
            x: (points[0][0] + points[1][0]) / 2,
            y: (points[0][1] + points[1][1]) / 2
          };
          const labelPoint = new Point({
            ...labelCoordinates,
            spatialReference: mapView.spatialReference
          });
          const label = new Graphic({
            geometry: labelPoint,
            symbol: {
              ...CROP_SPLIT_EDGE_LABEL,
              text:
                !isNullOrUndefined(length) && !this.whenReseting()
                  ? `${toLocale(length)}m`
                  : ""
            },
            visible: true
          });

          return label;
        })
      );
      if (!this.whenReseting()) {
        edgeLabelsLayer.addMany(edgeMeasurements);
      }
      const edgeLineLayer = this.edgeLineLayer();
      edgeLineLayer.removeAll();
      return newGraphic;
    } catch (e) {
      console.log(e);
    }
  };

  originalFeature = () => {
    const { feature } = this.props;
    return feature;
  };

  drawingTools = () => {
    const {
      labels: { POLYGON_TOOL_LABEL }
    } = this.props;
    return [
      {
        label: POLYGON_TOOL_LABEL,
        param: GEOMETRY_TYPE_POLY
      }
    ];
  };
  customSymbols = () => {
    return {
      polygonSymbol: SPLIT_DRAW_SYMBOL
    };
  };

  handleReset = async () => {
    this.setState({ resetting: true });
    this.clearEdgeLabels();
    this.geometryServiceController.abort();
    const originalFeature = this.originalFeature();
    const { updateGraphics } = this.props;
    updateGraphics([originalFeature]);
    const splitLabelsLayer = this.splitLabelsLayer();

    splitLabelsLayer.removeAll();

    this.setState(
      {
        startingPoint: null,
        vertexIndex: 0,
        cutGeometries: null
      },
      async () => {
        this.geometryServiceController = new AbortController();
        this.renderSplitGraphics();
        await this.renderSplitLabels();
        this.setState({
          resetting: false
        });
      }
    );
  };

  handleUpdateSketch = (event) => {
    const newEvent = { ...event };
    newEvent.graphic = event.graphics[0];
    this.handleSketchEvent(newEvent);
  };

  handleNotOverlap = (eventType) => {
    const {
      labels: { NOTIFICATION_ERROR_LABEL_ALT, SPLIT_AREA_ERROR_LABEL }
    } = this.props;
    const { sketchTool } = this.state;
    if (eventType === "update") {
      sketchTool.undo();
    } else {
      this.handleSketchReset();
    }
    NotificationManager.error(
      NOTIFICATION_ERROR_LABEL_ALT,
      SPLIT_AREA_ERROR_LABEL,
      4000
    );
  };

  handleSketchEvent = (event) => {
    const { state, toolEventInfo, graphic, type } = event;
    if (state === "complete" && type !== "update") {
      const overlaps = this.checkOverlap(graphic);
      if (!overlaps) {
        this.handleNotOverlap(type);
        return;
      }
      this.handleCreateSketchComplete(graphic);
    } else if (
      state === "active" &&
      type === "update" &&
      toolEventInfo.type.indexOf("stop") !== -1
    ) {
      const overlaps = this.checkOverlap(graphic);
      if (!overlaps) {
        this.handleNotOverlap(type);
        return;
      }
      this.handleCreateSketchComplete(graphic);
    } else if (
      state === "active" &&
      toolEventInfo &&
      toolEventInfo.type === "cursor-update"
    ) {
      this.updateEdgeLabelPosition(toolEventInfo.coordinates);
      if (this.timeout) clearTimeout(this.timeout);
      this.timeout = setTimeout(
        () => this.handleUpdateEdgeDistance(toolEventInfo.coordinates),
        50
      );
    } else if (toolEventInfo && toolEventInfo.type === "vertex-add") {
      const newVertex = state === "active" ? this.state.vertexIndex + 1 : 0;
      this.setState({
        vertexIndex: newVertex
      });
      this.renderEdgeLabel(newVertex);
      this.createStartingPoint(toolEventInfo.added[0]);
    } else {
      return;
    }
  };

  createStartingPoint = (coordinates) => {
    const { mapView } = this.props;

    const startingPoint = new Point({
      x: coordinates[0],
      y: coordinates[1],
      spatialReference: mapView.spatialReference
    });
    this.setState({
      startingPoint
    });
  };

  whenReseting = () => {
    const { resetting } = this.state;
    return resetting;
  };

  resetButtonDisabled = () => {
    const { disabled } = this.props;
    return disabled || this.getCutGeometries() === null;
  };

  getExplodeResults = () => {
    const { explodeResults } = this.state;
    return explodeResults;
  };

  setExplodeResults = (shouldExplodeResults) => {
    this.setState(
      {
        explodeResults: shouldExplodeResults === true ? true : false
      },
      () => {
        if (this.state.cutGeometries) {
          this.createSplitGraphics(this.state.cutGeometries);
        }
      }
    );
  };

  getSnappingOptions = () => {
    const splitLayer = this.splitLayer();

    return {
      enabled: true,
      selfEnabled: true,
      featureEnabled: true,
      featureSources: splitLayer ? [{ layer: splitLayer, enabled: true }] : []
    };
  };

  render() {
    const {
      labels: {
        SPLIT_RESETTING_LABEL,
        SPLIT_RESET_LABEL,
        EXPLODE_SPLIT_RESULTS_LABEL
      }
    } = this.props;
    return (
      <React.Fragment>
        <PrinterPreviewControlContainer>
          <Label htmlFor="explode-results-checkbox">
            <Input
              type="checkbox"
              id="explode-results-checkbox"
              checked={this.getExplodeResults()}
              onChange={(e) => this.setExplodeResults(e.target.checked)}
            />
            <span>{EXPLODE_SPLIT_RESULTS_LABEL}</span>
          </Label>
        </PrinterPreviewControlContainer>
        <DrawingTool
          tools={this.drawingTools()}
          onComplete={this.handleSketchEvent}
          onUpdate={this.handleUpdateSketch}
          onReset={this.handleReset}
          customSymbols={this.customSymbols()}
          canUpdate={true}
          onSketchToolCreate={this.onSketchToolCreate}
          customResetDisabled={this.resetButtonDisabled()}
          watchStates={["complete", "active", "start"]}
          customResetText={
            this.whenReseting() ? SPLIT_RESETTING_LABEL : SPLIT_RESET_LABEL
          }
          snappingOptions={this.getSnappingOptions()}
        />
      </React.Fragment>
    );
  }
}
