import React, { Component } from "react";
import AttachmentsComponent from "@agBoxUiKit/core/components/AttachmentsComponent/AttachmentsComponent";
import { isNullOrUndefined } from "../../App/utils";

export default class Attachments extends Component {
  getAttribute = () => {
    const { attribute } = this.props;
    return attribute ? attribute : null;
  };

  handleSelectFile = (files) => {
    if (files.length !== 1) return;
    const { handleSelectFile } = this.props;
    const attribute = this.getAttribute();
    if (!handleSelectFile) return;
    handleSelectFile(files[0], attribute);
  };

  handleClearFile = (file, i) => {
    const attribute = this.getAttribute();
    const { handleClearFile } = this.props;
    if (!handleClearFile) return;
    handleClearFile(file, i, attribute);
  };

  handleClearExistingFile = (file) => {
    const attribute = this.getAttribute();
    const { handleClearExistingFile } = this.props;
    if (!handleClearExistingFile) return;
    handleClearExistingFile(file, attribute);
  };

  getAttachments = () => {
    const { attachments } = this.props;
    return attachments ? attachments : [];
  };

  allowMultipleUploads = () => {
    const { allowMultipleUploads } = this.props;
    return !isNullOrUndefined(allowMultipleUploads)
      ? allowMultipleUploads
      : true;
  };

  showSelectedFile = () => {
    const { importFiles } = this.props;
    return importFiles ? importFiles.length > 0 : false;
  };

  showDropzone = () => {
    return (
      this.allowMultipleUploads() === true ||
      (this.allowMultipleUploads() === false &&
        this.showSelectedFile() === false &&
        this.getAttachments().length === 0)
    );
  };

  handleSelectedActiveFile = (file) => {
    const { handleSelectedActiveFile } = this.props;
    return handleSelectedActiveFile(file);
  };

  getHandleSelectedActiveFile = () => {
    const { handleSelectedActiveFile } = this.props;
    if (handleSelectedActiveFile) {
      return this.handleSelectedActiveFile;
    }
    return null;
  };

  getAttachmentsProps = () => {
    const { isEditing, labels, showHeading, importFiles } = this.props;
    return {
      isEditing,
      labels,
      showHeading,
      importFiles,
      showDropzone: this.showDropzone(),
      showSelectedFile: this.showSelectedFile(),
      handleClearExistingFile: this.handleClearExistingFile,
      handleClearFile: this.handleClearFile,
      handleSelectFile: this.handleSelectFile,
      handleSelectedActiveFile: this.getHandleSelectedActiveFile(),
      attachments: this.getAttachments()
    };
  };

  render() {
    return <AttachmentsComponent {...this.getAttachmentsProps()} />;
  }
}
