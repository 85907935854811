import { createLogic } from "redux-logic";
import {
  SELECT_ORGANISATION,
  LOADED_ORGANISATION,
  LOAD_ORGANISATION,
  LOAD_ORGANISATIONS,
  LOADED_ORGANISATIONS,
  URL_DELIMITER_PROP,
  ABORT_ERROR_NAME,
  DEFAULT_BASEMAP,
  CUSTOM_IMAGERY_URL,
  MAPSERVER_DELIMITER,
  IMAGERY_SERVER_DELIMITER,
  DEFAULT_ELEVATION_MODE,
  ELEVATION_OFFSET,
  SEARCH_BY_NAME,
  WEB_LOGIN_ACTION_LOG_TYPE,
  EDIT_TYPE_CONFIG,
  SEND_LOAD_ORG_ACTION_LOG,
  VECTOR_TILE_SERVER_DELIMITER,
  WEBTILELAYER_PATTERN_DELIMITER,
  URL_DELIMITER_ORG,
  URL_DELIMITER_SELECT_ORG
} from "../constants";
import {
  getSession,
  getUserBrowser,
  getUserOS,
  isNullOrUndefined
} from "../App/utils";
import { NotificationManager } from "react-notifications";
import FeatureLayer from "@arcgis/core/layers/FeatureLayer";
import TileLayer from "@arcgis/core/layers/TileLayer";
import VectorTileLayer from "@arcgis/core/layers/VectorTileLayer";
import WebTileLayer from "@arcgis/core/layers/WebTileLayer";
import MapImageLayer from "@arcgis/core/layers/MapImageLayer";
import ImageryLayer from "@arcgis/core/layers/ImageryLayer";
import { navigate } from "@reach/router";

const selectOrganisationLogic = createLogic({
  type: SELECT_ORGANISATION,
  async process(
    { globalActions, action, getState, selectors },
    dispatch,
    done
  ) {
    const { getLabel } = selectors;
    const { loadOrganisation, setLoadingText, cancelPropertyDisplay } =
      globalActions;
    const { LOADING_ORGANISATION_LABEL } = getLabel(getState());
    const { orgId } = action.payload;
    dispatch(cancelPropertyDisplay());
    if (window.location.pathname.indexOf(URL_DELIMITER_PROP) === -1) {
      dispatch(setLoadingText(LOADING_ORGANISATION_LABEL));
    }
    dispatch(loadOrganisation(orgId));
    done();
  }
});

const loadedOrganisationLogic = createLogic({
  type: LOADED_ORGANISATION,
  async process(
    { globalActions, action, getState, selectors },
    dispatch,
    done
  ) {
    const { preferences, title } = action.payload;
    const {
      setBasemap,
      startPropertyDisplay,
      setupLayerLabels,
      setupLayerRenderers,
      setDocTitle,
      setShowMap,
      closeProfileDropDown,
      loadPropertyUsers
    } = globalActions;
    const {
      getWebMap,
      getToken,
      getLabel,
      getObjectIdField,
      getMapView,
      getSelectedPropId,
      getPropertyUsers
    } = selectors;

    const selectedPropId = getSelectedPropId(getState());
    const propertyUsers = getPropertyUsers(getState());
    if (selectedPropId && !propertyUsers) dispatch(loadPropertyUsers());
    dispatch(setupLayerRenderers());
    dispatch(setupLayerLabels());
    dispatch(closeProfileDropDown());
    const token = getToken(getState());
    const webMap = getWebMap(getState());
    const mapView = getMapView(getState());
    const objectIdField = getObjectIdField(getState());
    const { ERROR_SERVER_MSG_LABEL, ERROR_LOADING_LAYER_LABEL } = getLabel(
      getState()
    );

    if (!webMap || !mapView) return done();

    let basemap = DEFAULT_BASEMAP[0];

    if (preferences) {
      const { layers, enable3D } = preferences;
      const basemaps = layers
        ? layers.filter((layer) => layer.type === "baseMap")
        : [];
      if (basemaps && basemaps.length) {
        basemap = basemaps[0];
      }

      const communityLayers = layers
        ? layers.filter(
            (layer) => layer.type && layer.type === "community" && layer.url
          )
        : [];

      const newLayers = communityLayers.reduce((result, layer) => {
        let featureLayer;
        const {
          visible,
          url,
          title,
          showLabels,
          definitionExpression = null,
          id
        } = layer;
        if (url.includes(MAPSERVER_DELIMITER)) {
          if (layer.subLayers && layer.subLayers.length) {
            featureLayer = new MapImageLayer({
              url,
              visible: true,
              sublayers: layer.subLayers.map((subLayer) => {
                const { id, title } = subLayer;
                return {
                  id,
                  title,
                  visible: false
                };
              })
            });
          } else {
            featureLayer = new TileLayer({
              url,
              visible: !isNullOrUndefined(visible) ? visible : false
            });
          }
        } else if (layer.url.includes(WEBTILELAYER_PATTERN_DELIMITER)) {
          featureLayer = new WebTileLayer({
            visible: !isNullOrUndefined(visible) ? visible : true,
            title: title,
            urlTemplate: url,
            id: "web-tile-layer"
          });
        } else if (layer.url.includes(CUSTOM_IMAGERY_URL)) {
          featureLayer = new WebTileLayer({
            visible: !isNullOrUndefined(visible) ? visible : true,
            title: title || "GPS-it Imagery",
            maxScale: 250,
            urlTemplate: url,
            id: "gpsit-imagery"
          });
        } else if (layer.url.includes(VECTOR_TILE_SERVER_DELIMITER)) {
          featureLayer = new VectorTileLayer({
            visible: !isNullOrUndefined(visible) ? visible : true,
            title: title || "Community Layer",
            maxScale: 250,
            url: url,
            id: id || "community-layer"
          });
        } else if (layer.url.includes(IMAGERY_SERVER_DELIMITER)) {
          featureLayer = new ImageryLayer({
            url,
            visible: !isNullOrUndefined(visible) ? visible : false
          });
        } else {
          featureLayer = new FeatureLayer({
            url: `${url}?token=${token}`,
            visible: !isNullOrUndefined(visible) ? visible : false,
            objectIdField,
            labelsVisible: !!showLabels,
            title,
            definitionExpression
          });
          if (enable3D === true) {
            featureLayer.elevationInfo = {
              mode: DEFAULT_ELEVATION_MODE,
              offset: ELEVATION_OFFSET
            };
          }
        }
        return [...result, featureLayer];
      }, []);
      webMap.addMany(newLayers);
      newLayers.forEach((layer) => {
        layer.when(
          () => {},
          (error) => {
            NotificationManager.error(
              `${ERROR_SERVER_MSG_LABEL}: ${error.message}`,
              `${ERROR_LOADING_LAYER_LABEL} ${layer.title}`,
              0
            );
          }
        );
      });
    }
    dispatch(setBasemap(basemap));
    const path = window.location.pathname;
    if (path.indexOf(URL_DELIMITER_PROP) === -1) {
      dispatch(startPropertyDisplay());
      dispatch(setDocTitle(title));
      dispatch(setShowMap(true));
    }

    done();
  }
});

const loadOrganisationsLogic = createLogic({
  type: LOAD_ORGANISATIONS,
  async process(
    { globalActions, agBoxApiRequests, getState, selectors },
    dispatch,
    done
  ) {
    try {
      const state = getState();
      const { getToken, getUserId, getAbortSignal, getLabel } = selectors;
      const token = getToken(state);
      const userId = getUserId(state);
      const signal = getAbortSignal(getState());
      const { LOADING_ORGANISATIONS_LABEL } = getLabel(getState());
      const { setLoadingText } = globalActions;

      dispatch(setLoadingText(LOADING_ORGANISATIONS_LABEL));
      const { requestOrganisations } = agBoxApiRequests;

      const sessionOrgs = getSession(`user-${userId}-organisations`);
      const userOrganisations =
        sessionOrgs && sessionOrgs.items.length !== 0
          ? sessionOrgs.items
          : await requestOrganisations(userId, token, signal);

      const { loadedOrganisations } = globalActions;
      const organisations = userOrganisations.map((organisation) => {
        const { expiryDate } = organisation;
        let expired = false;
        if (expiryDate) {
          const today = new Date().setHours(0, 0, 0, 0);
          const expiredDate = new Date(expiryDate).setHours(0, 0, 0, 0);
          expired = today > expiredDate;
        }
        return {
          ...organisation,
          expired
        };
      });
      dispatch(loadedOrganisations(organisations));
      done();
    } catch (e) {
      if (e.name && e.name === ABORT_ERROR_NAME) return done();
      if (process.env.NODE_ENV === "development") console.log(e);
      const { errorOrganisations } = globalActions;

      dispatch(errorOrganisations(e));
      done();
    }
  }
});

const loadedOrganisationsLogic = createLogic({
  type: LOADED_ORGANISATIONS,
  async process(
    { globalActions, action, getState, selectors },
    dispatch,
    done
  ) {
    const state = getState();
    const { errorOrganisations, loadInvitations } = globalActions;
    const { getAvailableOrgs, getLabel, getUserId } = selectors;
    const userId = getUserId(state);
    const organisations = getAvailableOrgs(getState());
    const nonExpiredOrganisations = organisations
      ? organisations.filter((org) => !org.expired)
      : [];
    const allOrgs = [...nonExpiredOrganisations];
    if (
      nonExpiredOrganisations.length === 1 &&
      !window.location.pathname.includes(URL_DELIMITER_PROP) &&
      window.location.hash !== URL_DELIMITER_SELECT_ORG
    ) {
      navigate(`/org/${nonExpiredOrganisations[0].orgId}/`, {
        state: {
          docTitle: nonExpiredOrganisations[0].title
        }
      });
    } else if (
      allOrgs.length > 1 &&
      !window.location.pathname.includes(URL_DELIMITER_ORG)
    ) {
      window.location.hash = URL_DELIMITER_SELECT_ORG;
    }
    if (!organisations || !organisations.length) {
      const { NO_ORGANISATIONS_ERROR_LABEL } = getLabel(getState());
      dispatch(
        errorOrganisations({
          message: NO_ORGANISATIONS_ERROR_LABEL
        })
      );
      dispatch(loadInvitations(userId));
    } else if (!nonExpiredOrganisations.length) {
      const { NO_ACTIVE_ORGANISATIONS_ERROR_LABEL } = getLabel(getState());
      dispatch(
        errorOrganisations({
          message: NO_ACTIVE_ORGANISATIONS_ERROR_LABEL
        })
      );
      dispatch(loadInvitations(userId));
    }
    done();
  }
});

const loadOrganisationLogic = createLogic({
  type: LOAD_ORGANISATION,
  async process(
    { globalActions, agBoxApiRequests, getState, action, selectors },
    dispatch,
    done
  ) {
    try {
      const { loadedOrganisation, sendLoadOrgActionLog } = globalActions;
      const state = getState();
      const { getToken, getAbortSignal } = selectors;
      const token = getToken(state);
      const { orgId } = action.payload;

      const { requestOrganisation } = agBoxApiRequests;
      const signal = getAbortSignal(getState());

      const sessionOrganisation = getSession(`org-${orgId}`);
      const organisation = sessionOrganisation
        ? sessionOrganisation
        : await requestOrganisation(orgId, token, signal);
      dispatch(loadedOrganisation(organisation));
      dispatch(sendLoadOrgActionLog(orgId));
      done();
    } catch (e) {
      if (e.name && e.name === ABORT_ERROR_NAME) return done();
      const { errorOrganisation, deselectWorkflow } = globalActions;
      const { getSelectedWorkflow } = selectors;

      dispatch(errorOrganisation(e));
      if (getSelectedWorkflow(getState())) {
        dispatch(deselectWorkflow());
      }
      if (process.env.NODE_ENV === "development") console.log(e);
      done();
    }
  }
});

const sendLoadOrgActionLogLogic = createLogic({
  type: SEND_LOAD_ORG_ACTION_LOG,
  async process(
    { globalActions, agBoxApiRequests, getState, action, selectors },
    dispatch,
    done
  ) {
    try {
      const state = getState();
      const { getFirstName, getLastName, getEmailAddress } = selectors;
      const firstName = getFirstName(state);
      const lastName = getLastName(state);
      const emailAddress = getEmailAddress(state);
      const { orgId } = action.payload;
      const { sendActionLog } = globalActions;

      dispatch(
        sendActionLog(orgId, WEB_LOGIN_ACTION_LOG_TYPE, {
          firstName,
          lastName,
          emailAddress,
          os: getUserOS(),
          ...getUserBrowser()
        })
      );
      done();
    } catch (e) {
      if (e.name && e.name === ABORT_ERROR_NAME) return done();
      if (process.env.NODE_ENV === "development") console.log(e);
      done();
    }
  }
});

export default [
  selectOrganisationLogic,
  loadOrganisationsLogic,
  loadedOrganisationsLogic,
  loadOrganisationLogic,
  loadedOrganisationLogic,
  sendLoadOrgActionLogLogic
];
