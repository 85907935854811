import React, { Component } from "react";
import { defaultTheme } from "../../..";

import {
  ToolTipWrapper,
  ToolTipContainer,
  ToolTipButton,
  ToolTipMessage,
  ToolTipLink,
  ToolTipTitle,
  ButtonLabel
} from "../../../core/components";

import { Icon } from "../../../core";
import PropTypes from "prop-types";
import { decodeComputedString } from "../../../../App/utils";

/**
 * This is a standard tooltip that can render text or links
 */
class ToolTip extends Component {
  static propTypes = {
    /** The message in the tooltip */
    helpMessage: PropTypes.string,
    /** The side the tooltip opens to */
    floatDirection: PropTypes.string,
    /** The link for inside the tooltip, if applicable */
    link: PropTypes.shape({
      url: PropTypes.string,
      title: PropTypes.string
    }),
    /** The title of the tooltip */
    title: PropTypes.string
  };
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false
    };
  }

  handleToggleMenu = () => {
    const { isOpen } = this.state;
    this.setState({ isOpen: !isOpen });
  };

  isOpen = () => {
    const { isOpen } = this.state;
    return isOpen;
  };

  getLanguageLabel = (stringConstant, data) => {
    const { labels } = this.props;
    const label = labels[stringConstant];
    if (!label) return stringConstant;
    return decodeComputedString(label, data);
  };

  getTooltipLabel = () => {
    const isOpen = this.isOpen();
    return !isOpen
      ? this.getLanguageLabel("OPEN_LABEL", {
          item: this.getLanguageLabel("TOOLTIP_LABEL")
        })
      : this.getLanguageLabel("CLOSE_LABEL", {
          item: this.getLanguageLabel("TOOLTIP_LABEL")
        });
  };

  render() {
    const { helpMessage, floatDirection, link, title } = this.props;

    return (
      <div>
        {helpMessage !== undefined || link !== undefined ? (
          <ToolTipWrapper
            data-name={"ToolTipWrapper"}
            onMouseOver={this.handleToggleMenu}
            onMouseOut={this.handleToggleMenu}
          >
            <ToolTipTitle data-name={"ToolTipTitle"}>
              {title}
              <ToolTipButton data-name={"ToolTipButton"}>
                <Icon
                  data-name={"Icon"}
                  iconColor={defaultTheme.agBlue}
                  type="description"
                  iconHeight={"16px"}
                  iconWidth={"16px"}
                />
                <ButtonLabel type={"sr-only"}>
                  {this.getTooltipLabel()}
                </ButtonLabel>
              </ToolTipButton>
            </ToolTipTitle>
            <ToolTipContainer
              data-name={"ToolTipContainer"}
              isOpen={this.isOpen()}
              floatDirection={floatDirection}
            >
              <ToolTipMessage data-name={"ToolTipMessage"}>
                {helpMessage}
              </ToolTipMessage>
              {link && (
                <ToolTipLink href={link.url} target="blank">
                  {link.title || link.url}
                </ToolTipLink>
              )}
            </ToolTipContainer>
          </ToolTipWrapper>
        ) : null}
      </div>
    );
  }
}

export default ToolTip;
