import React from "react";
import {
  InputGroup,
  Label,
  DropDownWrapper,
  DropDownContainer,
  Select,
  LabelText
} from "..";
import { Icon } from "../..";
import PropTypes from "prop-types";
import { isNullOrUndefined, toLocale } from "../../../../App/utils";

const DropDown = (props) => (
  <DropDownWrapper data-name={"DropDownWrapper"} direction={props.direction}>
    <InputGroup styletype={"pagination"}>
      {props.label && (
        <Label
          htmlFor={props.id}
          type={props.labelType}
          styletype={props.labelStyle ? props.labelStyle : ""}
        >
          <LabelText>{props.label}</LabelText>
          {props.requiredSymbol ? props.requiredSymbol : null}
        </Label>
      )}
      <DropDownContainer
        data-name={"DropDownContainer"}
        direction={props.direction}
      >
        <Select
          value={props.value}
          id={props.id}
          data-id={props.id}
          onChange={props.onChange}
          aria-labelledby={
            props["aria-labelledby"] ? props["aria-labelledby"] : props.label
          }
          invalid={!isNullOrUndefined(props.isValid) ? !props.isValid : false}
          required={props.required}
          disabled={props.disabled}
        >
          {props.options.map((item) => (
            <option
              key={item.value}
              value={item.value}
              disabled={item.disabled === true}
            >
              {item.title ? toLocale(item.title) : toLocale(item.value)}
            </option>
          ))}
        </Select>
        <Icon
          type="drop-down"
          iconColor="gray"
          iconWidth="0.8em"
          iconHeight="0.9em"
        />
      </DropDownContainer>
    </InputGroup>
  </DropDownWrapper>
);

DropDown.propTypes = {
  /** The direction to align the label and select elements */
  direction: PropTypes.oneOf(["horizontal", "vertical"]),
  /** An id for the element */
  id: PropTypes.string,
  /** The label for the select dropdown */
  label: PropTypes.string,
  /** The value of the input */
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool
  ]),
  /** Called on change of value */
  onChange: PropTypes.func,
  /** The dropdown value options */
  options: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool
      ]),
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool
      ])
    })
  ),
  "aria-labelledby": PropTypes.string,
  requiredSymbol: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  labelStyle: PropTypes.string
};

export default DropDown;
