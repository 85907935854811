import React, { Component } from "react";
import { Label } from "../../../core/components";
import PropTypes from "prop-types";
import RadioSetInput from "../FeatureDetailsComponent/subcomponents/Inputs/RadioSetInput";

/** A radio input set for handling more than one option for creating a feature */
export default class FeatureCreatePickerComponent extends Component {
  static propTypes = {
    /** Shows above the radio button options. Should describe what the user is choosing for (important for screenreaders) */
    legend: PropTypes.string,
    /** Whether to show a standalone disabled message */
    showDisabledMessage: PropTypes.bool,
    /** Standalone disabled message string to render */
    disabledMessage: PropTypes.string,
    /** The identifier for the set of radio inputs */
    name: PropTypes.string,
    /** The selected value */
    value: PropTypes.any,
    /** Called on change of selected value */
    handleUpdateValue: PropTypes.func,
    /** The radio input options to render */
    options: PropTypes.arrayOf(
      PropTypes.shape({
        disabled: PropTypes.bool,
        title: PropTypes.string,
        disabledMessage: PropTypes.string,
        content: PropTypes.node,
        value: PropTypes.any
      })
    )
  };
  getOptions = () => {
    const { options } = this.props;
    return options ? options : [];
  };
  getLegend = () => {
    const { legend } = this.props;
    return legend ? legend : "";
  };
  showDisabledMessage = () => {
    const { showDisabledMessage } = this.props;
    return showDisabledMessage === true;
  };
  disabledMessage = () => {
    const { disabledMessage } = this.props;
    return disabledMessage;
  };

  handleUpdateValue = (attr, value) => {
    const { handleUpdateValue } = this.props;
    if (handleUpdateValue) handleUpdateValue(value);
  };

  getSelectedValue = () => {
    const { value } = this.props;
    return value;
  };

  getContent = () => {
    const options = this.getOptions();
    if (options.length > 1)
      return (
        <RadioSetInput
          label={this.getLegend()}
          options={this.getOptions()}
          handleUpdateValue={this.handleUpdateValue}
          attribute={"create-selection"}
          required={false}
          isValid={true}
          value={this.getSelectedValue()}
        />
      );
    else if (options.length === 1 && options[0].content)
      return options[0].content;
    return null;
  };

  render() {
    return (
      <React.Fragment>
        {this.getContent()}
        {this.showDisabledMessage() && (
          <Label type="error" data-name={"disabled-message"}>
            {this.disabledMessage()}
          </Label>
        )}
      </React.Fragment>
    );
  }
}
