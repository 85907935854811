import { createLogic } from "redux-logic";
import {
  UNLOAD_PROPERTY_GROUP,
  LOAD_PROPERTY_GROUP,
  LOAD_PROPERTY_GROUPS,
  LOADED_PROPERTY_GROUPS,
  LOADED_PROPERTY_GROUP,
  ERROR_PROPERTY_GROUP,
  SELECT_PROPERTY_GROUP,
  URL_DELIMITER_WORKFLOW,
  ABORT_ERROR_NAME,
  URL_DELIMITER_ORG,
  URL_DELIMITER_PROP,
  SEARCH_BY_LOCATION,
  NZ_EXTENT
} from "../constants";
import { getSession } from "../App/utils.js";

const unloadPropertyGroupLogic = createLogic({
  latest: true,
  type: UNLOAD_PROPERTY_GROUP,
  async process(
    { globalActions, action, getState, selectors },
    dispatch,
    done
  ) {
    const { getZoomToSearchResults, getSearchPropertiesBy } = selectors;
    const { cancelPropertyDisplay, startPropertyDisplay, zoomToSearchResults } =
      globalActions;
    await dispatch(cancelPropertyDisplay());
    if (
      window.location.pathname.includes(URL_DELIMITER_ORG) &&
      !window.location.pathname.includes(URL_DELIMITER_PROP)
    ) {
      dispatch(startPropertyDisplay());
      if (
        getZoomToSearchResults(getState()) &&
        getSearchPropertiesBy(getState()) === SEARCH_BY_LOCATION
      )
        dispatch(zoomToSearchResults());
    }

    done();
  }
});

const loadPropertyGroupsLogic = createLogic({
  type: LOAD_PROPERTY_GROUPS,
  async process(
    { globalActions, action, agBoxApiRequests, getState, selectors },
    dispatch,
    done
  ) {
    const state = getState();
    try {
      const { getToken, getUserId, getAbortSignal } = selectors;
      const { orgId } = action.payload;
      const token = getToken(state);
      const userId = getUserId(state);
      const { requestPropertyGroups } = agBoxApiRequests;
      const signal = getAbortSignal(getState());
      const sessionWorkflow = getSession(
        `user-${userId}-org-${orgId}-propertyGroups`
      );
      const { items, meta } = sessionWorkflow
        ? sessionWorkflow
        : await requestPropertyGroups(userId, orgId, token, false, signal);

      const { loadedPropertyGroups } = globalActions;
      dispatch(loadedPropertyGroups(items, meta));

      done();
    } catch (e) {
      if (e.name && e.name === ABORT_ERROR_NAME) return done();
      const { errorPropertyGroups } = globalActions;
      dispatch(errorPropertyGroups(e));
      if (process.env.NODE_ENV === "development") console.log(e);
      done();
    }
  }
});

const loadPropertyGroupLogic = createLogic({
  type: LOAD_PROPERTY_GROUP,
  async process(
    { globalActions, agBoxApiRequests, action, getState, selectors },
    dispatch,
    done
  ) {
    const state = getState();
    try {
      const { getToken, getAbortSignal } = selectors;
      const token = getToken(state);
      const { orgId, groupId } = action.payload;
      const { requestPropertyGroup } = agBoxApiRequests;
      const signal = getAbortSignal(getState());
      const propertyGroup = await requestPropertyGroup(
        orgId,
        groupId,
        token,
        false,
        signal
      );

      const { loadedPropertyGroup, setMapPosition } = globalActions;
      dispatch(loadedPropertyGroup(propertyGroup));
      dispatch(setMapPosition(propertyGroup.extent || NZ_EXTENT));

      done();
    } catch (e) {
      if (e.name && e.name === ABORT_ERROR_NAME) return done();
      const { errorPropertyGroup } = globalActions;
      dispatch(errorPropertyGroup(e));
      if (process.env.NODE_ENV === "development") console.log(e);
      done();
    }
  }
});

const loadedPropertyGroupsLogic = createLogic({
  type: LOADED_PROPERTY_GROUPS,
  process({ globalActions, action, getState }, dispatch, done) {
    const { setupLayerRenderers } = globalActions;
    dispatch(setupLayerRenderers());
    done();
  }
});

const loadedPropertyGroupLogic = createLogic({
  type: LOADED_PROPERTY_GROUP,
  process({ globalActions, selectors, action, getState }, dispatch, done) {
    const {
      setFeatureLayers,
      setDefaultFeatureLayers,
      startPropertyDisplay,
      setDocTitle
    } = globalActions;
    const { layers, title } = action.payload;
    if (window.location.pathname.indexOf(URL_DELIMITER_WORKFLOW) === -1) {
      dispatch(setDefaultFeatureLayers(layers));
      dispatch(setFeatureLayers(layers));
      dispatch(startPropertyDisplay());
      dispatch(setDocTitle(title));
    }

    done();
  }
});

const errorPropertyGroupLogic = createLogic({
  type: ERROR_PROPERTY_GROUP,
  process({ globalActions, action, getState, selectors }, dispatch, done) {
    const { setMapPosition } = globalActions;
    const { getSelectedOrg } = selectors;
    if (action.payload === false && getSelectedOrg(getState())) {
      dispatch(setMapPosition());
    }
    done();
  }
});

const selectPropertyGroupLogic = createLogic({
  type: SELECT_PROPERTY_GROUP,
  process({ globalActions, selectors, action, getState }, dispatch, done) {
    const {
      getSelectedPropertyGroup,
      getPropertyLayers,
      getMapView,
      getSelectedWorkflow,
      getLabel
    } = selectors;
    const {
      loadPropertyGroup,
      deselectWorkflow,
      abortRequest,
      cancelPropertyDisplay,
      clearLocationSearchGraphics,
      setLoadingText,
      loadPropertyGroupWorkflows
    } = globalActions;
    const { orgId, groupId } = action.payload;
    const { LOADING_PROPERTY_GROUP_LABEL } = getLabel(getState());
    dispatch(setLoadingText(LOADING_PROPERTY_GROUP_LABEL));
    dispatch(cancelPropertyDisplay());

    const selectedPropertyGroup = getSelectedPropertyGroup(getState());
    if (selectedPropertyGroup) {
      dispatch(abortRequest());
      if (getSelectedWorkflow(getState())) {
        dispatch(deselectWorkflow());
      }
    }
    dispatch(loadPropertyGroupWorkflows(orgId, groupId));

    dispatch(loadPropertyGroup(orgId, groupId));
    const mapView = getMapView(getState());

    const propertyLayers = getPropertyLayers(getState());
    propertyLayers.forEach(async (item) => {
      item.labelingInfo = null;
      const layerView = await mapView.whenLayerView(item);
      if (!layerView) return;
      layerView.featureEffect = null;
    });
    dispatch(clearLocationSearchGraphics());
    done();
  }
});

export default [
  unloadPropertyGroupLogic,
  loadPropertyGroupsLogic,
  loadPropertyGroupLogic,
  loadedPropertyGroupsLogic,
  loadedPropertyGroupLogic,
  errorPropertyGroupLogic,
  selectPropertyGroupLogic
];
