import React, { Component } from "react";
import DrawingTool from "../DrawingTool";
import PropTypes from "prop-types";
import { TEMP_LAYER_PREFIX } from "../../constants";

export default class FeatureDrawTool extends Component {
  static propTypes = {
    onCompleteSketch: PropTypes.func,
    onUpdateSketch: PropTypes.func,
    onCancelSketch: PropTypes.func,
    toolType: PropTypes.string,
    drawingOptions: PropTypes.shape({
      canUpdate: PropTypes.bool,
      canScale: PropTypes.bool,
      canRotate: PropTypes.bool,
      defaultUpdateTool: PropTypes.string,
      toggleToolOnClick: PropTypes.bool,
      canDrawMultiple: PropTypes.bool,
      watchStates: PropTypes.arrayOf(PropTypes.string),
      sketchLayerTitle: PropTypes.string,
      customSymbols: PropTypes.shape({
        polygonSymbol: PropTypes.object,
        polylineSymbol: PropTypes.object,
        pointSymbol: PropTypes.object
      })
    })
  };
  constructor(props) {
    super(props);
    this.drawingTool = React.createRef();
  }
  getDrawingTools = () => {
    const { toolType } = this.props;
    return toolType
      ? [
          {
            param: toolType
          }
        ]
      : undefined;
  };

  onCompleteSketch = (event) => {
    const { graphic } = event;
    const { updateWorkflowState, onCompleteSketch } = this.props;
    updateWorkflowState({
      sketchGraphics: [graphic]
    });
    if (onCompleteSketch) onCompleteSketch(graphic);
  };

  sketchLayer = () => {
    const { webMap, sketchLayerTitle } = this.props;
    const layerTitle = sketchLayerTitle
      ? sketchLayerTitle
      : `${TEMP_LAYER_PREFIX}_SKETCH`;
    return webMap.layers.items.find((layer) => layer.title === layerTitle);
  };

  onResetSketch = () => {
    const { updateWorkflowState } = this.props;
    updateWorkflowState({
      sketchGraphics: []
    });
    const tool = this.drawingTool.current;
    tool.handleReset();
  };

  onUpdateSketch = (event) => {
    const { graphics, state, toolEventInfo } = event;
    if (
      state === "complete" ||
      (state === "active" &&
        toolEventInfo &&
        toolEventInfo.type.indexOf("stop") !== -1)
    ) {
      const { updateWorkflowState, onUpdateSketch } = this.props;
      updateWorkflowState({
        sketchGraphics: graphics
      });
      if (onUpdateSketch) onUpdateSketch(graphics[0]);
    }
  };

  onCancelSketch = () => {
    const { onCancelSketch } = this.props;
    if (!onCancelSketch) return;
    onCancelSketch();
  };

  getDrawingOptions = () => {
    const { drawingOptions } = this.props;
    return drawingOptions ? drawingOptions : {};
  };
  drawingToolProps = () => ({
    removeGraphicsOnUnmount: true,
    showResetButton: false,
    keepSelectionOnReset: true,
    tools: this.getDrawingTools(),
    onComplete: this.onCompleteSketch,
    onUpdate: this.onUpdateSketch,
    onCancel: this.onCancelSketch,
    watchStates: ["active", "complete"],
    onSketchToolCreate: this.props.onSketchToolCreate,
    ...this.getDrawingOptions()
  });
  render() {
    return <DrawingTool {...this.drawingToolProps()} ref={this.drawingTool} />;
  }
}
