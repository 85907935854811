import { combineReducers } from "redux";

import app from "./app";
import user from "./user";
import login from "./login";
import organisations from "./organisations";
import workflows from "./workflows";
import properties from "./properties";
import propertyGroups from "./propertyGroups";
import plugins from "./plugins";
import mapView from "./mapView";
import language from "./language";

export default combineReducers({
  app,
  user,
  login,
  organisations,
  workflows,
  properties,
  propertyGroups,
  plugins,
  mapView,
  language
});
