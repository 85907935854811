import React, { Component } from "react";
import StringCell from "./subcomponents/Cells/StringCell";
import RadioCell from "./subcomponents/Cells/RadioCell";
import CheckboxCell from "./subcomponents/Cells/CheckboxCell";
import NumberCell from "./subcomponents/Cells/NumberCell";
import SelectCell from "./subcomponents/Cells/SelectCell";
import DateCell from "./subcomponents/Cells/DateCell";
import {
  TableHeaderWrapper,
  TableHeaderCell,
  TableRow,
  TableRowContentContainer,
  TableCell,
  TableCellContentContainer,
  TableCellButton,
  TableBottomRow,
  TableRowErrorContainer
} from "..";
import { P } from "../../../core/components";
import HeaderCell from "./subcomponents/Cells/HeaderCell";
import TrashButton from "./subcomponents/Buttons/TrashButton";
import AddButton from "./subcomponents/Buttons/AddButton";
import NonEditableCell from "./subcomponents/Cells/NonEditableCell";
import PropTypes from "prop-types";
import { isNullOrUndefined } from "../../../../App/utils";

export default class FeatureTableComponent extends Component {
  static propTypes = {
    /** The rows of the table, separated into cells, belowCells (second line of cells), and sections (next line inside row, can be added/removed as a whole) */
    rows: PropTypes.arrayOf(
      PropTypes.shape({
        removeable: PropTypes.bool,
        showInvalidMessage: PropTypes.bool,
        errorMessage: PropTypes.string,
        cells: PropTypes.arrayOf(
          PropTypes.shape({
            editable: PropTypes.bool,
            key: PropTypes.string,
            fieldType: PropTypes.string,
            headerId: PropTypes.string,
            disabled: PropTypes.bool,
            cellValues: PropTypes.array,
            width: PropTypes.string,
            invisible: PropTypes.bool,
            addMultipleText: PropTypes.string,
            showAddMultiple: PropTypes.bool,
            options: PropTypes.array
          })
        ),
        belowCells: PropTypes.arrayOf(
          PropTypes.shape({
            editable: PropTypes.bool,
            key: PropTypes.string,
            fieldType: PropTypes.string,
            headerId: PropTypes.string,
            disabled: PropTypes.bool,
            cellValues: PropTypes.array,
            width: PropTypes.string,
            invisible: PropTypes.bool,
            addMultipleText: PropTypes.string,
            showAddMultiple: PropTypes.bool,
            isInline: PropTypes.bool,
            alignRight: PropTypes.bool,
            alignSelf: PropTypes.string,
            header: PropTypes.string,
            options: PropTypes.array
          })
        ),
        sections: PropTypes.arrayOf(
          PropTypes.shape({
            sectionItems: PropTypes.arrayOf(PropTypes.array),
            key: PropTypes.string,
            headers: PropTypes.arrayOf(
              PropTypes.shape({
                id: PropTypes.string,
                /** The cell width. Must include the unit e.g. px, % etc. */
                width: PropTypes.string,
                /** The text to render in the header cell */
                label: PropTypes.string,
                key: PropTypes.string
              })
            ),
            isEditable: PropTypes.bool,
            addNewText: PropTypes.string
          })
        )
      })
    ),
    /** Called on change of any input value */
    handleOnChangeValue: PropTypes.func,
    /** Called on click of add button for section */
    handleAddSectionItem: PropTypes.func,
    /** Called on click of deletee section button */
    handleDeleteSectionItem: PropTypes.func,
    /** Called on click of trash button for row */
    handleDeleteRow: PropTypes.func,
    /** Called on click of add value button inside cell*/
    handleAddValueItem: PropTypes.func,
    /** Called on click of delete button for single value inside cell */
    handleDeleteValueItem: PropTypes.func,
    /** Headers for the table */
    headers: PropTypes.arrayOf(
      PropTypes.shape({
        /** The id to use on the node */ id: PropTypes.string,
        /** The cell width. Must include the unit e.g. px, % etc. */
        width: PropTypes.string,
        /** The text to render in the header cell */
        label: PropTypes.string
      })
    ),
    /** Whether to create a column for the delete buttons for rows */
    showDeleteRowColumn: PropTypes.bool,
    /** Label to show on delete row button */
    deleteRowLabel: PropTypes.string,
    /** Label to show on delete section button */
    deleteSectionLabel: PropTypes.string,
    /** Label to show when no value is set in a cell field */
    noValueLabel: PropTypes.string,
    /** Called when formatting cells */
    handleDomainValues: PropTypes.func
  };
  getRows = () => {
    const { rows } = this.props;
    return rows ? rows : [];
  };
  getCellElement = (cell) => {
    const { datePickerLabels } = this.props;
    const { fieldType, valueIndex, headerId, cellIndex, rowIndex } = cell;
    const key = `${headerId}-row-${rowIndex}-cell-${cellIndex}-${valueIndex}`;
    switch (fieldType) {
      case "boolean":
      case "troolean":
        return <RadioCell {...this.cellProps(cell)} key={key} />;
      case "checkbox":
        return <CheckboxCell {...this.cellProps(cell)} key={key} />;
      case "integer":
      case "single":
      case "double":
        return <NumberCell {...this.cellProps(cell)} key={key} />;
      case "select":
        return <SelectCell {...this.cellProps(cell)} key={key} />;
      case "date":
        return (
          <DateCell
            {...{ ...this.cellProps(cell), ...datePickerLabels }}
            key={key}
          />
        );
      case "string":
      default:
        return <StringCell {...this.cellProps(cell)} key={key} />;
    }
  };

  handleOnChangeValue = (cellInfo, value) => {
    const { handleOnChangeValue } = this.props;
    if (handleOnChangeValue) handleOnChangeValue(cellInfo, value);
  };

  handleAddSectionItem = (section, rowIndex) => {
    const { handleAddSectionItem } = this.props;
    if (handleAddSectionItem) handleAddSectionItem(section, rowIndex);
  };
  handleDeleteSectionItem = (sectionKey, sectionItemIndex, rowIndex) => {
    const { handleDeleteSectionItem } = this.props;
    if (handleDeleteSectionItem)
      handleDeleteSectionItem(sectionKey, sectionItemIndex, rowIndex);
  };
  handleDeleteRow = (rowIndex) => {
    const { handleDeleteRow } = this.props;
    if (handleDeleteRow) handleDeleteRow(rowIndex);
  };
  handleAddValueItem = (cell, rowIndex) => {
    const { handleAddValueItem } = this.props;
    if (handleAddValueItem) handleAddValueItem(cell, rowIndex);
  };
  handleDeleteValueItem = (cell) => {
    const { handleDeleteValueItem } = this.props;
    if (handleDeleteValueItem) handleDeleteValueItem(cell);
  };
  cellProps = (cell) => {
    const { rowIndex, cellKey, valueIndex } = cell;
    return {
      ...cell,
      id: `row-${rowIndex}-cell-${cellKey}-${valueIndex}`,
      handleOnChangeValue: this.handleOnChangeValue,
      onClick: this.handleDeleteValueItem
    };
  };
  getHeaders = () => {
    const { headers } = this.props;
    return headers ? headers : [];
  };
  showDeleteRowColumn = () => {
    const { showDeleteRowColumn } = this.props;
    return showDeleteRowColumn;
  };
  deleteRowLabel = () => {
    const { deleteRowLabel } = this.props;
    return deleteRowLabel ? deleteRowLabel : "";
  };
  deleteSectionLabel = () => {
    const { deleteSectionLabel } = this.props;
    return deleteSectionLabel ? deleteSectionLabel : "";
  };

  formatCell = (cell) => {
    const { handleDomainValues, noValueLabel } = this.props;
    if (handleDomainValues) handleDomainValues(cell);
    const { cellValues, editable, disabled, fieldType, value, empty } = cell;
    if (fieldType === "node") return value;
    else if (!editable && !disabled)
      return empty ? null : (
        <NonEditableCell
          {...{
            ...cell,
            cellValues: cell.cellValues.map((cellValue) =>
              isNullOrUndefined(cellValue) || cellValue === ""
                ? noValueLabel
                : cellValue
            )
          }}
        />
      );
    return cellValues.map((cellValue, valueIndex) =>
      this.getCellElement({
        ...cell,
        cellValue,
        valueIndex
      })
    );
  };

  render() {
    return (
      <React.Fragment>
        <TableHeaderWrapper>
          {this.getHeaders().map(
            (header) =>
              !header.isBelow && <HeaderCell key={header.label} {...header} />
          )}
          {this.showDeleteRowColumn() && <TableHeaderCell cellWidth={"60px"} />}
        </TableHeaderWrapper>
        {this.getRows().map((row, rowIndex) => (
          <TableRow key={`row-${rowIndex}`} id={`row-${rowIndex}`}>
            <TableRowContentContainer>
              {row.cells.map((cell, cellIndex) => (
                <TableCell
                  cellWidth={cell.width}
                  key={`cell-${cellIndex}`}
                  invisible={cell.invisible}
                >
                  <TableCellContentContainer>
                    {this.formatCell({
                      ...cell,
                      cellIndex,
                      rowIndex
                    })}
                  </TableCellContentContainer>

                  {cell.showAddMultiple && (
                    <TableCellButton
                      onClick={() => this.handleAddValueItem(cell, rowIndex)}
                    >
                      {cell.addMultipleText}
                    </TableCellButton>
                  )}
                </TableCell>
              ))}
              {this.showDeleteRowColumn() && (
                <TableCell cellWidth="60px">
                  {row.removeable && (
                    <TrashButton
                      label={this.deleteRowLabel()}
                      onClick={() => this.handleDeleteRow(rowIndex)}
                    />
                  )}
                </TableCell>
              )}
            </TableRowContentContainer>
            {row.belowCells && (
              <TableBottomRow>
                {row.belowCells.map((cell, cellIndex) => (
                  <TableCell
                    key={`cell-below-${cellIndex}`}
                    cellWidth={cell.width}
                    inline={cell.isInline}
                    alignRight={cell.alignRight}
                    alignSelf={cell.alignSelf}
                  >
                    {cell.header && <P>{cell.header}: </P>}
                    <TableCellContentContainer>
                      {this.formatCell({
                        ...cell,
                        cellIndex,
                        rowIndex
                      })}
                    </TableCellContentContainer>
                  </TableCell>
                ))}
              </TableBottomRow>
            )}
            {row.sections && (
              <TableBottomRow>
                {row.sections.map((section) => (
                  <React.Fragment key={`section-${section.key}`}>
                    {section.headers.map((header) => (
                      <HeaderCell
                        key={`section-${section.key}-header-${header.key}`}
                        {...header}
                      />
                    ))}
                    <TableRow>
                      {section.sectionItems.map(
                        (sectionItem, sectionItemIndex) => (
                          <TableRowContentContainer
                            key={`section-${section.key}sectionItem-${sectionItemIndex}`}
                          >
                            {sectionItem.map((cell, cellIndex) => (
                              <TableCell
                                key={`section-${section.key}sectionItem-${sectionItemIndex}-cell-${cellIndex}`}
                                cellWidth={cell.width}
                                inline={cell.isInline}
                              >
                                <TableCellContentContainer>
                                  {this.formatCell({
                                    ...cell,
                                    cellIndex,
                                    rowIndex,
                                    sectionKey: section.key,
                                    sectionItemIndex
                                  })}
                                </TableCellContentContainer>
                              </TableCell>
                            ))}
                            {section.isEditable && (
                              <TableCell cellWidth="60px">
                                <TrashButton
                                  label={this.deleteSectionLabel()}
                                  onClick={() =>
                                    this.handleDeleteSectionItem(
                                      section.key,
                                      sectionItemIndex,
                                      rowIndex
                                    )
                                  }
                                />
                                 
                              </TableCell>
                            )}
                          </TableRowContentContainer>
                        )
                      )}
                      {section.isEditable && (
                        <AddButton
                          label={section.addNewText}
                          onClick={() =>
                            this.handleAddSectionItem(section, rowIndex)
                          }
                        />
                      )}
                    </TableRow>
                  </React.Fragment>
                ))}
              </TableBottomRow>
            )}
            {row.showInvalidMessage && (
              <TableRowErrorContainer id={`rowError-${rowIndex}`}>
                <P>{row.errorMessage}</P>
              </TableRowErrorContainer>
            )}
          </TableRow>
        ))}
      </React.Fragment>
    );
  }
}
