import { createLogic } from "redux-logic";
import { DEFAULT_LANGUAGE, LOAD_LANGUAGE } from "../constants";

const loadLanguageLogic = createLogic({
  type: LOAD_LANGUAGE,
  async process({ action, globalActions, getState }, dispatch, done) {
    const { loadedLanguage } = globalActions;
    const { language, languageFile } = action.payload;
    if (language === DEFAULT_LANGUAGE) return done();
    try {
      const languageJson = await fetch(
        `${process.env.REACT_APP_ASSET_PROXY_URL}/${languageFile
          .replace("http://", "")
          .replace("https://", "")}`
      );
      const result = await languageJson.json();
      if (result.error) throw result.error;
      dispatch(loadedLanguage(language, result));
      done();
    } catch (e) {
      if (process.env.NODE_ENV === "development") console.log(e);
      done();
    }
  }
});

export default [loadLanguageLogic];
