import React, { Component } from "react";
import { InputGroup } from "../../../AgBoxUIKit/core/components";
import { DateRangeWrapper } from "../../../AgBoxUIKit/core/layout";
import { DatePicker } from "../../../AgBoxUIKit/plugin";
import { FeatureDetailsFormItem } from "../../../AgBoxUIKit/plugin/components";
import {
  DATE_FILTER_TYPE_RANGE,
  DATE_FILTER_TYPE_SINGLE
} from "../../../constants";

export default class DateRangeFilter extends Component {
  startDate = () => {
    const { value } = this.props;
    return value && value.startDate ? value.startDate : null;
  };

  endDate = () => {
    const { value } = this.props;
    return value && value.endDate ? value.endDate : null;
  };

  onChangeStartDate = (startDate) => {
    const { updateDateFilter, title } = this.props;
    if (updateDateFilter)
      updateDateFilter(
        {
          startDate,
          endDate: this.endDate()
        },
        title
      );
  };

  onChangeEndDate = (endDate) => {
    const { updateDateFilter, title } = this.props;
    const startDate =
      this.filterType() === DATE_FILTER_TYPE_SINGLE
        ? endDate
        : this.startDate();

    if (updateDateFilter)
      updateDateFilter(
        {
          startDate,
          endDate
        },
        title
      );
  };

  filterType = () => {
    const { dateType } = this.props;
    return dateType ? dateType : DATE_FILTER_TYPE_RANGE;
  };

  minEndDate = () => {
    if (this.filterType() === DATE_FILTER_TYPE_SINGLE) return null;
    return this.startDate();
  };

  render() {
    const {
      DATE_RANGE_FROM_PLACEHOLDER_LABEL,
      DATE_RANGE_TO_PLACEHOLDER_LABEL
    } = this.props.labels;
    return (
      <DateRangeWrapper>
        {this.filterType() === DATE_FILTER_TYPE_RANGE && (
          <FeatureDetailsFormItem>
            <InputGroup>
              <DatePicker
                value={this.startDate()}
                placeholderLabel={DATE_RANGE_FROM_PLACEHOLDER_LABEL}
                onChange={this.onChangeStartDate}
                maxDate={this.endDate()}
              />
            </InputGroup>
          </FeatureDetailsFormItem>
        )}
        <FeatureDetailsFormItem>
          <InputGroup>
            <DatePicker
              value={this.endDate()}
              placeholderLabel={DATE_RANGE_TO_PLACEHOLDER_LABEL}
              onChange={this.onChangeEndDate}
              minDate={this.minEndDate()}
            />
          </InputGroup>
        </FeatureDetailsFormItem>
      </DateRangeWrapper>
    );
  }
}
