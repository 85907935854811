import { connect } from "react-redux";
import MapView from "./MapView";
import * as actions from "../actions";
import {
  getWebMapLoading,
  getWorkflowSelecting,
  getPluginsError,
  getShowMap,
  getDateFilterText,
  getWorkflowSize,
  getAttribution,
  getWorkflowPanelOpen,
  getHasSelectedWorkflow,
  getHasMapViewExtent
} from "../selectors";

export default connect(
  (state) => ({
    showMap: getShowMap(state),
    hasMapViewExtent: getHasMapViewExtent(state),
    webMapIsLoading: getWebMapLoading(state),
    workflowIsLoading: getWorkflowSelecting(state),
    hasSelectedWorkflow: getHasSelectedWorkflow(state),
    pluginErrors: getPluginsError(state),
    dateFilterText: getDateFilterText(state),
    workflowSize: getWorkflowSize(state),
    attribution: getAttribution(state),
    workflowPanelOpen: getWorkflowPanelOpen(state)
  }),
  actions
)(MapView);
