import React, { Component } from "react";
import {
  SearchInputWrapper,
  SearchInputContainer
} from "../../../AgBoxUIKit/plugin/layout";
import { Icon } from "../../../AgBoxUIKit/core";
import { Input, Button } from "../../../AgBoxUIKit/core/components";
import { defaultTheme } from "../../../AgBoxUIKit";
import { isNullOrUndefined } from "../../../App/utils";

export default class TextSearch extends Component {
  constructor(props) {
    super(props);
    this.textInput = React.createRef();
    this.timer = null;
    this.controller = new AbortController();
  }
  activeTextSearch = () => {
    const { activeTextSearch } = this.props;
    return !isNullOrUndefined(activeTextSearch) ? activeTextSearch : "";
  };

  isSearchNotBlank = () => {
    const activeTextSearch = this.activeTextSearch();
    return activeTextSearch.length > 0;
  };

  handleClearSearch = () => {
    const { onSearchCleared } = this.props;
    if (!onSearchCleared) return false;
    return onSearchCleared();
  };

  handleOnSearchStart = (value) => {
    if (!value || value === "") return this.handleClearSearch();
    const { onSearchStart } = this.props;
    if (!onSearchStart) return false;
    return onSearchStart({ geometry: null });
  };

  handleUpdateSearchText = (value) => {
    const { updateTextSearch } = this.props;
    if (!updateTextSearch) return false;
    updateTextSearch(value);
  };

  whenLoading = () => {
    const { loading } = this.props;
    return loading === true;
  };

  getAriaLabel = () => {
    const { ariaLabel } = this.props;
    return ariaLabel ? ariaLabel : "";
  };

  render() {
    return (
      <SearchInputWrapper data-name={"SearchInputWrapper"}>
        <SearchInputContainer
          data-name={"SearchInputContainer"}
          appearsdisabled={this.whenLoading()}
        >
          <Icon
            data-name={"Icon"}
            iconColor={defaultTheme.agBlue}
            type={"search"}
            bgHeight={"2em"}
            bgWidth={"2em"}
            iconHeight={"1.3em"}
            iconWidth={"1.3em"}
          />
          <Input
            id="text-search-input"
            ref={this.textInput}
            disabled={this.whenLoading()}
            type={"text"}
            value={this.activeTextSearch()}
            aria-label={this.getAriaLabel()}
            onChange={(e) => this.handleUpdateSearchText(e.target.value)}
          />
          {this.isSearchNotBlank() && (
            <Button
              aria-label="Reset the text search input"
              type="reset"
              onClick={this.handleClearSearch}
              styletype={"iconButton"}
              disabled={this.whenLoading()}
            >
              <Icon
                data-name={"Icon"}
                iconColor={defaultTheme.agRed}
                type={"close"}
                bgHeight={"1.8em"}
                bgWidth={"1.8em"}
                iconHeight={"1.2em"}
                iconWidth={"1.2em"}
              />
            </Button>
          )}
        </SearchInputContainer>
      </SearchInputWrapper>
    );
  }
}
