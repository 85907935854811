import styled from "styled-components/macro";

import {
  IconBackground,
  IconSymbol,
  Button,
  WidgetCloseButton,
  Heading1,
  Heading3,
  Input,
  List,
  Heading2,
  ButtonLabel,
  ButtonLink,
  SidebarControllerWrapper,
  P,
  Fieldset,
  Label,
  Legend
} from "../components";
import {
  PLUGIN_SIZE_REG,
  PLUGIN_SIZE_DOUBLE,
  PLUGIN_SIZE_FULL,
  PLUGIN_SIZE_HALF
} from "../../../constants";
import { loaderSizeRegular } from "../..";
import {
  AccordionCollapsed,
  FeatureDetailsFormItem,
  FormItemFieldContainer
} from "../../plugin/components";

export const LogoImage = styled.img`
  width: 100%;
  max-width: 400px;
  margin-bottom: 1rem;
`;
LogoImage.displayName = "LogoImage";

export const LoaderWrapper = styled.div`
  width: 100%;
  justify-content: space-around;
  align-items: center;
  opacity: 1;
  animation: fadeIn 0.3s ease-out;
  z-index: 999;
  display: ${(props) => (props.visible ? "flex" : "none")};
  height: ${(props) => (props.loaderHeight ? props.loaderHeight : "100%")};
`;
LoaderWrapper.displayName = "LoaderWrapper";

export const LoaderContainer = styled.div`
  padding: ${(props) => {
    if (props.size === loaderSizeRegular) return "1.5rem";
  }};
  & ${IconBackground} {
    box-shadow: 0px 2px 5px -1px rgba(0, 0, 0, 0.46);
  }
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: ${(props) =>
    props.size === loaderSizeRegular ? "column" : "row"};
`;
LoaderContainer.displayName = "LoaderContainer";
export const LogoImageWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: ${(props) => props.theme.agOffWhite};
  z-index: 999;
  ${LoaderWrapper} {
    height: auto;
  }
  ${LoaderContainer} {
    padding-top: 0;
  }
`;
LogoImageWrapper.displayName = "LogoImageWrapper";

export const AppWrapper = styled.div`
  font-size: ${(props) => {
    const { aspect, landscape } = props.theme;
    if (aspect === landscape) return "calc(0.5rem + 0.5vw)";
    else return "calc(0.6rem + 0.5vh)";
  }};
  line-height: ${(props) => props.theme.lineHeightBase};
  height: ${(props) => `${props.height}px`};
  background-color: ${(props) => props.theme.agLightestGray};
`;

export const AppContainer = styled.div`
  height: 100%;
  width: 100%;
`;

export const LoadingWrapper = styled.div``;
LoadingWrapper.displayName = "LoadingWrapper";

export const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  /* & > div {
    position: absolute;
    top: 50%;
  } */
`;
LoadingContainer.displayName = "LoadingContainer";

export const MapViewWrapper = styled.div`
  padding-left: 0;
  transition: padding 0.75s;
  transition-timing-function: ease-out;
  .esri-compass {
    margin-top: 0.8em;
  }
  @media all and (min-width: 500px) {
    padding-left: ${(props) => {
      const {
        size,
        workflowClosed,
        theme: {
          agPluginSize,
          agPluginSizeDouble,
          aspect,
          landscape,
          square,
          menuWidthClose
        }
      } = props;
      if (workflowClosed || size === PLUGIN_SIZE_FULL) return "0";
      else if (size === PLUGIN_SIZE_DOUBLE) {
        return aspect === landscape
          ? `calc(${agPluginSizeDouble}px + 8vw + ${menuWidthClose})`
          : aspect === square
          ? `calc(${agPluginSizeDouble}px + 5vw + ${menuWidthClose})`
          : `calc(${agPluginSizeDouble}px + 5vh + ${menuWidthClose})`;
      } else if (size === PLUGIN_SIZE_HALF) return "50%";
      return `calc(${agPluginSize}px + 5vw + ${menuWidthClose})`;
    }};
  }
`;
MapViewWrapper.displayName = "MapViewWrapper";

export const MapViewContainer = styled.div`
  opacity: ${(props) => (props["data-loading"] ? "0.5" : "1")};
  transition: opacity 1s;
  height: 100%;
  max-height: 100%;
  width: 100%;
  position: relative;
`;
MapViewContainer.displayName = "MapViewContainer";

export const MapSpinnerWrapper = styled.div`
  width: 100vh;
  width: 100vw;
  z-index: 1;
  & ${LoaderContainer} {
    padding: 0;
  }
`;
MapSpinnerWrapper.displayName = "MapSpinnerWrapper";

export const SideBarWrapper = styled.div`
  position: relative;
  left: 0;
`;
SideBarWrapper.displayName = "SideBarWrapper";

export const SideBarContentWrapper = styled.div``;
SideBarContentWrapper.displayName = "SideBarContentWrapper";

export const SideBarListWrapper = styled.div`
  width: ${(props) => {
    if (props.type !== "workflows") return "auto";
    return props.sidebarOpen
      ? props.theme.menuWidthOpen
      : props.theme.menuWidthClose;
  }};
  height: ${(props) =>
    props.type === "workflows" ? "calc(100vh - 7.5em)" : "auto"};
  overflow-y: ${(props) => (props.sidebarOpen ? "auto" : "hidden")};
  overflow-x: hidden;
  ${LoaderWrapper} {
    height: ${(props) => (props.type === "workflows" ? "auto" : "100%")};
    width: ${(props) =>
      props.type === "workflows" ? props.theme.menuWidthClose : "100%"};
    margin: 0.5em 0;
  }
  ${Label} {
    color: ${(props) => props.theme.agWhite};
    font-size: ${(props) => props.theme.fontSizeSmall};
    padding-left: 0.5em;
  }
  &:not(:last-child) {
    padding-bottom: ${(props) =>
      props.type === "propertyListing" ? "0.5em" : "0"};
  }
`;
SideBarListWrapper.displayName = "SideBarListWrapper";

export const SideBarContainer = styled.div`
  background-color: ${(props) => props.theme.agBlack};
  height: 100vh;
  left: 0;
  position: absolute;
  top: 0;
  transition: width 0.15s ease-in-out;
  width: ${(props) =>
    props.open ? props.theme.menuWidthOpen : props.theme.menuWidthClose};
  z-index: ${(props) => (props.open ? "101" : "100")};
  overflow: hidden;
`;
SideBarContainer.displayName = "SideBarContainer";

export const SideBarListContainer = styled.div``;
SideBarListContainer.displayName = "SideBarListContainer";

export const SideBarOrgControllerWrapper = styled.div`
  border-bottom: 2px solid ${(props) => props.theme.agGreen};
`;
SideBarOrgControllerWrapper.displayName = "SideBarOrgControllerWrapper";

export const SideBarOrgControllerContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: ${(props) => props.theme.menuWidthOpen};
  ${IconBackground} {
    flex-shrink: 0;
    width: ${(props) => props.theme.menuWidthClose};
    height: ${(props) => props.theme.menuWidthClose};
    &:hover {
      color: ${(props) => props.theme.agWhite};
      border: none;
      cursor: pointer;
    }
  }
  & ${SidebarControllerWrapper} {
    &:first-child {
      flex-grow: 99;
      flex-shrink: 99;
      align-items: center;
      > ${ButtonLabel} {
        white-space: normal;
        font-size: ${(props) => props.theme.fontSizeOne};
      }
      ${ButtonLink} {
        width: 100%;
        height: 3.75em;
        align-items: center;
      }
      ${IconBackground} {
        justify-content: flex-start;
        padding-left: 1em;
        margin-right: -1.05em;
      }
    }
    &:last-child {
      flex-grow: 1;
      flex-shrink: 1;
    }
  }
`;
SideBarOrgControllerContainer.displayName = "SideBarOrgControllerContainer";

export const SideBarShade = styled.div`
  background-color: ${(props) => props.theme.agBlack};
  height: 100vh;
  left: ${(props) => (props.closed ? "-999vw" : "0")};
  position: absolute;
  opacity: ${(props) => (props.closed ? "0" : "0.6")};
  top: 0;
  transition: opacity 0.6s;
  transition-timing-function: ease-in-out;
  width: 100vw;
  z-index: ${(props) => (props.workflowPanelOpen ? "100" : "99")};
`;

export const SideBarPropertySelectorWrapper = styled.div`
  background-color: ${(props) => props.theme.agTrueBlack};
  width: ${(props) => props.theme.menuWidthOpen};
`;
SideBarPropertySelectorWrapper.displayName = "SideBarPropertySelectorWrapper";

export const SideBarPropertySelectorContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`;
SideBarPropertySelectorContainer.displayName =
  "SideBarPropertySelectorContainer";

export const AgBoxLogoWrapper = styled.div`
  width: 100%;
`;
AgBoxLogoWrapper.displayName = "AgBoxLogoWrapper";

export const AgBoxLogoContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  ${IconBackground} {
    flex-grow: 1;
    flex-shrink: 0;
    width: ${(props) => props.theme.menuWidthClose};
    height: ${(props) => props.theme.menuWidthClose};
    &:hover {
      color: ${(props) => props.theme.agWhite};
      background-color: ${(props) => props.theme.agBlue};
      border: none;
      cursor: pointer;
    }
  }

  & ${ButtonLink} {
    &:first-child {
      flex-grow: 99;
      flex-shrink: 99;
      align-items: center;
      > ${ButtonLabel} {
        white-space: normal;
        font-size: ${(props) => props.theme.fontSizeOne};
      }
      ${IconBackground} {
        justify-content: flex-start;
        padding-left: 1em;
      }
    }
    &:last-child {
      flex-grow: 1;
      flex-shrink: 1;
    }
  }
`;
AgBoxLogoContainer.displayName = "AgBoxLogoContainer";

export const ChangePropertyContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  ${IconBackground} {
    width: ${(props) => props.theme.menuWidthClose};
    height: ${(props) => props.theme.menuWidthClose};
  }
`;
ChangePropertyContainer.displayName = "ChangePropertyContainer";

export const SearchInputWrapper = styled.div`
  background-color: ${(props) => props.theme.agWhite};
  border: 1px solid ${(props) => props.theme.agBlue};
  border-radius: 0.1rem;
  margin-bottom: 14px;
  flex-grow: 1;
`;
SearchInputWrapper.displayName = "SearchInputWrapper";

export const SearchInputContainer = styled.div`
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 0.2rem;
  border: 1px solid ${(props) => props.theme.agWhite};
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-content: center;

  input[type="text"] {
    box-shadow: none;
    background-color: transparent;
    border: none;
    color: ${(props) => props.theme.agBlack};
    font-size: ${(props) => props.theme.fontSizeSmall};
    font-weight: ${(props) => props.theme.baseFontWeight};
    letter-spacing: 0.06rem;
    padding: 0.6em 0 0 0;
    height: 2.25em;
    margin: 0;
    width: calc(100% - 3.75em);
    appearance: none;
  }

  input[type="text"]:focus {
    box-shadow: none !important;
    border: none;
    appearance: none;
    background-color: transparent;
    border-color: none;
  }
`;
SearchInputContainer.displayName = "SearchInputContainer";

export const SideBarDropDownWrapper = styled.div`
  margin: 0;
  margin-bottom: ${(props) => {
    if (props.type === "orgSubMenuController" && !props.closed) return "5px";
  }};
  box-sizing: border-box;
  max-height: ${(props) =>
    props.closed
      ? "0px"
      : props.type === "propertyListing"
      ? "calc(100vh - 122px)"
      : "1000px"};
  transition: max-height 0.2s ease;
  position: relative;
  overflow: ${(props) =>
    props.type === "propertyListing" && !props.closed ? "auto" : "hidden"};
`;
SideBarDropDownWrapper.displayName = "SideBarDropDownWrapper";

export const SideBarDropDownContainer = styled.div`
  padding: 0.5em;
  display: flex;
  flex-direction: column;

  & > ${List} {
    flex-grow: 99;
  }
`;
SideBarDropDownContainer.displayName = "SideBarDropDownContainer";

export const PropertyListContainer = styled.div``;
PropertyListContainer.displayName = "PropertyListContainer";

export const ModalWrapper = styled.div`
  font-size: ${(props) => {
    const { aspect, landscape } = props.theme;
    if (aspect === landscape) return "calc(0.5rem + 0.5vw)";
    else return "calc(0.6rem + 0.5vh)";
  }};
`;
ModalWrapper.displayName = "ModalWrapper";

export const ModalContainer = styled.div`
  max-width: 64em;
  position: relative;
  padding: 2em;
  background-color: #ffffff;
  background-clip: padding-box;
  margin: auto;
  @media all and (max-height: 800px) {
    padding: 1em;
  }
  @media all and (min-width: 36em) {
    min-width: 32em;
  }
`;
ModalContainer.displayName = "ModalContainer";

export const ModalBody = styled.div`
  & p,
  & legend,
  & label {
    font-family: ${(props) => props.theme.baseFont};
    margin: 0;
    padding: 0;
    line-height: ${(props) => props.theme.lineHeightBase};
    font-size: ${(props) => props.theme.fontSizeBase};
  }
`;
ModalBody.displayName = "ModalBody";

export const ModalFooter = styled.div`
  margin-top: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;

  & button,
  & a {
    margin-left: 1rem;
    min-width: 25%;
    &:first-child {
      margin-left: 0;
    }
  }
`;
ModalFooter.displayName = "ModalFooter";

export const WidgetWrapper = styled.div`
  opacity: ${(props) => (props.disabled ? 0.4 : 1)};
  position: relative;
`;
WidgetWrapper.displayName = "WidgetWrapper";

export const WidgetOuterWrapper = styled.div`
  opacity: ${(props) => (props.disabled ? 0.4 : 1)};
  right: 15px;
  position: absolute;
  top: ${(props) => (props.has3D ? "220px" : "147px")};
  @media all and (min-width: 500px) {
    z-index: ${(props) => {
      if (
        props.workflowSize === PLUGIN_SIZE_REG ||
        props.workflowSize === PLUGIN_SIZE_DOUBLE
      )
        return "1";
    }};
  }
`;
WidgetOuterWrapper.displayName = "WidgetOuterWrapper";

export const WidgetContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  & > ${Button} {
    padding: 0;
    border: 0;
    height: 2.8em;
  }

  & > ${Button}:hover {
    background-color: transparent;
  }

  & > ${Button}:hover ${IconSymbol} {
    opacity: 0.7;
  }
`;
WidgetContainer.displayName = "WidgetContainer";

export const WidgetPanelWrapper = styled.div`
  background-color: ${(props) => props.theme.agWhite};
  box-shadow: 0px 2px 0px 0px ${(props) => props.theme.agTransparentLightBlack};
  width: 12.5em;
  min-height: 13.75em;
  top: -10em;
  position: absolute;
  right: 3.5em;

  &:before {
    content: "";
    display: block;
    width: 0;
    height: 0;
    border-top: 12px solid transparent;
    border-bottom: 12px solid transparent;
    border-left: 12px solid ${(props) => props.theme.agWhite};
    position: absolute;
    top: 9.5em;
    right: -0.75em;
  }
  @media all and (min-width: 420px) {
    width: 18.75em;
  }
  @media all and (min-height: 600px) {
    top: -1.8em;
    &:before {
      top: 2.1em;
    }
  }
`;
WidgetPanelWrapper.displayName = "WidgetPanelWrapper";

export const WidgetPanelContainer = styled.div`
  padding: 1.2em;
  display: flex;
  flex-direction: column;
  padding-bottom: 1.8em;
  min-height: ${(props) => {
    if (props.type === "layers" || props.type === "measure") return "3.1em";
    if (props.type === "print") return "15.6em";
    if (props.type === "legend") return "13.4em";
    return "-3.1px";
  }};

  max-height: 22em;
  overflow-y: auto;
  position: relative;

  & ${WidgetCloseButton} {
    top: 0.9em;
  }

  hr {
    border-bottom: 1px solid ${(props) => props.theme.agLightGray};
    margin: 0.6em 0;
  }

  p {
    font-size: ${(props) => props.theme.fontSizeBase};
    line-height: ${(props) => props.theme.lineHeightSmallest};
    margin-bottom: 1em;
    margin-top: 0;
  }
  @media all and (min-height: 800px) {
    max-height: 100%;
    overflow-y: auto;
  }
`;
WidgetPanelContainer.displayName = "WidgetPanelContainer";

export const PrinterPreviewWrapper = styled.div`
  width: 1100px;
  height: 650px;
  position: fixed;
  top: calc(25vh);
  left: 300px;
  border: 1px dashed ${(props) => props.theme.agBlue};
  background-color: ${(props) => props.theme.agTransparentLightBlue};
  z-index: -1;
  pointer-events: none;
`;
PrinterPreviewWrapper.displayName = "PrinterPreviewWrapper";

export const WidgetLoaderWrapper = styled.div`
  min-height: 50px;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
`;
WidgetLoaderWrapper.displayName = "WidgetLoaderWrapper";

export const WidgetLoaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.agTransparentWhite};
  height: 100%;
  & > p {
    font-size: ${(props) => props.theme.fontSizeTwo};
    margin: 0;
  }
`;
WidgetLoaderContainer.displayName = "WidgetLoaderContainer";

export const PrinterMapExportWrapper = styled.div``;
PrinterMapExportWrapper.displayName = "PrinterMapExportWrapper";

export const PrinterMapExportContainer = styled.div`
  margin-top: 2rem;

  & ${Heading3} {
    margin-bottom: 0.5rem;
  }
`;
PrinterMapExportContainer.displayName = "PrinterMapExportContainer";

export const PrinterMapFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1rem;

  & label {
    display: block;
  }
  & ${Input} {
    font-size: ${(props) => props.theme.fontSizeBase};
  }
  & ::placeholder {
    font-size: ${(props) => props.theme.fontSizeBase};
    font-style: italic;
    color: ${(props) => props.theme.agLighterGray};
  }
  fieldset {
    margin-bottom: 0.5em;
  }
`;
PrinterMapFormContainer.displayName = "PrinterMapFormContainer";

export const PrinterPreviewControlContainer = styled.div`
  margin-top: 1rem;
  & > label {
    display: flex;
    flex-direction: row;
    font-size: ${(props) => props.theme.fontSizeSmall} & span {
      color: ${(props) => props.theme.agBlue};
      margin-left: 0.5rem;
    }
  }
`;
PrinterPreviewControlContainer.displayName = "PrinterPreviewControlContainer";

export const AccordianWrapper = styled.div`
  margin: ${(props) => {
    if (props.type === "packageContainer" || props.type === "packageDetails")
      return "1.2em 0 0";
  }};

  flex-grow: ${(props) => {
    if (props.type === "packageDetails") return "99";
  }};
`;
AccordianWrapper.displayName = "AccordianWrapper";

export const AccordianContainer = styled.div`
  border: 1px solid ${(props) => props.theme.agLightestGray};
  border-top: none;
  background-color: ${(props) => props.theme.agWhite};
`;
AccordianContainer.displayName = "AccordianContainer";

export const AccordianHeaderContainer = styled.div`
  background-color: ${(props) => props.theme.agOffWhite};
  border: 1px solid ${(props) => props.theme.agLightestGray};
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: auto;
  & > h5 {
    width: 100%;
  }
  & + &,
  ${AccordionCollapsed} + &,
  & + ${AccordionCollapsed} {
    margin-top: 1rem;
  }
  flex-direction: ${(props) =>
    props.headerDirection ? props.headerDirection : ""};
`;
AccordianHeaderContainer.displayName = "AccordianHeaderContainer";

export const AccordianBodyContainer = styled.div`
  max-height: ${(props) => {
    if (props.active) return "400px";
    return "0px";
  }};
  height: ${(props) => {
    if (props.active) return "auto";
    return "0px";
  }};
  transition: max-height 0.5s ease-in-out;
  overflow-y: auto;
  display: flex;
  flex-grow: 99;
  overflow-x: hidden;

  ::-webkit-scrollbar {
    width: 0.6rem;
  }
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }
  ::-webkit-scrollbar-thumb {
    background-color: ${(props) => props.theme.agLighterGray};
    outline: 1px solid ${(props) => props.theme.agLightGray};
  }
  & p {
    padding-top: 0.5em;
  }
  @media all and (max-height: 800px) {
    max-height: ${(props) => {
      if (props.active) return "200px";
      return "0px";
    }};
  }
`;
AccordianBodyContainer.displayName = "AccordianBodyContainer";

export const MeasureWidgetUnitWrapper = styled.div``;
MeasureWidgetUnitWrapper.displayName = "MeasureWidgetUnitWrapper";

export const MeasureWidgetTextWrapper = styled.div`
  margin-top: 1em;
  margin-bottom: 1em;

  & ${Button} {
    width: 100%;
  }
`;
MeasureWidgetTextWrapper.displayName = "MeasureWidgetTextWrapper";

export const MeasureWidgetTextContainer = styled.div`
  margin-bottom: 1rem;
  :last-child {
    margin-bottom: 0;
  }
`;
MeasureWidgetTextContainer.displayName = "MeasureWidgetTextContainer";

export const PaginationWrapper = styled.div`
  margin-top: 0.5rem;
  flex-grow: 1;
`;
PaginationWrapper.displayName = "PaginationWrapper";

export const PaginationContainer = styled.div`
  margin-top: 0.5rem;
  flex-grow: 1;
`;
PaginationContainer.displayName = "PaginationContainer";

export const LoginWrapper = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: ${(props) => props.theme.agLightestGray};
`;
LoginWrapper.displayName = "LoginWrapper";

export const LoginContainer = styled.div``;
LoginContainer.displayName = "LoginContainer";

export const OrganisationSelectorListWrap = styled.div`
  height: 90%;
  overflow-y: auto;
  ${LoaderWrapper} {
    margin-bottom: 1rem;
  }
`;
OrganisationSelectorListWrap.displayName = "OrganisationSelectorListWrap";

export const LoadingTextContainer = styled.div`
  padding: 1em;
`;
LoadingTextContainer.displayName = "LoadingTextContainer";

export const LoadingTextWrapper = styled.div`
  background-color: ${(props) => {
    if (props.bgColor) return props.bgColor;
    return "transparent";
  }};
  border-radius: 0.2em;
`;
LoadingTextContainer.displayName = "LoadingTextContainer";

export const GraphWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  max-height: 100%;
  .VictoryContainer {
    height: auto !important;
    svg + div {
      height: 100% !important;
    }
  }
  h2 {
    margin-top: 0.5em;
  }
  p {
    margin-top: 0.5em;
  }
`;
GraphWrapper.displayName = "GraphWrapper";

export const GraphLegendWrapper = styled.div`
  max-height: 100%;
  ${(props) => {
    if (props.styles) return props.styles;
  }};
`;
GraphLegendWrapper.displayName = "GraphLegendWrapper";

export const GraphLegendCircle = styled.div`
  width: 0.5em;
  height: 0.5em;
  border-radius: 50%;
  background-color: ${(props) => props.color};
  margin-right: 0.5em;
  border-width: 3px;
  border-style: solid;
  border-color: ${(props) =>
    props.hovered || props.selected ? props.theme.agLightBlue : "transparent"};
`;
GraphLegendCircle.displayName = "GraphLegendCircle";

export const GraphLegendText = styled.span`
  font-size: ${(props) => props.theme.fontSizeSmaller};
  max-width: 22ch;
  text-align: left;
  line-height: ${(props) => props.theme.lineHeightSmaller};
  font-weight: ${(props) =>
    props.hovered || props.selected ? "bold" : "normal"};
  color: ${(props) =>
    props.hovered || props.selected
      ? props.theme.agLightBlue
      : props.theme.agBlack};
`;
GraphLegendText.displayName = "GraphLegendText";

export const GraphLegendButton = styled.button`
  display: flex;
  align-items: center;
  margin-bottom: 0.5em;
  font-size: ${(props) => props.theme.fontSizeBase};
  cursor: pointer;
  &:last-child {
    margin-bottom: 0;
  }
`;
GraphLegendButton.displayName = "GraphLegendButton";

export const GraphLegendItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.5em;
  font-size: ${(props) => props.theme.fontSizeBase};
  &:last-child {
    margin-bottom: 0;
  }
`;
GraphLegendItem.displayName = "GraphLegendItem";

export const MapViewDateWrapper = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
  display: flex;
  justify-content: center;
  padding-top: 1rem;
`;
MapViewDateWrapper.displayName = "MapViewDateWrapper";

export const MapViewDateText = styled(Heading2)`
  color: white;
`;
MapViewDateText.displayName = "MapViewDateText";

export const InlineCheckboxContainer = styled.div`
  margin-top: 1rem;
  & > label {
    display: flex;
    flex-direction: row;
    font-size: ${(props) => props.theme.fontSizeSmall} & span {
      margin-left: 0.2rem;
    }
  }
`;
InlineCheckboxContainer.displayName = "InlineCheckboxContainer";

export const Announcement = styled.div`
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
`;
Announcement.displayName = "Announcement";

export const AuthenticationWrapper = styled.div`
  min-height: 100vh;
  background-color: ${(props) => props.theme.agGreen};
  display: flex;
  justify-content: center;
  align-items: center;
`;
AuthenticationWrapper.displayName = "AuthenticationWrapper";

export const AuthenticationContainer = styled.div`
  width: ${(props) => {
    const { aspect, landscape } = props.theme;
    if (props.logout) return "";
    if (aspect === landscape) return "60vw";
    return "40vw";
  }};
  min-width: 300px;
  background-color: ${(props) => props.theme.agWhite};
  border-radius: 6px;
  box-sizing: content-box;
  margin: calc(0.6rem + 0.5vh) 0;
`;
AuthenticationContainer.displayName = "AuthenticationContainer";

export const AuthenticationContentWrap = styled.div`
  padding: 1.5em 4em;
  display: flex;
  align-items: center;
  flex-direction: column;
  ${P} {
    margin-bottom: 1.2em;
    text-align: center;
  }
  ${LogoImage} {
    max-width: 15em;
    margin-bottom: 1.8em;
  }
  ${ButtonLink} {
    margin-top: 0.6em;
    width: 100%;
    border-radius: 3px;
    padding: 1em 0.8em;
    text-transform: uppercase;
  }
`;
AuthenticationContentWrap.displayName = "AuthenticationContentWrap";

export const DateRangeWrapper = styled.div`
  ${FeatureDetailsFormItem} {
    margin: 0;
    padding: 0;
    ${IconBackground} {
      top: 0.5rem;
    }
  }
`;
DateRangeWrapper.displayName = "DateRangeWrapper";

export const PropertySearchSelectionWrapper = styled.div`
  margin-bottom: 1rem;
  ${Fieldset} {
    display: flex;
  }
  ${Legend} {
    padding-top: 0;
    color: ${(props) => props.theme.agWhite};
  }
  ${Label} {
    margin-right: 1em;
    align-items: center;
    color: ${(props) => props.theme.agWhite};
    input[type="radio"] {
      -webkit-appearance: none;
      border-radius: 50%;
      outline: none;
      border: 2px solid ${(props) => props.theme.agWhite};
      padding: 0.2em;
      width: 1.25em;
      height: 1em;
    }
    input[type="radio"]:checked {
      border-color: ${(props) => props.theme.agWhite};
      background: ${(props) => props.theme.agWhite} content-box;
    }
    ${Input} {
      &::before {
        display: none;
      }
      &:after {
        display: none;
      }
    }
  }
`;
PropertySearchSelectionWrapper.displayName = "PropertySearchSelectionWrapper";

export const SuggestionList = styled.ul`
  border-top: 1px solid ${(props) => props.theme.agLightestGray};
  max-height: 300px;
  overflow-y: auto;
`;
SuggestionList.displayName = "SuggestionList";

export const SuggestionListItem = styled.li`
  padding: 0.4em;
  cursor: pointer;
  &:nth-child(2n) {
    background-color: ${(props) => props.theme.agLightestGray};
  }
  &:hover,
  &[aria-selected="true"] {
    background-color: ${(props) => props.theme.agBlue};
    color: ${(props) => props.theme.agWhite};
  }
`;
SuggestionListItem.displayName = "SuggestionListItem";

export const PropertySearchCheckboxWrap = styled.div`
  display: flex;
  align-items: center;
  label {
    color: ${(props) => props.theme.agWhite};
  }
  input[type="checkbox"] {
    background-color: ${(props) => props.theme.agWhite};
    &:checked,
    &:focus {
      background-color: ${(props) => props.theme.agWhite};
    }
  }
`;
PropertySearchCheckboxWrap.displayName = "PropertySearchCheckboxWrap";

export const InvitationContentContainer = styled.div`
  width: 100%;
  ${P} {
    text-align: left;
  }
`;
InvitationContentContainer.displayName = "InvitationContentContainer";

export const AuthenticationHeading = styled(Heading1)`
  font-size: calc(0.5em + 1.3vw);
  color: ${(props) => props.theme.agGreen};
  font-weight: ${(props) => props.theme.baseFontWeightHeavy} !important;
  text-align: center;
  white-space: pre-line;

  :first-child {
    margin: 0;
  }
`;
AuthenticationHeading.displayName = "AuthenticationHeading";

export const AuthenticationPasswordText = styled(P)`
  font-size: ${(props) => props.theme.fontSizeSmallest} !important;
  line-height: ${(props) => props.theme.lineHeightSmaller};
`;
AuthenticationPasswordText.displayName = "AuthenticationPasswordText";

export const AuthenticationForm = styled.form`
  width: 100%;
  ${FormItemFieldContainer} {
    margin-bottom: 1rem;
  }
  ${Button} {
    width: 100%;
  }
`;
AuthenticationForm.displayName = "AuthenticationForm";

export const PasswordInput = styled(Input)`
  width: 100%;
  padding-right: 3rem;
  + ${IconBackground} {
    right: 2.5rem;
  }
  &:read-only {
    background-color: ${(props) => props.theme.agLightGray};
  }
`;
PasswordInput.displayName = "PasswordInput";

export const PasswordLabel = styled(Label)`
  color: ${(props) =>
    props.type === "formError" ? props.theme.agErrorRed : props.theme.agGreen};
  font-weight: ${(props) => props.theme.baseFontWeightHeavy};
`;
PasswordLabel.displayName = "PasswordLabel";

export const PasswordIconButton = styled.button`
  border: none;
  box-shadow: none;
  position: absolute;
  right: 0.5em;
  height: calc(100% - 0.25rem);
  top: 0.25rem;
`;
PasswordIconButton.displayName = "PasswordIconButton";

export const FormValidationText = styled(P)`
  font-size: ${(props) => props.theme.fontSizeSmallest} !important;
  line-height: ${(props) => props.theme.lineHeightSmaller};
  color: ${(props) => props.theme.agErrorRed};
  margin-top: 1rem;
  font-weight: ${(props) => props.theme.baseFontWeightHeavy};
  font-style: italic;
`;
FormValidationText.displayName = "FormValidationText";

export const WidgetHeading = styled.h2`
  font-size: ${(props) => props.theme.fontSizeOne};
  font-weight: ${(props) => props.theme.baseFontWeightHeavy};
`;
WidgetHeading.displayName = "WidgetHeading";
