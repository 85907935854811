import React from "react";
import PropTypes from "prop-types";
import {
  SideBarDropDownContainer,
  SideBarDropDownWrapper
} from "../../../layout";
import { ButtonLabel, List, ListItem } from "../..";
import { defaultTheme } from "../../../..";
import Icon from "../../Icon/Icon";

const SidebarControllerMenu = ({ open, items = [] }) => {
  return (
    <SideBarDropDownWrapper
      closed={!open}
      data-name={"SideBarDropDownWrapper"}
      aria-expanded={open}
      type={"orgSubMenuController"}
    >
      <SideBarDropDownContainer data-name={"SideBarDropDownContainer"}>
        <List type="sideBar" data-name={"List"}>
          {items.map(
            (
              {
                Component,
                styletype,
                disabled,
                icon,
                label,
                labelIcon,
                onClick,
                url,
                target,
                type
              },
              i
            ) => (
              <ListItem key={label}>
                <Component
                  styletype={styletype}
                  type={type}
                  disabled={disabled || !open}
                  tabIndex={open ? 0 : -1}
                  title={label}
                  onClick={onClick}
                  href={url}
                  target={target}
                >
                  {icon && (
                    <Icon
                      data-name={"Icon"}
                      iconColor={defaultTheme.agWhite}
                      {...icon}
                    />
                  )}
                  <ButtonLabel data-name={"ButtonLabel"} type={"sideBar"}>
                    {label}
                    {labelIcon && (
                      <Icon
                        data-name={"LabelIcon"}
                        iconColor={defaultTheme.agWhite}
                        type={labelIcon.type}
                        {...labelIcon}
                      />
                    )}
                  </ButtonLabel>
                </Component>
              </ListItem>
            )
          )}
        </List>
      </SideBarDropDownContainer>
    </SideBarDropDownWrapper>
  );
};

SidebarControllerMenu.propTypes = {
  open: PropTypes.bool,
  /** Menu items */
  items: PropTypes.arrayOf(
    PropTypes.shape({
      styletype: PropTypes.string,
      disabled: PropTypes.bool,
      label: PropTypes.string,
      onClick: PropTypes.func,
      /** the component/element to use for this list item */
      Component: PropTypes.elementType,
      /** Icon on right of label */
      labelIcon: PropTypes.shape({
        type: PropTypes.string,
        bgHeight: PropTypes.string,
        bgWidth: PropTypes.string,
        height: PropTypes.string,
        width: PropTypes.string
      }),
      /** Url if is a link component */
      url: PropTypes.string,
      /** E.g. '_blank' if is a link component */
      target: PropTypes.string,
      type: PropTypes.string,
      /** Main icon (on left of label) */
      icon: PropTypes.shape({
        type: PropTypes.string,
        bgHeight: PropTypes.string,
        bgWidth: PropTypes.string,
        height: PropTypes.string,
        width: PropTypes.string
      })
    })
  )
};

export default SidebarControllerMenu;
