import React, { Component } from "react";
import PropTypes from "prop-types";
import { VictoryChart, VictoryBoxPlot, VictoryAxis } from "victory";
import { roundNumber } from "../../../../../App/utils";
import { defaultTheme } from "../../../../";
export default class BoxPlot extends Component {
  static propTypes = {
    /** The data to be rendered on the graph */
    data: PropTypes.arrayOf(
      PropTypes.shape({
        x: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        min: PropTypes.number,
        max: PropTypes.number,
        median: PropTypes.number,
        q1: PropTypes.number,
        q3: PropTypes.number,
        fill: PropTypes.string,
        /** Optional - have array of y values instead of min/max/median/q1/q3 */
        y: PropTypes.arrayOf(PropTypes.number)
      })
    ),
    /** Custom y axis tick values */
    yTickValues: PropTypes.arrayOf(PropTypes.number),
    /** Label to show on x axis */
    xLabel: PropTypes.string,
    /** Label to show on y axis */
    yLabel: PropTypes.string,
    // A bool determining whether to show labels for max, min, median, q1 & q3
    labels: PropTypes.bool,
    // sets the boxplot styling for min, max, median, q1 & q3
    style: PropTypes.object,
    // sets styling for x-axis
    xAxisStyle: PropTypes.object,
    // sets styling for y-axis
    yAxisStyle: PropTypes.object,
    //sets the height of the graph
    height: PropTypes.number
  };

  getData = () => {
    const { data } = this.props;
    return data ? data : [];
  };

  getHeight = () => {
    const { height } = this.props;
    return height ? height : 800;
  };

  getBoxWidth = () => {
    const data = this.getData();
    if (!data || !data.length) return 0;
    return roundNumber(100 / data.length, 2);
  };

  getLabels = () => {
    const { labels } = this.props;
    return labels;
  };

  getXLabel = () => {
    const { xLabel } = this.props;
    return xLabel;
  };

  getYLabel = () => {
    const { yLabel } = this.props;
    return yLabel;
  };

  getMaxXDomain = () => {
    const data = this.getData();
    if (!data || !data.length) return null;
    const uniqueXValues = [...new Set(data.map((item) => item.x))];
    return uniqueXValues.length + 0.5;
  };

  getStyleFill = (datum) => {
    return datum.fill ? datum.fill : defaultTheme.agGray;
  };

  getStyle = () => {
    const { style } = this.props;
    return style
      ? style
      : {
          q1: {
            fill: ({ datum }) => this.getStyleFill(datum)
          },
          q3: {
            fill: ({ datum }) => this.getStyleFill(datum)
          },
          median: {
            stroke: defaultTheme.agLightGray,
            strokeWidth: 2
          },
          min: {
            stroke: defaultTheme.agLightGray,
            strokeWidth: 2
          },
          max: {
            stroke: defaultTheme.agLightGray,
            strokeWidth: 2
          }
        };
  };

  getXAxisStyle = () => {
    const { xAxisStyle } = this.props;
    return xAxisStyle ? xAxisStyle : { axisLabel: { fontSize: 20 } };
  };

  getYAxisStyle = () => {
    const { yAxisStyle } = this.props;
    return yAxisStyle ? yAxisStyle : { axisLabel: { fontSize: 20 } };
  };

  getYTickValues = () => {
    const { yTickValues } = this.props;
    return yTickValues;
  };

  render() {
    return (
      <VictoryChart
        height={this.getHeight()}
        width={400}
        minDomain={{ x: 0.5, y: 0 }}
        maxDomain={{ x: this.getMaxXDomain() }}
      >
        <VictoryAxis label={this.getXLabel()} style={this.getXAxisStyle()} />
        <VictoryAxis
          dependentAxis
          crossAxis={false}
          label={this.getYLabel()}
          style={this.getYAxisStyle()}
          tickValues={this.getYTickValues()}
        />
        <VictoryBoxPlot
          data={this.getData()}
          labels={this.getLabels()}
          boxWidth={this.getBoxWidth()}
          style={this.getStyle()}
        />
      </VictoryChart>
    );
  }
}
