export const getSelectedWorkflow = (state) => state.workflows.selected;
export const getWorkflowLoading = (state) => state.workflows.isLoading;
export const getAvailableWorkflows = (state) => state.workflows.available;
export const getWorkflowSelecting = (state) => state.workflows.isSelecting;
export const getWorkflowError = (state) => state.workflows.error;
export const getWorkflowEventHandlers = (state) =>
  state.workflows.eventHandlers;
export const getWorkflowCommand = (state) => state.workflows.command;
export const getWorkflowState = (state) => {
  const selectedWorkflow = getSelectedWorkflow(state);
  if (!selectedWorkflow) return {};
  const { workflowId } = selectedWorkflow;
  return state.workflows.workflowState[workflowId] || {};
};
export const getWorkflowSize = (state) => state.workflows.size;
export const getWorkflowPosition = (state) => state.workflows.position;
export const getFeatureListItems = (state) => state.workflows.featureListItems;
export const getWorkflowPanelOpen = (state) => state.workflows.panelOpen;
export const getSelectedWorkflowId = (state) =>
  state.workflows.selected && state.workflows.selected.workflowId
    ? state.workflows.selected.workflowId
    : null;
export const getSelectedWorkflowTitle = (state) =>
  state.workflows.selected && state.workflows.selected.title
    ? state.workflows.selected.title
    : null;

export const getHasSelectedWorkflow = (state) =>
  state.workflows.selected && state.workflows.selected ? true : false;

export const getPdfResults = (state) => state.workflows.pdfResults || {};
