import React from "react";
import PropTypes from "prop-types";
import { ButtonLink, Heading1, SidebarControllerWrapper } from "../..";
import { defaultTheme } from "../../../..";
import Icon from "../../Icon/Icon";
import { Button } from "../..";

const SidebarControllerButton = ({
  description,
  label,
  logoTitle,
  url,
  onClick
}) => {
  return (
    <SidebarControllerWrapper>
      <Button styletype={"sidebarIcon"} onClick={onClick}>
        <Icon
          type={"landkind-logo"}
          data-name={"Icon"}
          title={logoTitle}
          iconColor={defaultTheme.agWhite}
          iconHeight={"2em"}
          iconWidth={"2em"}
          bgShape="square"
        />
      </Button>
      <ButtonLink
        type="iconButton"
        data-name={"ButtonLink"}
        title={description}
        to={url}
        state={{
          docTitle: label
        }}
      >
        <Heading1 type={"org-heading"}>{label}</Heading1>
      </ButtonLink>
    </SidebarControllerWrapper>
  );
};

SidebarControllerButton.propTypes = {
  /** Accessible description for the button */
  description: PropTypes.string,
  /** Display label for the button */
  label: PropTypes.string,
  /** Accessible title for the logo */
  logoTitle: PropTypes.string,
  /** Url to navigate to on click of the link button */
  url: PropTypes.string,
  /** function to close sidebar */
  onClick: PropTypes.func
};

export default SidebarControllerButton;
