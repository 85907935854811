import React, { Component } from "react";
import { AccordianHeaderContainer } from "../../../layout";
import { Button, ButtonLabel } from "../..";
import Icon from "../../Icon/Icon";
import { defaultTheme } from "../../../..";
import PropTypes from "prop-types";

export default class AccordionHeader extends Component {
  static propTypes = {
    onClick: PropTypes.func,
    isOpen: PropTypes.bool,
    headerLabel: PropTypes.string,
    headerContent: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    headerDirection: PropTypes.string,
    accordionName: PropTypes.string
  };

  onClickHeader = () => {
    const { onClick } = this.props;
    if (onClick) onClick();
  };

  isOpen = () => {
    const { isOpen } = this.props;
    return isOpen === true;
  };

  headerLabel = () => {
    const { headerLabel } = this.props;
    return headerLabel ? headerLabel : "";
  };

  headerContent = () => {
    const { headerContent } = this.props;
    return headerContent ? headerContent : null;
  };

  headerDirection = () => {
    const { headerDirection } = this.props;
    return headerDirection ? headerDirection : null;
  };

  accordionName = () => {
    const { accordionName } = this.props;
    return accordionName;
  };

  render() {
    return (
      <AccordianHeaderContainer
        data-name={"AccordianHeaderContainer"}
        headerDirection={this.headerDirection()}
      >
        <Button
          onClick={this.onClickHeader}
          styletype={"accordianButton"}
          type="button"
          title={`${
            this.isOpen() ? "Close" : "Open"
          } ${this.accordionName()} accordion list`}
          aria-controls={`${this.accordionName()}-accordian-list`}
          aria-expanded={this.isOpen()}
          id={`${this.accordionName()}-accordian-list-title`}
        >
          <Icon
            data-name={"Icon"}
            rotation={this.isOpen() ? 90 : 0}
            type={"right"}
            iconColor={defaultTheme.agDarkerGray}
            iconHeight={"0.8em"}
            iconWidth={"0.8em"}
          />
          <ButtonLabel
            type={"packageContainer"}
            data-name={"ButtonLabel"}
            htmlFor={`${this.accordionName()}-accordian-list`}
          >
            {this.headerLabel()}
          </ButtonLabel>
        </Button>

        {this.headerContent()}
      </AccordianHeaderContainer>
    );
  }
}
