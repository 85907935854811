import React from "react";
import { NonEditableCellContentContainer } from "../../..";
import { P } from "../../../../../core/components";
import PropTypes from "prop-types";

const NonEditableCell = (props) => {
  return (
    <NonEditableCellContentContainer>
      {props.cellValues.map((value) => (
        <P key={value} aria-labelledby={props.headerId}>
          {value}
        </P>
      ))}
    </NonEditableCellContentContainer>
  );
};

NonEditableCell.propTypes = {
  /** An array of values to render */
  cellValues: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number])
  ),
  /** The id of the cell's corresponding header */
  headerId: PropTypes.string
};

export default NonEditableCell;
