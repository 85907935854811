import React from "react";
import { FeatureListItemEmphasisWrap, LegendItemImage } from "../../";
import PropTypes from "prop-types";
import { Icon } from "../../../../core";

const FeatureListEmphasis = (props) => {
  const getEmphasisIcon = (item) => {
    const { emphasisIconColor, emphasisIconType, emphasisIconUrl } = item;
    if (emphasisIconUrl)
      return (
        <LegendItemImage
          src={emphasisIconUrl}
          key={emphasisIconUrl}
          aria-hidden={true}
        />
      );
    else if (emphasisIconType) {
      return (
        <Icon
          key={`${emphasisIconType}-${emphasisIconColor}`}
          type={emphasisIconType}
          iconColor={emphasisIconColor}
          bgShape="round"
          iconWidth="1.25em"
          iconHeight="1.25em"
        />
      );
    } else return null;
  };
  return (
    <FeatureListItemEmphasisWrap>
      {props.emphasis.map((item) => getEmphasisIcon(item))}
    </FeatureListItemEmphasisWrap>
  );
};

FeatureListEmphasis.propTypes = {
  emphasis: PropTypes.arrayOf(
    PropTypes.shape({
      emphasisIconType: PropTypes.string,
      emphasisIconColor: PropTypes.string,
      emphasisIconUrl: PropTypes.string
    })
  )
};

export default FeatureListEmphasis;
