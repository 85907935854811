import React from "react";
import { TableHeaderCell } from "../../..";
import { P } from "../../../../../core/components";
import PropTypes from "prop-types";

const HeaderCell = (props) => {
  return (
    <TableHeaderCell id={props.id} cellWidth={props.width}>
      <P>{props.label}</P>
    </TableHeaderCell>
  );
};

HeaderCell.propTypes = {
  /** The id to use on the node */
  id: PropTypes.string,
  /** The cell width. Must include the unit e.g. px, % etc. */
  width: PropTypes.string,
  /** The text to render in the header cell */
  label: PropTypes.string
};

export default HeaderCell;
