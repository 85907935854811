import { equals } from "@arcgis/core/geometry/geometryEngine";
import React, { Component } from "react";
import { defaultTheme } from "../../AgBoxUIKit";
import { Modal } from "../../AgBoxUIKit/core";
import {
  PackageHelpDescription,
  ValidationMessage
} from "../../AgBoxUIKit/plugin/components";
import {
  getApplicableItems,
  getFormattedDefaultValue,
  isNullOrUndefined,
  sortListMethod,
  decodeComputedString
} from "../../App/utils";
import {
  ALL_PAGINATION_OPTION,
  BASIC_LEGEND_SVG_PRINT,
  CATEGORY_FIELD,
  DEFAULT_PAGINATION_OPTIONS,
  FEATURE_TEXT_SEARCH,
  GEOMETRY_TYPE_LINE,
  GEOMETRY_TYPE_POLY,
  POLYLINE_LEGEND_SVG,
  SVG_POINT_CIRCLE,
  SVG_POINT_CROSS,
  SVG_POINT_DIAMOND,
  SVG_POINT_SQUARE,
  SVG_POINT_TRIANGLE,
  SVG_POINT_X,
  TEMP_LAYER_PREFIX,
  DEFAULT_SKETCH_POINT_SYMBOL,
  LOCALE_COMPARE_SORT_METHOD
} from "../../constants";
import DrawingTool from "../DrawingTool";
import FeatureList from "../FeatureList";
import FeatureSearch from "../FeatureSearch";

export default class FeatureCreator extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchText: "",
      listItems: [],
      page: 1,
      limit: null,
      filters: [],
      selectedItem: null,
      sketchTool: null,
      showModal: false,
      sketches: []
    };
    this.drawingTool = React.createRef();
  }
  componentDidMount() {
    this.setListItems();
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevState.page !== this.state.page) {
      this.scrollToTop();
    }
  }
  getTitle = () => {
    const {
      title,
      labels: { DEFAULT_ADD_FEATURE_LABEL }
    } = this.props;
    return title || DEFAULT_ADD_FEATURE_LABEL;
  };

  getDescription = () => {
    const { description } = this.props;
    const featureType = this.getFeatureType();
    return (
      description ||
      this.getLanguageLabel("DEFAULT_ADD_FEATURE_DESCRIPTION", {
        featureType: featureType
      })
    );
  };

  getLanguageLabel = (stringConstant, data) => {
    const { labels } = this.props;
    const label = labels[stringConstant];
    if (!label) return stringConstant;
    return decodeComputedString(label, data);
  };

  getFeatureType = () => {
    const { labels } = this.props;
    const title = this.getLayers()?.[0]?.title;
    if (!title) return labels.FEATURE_LABEL;
    return title.startsWith(labels.HAZARD_LABEL.toLowerCase())
      ? labels.HAZARD_LABEL
      : labels.FEATURE_LABEL;
  };

  updateSearchText = (searchText) => {
    this.setState(
      {
        searchText,
        page: 1
      },
      () => this.updateSelectedListItem()
    );
  };

  updateSelectedListItem = () => {
    const { selectedItem } = this.state;
    const sketchLayer = this.getSketchLayer();
    if (!selectedItem || this.getPaginatedList().find((item) => item.selected))
      return;
    this.setState({
      selectedItem: null
    });
    if (sketchLayer) {
      sketchLayer.removeAll();
    }
    this.onResetSketches();
  };

  getSearchText = () => {
    const { searchText } = this.state;
    return searchText;
  };

  handleSearchCleared = () => {
    this.updateSearchText("");
  };

  getCreateFields = () => {
    const { createFields } = this.props;
    return createFields || [CATEGORY_FIELD];
  };

  getAvailableDrawingTools = () => {
    const { drawingTools } = this.props;
    return drawingTools && drawingTools.length
      ? drawingTools
      : ["polygon", "polyline", "point"];
  };

  getDomainValuesForField = (field) => {
    const { domainValues = {}, defaultValues = {} } = this.props;
    if (domainValues[field]) return domainValues[field];
    else if (!isNullOrUndefined(defaultValues[field])) {
      return Array.isArray(defaultValues[field])
        ? defaultValues[field]
        : [
            {
              value: defaultValues[field]
            }
          ];
    }
    return [];
  };

  getCombinations = (arraysToCombine = []) => {
    const divisors = [];
    let permsCount = 1;
    for (let i = arraysToCombine.length - 1; i >= 0; i--) {
      divisors[i] = divisors[i + 1]
        ? divisors[i + 1] * arraysToCombine[i + 1].length
        : 1;
      permsCount *= arraysToCombine[i].length || 1;
    }

    const getCombination = (n, arrays, divisors) =>
      arrays.reduce((acc, arr, i) => {
        acc.push(arr[Math.floor(n / divisors[i]) % arr.length]);
        return acc;
      }, []);

    const combinations = [];
    for (let i = 0; i < permsCount; i++) {
      combinations.push(getCombination(i, arraysToCombine, divisors));
    }
    return combinations;
  };

  getListItemsForGeometryType = async (geometryType, items, index) => {
    const layers = this.getLayers();
    const geometryTypeLayer = layers.find(
      (layer) => layer.geometryType === geometryType
    );
    if (!geometryTypeLayer || !geometryTypeLayer.renderer) return [];

    const { type, symbol } = geometryTypeLayer.renderer;
    if (type === "simple") {
      return items.map((item, i) => ({
        ...item,
        attributes: { ...item.attributes, objectId: index * 1000 + i },
        image: this.getImage(symbol, geometryType),
        symbol
      }));
    } else
      return await Promise.all(
        items.map(async (item, i) => {
          const correctedItem = {
            attributes: {
              ...item.attributes
            },
            geometry: item.geometry
          };

          const uniqueValueInfo =
            await geometryTypeLayer.renderer.getUniqueValueInfo(correctedItem);
          if (!uniqueValueInfo) return item;
          return {
            ...item,
            attributes: { ...item.attributes, objectId: index * 1000 + i },
            image: this.getImage(uniqueValueInfo.symbol, geometryType),
            symbol: uniqueValueInfo.symbol
          };
        })
      ).then((result) => result.filter((item) => item.image));
  };

  getGeometryTypesForCombo = (domainValues) => {
    const drawingTools = this.getAvailableDrawingTools();
    const domainValuesWithGeometryFilters = domainValues.filter(
      (domainValue) => {
        const { filters } = domainValue;
        if (!filters || !filters.length) return false;
        return filters.some(({ filterField }) =>
          Array.isArray(filterField)
            ? filterField.includes("geometryType")
            : filterField === "geometryType"
        );
      }
    );
    if (!domainValuesWithGeometryFilters.length) return drawingTools;

    const domainValueGeometryTypes = domainValues.reduce(
      (geometryTypes, domainValue) => {
        let availableGeometryTypes = drawingTools;
        if (domainValue.filters && domainValue.filters.length) {
          availableGeometryTypes = [].concat(
            ...domainValue.filters
              .filter(({ filterField }) =>
                Array.isArray(filterField)
                  ? filterField.includes("geometryType")
                  : filterField === "geometryType"
              )
              .map((filter) => {
                const { filterValue } = filter;
                return Array.isArray(filterValue) ? filterValue : filterValue;
              })
          );
        }

        //make sure geometry types are available for all domain values in combination
        return [
          ...new Set([...geometryTypes, ...availableGeometryTypes])
        ].filter(
          (geometryType) =>
            drawingTools.includes(geometryType) &&
            domainValuesWithGeometryFilters.every(({ filters }) => {
              return filters.some(({ filterField, filterValue }) => {
                const fields = Array.isArray(filterField)
                  ? filterField
                  : [filterField];
                const values = Array.isArray(filterValue)
                  ? filterValue
                  : [filterValue];
                return (
                  fields.includes("geometryType") &&
                  values.includes(geometryType)
                );
              });
            })
        );
      },
      []
    );
    return domainValueGeometryTypes;
  };

  createFakeGraphic = (itemDomainValues, layer) => {
    const {
      defaultValues = {},
      customAttributes = {},
      domainValues = {}
    } = this.props;
    const createFields = this.getCreateFields();
    const attributes = {
      title: itemDomainValues.map(({ value }) => value).join("-")
    };
    const customData = {};
    const { geometryType, fields } = layer;
    itemDomainValues.forEach((item, i) => {
      const attr = createFields[i];
      if (customAttributes[attr]) {
        customData[attr] = {
          ...customAttributes[attr],
          value: item.value
        };
      } else attributes[attr] = item.value;
    });
    const fakeGraphic = {
      attributes: {
        ...attributes,
        customData: JSON.stringify(customData)
      },
      geometry: {
        type: geometryType
      },
      layer
    };
    fields.forEach(({ name }) => {
      if (Object.prototype.hasOwnProperty.call(attributes, name)) return;
      const formattedDefaultValue = getFormattedDefaultValue(
        fakeGraphic,
        defaultValues[name],
        geometryType,
        domainValues[name]
      );
      fakeGraphic.attributes[name] = formattedDefaultValue;
    });

    Object.keys(customAttributes).forEach((attr) => {
      if (Object.prototype.hasOwnProperty.call(customData, attr)) return;
      const formattedDefaultValue = getFormattedDefaultValue(
        fakeGraphic,
        defaultValues[attr],
        geometryType,
        domainValues[attr]
      );
      fakeGraphic.attributes.customData = JSON.stringify({
        ...JSON.parse(fakeGraphic.attributes.customData),
        [attr]: {
          ...customAttributes[attr],
          value: formattedDefaultValue
        }
      });
    });
    return fakeGraphic;
  };

  createListItems = async (combinations) => {
    const layers = await Promise.all(
      this.getLayers().map((layer) => layer.when(() => layer))
    );
    const graphics = combinations.reduce((result, combo) => {
      const geometryTypes = this.getGeometryTypesForCombo(combo);
      return {
        ...result,
        ...geometryTypes.reduce((items, geometryType) => {
          const layer = layers.find(
            (layer) => layer.geometryType === geometryType
          );
          if (!layer) return items;
          const fakeGraphic = this.createFakeGraphic(combo, layer);

          if (
            combo.some(
              (item) => !getApplicableItems(fakeGraphic, item, geometryType)
            )
          )
            return items;
          return {
            ...items,
            [geometryType]: [...(result[geometryType] || []), fakeGraphic]
          };
        }, {})
      };
    }, {});
    return await Promise.all(
      Object.keys(graphics).map((geometryType, index) =>
        this.getListItemsForGeometryType(
          geometryType,
          graphics[geometryType],
          index
        )
      )
    ).then((results) => [].concat(...results));
  };

  getUniqueListItems = async () => {
    const createFields = this.getCreateFields();
    const combinations = this.getCombinations(
      createFields.map((field) => this.getDomainValuesForField(field))
    );
    return await this.createListItems(combinations);
  };

  setListItems = async () => {
    const listItems = await this.getUniqueListItems();
    this.setState({
      listItems
    });
  };

  getIsItemSelected = (item) => {
    const { selectedItem } = this.state;
    if (!item || !selectedItem) return false;
    const createFields = this.getCreateFields();
    const customData = JSON.parse(item.attributes.customData);
    const selectedItemCustomData = JSON.parse(
      selectedItem.attributes.customData
    );
    return createFields.every((field) => {
      if (Object.prototype.hasOwnProperty.call(item.attributes, field))
        return item.attributes[field] === selectedItem.attributes[field];
      if (customData[field] && selectedItemCustomData[field])
        return customData[field].value === selectedItemCustomData[field].value;
      else return item.attributes[field] === selectedItem.attributes[field];
    }) && selectedItem.geometry.type === item.geometry.type
      ? true
      : false;
  };

  getListItems = () => {
    const { listSortOrder } = this.props;
    const { listItems, searchText, filters = [] } = this.state;
    const createFields = this.getCreateFields();
    if (!listItems) return [];
    const { polygon, polyline, point } = listItems.reduce(
      (result, item) => {
        const { type } = item.geometry;
        if (filters.find((filterItem) => filterItem.value === type))
          return result;
        const customData = item.attributes.customData
          ? JSON.parse(item.attributes.customData)
          : {};
        const listItem = {
          ...item,
          selected: this.getIsItemSelected(item)
        };
        if (!searchText)
          return {
            ...result,
            [type]: [...result[type], listItem]
          };

        if (
          !createFields.some((field) => {
            const fieldValue =
              listItem.attributes[field] ||
              (customData[field] ? customData[field].value : "");
            if (!fieldValue || typeof fieldValue !== "string") return false;
            return fieldValue.toLowerCase().includes(searchText.toLowerCase());
          })
        )
          return result;
        return {
          ...result,
          [type]: [...result[type], listItem]
        };
      },
      {
        polygon: [],
        polyline: [],
        point: []
      }
    );
    let resultList = [...polygon, ...polyline, ...point];

    if (listSortOrder) {
      resultList = resultList.sort(
        sortListMethod(
          listSortOrder.ascending,
          LOCALE_COMPARE_SORT_METHOD,
          listSortOrder.order || ["title"]
        )
      );
    }

    return resultList;
  };

  getSketchLayer = () => {
    const { webMap } = this.props;
    return webMap.layers.items.find(
      (layer) => layer.title === `${TEMP_LAYER_PREFIX}_SKETCH`
    );
  };

  handleSelectListItem = (selectedItem) => {
    const { sketches } = this.state;
    const showModal = sketches.length ? true : false;
    this.setState(
      {
        selectedItem,
        showModal
      },
      () => {
        if (!showModal) this.selectNewTool();
      }
    );
  };

  getEmphasis = () => {
    const drawingTools = this.getAvailableDrawingTools();
    const listItems = this.getPaginatedList();
    return drawingTools.map((geometryType) => ({
      features: listItems.filter(
        (listItem) => listItem.geometry.type === geometryType
      ),
      emphasisIconType: geometryType,
      emphasisIconColor: defaultTheme.agBurntOrange,
      hideColorBar: true
    }));
  };

  getLayers = () => {
    const { layers } = this.props;
    return layers || [];
  };

  generatePolygonSVG = (symbol) => {
    const basicSvg = BASIC_LEGEND_SVG_PRINT;
    const svgWrap = document.createElement("div");
    svgWrap.innerHTML = basicSvg;
    const fillColor = symbol.color.toString();
    const g = svgWrap.querySelector("g");
    const path = g.querySelector("rect");
    let strokeColor = fillColor;
    let lineStyle = symbol.style;
    path.setAttribute("fill", fillColor);
    strokeColor = symbol.outline ? symbol.outline.color.toString() : fillColor;
    lineStyle = symbol.outline ? symbol.outline.style : "solid";
    path.setAttribute("stroke", strokeColor);

    if (lineStyle !== "solid") {
      let strokeDashArray = null;
      if (lineStyle === "dash") strokeDashArray = "6,8";
      else if (lineStyle === "long-dash-dot-dot")
        strokeDashArray = "14,8,1,8,1,8";
      else if (lineStyle === "dash-dot")
        strokeDashArray = "8,10.666666666666666,1,10.666666666666666";
      else if (lineStyle === "short-dash") strokeDashArray = "3,4";
      path.setAttribute("stroke-dasharray", strokeDashArray);
    }
    const svg = svgWrap.firstChild;
    return svg;
  };

  generatePolylineSVG = (symbol) => {
    const basicSvg = POLYLINE_LEGEND_SVG;
    const svgWrap = document.createElement("div");
    svgWrap.innerHTML = basicSvg;
    const strokeColor = symbol.color.toString();
    const g = svgWrap.querySelector("g");
    const path = g.querySelector("path");
    const lineStyle = symbol.style || "solid";
    path.setAttribute("stroke", strokeColor);

    if (lineStyle !== "solid") {
      let strokeDashArray = null;
      if (lineStyle === "dash") strokeDashArray = "6,8";
      else if (lineStyle === "long-dash-dot-dot")
        strokeDashArray = "14,8,1,8,1,8";
      else if (lineStyle === "dash-dot")
        strokeDashArray = "8,10.666666666666666,1,10.666666666666666";
      else if (lineStyle === "short-dash") strokeDashArray = "3,4";
      path.setAttribute("stroke-dasharray", strokeDashArray);
    }
    const svg = svgWrap.firstChild;
    return svg;
  };

  generatePointSVG = (symbol) => {
    const { style, color, outline } = symbol;
    let basicSVG = SVG_POINT_CIRCLE;
    if (style === "cross") basicSVG = SVG_POINT_CROSS;
    else if (style === "diamond") basicSVG = SVG_POINT_DIAMOND;
    else if (style === "square") basicSVG = SVG_POINT_SQUARE;
    else if (style === "x") basicSVG = SVG_POINT_X;
    else if (style === "triangle") basicSVG = SVG_POINT_TRIANGLE;
    const fillColor = color.toString();
    const strokeColor = outline ? outline.color.toString() : fillColor;
    const svgWrap = document.createElement("div");
    svgWrap.innerHTML = basicSVG;
    const svg = svgWrap.firstChild;
    const shapes = svg.childNodes;
    const lineStyle = outline ? outline.style : "solid";
    let strokeDashArray = null;
    if (lineStyle !== "solid") {
      if (lineStyle === "dash") strokeDashArray = "6,8";
      else if (lineStyle === "long-dash-dot-dot")
        strokeDashArray = "14,8,1,8,1,8";
      else if (lineStyle === "dash-dot")
        strokeDashArray = "8,10.666666666666666,1,10.666666666666666";
      else if (lineStyle === "short-dash") strokeDashArray = "3,4";
    }

    shapes.forEach((shape) => {
      if (shape.nodeName !== "#text") {
        if (strokeDashArray) {
          shape.setAttribute("stroke-dasharray", strokeDashArray);
        }
        shape.setAttribute("fill", fillColor);
        shape.setAttribute("stroke", strokeColor);
      }
    });
    return svg;
  };

  getImage = (symbol, geometryType) => {
    const { url } = symbol;
    if (url) return url;
    let svg;
    if (geometryType === GEOMETRY_TYPE_POLY)
      svg = this.generatePolygonSVG(symbol);
    else if (geometryType === GEOMETRY_TYPE_LINE)
      svg = this.generatePolylineSVG(symbol);
    else svg = this.generatePointSVG(symbol);
    return `data:image/svg+xml;base64,${window.btoa(
      new XMLSerializer().serializeToString(svg)
    )}`;
  };

  getDisplayTitleForField = (field) => {
    const { displayTitles = {} } = this.props;
    if (displayTitles[field]) return displayTitles[field];
    return field.replace(/([A-Z])/g, " $1").replace(/^./, function (str) {
      return str.toUpperCase();
    });
  };
  getSubtitleFields = () => {
    const createFields = this.getCreateFields();
    return createFields
      .filter((field, i) => i !== 0)
      .map((field) => ({
        field,
        label: `${this.getDisplayTitleForField(field)}: `
      }));
  };

  getDomainValues = () => {
    const { domainValues } = this.props;
    return domainValues || {};
  };

  getPaginationOptions = () => {
    const { orgPaginationOptions, paginationOptions } = this.props;
    if (paginationOptions) return paginationOptions;
    return orgPaginationOptions
      ? [...orgPaginationOptions, ALL_PAGINATION_OPTION]
      : DEFAULT_PAGINATION_OPTIONS;
  };

  getPageNumber = () => {
    const { page } = this.state;
    return page || 1;
  };

  getPageLimit = () => {
    const { limit } = this.state;
    return limit || this.getPaginationOptions()[0];
  };

  getPaginatedList = () => {
    const listItems = this.getListItems();
    if (!this.getShowPagination() || listItems.length <= this.getPageLimit()) {
      return listItems;
    }
    const page = this.getPageNumber();
    const limit = this.getPageLimit();
    const start = (page - 1) * limit;
    return listItems.slice(start, start + limit);
  };

  scrollToTop = () => {
    const firstListItem = document.querySelector(
      "[class*='FeatureListItemWrap']"
    );
    if (!firstListItem) return;
    firstListItem.scrollIntoView({
      behavior: "smooth"
    });
  };

  handleChangePageLimit = (limit) => {
    this.setState(
      {
        limit,
        page: 1
      },
      () => this.updateSelectedListItem()
    );
  };

  handleChangePageNumber = ({ page }) => {
    this.setState(
      {
        page
      },
      () => this.updateSelectedListItem()
    );
  };

  getActiveFilters = () => {
    const { filters } = this.state;
    return filters || [];
  };

  setSelectedFilters = (filters) => {
    this.setState(
      {
        filters,
        page: 1
      },
      () => this.updateSelectedListItem()
    );
  };

  getFilterOptions = () => {
    const drawingTools = this.getAvailableDrawingTools();
    if (drawingTools.length < 2) return [];
    const {
      FILTER_POLYGON_DEFAULT_LABEL,
      FILTER_POLYLINE_DEFAULT_LABEL,
      FILTER_POINT_DEFAULT_LABEL
    } = this.props.labels;
    return [
      {
        title: FILTER_POLYGON_DEFAULT_LABEL,
        type: "checkbox",
        filterType: "geometry",
        field: "geometry",
        value: "polygon",
        disabled: false,
        onClick: this.setSelectedFilters
      },
      {
        title: FILTER_POLYLINE_DEFAULT_LABEL,
        type: "checkbox",
        filterType: "geometry",
        field: "geometry",
        value: "polyline",
        disabled: false,
        onClick: this.setSelectedFilters
      },
      {
        title: FILTER_POINT_DEFAULT_LABEL,
        type: "checkbox",
        filterType: "geometry",
        field: "geometry",
        value: "point",
        disabled: false,
        onClick: this.setSelectedFilters
      }
    ].filter((filterItem) => drawingTools.includes(filterItem.value));
  };

  handleResetFilter = () => {
    this.setSelectedFilters([]);
  };

  onCompleteSketch = (e) => {
    const { onUpdateSketches } = this.props;
    const { selectedItem, sketches } = this.state;
    const updatedSketches = [
      ...sketches,
      {
        ...selectedItem,
        geometry: e.graphic.geometry
      }
    ];
    this.setState({
      sketches: updatedSketches
    });
    if (!onUpdateSketches) return;
    onUpdateSketches(updatedSketches);
  };

  onUpdateSketch = (e) => {
    const { onUpdateSketches } = this.props;
    const { sketches } = this.state;
    const updatedSketches = sketches.map((sketch) => {
      if (sketch.selected) sketch.geometry = e.graphics[0].geometry;
      return sketch;
    });
    this.setState({
      sketches
    });
    if (!onUpdateSketches) return;
    onUpdateSketches(updatedSketches);
  };

  setSketchTool = (sketchTool) => {
    this.setState({
      sketchTool
    });
  };

  onResetSketches = () => {
    this.setState({
      sketches: []
    });
    const { onUpdateSketches } = this.props;
    if (onUpdateSketches) onUpdateSketches([]);
  };

  handleDeleteSketches = (e) => {
    const { onUpdateSketches } = this.props;
    const { sketches } = this.state;
    if (!e) return;
    const updatedSketches = sketches.filter(
      (sketch) => !equals(sketch.geometry, e.graphics[0].geometry)
    );
    this.setState({
      sketches: updatedSketches
    });
    if (!updatedSketches.length) this.startNewSketch();
    if (onUpdateSketches) onUpdateSketches(updatedSketches);
  };

  getAllowAddMultipleFeatures = () => {
    const { allowAddMultiple } = this.props;
    return allowAddMultiple !== false;
  };
  getDrawingTool = () => {
    const { selectedItem } = this.state;
    if (!selectedItem) return null;
    const {
      symbol,
      geometry: { type }
    } = selectedItem;

    return (
      <DrawingTool
        tools={[
          {
            param: type
          }
        ]}
        customSymbols={{
          [`${type === "polyline" ? "line" : type}Symbol`]:
            symbol?.type !== "simple-marker" && symbol?.color?.a !== 0
              ? symbol
              : DEFAULT_SKETCH_POINT_SYMBOL
        }}
        onComplete={this.onCompleteSketch}
        onUpdate={this.onUpdateSketch}
        onSketchToolCreate={this.setSketchTool}
        showAddButton={this.getAllowAddMultipleFeatures()}
        showDeleteButton={true}
        canDelete={true}
        onReset={this.onResetSketches}
        onDelete={this.handleDeleteSketches}
        ref={this.drawingTool}
        removeGraphicsOnUnmount={false}
      />
    );
  };

  getShowModal = () => {
    const { showModal } = this.state;
    return showModal;
  };

  startNewSketch = () => {
    const { selectedItem, sketchTool } = this.state;
    if (!selectedItem || !sketchTool) return;
    const { handleSelectSketchTool } = this.drawingTool.current;
    const geometryType = selectedItem.geometry.type;
    sketchTool[`${geometryType}Symbol`] = selectedItem.symbol;
    handleSelectSketchTool({
      currentTarget: {
        dataset: {
          id: geometryType
        }
      }
    });
  };

  selectNewTool = () => {
    const sketchLayer = this.getSketchLayer();
    if (sketchLayer) {
      sketchLayer.removeAll();
    }
    this.startNewSketch();
    this.onResetSketches();
    this.closeModal();
  };

  closeModal = () => {
    this.setState({
      showModal: false
    });
  };

  getModal = () => {
    const {
      labels: {
        CLEAR_MAP_TITLE_LABEL,
        CLEAR_MAP_BODY_LABEL,
        CLEAR_MAP_CONFIRM_LABEL,
        CANCEL_LABEL
      }
    } = this.props;
    return (
      <Modal
        isOpen={this.getShowModal()}
        title={CLEAR_MAP_TITLE_LABEL}
        body={CLEAR_MAP_BODY_LABEL}
        primaryButtonTitle={CLEAR_MAP_CONFIRM_LABEL}
        primaryButtonAction={this.selectNewTool}
        secondaryButtonTitle={CANCEL_LABEL}
        secondaryButtonAction={this.closeModal}
        onClose={this.closeModal}
      />
    );
  };

  getValidationMessage = () => {
    const { sketches } = this.state;
    const { ADD_FEATURE_LOCATION_LABEL } = this.props.labels;
    return sketches.length ? null : (
      <ValidationMessage>{ADD_FEATURE_LOCATION_LABEL}</ValidationMessage>
    );
  };

  getShowPagination = () => {
    const { showPagination } = this.props;
    return showPagination || false;
  };

  render() {
    return (
      <>
        <PackageHelpDescription>{this.getDescription()}</PackageHelpDescription>
        <FeatureSearch
          updateTextSearch={this.updateSearchText}
          activeTextSearch={this.getSearchText()}
          searchTypes={["text"]}
          onSearchCleared={this.handleSearchCleared}
          selectedSearchType={FEATURE_TEXT_SEARCH}
          showFeatureFilter={true}
          activeFilters={this.getActiveFilters()}
          filterOptions={this.getFilterOptions()}
          handleResetFilter={this.handleResetFilter}
        />
        <FeatureList
          features={this.getPaginatedList()}
          titleField={this.getCreateFields()[0]}
          subtitleFields={this.getSubtitleFields()}
          onSelect={this.handleSelectListItem}
          showFeatureCount={true}
          emphasis={this.getEmphasis()}
          totalListItems={this.getListItems().length}
          totalFeatureCount={this.getListItems().length}
          disableHighlight={true}
          paginationOptions={this.getPaginationOptions()}
          domainValues={this.getDomainValues()}
          listLimit={this.getPageLimit()}
          listPage={this.getPageNumber()}
          changePerPage={this.handleChangePageLimit}
          handlePagination={this.handleChangePageNumber}
          activeFilters={this.getActiveFilters()}
          showPagination={this.getShowPagination()}
        />
        {this.getValidationMessage()}
        {this.getDrawingTool()}
        {this.getModal()}
      </>
    );
  }
}
