import React from "react";
import { TableBodyCell } from "../..";
import CellButton from "./CellButton";
import PropTypes from "prop-types";

const Cell = (props) => {
  const { id, colSpan, rowSpan, verticalAlign, buttons, items } = props;
  return (
    <TableBodyCell
      key={id}
      colSpan={colSpan}
      rowSpan={rowSpan}
      verticalAlign={verticalAlign}
    >
      {items.map((item) => item)}
      {buttons.map((button, i) => (
        <CellButton button={button} cellItem={props} key={`${id}-button${i}`} />
      ))}
    </TableBodyCell>
  );
};

Cell.propTypes = {
  /** The unique id for the cell */
  id: PropTypes.string,
  /** colspan for the cell */
  colSpan: PropTypes.number,
  /** rowspan for the cell */
  rowSpan: PropTypes.number,
  /** vertical alignment of the cell content */
  verticalAlign: PropTypes.string,
  /** Cell values/content */
  items: PropTypes.arrayOf(PropTypes.node),
  /** Any buttons to go at the bottom of the cell */
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      /** Called on click of button */
      onClick: PropTypes.func,
      /** The title string for the button. This is useful if the button doesn't have a label (e.g. an icon only is visible) but still need some sort of accessible description for the button */
      title: PropTypes.string,
      /** The label to display in the button (if any) */
      label: PropTypes.string,
      /** The icon to display in the button (if any) */
      icon: PropTypes.shape({
        type: PropTypes.string,
        /** The css color (rgb, hex, color name) for the background of the icon */
        bgColor: PropTypes.string,
        /** The shape around the icon, e.g. round */
        bgShape: PropTypes.string,
        /** The width, in pixels, of the background */
        bgWidth: PropTypes.string,
        /** The height, in pixels, of the background */
        bgHeight: PropTypes.string,
        /** The height, in pixels, of the icon */
        iconHeight: PropTypes.string,
        /** The width, in pixels, of the icon */
        iconWidth: PropTypes.string,
        /** The css color (rgb, hex, color name) for the icon. Can also be a function that returns a string */
        iconColor: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
        /** If the icon should be spinning or not */
        spinning: PropTypes.bool,
        /** The path to the icon file, if not the standard. If the file does not exist, should default to a generic error icon*/
        path: PropTypes.string,
        /** the rotation number in degrees for the icon */
        rotation: PropTypes.number
      })
    })
  )
};

Cell.defaultProps = {
  verticalAlign: "top",
  items: [],
  buttons: [],
  rowSpan: 1,
  colSpan: 1
};

export default Cell;
