import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  AttachmentContainer,
  AttachmentList,
  AttachmentListHeader,
  AttachmentListItem,
  AttachmentListItemLink,
  DropzoneWrapper,
  DropzoneContainer,
  AttachmentListItemImage,
  AttachmentListItemImageWrap,
  AttachmentListItemText,
  AttachmentListItemButton
} from "../../../plugin/layout";
import { ButtonLabel } from "..";
import { defaultTheme } from "../../..";
import { Icon } from "../../";
import { FeatureDetailsItemTitle } from "../../../plugin/components";
import Dropzone from "react-dropzone";
import { isNullOrUndefined, openInNewTab } from "../../../../App/utils";
import { ATTACHMENTS_HEADING_LABEL } from "../../../../constants";

export default class AttachmentsComponent extends Component {
  static propTypes = {
    /** If the parent component is editing or not */
    isEditing: PropTypes.bool,
    /** List of saved attachments in the feature already. */
    attachments: PropTypes.arrayOf(
      PropTypes.shape({
        /** The URL for the download of the attachment */
        url: PropTypes.string,
        /** The name of the file */
        name: PropTypes.string
      })
    ),
    /** List of the attachments to be saved */
    importFiles: PropTypes.arrayOf(
      PropTypes.shape({
        /** The nsame of the attachment */
        name: PropTypes.string
      })
    ),
    showSelectedFile: PropTypes.bool,
    showDropzone: PropTypes.bool,
    /** Function called when the user clicks on the attachment*/
    handleSelectFile: PropTypes.func,
    /** Function to called when clearing an unsaved attachment */
    handleClearFile: PropTypes.func,
    /** Function called when clearing a saved attachment */
    handleClearExistingFile: PropTypes.func,
    /** Whether to show the 'Add Attachments' heading*/
    showHeading: PropTypes.bool,
    /** The labels for each control */
    labels: PropTypes.shape({
      /** Shows in the button to add a new attachment */
      add: PropTypes.string,
      /** Shows in the button to clear an attachment */
      clear: PropTypes.string,
      /** Shows in the dropzone */
      drop: PropTypes.string
    })
  };
  whenEditing = () => {
    const { isEditing } = this.props;
    return isEditing;
  };

  getAttachments = () => {
    const { attachments } = this.props;
    return attachments ? attachments : [];
  };

  showSelectedFile = () => {
    const { showSelectedFile } = this.props;
    return showSelectedFile === true;
  };

  handleSelectFile = (files) => {
    const { handleSelectFile } = this.props;
    if (!handleSelectFile) return;
    handleSelectFile(
      files.map((file) => {
        if (!this.isImage(file)) return file;
        file.preview = URL.createObjectURL(file);
        return file;
      })
    );
  };

  handleClearFile = (file, i) => {
    const { handleClearFile } = this.props;
    if (!handleClearFile) return;
    handleClearFile(file, i);
  };

  handleClearExistingFile = (file) => {
    const { handleClearExistingFile } = this.props;
    if (!handleClearExistingFile) return;
    handleClearExistingFile(file);
  };

  getImportFiles = () => {
    const { importFiles } = this.props;
    return importFiles ? importFiles : [];
  };

  showHeading = () => {
    const { showHeading } = this.props;
    return !isNullOrUndefined(showHeading) ? showHeading : true;
  };

  showDropzone = () => {
    const { showDropzone } = this.props;
    return showDropzone === true;
  };

  addAttachmentLabel = () => {
    const { add } = this.props.labels;
    return add ? add : "";
  };

  clearAttachmentLabel = () => {
    const { clear } = this.props.labels;
    return clear ? clear : "";
  };

  dropLabel = () => {
    const { drop } = this.props.labels;
    return drop ? drop : "";
  };

  isImage = (attachment) => {
    if (attachment.type) return attachment.type.includes("image");
    const imageTypeRegexp = new RegExp("(.png|.jpg|.jpeg|.svg)", "gi");
    return imageTypeRegexp.test(attachment.name);
  };

  handleSelectedActiveFile = (file) => {
    const { handleSelectedActiveFile } = this.props;
    if (handleSelectedActiveFile) {
      handleSelectedActiveFile(file);
    } else if (file?.url) {
      openInNewTab(file.url);
    }
  };

  render() {
    return (
      <AttachmentContainer>
        {this.getAttachments().length > 0 && (
          <AttachmentList>
            <AttachmentListHeader>
              {ATTACHMENTS_HEADING_LABEL}
            </AttachmentListHeader>
            {this.getAttachments().map((item, i) => (
              <AttachmentListItem key={item.url}>
                <AttachmentListItemLink
                  target="blank"
                  onClick={() => {
                    this.handleSelectedActiveFile(item);
                  }}
                >
                  {this.isImage(item) && (
                    <AttachmentListItemImageWrap>
                      <AttachmentListItemImage src={item.url} alt={item.name} />
                    </AttachmentListItemImageWrap>
                  )}
                  <AttachmentListItemText>{item.name}</AttachmentListItemText>
                </AttachmentListItemLink>
                {this.whenEditing() && (
                  <AttachmentListItemButton
                    data-name={"AttachmentListItemButton"}
                    styletype="close"
                    onClick={() => this.handleClearExistingFile(item, i)}
                  >
                    <Icon
                      data-name={"Icon"}
                      iconColor={defaultTheme.agDarkGray}
                      type="close"
                      bgHeight={"14px"}
                      bgWidth={"14px"}
                      iconHeight={"14px"}
                      iconWidth={"14px"}
                    />
                    <ButtonLabel type="sr-only">
                      {this.clearAttachmentLabel()}
                    </ButtonLabel>
                  </AttachmentListItemButton>
                )}
              </AttachmentListItem>
            ))}
          </AttachmentList>
        )}
        {this.whenEditing() && (
          <div>
            {this.showHeading() && (
              <FeatureDetailsItemTitle>
                {this.addAttachmentLabel()}
              </FeatureDetailsItemTitle>
            )}
            {this.showSelectedFile() && (
              <AttachmentList>
                {this.getImportFiles().map((item, i) => (
                  <AttachmentListItem key={item.name}>
                    {item.preview && (
                      <AttachmentListItemImageWrap>
                        <AttachmentListItemImage
                          src={item.preview}
                          alt={item.name}
                        />
                      </AttachmentListItemImageWrap>
                    )}
                    <AttachmentListItemText>
                      {item.name.replace(/\s/g, "_")}
                    </AttachmentListItemText>
                    <AttachmentListItemButton
                      data-name={"Button"}
                      styletype="close"
                      onClick={() => this.handleClearFile(item, i)}
                    >
                      <Icon
                        data-name={"Icon"}
                        iconColor={defaultTheme.agDarkGray}
                        type="close"
                        bgHeight={"14px"}
                        bgWidth={"14px"}
                        iconHeight={"14px"}
                        iconWidth={"14px"}
                      />
                      <ButtonLabel type="sr-only">
                        {this.clearAttachmentLabel()}
                      </ButtonLabel>
                    </AttachmentListItemButton>
                  </AttachmentListItem>
                ))}
              </AttachmentList>
            )}
            {this.showDropzone() && (
              <Dropzone onDrop={this.handleSelectFile} multiple={false}>
                {({ getRootProps, getInputProps }) => (
                  <DropzoneWrapper data-name={"DropzoneWrapper"}>
                    <DropzoneContainer
                      data-name={"DropzoneContainer"}
                      {...getRootProps()}
                    >
                      <input {...getInputProps()} />
                      <Icon
                        type={"upload"}
                        iconColor={defaultTheme.agDarkestGray}
                        bgShape="round"
                        iconWidth="30px"
                        iconHeight="30px"
                      />

                      <p>{this.dropLabel()}</p>
                    </DropzoneContainer>
                  </DropzoneWrapper>
                )}
              </Dropzone>
            )}
          </div>
        )}
      </AttachmentContainer>
    );
  }
}
