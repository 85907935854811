import React from "react";
import { defaultTheme } from "../../../..";
import { Icon } from "../../../../core";

const RequiredSymbol = (props) => {
  return (
    <Icon
      type="error"
      iconColor={defaultTheme.agErrorRed}
      iconWidth="1em"
      iconHeight="1em"
    />
  );
};

export default RequiredSymbol;
