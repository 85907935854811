import { handleActions } from "redux-actions";
import {
  LOAD_WORKFLOWS,
  LOADED_WORKFLOWS,
  ERROR_WORKFLOWS,
  LOAD_WORKFLOW,
  LOADED_WORKFLOW,
  CLEAR_WORKFLOW,
  ERROR_WORKFLOW,
  SET_WORKFLOW_STATE,
  UPDATE_WORKFLOW_EVENT_HANDLERS,
  SELECT_WORKFLOW,
  CLEAR_FEATURE_LIST_ITEMS,
  SET_WORKFLOW_SIZE,
  PLUGIN_SIZE_REG,
  SET_FEATURE_LIST_ITEMS,
  LOAD_PROPERTY_GROUP_WORKFLOWS,
  LOADED_PROPERTY_GROUP_WORKFLOWS,
  CLEAR_ERRORS,
  SET_WORKFLOW_PANEL_OPEN,
  UNLOAD_PROPERTY,
  UNLOAD_PROPERTY_GROUP,
  UPDATE_PRINT_RESULTS
} from "../constants";

export const defaultState = {
  isLoading: false,
  available: [],
  isSelecting: false,
  selected: false,
  error: false,
  eventHandlers: [],
  command: null,
  featureListItems: [],
  size: PLUGIN_SIZE_REG,
  position: "left",
  panelOpen: true,
  pdfResults: {},
  workflowState: {}
};

// @ts-ignore
export default handleActions(
  {
    [LOAD_WORKFLOWS]: (state) => ({
      ...state,
      isLoading: true,
      available: [],
      command: null,
      error: false
    }),
    [LOAD_PROPERTY_GROUP_WORKFLOWS]: (state) => ({
      ...state,
      isLoading: true,
      available: [],
      command: null,
      error: false
    }),
    [LOADED_WORKFLOWS]: (state, { payload }) => ({
      ...state,
      isLoading: false,
      available: payload
    }),
    [LOADED_PROPERTY_GROUP_WORKFLOWS]: (state, { payload }) => ({
      ...state,
      isLoading: false,
      available: payload
    }),
    [ERROR_WORKFLOWS]: (state, { payload }) => ({
      ...state,
      isLoading: false,
      available: [],
      error: payload
    }),
    [SELECT_WORKFLOW]: (state, { payload }) => ({
      ...state,
      isSelecting: true,
      featureListItems: []
    }),
    [LOAD_WORKFLOW]: (state, { payload }) => ({
      ...state,
      isSelecting: true,
      selected: false,
      error: false,
      command: payload.command
    }),
    [LOADED_WORKFLOW]: (state, { payload }) => ({
      ...state,
      isSelecting: false,
      selected: payload,
      eventHandlers: [],
      position: payload.state.position,
      workflowState: {
        ...state.workflowState,
        [payload.workflowId]: {
          //spread the existing workflow state after the workflow state object so as to keep any existing settings
          ...payload.state,
          ...(state.workflowState[payload.workflowId] || {})
        }
      }
    }),
    [CLEAR_WORKFLOW]: (state) => ({
      ...state,
      isSelecting: false,
      selected: false,
      isLoading: false,
      error: false,
      eventHandlers: [],
      command: null
    }),
    [ERROR_WORKFLOW]: (state, { payload }) => ({
      ...state,
      isSelecting: false,
      selected: false,
      isLoading: false,
      error: payload
    }),
    [SET_WORKFLOW_STATE]: (state, { payload }) => ({
      ...state,
      workflowState: {
        ...state.workflowState,
        [payload.workflowId]: payload.newState
      }
    }),
    [UPDATE_WORKFLOW_EVENT_HANDLERS]: (state, { payload }) => ({
      ...state,
      eventHandlers: payload.eventHandlers
    }),
    [SET_FEATURE_LIST_ITEMS]: (state, { payload }) => ({
      ...state,
      featureListItems: payload.featureListItems
    }),
    [CLEAR_FEATURE_LIST_ITEMS]: (state, { payload }) => ({
      ...state,
      featureListItems: []
    }),
    [SET_WORKFLOW_SIZE]: (state, { payload }) => ({
      ...state,
      size: payload
    }),
    [CLEAR_ERRORS]: (state) => ({
      ...state,
      error: false
    }),
    [SET_WORKFLOW_PANEL_OPEN]: (state, { payload }) => ({
      ...state,
      panelOpen: payload
    }),
    [UNLOAD_PROPERTY]: (state, { payload }) => ({
      ...state,
      available: [],
      propId: payload
    }),
    [UNLOAD_PROPERTY_GROUP]: (state) => ({
      ...state,
      available: []
    }),
    [UPDATE_PRINT_RESULTS]: (state, { payload }) => ({
      ...state,
      pdfResults: {
        ...state.pdfResults,
        [payload.propId]: [
          ...(state.pdfResults[payload.propId] || []),
          payload.pdfResult
        ]
      }
    })
  },
  defaultState
);
