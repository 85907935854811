import React from "react";
import DropDown from "../../../../../core/components/FormComponents/DropDown";
import RequiredSymbol from "../RequiredSymbol";
import PropTypes from "prop-types";

const SelectInput = (props) => {
  function onChange(e) {
    const { handleUpdateValue, attribute } = props;
    handleUpdateValue(attribute, e.target.value);
  }
  return (
    <DropDown
      {...{
        ...props,
        requiredSymbol:
          props.required && !props.isValid ? <RequiredSymbol /> : null,
        onChange,
        options: [
          {
            disabled: true,
            value: "",
            title: props.defaultOptionLabel
          },
          ...props.options
        ],
        id: `${props.attribute}-input`,
        "aria-labelledby": props.attribute,
        "aria-invalid": !props.isValid,
        labelType: !props.isValid ? "formError" : "",
        labelStyle: "form-label"
      }}
    />
  );
};

SelectInput.propTypes = {
  defaultOptionLabel: PropTypes.string,
  /** The label for the select dropdown */
  label: PropTypes.string,
  /** The value of the input */
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool
  ]),
  /** Called on change of value */
  handleUpdateValue: PropTypes.func,
  /** The dropdown value options */
  options: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool
      ]),
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool
      ])
    })
  ),
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  isValid: PropTypes.bool,
  attribute: PropTypes.string
};

export default SelectInput;
