import React, { Component } from "react";
import {
  AccordianWrapper,
  AccordianContainer
} from "../../AgBoxUIKit/core/layout";
import AccordionComponent from "../../AgBoxUIKit/core/components/AccordionComponent/AccordionComponent";

/** An accordion component that can render multiple accordion items */
class Accordion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isListOpen: {}
    };
  }
  componentDidMount() {
    this.setupListOpenState();
  }
  /**
   * Sets up each accordion in state and sets all to closed
   * @public
   */
  setupListOpenState = () => {
    const accordions = this.getAccordions();
    const isListOpen = {};
    accordions.forEach((key) => (isListOpen[key] = false));
    this.setState({ isListOpen });
  };
  /**
   * Returns if the accordion is set to open or closed
   * @param key - the accordion property name
   * @public
   */
  isListOpen = (key) => {
    const { isListOpen } = this.state;
    return isListOpen[key];
  };

  /**
   * Sets the passed accordion to open and all others to false
   * @param key - the accordion property name
   * @public
   */
  toggleList = (key) => {
    const isListOpen = this.isListOpen(key);
    const accordions = this.getAccordions();
    const newIsListOpen = {};
    accordions.forEach((item) => {
      newIsListOpen[item] = item === key ? !isListOpen : false;
    });
    this.setState({
      isListOpen: newIsListOpen
    });
    this.handleOnToggle(newIsListOpen);
  };

  /**
   * Goes through each accordion and calls onToggle if it exists, passing onToggle the open/close value of the accordion as a param.
   *
   * @param isListOpen - object with accordion names and values of whether the list is open or closed
   * @public
   */
  handleOnToggle = (isListOpen) => {
    const accordions = this.getAccordions();
    accordions.forEach((item) => {
      if (
        this.getAccordionDetails(item) &&
        this.getAccordionDetails(item).onToggle
      )
        this.getAccordionDetails(item).onToggle(isListOpen[item]);
    });
  };
  /**
   * Returns the content of the accordion object
   * @param key - the accordion property name
   * @public
   */
  getAccordionDetails = (key) => {
    const { accordions } = this.props;
    const accordionDetails = accordions[key];
    return accordionDetails ? accordionDetails : {};
  };

  getAccordionHeaderLabel = (key) => {
    const accordionDetails = this.getAccordionDetails(key);
    return accordionDetails && accordionDetails.headerLabel
      ? accordionDetails.headerLabel
      : "";
  };
  /**
   * Returns the content of the accordion headerLabel. If not provided, returns an empty string
   * @param key - the accordion property name
   * @public
   */
  getAccordionHeaderContent = (key) => {
    const accordionDetails = this.getAccordionDetails(key);
    const { headerContent } = accordionDetails;
    if (!headerContent || !accordionDetails) return null;
    return headerContent;
  };

  /**
   * Returns the accordion list. If not provided, returns an empty array. If the list is empty, sets the accordion to be closed.
   * @param key - the accordion property name
   * @public
   */
  getAccordionList = (key) => {
    const accordionDetails = this.getAccordionDetails(key);
    if (
      (!accordionDetails ||
        ((!accordionDetails.list || accordionDetails.list.length === 0) &&
          !accordionDetails.bodyContent)) &&
      this.isListOpen(key)
    ) {
      this.toggleList(key);
    }
    return accordionDetails && accordionDetails.list
      ? accordionDetails.list.map((listItem) => {
          listItem.content = this.getAccordionBodyContent(key, listItem);
          listItem.itemKey = key;
          return listItem;
        })
      : [];
  };

  /**
   * Calls handleSelectItem for the accordion if it exists. Passes the item  to the function as a param.
   * @param item - the accordion list item
   * @public
   */
  handleSelectItem = (item) => {
    const { itemKey } = item;
    const accordionDetails = this.getAccordionDetails(itemKey);
    const { handleSelectItem } = accordionDetails;
    if (handleSelectItem) handleSelectItem(item);
  };

  /**
   * Returns the content of the accordion list item if it exists, from the bodyContent function. Passes the item to the bodyContent function as a param.
   * @param key - the accordion property name
   * @param item - the accordion list item
   * @public
   */
  getAccordionBodyContent = (key, item) => {
    const accordionDetails = this.getAccordionDetails(key);
    const { bodyContent } = accordionDetails;
    if (!bodyContent) return null;
    return bodyContent(item);
  };

  /**
   * Returns the accordion list item key if it exists, from the bodyContent function. Passes the item to the bodyContent function as a param. If it does not exist, creates a unique string using the accordion name and the item index.
   * @param accordionKey - the accordion property name
   * @param item - the accordion list item
   * @param i - the index of the list item in the array
   * @public
   */
  getAccordionItemKey = (accordionKey, item, i) => {
    const accordionDetails = this.getAccordionDetails(accordionKey);
    const { key } = accordionDetails;
    if (!key) return `${accordionKey}-accordion-item-${i}`;
    return typeof key === "function" ? key(item, i) : key;
  };

  /**
   * Returns an array of the accordions
   * @public
   */

  getAccordions = () => {
    const { accordions } = this.props;
    return Object.keys(accordions).filter((key) => accordions[key] !== null);
  };
  /**
   * Returns the results of the otherContent function if it exists.
   * @param key - the accordion property name
   * @public
   */

  getOtherContent = (key) => {
    const accordionDetails = this.getAccordionDetails(key);
    if (!accordionDetails.otherContent) return null;
    return accordionDetails.otherContent(key);
  };

  render() {
    return (
      <React.Fragment>
        {this.getAccordions().map((key) => (
          <AccordianWrapper
            data-name={"AccordianWrapper"}
            type={"packageContainer"}
            key={key}
          >
            <AccordianContainer data-name={"AccordianContainer"}>
              <AccordionComponent
                headerLabel={this.getAccordionHeaderLabel(key)}
                headerContent={this.getAccordionHeaderContent(key)}
                list={this.getAccordionList(key)}
                handleSelectItem={this.handleSelectItem}
                accordionName={this.getAccordionHeaderLabel(key)}
              />
            </AccordianContainer>
            {this.getOtherContent(key)}
          </AccordianWrapper>
        ))}
      </React.Fragment>
    );
  }
}

export default Accordion;
