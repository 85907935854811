import {
  LAYER_NAMES,
  DATA_SETS_OBJECTID,
  DEFAULT_UNIT_HECTARES,
  UNIT_HECTARES
} from "../constants";

export const getDefaultSpatialReference = (state) =>
  state.organisations.spatialReference;
export const getSelectedOrg = (state) => state.organisations.selected;
export const getOrgLoading = (state) => state.organisations.isLoading;
export const getAvailableOrgs = (state) => state.organisations.available;
export const getOrgSelecting = (state) => state.organisations.isSelecting;
export const getOrgRoles = (state) => state.organisations.roles;
export const getCustomLayers = (state) => state.organisations.customLayers;
export const getOrgError = (state) => state.organisations.error;
export const getSelectedOrgId = (state) => state.organisations.selected.orgId;
export const getOrganisationCustomLayers = (state) =>
  state.organisations.customLayers;
export const getOrganisationPreferences = (state) => {
  return state.organisations &&
    state.organisations.selected &&
    state.organisations.selected.preferences
    ? state.organisations.selected.preferences
    : false;
};
export const getOrgRenderers = (state) => state.organisations.renderers;
export const getLayerLabels = (state) => state.organisations.layerLabels;
export const getOrgPaginationOptions = (state) =>
  state.organisations.selected &&
  state.organisations.selected.preferences &&
  state.organisations.selected.preferences.paginationOptions
    ? state.organisations.selected.preferences.paginationOptions
    : false;

export const getLayerName = (state, layerName) =>
  LAYER_NAMES[layerName] ? LAYER_NAMES[layerName] : layerName;

export const getObjectIdField = () => DATA_SETS_OBJECTID;

export const getHas3D = (state) => {
  return state.organisations &&
    state.organisations.selected &&
    state.organisations.selected.preferences &&
    state.organisations.selected.preferences.enable3D
    ? state.organisations.selected.preferences.enable3D === true
    : false;
};

export const getOrganisationWorkflowOrder = (state) =>
  state.organisations &&
  state.organisations.selected &&
  state.organisations.selected.preferences &&
  state.organisations.selected.preferences.workflowOrder
    ? state.organisations.selected.preferences.workflowOrder
    : [];
export const getUsePropertyPins = (state) =>
  state.organisations &&
  state.organisations.selected &&
  state.organisations.selected.preferences
    ? state.organisations.selected.preferences.propertyGroupTilingEnabled ===
      true
    : false;

export const getHasAdminAccess = (state, orgId) => {
  if (!state.organisations || !state.organisations.available || !orgId)
    return false;
  const selectedOrg = state.organisations.available.find(
    (org) => org.orgId === orgId
  );
  if (!selectedOrg) return false;
  return selectedOrg.adminAccess;
};
export const getAreaUnit = (state) => {
  const selectedOrg = getSelectedOrg(state);
  if (
    !selectedOrg ||
    !selectedOrg.preferences ||
    !selectedOrg.preferences.units ||
    !selectedOrg.preferences.units.areaUnits ||
    !selectedOrg.preferences.units.areaUnits.length
  )
    return {
      unit: UNIT_HECTARES,
      shortname: DEFAULT_UNIT_HECTARES
    };
  const { areaUnits = [], preferredAreaUnit } = selectedOrg.preferences.units;
  if (preferredAreaUnit) {
    const fullUnit = areaUnits.find(({ unit }) => unit === preferredAreaUnit);
    if (fullUnit) return fullUnit;
  }
  return areaUnits[0];
};
