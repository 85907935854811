import React, { Component } from "react";
import PropTypes from "prop-types";
import NumberInput from "./subcomponents/Inputs/NumberInput";
import PercentageInput from "./subcomponents/Inputs/PercentageInput";
import DateInput from "./subcomponents/Inputs/DateInput";
import RadioSetInput from "./subcomponents/Inputs/RadioSetInput";
import AttachmentsComponent from "../../../core/components/AttachmentsComponent/AttachmentsComponent";
import StringInput from "./subcomponents/Inputs/StringInput";
import InputWrapper from "./subcomponents/InputWrapper";
import NonEditableInput from "./subcomponents/Inputs/NonEditableInput";
import SelectInput from "./subcomponents/Inputs/SelectInput";
import CheckboxInput from "./subcomponents/Inputs/CheckboxInput";
import TimeInput from "./subcomponents/Inputs/TimeInput";
import TextAreaInput from "./subcomponents/Inputs/TextAreaInput";
import CompoundInput from "./subcomponents/Inputs/CompoundInput";
import ColorInput from "./subcomponents/Inputs/ColorInput";

export default class FeatureDetailsComponent extends Component {
  static propTypes = {
    handleUpdateValue: PropTypes.func,
    dateResetLabel: PropTypes.string,
    requiredLabel: PropTypes.string,
    datePlaceholderLabel: PropTypes.string,
    detailsItems: PropTypes.array
  };
  handleUpdateValue = (attribute, value) => {
    const { handleUpdateValue } = this.props;
    if (!handleUpdateValue) return false;
    return handleUpdateValue(attribute, value);
  };
  itemInput = (detailsItem) => {
    if (!detailsItem.isEditable) {
      return detailsItem.type === "color" ? (
        <ColorInput {...{ ...detailsItem, disabled: true }} />
      ) : (
        <NonEditableInput
          {...{ ...detailsItem, useListItem: !this.props.editable }}
        />
      );
    }
    const { dateResetLabel, datePlaceholderLabel, requiredLabel } = this.props;
    const item = {
      ...detailsItem,
      handleUpdateValue: this.handleUpdateValue
    };
    const { type, key } = item;
    item.attribute = key;
    if (type === "date") {
      item.placeholderLabel = datePlaceholderLabel;
      item.resetLabel = dateResetLabel;
    }
    if (type === "single") item.step = 0.1;
    if (type === "double") item.step = 0.01;
    if (type === "integer") item.step = 1;
    let input;
    switch (type) {
      case "single":
      case "double":
      case "integer":
        input = <NumberInput {...item} />;
        break;
      case "percentage":
        input = <PercentageInput {...item} />;
        break;
      case "select":
        input = <SelectInput {...item} />;
        break;
      case "date":
        input = <DateInput {...item} />;
        break;
      case "boolean":
      case "troolean":
        input = <RadioSetInput {...item} />;
        break;
      case "checkbox":
        input = <CheckboxInput {...item} />;
        break;
      case "attachment":
        input = <AttachmentsComponent {...item} />;
        break;
      case "time":
        input = <TimeInput {...item} />;
        break;
      case "color":
        input = <ColorInput {...item} />;
        break;
      case "textArea":
        input = <TextAreaInput {...item} />;
        break;
      case "string":
      default:
        input = <StringInput {...item} />;
        break;
    }
    return (
      <InputWrapper
        {...{
          key,
          helpText: item.helpText,
          requiredLabel,
          required: item.required,
          isValid: item.isValid,
          isHighlighted: item.isHighlighted,
          attribute: item.attribute
        }}
      >
        {input}
      </InputWrapper>
    );
  };

  getDetailsItems = () => {
    const { detailsItems } = this.props;
    return detailsItems ? detailsItems : [];
  };

  getVisualItems = () => {
    const detailsItems = this.getDetailsItems();
    return detailsItems.reduce((result, item) => {
      if (item.isCompound) {
        const key = item.inputs.map((input) => input.key).join("-");
        const inputs = item.inputs.map((input) => this.itemInput(input));
        return [
          ...result,
          <CompoundInput key={key} {...{ ...item, inputs }} />
        ];
      }
      return [...result, this.itemInput(item)];
    }, []);
  };
  render() {
    return <React.Fragment>{this.getVisualItems()}</React.Fragment>;
  }
}
