import React, { Component } from "react";
import BottomButtonBarComponent from "../../AgBoxUIKit/plugin/components/BottomButtonBarComponent/BottomButtonBarComponent";

export default class BottomButtonBar extends Component {
  onClickButton = (e, action, shouldPreventDefault) => {
    if (action) {
      if (shouldPreventDefault) e.preventDefault();
      action();
    }
  };

  getButtons = () => {
    const {
      primaryAction,
      primaryLink,
      secondaryLink,
      primaryDisabled,
      primaryLabel,
      primaryAppearsDisabled,
      primaryPosition,
      secondaryAction,
      secondaryDisabled,
      secondaryLabel,
      secondaryAppearsDisabled,
      secondaryPosition,
      customButtons,
      secondaryType,
      primaryType,
      labels: { DEFAULT_SECONDARY_BUTTON_LABEL, DEFAULT_PRIMARY_BUTTON_LABEL }
    } = this.props;

    const newArray = customButtons
      ? customButtons
          .filter((dataCheck) => {
            return (
              (dataCheck.customAction || dataCheck.link) &&
              dataCheck.customLabel
            );
          })
          .map((buttonData) => {
            return {
              type: buttonData.link
                ? buttonData.customStyleType
                  ? buttonData.customStyleType
                  : "tertiary"
                : buttonData.customType
                ? buttonData.customType
                : "button",
              action: buttonData.customAction,
              label: buttonData.customLabel,
              styletype: buttonData.customStyleType
                ? buttonData.customStyleType
                : "tertiary",
              disabled: buttonData.customDisabled,
              appearsdisabled: buttonData.customAppearsDisabled,
              link: buttonData.link ? buttonData.link : null
            };
          })
      : [];

    if (secondaryAction || secondaryLink) {
      const buttonData = {
        styletype: "tertiary",
        type: secondaryLink
          ? "tertiary"
          : secondaryType
          ? secondaryType
          : "button",
        action: secondaryAction,
        label: secondaryLabel ? secondaryLabel : DEFAULT_SECONDARY_BUTTON_LABEL,
        disabled: secondaryDisabled,
        appearsdisabled: secondaryAppearsDisabled,
        link: secondaryLink
      };

      let secondaryButtonPosition = secondaryPosition ? secondaryPosition : 0;

      if (primaryAction && primaryPosition < secondaryPosition) {
        secondaryButtonPosition =
          secondaryButtonPosition > 0 ? (secondaryButtonPosition -= 1) : 0;
      }

      newArray.splice(secondaryButtonPosition, 0, buttonData);
    }

    if (primaryAction || primaryLink) {
      const buttonData = {
        styletype: "primary",
        type: primaryLink ? "primary" : primaryType ? primaryType : "button",
        action: primaryAction,
        label: primaryLabel ? primaryLabel : DEFAULT_PRIMARY_BUTTON_LABEL,
        disabled: primaryDisabled,
        appearsdisabled: primaryAppearsDisabled,
        form: primaryType === "submit" ? "details-form" : null,
        link: primaryLink
      };

      let primaryButtonPosition = 0;

      primaryButtonPosition = primaryPosition
        ? primaryPosition
        : secondaryPosition === newArray.length && secondaryAction
        ? newArray.length - 1
        : newArray.length;

      newArray.splice(primaryButtonPosition, 0, buttonData);
    }
    return newArray;
  };

  validationMessages = () => {
    const { validationMessage } = this.props;
    if (!validationMessage) return [];
    return Array.isArray(validationMessage)
      ? validationMessage
      : [validationMessage];
  };

  render() {
    return (
      <BottomButtonBarComponent
        validationMessage={this.validationMessages()}
        buttons={this.getButtons()}
        onButtonClick={this.onClickButton}
        type={this.props.type}
      />
    );
  }
}
