import React, { Component } from "react";
import { DropdownWrapper } from "../../AgBoxUIKit/plugin/layout";
import { ContextDropdownComponent } from "../../AgBoxUIKit/plugin";
import { decodeComputedString, isNullOrUndefined } from "../../App/utils";

export default class ContextDropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      activeRow: null
    };
    this.dropdownWrap = React.createRef();
  }

  componentDidMount() {
    document.addEventListener("keydown", this.escapeToClose);
    document.addEventListener("click", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.escapeToClose);
    document.removeEventListener("click", this.handleClickOutside);
  }

  handleClickOutside = (e) => {
    const { isOpen } = this.state;
    if (!isOpen) return;

    //if there is a modal being closed at this point, don't close the menu, because it needs to refocus on that menu item we were last on
    const modal = document.getElementById("agbox-modal");

    this.setState({
      activeRow: null
    });

    if (this.dropdownWrap.current.contains(e.target) || modal) return;
    this.setState({
      isOpen: false
    });
  };

  escapeToClose = (e) => {
    const { isOpen } = this.state;
    if (e.key !== "Escape" || !isOpen) return;
    this.setState({
      isOpen: false,
      activeRow: null
    });
  };

  isActiveRow = (row) => {
    const { activeRow } = this.state;
    return row.active
      ? row.active
      : activeRow
      ? activeRow === row.title
      : false;
  };

  menuItems = () => {
    const { menuItems } = this.props;
    if (!menuItems) return [];
    if (Array.isArray(menuItems)) {
      return menuItems;
    }

    return Object.keys(menuItems)
      .reduce((result, key) => {
        return [
          ...result,
          {
            title: key,
            isTitle: true
          },
          ...menuItems[key]
        ];
      }, [])
      .filter((item) => {
        return !item.hidden;
      })
      .map((row) => ({ ...row, active: this.isActiveRow(row) }));
  };

  handleToggleMenu = () => {
    const { isOpen } = this.state;
    this.setState({ isOpen: !isOpen, activeRow: null });
  };

  isOpen = () => {
    const { isOpen } = this.state;
    return isOpen;
  };

  handleClickMenuItem = (row) => {
    const menuContent = this.menuItems();
    this.setState({
      activeRow:
        row.title === "Reset"
          ? menuContent.filter((item) => !item.isTitle)[0].title
          : row.title,
      isOpen: row.title.toLowerCase().includes("archive") ? true : false
    });
    row.onClick();
  };

  getIconType = () => {
    const { icon } = this.props;
    return icon ? icon : "horizontal-handle";
  };

  allItemsAreDisabled = () => {
    const menuItems = this.menuItems();
    const nonTitleItems = menuItems.filter((item) => !item.isTitle);
    const disabledItems = menuItems.filter(
      (item) => !item.isTitle && item.disabled
    );
    return disabledItems.length === nonTitleItems.length;
  };

  whenLoading = () => {
    const { loading } = this.props;
    return !isNullOrUndefined(loading) ? loading : false;
  };

  buttonIsDisabled = () => {
    const { disabled } = this.props;
    return disabled
      ? disabled
      : this.whenLoading() || this.allItemsAreDisabled();
  };

  getLanguageLabel = (stringConstant, data) => {
    const { labels } = this.props;
    const label = labels[stringConstant];
    if (!label) return stringConstant;
    return decodeComputedString(label, data);
  };

  getMenuLabel = () => {
    const isOpen = this.isOpen();
    return !isOpen
      ? this.getLanguageLabel("OPEN_LABEL", {
          item: this.getLanguageLabel("MENU_LABEL")
        })
      : this.getLanguageLabel("CLOSE_LABEL", {
          item: this.getLanguageLabel("MENU_LABEL")
        });
  };

  showTextCheckbox = () => {
    const { showTextCheckbox } = this.props;
    return showTextCheckbox === true;
  };

  render() {
    return (
      <DropdownWrapper data-name={"DropdownWrapper"} ref={this.dropdownWrap}>
        <ContextDropdownComponent
          handleClickMenuItem={this.handleClickMenuItem}
          menuItems={this.menuItems()}
          menuIconType={this.getIconType()}
          menuButtonDisabled={this.buttonIsDisabled()}
          handleToggleMenu={this.handleToggleMenu}
          isOpen={this.isOpen()}
          menuLabel={this.getMenuLabel()}
          showTextCheckbox={this.showTextCheckbox()}
          onToggleTextCheckbox={this.props.onToggleTextCheckbox}
        />
      </DropdownWrapper>
    );
  }
}
