import {
  ERROR_TYPE_USER,
  ERROR_TYPE_ORG,
  ERROR_TYPE_PROPERTY,
  ERROR_TYPE_WORKFLOWS,
  ERROR_TYPE_PLUGIN,
  ERROR_TYPE_PROPERTY_GROUP,
  BASE_DATA_PROPERTY_LAYER
} from "../constants";
import { getLayerName } from "./organisation";
import { getLabel } from "./language";

export const getActiveWidget = (state) => state.app.activeWidget;
export const getAbortSignal = (state) =>
  state.app.abortController ? state.app.abortController.signal : null;
export const getAbortController = (state) => state.app.abortController;
export const getAppLoading = (state) => state.app.isLoading;
export const getMapContainer = (state) => state.app.mapContainer;
export const getWebmapId = (state) => state.app.webMapId;
export const getMapView = (state) => state.app.mapView;
export const getWebMapLoading = (state) => state.app.webMapLoading;
export const getWebMap = (state) => state.app.webMap;
export const getBasemapId = (state) => state.app.basemapId;
export const getWebMapError = (state) => state.app.webMapError;
export const getSidebarOpen = (state) => state.app.sidebarOpen;
export const getPropertyDropdownOpen = (state) =>
  state.app.propertyDropDownOpen;
export const getProfileDropDownOpen = (state) => state.app.profileDropDownOpen;
export const getAppBusy = (state) => state.app.busy;
export const getIsMapViewReady = (state) => (state.app.mapView ? true : false);
export const getWebMapLayers = (state) => {
  const { webMap } = state.app;
  if (!webMap) return [];
  return webMap.layers.items;
};
export const getPropertyLayers = (state) =>
  state.app.webMap
    ? state.app.webMap.layers.filter(
        (layer) => layer.title === getLayerName(state, BASE_DATA_PROPERTY_LAYER)
      )
    : [];
export const getLoadingText = (state) => state.app.loadingText;
export const getDisableCache = (state) => state.app.disableCache;
export const getAttribution = (state) => state.app.attribution;
export const getRequestInfo = (state) => state.app.requestInfo;

export const getError = (state) => {
  const {
    organisations,
    workflows,
    plugins,
    properties,
    user,
    propertyGroups,
    app
  } = state;
  const { ERRORS_GENERIC_CATCH_LABEL } = getLabel(state);
  let errorMessage = ERRORS_GENERIC_CATCH_LABEL;
  let errorType = null;

  if (user.error) {
    errorMessage = user.error ? user.error : errorMessage;
    errorType = ERROR_TYPE_USER;
  } else if (organisations.error) {
    errorMessage = organisations.error.message
      ? organisations.error.message
      : errorMessage;
    errorType = ERROR_TYPE_ORG;
  } else if (properties.error) {
    errorMessage = properties.error.message
      ? properties.error.message
      : errorMessage;
    errorType = ERROR_TYPE_PROPERTY;
  } else if (workflows.error) {
    errorMessage = workflows.error.message
      ? workflows.error.message
      : errorMessage;
    errorType = ERROR_TYPE_WORKFLOWS;
  } else if (plugins.error) {
    errorMessage = plugins.error.message ? plugins.error.message : errorMessage;
    errorType = ERROR_TYPE_PLUGIN;
  } else if (propertyGroups.error) {
    errorMessage = propertyGroups.error.message
      ? propertyGroups.error.message
      : errorMessage;
    errorType = ERROR_TYPE_PROPERTY_GROUP;
  } else if (app.error) {
    errorMessage = app.error.message;
  }

  return user.error ||
    organisations.error ||
    properties.error ||
    workflows.error ||
    plugins.error ||
    propertyGroups.error ||
    app.error
    ? {
        message: errorMessage,
        type: errorType
      }
    : null;
};
