import React from "react";
import { TableCellButton } from "../../..";
import { Icon } from "../../../../../core";
import { defaultTheme } from "../../../../..";
import PropTypes from "prop-types";

const DeleteCellValueButton = (props) => {
  return (
    <TableCellButton
      title={props.deleteLabel}
      onClick={() => props.onClick(props)}
      type="button"
    >
      <Icon
        type="close"
        iconColor={defaultTheme.agBlue}
        iconWidth={"16px"}
        iconHeight={"16px"}
      />
    </TableCellButton>
  );
};

DeleteCellValueButton.propTypes = {
  /** Called on click of the button */
  onClick: PropTypes.func,
  /** Used for the element title of the button - important for a11y */
  deleteLabel: PropTypes.string
};

export default DeleteCellValueButton;
