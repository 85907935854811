import { Router } from "@reach/router";
import React, { Component, lazy, Suspense } from "react";
import Pack from "../Pack";
import { Loader } from "../AgBoxUIKit/core";
const Sidebar = lazy(() => import("../Components/Sidebar"));

export default class Main extends Component {
  componentDidMount() {
    this.handleSelectOrg();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.orgId !== this.props.orgId && this.props.orgId) {
      this.handleSelectOrg();
    }
  }

  handleSelectOrg = () => {
    const { selectOrganisation, orgId } = this.props;
    selectOrganisation({ orgId });
  };

  showSideBar = () => {
    const {
      selectedWorkflow,
      workflowLoading,
      hasLoadedProperties,
      hasLoadedPropertyGroups
    } = this.props;
    const workflowOpen = /workflow/.test(window.location.href);
    if (
      !workflowOpen ||
      (workflowOpen && selectedWorkflow && !workflowLoading) ||
      (hasLoadedProperties && hasLoadedPropertyGroups)
    ) {
      return (
        <Suspense fallback={<Loader visible={true} />}>
          <Sidebar orgId={this.props.orgId} />
        </Suspense>
      );
    } else {
      return null;
    }
  };

  render() {
    return (
      <>
        {this.showSideBar()}
        <Router primary={false}>
          {["prop/:propId/*", "propertyGroup/:groupId/*"].map((path) => (
            <Pack path={path} key={path} />
          ))}
        </Router>
      </>
    );
  }
}
