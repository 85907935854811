import { createActions } from "redux-actions";
import {
  LOADED_PROPERTIES,
  UPDATED_PROPERTIES,
  ERROR_PROPERTIES,
  LOADED_PROPERTY,
  ERROR_PROPERTY,
  SELECT_PROPERTY,
  UNLOAD_PROPERTY,
  LOAD_PROPERTY,
  SELECT_PROPERTY_HOME,
  UPDATE_PROPERTIES_PAGINATION,
  UPDATE_PROPERTIES,
  LOAD_PROPERTIES,
  UPDATE_SELECTED_PROPERTY_DETAILS,
  UPDATE_AVAILABLE_PROPERTY,
  SET_AVAILABLE_PROPERTIES,
  UPDATE_PROPERTIES_SEARCH,
  UPDATE_PROPERTY_SEARCH_CONTROLLER,
  CLOSE_PROPERTIES_MODAL,
  OPEN_PROPERTY_SELECTOR_DROP_DOWN,
  CLOSE_PROPERTY_SELECTOR_DROP_DOWN,
  SET_SEARCH_PROPERTIES_BY,
  SET_PROPERTY_SEARCH_SUGGESTIONS,
  SET_SELECTED_PROPERTY_SEARCH_SUGGESTION,
  START_SEARCH_BY_LOCATION,
  ERROR_PROPERTIES_SEARCH,
  LOAD_ADDRESS_CANDIDATE,
  SET_ADDRESS_CANDIDATE,
  SET_PROPERTY_GEOMETRY_SEARCH,
  SEARCH_PROPERTIES_BY_NAME,
  SEARCH_PROPERTIES_BY_LOCATION,
  SET_ZOOM_TO_SEARCH_RESULTS,
  ZOOM_TO_SEARCH_RESULTS,
  ZOOM_TO_ORIGINAL_POSITION,
  ADD_LOCATION_SEARCH_MARKER,
  CLEAR_LOCATION_SEARCH_GRAPHICS,
  SET_PROPERTY_SELECTING,
  START_SEARCH_BY_COORDINATES,
  UPDATE_FAVOURITE_PROPERTIES,
  UPDATED_FAVOURITE_PROPERTIES,
  LOAD_PROPERTY_USERS,
  LOADED_PROPERTY_USERS
} from "../constants";

export const {
  loadedProperties,
  updatedProperties,
  errorProperties,
  loadedProperty,
  errorProperty,
  selectProperty,
  unloadProperty,
  loadProperty,
  selectPropertyHome,
  updatePropertiesPagination,
  updateProperties,
  loadProperties,
  updateSelectedPropertyDetails,
  updateAvailableProperty,
  setAvailableProperties,
  updatePropertiesSearch,
  updatePropertySearchController,
  closePropertiesModal,
  openPropertySelectorDropDown,
  closePropertySelectorDropDown,
  setSearchPropertiesBy,
  setPropertySearchSuggestions,
  setSelectedPropertySearchSuggestion,
  startSearchByLocation,
  errorPropertiesSearch,
  loadAddressCandidate,
  setAddressCandidate,
  setPropertyGeometrySearch,
  searchPropertiesByName,
  searchPropertiesByLocation,
  setZoomToSearchResults,
  zoomToSearchResults,
  zoomToOriginalPosition,
  addLocationSearchMarker,
  clearLocationSearchGraphics,
  setPropertySelecting,
  startSearchByCoordinates,
  updateFavouriteProperties,
  updatedFavouriteProperties,
  loadPropertyUsers,
  loadedPropertyUsers
} = createActions({
  [LOADED_PROPERTIES]: (properties, meta) => ({ properties, meta }),
  [UPDATED_PROPERTIES]: (properties, meta) => ({ properties, meta }),
  [ERROR_PROPERTIES]: (error) => error,
  [LOADED_PROPERTY]: (property) => property,
  [ERROR_PROPERTY]: (error) => error,
  [SELECT_PROPERTY]: (orgId, propId) => ({ orgId, propId }),
  [UNLOAD_PROPERTY]: (propId) => ({ propId }),
  [LOAD_PROPERTY]: (orgId, propId) => ({ orgId, propId }),
  [SELECT_PROPERTY_HOME]: () => {},
  [UPDATE_PROPERTIES_PAGINATION]: (offset, limit) => ({ offset, limit }),
  [UPDATE_PROPERTIES]: () => {},
  [LOAD_PROPERTIES]: (orgId) => ({ orgId }),
  [UPDATE_SELECTED_PROPERTY_DETAILS]: (selectedPropertyDetails) =>
    selectedPropertyDetails,
  [UPDATE_AVAILABLE_PROPERTY]: (newPropertyDetails) => ({
    newPropertyDetails
  }),
  [SET_AVAILABLE_PROPERTIES]: (availableProperties) => availableProperties,
  [UPDATE_PROPERTIES_SEARCH]: (search) => ({ search }),
  [UPDATE_PROPERTY_SEARCH_CONTROLLER]: (controller) => ({ controller }),
  [CLOSE_PROPERTIES_MODAL]: () => {},
  [OPEN_PROPERTY_SELECTOR_DROP_DOWN]: () => {},
  [CLOSE_PROPERTY_SELECTOR_DROP_DOWN]: () => {},
  [SET_SEARCH_PROPERTIES_BY]: (searchBy) => searchBy,
  [SET_PROPERTY_SEARCH_SUGGESTIONS]: (suggestions) => suggestions,
  [SET_SELECTED_PROPERTY_SEARCH_SUGGESTION]: (suggestion) => suggestion,
  [START_SEARCH_BY_LOCATION]: () => {},
  [ERROR_PROPERTIES_SEARCH]: (error) => error,
  [LOAD_ADDRESS_CANDIDATE]: () => {},
  [SET_ADDRESS_CANDIDATE]: (addressCandidate) => addressCandidate,
  [SET_PROPERTY_GEOMETRY_SEARCH]: (geometry) => geometry,
  [SEARCH_PROPERTIES_BY_NAME]: () => {},
  [SEARCH_PROPERTIES_BY_LOCATION]: () => {},
  [SET_ZOOM_TO_SEARCH_RESULTS]: (zoomToSearchResults) => zoomToSearchResults,
  [ZOOM_TO_SEARCH_RESULTS]: () => {},
  [ZOOM_TO_ORIGINAL_POSITION]: () => {},
  [ADD_LOCATION_SEARCH_MARKER]: (addressCandidate) => addressCandidate,
  [CLEAR_LOCATION_SEARCH_GRAPHICS]: () => {},
  [SET_PROPERTY_SELECTING]: (selecting) => selecting,
  [START_SEARCH_BY_COORDINATES]: () => {},
  [UPDATE_FAVOURITE_PROPERTIES]: (orgId, updates) => ({ orgId, updates }),
  [UPDATED_FAVOURITE_PROPERTIES]: (orgId, favoriteProperties) => ({
    orgId,
    favoriteProperties
  }),
  [LOAD_PROPERTY_USERS]: () => {},
  [LOADED_PROPERTY_USERS]: (users) => users
});
