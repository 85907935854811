import { navigate } from "@reach/router";
import { createLogic } from "redux-logic";
import {
  LOADED_USER,
  LOAD_USER,
  LOAD_INVITATIONS,
  INVITE_STATUS_INVITED,
  ACCEPT_INVITATION,
  URL_DELIMITER_ORG,
  ACCEPTED_INVITATION,
  LOAD_INVITATION,
  ACCEPT_INVITATION_PUBLIC,
  URL_DELIMITER_PROP,
  LOADED_INVITATIONS,
  MAP_CONTAINER_ID,
  URL_DELIMITER_SELECT_ORG,
  ABORT_ERROR_NAME,
  UPDATE_USER_PREFERENCES
} from "../constants";
import { clearSession, getSession, storeSession } from "../App/utils";
import { NotificationManager } from "react-notifications";

const loadedUserLogic = createLogic({
  type: LOADED_USER,
  process(
    { globalActions, agBoxApiRequests, getState, selectors },
    dispatch,
    done
  ) {
    const { loadMap, loadOrganisations } = globalActions;
    dispatch(loadMap(MAP_CONTAINER_ID, null));
    dispatch(loadOrganisations());
    done();
  }
});

const loadUserLogic = createLogic({
  type: LOAD_USER,
  async process(
    { globalActions, agBoxApiRequests, getState, selectors },
    dispatch,
    done
  ) {
    try {
      const state = getState();
      const { getToken, getUserId, getAbortSignal, getLabel } = selectors;
      const { setLoadingText } = globalActions;
      const { LOADING_USER_LABEL } = getLabel(getState());
      dispatch(setLoadingText(LOADING_USER_LABEL));

      const token = getToken(state);
      const userId = getUserId(state);

      const { requestUser } = agBoxApiRequests;
      const signal = getAbortSignal(getState());

      const sessionUser = getSession(`user-${userId}`);
      const user = sessionUser
        ? sessionUser
        : await requestUser(userId, token, signal);

      const { loadedUser, loadLanguage } = globalActions;
      const { preferences } = user;
      let orgId = null;
      if (window.location.pathname.includes(URL_DELIMITER_ORG)) {
        const substring = window.location.pathname.split(
          `${URL_DELIMITER_ORG}/`
        )[1];
        orgId = substring.split("/")[0];
      }
      let language, languageFile;
      if (preferences && preferences.language) {
        language = preferences.language;
        languageFile = preferences.languageFile;
      } else if (orgId && preferences[orgId] && preferences[orgId].language) {
        language = preferences[orgId].language;
        languageFile = preferences[orgId].languageFile;
      }
      if (language && languageFile) {
        dispatch(loadLanguage(language, languageFile));
      }

      dispatch(loadedUser(user));
      done();
    } catch (e) {
      if (process.env.NODE_ENV === "development") console.log(e);
      const { errorUser } = globalActions;
      dispatch(errorUser(e));
      done();
    }
  }
});

const loadInvitationsLogic = createLogic({
  type: LOAD_INVITATIONS,
  async process(
    { globalActions, agBoxApiRequests, action, selectors, getState },
    dispatch,
    done
  ) {
    try {
      const { userId } = action.payload;
      const { loadedInvitations } = globalActions;
      const { getToken, getAbortSignal } = selectors;
      const token = getToken(getState());
      const signal = getAbortSignal(getState());
      const invitationResults = await agBoxApiRequests.requestUserInvitations(
        userId,
        token,
        signal,
        INVITE_STATUS_INVITED
      );
      dispatch(loadedInvitations(invitationResults));
      done();
    } catch (e) {
      if (!e.name || e.name !== ABORT_ERROR_NAME) {
        if (process.env.NODE_ENV === "development") console.log(e);
        const { errorUser } = globalActions;
        dispatch(errorUser(e));
      }
      done();
    }
  }
});

const loadedInvitationsLogic = createLogic({
  type: LOADED_INVITATIONS,
  process(
    { globalActions, agBoxApiRequests, action, selectors, getState },
    dispatch,
    done
  ) {
    const { items } = action.payload;
    const { acceptInvitation, errorOrganisations } = globalActions;
    const { getAvailableOrgs, getLabel } = selectors;
    const organisations = getAvailableOrgs(getState());
    const nonExpiredOrganisations = organisations
      ? organisations.filter((org) => !org.expired)
      : [];
    const allOrgs = [...nonExpiredOrganisations, ...(items || [])];
    if (!nonExpiredOrganisations.length && items && items.length === 1) {
      const { orgId, inviteId } = items[0];
      dispatch(acceptInvitation(orgId, inviteId));
    } else if (
      (!items || !items.length) &&
      nonExpiredOrganisations.length === 1 &&
      !window.location.pathname.includes(URL_DELIMITER_PROP) &&
      window.location.hash !== URL_DELIMITER_SELECT_ORG
    ) {
      navigate(`/org/${nonExpiredOrganisations[0].orgId}/`, {
        state: {
          docTitle: nonExpiredOrganisations[0].title
        }
      });
    } else if (
      allOrgs.length > 1 &&
      !window.location.pathname.includes(URL_DELIMITER_ORG)
    ) {
      window.location.hash = URL_DELIMITER_SELECT_ORG;
    }
    if (
      (!organisations || !organisations.length) &&
      (!items || !items.length)
    ) {
      const { NO_ORGANISATIONS_ERROR_LABEL } = getLabel(getState());
      dispatch(
        errorOrganisations({
          message: NO_ORGANISATIONS_ERROR_LABEL
        })
      );
    } else if (!nonExpiredOrganisations.length && (!items || !items.length)) {
      const { NO_ACTIVE_ORGANISATIONS_ERROR_LABEL } = getLabel(getState());
      dispatch(
        errorOrganisations({
          message: NO_ACTIVE_ORGANISATIONS_ERROR_LABEL
        })
      );
    }
    done();
  }
});

const acceptInvitationLogic = createLogic({
  type: ACCEPT_INVITATION,
  async process(
    { globalActions, agBoxApiRequests, action, selectors, getState },
    dispatch,
    done
  ) {
    try {
      const { orgId, inviteId } = action.payload;
      const { acceptedInvitation } = globalActions;
      const { getToken, getAbortSignal, getUserId } = selectors;
      const token = getToken(getState());
      const signal = getAbortSignal(getState());
      const userId = getUserId(getState());
      await agBoxApiRequests.acceptUserInvitation(
        orgId,
        inviteId,
        JSON.stringify({}),
        token,
        signal
      );
      clearSession(`user-${userId}-organisations`);
      dispatch(acceptedInvitation(orgId, inviteId));
      done();
    } catch (e) {
      if (process.env.NODE_ENV === "development") console.log(e);
      const { errorUser } = globalActions;
      dispatch(errorUser(e));
      done();
    }
  }
});

const acceptedInvitationLogic = createLogic({
  type: ACCEPTED_INVITATION,
  process(
    { globalActions, agBoxApiRequests, action, selectors, getState },
    dispatch,
    done
  ) {
    const { orgId } = action.payload;
    const { loadOrganisations } = globalActions;
    dispatch(loadOrganisations());
    navigate(`/${URL_DELIMITER_ORG}/${orgId}`);
    done();
  }
});

const loadInvitationLogic = createLogic({
  type: LOAD_INVITATION,
  async process(
    { globalActions, agBoxApiRequests, action, selectors, getState },
    dispatch,
    done
  ) {
    try {
      const { orgId, inviteId, emailAddress, inviteToken } = action.payload;
      const { getAbortSignal } = selectors;
      const { loadedInvitation } = globalActions;
      const signal = getAbortSignal(getState());
      const invitation = await agBoxApiRequests.requestInvitationPublic(
        inviteId,
        orgId,
        inviteToken,
        emailAddress,
        signal
      );
      dispatch(loadedInvitation(invitation));
      done();
    } catch (e) {
      const { errorUser } = globalActions;
      dispatch(errorUser(e));
      done();
    }
  }
});

const acceptInvitationPublicLogic = createLogic({
  type: ACCEPT_INVITATION_PUBLIC,
  async process(
    { globalActions, agBoxApiRequests, action, selectors, getState },
    dispatch,
    done
  ) {
    try {
      const { orgId, inviteId, emailAddress, inviteToken, password } =
        action.payload;
      const { getAbortSignal } = selectors;
      const { acceptedInvitation } = globalActions;
      const signal = getAbortSignal(getState());
      const body = JSON.stringify({
        emailAddress
      });
      await agBoxApiRequests.acceptInvitationPublic(
        inviteId,
        orgId,
        inviteToken,
        body,
        password,
        signal
      );
      dispatch(acceptedInvitation(orgId));
      done();
    } catch (e) {
      const { errorUser } = globalActions;
      dispatch(errorUser(e));
      done();
    }
  }
});

const updateUserPreferencesLogic = createLogic({
  type: UPDATE_USER_PREFERENCES,
  async process(
    { globalActions, agBoxApiRequests, action, selectors, getState },
    dispatch,
    done
  ) {
    try {
      const { userId, preferences } = action.payload;
      const { language, languageFile } = preferences;
      const { getAbortSignal, getToken, getLabel } = selectors;
      const { updatedUserPreferences, loadLanguage } = globalActions;
      const {
        NOTIFICATION_SUCCESS_LABEL,
        USER_PREFERENCES_UPDATED_SUCCESS_LABEL
      } = getLabel(getState());
      const signal = getAbortSignal(getState());
      const token = getToken(getState());
      const body = JSON.stringify({ preferences });
      const userDetails = await agBoxApiRequests.updateUser(
        userId,
        body,
        token,
        signal
      );
      dispatch(updatedUserPreferences(userDetails));
      if (language && languageFile)
        dispatch(loadLanguage(language, languageFile));
      storeSession(`user-${userId}`, userDetails);
      NotificationManager.success(
        USER_PREFERENCES_UPDATED_SUCCESS_LABEL,
        NOTIFICATION_SUCCESS_LABEL
      );
      done();
    } catch (e) {
      const { setGenericError } = globalActions;
      dispatch(setGenericError(e));
      done();
    }
  }
});

export default [
  loadedUserLogic,
  loadUserLogic,
  loadInvitationsLogic,
  acceptInvitationLogic,
  acceptedInvitationLogic,
  loadInvitationLogic,
  acceptInvitationPublicLogic,
  loadedInvitationsLogic,
  updateUserPreferencesLogic
];
