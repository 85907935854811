import React from "react";
import PropTypes from "prop-types";
import { Button } from "../..";
import { defaultTheme } from "../../../..";
import Icon from "../../Icon/Icon";

const SidebarControllerMenuButton = ({ description, onToggle }) => {
  return (
    <Button
      type="button"
      styletype="iconButton"
      data-name={"Button"}
      title={description}
      onClick={onToggle}
    >
      <Icon
        data-name={"Icon"}
        iconColor={defaultTheme.agWhite}
        type={"hamburger"}
        iconHeight={"1.25em"}
        iconWidth={"1.25em"}
        bgShape="square"
      />
    </Button>
  );
};

SidebarControllerMenuButton.propTypes = {
  /** Accesible label for the button */
  description: PropTypes.string,
  /** Called on click of button */
  onToggle: PropTypes.func
};

export default SidebarControllerMenuButton;
