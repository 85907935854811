import { connect } from "react-redux";
import AcceptInvitation from "./AcceptInvitation";
import * as actions from "../actions";
import {
  getAcceptingInvitation,
  getInvitation,
  getLabel,
  getLoadingInvitations,
  getUserError
} from "../selectors";

export default connect(
  (state) => ({
    labels: getLabel(state),
    loadingInvitation: getLoadingInvitations(state),
    invitation: getInvitation(state),
    acceptingInvitation: getAcceptingInvitation(state),
    userError: getUserError(state)
  }),
  actions
)(AcceptInvitation);
