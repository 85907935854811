import React, { Component } from "react";
import {
  PluginToolSelectorWrapper,
  PluginToolSelectorContainer,
  PluginToolBarContainer,
  PluginToolBarHelpTextContainer
} from "../../../plugin/layout";
import { Button } from "..";
import Icon from "../Icon/Icon";
import PropTypes from "prop-types";

/** A reuseable UI component for the drawing tool. Handles rendering of other UI components within the drawing tool */
export default class DrawingToolComponent extends Component {
  static propTypes = {
    /** The tools to render buttons for in the toolbar */
    tools: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        param: PropTypes.string,
        action: PropTypes.func,
        styletype: PropTypes.string
      })
    ),
    /** Whether to show the reset button */
    showResetButton: PropTypes.bool,
    /** Any custom buttons to render */
    customButtons: PropTypes.arrayOf(
      PropTypes.shape({
        customLabel: PropTypes.string,
        customAction: PropTypes.func,
        customDisabled: PropTypes.bool
      })
    ),
    /** Help text for the tools */
    helpTexts: PropTypes.arrayOf(PropTypes.string),
    /** If the reset button is disabled */
    resetDisabled: PropTypes.bool,
    /** The text to render on the reset button */
    resetText: PropTypes.string,
    /** Called on click of reset button */
    handleReset: PropTypes.func,
    /** The heading to render over the tools */
    toolHeading: PropTypes.string,
    /** The text to render on the delete button */
    deleteText: PropTypes.string,
    /** Called on click of delete button */
    handleDelete: PropTypes.func,
    /** Whether to show the delete button */
    showDeleteButton: PropTypes.bool,
    /** Whether to show the 'add another' button */
    showAddButton: PropTypes.bool,
    /** Text to show in the 'add' button */
    addText: PropTypes.string,
    /** Called on click of add button */
    handleAddSketch: PropTypes.func
  };
  getTools = () => {
    const { tools } = this.props;
    return tools ? tools : [];
  };
  showResetButton = () => {
    const { showResetButton } = this.props;
    return showResetButton;
  };
  getCustomButtons = () => {
    const { customButtons } = this.props;
    return customButtons ? customButtons : [];
  };
  getHelpTexts = () => {
    const { helpTexts } = this.props;
    return helpTexts ? helpTexts : [];
  };
  resetDisabled = () => {
    const { resetDisabled } = this.props;
    return resetDisabled;
  };
  getResetText = () => {
    const { resetText } = this.props;
    return resetText;
  };
  handleReset = () => {
    const { handleReset } = this.props;
    if (handleReset) handleReset();
  };
  toolHeading = () => {
    const { toolHeading } = this.props;
    return toolHeading ? toolHeading : "";
  };

  showDeleteButton = () => {
    const { showDeleteButton } = this.props;
    return showDeleteButton;
  };

  handleDelete = () => {
    const { handleDelete } = this.props;
    if (handleDelete) handleDelete();
  };

  getDeleteText = () => {
    const { deleteText } = this.props;
    return deleteText;
  };

  getAddText = () => {
    const { addText } = this.props;
    return addText;
  };

  showAddButton = () => {
    const { showAddButton } = this.props;
    return showAddButton;
  };

  handleAddSketch = () => {
    const { handleAddSketch } = this.props;
    if (!handleAddSketch) return;
    handleAddSketch();
  };
  render() {
    return (
      <PluginToolSelectorWrapper data-name={"PluginToolSelectorWrapper"}>
        <PluginToolSelectorContainer
          data-name={"PluginToolSelectorContainer"}
          showBackground={this.getTools().length > 1}
          hasContent={
            this.getTools().length > 1 ||
            this.showResetButton() ||
            this.showDeleteButton() ||
            this.showAddButton() ||
            this.getCustomButtons().length > 0
              ? true
              : false
          }
        >
          {this.getTools().length > 1 && (
            <PluginToolBarContainer data-name={"PluginToolBarContainer"}>
              {this.getTools().map((buttonItem) => {
                return (
                  <Button
                    data-id={buttonItem.param}
                    key={buttonItem.label}
                    styletype={
                      buttonItem.styletype ? buttonItem.styletype : "tertiary"
                    }
                    onClick={buttonItem.action}
                    type="button"
                  >
                    {buttonItem.label}
                  </Button>
                );
              })}
            </PluginToolBarContainer>
          )}

          {this.showResetButton() && (
            <PluginToolBarContainer data-name={"PluginToolBarContainer"}>
              <Button
                styletype={"tertiary"}
                onClick={this.handleReset}
                disabled={this.resetDisabled()}
                type="button"
              >
                {this.getResetText()}
              </Button>
            </PluginToolBarContainer>
          )}
          {this.showDeleteButton() && (
            <PluginToolBarContainer data-name={"PluginToolBarContainer"}>
              <Button
                styletype={"tertiary"}
                onClick={this.handleDelete}
                type="button"
                data-name={"delete-sketch-button"}
              >
                {this.getDeleteText()}
              </Button>
            </PluginToolBarContainer>
          )}
          {this.showAddButton() && (
            <PluginToolBarContainer data-name={"PluginToolBarContainer"}>
              <Button
                styletype={"tertiary"}
                onClick={this.handleAddSketch}
                type="button"
                data-name={"add-sketch-button"}
              >
                {this.getAddText()}
              </Button>
            </PluginToolBarContainer>
          )}
          {this.getCustomButtons().length > 0 &&
            this.getCustomButtons().map((button) => (
              <PluginToolBarContainer
                data-name={"PluginToolBarContainer"}
                key={button.customLabel}
              >
                <Button
                  styletype={"tertiary"}
                  onClick={button.customAction}
                  disabled={button.customDisabled}
                  type="button"
                >
                  {button.customLabel}
                </Button>
              </PluginToolBarContainer>
            ))}
          {this.getHelpTexts().length > 0 && this.getTools().length > 1 && (
            <PluginToolBarHelpTextContainer
              data-name={"PluginToolBarHelpTextContainer"}
            >
              <div>
                <Icon
                  type="info"
                  bgColor="transparent"
                  bgShape="round"
                  iconWidth="20px"
                  iconHeight="20px"
                />
                <h3>{this.toolHeading()}</h3>
              </div>
              {this.getHelpTexts().map((text, index) => (
                <p key={`${text}-index`}>{text}</p>
              ))}
            </PluginToolBarHelpTextContainer>
          )}
        </PluginToolSelectorContainer>
      </PluginToolSelectorWrapper>
    );
  }
}
