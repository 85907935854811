import { connect } from "react-redux";
import {
  getWebMap,
  getMapView,
  getMapViewHandler,
  getOrgPaginationOptions,
  getLabel,
  getOrganisationPreferences,
  getActiveWidget,
  getLayerName,
  getObjectIdField,
  getHas3D,
  getAreaUnit
} from "../../selectors";
import * as actions from "../../actions";
import FeatureList from "./FeatureList";
import { isNullOrUndefined } from "../../App/utils";

export default connect(
  (state, ownProps) => ({
    webMap: getWebMap(state),
    mapView: getMapView(state),
    featureSearchMapClickHandler: getMapViewHandler(state),
    orgPaginationOptions: getOrgPaginationOptions(state),
    labels: getLabel(state),
    showPaginationDropDown:
      ownProps.showPaginationDropDown === false
        ? false
        : getOrganisationPreferences(state) &&
          isNullOrUndefined(getOrganisationPreferences(state).featureListLimit),
    activeWidget: getActiveWidget(state),
    getLayerName: (layerTitle) => getLayerName(state, layerTitle),
    objectIdField: getObjectIdField(state),
    has3D: getHas3D(state),
    areaUnit: getAreaUnit(state)
  }),
  actions
)(FeatureList);
