import React, { Component } from "react";
import PropTypes from "prop-types";
import { DropdownWrapper } from "../../AgBoxUIKit/plugin/layout";
import {
  DropdownButton,
  DropdownMenuWrap,
  DropdownMenuForm,
  DropdownMenuLegend,
  DropdownMenuListInputs,
  DropdownMenuListInputItem,
  Input,
  Label,
  DropdownMenuAction
} from "../../AgBoxUIKit/core/components";
import { Icon } from "../../AgBoxUIKit/core";
import { defaultTheme } from "../../AgBoxUIKit";
import DropDown from "../../AgBoxUIKit/core/components/FormComponents/DropDown";
import DateRangeFilter from "./DateRangeFilter";

/**
 * This component renders filter options for the user to filter a list by a specified field.
 */
export default class FeatureFilter extends Component {
  static propTypes = {
    /** This is called every time the filter selections are changed, and passes the number of active filters. */
    onChangeFilter: PropTypes.func,
    /** The array of filter options */
    filterOptions: PropTypes.arrayOf(
      PropTypes.shape({
        /** If the option is disabled */
        disabled: PropTypes.bool,
        /** Specifies the sort of button for each filter list item */
        type: PropTypes.string,
        /** This is called when a filter option is clicked, and passes the click event to the function */
        onClick: PropTypes.func,
        /** The visible title of the filter option */
        title: PropTypes.string,
        /** The field to be filtered by */
        field: PropTypes.string,
        /** The value of the field to be filtered by */
        value: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.object,
          PropTypes.bool
        ])
      })
    ),
    /** If this component is rendered inside FeatureList, this func should be passed to the FeatureList to be then passed down to this component. */
    handleResetFilter: PropTypes.func,
    /** The currently selected filters */
    activeFilters: PropTypes.arrayOf(
      PropTypes.shape({
        /** If the option is disabled */
        disabled: PropTypes.bool,
        /** Specifies the sort of button for each filter list item */
        type: PropTypes.string,
        /** This is called when a filter option is clicked, and passes the click event to the function */
        onClick: PropTypes.func,
        /** The visible title of the filter option */
        title: PropTypes.string,
        /** The field to be filtered by */
        field: PropTypes.string,
        /** The value of the field to be filtered by */
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
      })
    )
  };
  constructor(props) {
    super(props);
    this.state = {
      filterOpen: false
    };
    this.filterForm = React.createRef();
    this.filterWrap = React.createRef();
  }

  componentDidMount() {
    document.addEventListener("keydown", this.escapeToClose);
    document.addEventListener("click", this.handleClickOutside);
    const { activeFilters, onChangeFilter } = this.props;
    if (activeFilters && activeFilters.length > 0) {
      onChangeFilter(activeFilters.length);
    }
  }

  handleClickOutside = (e) => {
    const { filterOpen } = this.state;
    if (!filterOpen) return;
    if (
      this.filterWrap.current.contains(e.target) ||
      (typeof e.target.className === "string" &&
        e.target.className.indexOf("react-datepicker") !== -1)
    )
      return;
    this.setState({
      filterOpen: false
    });
  };

  escapeToClose = (e) => {
    const { filterOpen } = this.state;
    if (e.key !== "Escape" || !filterOpen) return;
    this.setState({
      filterOpen: false
    });
  };

  componentWillUnmount() {
    document.removeEventListener("keydown", this.escapeToClose);
    document.removeEventListener("click", this.handleClickOutside);
  }

  toggleFilterDropdown = () => {
    const { filterOpen } = this.state;
    this.setState({
      filterOpen: !filterOpen
    });
  };

  handleSelectFilter = (e) => {
    const { onChangeFilter, activeFilters } = this.props;
    if (!activeFilters) return;
    let newActiveFilter;
    const filterObj = this.getFilterByTitle(e.currentTarget.dataset.title);
    if (e.currentTarget.checked) {
      newActiveFilter = [...activeFilters, filterObj];
    } else {
      newActiveFilter = activeFilters.filter((item) => {
        const matches =
          item.field === filterObj.field && item.value === filterObj.value;
        return !matches;
      });
    }

    if (filterObj.onClick) {
      filterObj.onClick(newActiveFilter, filterObj);
    }

    onChangeFilter(newActiveFilter.length);
  };

  handleResetFilter = () => {
    if (this.props.handleResetFilter) {
      this.props.handleResetFilter();
    }
  };
  isOpen = () => {
    const { filterOpen } = this.state;
    return filterOpen;
  };

  filterMenuItems = () => {
    return this.props.filterOptions ? this.props.filterOptions : [];
  };

  isActiveFilter = (filterObj) => {
    const { activeFilters } = this.props;
    if (!activeFilters || !filterObj) return false;
    const existsInFilter = activeFilters.some((item) => {
      const matches =
        item.field === filterObj.field && item.value === filterObj.value;
      return matches;
    });
    return existsInFilter ? true : false;
  };

  showFilter = () => {
    const enabledFilters = this.filterMenuItems().filter(
      (filter) => !filter.disabled
    );
    const show = enabledFilters.length > 0;
    if (!show && this.isOpen()) {
      this.setState({
        filterOpen: false
      });
    }
    return show;
  };

  shouldRenderFilterBy = () => {
    const { renderFilterBy } = this.state;
    return renderFilterBy === true;
  };

  handleSelectFieldFilterValue = (e) => {
    const { onChangeFilter } = this.props;
    const value = e.currentTarget.value;
    const filterObj = this.getFilterByTitle(e.currentTarget.dataset.id);

    const newActiveFilters = this.updateFiltersWithNewValue(filterObj, value);
    if (filterObj.onClick) filterObj.onClick(newActiveFilters);

    onChangeFilter(newActiveFilters.length);
  };

  updateFiltersWithNewValue = (filter, value) => {
    const { activeFilters } = this.props;

    const newActiveFilters = activeFilters.map((activeFilter) => {
      if (filter.field === activeFilter.field) {
        return {
          ...activeFilter,
          value
        };
      }
      return activeFilter;
    });
    return newActiveFilters;
  };

  getFilterByTitle = (title) => {
    const filters = this.filterMenuItems();
    return filters.find((filter) => filter.title === title);
  };

  updateDateFilter = (value, title) => {
    const { onChangeFilter } = this.props;
    const filter = this.getFilterByTitle(title);
    const newActiveFilters = this.updateFiltersWithNewValue(filter, value);
    if (filter.onClick)
      filter.onClick(newActiveFilters, {
        ...filter,
        value
      });

    onChangeFilter(newActiveFilters.length);
  };

  render() {
    const {
      labels: {
        RESET_FILTER_LABEL,
        FILTER_RESULTS_HEADING_LABEL,
        DEFAULT_OPTION_VALUE_LABEL
      }
    } = this.props;

    return (
      <DropdownWrapper data-name={"DropdownWrapper"} ref={this.filterWrap}>
        {this.showFilter() && (
          <DropdownButton
            data-name={"DropdownButton"}
            type="button"
            styletype="filter"
            title={FILTER_RESULTS_HEADING_LABEL}
            onClick={this.toggleFilterDropdown}
          >
            <Icon
              type="filter"
              iconColor={defaultTheme.agBlue}
              iconWidth="1em"
              iconHeight="1em"
            />
          </DropdownButton>
        )}
        <DropdownMenuWrap
          data-name={"DropdownMenu"}
          type="contextMenu"
          isOpen={this.isOpen()}
          tabindex={1}
          role="dialog"
          aria-labelledby="filter-heading"
        >
          <DropdownMenuForm>
            <DropdownMenuLegend id="filter-heading">
              {FILTER_RESULTS_HEADING_LABEL}
            </DropdownMenuLegend>
            <DropdownMenuListInputs>
              {this.filterMenuItems().map(
                (filter) =>
                  !filter.disabled && (
                    <DropdownMenuListInputItem key={filter.title}>
                      {filter.type === "checkbox" ? (
                        <React.Fragment>
                          <Label
                            htmlFor={filter.title}
                            title={filter.title}
                            appearsdisabled={filter.disabled}
                          >
                            <Input
                              id={filter.title}
                              type="checkbox"
                              checked={
                                filter.checked || this.isActiveFilter(filter)
                              }
                              data-title={filter.title}
                              onChange={this.handleSelectFilter}
                              disabled={filter.disabled}
                            />
                            <span>{filter.title}</span>
                          </Label>
                          {filter.checked && filter.domainValues && (
                            <DropDown
                              options={[
                                {
                                  value: "-",
                                  disabled: true,
                                  title: DEFAULT_OPTION_VALUE_LABEL
                                },
                                ...filter.domainValues
                              ]}
                              id={filter.title}
                              value={filter.value}
                              onChange={this.handleSelectFieldFilterValue}
                            />
                          )}
                          {filter.checked && filter.dateType && (
                            <DateRangeFilter
                              {...filter}
                              updateDateFilter={this.updateDateFilter}
                            />
                          )}
                        </React.Fragment>
                      ) : (
                        <DropdownMenuAction
                          type="button"
                          data-title={filter.title}
                          onClick={this.handleSelectFilter}
                        >
                          {filter.title}
                        </DropdownMenuAction>
                      )}
                    </DropdownMenuListInputItem>
                  )
              )}
            </DropdownMenuListInputs>
            <DropdownMenuAction onClick={this.handleResetFilter} type="button">
              {RESET_FILTER_LABEL}
            </DropdownMenuAction>
          </DropdownMenuForm>
        </DropdownMenuWrap>
      </DropdownWrapper>
    );
  }
}
