import { connect } from "react-redux";
import FeatureSearch from "./FeatureSearch";
import * as actions from "../../actions";
import { getLabel, getLayerName } from "../../selectors";

export default connect(
  (state) => ({
    labels: getLabel(state),
    getLayerName: (layerName) => getLayerName(state, layerName)
  }),
  actions
)(FeatureSearch);
