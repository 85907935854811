import { handleActions } from "redux-actions";
import {
  LOAD_PROPERTIES,
  LOADED_PROPERTIES,
  ERROR_PROPERTIES,
  UNLOAD_PROPERTY,
  LOAD_PROPERTY,
  LOADED_PROPERTY,
  ERROR_PROPERTY,
  UPDATE_PROPERTIES_PAGINATION,
  UPDATED_PROPERTIES,
  UPDATE_PROPERTIES_SEARCH,
  CLOSE_PROPERTIES_MODAL,
  UPDATE_PROPERTY_SEARCH_CONTROLLER,
  LOADED_PROPERTY_GROUP,
  SELECT_PROPERTY,
  CLEAR_ERRORS,
  UPDATE_SELECTED_PROPERTY_DETAILS,
  SET_AVAILABLE_PROPERTIES,
  SELECT_ORGANISATION,
  SET_SEARCH_PROPERTIES_BY,
  SEARCH_BY_NAME,
  SET_PROPERTY_SEARCH_SUGGESTIONS,
  SET_SELECTED_PROPERTY_SEARCH_SUGGESTION,
  LOADED_ORGANISATION,
  START_SEARCH_BY_LOCATION,
  ERROR_PROPERTIES_SEARCH,
  SET_ADDRESS_CANDIDATE,
  LOAD_ADDRESS_CANDIDATE,
  SET_PROPERTY_GEOMETRY_SEARCH,
  SEARCH_PROPERTIES_BY_LOCATION,
  SET_ZOOM_TO_SEARCH_RESULTS,
  SET_PROPERTY_SELECTING,
  LOAD_ORGANISATION,
  UPDATED_FAVOURITE_PROPERTIES,
  LOAD_PROPERTY_USERS,
  LOADED_PROPERTY_USERS
} from "../constants";

export const defaultState = {
  isLoading: false,
  search: false,
  noPropertiesModal: false,
  available: [],
  isSelecting: false,
  selected: false,
  error: false,
  pagination: {
    offset: 0,
    limit: 10,
    total: 0
  },
  controller: null,
  propertyLayerUrl: null,
  searchBy: SEARCH_BY_NAME,
  propertySearchSuggestions: [],
  selectedPropertySearchSuggestion: null,
  searchError: null,
  addressCandidate: null,
  searchGeometry: null,
  zoomToSearchResults: false,
  searchMeta: null,
  propertiesExtent: null,
  favoritedProperties: {}
};

// @ts-ignore
export default handleActions(
  {
    [LOAD_PROPERTIES]: (state, { payload }) => ({
      ...state,
      isLoading: true,
      available: [],
      finishedLoading: false
    }),
    [LOADED_PROPERTIES]: (state, { payload }) => ({
      ...state,
      finishedLoading: true,
      isLoading: false,
      available: payload.properties,
      noPropertiesModal: payload.properties.length > 0 ? false : true,
      propertyLayerUrl: payload.meta.layerUrls[0],
      propertiesExtent: payload.meta.extent,
      pagination: {
        total: payload.meta.total
          ? Number(payload.meta.total)
          : Number(state.pagination.total),
        offset: payload.meta.offset
          ? Number(payload.meta.offset)
          : Number(state.pagination.offset),
        limit: payload.meta.limit
          ? Number(payload.meta.limit)
          : Number(state.pagination.limit)
      }
    }),
    [ERROR_PROPERTIES]: (state, { payload }) => ({
      ...state,
      isLoading: false,
      available: [],
      error: payload
    }),
    [UNLOAD_PROPERTY]: (state, { payload }) => ({
      ...state,
      isSelecting: false,
      selected: false,
      propId: payload
    }),
    [SELECT_PROPERTY]: (state, { payload }) => ({
      ...state,
      isSelecting: true
    }),
    [LOAD_PROPERTY]: (state, { payload }) => ({
      ...state,
      isSelecting: true,
      selected: false
    }),
    [LOADED_PROPERTY]: (state, { payload }) => ({
      ...state,
      isSelecting: false,
      selected: payload
    }),
    [LOADED_PROPERTY_GROUP]: (state, { payload }) => ({
      ...state,
      isSelecting: false,
      selected: false
    }),
    [ERROR_PROPERTY]: (state, { payload }) => ({
      ...state,
      isSelecting: false,
      selected: false,
      error: payload
    }),
    [UPDATE_PROPERTIES_PAGINATION]: (state, { payload }) => ({
      ...state,
      isLoading: true,
      pagination: {
        ...state.pagination,
        ...payload
      }
    }),
    [UPDATED_PROPERTIES]: (state, { payload }) => ({
      ...state,
      isLoading: false,
      available: payload.properties,
      propertyLayerUrl: payload.meta.layerUrls[0],
      pagination: {
        ...state.pagination,
        total: payload.meta.total
          ? Number(payload.meta.total)
          : Number(state.pagination.total),
        offset: payload.meta.offset
          ? Number(payload.meta.offset)
          : Number(state.pagination.offset)
      },
      searchMeta: payload.meta
    }),
    [UPDATE_PROPERTIES_SEARCH]: (state, { payload }) => ({
      ...state,
      search: payload.search,
      pagination: {
        ...state.pagination,
        offset: 0,
        total: 0
      },
      propertySearchSuggestions: [],
      selectedPropertySearchSuggestion: null,
      addressCandidate: null,
      isLoading: true
    }),
    [LOAD_ORGANISATION]: (state) => ({
      ...state,
      search: "",
      pagination: {
        ...state.pagination,
        total: 0,
        offset: 0
      },
      propertySearchSuggestions: [],
      selectedPropertySearchSuggestion: null,
      addressCandidate: null
    }),
    [SELECT_ORGANISATION]: (state) => ({
      ...state,
      search: false,
      searchBy: SEARCH_BY_NAME,
      searchError: null,
      searchGeometry: null
    }),
    [UPDATE_PROPERTY_SEARCH_CONTROLLER]: (state, { payload }) => ({
      ...state,
      controller: payload.controller
    }),
    [CLOSE_PROPERTIES_MODAL]: (state) => ({
      ...state,
      noPropertiesModal: false
    }),
    [CLEAR_ERRORS]: (state) => ({
      ...state,
      error: false
    }),
    [UPDATE_SELECTED_PROPERTY_DETAILS]: (state, { payload }) => ({
      ...state,
      selected: payload
    }),
    [SET_AVAILABLE_PROPERTIES]: (state, { payload }) => ({
      ...state,
      available: payload
    }),
    [SET_SEARCH_PROPERTIES_BY]: (state, { payload }) => ({
      ...state,
      searchBy: payload,
      searchError: null,
      available: [],
      searchGeometry: null,
      pagination: {
        ...state.pagination,
        total: 0,
        offset: 0
      }
    }),
    [SET_PROPERTY_SEARCH_SUGGESTIONS]: (state, { payload }) => ({
      ...state,
      propertySearchSuggestions: payload,
      selectedPropertySearchSuggestion: null,
      isLoading: false,
      searchMeta: null,
      searchGeometry: null,
      pagination: {
        ...state.pagination,
        total: 0,
        offset: 0
      }
    }),
    [SET_SELECTED_PROPERTY_SEARCH_SUGGESTION]: (state, { payload }) => ({
      ...state,
      selectedPropertySearchSuggestion: payload
    }),
    [LOADED_ORGANISATION]: (state) => ({
      ...state,
      searchBy: SEARCH_BY_NAME
    }),
    [START_SEARCH_BY_LOCATION]: (state, { payload }) => ({
      ...state,
      available: [],
      isLoading: true,
      searchError: null
    }),
    [ERROR_PROPERTIES_SEARCH]: (state, { payload }) => ({
      ...state,
      searchError: payload,
      isLoading: false
    }),
    [SET_ADDRESS_CANDIDATE]: (state, { payload }) => ({
      ...state,
      addressCandidate: payload,
      isLoading: false
    }),
    [LOAD_ADDRESS_CANDIDATE]: (state, { payload }) => ({
      ...state,
      isLoading: true
    }),
    [SET_PROPERTY_GEOMETRY_SEARCH]: (state, { payload }) => ({
      ...state,
      searchGeometry: payload
    }),
    [SEARCH_PROPERTIES_BY_LOCATION]: (state, { payload }) => ({
      ...state,
      isLoading: true
    }),
    [SET_ZOOM_TO_SEARCH_RESULTS]: (state, { payload }) => ({
      ...state,
      zoomToSearchResults: payload
    }),
    [SET_PROPERTY_SELECTING]: (state, { payload }) => ({
      ...state,
      isSelecting: payload
    }),
    [UPDATED_FAVOURITE_PROPERTIES]: (state, { payload }) => ({
      ...state,
      favoritedProperties: {
        ...state.favoritedProperties,
        [payload.orgId]: payload.favoriteProperties
      }
    }),
    [LOAD_PROPERTY_USERS]: (state, { payload }) => ({
      ...state,
      isSelecting: false,
      selected: {
        ...state.selected,
        usersLoading: true,
        users: []
      }
    }),
    [LOADED_PROPERTY_USERS]: (state, { payload }) => ({
      ...state,
      isSelecting: false,
      selected: {
        ...state.selected,
        usersLoading: false,
        users: payload
      }
    })
  },
  defaultState
);
