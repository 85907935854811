import React, { Component } from "react";
import { BottomWrapper, BottomContainer } from "../../layout";
import { Button, ButtonLink } from "../../../core/components";
import { ValidationMessage } from "..";
import PropTypes from "prop-types";

export default class BottomButtonBarComponent extends Component {
  static propTypes = {
    buttons: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        type: PropTypes.string,
        disabled: PropTypes.bool,
        styletype: PropTypes.string
      })
    ),
    onButtonClick: PropTypes.func,
    validationMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.array])
  };

  getButtons = () => {
    const { buttons } = this.props;
    return buttons ? buttons : [];
  };

  onButtonClickMethod = (e, action, shouldPreventDefault) => {
    const { onButtonClick } = this.props;
    if (!onButtonClick) return;
    onButtonClick(e, action, shouldPreventDefault);
  };

  getValidationMessages = () => {
    const { validationMessage } = this.props;
    if (!validationMessage) return [];
    return Array.isArray(validationMessage)
      ? validationMessage
      : [validationMessage];
  };

  render() {
    return (
      <BottomWrapper data-name={"BottomWrapper"} type={this.props.type}>
        <BottomContainer data-name={"BottomContainer"} type={this.props.type}>
          {this.getValidationMessages().map((message) => (
            <ValidationMessage key={message}>{message}</ValidationMessage>
          ))}
          {this.getButtons().map((buttonItem) =>
            buttonItem.link ? (
              <ButtonLink
                key={`${buttonItem.label}ButtonItem`}
                styletype={
                  buttonItem.styletype ? buttonItem.styletype : "tertiary"
                }
                type={buttonItem.type ? buttonItem.type : "button"}
                onClick={(e) =>
                  this.onButtonClickMethod(e, buttonItem.action, false)
                }
                disabled={buttonItem.disabled}
                appearsdisabled={buttonItem.appearsdisabled}
                form={buttonItem.form ? buttonItem.form : null}
                to={buttonItem.link.url}
                state={{ docTitle: buttonItem.link.docTitle }}
              >
                {buttonItem.label}
              </ButtonLink>
            ) : (
              <Button
                key={`${buttonItem.label}ButtonItem`}
                styletype={
                  buttonItem.styletype ? buttonItem.styletype : "tertiary"
                }
                type={buttonItem.type ? buttonItem.type : "button"}
                onClick={(e) =>
                  this.onButtonClickMethod(e, buttonItem.action, true)
                }
                disabled={buttonItem.disabled}
                appearsdisabled={buttonItem.appearsdisabled}
                form={buttonItem.form ? buttonItem.form : null}
              >
                {buttonItem.label}
              </Button>
            )
          )}
        </BottomContainer>
      </BottomWrapper>
    );
  }
}
