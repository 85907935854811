import styled from "styled-components/macro";
import { deviceHeight } from "../..";
import { STYLE_TYPE_SINGLE_FORM_ITEM } from "../../../constants";

import {
  List,
  ListItem,
  P,
  Heading2,
  Heading3,
  Heading6,
  Button,
  IconBackground,
  Label,
  Select,
  InputGroup,
  Fieldset,
  Input,
  ButtonLabel,
  IconSymbol,
  TableBodyCell
} from "../../core/components";

import PackageHeading from "./PackageHeading/PackageHeading";
export { PackageHeading };

export const PackageList = styled(List)``;

export const PackageListItem = styled(ListItem)``;

//HELP DESCRIPTION IS EXTENDED FROM THE BASE P STYLED COMPONENT
export const PackageHelpDescription = styled.p`
  font-size: ${(props) => props.theme.fontSizeBase};
  line-height: ${(props) => props.theme.lineHeightSmallest};
  font-family: ${(props) => props.theme.baseFontAlternative};
  padding-bottom: 0;
  margin-bottom: 0;
  color: ${(props) => {
    if (props.type === "error") return props.theme.agRed;
  }};
  font-style: ${(props) => {
    if (props.type === "error") return "italic";
  }};
  @media all and (min-width: 600px) {
    padding-bottom: 1.2vh;
  }
`;
PackageHelpDescription.displayName = "PackageHelpDescription";

export const PackageHeadingStyle = styled(Heading2)`
  color: ${(props) => props.theme.agGreen};
  white-space: normal;
  font-size: ${(props) =>
    props.responsiveFontSize
      ? props.responsiveFontSize
      : props.responsiveText
      ? `${props.theme.fontSizeResponsiveHeading})`
      : props.theme.fontSizeFour};

  overflow-wrap: break-word;
  max-width: 85%;
  margin: 0px 0px 0.7rem;
  font-weight: ${(props) => props.theme.baseFontWeightHeavy};
  line-height: ${(props) => props.theme.lineHeightSmallest};

  @media ${deviceHeight.medium} {
    font-size: ${(props) => props.theme.fontSizeThree};
    font-weight: ${(props) => props.theme.baseFontWeightHeavy};
  }

  + ${PackageHelpDescription} {
    margin-top: 0;
  }
`;
PackageHeadingStyle.displayName = "PackageHeadingStyle";

export const PackageSubHeading = styled(Heading3)`
  padding: 0px;
  font-family: Poppins, sans-serif;
  line-height: 1.25em;
  width: auto;
  font-size: ${(props) => props.theme.fontSizeTwo};
  font-weight: ${(props) => props.theme.baseFontWeightRegular};

  @media (max-height: 800px) {
    font-size: ${(props) => props.theme.fontSizeTwo};
  }
`;
PackageSubHeading.displayName = "PackageSubHeading";

export const PackageListTable = styled.table`
  background-color: ${(props) => props.theme.agWhite};
  border-collapse: collapse;
  thead {
    background-color: ${(props) => props.theme.agLightBlue};
    -webkit-filter: brightness(1.2);
    text-align: left;

    tr {
      th {
        padding: 1.2em;
      }
    }
  }
  tbody {
    tr {
      td {
        text-align: left;
        padding: 1.2em;
      }

      &:hover {
        background-color: ${(props) => props.theme.agOffWhite};
        filter: brightness(0.98);
        cursor: pointer;
      }

      &:nth-child(2n) {
        background-color: ${(props) => props.theme.agOffWhite};

        &:hover {
          filter: brightness(0.95);
        }
      }
    }
  }
`;
PackageListTable.displayName = "PackageListTable";

export const PackageDetailsViewItem = styled.div`
  background-color: ${(props) =>
    props.buttonHolder ? "transparent" : props.theme.agLightestGray};
  filter: brightness(1.03);
  margin-top: 0.5rem;
  padding: 1.2em;

  margin: ${(props) => (props.custom ? 0 : ".5rem 0 0 0")};
  padding: ${(props) => (props.custom ? 0 : "1.2em")};

  p {
    line-height: ${(props) => props.theme.lineHeightSmallest};
    padding: 0.4em 0 0 0;
  }

  &:first-child {
    margin-top: 0;
  }

  & > button {
    width: 100%;
  }
`;
PackageDetailsViewItem.displayName = "PackageDetailsViewItem";

export const PackageDetailsViewHeading = styled(Heading3)`
  margin-bottom: 0;
  color: ${(props) => props.theme.agDarkestGray};
  font-size: ${(props) => props.theme.fontSizeBase};

  & + p {
    margin-top: 0;
    margin-bottom: 0;
  }
`;
PackageDetailsViewHeading.displayName = "PackageDetailsViewHeading";

export const PluginButtonToolbar = styled.div`
  margin-top: 1em;
  margin-bottom: 1em;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: stretch;
`;
PluginButtonToolbar.displayName = "PluginButtonToolbar";

export const ToolbarButton = styled(Button)`
  background-color: ${(props) =>
    props.active ? props.theme.agBlue : props.theme.agWhite};
  color: ${(props) =>
    props.active ? props.theme.agWhite : props.theme.agBlue};
  flex: 1;
`;
ToolbarButton.displayName = "ToolbarButton";

export const FeatureListItemButton = styled.button`
  position: relative;
  width: 100%;
  padding: 0;
  font-size: ${(props) => props.theme.fontSizeBase};
  > * {
    text-align: left;
  }
  :focus {
    outline-color: ${(props) => props.theme.agGreen};
  }
`;
FeatureListItemButton.displayName = "FeatureListItemButton";

export const FeatureListItemContainer = styled.div`
  position: relative;
  width: 100%;
  padding: 0;
  font-size: ${(props) => props.theme.fontSizeBase};
  > * {
    text-align: left;
  }
`;
FeatureListItemContainer.displayName = "FeatureListItemContainer";

export const FeatureListItemContent = styled.div`
  padding: 0.5em 1.2em 0.8em 1.2em;
padding-bottom: ${(props) => {
  return "calc(0.1em + 0.3vw)";
}};
  padding-top: ${(props) => {
  return "calc(0.1em + 0.3vw)";
}};

  font-size: ${(props) => `calc(${props.theme.fontSizeSmaller} + 0.05vw)`};
  display: flex;
  position: relative;
  justify-content: space-between;
  background-color: ${(props) =>
    props.highlight
      ? props.theme.agOffWhite
      : props.selected
      ? props.theme.agLightSelectedPurple
      : "transparent"};
  border: ${(props) =>
    props.selected
      ? `2px solid${props.theme.agDarkSelectedPurple}`
      : "2px solid transparent"};
  filter: ${(props) => (props.highlight ? "brightness(0.98)" : "")};
  cursor: ${(props) => (props.highlight ? "pointer" : "")};
  &:hover {
    background-color: ${(props) =>
      props.hover !== false ? props.theme.agOffWhite : "initial"};
    filter: ${(props) =>
      props.hover !== false ? "brightness(0.98)" : "initial"};
    cursor: ${(props) => (props.hover !== false ? "pointer" : "initial")};
  }

  & + & {
    border-top: ${(props) => `1px solid ${props.theme.agOffWhite}`};
  }
  @media ${deviceHeight.medium} {
    padding-bottom: ${(props) => {
      return "calc(0.1em + 0.3vh)";
    }};
    padding-top: ${(props) => {
  return "calc(0.1em + 0.3vh)";
}};
    font-size: ${(props) => `calc(${props.theme.fontSizeSmaller} - 0.02vw)`};
  }
`;
FeatureListItemContent.displayName = "FeatureListItem";

export const FeatureListEmphasisColourBar = styled.div`
  background-color: ${(props) => props.color};
  width: 100%;
  height: 3px;
`;

export const FeatureListEmphasisColourBarWrap = styled.div`
  background-color: ${(props) => props.color};
  width: 100%;
  position: absolute;
  bottom: -3px;
  left: 0;
  display: flex;
  flex-direction: column;
`;

export const FeatureListItemEmphasisWrap = styled.div`
  display: flex;
  > ${IconBackground} {
    margin-left: 0.5rem;
  }
  > img {
    width: 1.5em;
    height: auto;
  }
`;

export const FeatureListItemImageWrap = styled.div`
  margin-right: 1em;
  margin-top: 0.2em;
  width: 2em;
`;
FeatureListItemImageWrap.displayName = "FeatureListItemImageWrap";

export const FeatureListItemTitleWrap = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
`;
FeatureListItemTitleWrap.displayName = "FeatureListItemTitleWrap";

export const FeatureListItemTitle = styled.p`
  width: 90%;
  word-wrap: break-word;
  margin: 0;
  padding: 0;
  font-weight: ${(props) => props.theme.baseFontWeightHeavy};
  color: ${(props) => {
    if (props.textColor) return props.textColor;
    else return props.theme.agBlack;
  }};
  @media ${deviceHeight.medium} {
    font-size: ${(props) => props.theme.fontSizeSmall};
  }
`;
FeatureListItemTitle.displayName = "FeatureListItemTitle";

export const FeatureListItemSubtitle = styled.span`
  font-size: ${(props) => props.theme.fontSizeSmallest};
  color: ${(props) => {
    if (props.textColor) return props.textColor;
    return props.theme.agBlack;
  }};
  font-weight: ${(props) => {
    if (props.highlightSubtitle) return props.theme.baseFontWeightHeavy;
  }};
`;
FeatureListItemSubtitle.displayName = "FeatureListItemSubtitle";

export const FeatureListCount = styled.p`
  font-size: ${(props) => props.theme.fontSizeSmallest};
  font-style: italic;
  width: 100%;
  margin-bottom: 0.5rem;
`;
FeatureListCount.displayName = "FeatureListCount";

export const FeatureDetailsItemContainer = styled.div`
  margin: 0 0 1em 0;
  padding: 0.5em;
  border-radius: 5px;
  background-color: ${(props) => {
    if (props.isHighlighted) return props.theme.agHighlightedField;
  }};
`;
FeatureDetailsItemContainer.displayName = "FeatureDetailsItemContainer";

export const FeatureDetailsItem = styled.li`
  margin: 0 0 1vh 0;
  list-style: none;
  background-color: ${(props) => {
    if (props.isHighlighted) return props.theme.agHighlightedField;
  }};
  border-radius: 5px;
  input[type="radio"],
  input[type="checkbox"] {
    float: left;
    width: 1.2em;
    height: 1.2em;
    margin-top: 0.12em;
    margin-right: 0.5em;
    margin-left: 0.125em;
    line-height: ${(props) => props.theme.lineHeightSmallest};
    box-shadow: none;
    box-sizing: content-box;
  }
`;
FeatureDetailsItem.displayName = "FeatureDetailsItem";

export const FeatureDetailsFormItem = styled.div`
  margin: 0 0 1em 0;
  background-color: ${(props) => {
    if (props.isHighlighted) return props.theme.agHighlightedField;
  }};
  padding: ${(props) =>
    props.styleType === STYLE_TYPE_SINGLE_FORM_ITEM ? "0" : "0.5em"};
  border-radius: 5px;
  input[type="radio"],
  input[type="checkbox"] {
    float: left;
    width: ${(props) =>
      props.styleType === STYLE_TYPE_SINGLE_FORM_ITEM ? "1.5em" : "1.2em"};
    height: ${(props) =>
      props.styleType === STYLE_TYPE_SINGLE_FORM_ITEM ? "1.5em" : "1.2em"};
    margin-top: 0;
    margin-right: 0.5em;
    margin-left: 0.125em;
    line-height: ${(props) => props.theme.lineHeightSmallest};
    box-shadow: none;
    box-sizing: content-box;
  }
  & ${Fieldset} {
    > ${Label} {
      font-weight: normal;
    }
  }
  ${Select} {
    & ~ ${IconBackground} {
      top: 40%;
    }
  }
`;
FeatureDetailsFormItem.displayName = "FeatureDetailsFormItem";

export const SubFormItemWrapper = styled.div`
  ${FeatureDetailsFormItem} {
    padding: 0.5rem 0 0 0;
  }
`;
SubFormItemWrapper.displayName = "SubFormItemWrapper";

export const FeatureDetailsItemTitle = styled(Heading6)`
  color: ${(props) => props.theme.agBlack}
  margin-bottom: 0.2rem;
  font-weight: ${(props) => props.theme.baseFontWeightHeavy};
`;
FeatureDetailsItemTitle.displayName = "FeatureDetailsItemTitle";

export const FeatureDetailsItemNonEditableTitle = styled(Heading3)`
  color: ${(props) => props.theme.agBlack}
  font-weight: ${(props) => props.theme.baseFontWeightHeavy};
  font-size: ${(props) => props.theme.fontSizeBase};
  line-height: ${(props) => props.theme.lineHeightBase};
`;
FeatureDetailsItemNonEditableTitle.displayName =
  "FeatureDetailsItemNonEditableTitle";

export const FeatureDetailsItemWrap = styled.div``;
FeatureDetailsItemWrap.displayName = "FeatureDetailsItemWrap";

export const FeatureDetailsItemValue = styled(P)`
  white-space: pre-line;
`;
FeatureDetailsItemValue.displayName = "FeatureDetailsItemValue";

export const FeatureDetailsItemInput = styled.div``;
FeatureDetailsItemInput.displayName = "FeatureDetailsItemInput";

export const FeatureDetailsItemInputRequired = styled.span`
  font-size: ${(props) => props.theme.fontSizeSmallest};
  line-height: ${(props) => props.theme.lineHeightBase};
  padding: 0.375rem 0.375rem;
  color: ${(props) => props.theme.agOffBlack};
  background-color: ${(props) => props.theme.agOffRed};
  border: 1px solid ${(props) => props.theme.agLightRed};
  position: relative;
  display: none;
  margin-top: 0.8em;
  display: inline-block;

  &:before {
    content: "";
    position: absolute;
    top: -16px;
    border: 8px solid transparent;
    border-bottom-color: ${(props) => props.theme.agLightRed};
    left: 11px;
  }

  &:after {
    content: "";
    position: absolute;
    top: -15px;
    border: 8px solid transparent;
    border-bottom-color: ${(props) => props.theme.agOffRed};
    left: 11px;
  }
`;
FeatureDetailsItemInputRequired.displayName = "FeatureDetailsItemInputRequired";

export const FeatureDetailsItemInputHelpText = styled.span`
  font-size: ${(props) => props.theme.fontSizeSmallest};
  line-height: ${(props) => props.theme.lineHeightBase};
  padding: 0.375em 0.375em;
  color: ${(props) =>
    props.invalid ? props.theme.agErrorRed : props.theme.agOffBlack};
  position: relative;
  display: none;
  margin-top: 0.5em;
  display: inline-block;
  font-style: italic;
  font-weight: ${(props) =>
    props.invalid ? props.theme.baseFontWeightHeavy : "normal"};
`;
FeatureDetailsItemInputHelpText.displayName = "FeatureDetailsItemInputHelpText";

export const FeatureDetailsInputErrorMessage = styled.span`
  font-size: ${(props) => props.theme.fontSizeBase};
  line-height: ${(props) => props.theme.lineHeightBase};
  padding: 0.375rem 0;
  color: ${(props) => props.theme.agErrorRed};
  position: relative;
  margin-top: 0.5rem;
  display: inline-block;
  font-style: italic;
  font-weight: ${(props) => props.theme.baseFontWeightHeavy};
`;
FeatureDetailsInputErrorMessage.displayName = "FeatureDetailsInputErrorMessage";
export const DatePickerContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 100%;
  min-height: 2, 25em;
  margin: 0.25rem 0 0 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  font-size: ${(props) => props.theme.fontSizeSmall};
  line-height: ${(props) => props.theme.lineHeightSmallest};
  color: ${(props) => props.theme.agOffBlack};
  vertical-align: baseline;
  outline: none;
  outline-offset: 0;
  background-color: ${(props) => props.theme.agWhite};
  border: 1px solid
    ${(props) =>
      props.invalid ? props.theme.agErrorRed : props.theme.agLighterGray};
  border-radius: 0;
  -webkit-transition: border-color 150ms linear;
  transition: border-color 150ms linear;
  box-shadow: inset 0 1px 2px "rgba(0, 0,0 , 0.075)";
  position: relative;
  flex-grow: 999;

  &:focus-within {
    border-color: ${(props) =>
      props.invalid ? props.theme.agRed : props.theme.agDarkBlue};
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075),
      0 0 5px
        ${(props) =>
          props.invalid ? props.theme.agRed : props.theme.agDarkBlue};
  }

  & > div {
    width: 100%;
    position: relative;
  }

  & > div > div {
    width: 100%;
    border-radius: 0;
    border: none;
  }

  & div.react-datepicker__input-container {
    width: 100%;
  }

  & div.react-datepicker__input-container input {
    appearance: none;
    background-color: transparent;
    border: none;
    font-size: ${(props) => props.theme.fontSizeBase};
    width: calc(100% - 1.2rem);
    padding: 0 0.6rem;
    height: 2.25em;
  }
  & div.react-datepicker__input-container input:focus {
    appearance: none;
    background-color: transparent;
    border: none;
    outline: none;
  }

  & div.react-datepicker-popper[data-placement^="bottom"] {
    position: relative !important;
    margin-top: 0 !important;
    flex-grow: 999;
    transform: none !important;
  }

  & + span {
    color: ${(props) =>
      props.invalid ? props.theme.agRed : props.theme.agOffBlack};
  }

  & div.react-datepicker__triangle {
    display: none;
  }

  & div.react-datepicker__month-container {
    float: none;
  }

  & div.react-datepicker__header {
    border-radius: 0;
    border-top: 1px solid ${(props) => props.theme.agLighterGray};
  }

  & div.react-datepicker__day {
    margin: 0.15rem;
  }
  & .react-datepicker__navigation--previous {
    border-right-color: ${(props) => props.theme.agBlue};
    &:hover {
      border-right-color: ${(props) => props.theme.agDarkBlue};
      opacity: 0.8;
    }
  }
  & .react-datepicker__navigation--next {
    border-left-color: ${(props) => props.theme.agBlue};
    &:hover {
      border-left-color: ${(props) => props.theme.agDarkBlue};
      opacity: 0.8;
    }
  }
  & .react-datepicker {
    display: block;
  }
  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    width: 12%;
  }
`;
DatePickerContainer.displayName = "DatePickerContainer";
export const DatePickerWrapper = styled.div`
  &:focus-within {
    ${DatePickerContainer} {
      border-color: ${(props) =>
        props.invalid ? props.theme.agRed : props.theme.agDarkBlue};
      box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075),
        0 0 5px
          ${(props) =>
            props.invalid ? props.theme.agRed : props.theme.agDarkBlue};
    }
  }
`;
DatePickerWrapper.displayName = "DatePickerWrapper";

export const DatePickerIconWrapper = styled.div`
  & ${IconBackground} {
    position: absolute;
    right: 0;
    top: 0;
    pointer-events: none;
    &[type="error"] {
      top: 0;
      right: 0;
    }
  }
  ${Button} {
    position: absolute;
    top: 0;
    right: 1.6rem;
    height: 2.25em;
    align-items: center;

    ${IconBackground} {
      position: static;
    }
    &:hover {
      background-color: transparent;
    }
  }
`;
DatePickerIconWrapper.displayName = "DatePickerIconWrapper";

export const ValidationMessage = styled.p`
  color: ${(props) => props.theme.agRed};
  font-size: ${(props) => props.theme.fontSizeSmall};
  font-weight: ${(props) => props.theme.baseFontWeightHeavy};
  margin-bottom: 0.4em;
  margin-top: 0.2rem;
`;
ValidationMessage.displayName = "ValidationMessage";

export const LocationSearchForm = styled.form`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 1em;
  ${Fieldset} {
    width: 100%;
  }
`;
LocationSearchForm.displayName = "LocationSearchForm";

export const LocationSearchFormGroup = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  ${Input} {
    width: 48%;
  }
  > ${InputGroup} {
    width: 48%;
    margin-top: 0;
  }
`;
LocationSearchFormGroup.displayName = "LocationSearchFormGroup";

export const MaturityMeasurementDisplayContainer = styled.div`
  margin-top: 1rem;
`;
MaturityMeasurementDisplayContainer.displayName =
  "MaturityMeasurementDisplayContainer";

export const MaturityMeasurementDisplayTitle = styled(Heading2)`
  margin-bottom: 1rem;
  font-size: ${(props) => props.theme.fontSizeTwo};
  line-height: ${(props) => props.theme.lineHeightLargest};
`;
MaturityMeasurementDisplayTitle.displayName = "MaturityMeasurementDisplayTitle";

export const MaturityMeasurementDisplayText = styled(P)`
  font-size: ${(props) => props.theme.fontSizeOne};
`;
MaturityMeasurementDisplayText.displayName = "MaturityMeasurementDisplayText";

export const PrintFormItem = styled.div`
  margin-bottom: 0.5rem;
  ${Input} {
    width: 50%;
  }
`;
PrintFormItem.displayName = "PrintFormItem";

export const FlexFormItemWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${FeatureDetailsFormItem} {
    width: 48%;
    padding: 0.5em 0 0 0;
    p {
      margin: 0.5em 0;
    }
  }
`;
FlexFormItemWrap.displayName = "FlexFormItemWrap";

export const LegendWrapper = styled.div`
  padding: 1rem 0 0 0;
  ${FeatureDetailsFormItem} {
    margin-bottom: 0.8rem;
    padding: 0;
    &:last-child {
      margin-bottom: 0;
    }
  }
`;
LegendWrapper.displayName = "LegendWrapper";

export const LegendItemContainer = styled.div`
  display: flex;
  align-items: center;
  height: ${(props) => (props.height ? props.height : "200px")};
`;
LegendItemContainer.displayName = "LegendItemContainer";

export const LegendItemLabel = styled.p`
  font-size: ${(props) => props.theme.fontSizeSmaller};
  margin: 0;
`;
LegendItemLabel.displayName = "LegendItemLabel";

export const LegendGradientContainer = styled.div`
  background-image: ${(props) => `linear-gradient(to top, ${props.gradient})`};
  width: 2rem;
  height: calc(100% - 10px);
  margin-top: 5px;
  border-radius: 5px;
`;
LegendGradientContainer.displayName = "LegendGradientContainer";

export const LegendGradientContainerWrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
`;
LegendGradientContainerWrapper.displayName = "LegendGradientContainerWrapper";

export const LegendGradientContainerBlock = styled.div`
  background-image: ${(props) =>
    `linear-gradient(to bottom, ${props.gradient})`};
  width: 2rem;
  height: 100%;
  border-radius: 5px;
`;
LegendGradientContainerBlock.displayName = "LegendGradientContainerBlock";

export const LegendGradientLabelContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 1rem;
`;
LegendGradientLabelContainer.displayName = "LegendGradientLabelContainer";

export const LegendStepItem = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: ${(props) => (props.centered ? "center" : "flex-start")};
  ${LegendItemLabel} {
    width: ${(props) => (props.centered ? "33%" : "auto")};
    margin-left: ${(props) => (props.centered ? "0" : "1rem")};
    text-align: center;
  }
`;
LegendStepItem.displayName = "LegendStepItem";

export const LegendStepContainer = styled.div`
  width: 100%;
  margin-top: 0.5rem;
  display: flex;
  flex-direction: ${(props) => (props.reverse ? "column-reverse" : "column")};
  ${LegendStepItem} {
    margin-bottom: ${(props) => (props.compressed ? 0 : "0.3rem")};
  }
  ${LegendItemLabel} {
    line-height: ${(props) => (props.compressed ? "1rem" : "initial")};
  }
`;
LegendStepContainer.displayName = "LegendStepContainer";

export const LegendStepColor = styled.div`
  width: 1.5rem;
  height: 1.5rem;
  background-color: ${(props) => props.color};
`;
LegendStepColor.displayName = "LegendStepColor";

export const LegendTitle = styled(Heading2)`
  font-size: ${(props) => props.theme.fontSizeOne};
  line-height: ${(props) => props.theme.lineHeightBase};
  padding-top: 0.5rem;
  margin-bottom: 1rem;
`;
LegendTitle.displayName = "LegendTitle";

export const LegendItemImage = styled.img`
  width: 100%;
  height: auto;
  align-self: flex-start;
`;
LegendItemImage.displayName = "LegendItemImage";

export const LegendItem = styled.div`
  display: flex;
  align-items: center;
  margin-top: 1em;
  ${LegendItemLabel} {
    margin: 0 0 0 1em;
  }
  ${LegendStepColor} {
    width: 1em;
    height: 1em;
  }
`;
LegendItem.displayName = "LegendItem";

export const LegendItemImageWrapper = styled.div`
  width: 1.3em;
  height: 1.3em;
  display: flex;
  align-items: center;
  justify-content: center;
`;
LegendItemImageWrapper.displayName = "LegendItemImageWrapper";

export const FeatureTableWrapper = styled.div`
  width: 100%;
  overflow-x: auto;
`;
FeatureTableWrapper.displayName = "FeatureTableWrapper";

export const FeatureTableContainer = styled.div`
  max-height: ${(props) => (props.scrollable ? "90%" : "100%")};
  overflow-y: auto;
`;
FeatureTableContainer.displayName = "FeatureTableContainer";

export const TableTrashButtonCell = styled(TableBodyCell)`
  vertical-align: middle;
  width: ${(props) => props.width || "auto"};
`;
TableTrashButtonCell.displayName = "TableTrashButtonCell";

export const TableHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  background-color: ${(props) => props.theme.agGreen};
`;
TableHeaderWrapper.displayName = "TableHeaderWrapper";

export const TableHeaderCell = styled.div`
  width: ${(props) => (props.cellWidth ? props.cellWidth : "auto")};
  padding: 0.8em 0.3em;
  flex-grow: ${(props) => (props.cellWidth === "auto" ? 1 : "unset")};
  display: ${(props) => (props.inline ? "inline-block" : "block")};
  ${P} {
    color: white;
  }
  &:first-child {
    padding-left: 0.6rem;
  }
`;
TableHeaderCell.displayName = "TableHeaderCell";

export const TableRow = styled.div`
  width: 100%;
  background-color: ${(props) => props.theme.agLightestGray};
  margin-bottom: 0.3rem;
  display: flex;
  flex-direction: column;
`;
TableRow.displayName = "TableRow";

export const TableRowContentContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
`;
TableRowContentContainer.displayName = "TableRowContentContainer";

export const TableBottomRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  > ${TableHeaderCell} {
    padding-bottom: 0;
    & ${P} {
      color: initial;
    }
  }
`;
TableBottomRow.displayName = "TableBottomRow";

export const TableCell = styled.div`
  width: ${(props) => (props.cellWidth ? props.cellWidth : "auto")};
  padding: 0.8em 0.3em;
  flex-grow: ${(props) => (props.cellWidth === "auto" ? 1 : "unset")};
  display: ${(props) =>
    props.invisible ? "none" : props.inline ? "inline-block" : "block"};
  margin-left: ${(props) => (props.alignRight ? "auto" : "unset")};
  align-self: ${(props) => (props.alignSelf ? props.alignSelf : "unset")};
  &:first-child {
    padding-left: 0.6rem;
  }
`;
TableCell.displayName = "TableCell";

export const TableSelectWrap = styled.div`
  position: relative;
  width: 100%;
  max-width: 500px;
  ${Select} {
    width: 100%;
    padding-right: 30px;
  }
  &:not(:first-child) {
    margin-top: 0.5rem;
  }
`;
TableSelectWrap.displayName = "TableSelectWrap";

export const TableDateWrap = styled.div`
  max-width: 500px;
  ${DatePickerWrapper} {
    max-width: 500px;
    ${IconBackground} {
      top: 0.5rem;
      &[type="error"] {
        top: 0.8rem;
        right: 0.3rem;
      }
    }
  }
  &:not(:first-child) {
    margin-top: 0.5rem;
  }
`;
TableDateWrap.displayName = "TableDateWrap";

export const TableRowErrorContainer = styled.div`
  width: 100%;
  text-align: center;
  padding-bottom: 0.5rem;
  ${P} {
    color: ${(props) => props.theme.agErrorRed};
    font-weight: bold;
  }
`;
TableRowErrorContainer.displayName = "TableRowErrorContainer";

export const TableCellButton = styled(Button)`
  padding: 0;
  height: auto;
  margin-top: 0.5rem;
  font-size: ${(props) => props.theme.fontSizeSmallest};
  &:hover {
    background-color: transparent;
    color: ${(props) => props.theme.agDarkBlue};
    text-decoration: underline;
  }
  &:focus {
    outline: none;
    text-decoration: underline;
  }
`;
TableCellButton.displayName = "TableCellButton";

export const TableTrashButton = styled(Button)`
  margin: 0 auto;
  display: flex;
  align-items: center;
  align-self: center;
  height: auto;
  border: 1px solid transparent;
  ${ButtonLabel} {
    color: ${(props) => props.theme.agBlue};
  }
  &:hover,
  &:focus {
    background-color: transparent;
    border: 1px solid ${(props) => props.theme.agBlue};
  }
`;
TableTrashButton.displayName = "TableTrashButton";

export const TableCellContentWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  ${TableCellButton} {
    padding-left: 0.5rem;
    margin-top: 0;
  }
  ${InputGroup} {
    width: 100%;
  }
  &:not(:first-child) {
    margin-top: 0.5rem;
  }
`;
TableCellContentWrap.displayName = "TableCellContentWrap";

export const TableCellContentContainer = styled.div``;
TableCellContentContainer.displayName = "TableCellContentContainer";

export const NonEditableCellContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
`;
NonEditableCellContentContainer.displayName = "NonEditableCellContentContainer";

export const TableViewButtonWrap = styled.div`
  margin-top: 1rem;
  ${ButtonLabel} {
    color: ${(props) => props.theme.agDarkBlue};
    font-weight: normal;
    padding-top: 0.2rem;
  }
  ${Button} {
    align-items: center;
    padding-right: 1rem;
    border: 1px solid transparent;
    &:hover {
      background-color: transparent;
      border-color: ${(props) => props.theme.agDarkBlue};
    }
    &:focus {
      outline-color: ${(props) => props.theme.agDarkBlue};
    }
  }
`;
TableViewButtonWrap.displayName = "TableViewButtonWrap";

export const TableViewButtonBar = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 1em;
  ${Button} {
    &:last-child {
      margin-left: 1rem;
    }
  }
  @media (max-width: 640px) {
    flex-direction: column;
    ${Button} {
      margin-bottom: 1rem;
      &:last-child {
        margin-left: 0;
      }
    }
  }
  @media (min-width: 640px) {
    > div {
      display: flex;
      margin-left: 1rem;
    }
  }
`;
TableViewButtonBar.displayName = "TableViewButtonBar";

export const TableNoDataMessage = styled(P)`
  text-align: center;
  margin-top: 1rem;
`;
TableNoDataMessage.displayName = "TableNoDataMessage";

export const ModalHeaderFlexContainer = styled.div`
  display: flex;
  align-items: center;
  ${Heading2} {
    color: ${(props) => props.theme.agRed};
    font-weight: bold;
    margin-left: 1rem;
  }
`;
ModalHeaderFlexContainer.displayName = "ModalHeaderFlexContainer";

export const SprayModalContentWrap = styled.div`
  margin-top: 1rem;
  ${FeatureDetailsFormItem} {
    margin-top: 1rem;
  }
  ${InputGroup} {
    margin-top: 1rem;
  }
`;
ModalHeaderFlexContainer.displayName = "SprayModalContentWrap";

export const ViewContentContainer = styled.div`
  margin-top: 1rem;
  ${Heading2} {
    margin-bottom: 1rem;
    font-size: ${(props) => props.theme.fontSizeTwo};
  }
`;
ViewContentContainer.displayName = "ViewContentContainer";

export const PanelControlButtonWrap = styled.div`
  position: ${(props) => (props.isOpen ? "absolute" : "fixed")};
  top: calc(50% - 40px);
  right: ${(props) => (props.isOpen ? "0" : "calc(100% - 5em)")};
  background-color: ${(props) => props.theme.agWhite};
  border: ${(props) => `1px solid ${props.theme.agBlue}`};
  border-radius: 50%;
  z-index: 9000;
  transition: 0.2s position ease;
  ${Button} {
    &:before {
      content: "";
      border: none;
      border-radius: 100%;
      position: absolute;
      width: 83%;
      height: 83%;
      top: 7%;
      left: 7%;
    }
    &:hover {
      border-radius: 50%;
      ${IconSymbol} {
        background-color: ${(props) => props.theme.agWhite};
      }
    }
    &:focus {
      outline: none;
      &:before {
        border: 1px solid white;
      }
    }
  }
  @media all and (min-width: 500px) {
    right: ${(props) => {
      if (props.isOpen) return props.isFullWidth ? "1em" : "-1em";
    }};
  }
`;
PanelControlButtonWrap.displayName = "PanelControlButtonWrap";

export const FeatureListItemWrap = styled.li`
  border: ${(props) => `1px solid ${props.theme.agLightestGray}`};
  margin-bottom: 0.3rem;
  &  +  &  {
    margin-top: 0.3rem;
  }
  &:nth-child(2n)  {
    background-color: ${(props) => props.theme.agOffWhite};
    div  +  div  {
      border-top: ${(props) => `1px solid ${props.theme.agLighterGray}`};
    }
    &:hover  {
      filter: brightness(0.95);
    }
  }
`;
FeatureListItemWrap.displayName = "FeatureListItemWrap";

export const FeatureCreatePickerWrap = styled.div`
  + ${FeatureDetailsFormItem} {
    padding-top: 1rem;
  }
`;
FeatureCreatePickerWrap.displayName = "FeatureCreatePickerWrap";

export const FeatureInfoContainer = styled.div`
  background-color: ${(props) => props.theme.agLighterGray};
  padding: 0.5rem;
  margin-top: 1rem;
  > ${P} {
    margin: 0;
    font-size: ${(props) => props.theme.fontSizeSmallest};
    line-height: ${(props) => props.theme.lineHeightSmallest};
  }
`;
FeatureInfoContainer.displayName = "FeatureInfoContainer";

export const WindWidgetWrap = styled(Fieldset)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 2em 0;
  overflow: hidden;
`;
WindWidgetWrap.displayName = "WindWidgetWrap";

export const WindWidgetContainer = styled.div`
  border-radius: 50%;
  width: 12em;
  height: 12em;
  position: relative;
  overflow: hidden;
  margin: 0 auto;
  transform: ${(props) => `rotate(${props.rotation}deg)`};
`;
WindWidgetContainer.displayName = "WindWidgetContainer";

export const WindWidgetIconWrap = styled.div`
  position: absolute;
  width: 50%;
  height: 50%;
  top: 25%;
  left: 25%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.agLightestGray};
  border-radius: 50%;
`;
WindWidgetIconWrap.displayName = "WindWidgetIconWrap";

export const WindWidgetSegmentTextWrap = styled.div`
  height: 90%;
  width: 100%;
  position: absolute;
  z-index: 900000;
  left: -0.4em;
  top: 0.8em;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  color: ${(props) => props.theme.agBlack};
`;
WindWidgetSegmentTextWrap.displayName = "WindWidgetSegmentTextWrap";

export const WindWidgetSegment = styled.label`
  transform: ${(props) => `rotate(${props.rotation}deg) skewy(-45deg)`};
  padding: 0;
  height: 6em;
  width: 4.2em;
  display: block;
  position: absolute;
  transform-origin: 0 6em;
  left: 6em;
  top: 0;
  overflow: hidden;
  background-color: ${(props) =>
    props.selected ? props.theme.agGreen : props.theme.agLightGray};
  &:hover,
  &:focus {
    outline: none;
  }
  &:nth-child(2n) {
    background-color: ${(props) =>
      props.selected ? props.theme.agGreen : props.theme.agWhite};
  }
  input {
    position: absolute;
    top: 30%;
    margin: 0;
    opacity: 0;
  }
  ${WindWidgetSegmentTextWrap} {
    transform: ${(props) => `skewy(45deg) rotate(${360 - props.rotation}deg)`};
  }
`;

WindWidgetSegment.displayName = "WindWidgetSegment";

export const PresetTableRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
  ${Fieldset} {
    display: flex;
    width: 40%;
    justify-content: space-between;
    &:nth-child(2) {
      justify-content: space-around;
    }
    ${InputGroup} {
      width: 25%;
      align-items: center;
    }
    + ${P} {
      margin-left: 3rem;
    }
  }
  ${P} {
    width: 30%;
    word-break: break-word;
  }
`;
PresetTableRow.displayName = "PresetTableRow";

export const PresetTableLegendWrap = styled.div`
  display: flex;
  justify-content: center;
  width: 30%;
`;
PresetTableLegendWrap.displayName = "PresetTableLegendWrap";

export const PresetTableHeadingItemWrap = styled.div`
  display: flex;
  justify-content: space-between;
  &:first-child {
    width: 30%;
  }
  &:nth-child(2) {
    width: 40%;
    justify-content: space-around;
  }
  &:nth-child(3) {
    width: 30%;
    justify-content: center;
  }
  &:only-child {
    justify-content: space-between;
  }
  ${Label} {
    font-size: ${(props) => props.theme.fontSizeSmaller};
    text-align: center;
  }
`;
PresetTableHeadingItemWrap.displayName = "PresetTableHeadingItemWrap";

export const PresetTableWrap = styled.div`
  width: 100%;
  margin-top: 1rem;
`;
PresetTableWrap.displayName = "PresetTableWrap";

export const HalfSizeContainer = styled.div`
  width: 50%;
`;
HalfSizeContainer.displayName = "HalfSizeContainer";

export const FlexContainer = styled.div`
  display: flex;
  justify-content: ${(props) =>
    props.justify ? props.justify : "space-between"};
  align-items: ${(props) => {
    if (props.align === "bottom") {
      return "flex-end";
    }
    if (props.align === "top") {
      return "flex-start";
    }
    return "center";
  }};
`;
FlexContainer.displayName = "FlexContainer";

export const CompoundInputContentWrapper = styled.div`
  display: flex;
`;
CompoundInputContentWrapper.displayName = "CompoundInputContentWrapper";

export const CompoundInputFieldWrapper = styled.div`
  width: 50%;
  ${Input} {
    margin-top: 0;
  }
  ${FeatureDetailsFormItem}, ${FeatureDetailsItem} {
    &:first-child {
      margin: 0;
      padding-right: 0;
    }
    &:last-child {
      padding-left: 0;
    }
  }
`;
CompoundInputFieldWrapper.displayName = "CompoundInputFieldWrapper";

export const CompoundInputContainer = styled.div`
  padding: 0.5rem;
  margin: 0 0 1.5rem 0;
  legend {
    padding: 0;
  }
`;
CompoundInputContainer.displayName = "CompoundInputContainer";

export const FormItemWrapper = styled.div`
  display: flex;
  align-items: flex-end;
`;
FormItemWrapper.displayName = "FormItemWrapper";

export const FormItemFieldContainer = styled.div`
  width: 100%;
  ${FeatureDetailsItem} & {
    width: auto;
  }
`;
FormItemFieldContainer.displayName = "FormItemFieldContainer";

export const FormItemUnitContainer = styled.div`
  padding-bottom: 0.3rem;
  padding-left: 0.5rem;
  ${FeatureDetailsItem} & {
    padding: 0;
  }
`;
FormItemUnitContainer.displayName = "FormItemUnitContainer";

export const ModalFormWrapper = styled.div`
  ${FeatureDetailsFormItem} {
    padding: 0;
    margin: 0.5rem 0;
    ${FormItemFieldContainer} {
      width: auto;
    }
  }
`;
ModalFormWrapper.displayName = "ModalFormWrapper";

export const PackageSubHeadingLarge = styled(Heading2)`
  line-height: ${(props) => props.theme.lineHeightSmallest};
  color: ${(props) => props.theme.agGreen};
  font-weight: ${(props) => props.theme.baseFontWeightHeavy};
  margin-bottom: 0.9rem;
  word-wrap: break-word;
  text-align: ${(props) => (props.centered ? "center" : "left")};
  width: ${(props) => (props.centered ? "100%" : "auto")};
  font-size: ${(props) => {
    const {
      theme: { aspect, landscape, fontSizeFour, fontSizeThree }
    } = props;
    if (aspect === landscape) {
      return fontSizeFour;
    } else {
      return fontSizeThree;
    }
  }};
  @media ${deviceHeight.medium} {
    font-weight: ${(props) => props.theme.baseFontWeightHeavy};
  }
`;
PackageSubHeadingLarge.displayName = "PackageSubHeadingLarge";

export const ScreenReaderText = styled.span`
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
`;
ScreenReaderText.displayName = "ScreenReaderText";

export const ContentContainer = styled.div``;
ContentContainer.displayName = "ContentContainer";

export const ContentText = styled.p`
  font-size: ${(props) => {
    if (props.size === "XL") return props.theme.fontSizeSix;
    else if (props.size === "L") return props.theme.fontSizeFour;
    else if (props.size === "M") return props.theme.fontSizeTwo;
    else if (props.theme === "S") return props.theme.fontSizeSmall;
    else return props.theme.fontSizeSmallest;
  }};
  font-weight: ${(props) => props.fontWeight};
  color: ${(props) => props.fontColor};
  text-align: center;
  line-height: ${(props) => props.theme.lineHeightSmaller};
  @media screen and (min-width: 800px) {
    font-size: ${(props) => {
      const {
        theme: { aspect, landscape }
      } = props;
      if (aspect === landscape) {
        if (props.size === "XL") return "calc(2em + 2vw)";
        else if (props.size === "L") return "calc(2em + 1vw)";
        else if (props.size === "M") return "calc(1em + 1vw)";
        else if (props.theme === "S") return "calc(1em + 0.5vw)";
        else return props.theme.fontSizeSmall;
      } else {
        if (props.size === "XL") return "calc(2em + 1vh)";
        else if (props.size === "L") return "calc(2em + 0.5vh)";
        else if (props.size === "M") return "calc(1em + 1vh)";
        else if (props.theme === "S") return "calc(1em + 0.5vh)";
        else return props.theme.fontSizeSmall;
      }
    }};
  }
  margin: 0.5rem 0;
`;
ContentText.displayName = "ContentText";

export const FeatureDetailsFormWrapper = styled.div`
  ${FeatureDetailsFormItem} {
    margin: 0 0 0.1rem 0;
    padding: 0.5rem 0rem;
  }
`;
FeatureDetailsFormWrapper.displayName = "FeatureDetailsFormWrapper";

export const AccordionWrapper = styled.div`
  padding: 1rem 0 0 0;
  form {
    margin-top: 0.25rem;
    margin-bottom: 0.825rem;
    padding: 0;
    &:last-child {
      margin-bottom: 0;
    }
  }
  ${ButtonLabel} {
    margin-left: 0.5rem;
    padding: 0;
    font-weight: bold;
    &:last-child {
      margin-bottom: 0;
    }
  }
  ${IconSymbol} {
    background-color: ${(props) => props.iconColor};
  }
`;
AccordionWrapper.displayName = "AccordionWrapper";

export const AccordionCollapsed = styled.label`
  background-color: ${(props) => props.theme.agOffWhite};
  border: 1px solid ${(props) => props.theme.agLightestGray};
  margin-top: 0.25rem;
  display: flex;
  align-items: center;
  height: auto;
  & + & {
    margin-top: 1rem;
  }
  label {
    display: flex;
    align-items: center;
  }
  span {
    color: ${(props) => props.theme.agOffBlack};
    padding: ${(props) =>
      props.styletype === "checklist-item" ? "0 0.4rem" : "0.5rem 0.4rem"};
    font-weight: bold;
    display: flex;
    flex: 1;
    align-items: center;
    &:hover {
      background-color: ${(props) =>
        props.styletype === "checklist-item" ? props.theme.agLightestGray : ""};
      cursor: ${(props) =>
        props.styletype === "checklist-item" ? "pointer" : ""};
    }
  }
`;
AccordionCollapsed.displayName = "AccordionCollapsed";

export const SingleInputWrapper = styled.div`
  margin: ${(props) =>
    props.styletype === "accordion-header-checkbox" ? 0 : "0 0 1em 0"};
  fieldset legend {
    padding: ${(props) =>
      props.styletype === "accordion-header-checkbox" ? "0" : ""};
  }
  fieldset label {
    padding: ${(props) =>
      props.styletype === "accordion-header-checkbox" ? "0.5em" : ""};
  }
  input[type="checkbox"] {
    margin-right: ${(props) =>
      props.styletype === "accordion-header-checkbox" ? "0" : ""};
  }
  &:hover {
    background-color: ${(props) =>
      props.styletype === "accordion-header-checkbox"
        ? props.theme.agLightestGray
        : "transparent"};
    cursor: pointer;
  }
`;
SingleInputWrapper.displayName = "SingleInputWrapper";
