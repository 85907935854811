import { createStore, applyMiddleware } from "redux";
import { createLogicMiddleware } from "redux-logic";
import shortid from "shortid";
import rootReducer from "./reducers";
import * as globalActions from "./actions";
import * as selectors from "./selectors";
import { AGBOX_API_URL, AGBOX_API_KEY } from "./constants";
import AgBoxApiRequests from "./apis/agbox/requests";
import logic from "./logic";
const agBoxApiRequests = AgBoxApiRequests(AGBOX_API_URL, AGBOX_API_KEY);

const logicDependencies = {
  agBoxApiRequests,
  AgBoxApiRequests,
  selectors,
  globalActions
};

// Create the logic as middleware
const logicMiddleware = createLogicMiddleware(logic, logicDependencies);

// Create middleware for adding workflow event handler
const addWorkflowEventHandlerMiddleware = () => (next) => (action) => {
  if (action.type === "ADD_WORKFLOW_EVENT_HANDLER") {
    const { handle, method } = action.payload;
    action.payload = {
      id: shortid.generate(),
      handle,
      method
    };
  }
  return next(action);
};

export default function configureStore() {
  const store = createStore(
    rootReducer,
    applyMiddleware(logicMiddleware, addWorkflowEventHandlerMiddleware)
  );
  return store;
}
