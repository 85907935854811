import React from "react";
import PropTypes from "prop-types";
import { ButtonLabel, ButtonLink, Heading2, List, ListItem, P } from "../..";
import Icon from "../../Icon/Icon";
import { defaultTheme } from "../../../..";
import { SideBarListWrapper, SideBarListContainer } from "../../../layout";
import Loader from "../../Loader/Loader";

const SidebarList = ({
  items = [],
  type,
  title,
  loading,
  loaderSize,
  pagination = null,
  sidebarOpen
}) => {
  return (
    <SideBarListWrapper type={type} sidebarOpen={sidebarOpen}>
      {loading ? (
        <Loader visible={true} size={loaderSize} />
      ) : (
        <SideBarListContainer type={type}>
          {title && <Heading2 type="list-heading">{title}</Heading2>}
          <List data-name={"List"} type={type}>
            {items.map((item) => (
              <ListItem data-name={"ListItem"} key={item.id} type={item.type}>
                {item.button}
                {item.url ? (
                  <ButtonLink
                    data-name={"ButtonLink"}
                    type={item.type}
                    onClick={item.onClick}
                    to={item.url}
                    state={{
                      docTitle: item.docTitle
                    }}
                    tabIndex={0}
                    activestyle={(item.active === true).toString()}
                  >
                    {item.icon && (
                      <Icon
                        data-name={"Icon"}
                        iconColor={defaultTheme.agWhite}
                        {...item.icon}
                      />
                    )}
                    <ButtonLabel type="sideBar" data-name={"ButtonLabel"}>
                      {item.label}
                    </ButtonLabel>
                  </ButtonLink>
                ) : (
                  <P>{item.label}</P>
                )}
              </ListItem>
            ))}
          </List>
          {pagination}
        </SideBarListContainer>
      )}
    </SideBarListWrapper>
  );
};

SidebarList.propTypes = {
  /** List items */
  items: PropTypes.arrayOf(
    PropTypes.shape({
      /** Navigated to on click of link button */
      url: PropTypes.string,
      /** Called on click of link button */
      onClick: PropTypes.func,
      /** docTitle to set on click of link button  */
      docTitle: PropTypes.string,
      /** If button has active styling on it */
      active: PropTypes.bool,
      /** Any icon to display in the link button */
      icon: PropTypes.shape({
        type: PropTypes.string,
        path: PropTypes.string,
        bgWidth: PropTypes.string,
        bgHeight: PropTypes.string,
        width: PropTypes.string,
        height: PropTypes.string
      }),
      /** the label for the link button */
      label: PropTypes.string,
      /** a unique id for the list item */
      id: PropTypes.any
    })
  ),
  /** The type, for styling purposes */
  type: PropTypes.string,
  /** The title for the list */
  title: PropTypes.string,
  /** Whether the list is loading */
  loading: PropTypes.bool,
  /** The size of the loder. Defaults to regular size */
  loaderSize: PropTypes.string,
  /** Pagination element if required */
  pagination: PropTypes.any,
  /** Whether the sidebar itself is open */
  sidebarOpen: PropTypes.bool
};

export default SidebarList;
