import { connect } from "react-redux";
import MapReader from "./MapReader";
import * as actions from "../actions";
import { getHas3D, getLabel, getMapView } from "../selectors";

export default connect(
  (state) => ({
    mapView: getMapView(state),
    has3D: getHas3D(state),
    labels: getLabel(state)
  }),
  actions
)(MapReader);
