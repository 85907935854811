import React, { Component } from "react";
import { VictoryChart, VictoryHistogram } from "victory";
import PropTypes from "prop-types";
import { defaultTheme } from "../../../..";

/** Histogram graph component. See [Victory graph library by Formidable](https://formidable.com/open-source/victory/docs/https://formidable.com/open-source/victory/docs/victory-histogram/) for more information on props */
export default class Histogram extends Component {
  static propTypes = {
    /** The data to be rendered on the graph */
    data: PropTypes.arrayOf(PropTypes.object),
    /** The bins to arrange the bars into */
    bins: PropTypes.oneOfType([PropTypes.array, PropTypes.number]),
    /** The spacing between bins */
    binGap: PropTypes.number,
    /** Colors for the bins of the graph */
    colorBreaks: PropTypes.arrayOf(
      PropTypes.shape({
        minValue: PropTypes.number,
        maxValue: PropTypes.number,
        color: PropTypes.string
      })
    ),
    /** The default bin color */
    defaultColor: PropTypes.string
  };
  static defaultProps = {
    defaultColor: defaultTheme.agGray
  };
  getData = () => {
    const { data } = this.props;
    if (!data) return [];
    return data;
  };

  getBins = () => {
    const { bins } = this.props;
    return bins ? bins : [];
  };

  getBinGap = () => {
    const { binGap } = this.props;
    return binGap;
  };

  getDataFill = (datum) => {
    const { colorBreaks, defaultColor } = this.props;
    if (!colorBreaks || !colorBreaks.length || !datum) return defaultColor;
    const { x0, x1 } = datum;
    const colorBreak = colorBreaks.find(
      (item) => x0 >= item.minValue && x1 - 0.01 <= item.maxValue
    );
    return colorBreak ? colorBreak.color : defaultColor;
  };
  render() {
    return (
      <VictoryChart height={400} width={400}>
        <VictoryHistogram
          data={this.getData()}
          bins={this.getBins()}
          binSpacing={this.getBinGap()}
          style={{
            data: {
              fill: ({ datum }) => this.getDataFill(datum)
            }
          }}
        />
      </VictoryChart>
    );
  }
}
