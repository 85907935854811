import { createActions } from "redux-actions";
import {
  CHECK_ESRI_AUTHENTICATION,
  CHECK_AUTHZERO_AUTHENTICATION,
  SUCCESSFUL_AUTHENTICATION,
  ERROR_AUTHENTICATION,
  INVALIDATE_AUTHENTICATION,
  CLEAR_AUTHENTICATION,
  RESET_LOGGING_OUT,
  SET_AUTH0_CLIENT
} from "../constants";

export const {
  checkEsriAuthentication,
  checkAuthzeroAuthentication,
  successfulAuthentication,
  errorAuthentication,
  invalidateAuthentication,
  clearAuthentication,
  resetLoggingOut,
  setAuth0Client
} = createActions({
  [CHECK_ESRI_AUTHENTICATION]: () => {},
  [CHECK_AUTHZERO_AUTHENTICATION]: () => {},
  [SUCCESSFUL_AUTHENTICATION]: (user) => user,
  [ERROR_AUTHENTICATION]: (error) => error,
  [INVALIDATE_AUTHENTICATION]: () => {},
  [CLEAR_AUTHENTICATION]: () => {},
  [RESET_LOGGING_OUT]: () => {},
  [SET_AUTH0_CLIENT]: (auth0Client) => ({ auth0Client })
});
