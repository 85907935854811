import React, { Component } from "react";
import {
  FeatureTableWrapper,
  FeatureTableContainer,
  TableViewButtonWrap
} from "../../AgBoxUIKit/plugin/components";
import FeatureTableComponent from "../../AgBoxUIKit/plugin/components/FeatureTableComponent/FeatureTableComponent";
import AddButton from "../../AgBoxUIKit/plugin/components/FeatureTableComponent/subcomponents/Buttons/AddButton";

export default class FeatureTable extends Component {
  getHeaders = () => {
    const { header } = this.props;
    return header
      ? header.map((headerItem, index) => ({
          ...headerItem,
          id: `headerItem-${index}`
        }))
      : [];
  };
  getCorrespondingHeader = (cell) => {
    const headers = this.getHeaders();
    const { key } = cell;
    return headers.find((header) => header.key === key);
  };
  getCellWidth = (cell) => {
    const relatedHeader = this.getCorrespondingHeader(cell);
    return relatedHeader && relatedHeader.width ? relatedHeader.width : "auto";
  };
  valueIsValid = (value, required) => {
    const { checkValueValidation } = this.props;
    const isValid = checkValueValidation
      ? checkValueValidation(value)
      : required
      ? value != null && value !== ""
      : true;
    return isValid;
  };

  handleOnChangeValue = (cellInfo, newValue) => {
    const { valueIndex, cellKey } = cellInfo;
    const { handleOnChange } = this.props;
    if (!handleOnChange) return;
    const cellCopy = { ...cellInfo, key: cellKey };
    const { cellValues } = cellCopy;
    cellValues[valueIndex] = newValue;
    handleOnChange(cellCopy);
  };
  requiredValueIsBlank = (value, required) => {
    return required ? value == null || value === "" : false;
  };
  handleDeleteCellItem = (cellInfo) => {
    const { handleOnChange } = this.props;
    if (!handleOnChange) return;
    const { cellValues, valueIndex, cellKey } = cellInfo;
    cellValues.splice(valueIndex, 1);
    handleOnChange({ ...cellInfo, key: cellKey });
  };
  rowHasInvalidCells = (row) => {
    return row.some((cell) => {
      const { value, required } = cell;
      const cellValues = Array.isArray(value) ? value : [value];
      return cellValues.some(
        (cellValue) => !this.valueIsValid(cellValue, required)
      );
    });
  };
  handleAddItem = (cell, rowIndex) => {
    const cellCopy = { ...cell };
    const { handleOnChange } = this.props;
    const { value, defaultValue, fieldType, key } = cell;
    let cellValues = Array.isArray(value) ? value : [value];
    const newValue = defaultValue
      ? defaultValue
      : fieldType === "boolean" || fieldType === "troolean"
      ? null
      : "";
    cellValues = [...cellValues, newValue];
    const updatedCell = {
      ...cellCopy,
      cellValues,
      value: cellValues,
      rowIndex
    };
    if (handleOnChange) handleOnChange(updatedCell);
  };
  rowHasEditableItem = (row) => {
    return row.some((cell) => cell.editable === true);
  };
  handleDeleteRow = (rowIndex) => {
    const { handleDeleteRow } = this.props;
    if (handleDeleteRow) handleDeleteRow(rowIndex);
  };
  allowDeleteRow = () => {
    const { allowDeleteRow } = this.props;
    return allowDeleteRow !== false;
  };
  noRowsMessage = () => {
    const { GENERIC_NO_ROWS_LABEL } = this.props.labels;
    const { noDataLabel } = this.props;
    return noDataLabel ? noDataLabel : GENERIC_NO_ROWS_LABEL;
  };
  belowCells = (row) => {
    return row.filter((cell) => cell.isBelow);
  };
  optionalSections = (row) => row.filter((item) => item.isOptional);
  sectionCellHeaders = (section) => {
    return section.sectionItems.length === 0 ? [] : section.sectionItems[0];
  };
  rowIsRemovable = (row) => {
    return (
      this.rowHasEditableItem(row) &&
      this.allowDeleteRow() &&
      !row.some((cell) => cell.isRemoveable === false)
    );
  };
  handleAddSectionItem = (section, rowIndex) => {
    const { handleAddSectionItem } = this.props;
    if (!handleAddSectionItem) return;
    handleAddSectionItem(section, rowIndex);
  };
  handleDeleteSectionItem = (sectionKey, sectionItemIndex, rowIndex) => {
    const { handleRemoveSectionItem } = this.props;
    if (!handleRemoveSectionItem) return;
    handleRemoveSectionItem(sectionKey, sectionItemIndex, rowIndex);
  };
  getRows = () => {
    const { rows } = this.props;
    return rows ? rows : [];
  };
  anyRowsHaveEditableItem = () => {
    const rows = this.getRows();
    return rows.some((row) => this.rowHasEditableItem(row));
  };
  anyRowsRemovable = () => {
    const rows = this.getRows();
    return rows.some((row) => this.rowIsRemovable(row));
  };
  addNewRow = async () => {
    const { onAddNewRow } = this.props;
    if (!onAddNewRow) return;
    await onAddNewRow();
    const rows = [...this.getRows()];
    const lastRow = rows[rows.length - 1];
    const firstEditableItem = lastRow.find((cell) => cell.editable === true);
    const { key } = firstEditableItem;
    const input = document.getElementById(
      `row-${rows.length - 1}-cell-${key}-0`
    );
    if (!input) return;
    input.focus();
  };
  canAddRow = () => {
    const { canAddRow } = this.props;
    return canAddRow === true;
  };

  addRowLabel = () => {
    const {
      addRowLabel,
      labels: { GENERIC_ADD_ROW_LABEL }
    } = this.props;
    return addRowLabel ? addRowLabel : GENERIC_ADD_ROW_LABEL;
  };

  formatCell = (cell) => {
    const {
      labels: { LABEL_YES, LABEL_NO, LABEL_NA, DEFAULT_OPTION_VALUE_LABEL }
    } = this.props;
    const {
      domainValues,
      value,
      fieldType,
      allowAddMultiple,
      editable,
      width,
      key
    } = cell;
    const cellValues = Array.isArray(value) ? value : [value];
    let options = domainValues;
    if (fieldType === "boolean") {
      options = [
        {
          value:
            domainValues && domainValues.length > 0
              ? domainValues[0].value
              : true,
          title:
            domainValues && domainValues.length > 0
              ? domainValues[0].title
              : LABEL_YES
        },
        {
          value:
            domainValues && domainValues.length > 1
              ? domainValues[1].value
              : false,
          title:
            domainValues && domainValues.length > 1
              ? domainValues[1].title
              : LABEL_NO
        }
      ];
    }
    if (fieldType === "troolean") {
      options = [
        {
          value:
            domainValues && domainValues.length > 0
              ? domainValues[0].value
              : true,
          title:
            domainValues && domainValues.length > 0
              ? domainValues[0].title
              : LABEL_YES
        },
        {
          value:
            domainValues && domainValues.length > 1
              ? domainValues[1].value
              : false,
          title:
            domainValues && domainValues.length > 1
              ? domainValues[1].title
              : LABEL_NO
        },
        {
          value:
            domainValues && domainValues.length > 2
              ? domainValues[2].value
              : null,
          title:
            domainValues && domainValues.length > 2
              ? domainValues[2].title
              : LABEL_NA
        }
      ];
    }
    if (fieldType === "select") {
      options = [
        { title: DEFAULT_OPTION_VALUE_LABEL, value: "", disabled: true },
        ...domainValues
      ];
    }

    if (fieldType === "checkbox") {
      options = domainValues.map((domainValue) => ({
        ...domainValue,
        checked: cellValues.length > 0 && cellValues[0] === domainValue.value
      }));
    }
    return {
      ...cell,
      width: width
        ? width
        : this.getCorrespondingHeader(cell) &&
          this.getCorrespondingHeader(cell).width
        ? this.getCorrespondingHeader(cell).width
        : "auto",
      cellValues,
      cellKey: key,
      options: options
        ? options.map((option) => ({
            ...option,
            title: option.title ? option.title : option.value
          }))
        : [],
      editable:
        fieldType === "select" &&
        options.filter((option) => !option.disabled).length === 1
          ? false
          : editable,
      showAddMultiple: allowAddMultiple && editable,
      showDeleteButton: allowAddMultiple && editable && cellValues.length > 1
    };
  };

  handleDomainValues = (cell) => {
    const {
      domainValues,
      fieldType,
      value,
      canEdit,
      allowAddMultiple,
      cellValues
    } = cell;
    const { handleOnChange } = this.props;
    if (
      domainValues &&
      (fieldType === "select" ||
        fieldType === "troolean" ||
        (fieldType === "string" && domainValues.length > 0))
    ) {
      const emptyCellValues = cellValues.some((value) => value === "");
      let validCellValues = cellValues.filter(
        (valueItem) =>
          domainValues.filter((domainValue) => domainValue.value === valueItem)
            .length > 0 || value === ""
      );
      if (
        !cellValues.every((value) => value === "") &&
        cellValues.length !== validCellValues.length &&
        !canEdit &&
        !allowAddMultiple
      ) {
        handleOnChange({
          ...cell,
          cellValues: validCellValues.length > 0 ? validCellValues : [""]
        });
      }
      if (domainValues.length === 1 && emptyCellValues) {
        const updatedCellValues = cellValues.map((cellValue) =>
          cellValue === "" ? domainValues[0].value : cellValue
        );
        handleOnChange({
          ...cell,
          cellValues: updatedCellValues
        });
      }
    }
  };

  getFormattedRows = () => {
    const rows = this.getRows();
    const {
      labels: { GENERIC_VALIDATION_ERROR_MESSAGE_LABEL }
    } = this.props;
    const formattedRows = rows.map((row) => {
      const cells = row
        .filter((cell) => !cell.isBelow && !cell.isOptional)
        .map((cell) => this.formatCell(cell));
      const belowCells = this.belowCells(row).map((cell) => ({
        ...this.formatCell(cell),
        header: this.getCorrespondingHeader(cell)
          ? this.getCorrespondingHeader(cell).label
          : ""
      }));
      const sections = this.optionalSections(row);
      const removeable = this.rowIsRemovable(row);
      const showInvalidMessage =
        this.rowHasEditableItem(row) && this.rowHasInvalidCells(row);
      const errorMessage = GENERIC_VALIDATION_ERROR_MESSAGE_LABEL;
      const formattedSections = sections.map((section) => {
        const headers = this.sectionCellHeaders(section);
        return {
          ...section,
          headers,
          sectionItems: section.sectionItems.map(
            (sectionItem, sectionItemIndex) =>
              sectionItem.map((cell) => ({
                ...this.formatCell(cell),
                sectionKey: section.key,
                sectionItemIndex
              }))
          )
        };
      });
      return {
        cells,
        belowCells,
        sections: formattedSections,
        removeable,
        showInvalidMessage,
        errorMessage
      };
    });
    return formattedRows;
  };
  featureTableProps = () => {
    const {
      labels: {
        DELETE_TABLE_ROW_LABEL,
        FEATURE_ATTRIBUTE_NO_VALUE_LABEL,
        DATE_PICKER_PLACEHOLDER_LABEL,
        RESET_DATEPICKER_LABEL
      }
    } = this.props;
    return {
      rows: this.getFormattedRows(),
      headers: this.getHeaders(),
      handleOnChangeValue: this.handleOnChangeValue,
      handleAddSectionItem: this.handleAddSectionItem,
      handleDeleteSectionItem: this.handleDeleteSectionItem,
      handleDeleteRow: this.handleDeleteRow,
      handleAddValueItem: this.handleAddItem,
      handleDeleteValueItem: this.handleDeleteCellItem,
      showDeleteRowColumn:
        this.anyRowsHaveEditableItem() && this.allowDeleteRow(),
      deleteRowLabel: DELETE_TABLE_ROW_LABEL,
      deleteSectionLabel: DELETE_TABLE_ROW_LABEL,
      handleDomainValues: this.handleDomainValues,
      noValueLabel: FEATURE_ATTRIBUTE_NO_VALUE_LABEL,
      datePickerLabels: {
        resetLabel: RESET_DATEPICKER_LABEL,
        placeholderLabel: DATE_PICKER_PLACEHOLDER_LABEL
      }
    };
  };
  render() {
    return (
      <FeatureTableWrapper>
        <FeatureTableContainer
          scrollable={this.canAddRow()}
          minWidth={this.props.minWidth}
        >
          <FeatureTableComponent {...this.featureTableProps()} />
        </FeatureTableContainer>
        {this.canAddRow() && (
          <TableViewButtonWrap>
            <AddButton label={this.addRowLabel()} onClick={this.addNewRow} />
          </TableViewButtonWrap>
        )}
      </FeatureTableWrapper>
    );
  }
}
