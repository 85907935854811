import { createActions } from "redux-actions";
import {
  LOADED_WEB_MAP,
  ERROR_LOADING_WEB_MAP,
  SETUP_LAYER_LABELS,
  UPDATED_LAYERS,
  LOADING_WEB_MAP,
  SET_FEATURE_LAYERS,
  SETUP_LAYER_RENDERERS,
  SET_DEFAULT_FEATURE_LAYERS,
  RESET_VISIBLE_LAYERS,
  SET_EXTENT,
  UPDATE_LAYER_LABELS,
  SET_MAP_POSITION,
  SET_SHOW_MAP,
  LOADED_MAP,
  LOAD_MAP,
  SET_BASEMAP_ID,
  SET_BASEMAP,
  SET_ELEVATION_ON,
  CLEAR_PROPERTY_DISPLAY_LAYERS,
  SET_PROPERTY_DISPLAY_LAYER_FILTERS,
  START_PROPERTY_DISPLAY,
  CANCEL_PROPERTY_DISPLAY,
  LOAD_IMAGERY_DATES,
  LOADED_IMAGERY_DATES,
  CREATE_IMAGERY_TILE_LAYERS,
  REMOVE_IMAGERY_TILE_LAYERS,
  SET_IMAGERY_LOADING,
  MAP_VIEW_CLICK
} from "../constants";

export const {
  loadedWebMap,
  errorLoadingWebMap,
  setupLayerLabels,
  setupLayerRenderers,
  updatedLayers,
  loadingWebMap,
  setFeatureLayers,
  setDefaultFeatureLayers,
  resetVisibleLayers,
  setExtent,
  updateLayerLabels,
  setBasemap,
  setBasemapId,
  loadMap,
  loadedMap,
  setMapPosition,
  setShowMap,
  setElevationOn,
  clearPropertyDisplayLayers,
  setPropertyDisplayLayerFilters,
  startPropertyDisplay,
  cancelPropertyDisplay,
  loadImageryDates,
  loadedImageryDates,
  createImageryTileLayers,
  removeImageryTileLayers,
  setImageryLoading,
  mapViewClick
} = createActions({
  [LOADED_WEB_MAP]: (webMap) => webMap,
  [ERROR_LOADING_WEB_MAP]: (error) => error,
  [SETUP_LAYER_LABELS]: () => {},
  [SETUP_LAYER_RENDERERS]: () => {},
  [UPDATED_LAYERS]: () => {},
  [LOADING_WEB_MAP]: (loading) => loading,
  [SET_FEATURE_LAYERS]: (featureLayers) => ({ featureLayers }),
  [SET_DEFAULT_FEATURE_LAYERS]: (layerUrls) => ({ layerUrls }),
  [RESET_VISIBLE_LAYERS]: () => {},
  [SET_EXTENT]: (extent) => ({ extent }),
  [UPDATE_LAYER_LABELS]: (labels) => labels,
  [SET_BASEMAP]: (basemap) => basemap,
  [SET_BASEMAP_ID]: (basemapId) => basemapId,
  [LOAD_MAP]: (mapContainer, baseMap) => ({ mapContainer, baseMap }),
  [LOADED_MAP]: (mapView) => mapView,
  [SET_MAP_POSITION]: (extent) => extent,
  [SET_SHOW_MAP]: (showMap) => ({ showMap }),
  [SET_ELEVATION_ON]: (elevationOn) => ({ elevationOn }),
  [CLEAR_PROPERTY_DISPLAY_LAYERS]: () => {},
  [SET_PROPERTY_DISPLAY_LAYER_FILTERS]: (searchGeometry) => ({
    searchGeometry
  }),
  [START_PROPERTY_DISPLAY]: () => {},
  [CANCEL_PROPERTY_DISPLAY]: () => {},
  [LOAD_IMAGERY_DATES]: (orgId, propId) => ({ orgId, propId }),
  [LOADED_IMAGERY_DATES]: (orgId, propId, results) => ({
    orgId,
    propId,
    results
  }),
  [CREATE_IMAGERY_TILE_LAYERS]: (orgId, propId, results) => ({
    orgId,
    propId,
    results
  }),
  [REMOVE_IMAGERY_TILE_LAYERS]: (propId) => ({ propId }),
  [SET_IMAGERY_LOADING]: (propId, isLoading) => ({ propId, isLoading }),
  [MAP_VIEW_CLICK]: (event) => event
});
