import React, { Component } from "react";
import { Modal } from "@agBoxUiKit/core";

import {
  ERROR_TYPE_PROPERTY,
  ERROR_TYPE_ORG,
  ERROR_TYPE_WORKFLOWS,
  URL_DELIMITER_PROP,
  ERROR_TYPE_PROPERTY_GROUP,
  SUPPORT_EMAIL,
  URL_DELIMITER_PROP_GROUP,
  ERROR_TYPE_USER,
  ERROR_ORGANISATION,
  URL_DELIMITER_WORKFLOW,
  URL_DELIMITER_SELECT_ORG
} from "../constants";

/**
 * Error modal that will appear whenever an error is present in any category in the redux state.
 * When an error is present, it changes the window location hash to be 'error'.
 *
 * Makes use of the [Modal](#/UIKit%20Components?id=modal) component.
 */
class Errors extends Component {
  showError = () => (this.props.error ? true : false);

  componentDidUpdate(prevProps) {
    if (prevProps.error !== this.props.error) {
      if (this.props.error) {
        this.addURLHash();
      } else {
        this.removeURLHash();
      }
    }
  }

  addURLHash = () => {
    window.location.hash = "error";
  };

  removeURLHash = () => {
    if (window.location.hash === "#error") window.location.hash = "";
  };

  getModalTitle = () => {
    const {
      error,
      labels: { ERRORS_MODAL_HEADING_LABEL }
    } = this.props;
    return error ? ERRORS_MODAL_HEADING_LABEL : "";
  };

  supportEmail = () => {
    const { selectedOrg } = this.props;
    if (!selectedOrg) return SUPPORT_EMAIL;
    const { preferences } = selectedOrg;
    if (!preferences) return SUPPORT_EMAIL;
    const { supportEmail } = preferences;
    return supportEmail ? supportEmail : SUPPORT_EMAIL;
  };

  encodeString = (string) => {
    return string.replace(/ /g, "%20");
  };

  getModalBody = () => {
    const {
      error,
      selectedOrg,
      selectedProperty,
      selectedPropertyGroup,
      labels: {
        ERRORS_CONTACT_ADMIN_LABEL,
        AGBOX_ERROR_LABEL,
        AGBOX_HAS_FOLLOWING_ERROR_LABEL
      }
    } = this.props;
    if (!error) return null;
    const propertyString = selectedProperty
      ? `Property: ${selectedProperty.title} (${selectedProperty.propId})`
      : selectedPropertyGroup
      ? `PropertyGroup: ${selectedPropertyGroup.title} (${selectedPropertyGroup.groupId})`
      : "";
    return (
      <div>
        <p>
          {error.message}
          <br />
          <a
            href={`mailto:${this.supportEmail()}?subject=${this.encodeString(
              AGBOX_ERROR_LABEL
            )}&body=${this.encodeString(
              AGBOX_HAS_FOLLOWING_ERROR_LABEL
            )}:%0A${this.encodeString(`Message: ${error.message}`)}%0A%0A${
              selectedOrg
                ? this.encodeString(`Organisation: ${selectedOrg.title}`)
                : ""
            }%0A${this.encodeString(propertyString)}`}
          >
            {ERRORS_CONTACT_ADMIN_LABEL}
          </a>
        </p>
      </div>
    );
  };

  primaryButtonTitle = () => {
    const {
      error,
      availableOrgs,
      availableProperties,
      availablePropertyGroups,
      labels: {
        ERRORS_BACK_BUTTON_LABEL,
        ERRORS_CHANGE_ORG_LABEL,
        ERRORS_CHANGE_PROPERTY_LABEL,
        ERRORS_CHANGE_PROPERTY_GROUP_LABEL,
        ERRORS_CLOSE_LABEL,
        SIGN_OUT_LABEL
      }
    } = this.props;
    if (!error) return "";

    switch (error.type) {
      case ERROR_TYPE_ORG: {
        return availableOrgs.length ? ERRORS_CHANGE_ORG_LABEL : SIGN_OUT_LABEL;
      }
      case ERROR_TYPE_USER: {
        return SIGN_OUT_LABEL;
      }
      case ERROR_TYPE_PROPERTY: {
        if (!availableProperties || !availableProperties.length)
          return ERRORS_CHANGE_ORG_LABEL;
        return ERRORS_CHANGE_PROPERTY_LABEL;
      }
      case ERROR_TYPE_PROPERTY_GROUP: {
        if (window.location.pathname.includes(URL_DELIMITER_PROP_GROUP)) {
          return availablePropertyGroups.length <= 1 &&
            availableProperties.length
            ? ERRORS_CHANGE_PROPERTY_LABEL
            : ERRORS_CHANGE_PROPERTY_GROUP_LABEL;
        }
        return ERRORS_CLOSE_LABEL;
      }
      case null: {
        return ERRORS_CLOSE_LABEL;
      }
      default: {
        return ERRORS_BACK_BUTTON_LABEL;
      }
    }
  };

  primaryButtonAction = () => {
    const {
      error,
      clearErrors,
      invalidateAuthentication,
      availableOrgs,
      availableProperties
    } = this.props;
    const { pathname } = window.location;
    if (error) {
      const { type } = error;
      switch (type) {
        case ERROR_TYPE_ORG: {
          return availableOrgs.length
            ? this.handleGoToSelectOrg
            : invalidateAuthentication;
        }
        case ERROR_TYPE_WORKFLOWS: {
          return this.handleGoToSelectWorkflow;
        }
        case ERROR_TYPE_PROPERTY_GROUP: {
          return pathname.indexOf(URL_DELIMITER_PROP_GROUP) === -1
            ? clearErrors
            : null;
        }
        case ERROR_TYPE_USER: {
          return invalidateAuthentication;
        }
        case ERROR_TYPE_PROPERTY: {
          return !availableProperties || !availableProperties.length
            ? this.handleGoToSelectOrg
            : null;
        }
        case null: {
          return clearErrors;
        }
        default: {
          return null;
        }
      }
    }
    return null;
  };

  handleGoToSelectOrg = () => {
    const { errorPropertyGroups, errorProperties, abortRequest } = this.props;
    errorPropertyGroups(false);
    errorProperties(false);
    abortRequest();
    window.location.hash = URL_DELIMITER_SELECT_ORG;
  };

  handleGoToSelectWorkflow = () => {
    const { errorWorkflow } = this.props;
    errorWorkflow(false);
  };

  primaryButtonLink = () => {
    const { error, availableProperties } = this.props;
    if (!error) return null;
    const docTitle = this.getNewDocTitle();
    let url = null;
    switch (error.type) {
      case ERROR_TYPE_WORKFLOWS:
        url = this.getWorkflowErrorBackLink();
        break;
      case ERROR_TYPE_PROPERTY:
        url =
          availableProperties && availableProperties.length
            ? this.getPropErrorBackLink()
            : null;
        break;
      case ERROR_TYPE_PROPERTY_GROUP:
        url = this.getPropErrorBackLink();
        break;
      default:
        url = null;
    }
    if (!url) return null;
    return {
      url,
      docTitle
    };
  };

  getWorkflowErrorBackLink = () => {
    const path = window.location.pathname;
    return path.indexOf(URL_DELIMITER_WORKFLOW) !== -1
      ? path.split(URL_DELIMITER_WORKFLOW)[0]
      : null;
  };

  getPropErrorBackLink = () => {
    const path = window.location.pathname;
    return path.indexOf(URL_DELIMITER_PROP) !== -1
      ? path.split(URL_DELIMITER_PROP)[0]
      : null;
  };

  getNewDocTitle = () => {
    const {
      error,
      selectedProperty,
      selectedPropertyGroup,
      selectedOrg,
      labels: { SELECT_ORG_DOC_TITLE }
    } = this.props;
    if (!error) return null;
    switch (error.type) {
      case ERROR_TYPE_WORKFLOWS: {
        if (selectedProperty) return selectedProperty.title;
        else if (selectedPropertyGroup) return selectedPropertyGroup.title;
        return null;
      }
      case ERROR_TYPE_PROPERTY:
      case ERROR_TYPE_PROPERTY_GROUP:
        return selectedOrg ? selectedOrg.title : null;
      case ERROR_ORGANISATION:
        return SELECT_ORG_DOC_TITLE;
      default:
        return null;
    }
  };

  render() {
    return (
      <Modal
        isOpen={this.showError()}
        title={this.getModalTitle()}
        body={this.getModalBody()}
        showCloseButton={false}
        primaryButtonTitle={this.primaryButtonTitle()}
        primaryButtonAction={this.primaryButtonAction()}
        primaryButtonLink={this.primaryButtonLink()}
      />
    );
  }
}

export default Errors;
