import { connect } from "react-redux";
import FeatureMerge from "./FeatureMerge";
import {
  getWebMap,
  getMapView,
  getHas3D,
  getLabel,
  getPropertySpatialReference,
  getDefaultSpatialReference
} from "../../selectors";
import * as actions from "../../actions";

export default connect(
  (state) => ({
    mapView: getMapView(state),
    webMap: getWebMap(state),
    has3D: getHas3D(state),
    labels: getLabel(state),
    orgSpatialReference: getDefaultSpatialReference(state),
    propertySpatialReference: getPropertySpatialReference(state)
  }),
  actions,
  null,
  {
    forwardRef: true
  }
)(FeatureMerge);
