import { handleActions } from "redux-actions";
import { lazy } from "react";
import {
  LOAD_WORKFLOW_PLUGINS,
  LOADED_WORKFLOW_PLUGINS,
  CLEAR_WORKFLOW_PLUGINS,
  ERROR_WORKFLOW_PLUGINS,
  CLEAR_ERRORS
} from "../constants";

const allocateOverseerBlocks = lazy(() =>
  import("../plugins/allocateOverseerBlocks")
);

const baseFeatures = lazy(() => import("../plugins/baseFeatures"));

const biosecurityDataCollection = lazy(() =>
  import("../plugins/biosecurityDataCollection")
);

const biosecurityDataSummary = lazy(() =>
  import("../plugins/biosecurityDataSummary")
);

const biosecurityEvents = lazy(() => import("../plugins/biosecurityEvents"));

const codlingMoths = lazy(() => import("../plugins/codlingMoths"));
const codlingMothsSummary = lazy(() =>
  import("../plugins/codlingMothsSummary")
);
const cropEstimates = lazy(() => import("../plugins/cropEstimates"));
const cropEstimatesSummary = lazy(() =>
  import("../plugins/cropEstimatesSummary")
);
const cropEvents = lazy(() => import("../plugins/cropEvents"));
const crops = lazy(() => import("../plugins/crops"));
const cropSplitEstimation = lazy(() =>
  import("../plugins/cropSplitEstimation")
);
const cropSummaryViewer = lazy(() => import("../plugins/cropSummaryViewer"));

const counts = lazy(() => import("../plugins/counts"));
const countSummaryViewer = lazy(() => import("../plugins/countSummaryViewer"));
const dmsPrinting = lazy(() => import("../plugins/dmsPrinting"));

const environmentalInfo = lazy(() => import("../plugins/environmentalInfo"));
const environmentalSummary = lazy(() =>
  import("../plugins/environmentalSummary")
);
const explorer = lazy(() => import("../plugins/explorer"));

const farmMapQuote = lazy(() => import("../plugins/farmMapQuote"));
const fepScorecard = lazy(() => import("../plugins/fepScorecard"));
const fieldDaysQuote = lazy(() => import("../plugins/fieldDaysQuote"));

const fruitDensity = lazy(() => import("../plugins2/fruitDensity"));
const fruitometryDataViewer = lazy(() =>
  import("../plugins2/fruitometryDataViewer")
);
const hazards = lazy(() => import("../plugins2/hazards"));
const hazardSummaryViewer = lazy(() =>
  import("../plugins2/hazardSummaryViewer")
);
const historyFeatures = lazy(() => import("../plugins2/historyFeatures"));
const incursionSummary = lazy(() => import("../plugins2/incursionSummary"));
const managementAreas = lazy(() => import("../plugins2/managementAreas"));
const managementAreaSummaryViewer = lazy(() =>
  import("../plugins2/managementAreasSummaryViewer")
);
const monitoringBays = lazy(() => import("../plugins2/monitoringBays"));
const mpacMaturityAreaSummary = lazy(() =>
  import("../plugins2/mpacMaturityAreaSummary")
);
const nzapiBlocks = lazy(() => import("../plugins2/nzapiBlocks"));
const nzapiBlockSummary = lazy(() => import("../plugins2/nzapiBlockSummary"));
const nzapiPrinting = lazy(() => import("../plugins2/nzapiPrinting"));
const operationsAndEvents = lazy(() =>
  import("../plugins2/operationsAndEvents")
);
const operationsAndEventsSummary = lazy(() =>
  import("../plugins2/operationsAndEventsSummary")
);
const overseerBlocksSummaryViewer = lazy(() =>
  import("../plugins2/overseerBlocksSummaryViewer")
);
const paddockAnalysis = lazy(() => import("../plugins2/paddockAnalysis"));
const printing = lazy(() => import("../plugins2/printing"));
const productionSites = lazy(() => import("../plugins2/productionSites"));
const propertyEditor = lazy(() => import("../plugins2/propertyEditor"));

const propertyFeatures = lazy(() => import("../plugins3/propertyFeatures"));
const propertySummaryViewer = lazy(() =>
  import("../plugins3/propertySummaryViewer")
);
const sharedMaps = lazy(() => import("../plugins3/sharedMaps"));
const sprayApplication = lazy(() => import("../plugins3/sprayApplication"));
const sprayDrift = lazy(() => import("../plugins3/sprayDrift"));
const sprayJobs = lazy(() => import("../plugins3/sprayJobs"));
const sprayJobsSummary = lazy(() => import("../plugins3/sprayJobsSummary"));
const sprayManagement = lazy(() => import("../plugins3/sprayManagement"));
const sunforestDataViewer = lazy(() =>
  import("../plugins3/sunforestDataViewer")
);
const sunforestDataViewerSummary = lazy(() =>
  import("../plugins3/sunforestDataViewerSummary")
);
const zespriSprayPlans = lazy(() => import("../plugins3/zespriSprayPlans"));
const zespriSurvey = lazy(() => import("../plugins3/zespriSurvey"));
const zespriSurveySummary = lazy(() =>
  import("../plugins3/zespriSurveySummary")
);

export const defaultState = {
  available: {
    allocateOverseerBlocks,
    baseFeatures,
    biosecurityDataCollection,
    biosecurityDataSummary,
    biosecurityEvents,
    codlingMoths,
    codlingMothsSummary,
    counts,
    countSummaryViewer,
    crops,
    cropEvents,
    cropEstimates,
    cropEstimatesSummary,
    cropSplitEstimation,
    cropSummaryViewer,
    dmsPrinting,
    explorer,
    environmentalInfo,
    environmentalSummary,
    farmMapQuote,
    fepScorecard,
    fieldDaysQuote,
    fruitDensity,
    fruitometryDataViewer,
    hazards,
    hazardSummaryViewer,
    historyFeatures,
    incursionSummary,
    managementAreas,
    managementAreaSummaryViewer,
    mpacMaturityAreaSummary,
    monitoringBays,
    nzapiBlocks,
    nzapiBlockSummary,
    nzapiPrinting,
    operationsAndEvents,
    operationsAndEventsSummary,
    overseerBlocksSummaryViewer,
    paddockAnalysis,
    productionSites,
    printing,
    propertyFeatures,
    propertyEditor,
    propertySummaryViewer,
    sharedMaps,
    sprayApplication,
    sprayDrift,
    sprayJobs,
    sprayJobsSummary,
    sprayManagement,
    sunforestDataViewer,
    sunforestDataViewerSummary,
    zespriSprayPlans,
    zespriSurvey,
    zespriSurveySummary
  },
  loadable: [],
  isLoading: false,
  error: false
};

// @ts-ignore
export default handleActions(
  {
    [LOAD_WORKFLOW_PLUGINS]: (state) => ({
      ...state,
      isLoading: true,
      loadable: [],
      error: false
    }),
    [LOADED_WORKFLOW_PLUGINS]: (state, { payload }) => ({
      ...state,
      isLoading: false,
      loadable: payload,
      error: false
    }),
    [CLEAR_WORKFLOW_PLUGINS]: (state) => ({
      ...state,
      isLoading: false,
      loadable: [],
      error: false
    }),
    [ERROR_WORKFLOW_PLUGINS]: (state, { payload }) => ({
      ...state,
      isLoading: false,
      loadable: [],
      error: payload
    }),
    [CLEAR_ERRORS]: (state) => ({
      ...state,
      error: false
    })
  },
  defaultState
);
