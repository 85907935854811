import React, { Component } from "react";
import { FeatureCreatePickerComponent } from "../../AgBoxUIKit/plugin";
import DrawingTool from "../DrawingTool";
import { FeatureCreatePickerWrap } from "../../AgBoxUIKit/plugin/components";

export default class FeatureCreatePicker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checkedOption: "draw"
    };
  }
  componentDidMount() {
    this.selectInitialValue();
  }

  selectInitialValue = () => {
    const hasDrawOption =
      this.drawingToolSettings() && this.hasAnyDrawingPermissions();
    if (!hasDrawOption && !this.hasSelectionOptions()) return;
    const selectedOption = hasDrawOption
      ? "draw"
      : this.getSelectionOptions()[0].value;
    this.handleUpdateSelection(selectedOption);
  };

  drawingToolSettings = () => {
    const { drawingToolSettings } = this.props;
    return drawingToolSettings;
  };
  hasAnyDrawingPermissions = () => {
    const { permissions, tools } = this.drawingToolSettings();
    if (!permissions) return true;
    return permissions.some(
      (item) => tools.find((tool) => tool.param === item) != undefined
    );
  };

  handleUpdateSelection = (value) => {
    this.setState({
      checkedOption: value
    });
    const options = this.getOptions();
    const selectedOption = options.find((option) => option.value === value);
    if (selectedOption && selectedOption.action) {
      selectedOption.action();
    }
  };

  getOptions = () => {
    const {
      labels: { DRAW_FEATURE_LABEL }
    } = this.props;
    const drawingToolSettings = this.drawingToolSettings();
    const otherOptions = this.getSelectionOptions();
    const allOptions =
      drawingToolSettings && this.hasAnyDrawingPermissions()
        ? [
            {
              title: drawingToolSettings.optionLabel
                ? drawingToolSettings.optionLabel
                : DRAW_FEATURE_LABEL,
              disabled: drawingToolSettings.optionDisabled === true,
              value: "draw",
              action: drawingToolSettings.onDrawSelect,
              content: (
                <>
                  <DrawingTool {...this.drawingToolSettings()} />
                  {drawingToolSettings.optionContent}
                </>
              ),
              indent: "0px"
            },
            ...otherOptions
          ]
        : otherOptions;
    return allOptions;
  };
  showDisabledMessage = () => {
    return (
      !this.drawingToolSettings() &&
      this.hasSelectionOptions() &&
      this.getSelectionOptions().every((option) => option.disabled)
    );
  };

  getSelectionOptions = () => {
    const { selectionOptions } = this.props;
    return selectionOptions ? selectionOptions : [];
  };

  hasSelectionOptions = () => {
    return this.getSelectionOptions().length;
  };
  disabledMessage = () => {
    return this.showDisabledMessage()
      ? this.getSelectionOptions()
          .map((option) => option.disabledMessage)
          .join("\n")
      : "";
  };

  getLegend = () => {
    const { legend } = this.props;
    return legend ? legend : "";
  };
  render() {
    return (
      <FeatureCreatePickerWrap>
        <FeatureCreatePickerComponent
          options={this.getOptions()}
          showDisabledMessage={this.showDisabledMessage()}
          disabledMessage={this.disabledMessage()}
          legend={this.getLegend()}
          name={"selection-options-set"}
          value={this.state.checkedOption}
          handleUpdateValue={this.handleUpdateSelection}
        />
      </FeatureCreatePickerWrap>
    );
  }
}
