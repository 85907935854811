import { connect } from "react-redux";
import FeatureDrawTool from "./FeatureDrawTool";
import { getLabel, getWebMap } from "../../selectors";
import * as actions from "../../actions";

export default connect(
  (state) => ({
    labels: getLabel(state),
    webMap: getWebMap(state)
  }),
  actions,
  null,
  {
    forwardRef: true
  }
)(FeatureDrawTool);
