import { connect } from "react-redux";
import OrganisationSelector from "./OrganisationSelector";
import * as actions from "../actions";
import {
  getAvailableOrgs,
  getSelectedOrg,
  getOrgLoading,
  getOrgSelecting,
  getSelectedProperty,
  getPropertiesSelecting,
  getLabel,
  getUserInvites,
  getLoadingInvitations,
  getAcceptingInvitation,
  getPropertiesError,
  getUserId
} from "../selectors";

export default connect(
  (state) => ({
    availableOrganisations: getAvailableOrgs(state),
    selectedOrganisation: getSelectedOrg(state),
    loadingOrganisations: getOrgLoading(state),
    selectingOrganisation: getOrgSelecting(state),
    selectedProperty: getSelectedProperty(state),
    selectingProperty: getPropertiesSelecting(state),
    labels: getLabel(state),
    invitations: getUserInvites(state),
    loadingInvites: getLoadingInvitations(state),
    acceptingInvite: getAcceptingInvitation(state),
    propertiesError: getPropertiesError(state),
    userId: getUserId(state)
  }),
  actions
)(OrganisationSelector);
