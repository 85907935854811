import { getSession } from "../App/utils";
import { DEFAULT_PROPERTY_SELECTOR_LABEL } from "../constants";

export const getPropertiesSearchController = (state) =>
  state.properties.controller;
export const getPropertiesLoading = (state) => state.properties.isLoading;
export const getPropertiesSearch = (state) => state.properties.search;
export const getNoPropertiesModal = (state) =>
  state.properties.noPropertiesModal;
export const getAvailableProperties = (state) => state.properties.available;
export const getPropertiesSelecting = (state) => state.properties.isSelecting;
export const getSelectedProperty = (state) => state.properties.selected;
export const getPropertiesError = (state) => state.properties.error;
export const getPropertiesPagination = (state) => state.properties.pagination;
export const getSelectedPropId = (state) =>
  state.properties.selected ? state.properties.selected.propId : null;
export const getHasLoadedProperties = (state) =>
  state.properties.finishedLoading;
export const getUserPropertiesForSelectedOrganisation = (state) => {
  try {
    const orgId = state.organisations.selected.orgId;
    const properties = state.user.organisations[orgId].properties;
    return Object.keys(properties).map((propId) => ({
      propId,
      roleId: properties[propId].roleId
    }));
  } catch (e) {
    if (process.env.NODE_ENV === "development") console.log(e);
    return [];
  }
};
export const getPropertyLayerUrl = (state) => state.properties.propertyLayerUrl;
export const getPropertySpatialReference = (state) =>
  state.properties.selected ? state.properties.selected.spatialReference : null;
export const getOrgPropertySelector = (state) =>
  state.organisations.selected &&
  state.organisations.selected.preferences &&
  state.organisations.selected.preferences.propertySelectorLabel
    ? state.organisations.selected.preferences.propertySelectorLabel
    : DEFAULT_PROPERTY_SELECTOR_LABEL;
export const getSearchPropertiesBy = (state) => state.properties.searchBy;

export const getPropertySearchSuggestions = (state) =>
  state.properties.propertySearchSuggestions;
export const getSelectedPropertySearchSuggestion = (state) =>
  state.properties.selectedPropertySearchSuggestion;
export const getPropertiesSearchError = (state) => state.properties.searchError;
export const getPropertiesSearchGeometry = (state) =>
  state.properties.searchGeometry;
export const getZoomToSearchResults = (state) =>
  state.properties.zoomToSearchResults;
export const getSearchMeta = (state) => state.properties.searchMeta;
export const getPropertiesExtent = (state) => state.properties.propertiesExtent;
export const getPropertyFavorites = (state, orgId, userId) => {
  return (
    state.properties.favoritedProperties[orgId] ||
    getSession(`user-${userId}-org-${orgId}-favourite-properties`) ||
    null
  );
};
export const getPropertyUsersLoading = (state) =>
  state.properties.selected.usersLoading || false;
export const getPropertyUsers = (state) => state.properties.selected.users;
