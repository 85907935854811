import React, { Component } from "react";
import {
  TEMP_LAYER_PREFIX,
  FEATURE_AREA_SEARCH,
  URL_DELIMITER_ORG,
  URL_DELIMITER_PROP,
  URL_DELIMITER_PROP_GROUP,
  URL_DELIMITER_WORKFLOW
} from "../../../constants";
import {
  SearchInputWrapper,
  SearchAreaContainer
} from "../../../AgBoxUIKit/plugin/layout";
import { ToolTip } from "../../../AgBoxUIKit/plugin";
import { Button } from "../../../AgBoxUIKit/core/components";
import { Icon } from "../../../AgBoxUIKit/core";
import { defaultTheme } from "../../../AgBoxUIKit";
import DrawingTool from "../../DrawingTool";
import GraphicsLayer from "@arcgis/core/layers/GraphicsLayer";
import { union } from "@arcgis/core/geometry/geometryEngine";
import { isNullOrUndefined } from "../../../App/utils";

export default class AreaSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchClickHandler: null,
      searchGeometries: [],
      searchGraphicsLayer: null,
      sketchTool: null,
      isSearching: false,
      drawingToolSettings: null,
      currentUrl: window.location.href,
      updatedUrl: false
    };

    this.timer = null;
    this.controller = new AbortController();
    this.geometryController = new AbortController();
  }

  componentDidMount() {
    const storedUrl = localStorage.getItem("currentUrl");
    if (storedUrl) {
      this.setState({ currentUrl: storedUrl }, () => {
        this.checkUrlIdChanges();
      });
    } else {
      this.checkUrlIdChanges();
    }
  }

  componentDidUpdate() {
    const newUrl = window.location.href;
    const currentUrl = this.getCurrentUrl();
    if (!newUrl || !currentUrl) return;
    if (currentUrl !== newUrl && this.urlIdsAreDifferent(currentUrl, newUrl)) {
      this.handleUrlChange(newUrl);
    }
  }

  componentWillUnmount() {
    const currentUrl = this.getCurrentUrl();
    this.updateUrl(currentUrl);
    this.unloadSearch();
  }

  setupSearchGraphicsLayer = () => {
    const { webMap } = this.props;
    const searchGraphicsLayer = new GraphicsLayer({
      title: `${TEMP_LAYER_PREFIX}_FEATURE_SEARCH`
    });
    webMap.add(searchGraphicsLayer);

    this.setState({ searchGraphicsLayer });
  };

  setCurrentUrl = (url) => {
    this.setState({ currentUrl: url });
  };

  getCurrentUrl = () => {
    return this.state.currentUrl;
  };

  handleOnSearchStart = (geometry) => {
    const { onSearchStart } = this.props;
    if (!onSearchStart) return false;
    return onSearchStart({ geometry });
  };

  handleClearSearch = () => {
    const { onSearchCleared } = this.props;
    this.setState({ searchGeometries: [], isSearching: false });
    if (!onSearchCleared) return false;
    return onSearchCleared();
  };

  handleResetSearch = () => {
    const { searchGraphicsLayer } = this.state;
    if (searchGraphicsLayer) searchGraphicsLayer.removeAll();
    this.handleClearSearch();
  };

  handleExecuteSearch = (geometry) => {
    const { searchGeometries } = this.state;
    const geometriesToSearch = geometry ? [geometry] : searchGeometries;
    this.setState({ searchGeometries: geometriesToSearch });
    if (geometriesToSearch.length === 0) return this.handleResetSearch();
    const geometriesToSearchUnion = union(geometriesToSearch);
    this.handleOnSearchStart(geometriesToSearchUnion);
  };

  handleCompleteSketch = (event) => {
    if (event.state === "complete") {
      const { geometry } = event.graphic;
      if (geometry.type === "polygon") this.handleExecuteSearch(geometry);
      if (this.props.saveSearchGraphic) {
        const { updateWorkflowState } = this.props;
        updateWorkflowState({ existingSearchGraphic: event.graphic });
      }
    }
  };

  handleUpdateSketch = (event) => {
    const { graphics } = event;
    if (graphics && graphics.length) {
      event.graphic = event.graphics[0];
      this.handleCompleteSketch(event);
    }
  };

  handleCreateSketchTool = (sketchTool) => {
    this.setState({ sketchTool });
  };

  setupSearch = async () => {
    const { saveSearchGraphic, workflowState } = this.props;
    let existingSearchGraphic = workflowState?.existingSearchGraphic;
    if (!saveSearchGraphic || this.state.updatedUrl) {
      existingSearchGraphic = null;
    } else if (saveSearchGraphic && !this.state.updatedUrl) {
      if (!isNullOrUndefined(existingSearchGraphic)) {
        this.handleExecuteSearch(existingSearchGraphic.geometry);
      }
    }

    await this.setupSearchGraphicsLayer();
    const searchClickHandler = FEATURE_AREA_SEARCH;
    const { addFeatureSearchClickHandler } = this.props;
    addFeatureSearchClickHandler(searchClickHandler);
    const { searchGraphicsLayer } = this.state;
    this.setState({
      searchClickHandler,
      drawingToolSettings: {
        existingSearchGraphic: existingSearchGraphic,
        saveSearchGraphic: this.props.saveSearchGraphic,
        layer: searchGraphicsLayer,
        canUpdate: true,
        tools: [
          {
            param: "polygon"
          }
        ],
        onComplete: this.handleCompleteSketch,
        onUpdate: this.handleUpdateSketch,
        onSketchToolCreate: this.handleCreateSketchTool,
        canDrawMultiple: null,
        showResetButton: false
      }
    });
  };

  unloadSearch = () => {
    const { searchGraphicsLayer, searchClickHandler } = this.state;
    const { removeFeatureSearchClickHandler, webMap } = this.props;
    if (searchClickHandler) removeFeatureSearchClickHandler(searchClickHandler);
    if (searchGraphicsLayer) webMap.remove(searchGraphicsLayer);
  };

  handleResetDrawSearch = () => {
    const { searchGraphicsLayer, sketchTool } = this.state;
    if (searchGraphicsLayer) searchGraphicsLayer.removeAll();
    this.handleClearSearch();

    if (sketchTool) sketchTool.create("polygon");
  };

  whenLoading = () => {
    const { loading } = this.props;
    return loading === true;
  };

  getLanguageLabel = (label) => {
    const { labels } = this.props;
    return labels[label] ? labels[label] : "";
  };

  updateUrl = (newUrl) => {
    this.setCurrentUrl(newUrl);
    localStorage.setItem("currentUrl", newUrl);
  };

  urlIdsAreDifferent = (currentUrl, newUrl) => {
    const extractIds = (url) => {
      const orgMatch = url.match(`/${URL_DELIMITER_ORG}/([^\/\?]+)/`);
      const propMatch = url.match(`/${URL_DELIMITER_PROP}/([^\/\?]+)/`);
      const propertyGroupMatch = url.match(
        `/${URL_DELIMITER_PROP_GROUP}/([^\/\?]+)/`
      );
      const workflowMatch = url.match(`/${URL_DELIMITER_WORKFLOW}/([^\/\?]+)`);

      return {
        org: orgMatch ? orgMatch[1] : null,
        prop: propMatch ? propMatch[1] : null,
        propertyGroup: propertyGroupMatch ? propertyGroupMatch[1] : null,
        workflow: workflowMatch ? workflowMatch[1] : null
      };
    };

    const currentIds = extractIds(currentUrl);
    const newIds = extractIds(newUrl);

    const isDifferent = Object.keys(currentIds).some(
      (key) => currentIds[key] !== newIds[key]
    );
    return isDifferent;
  };

  checkUrlIdChanges = () => {
    const newUrl = window.location.href;
    const currentUrl = this.getCurrentUrl();
    if (!newUrl || !currentUrl) return;
    if (currentUrl !== newUrl && this.urlIdsAreDifferent(currentUrl, newUrl)) {
      this.handleUrlChange(newUrl);
    } else {
      this.setupSearch();
    }
  };

  handleUrlChange = (newUrl) => {
    const { onSearchCleared } = this.props;
    if (onSearchCleared) {
      onSearchCleared();
    }
    this.updateUrl(newUrl);
    this.setState({ updatedUrl: true }, () => {
      this.setupSearch();
    });
  };

  render() {
    return (
      <React.Fragment>
        {this.state.drawingToolSettings && (
          <DrawingTool {...this.state.drawingToolSettings} />
        )}
        <SearchInputWrapper data-name={"SearchInputWrapper"}>
          <SearchAreaContainer data-name={"SearchAreaContainer"}>
            {this.state.searchGeometries.length === 0 ? (
              <React.Fragment>
                <p>{this.getLanguageLabel("AREA_SEARCH_DESC_LABEL")}</p>
                <ToolTip
                  helpMessage={this.props.helpText}
                  floatDirection={"right"}
                />
              </React.Fragment>
            ) : (
              <Button
                disabled={this.whenLoading()}
                onClick={this.handleResetDrawSearch}
                type="button"
                styletype={"formReset"}
              >
                <span>{this.getLanguageLabel("AREA_SEARCH_CLEAR_LABEL")}</span>
                <Icon
                  data-name={"Icon"}
                  iconColor={defaultTheme.agRed}
                  type={"close"}
                  bgHeight={"32px"}
                  bgWidth={"32px"}
                  iconHeight={"14px"}
                  iconWidth={"14px"}
                />
              </Button>
            )}
          </SearchAreaContainer>
        </SearchInputWrapper>
      </React.Fragment>
    );
  }
}
