import React, { Component } from "react";
import { FeatureListContainer } from "../../layout";
import FeatureListItem from "./subcomponents/FeatureListItem";
import { FeatureListItemContent } from "..";
import PropTypes from "prop-types";

export default class FeatureListComponent extends Component {
  static propTypes = {
    /** The list items to render */
    listItems: PropTypes.arrayOf(
      PropTypes.shape({
        emphasis: PropTypes.arrayOf(
          PropTypes.shape({
            emphasisIconType: PropTypes.string,
            emphasisIconColor: PropTypes.string,
            hideColorBar: PropTypes.bool
          })
        ),
        title: PropTypes.string,
        subtitles: PropTypes.arrayOf(
          PropTypes.shape({
            value: PropTypes.string,
            highlightColor: PropTypes.string,
            highlightSubtitle: PropTypes.bool,
            show: PropTypes.oneOf(["Always", "ifExists"])
          })
        ),
        highlight: PropTypes.bool,
        image: PropTypes.string
      })
    ),
    /** The key for the objectId field */
    objectIdField: PropTypes.string,
    /**Called on click of the list item */
    handleSelectItem: PropTypes.func,
    /** Called on mouse enter of list item */
    handleHoverItem: PropTypes.func,
    /** Called on mouse leave of list item */
    handleRemoveHover: PropTypes.func,
    /** A message to render when the list is empty */
    noFeatureMessage: PropTypes.string
  };
  listItems = () => {
    const { listItems } = this.props;
    return listItems ? listItems : [];
  };
  objectIdField = () => {
    const { objectIdField } = this.props;
    return objectIdField;
  };
  handleSelectItem = (e) => {
    const id = e.currentTarget.dataset.id;
    const title = e.currentTarget.dataset.title;
    const layerTitle = e.currentTarget.dataset.layerTitle;
    const listItems = this.listItems();
    const { handleSelectItem } = this.props;
    const selectedItem = listItems.find(
      (item) =>
        item.attributes.objectId == id &&
        (!title || item.attributes.title === title) &&
        (!layerTitle || item.layer.title === layerTitle)
    );
    if (handleSelectItem && selectedItem) handleSelectItem(selectedItem);
  };
  handleHoverItem = (e) => {
    const id = e.currentTarget.dataset.id;
    const listItems = this.listItems();
    const { handleHoverItem } = this.props;
    if (!handleHoverItem) return false;
    handleHoverItem(listItems.find((item) => item.attributes.objectId == id));
  };
  handleRemoveHover = () => {
    const { handleRemoveHover } = this.props;
    if (!handleRemoveHover) return false;
    handleRemoveHover();
  };
  whenNoListItems = () => {
    return this.listItems() ? this.listItems().length === 0 : true;
  };
  noFeatureMessage = () => {
    const { noFeatureMessage } = this.props;
    return noFeatureMessage;
  };
  listItemProps = (item) => {
    const { isClickable } = this.props;
    const itemCopy = item.clone ? item.clone() : { ...item };
    itemCopy.handleRemoveHover = this.handleRemoveHover;
    itemCopy.handleSelectItem = this.handleSelectItem;
    itemCopy.handleItemHoverEvent = this.handleHoverItem;
    itemCopy.isClickable = isClickable;
    itemCopy.id = item.attributes.objectId;
    return itemCopy;
  };
  render() {
    return (
      <FeatureListContainer data-name={"FeatureListContainer"}>
        {this.whenNoListItems() ? (
          <FeatureListItemContent hover={false}>
            {this.noFeatureMessage()}
          </FeatureListItemContent>
        ) : (
          this.listItems().map((item, index) => (
            <FeatureListItem
              key={`listitem-${item.title}-${index}`}
              {...this.listItemProps(item)}
            />
          ))
        )}
      </FeatureListContainer>
    );
  }
}
