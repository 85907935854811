import React from "react";
import { TableCellContentWrap } from "../../..";
import { Fieldset, Label, Input } from "../../../../../core/components";
import DeleteCellValueButton from "../Buttons/DeleteCellValueButton";
import PropTypes from "prop-types";

const RadioCell = (props) => {
  return (
    <TableCellContentWrap>
      <Fieldset aria-labelledby={props.headerId}>
        {props.options.map((option) => (
          <Label type={option.labelType} key={option.value}>
            <Input
              type="radio"
              value={option.value}
              checked={option.checked}
              name={props.name}
              invalid={props.invalid}
              required={props.required}
              aria-invalid={props.invalid}
              aria-describedby={props.describedBy}
              onChange={() => props.handleOnChangeValue(props, option.value)}
            />
            {option.title}
          </Label>
        ))}
      </Fieldset>
      {props.showDeleteButton && <DeleteCellValueButton {...props} />}
    </TableCellContentWrap>
  );
};

RadioCell.propTypes = {
  /** The id that is on the corresponding header to the cell */
  headerId: PropTypes.string,
  /** Renders one input for each option */
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.bool,
        PropTypes.number
      ]),
      labelType: PropTypes.string,
      checked: PropTypes.bool,
      invalid: PropTypes.bool,
      title: PropTypes.string
    })
  ),
  /** If the field is required */
  required: PropTypes.bool,
  /** An id that refers to a node that has a description for the field. Is important for a11y */
  describedBy: PropTypes.string,
  /** Whether to show the delete value button */
  showDeleteButton: PropTypes.bool,
  /** Gets put on each input to link them as one fieldset */
  name: PropTypes.string,
  /** If the field is disabled */
  disabled: PropTypes.bool
};

export default RadioCell;
