import React, { Component } from "react";
import { Input, Label } from "..";
import RadioSetInput from "../../../plugin/components/FeatureDetailsComponent/subcomponents/Inputs/RadioSetInput";
import {
  PropertySearchCheckboxWrap,
  PropertySearchSelectionWrapper,
  SideBarContentWrapper,
  SideBarDropDownContainer,
  SideBarDropDownWrapper,
  SideBarOrgControllerContainer,
  SideBarOrgControllerWrapper,
  SideBarPropertySelectorContainer,
  SideBarPropertySelectorWrapper
} from "../../layout";
import SearchCombobox from "../SearchCombobox/SearchCombobox";
import SidebarControllerButton from "./subcomponents/SidebarControllerButton";
import SidebarControllerMenu from "./subcomponents/SidebarControllerMenu";
import SidebarControllerMenuButton from "./subcomponents/SidebarControllerMenuButton";
import SidebarHomeButton from "./subcomponents/SidebarHomeButton";
import SidebarList from "./subcomponents/SidebarList";
import SidebarCloseButton from "./subcomponents/SidebarCloseButton";

export default class SidebarComponent extends Component {
  getController = () => {
    const { controller, onClick } = this.props;
    if (!controller) return null;
    const { logoTitle, description, label, url, submenu } = controller;
    return (
      <SideBarOrgControllerWrapper>
        <SideBarOrgControllerContainer>
          <SidebarControllerButton
            logoTitle={logoTitle}
            description={description}
            url={url}
            label={label}
            onClick={onClick}
          />
          <SidebarControllerMenuButton {...submenu} />
        </SideBarOrgControllerContainer>
        <SidebarControllerMenu {...submenu} />
      </SideBarOrgControllerWrapper>
    );
  };

  getSidebarCloseButton = () => {
    const { onClick, visible, description } = this.props.closeButton;
    if (!visible) {
      return null;
    }
    return <SidebarCloseButton onClick={onClick} description={description} />;
  };

  getPropertySearchSelector = () => {
    const { propertySelector } = this.props;
    if (!propertySelector) return null;
    const { search, dropdown } = propertySelector;
    if (
      !search ||
      !search.options ||
      search.options.length < 2 ||
      !dropdown ||
      !dropdown.open
    )
      return null;
    const { searchByLabel, options, onChangeSearchBy, selectedSearchType } =
      search;
    return (
      <PropertySearchSelectionWrapper>
        <RadioSetInput
          label={searchByLabel}
          options={options}
          value={selectedSearchType}
          attribute={"search-by"}
          handleUpdateValue={onChangeSearchBy}
          isValid={true}
        />
      </PropertySearchSelectionWrapper>
    );
  };
  getPropertySearchInput = () => {
    const { propertySelector } = this.props;
    if (
      !propertySelector ||
      !propertySelector.search ||
      !propertySelector.dropdown ||
      !propertySelector.dropdown.open
    )
      return null;
    const {
      search: {
        searchTerm,
        updateSearchTerm,
        suggestions,
        selectedSuggestion,
        clearSearchTerm,
        showSuggestions,
        onSelectSuggestion,
        searchInputLabel,
        zoom
      }
    } = propertySelector;
    return (
      <>
        <SearchCombobox
          id={"property-search-input"}
          searchTerm={searchTerm}
          updateSearchTerm={updateSearchTerm}
          suggestions={suggestions}
          selectedSuggestion={selectedSuggestion}
          searchInputDisabled={false}
          clearSearchTerm={clearSearchTerm}
          showSuggestions={showSuggestions}
          onSelectSuggestion={onSelectSuggestion}
          ariaLabel={searchInputLabel}
          ariaExpanded={showSuggestions}
        />
        {zoom && (
          <PropertySearchCheckboxWrap>
            <Input
              id={"display-results-map"}
              type={"checkbox"}
              checked={zoom.checked}
              onChange={zoom.onChange}
            />
            <Label htmlFor={"display-results-map"}>{zoom.label}</Label>
          </PropertySearchCheckboxWrap>
        )}
      </>
    );
  };

  getPropertyDropdown = () => {
    const { propertySelector } = this.props;
    if (!propertySelector || !propertySelector.dropdown) return null;
    const {
      dropdown: { open, lists = [] }
    } = propertySelector;
    return (
      <SideBarDropDownWrapper
        data-name={"SideBarDropDownWrapper"}
        type={"propertyListing"}
        closed={!open}
      >
        {open ? (
          <SideBarDropDownContainer
            data-name={"SideBarDropDownContainer"}
            type={"propertyListing"}
          >
            {this.getPropertySearchSelector()}
            {this.getPropertySearchInput()}
            {lists.map((list, i) => (
              <SidebarList
                key={`property-dropdown-list-${i}`}
                {...list}
                type={"propertyListing"}
              />
            ))}
          </SideBarDropDownContainer>
        ) : null}
      </SideBarDropDownWrapper>
    );
  };

  getSideBarPropertySelector = () => {
    const { propertySelector } = this.props;
    if (!propertySelector) return null;
    return (
      <SideBarPropertySelectorWrapper>
        <SideBarPropertySelectorContainer
          data-name={"PropertySelectorContainer"}
        >
          <SidebarHomeButton {...propertySelector} />
          {this.getPropertyDropdown()}
        </SideBarPropertySelectorContainer>
      </SideBarPropertySelectorWrapper>
    );
  };
  getWorkflowList = () => {
    const { workflows, open } = this.props;
    if (!workflows) return null;
    return <SidebarList {...workflows} sidebarOpen={open} type={"workflows"} />;
  };
  render() {
    return (
      <SideBarContentWrapper>
        {this.getController()}
        {this.getSideBarPropertySelector()}
        {this.getWorkflowList()}
        {this.getSidebarCloseButton()}
      </SideBarContentWrapper>
    );
  }
}
