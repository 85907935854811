import { connect } from "react-redux";
import { getLabel, getOrgPaginationOptions, getWebMap } from "../../selectors";
import FeatureCreator from "./FeatureCreator";
import * as actions from "../../actions";

export default connect(
  (state) => ({
    labels: getLabel(state),
    orgPaginationOptions: getOrgPaginationOptions(state),
    webMap: getWebMap(state)
  }),
  actions
)(FeatureCreator);
