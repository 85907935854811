import React from "react";
import { PaginationPageNumber, Button, ButtonLabel } from "../..";
import PropTypes from "prop-types";
import { toLocale } from "../../../../../App/utils";

const PaginationPageButton = (props) => {
  return (
    <PaginationPageNumber
      key={props.page}
      data-name={"PaginationPageNumber"}
      active={props.active}
      buttonColor={props.color}
    >
      <Button
        type="button"
        styletype={"pagination"}
        data-name={"Button"}
        title={`Page number ${props.page}`}
        onClick={props.onClick}
        data-page={props.page}
      >
        <ButtonLabel data-name={"ButtonLabel"} type="pagination">
          {toLocale(props.page)}
        </ButtonLabel>
      </Button>
    </PaginationPageNumber>
  );
};

PaginationPageButton.propTypes = {
  /** The page number being rendered */
  page: PropTypes.number,
  /** Called on click of the button */
  onClick: PropTypes.func,
  /** The color of the button text */
  color: PropTypes.string,
  /** If the button is active */
  active: PropTypes.bool
};

export default PaginationPageButton;
