export const getLoadedUser = (state) => state.user.loadedUser;
export const getUserId = (state) => state.user.userId;
export const getEmailAddress = (state) => state.user.emailAddress;
export const getFirstName = (state) => state.user.firstName;
export const getLastName = (state) => state.user.lastName;
export const getUserOrgs = (state) => state.user.organisations;
export const getUserError = (state) => state.user.error;
export const getUserOrgRoles = (state) =>
  state.user.organisations ? state.user.organisations.roles : null;
export const getUserInvites = (state) => state.user.invitations;
export const getLoadingInvitations = (state) => state.user.isLoading;
export const getAcceptingInvitation = (state) => state.user.isAccepting;
export const getInvitation = (state) => state.user.invitation;
export const getUserWorkflowOrder = (state) => {
  const orgId =
    state.organisations.selected && state.organisations.selected.orgId
      ? state.organisations.selected.orgId
      : null;
  if (!state.user || !state.user.preferences) return [];
  else if (state.user.preferences.workflowOrder)
    return state.user.preferences.workflowOrder;
  else if (orgId && state.user.preferences[orgId])
    return state.user.preferences[orgId].workflowOrder || [];
  return [];
};
export const getUserDefaultPropertyWorkflow = (state, orgId) => {
  //eventually will deprecate preferences not keyed by orgId
  if (!state.user || !state.user.preferences) return null;
  else if (state.user.preferences.propertyDefaultWorkflow)
    return state.user.preferences.propertyDefaultWorkflow;
  else if (orgId && state.user.preferences[orgId])
    return state.user.preferences[orgId].propertyDefaultWorkflow || null;
  return null;
};

export const getUserDefaultPropertyGroupWorkflow = (state, orgId) => {
  //eventually will deprecate preferences not keyed by orgId
  if (!state.user || !state.user.preferences) return null;
  else if (state.user.preferences.propertyGroupDefaultWorkflow)
    return state.user.preferences.propertyGroupDefaultWorkflow;
  else if (orgId && state.user.preferences[orgId])
    return state.user.preferences[orgId].propertyGroupDefaultWorkflow || null;
  return null;
};

export const getUserPreferencesForOrg = (state, orgId) => {
  if (
    !state.user ||
    !state.user.preferences ||
    !orgId ||
    !state.user.preferences[orgId]
  )
    return null;
  return state.user.preferences[orgId];
};

export const getUpdatingUser = (state) => {
  return state.user.isUpdating;
};
