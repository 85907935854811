import { createLogic } from "redux-logic";
import {
  LOAD_MAP,
  SET_MAP_POSITION,
  REMOVE_LAYERS,
  SETUP_LAYER_LABELS,
  SETUP_LAYER_RENDERERS,
  FEATURELAYER_SUFFIX_POINT,
  FEATURELAYER_SUFFIX_LINE,
  UPDATED_LAYERS,
  SET_FEATURE_LAYERS,
  DEFAULT_WORLD_EXTENT,
  POINT_SIZE_VISUAL_VARIABLE,
  DELETEDDATE_QUERY_EXPRESSION,
  TEMP_LAYER_PREFIX,
  RESET_VISIBLE_LAYERS,
  QUERY_EXPRESSION,
  SET_BASEMAP,
  LOADED_MAP,
  DEFAULT_BASEMAP,
  SET_ELEVATION_ON,
  DEFAULT_ELEVATION_MODE,
  ELEVATION_OFFSET,
  HIGHLIGHT_COLOR,
  CLEAR_PROPERTY_DISPLAY_LAYERS,
  SET_PROPERTY_DISPLAY_LAYER_FILTERS,
  ABORT_ERROR_NAME,
  PROPERTY_DISPLAY_SUFFIX,
  START_PROPERTY_DISPLAY,
  SCALE_CENTROID_THRESHOLD,
  CANCEL_PROPERTY_DISPLAY,
  PROPERTY_POINT_RENDERER,
  LOAD_IMAGERY_DATES,
  CUSTOM_IMAGERY_LAYER_TYPE,
  CREATE_IMAGERY_TILE_LAYERS,
  AGBOX_API_URL,
  REMOVE_IMAGERY_TILE_LAYERS,
  MAP_VIEW_CLICK,
  URL_DELIMITER_ORG,
  URL_DELIMITER_ROLE,
  URL_DELIMITER_PROP,
  UPDATE_LAYER_LABELS,
  GENERIC_LABEL_SYMBOL,
  SEARCH_BY_LOCATION,
  ESRI_API_KEY,
  SATELLITE_BASEMAP,
  URL_DELIMITER_PROP_GROUP,
  URL_DELIMITER_WORKFLOW
} from "../constants";
import { isNullOrUndefined, parseSymbol } from "../App/utils";
import { navigate } from "@reach/router";
import MapView from "@arcgis/core/views/MapView";
import SceneView from "@arcgis/core/views/SceneView";
import Map from "@arcgis/core/Map";
import Compass from "@arcgis/core/widgets/Compass";
import Basemap from "@arcgis/core/Basemap";
import FeatureLayer from "@arcgis/core/layers/FeatureLayer";
import LabelClass from "@arcgis/core/layers/support/LabelClass";
import UniqueValueRenderer from "@arcgis/core/renderers/UniqueValueRenderer";
import SimpleRenderer from "@arcgis/core/renderers/SimpleRenderer";
import Extent from "@arcgis/core/geometry/Extent";
import BaseTileLayer from "@arcgis/core/layers/BaseTileLayer";
import esriConfig from "@arcgis/core/config";
import Field from "@arcgis/core/layers/support/Field";

const loadMapView = createLogic({
  type: LOAD_MAP,
  process(
    // @ts-ignore
    { globalActions, selectors, action, getState },
    dispatch,
    done
  ) {
    const state = getState();
    const { getMapViewExtent, getHas3D } = selectors;

    const extent = getMapViewExtent(state);
    const is3D = getHas3D(state);

    const { mapContainer, baseMap } = action.payload;

    const MapViewModule = is3D ? SceneView : MapView;

    esriConfig.apiKey = ESRI_API_KEY;
    const webMapItem = new Map({
      basemap: baseMap ? baseMap : SATELLITE_BASEMAP,
      ground: "world-elevation"
    });
    //listen to the adding of layers and if is one of our own poly layers, add tghe featureArea field to the layer
    webMapItem.layers.on("after-add", async ({ item }) => {
      if (
        item.title &&
        item.title.includes("Poly") &&
        item.url &&
        item.url.includes(AGBOX_API_URL)
      ) {
        await item.when(() => {
          item.fields.push(
            new Field({
              type: "double",
              name: "featureArea"
            })
          );
        });
      }
    });

    const mapView = new MapViewModule({
      map: webMapItem,
      container: mapContainer,
      extent,
      constraints: {
        minZoom: 5
      },
      spatialReference: {
        wkid: 102100
      }
    });

    if (is3D) {
      mapView.highlightOptions = {
        color: HIGHLIGHT_COLOR
      };
    }

    if (!is3D) {
      const compass = new Compass({
        view: mapView
      });
      mapView.ui.add(compass, "top-left");
    }

    const { loadedMap, loadedWebMap } = globalActions;
    mapView.ui.remove("attribution");

    dispatch(loadedMap(mapView));

    dispatch(loadedWebMap(webMapItem));

    const hideGroundLayers = () =>
      webMapItem.ground.layers.forEach((layer) => {
        layer.visible = false;
      });

    mapView.when(() => {
      hideGroundLayers();
    });

    done();
  }
});

const loadedMapLogic = createLogic({
  type: LOADED_MAP,
  async process({ globalActions, getState, selectors }, dispatch, done) {
    const { setBasemap } = globalActions;
    const { getOrganisationPreferences } = selectors;
    const preferences = getOrganisationPreferences(getState());
    if (!preferences) {
      dispatch(setBasemap(DEFAULT_BASEMAP[0]));
    }
    done();
  }
});

const setMapPositionLogic = createLogic({
  type: SET_MAP_POSITION,
  latest: true,
  process({ globalActions, selectors, getState, action }, dispatch, done) {
    const { loadingWebMap, setShowMap, setExtent } = globalActions;
    try {
      dispatch(loadingWebMap(true));

      const state = getState();
      const { getAbortSignal, getMapView, getMapViewExtent } = selectors;
      const extent = action.payload
        ? action.payload
        : getMapViewExtent(getState());
      if (!extent) return dispatch(setShowMap(false));
      dispatch(setExtent(extent));
      const { xmin, xmax, ymin, ymax, spatialReference } = extent;
      const mapView = getMapView(state);
      const isValidExtent =
        !isNullOrUndefined(xmin) &&
        !isNullOrUndefined(ymin) &&
        !isNullOrUndefined(xmax) &&
        !isNullOrUndefined(ymax);
      const targetExtent = isValidExtent
        ? new Extent({
            xmin: Number(xmin),
            xmax: Number(xmax),
            ymin: Number(ymin),
            ymax: Number(ymax),
            spatialReference: spatialReference
              ? spatialReference
              : {
                  wkid: 102100
                }
          })
        : new Extent({
            ...DEFAULT_WORLD_EXTENT,
            spatialReference: {
              wkid: 102100
            }
          });
      const isDefaultExtent = isValidExtent
        ? xmin === DEFAULT_WORLD_EXTENT.xmin &&
          xmax === DEFAULT_WORLD_EXTENT.xmax &&
          ymin === DEFAULT_WORLD_EXTENT.ymin &&
          ymax === DEFAULT_WORLD_EXTENT.ymax
        : true;
      if (!isDefaultExtent) {
        targetExtent.expand(1.2);
      }
      const goTo = isDefaultExtent
        ? { target: targetExtent, zoom: 3 }
        : { target: targetExtent };
      const signal = getAbortSignal(getState());
      mapView
        .goTo(goTo, { signal, pickClosestTarget: false })
        .catch((error) => {
          if (error.name !== ABORT_ERROR_NAME) {
            console.error(error);
          }
        });
      dispatch(setShowMap(true));
    } catch (e) {
      console.log(e);
    } finally {
      dispatch(loadingWebMap(false));
      done();
    }
  }
});

const setupLayerLabelsLogic = createLogic({
  type: SETUP_LAYER_LABELS,
  async process({ selectors, getState, globalActions }, dispatch, done) {
    try {
      const state = getState();
      const { getSelectedWorkflow, getWebMap, getOrganisationPreferences } =
        selectors;
      const { updateLayerLabels } = globalActions;
      const orgPreferences = getOrganisationPreferences(getState());
      const orgLayerLabels =
        orgPreferences && orgPreferences.layerLabelsJSON
          ? orgPreferences.layerLabelsJSON
          : {};
      const workflow = getSelectedWorkflow(state);
      const workflowLayerLabels =
        workflow &&
        workflow.hasOwnProperty("state") &&
        workflow.state.hasOwnProperty("layerLabels")
          ? workflow.state.layerLabels
          : {};

      const layerLabels = {
        ...orgLayerLabels,
        ...workflowLayerLabels
      };

      const webMap = getWebMap(state);
      const labelClasses = {};
      webMap.layers.forEach((layer) => {
        const { title } = layer;
        if (layerLabels.hasOwnProperty(title)) {
          const labelClass = LabelClass.fromJSON(layerLabels[title]);
          layer.labelingInfo = labelClass;
          labelClasses[title] = labelClass;
        }
      });
      dispatch(updateLayerLabels(labelClasses));
    } catch (e) {
      console.log(e);
    } finally {
      done();
    }
  }
});

const reorderLayers = createLogic({
  type: SETUP_LAYER_RENDERERS,
  async process({ selectors, getState }, dispatch, done) {
    try {
      const state = getState();
      const { getWebMap, getSelectedOrg, getLayerName } = selectors;
      const webMap = getWebMap(state);
      const selectedOrg = getSelectedOrg(state);
      const orgCommunityLayers =
        selectedOrg && selectedOrg.preferences && selectedOrg.preferences.layers
          ? selectedOrg.preferences.layers.filter(
              (layer) => layer.type && layer.type === "community"
            )
          : false;
      const layers = webMap.layers;
      let polyLayers = [];
      let pointLayers = [];
      let lineLayers = [];
      let propertyLayers = [];
      let communityLayers = [];
      layers
        .filter((layer) => {
          return layer && layer.title;
        })
        .forEach((layer) => {
          if (layer.title === getLayerName("property")) {
            propertyLayers.push(layer);
          } else if (layer.title.includes(FEATURELAYER_SUFFIX_POINT)) {
            pointLayers.push(layer);
          } else if (layer.title.includes(FEATURELAYER_SUFFIX_LINE)) {
            lineLayers.push(layer);
          } else if (
            orgCommunityLayers &&
            orgCommunityLayers.find((communityLayer) => {
              if (!communityLayer.url || !layer.url) return;
              const { url, layerId } = layer;
              return (
                `${layer.url}/${layer.layerId}` === communityLayer.url ||
                layer.url === communityLayer.url
              );
            })
          ) {
            communityLayers.push(layer);
          } else {
            polyLayers.push(layer);
          }
        });

      const allLayers = [
        ...communityLayers,
        ...propertyLayers,
        ...polyLayers,
        ...lineLayers,
        ...pointLayers
      ];

      allLayers.forEach((layer, index) => {
        webMap.reorder(layer, index);
      });
      done();
    } catch (e) {
      console.log(e);
    }
  }
});

const setDefinitionExpressionForLayers = createLogic({
  type: SETUP_LAYER_RENDERERS,
  async process({ selectors, getState }, dispatch, done) {
    try {
      const state = getState();
      const { getWebMap, getMapView } = selectors;
      const webMap = getWebMap(state);
      const mapView = getMapView(state);
      const layers = webMap.layers;
      layers.forEach(async (layer) => {
        const layerView = await mapView.whenLayerView(layer);
        const defExpression =
          layer.title && layer.title.indexOf("crop") !== -1
            ? QUERY_EXPRESSION
            : !isNullOrUndefined(layer.fields) &&
              layer.fields.find((field) => field.name === "deletedDate") !==
                undefined
            ? DELETEDDATE_QUERY_EXPRESSION
            : "1=1";
        if (!layerView) layer.definitionExpression = defExpression;
        else {
          layerView.filter = {
            where: defExpression
          };
        }
      });
    } catch (e) {
      console.log(e);
    } finally {
      done();
    }
  }
});

const setupLayerRenderersLogic = createLogic({
  type: SETUP_LAYER_RENDERERS,
  process({ selectors, getState, globalActions }, dispatch, done) {
    try {
      const state = getState();
      const {
        getSelectedWorkflow,
        getWebMap,
        getOrganisationPreferences,
        getPropertyGroupsData
      } = selectors;
      const { updateOrgRenderers } = globalActions;
      const orgPreferences = getOrganisationPreferences(state);
      const organisationLayerRenderers =
        orgPreferences && orgPreferences.layerRenderersJSON
          ? orgPreferences.layerRenderersJSON
          : {};

      const workflow = getSelectedWorkflow(state);
      const workflowLayerRenderers =
        workflow &&
        Object.prototype.hasOwnProperty.call(workflow, "state") &&
        Object.prototype.hasOwnProperty.call(workflow, "layerRenderers")
          ? workflow.state.layerRenderers
          : {};

      const propertyGroupData = getPropertyGroupsData(state);
      const propertyGroupLayerRenderers = propertyGroupData.renderer
        ? propertyGroupData.renderer
        : {};

      const orgLayerNames = Object.keys(organisationLayerRenderers);

      let layerRenderers = orgLayerNames.reduce((renderer, layerName) => {
        let layerRenderer = organisationLayerRenderers[layerName];
        if (propertyGroupLayerRenderers.hasOwnProperty(layerName)) {
          const propertyGroupRenderer = propertyGroupLayerRenderers[layerName];
          if (
            propertyGroupRenderer.type?.includes("unique") ||
            propertyGroupRenderer.hasOwnProperty("uniqueValueInfos")
          ) {
            layerRenderer.uniqueValueInfos =
              propertyGroupRenderer.uniqueValueInfos.reduce(
                (uniqueValueInfos, rendererInfo) => {
                  const existingUniqueValueInfoIndex =
                    uniqueValueInfos.findIndex(
                      (uniqueValueInfo) =>
                        uniqueValueInfo.value === rendererInfo.value
                    );
                  if (
                    existingUniqueValueInfoIndex > -1 &&
                    uniqueValueInfos[existingUniqueValueInfoIndex].label ===
                      rendererInfo.label
                  ) {
                    uniqueValueInfos[existingUniqueValueInfoIndex] =
                      rendererInfo;
                    return uniqueValueInfos;
                  } else {
                    return [...uniqueValueInfos, rendererInfo];
                  }
                },
                layerRenderer.uniqueValueInfos
              );
          }
        }

        return {
          ...renderer,
          [layerName]: layerRenderer
        };
      }, {});

      layerRenderers = {
        ...organisationLayerRenderers,
        ...workflowLayerRenderers
      };

      const webMap = getWebMap(state);
      const renderers = {};
      webMap.layers.forEach((layer) => {
        const { title } = layer;
        if (layerRenderers.hasOwnProperty(title)) {
          const renderer = layerRenderers[title];
          let parsedRenderer;
          if (renderer.type?.includes("unique")) {
            if (renderer.uniqueValueInfos) {
              renderer.uniqueValueInfos = renderer.uniqueValueInfos.map(
                (item) => ({
                  ...item,
                  symbol: parseSymbol(item.symbol)
                })
              );
            }
            if (renderer.defaultSymbol) {
              renderer.defaultSymbol = parseSymbol(renderer.defaultSymbol);
            }
            parsedRenderer = UniqueValueRenderer.fromJSON(renderer);
          } else if (renderer.type === "simple") {
            parsedRenderer = SimpleRenderer.fromJSON({
              ...renderer,
              symbol: parseSymbol(renderer.symbol)
            });
          } else return;
          layer.renderer = parsedRenderer;
          renderers[title] = parsedRenderer;
        }
        if (layerRenderers.visualVariables) {
          if (
            layerRenderers.visualVariables.hasOwnProperty(title) &&
            layer.renderer
          ) {
            layer.renderer.visualVariables = [
              layerRenderers.visualVariables[title]
            ];
          } else if (
            layer.title.includes(FEATURELAYER_SUFFIX_POINT) &&
            layerRenderers.visualVariables.default_points &&
            layer.renderer
          ) {
            layer.renderer.visualVariables = [
              layerRenderers.visualVariables.default_points
            ];
          }
        } else if (
          layer.title.includes(FEATURELAYER_SUFFIX_POINT) &&
          layer.renderer
        ) {
          layer.renderer.visualVariables = [POINT_SIZE_VISUAL_VARIABLE];
        }
      });

      dispatch(updateOrgRenderers(renderers));
    } catch (e) {
      console.log(e);
    } finally {
      done();
    }
  }
});

const updatedLayersLogic = createLogic({
  type: UPDATED_LAYERS,
  async process({ globalActions, action }, dispatch, done) {
    const { setupLayerRenderers, setupLayerLabels } = globalActions;
    dispatch(setupLayerLabels());
    dispatch(setupLayerRenderers());
    done();
  }
});

const removeLayerLogic = createLogic({
  type: REMOVE_LAYERS,
  process({ selectors, getState, action }, dispatch, done) {
    const state = getState();
    const { getWebMap } = selectors;
    const webMap = getWebMap(state);
    const { layers } = action.payload;
    webMap.removeMany(layers);
    done();
  }
});

const setFeatureLayersLogic = createLogic({
  latest: true,
  type: SET_FEATURE_LAYERS,
  process({ globalActions, getState, action, selectors }, dispatch, done) {
    try {
      const state = getState();
      const {
        getActiveWidget,
        getWebMap,
        getObjectIdField,
        getOrganisationPreferences
      } = selectors;
      const preferences = getOrganisationPreferences(state);
      const objectIdField = getObjectIdField(state);
      const activeWidget = getActiveWidget(state);
      const webMap = getWebMap(state);
      const { featureLayers } = action.payload;
      const existingLayers = webMap.layers.items;
      const communityLayers =
        preferences && preferences.layers
          ? preferences.layers.filter(
              (layer) => layer.type && layer.type === "community"
            )
          : false;

      let layersToRemove = [];
      let layersToKeep = [];

      existingLayers.forEach((layer) => {
        const layerExists =
          featureLayers &&
          featureLayers.find((featureLayer) => {
            return featureLayer.url === layer.url;
          });

        const isCommunityLayer = communityLayers
          ? communityLayers.find((communityLayer) => {
              if (!communityLayer.url || (!layer.url && !layer.urlTemplate))
                return;
              return (
                `${layer.url}/${layer.layerId}` === communityLayer.url ||
                layer.url === communityLayer.url ||
                layer.urlTemplate === communityLayer.url
              );
            })
          : false;

        const customImageryLayerInfo =
          preferences && preferences.layers
            ? preferences.layers.find(
                (layer) => layer.type === CUSTOM_IMAGERY_LAYER_TYPE
              )
            : false;

        if (
          layerExists ||
          isCommunityLayer ||
          layer.title.includes(PROPERTY_DISPLAY_SUFFIX) ||
          layer.title.includes(`${TEMP_LAYER_PREFIX}_FEATURE_SEARCH`) ||
          (customImageryLayerInfo &&
            layer.title.includes(customImageryLayerInfo.title))
        ) {
          layersToKeep.push(layer);
        } else if (
          activeWidget &&
          activeWidget === "measure" &&
          layer.title.includes(`${TEMP_LAYER_PREFIX}_MEASURE`)
        ) {
          layersToKeep.push(layer);
        } else {
          layersToRemove.push(layer);
        }
      });

      const layersToCreate = featureLayers
        ? featureLayers.filter((featureLayer) => {
            const regex = new RegExp(`(${featureLayer.url}.*)`, "i");
            return isNullOrUndefined(
              layersToKeep.find((layer) => regex.test(`${layer.url}`))
            );
          })
        : [];

      const layersToAdd = layersToCreate.map((layer) => {
        let visible = true;
        if (
          layer.title === "propertyPoly" &&
          preferences &&
          preferences.propertyGroupTilingEnabled &&
          (!window.location.pathname.includes(URL_DELIMITER_PROP) ||
            (window.location.pathname.includes(URL_DELIMITER_PROP_GROUP) &&
              !window.location.pathname.includes(URL_DELIMITER_WORKFLOW)))
        ) {
          visible = false;
        }

        const featureLayer = new FeatureLayer({
          ...layer,
          visible
        });

        featureLayer.when(() => {
          const existingOutFields = featureLayer.outFields
            ? featureLayer.outFields
            : [];
          const fields = featureLayer.fields ? featureLayer.fields : [];
          let outFields = fields.find((field) => field.name === "propId")
            ? [...existingOutFields, "propId"]
            : existingOutFields;
          if (outFields.indexOf(objectIdField) === -1) {
            outFields = [...outFields, objectIdField];
          }
          featureLayer.outFields = outFields;
          if (preferences && preferences.enable3D === true) {
            featureLayer.elevationInfo = {
              mode: DEFAULT_ELEVATION_MODE,
              offset: ELEVATION_OFFSET
            };
          }
        });

        return featureLayer;
      });

      webMap.removeMany(layersToRemove);
      webMap.addMany(layersToAdd);

      const { updatedLayers } = globalActions;
      dispatch(updatedLayers());
    } catch (e) {
      console.log(e);
    }
    done();
  }
});

const resetVisibleLayersLogic = createLogic({
  type: RESET_VISIBLE_LAYERS,
  async process(
    { globalActions, getState, action, selectors },
    dispatch,
    done
  ) {
    const state = getState();
    const { getWebMap, getObjectIdField, getOrganisationPreferences } =
      selectors;
    const objectIdField = getObjectIdField(state);
    const preferences = getOrganisationPreferences(state);
    const webMap = getWebMap(state);

    const communityLayers = preferences.layers
      ? preferences.layers.filter(
          (layer) => layer.type && layer.type === "community"
        )
      : [];

    const customImageryLayers = preferences.layers
      ? preferences.layers
          .filter(
            (layer) => layer.type && layer.type === CUSTOM_IMAGERY_LAYER_TYPE
          )
          .map(({ title }) => title)
      : [];

    //remove any feature layers that shouldn't stay on the map. Keep community and custom imagery layers
    const layersToRemove = webMap.layers.items.filter((layer) => {
      const { url, title, urlTemplate, layerId } = layer;
      if (title && customImageryLayers.includes(title.split("-")[0]))
        return false;
      else if (
        communityLayers.find(
          (communityLayer) =>
            communityLayer.url === url ||
            communityLayer.url === `${url}/${layerId}` ||
            communityLayer.url === urlTemplate
        )
      )
        return false;
      return true;
    });
    webMap.removeMany(layersToRemove);
    const { getDefaultVisibleLayers } = selectors;
    const visibleLayers = getDefaultVisibleLayers(getState());
    const layersToAdd = visibleLayers.map((layer) => {
      const featureLayer = new FeatureLayer({
        url: layer.url,
        title: layer.title
      });
      const existingOutFields = featureLayer.outFields
        ? featureLayer.outFields
        : [];
      featureLayer.outFields =
        existingOutFields.indexOf(objectIdField) !== -1
          ? [...existingOutFields, "propId"]
          : [...existingOutFields, "propId", objectIdField];
      return featureLayer;
    });

    webMap.addMany(layersToAdd);
    const { updatedLayers } = globalActions;
    dispatch(updatedLayers());
    done();
  }
});

const setBasemapLogic = createLogic({
  type: SET_BASEMAP,
  process({ selectors, getState, action, globalActions }, dispatch, done) {
    const { payload } = action;
    const { getWebMap } = selectors;
    const webMap = getWebMap(getState());
    if (
      !isNullOrUndefined(payload.id) &&
      payload.id !== false &&
      !isNullOrUndefined(webMap)
    ) {
      const newBasemap = Basemap.fromId(payload.id);
      webMap.basemap = newBasemap;
      const { setBasemapId, setAttribution } = globalActions;
      dispatch(setBasemapId(payload.id));
      if (payload.attribution) {
        dispatch(setAttribution(payload.attribution));
      }
    }
    done();
  }
});

const propertyLayerLabelsLogic = createLogic({
  type: UPDATE_LAYER_LABELS,
  warnTimeout: 0,
  process({ selectors, getState, action }, dispatch, done) {
    try {
      const state = getState();
      const { getPropertyLayers } = selectors;

      const propertyLayers = getPropertyLayers(state);
      const orgLabels = action.payload;

      const labelClass = new LabelClass({
        labelExpressionInfo: { expression: "$feature.title" },
        labelPlacement: "always-horizontal",
        symbol: {
          type: "text", // autocasts as new TextSymbol()
          color: "black",
          haloSize: 1,
          haloColor: "white",
          font: {
            // autocast as new Font()
            size: 16,
            family: "sans-serif",
            weight: "bold"
          }
        }
      });

      propertyLayers.items.forEach((item) => {
        item.labelingInfo = orgLabels[item.title]
          ? [orgLabels[item.title]]
          : [labelClass];
      });
      done();
    } catch (e) {
      console.log(e);
    }
  }
});

const setElevationLogic = createLogic({
  type: SET_ELEVATION_ON,
  process(
    // @ts-ignore
    { globalActions, selectors, action, getState },
    dispatch,
    done
  ) {
    const state = getState();

    const { payload } = action;
    const elevationOn = payload.elevationOn === true;

    const { getWebMap } = selectors;

    const webMap = getWebMap(state);
    webMap.ground.layers.forEach((layer) => {
      layer.visible = elevationOn;
    });

    done();
  }
});
const clearPropertyDisplayLayersLogic = createLogic({
  type: CLEAR_PROPERTY_DISPLAY_LAYERS,
  async process(
    // @ts-ignore
    { globalActions, selectors, action, getState },
    dispatch,
    done
  ) {
    const { getWebMap } = selectors;
    const webMap = getWebMap(getState());
    if (!webMap || !webMap.layers) return done();
    const propertyDisplayLayers = webMap.layers.items.filter(
      (layer) => layer.title && layer.title.includes(PROPERTY_DISPLAY_SUFFIX)
    );
    if (propertyDisplayLayers.length) {
      webMap.removeMany(propertyDisplayLayers);
    }
    done();
  }
});

const setPropertyDisplayLayerFiltersLogic = createLogic({
  type: SET_PROPERTY_DISPLAY_LAYER_FILTERS,
  async process(
    // @ts-ignore
    { globalActions, selectors, action, getState },
    dispatch,
    done
  ) {
    const {
      getWebMap,
      getMapView,
      getSearchPropertiesBy,
      getSelectedProperty,
      getSelectedPropertyGroup,
      getAvailableProperties
    } = selectors;
    const { searchGeometry } = action.payload;
    const webMap = getWebMap(getState());
    const mapView = getMapView(getState());
    const propertyDisplayLayers = webMap.layers.items.filter(
      (layer) => layer.title && layer.title.includes(PROPERTY_DISPLAY_SUFFIX)
    );
    if (propertyDisplayLayers.length) {
      const allowedPropertiesToDisplay = getAvailableProperties(getState()).map(
        (propertyItem) => propertyItem.objectId
      );
      let whereExp = "1=1";
      if (allowedPropertiesToDisplay.length < 1000) {
        whereExp = `objectId in (${allowedPropertiesToDisplay.join(",")})`;
      }

      if (
        getSearchPropertiesBy(getState()) === SEARCH_BY_LOCATION &&
        (getSelectedProperty(getState()) ||
          getSelectedPropertyGroup(getState()) ||
          !searchGeometry)
      ) {
        whereExp = "1=2";
      }
      const layerViews = await Promise.all(
        propertyDisplayLayers.map((layer) => mapView.whenLayerView(layer))
      );
      layerViews.forEach((layerView) => {
        layerView.filter = {
          geometry: searchGeometry,
          where: whereExp
        };
      });
    }
    done();
  }
});

const startPropertyDisplayLogic = createLogic({
  type: START_PROPERTY_DISPLAY,
  async process(
    // @ts-ignore
    { globalActions, selectors, action, getState, agBoxApiRequests },
    dispatch,
    done
  ) {
    const {
      getOrganisationPreferences,
      getSelectedOrgId,
      getSelectedPropertyGroup,
      getWebMap,
      getPropertiesSearchGeometry
    } = selectors;
    const { setPropertyDisplayLayerFilters } = globalActions;
    const orgPreferences = getOrganisationPreferences(getState());
    if (!orgPreferences || !orgPreferences.propertyGroupTilingEnabled)
      return done();
    const webMap = getWebMap(getState());
    const selectedPropertyGroup = getSelectedPropertyGroup(getState());
    const orgId = getSelectedOrgId(getState());

    const propertyLayer =
      webMap.layers.items.find((layer) => layer.title === "propertyPoly") || {};

    propertyLayer.visible = false;

    const layerUrl = selectedPropertyGroup
      ? `${AGBOX_API_URL}/dataSets/Property/organisations/${selectedPropertyGroup.orgId}/propertyGroups/${selectedPropertyGroup.groupId}/FeatureServer/0`
      : `${AGBOX_API_URL}/dataSets/Property/organisations/${orgId}/FeatureServer/0`;
    const { layerLabelsJSON, layerRenderersJSON } = orgPreferences;

    let labelingInfo = [];
    if (layerLabelsJSON && layerLabelsJSON.propertyPoly) {
      labelingInfo = [LabelClass.fromJSON(layerLabelsJSON.propertyPoly)];
    } else {
      labelingInfo = [
        {
          labelExpressionInfo: {
            expression: "$feature.title"
          },
          symbol: GENERIC_LABEL_SYMBOL
        }
      ];
    }

    let polygonRenderer = null;
    if (layerRenderersJSON && layerRenderersJSON.propertyPoly) {
      const rendererJSON = layerRenderersJSON.propertyPoly;
      const { type = "" } = rendererJSON;
      if (type.includes("unique"))
        polygonRenderer = UniqueValueRenderer.fromJSON(rendererJSON);
      else if (type === "simple")
        polygonRenderer = SimpleRenderer.fromJSON(rendererJSON);
    }
    const layers = [
      new FeatureLayer({
        url: layerUrl,
        title: `propertyPoly_${PROPERTY_DISPLAY_SUFFIX}`,
        renderer: polygonRenderer,
        labelingInfo,
        visible: true,
        labelsVisible: true,
        geometryType: "polygon",
        minScale: SCALE_CENTROID_THRESHOLD,
        maxScale: 0,
        outFields: "*"
      }),
      new FeatureLayer({
        url: layerUrl,
        title: `propertyPoly_${PROPERTY_DISPLAY_SUFFIX}_pins`,
        renderer: PROPERTY_POINT_RENDERER,
        labelingInfo,
        visible: true,
        labelsVisible: true,
        geometryType: "point",
        maxScale: SCALE_CENTROID_THRESHOLD,
        minScale: 0,
        customParameters: {
          returnGeometry: "false",
          returnCentroidsOnly: "true"
        },
        outFields: ["title", "officialName"]
      })
    ];
    webMap.addMany(layers);
    dispatch(
      setPropertyDisplayLayerFilters(getPropertiesSearchGeometry(getState()))
    );
    done();
  }
});

const cancelPropertyDisplayLogic = createLogic({
  type: CANCEL_PROPERTY_DISPLAY,
  async process(
    // @ts-ignore
    { globalActions, selectors, action, getState },
    dispatch,
    done
  ) {
    const { getWebMapLayers } = selectors;
    const layers = getWebMapLayers(getState());
    const propertyLayer = layers.find(
      (layer) => layer.title === "propertyPoly"
    );
    const { clearPropertyDisplayLayers } = globalActions;
    await dispatch(clearPropertyDisplayLayers());
    if (propertyLayer) propertyLayer.visible = true;
    done();
  }
});

const loadImageryDatesLogic = createLogic({
  type: LOAD_IMAGERY_DATES,
  async process(
    // @ts-ignore
    { globalActions, selectors, action, getState, agBoxApiRequests },
    dispatch,
    done
  ) {
    try {
      const {
        getOrganisationPreferences,
        getImageryDatesForProperty,
        getToken,
        getAbortSignal
      } = selectors;
      const { createImageryTileLayers, loadedImageryDates, setImageryLoading } =
        globalActions;
      const preferences = getOrganisationPreferences(getState());
      if (
        !preferences ||
        !preferences.layers ||
        !preferences.layers.length ||
        !preferences.layers.find(
          (layer) => layer.type === CUSTOM_IMAGERY_LAYER_TYPE
        )
      )
        return done();
      const { orgId, propId } = action.payload;
      dispatch(setImageryLoading(propId, true));
      let imageryDates = getImageryDatesForProperty(getState(), propId);

      if (!imageryDates) {
        const token = getToken(getState());
        const signal = getAbortSignal(getState());
        imageryDates = await agBoxApiRequests.requestImageryDates(
          orgId,
          propId,
          token,
          signal
        );
        dispatch(loadedImageryDates(orgId, propId, imageryDates));
      }
      dispatch(createImageryTileLayers(orgId, propId, imageryDates));
    } catch (e) {
      const { setImageryLoading } = globalActions;
      const { propId } = action.payload;
      if (process.env.NODE_ENV === "development") console.log(e);
      dispatch(setImageryLoading(propId, false));
    } finally {
      done();
    }
  }
});

const createImageryTileLayersLogic = createLogic({
  type: CREATE_IMAGERY_TILE_LAYERS,
  async process(
    // @ts-ignore
    { globalActions, selectors, action, getState, agBoxApiRequests },
    dispatch,
    done
  ) {
    const { orgId, propId, results } = action.payload;
    const { getOrganisationPreferences, getWebMap, getToken } = selectors;
    const { setImageryLoading } = globalActions;
    const preferences = getOrganisationPreferences(getState());
    const webMap = getWebMap(getState());
    const customImageryLayer =
      preferences && preferences.layers
        ? preferences.layers.find(
            (layer) => layer.type === CUSTOM_IMAGERY_LAYER_TYPE
          )
        : false;
    if (!customImageryLayer) return done();
    const { title } = customImageryLayer;
    const CustomTileLayer = BaseTileLayer.createSubclass({
      properties: {
        urlTemplate: null
      },
      getTileUrl: function (level, row, col) {
        return this.urlTemplate
          .replace(":level", level)
          .replace(":col", col)
          .replace(":row", row);
      },
      fetchTile: async function (level, row, col, options) {
        try {
          // call getTileUrl method to construct the Url for the image
          // for given level, row and column

          const url = this.getTileUrl(level, row, col);
          if (!url) return null;

          // request for the tile based on the url returned from getTileUrl() method.
          // the signal option ensures that obsolete requests are aborted.

          const result = await fetch(url, {
            headers: {
              "x-api-key": results.meta.token
            },
            method: "GET",
            signal: options && options.signal
          });
          if (result.status !== 200) return null;
          const response = await result.text();

          const image = new Image();
          image.src = `data:image/png;base64,${response}`;
          return image;
        } catch (e) {
          return null;
        }
      }
    });
    const imageryLayers = results.items.map(({ surveyedDate, s3Key }) => {
      return new CustomTileLayer({
        visible: false,
        title: `${title}-${surveyedDate}-${propId}`,
        maxScale: 50,
        urlTemplate: `${process.env.REACT_APP_IMAGERY_URL}/v2/organisations/${orgId}/properties/${propId}/${s3Key}/:level/:col/:row`,
        id: s3Key
      });
    });
    webMap.addMany(imageryLayers, 0);
    dispatch(setImageryLoading(propId, false));
    done();
  }
});

const removeImageryTileLayersLogic = createLogic({
  type: REMOVE_IMAGERY_TILE_LAYERS,
  process(
    // @ts-ignore
    { selectors, getState, action },
    dispatch,
    done
  ) {
    const { propId } = action.payload;
    const { getWebMap, getImageryDatesForProperty } = selectors;
    const webMap = getWebMap(getState());
    const imageryResults = getImageryDatesForProperty(getState(), propId);
    if (
      !imageryResults ||
      !imageryResults.items ||
      !imageryResults.items.length
    )
      return done();
    const imageryLayers = imageryResults.items
      .map(({ surveyedDate }) => {
        const dateLayer = webMap.layers.items.find(
          (layer) =>
            layer.title && layer.title.includes(`${surveyedDate}-${propId}`)
        );
        return dateLayer;
      })
      .filter((layer) => layer);
    webMap.removeMany(imageryLayers);
    done();
  }
});

const mapViewClickLogic = createLogic({
  type: MAP_VIEW_CLICK,
  async process(
    { selectors, globalActions, getState, action, agBoxApiRequests },
    dispatch,
    done
  ) {
    const event = action.payload;
    const {
      getMapView,
      getSelectedProperty,
      getSelectedOrg,
      getSelectedPropertyGroup
    } = selectors;
    const mapView = getMapView(getState());
    const propertySelected = getSelectedProperty(getState());
    const propertyGroupSelected = getSelectedPropertyGroup(getState());
    if (propertySelected || propertyGroupSelected) return done();

    const { results } = await mapView.hitTest(event);
    const propertyPoly = results.find(
      (result) =>
        result.graphic.geometry &&
        result.graphic.geometry.type === "polygon" &&
        result.graphic.attributes &&
        result.graphic.attributes.propId
    );
    if (propertyPoly) {
      const { propId, title } = propertyPoly.graphic.attributes;
      const org = getSelectedOrg(getState());
      const { orgId } = org;

      //go to that property
      navigate(
        `/${URL_DELIMITER_ORG}/${orgId}/${URL_DELIMITER_PROP}/${propId}`,
        {
          state: {
            docTitle: title
          }
        }
      );
    }

    done();
  }
});

export default [
  loadMapView,
  loadedMapLogic,
  setMapPositionLogic,
  setupLayerLabelsLogic,
  updatedLayersLogic,
  removeLayerLogic,
  setupLayerRenderersLogic,
  reorderLayers,
  setDefinitionExpressionForLayers,
  setFeatureLayersLogic,
  resetVisibleLayersLogic,
  setBasemapLogic,
  propertyLayerLabelsLogic,
  setElevationLogic,
  clearPropertyDisplayLayersLogic,
  setPropertyDisplayLayerFiltersLogic,
  startPropertyDisplayLogic,
  cancelPropertyDisplayLogic,
  loadImageryDatesLogic,
  createImageryTileLayersLogic,
  removeImageryTileLayersLogic,
  mapViewClickLogic
];
