import { createLogic } from "redux-logic";
import moment from "moment";
import {
  LOAD_APP,
  ABORT_REQUEST,
  SETUP_INTERCEPTORS,
  START_REQUEST,
  DO_REQUEST,
  SET_DOC_TITLE,
  AGBOX_DOC_TITLE,
  ABORT_ERROR_NAME,
  SEND_ACTION_LOG,
  EDIT_TYPE_CONFIG,
  AGBOX_API_URL,
  LINZ_API_KEY,
  LINZ_BASEMAP_URL
} from "../constants";
import esriConfig from "@arcgis/core/config";

const loadAppLogic = createLogic({
  type: LOAD_APP,
  process({ globalActions, selectors, getState }, dispatch, done) {
    const { checkAuthzeroAuthentication, setLoadingText } = globalActions;
    const { getLabel } = selectors;
    const labels = getLabel(getState());
    dispatch(setLoadingText(labels["CHECKING_LOGIN_STATUS_LABEL"]));
    dispatch(checkAuthzeroAuthentication());
    done();
  }
});

const abortRequestLogic = createLogic({
  type: ABORT_REQUEST,
  process({ selectors, globalActions, getState }, dispatch, done) {
    const { getAbortController } = selectors;

    const { abortSignalLoaded } = globalActions;
    const abortController = getAbortController(getState());
    abortController.abort();

    const newController = new AbortController();
    dispatch(abortSignalLoaded(newController));
    done();
  }
});

const setupInterceptorsLogic = createLogic({
  type: SETUP_INTERCEPTORS,
  warnTimeout: 0,
  process({ globalActions, getState, selectors, action }, dispatch, done) {
    const {
      getSelectedPropId,
      getUserId,
      getDefaultSpatialReference,
      getPropertySpatialReference
    } = selectors;
    const { abortSignalLoaded, appBusy } = globalActions;
    const { token } = action.payload;
    const controller = new AbortController();
    dispatch(abortSignalLoaded(controller));

    const dataSetsInterceptors = {
      urls: new RegExp(`(https://.*/dataSets/.*)`, "i"),
      before: (e) => {
        e.requestOptions.headers = {
          Authorization: `Bearer ${token}`
        };
        if (
          e.requestOptions.query.f === "json" &&
          Object.prototype.hasOwnProperty.call(
            e.requestOptions.query,
            "gdbVersion"
          )
        ) {
          e.requestOptions.query.offset = 0;
          e.requestOptions.query.limit = 1;
          e.requestOptions.query.returnGeometry = false;
        }

        if (e.requestOptions.query.resultType === "tile") {
          e.requestOptions.query.resultOffset = 0;
          e.requestOptions.query.resultRecordCount = 1200;
        }

        if (e.requestOptions.query.geometry) {
          e.requestOptions.query.geometry = JSON.stringify({
            ...JSON.parse(e.requestOptions.query.geometry),
            spatialReference: {
              latestWkid: 3857
            }
          });
        }
        if (
          e.url.includes(AGBOX_API_URL) &&
          e.requestOptions.query.returnCountOnly !== true &&
          !(
            e.requestOptions.query.f === "json" &&
            Object.prototype.hasOwnProperty.call(
              e.requestOptions.query,
              "gdbVersion"
            )
          )
        ) {
          const outSR =
            getPropertySpatialReference(getState()) ||
            getDefaultSpatialReference(getState());
          if (outSR) {
            e.requestOptions.query.returnArea = "true";
            e.requestOptions.query.returnAreaSR = outSR;
          }
        }
      }
    };

    /* NEW INTERCEPTOR FOR AWS DATASETS - NEEDS TO APPLY BEARER TOKEN */
    const dataSetsUrl = new RegExp(`(https://.*/dataSets/.*/applyEdits*)`, "i");
    const dataSetsApplyEditsInterceptor = {
      urls: [dataSetsUrl],
      before: (e) => {
        const state = getState();
        const propId = getSelectedPropId(state);
        dispatch(appBusy(true));
        const userId = getUserId(state);
        const timeStamp = moment();
        e.requestOptions.headers = {
          Authorization: `Bearer ${token}`
        };

        const { adds, updates, deletes } = e.requestOptions.query;
        if (adds) {
          const updatedAdds = JSON.parse(adds).map((item) => ({
            ...item,
            attributes: {
              ...item.attributes,
              createdUser: userId,
              createdDate: timeStamp,
              lastEditUser: userId,
              lastEditDate: timeStamp,
              propId
            }
          }));
          e.requestOptions.query.adds = JSON.stringify(updatedAdds);
        }

        if (updates) {
          const updatedUpdates = JSON.parse(updates).map((item) => ({
            ...item,
            attributes: {
              ...item.attributes,
              lastEditUser: userId,
              lastEditDate: timeStamp
            }
          }));
          e.requestOptions.query.updates = JSON.stringify(updatedUpdates);
        }

        if (deletes) {
          const updatedDeletes = JSON.parse(deletes).map((item) => ({
            ...item,
            attributes: {
              ...item.attributes,
              deletedDate: timeStamp
            }
          }));
          const updatesArray = updates !== null ? updates : [];
          e.requestOptions.query.updates = JSON.stringify([
            ...updatesArray,
            ...updatedDeletes
          ]);
          e.requestOptions.query.deletes = null;
        }
      },
      after: (e) => {
        const { appBusy } = globalActions;
        dispatch(appBusy(false));
      },
      error: (e) => {
        const { appBusy } = globalActions;
        dispatch(appBusy(false));
      }
    };

    const linzBasemapsInterceptors = {
      urls: new RegExp(`(https://${LINZ_BASEMAP_URL}/.*)`, "i"),
      before: (e) => {
        e.url += `?api=${LINZ_API_KEY}`;
      }
    };

    /* APPLY EDITS INTERCEPTOR MUST BE DECLARED BEFORE THE OTHER DATASETS INTERCEPTOR OTHERWISE WILL PICKUP THOSE REQUESTS */
    esriConfig.request.interceptors.push(
      ...[
        dataSetsApplyEditsInterceptor,
        dataSetsInterceptors,
        linzBasemapsInterceptors
      ]
    );
    done();
  }
});

const startRequestLogic = createLogic({
  type: START_REQUEST,
  async process(
    { selectors, globalActions, getState, action, agBoxApiRequests },
    dispatch,
    done
  ) {
    const { getLabel } = selectors;
    const { requestName, params } = action.payload;
    const { doRequest, setRequestError } = globalActions;
    const requestMethod = agBoxApiRequests[requestName];
    if (requestMethod) {
      dispatch(doRequest(requestName, requestMethod, params));
    } else {
      const { ERROR_FINDING_REQUEST_LABEL } = getLabel(getState());
      dispatch(setRequestError(requestName, ERROR_FINDING_REQUEST_LABEL));
    }
    done();
  }
});

const doRequestLogic = createLogic({
  type: DO_REQUEST,
  async process(
    { selectors, globalActions, getState, action, agBoxApiRequests },
    dispatch,
    done
  ) {
    const { requestName, requestMethod, params } = action.payload;
    try {
      const { completedRequest } = globalActions;
      const results = await requestMethod(...params);
      dispatch(completedRequest(requestName, results));
      done();
    } catch (e) {
      const { setRequestError, completedRequest } = globalActions;
      if (!e.name || e.name !== ABORT_ERROR_NAME) {
        dispatch(setRequestError(requestName, e.message));
      } else {
        dispatch(completedRequest(requestName, null));
      }

      done();
    }
  }
});

const setDocTitleLogic = createLogic({
  type: SET_DOC_TITLE,
  process({ action }, dispatch, done) {
    const { docTitle } = action.payload;
    document.title = `${docTitle}${AGBOX_DOC_TITLE}`;
    done();
  }
});

const sendActionLogLogic = createLogic({
  type: SEND_ACTION_LOG,
  async process(
    { globalActions, agBoxApiRequests, getState, action, selectors },
    dispatch,
    done
  ) {
    try {
      const state = getState();
      const { getToken, getAbortSignal, getUserId } = selectors;
      const token = getToken(state);
      const userId = getUserId(state);
      const signal = getAbortSignal(getState());
      const { orgId, actionType, data } = action.payload;

      const { createActionLog } = agBoxApiRequests;

      await createActionLog(
        JSON.stringify({
          action: actionType,
          orgId,
          userId,
          editType: EDIT_TYPE_CONFIG,
          timestamp: moment(),
          data: JSON.stringify(data)
        }),
        token,
        signal
      );
      done();
    } catch (e) {
      if (e.name && e.name === ABORT_ERROR_NAME) return done();
      if (process.env.NODE_ENV === "development") console.log(e);
      done();
    }
  }
});

export default [
  loadAppLogic,
  abortRequestLogic,
  setupInterceptorsLogic,
  startRequestLogic,
  doRequestLogic,
  setDocTitleLogic,
  sendActionLogLogic
];
