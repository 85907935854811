import React from "react";
import { Input, InputContainer, Label } from "../../../../../core/components";
import RequiredSymbol from "../RequiredSymbol";
import PropTypes from "prop-types";
import {
  FormItemFieldContainer,
  FormItemUnitContainer,
  FormItemWrapper
} from "../../..";

const StringInput = (props) => {
  function onChange(e) {
    const { handleUpdateValue, attribute } = props;
    handleUpdateValue(attribute, e.target.value);
  }
  return (
    <FormItemWrapper>
      <FormItemFieldContainer>
        <Label
          htmlFor={`${props.attribute}-input`}
          type={!props.isValid ? "formError" : ""}
          styletype="form-label"
        >
          {props.label}
        </Label>
        <InputContainer>
          <Input
            maxLength={props.maxlength}
            minLength={props.minLength}
            id={`${props.attribute}-input`}
            pattern={props.pattern}
            type={"text"}
            value={props.value}
            placeholder={props.placeholder}
            onChange={onChange}
            invalid={!props.isValid}
            disabled={props.disabled}
            required={props.required}
            aria-invalid={!props.isValid}
            aria-describedby={
              props.helpText
                ? `InputWrapper-${props.attribute}-help-text`
                : null
            }
            readOnly={props.readonly === true}
          />
          {!props.isValid && props.required && <RequiredSymbol />}
        </InputContainer>
      </FormItemFieldContainer>
      {props.unit && (
        <FormItemUnitContainer>
          <Label>{props.unit}</Label>
        </FormItemUnitContainer>
      )}
    </FormItemWrapper>
  );
};

StringInput.propTypes = {
  attribute: PropTypes.string,
  isValid: PropTypes.bool,
  label: PropTypes.string,
  maxlength: PropTypes.number,
  minlength: PropTypes.number,
  pattern: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(RegExp)
  ]),
  value: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  handleUpdateValue: PropTypes.func,
  required: PropTypes.bool,
  unit: PropTypes.string,
  readonly: PropTypes.bool
};

export default StringInput;
