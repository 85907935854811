import React, { Component } from "react";
import {
  roundNumber,
  projectGeometries,
  decodeComputedString,
  isNullOrUndefined
} from "../../../App/utils";
import { NotificationManager } from "react-notifications";
import {
  GENERIC_LABEL_SYMBOL,
  NZTM_MIN_Y,
  LAT_MIN,
  NZTM_MIN_X,
  LONG_MIN,
  NZTM_MAX_Y,
  LAT_MAX,
  NZTM_MAX_X,
  LONG_MAX,
  DEFAULT_AREA_SELECTION_SYMBOL,
  TEMP_LAYER_PREFIX,
  UNIT_METERS,
  FEATURE_AREA_SEARCH,
  DEFAULT_WKID
} from "../../../constants";
import {
  LocationSearchForm,
  LocationSearchFormGroup
} from "../../../AgBoxUIKit/plugin/components";
import {
  Fieldset,
  Legend,
  Input,
  InputGroup,
  Label,
  Button
} from "../../../AgBoxUIKit/core/components";
import DropDown from "../../../AgBoxUIKit/core/components/FormComponents/DropDown";
import {
  SearchAreaContainer,
  SearchInputWrapper
} from "../../../AgBoxUIKit/plugin/layout";
import { ToolTip } from "../../../AgBoxUIKit/plugin";
import { Icon } from "../../../AgBoxUIKit/core";
import { defaultTheme } from "../../../AgBoxUIKit";
import DrawingTool from "../../DrawingTool";
import GraphicsLayer from "@arcgis/core/layers/GraphicsLayer";
import Graphic from "@arcgis/core/Graphic";
import {
  buffer,
  planarLength,
  union
} from "@arcgis/core/geometry/geometryEngine";
import SketchViewModel from "@arcgis/core/widgets/Sketch/SketchViewModel";
import Polygon from "@arcgis/core/geometry/Polygon";
import Polyline from "@arcgis/core/geometry/Polyline";
import Point from "@arcgis/core/geometry/Point";
import SpatialReference from "@arcgis/core/geometry/SpatialReference";

export default class LocationSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchGeometries: [],
      searchCircleLayer: null,
      locationRadius: 100,
      locationUnit: "meters",
      locationPoint: null,
      locationCenter: {
        lat: "",
        long: ""
      },
      isSearching: false,
      locationSpatialReference: null,
      locationMouseMove: null,
      locationMouseMoveCoords: [],
      locationMouseLeave: null,
      sketchTool: null,
      hitTestClickFunction: null
    };
    this.searchController = new AbortController();
    this.geometryController = new AbortController();
    this.locationTimer = null;
    this.mouseMoveTimer = null;
  }

  componentDidMount() {
    this.setupSearch();
  }

  componentWillUnmount() {
    this.handleUnloadSearch();
  }

  setupSearch = () => {
    const { webMap, mapView, orgPreferences, addFeatureSearchClickHandler } =
      this.props;
    const searchCircleLayer = new GraphicsLayer({
      title: `${TEMP_LAYER_PREFIX}_FEATURE_SEARCH_LOCATION`
    });
    const searchGraphicsLayer = new GraphicsLayer({
      title: `${TEMP_LAYER_PREFIX}_FEATURE_SEARCH`
    });
    webMap.addMany([searchCircleLayer, searchGraphicsLayer]);

    const locationMouseMove = mapView.on(
      "pointer-move",
      this.handleMouseMoveCoord
    );

    const locationMouseLeave = mapView.on(
      "pointer-leave",
      this.handleMouseCoordLeave
    );

    const searchClickHandler = FEATURE_AREA_SEARCH;
    const { units } = orgPreferences;

    const distanceUnit =
      units && units.distanceUnits && units.distanceUnits.length > 0
        ? units.distanceUnits[0].unit
        : UNIT_METERS;

    addFeatureSearchClickHandler(searchClickHandler);

    const hitTestClickFunction = mapView.on(
      "click",
      this.handleClickSearchCircle
    );

    const searchCircleSketchTool = new SketchViewModel({
      layer: searchCircleLayer,
      view: mapView,
      updateOnGraphicClick: false,
      defaultUpdateOptions: {
        tool: "transform",
        toggleToolOnClick: false,
        preserveAspectRatio: true,
        enableZ: false
      }
    });

    searchCircleSketchTool.on("update", this.handleUpdateSketch);
    const spatialReferenceOptions = this.locationSpatialReferenceOptions();
    this.setState({
      locationUnit: distanceUnit,
      locationSpatialReference: spatialReferenceOptions.length
        ? spatialReferenceOptions[0].value
        : null,
      locationMouseMove,
      searchClickHandler,
      hitTestClickFunction,
      locationMouseLeave,
      searchCircleLayer,
      searchGraphicsLayer,
      searchCircleSketchTool,
      drawingToolSettings: {
        layer: searchGraphicsLayer,
        canUpdate: false,
        onComplete: this.handleOnCompleteSketch,
        onUpdate: this.handleUpdateSketch,
        onSketchToolCreate: this.onSketchToolCreate,
        showResetButton: false,
        watchStates: ["complete", "active"],
        tools: [
          {
            param: "point"
          }
        ]
      }
    });
  };

  handleClickSearchCircle = async (e) => {
    const { mapView } = this.props;
    const { searchCircleSketchTool, searchCircleLayer } = this.state;
    if (searchCircleLayer.graphics.items.length <= 0) return;
    const response = await mapView.hitTest(e);
    const result = response.results.filter((item) => {
      return item.layer && item.layer.title === searchCircleLayer.title;
    })[0];
    if (result && searchCircleSketchTool)
      searchCircleSketchTool.update(result.graphic);
  };

  handleClearSearch = () => {
    const { onSearchCleared } = this.props;
    this.setState({ searchGeometries: [], isSearching: false });
    if (!onSearchCleared) return false;
    return onSearchCleared();
  };

  handleOnSearchStart = (geometry) => {
    const { onSearchStart } = this.props;
    if (!onSearchStart) return false;
    return onSearchStart({ geometry });
  };
  onSketchToolCreate = (sketchTool) => {
    this.setState({
      sketchTool
    });
  };
  getLanguageLabel = (stringConstant, data) => {
    const { labels } = this.props;
    const label = labels[stringConstant];
    if (!label) return stringConstant;
    return decodeComputedString(label, data);
  };
  projectSpatialReference = async (geometry, spatialReference) => {
    const { locationSpatialReference } = this.state;
    const { mapView } = this.props;

    const spatialReferenceOutput =
      spatialReference || locationSpatialReference || DEFAULT_WKID;

    const { outGeometries, warning, error } = await projectGeometries(
      [geometry],
      spatialReferenceOutput,
      mapView.spatialReference.wkid
    );

    if (warning) {
      NotificationManager.warning(
        `${this.getLanguageLabel(
          "SPATIAL_REFERENCE_CALC_MESSAGE_LABEL",
          warning
        )}`,
        this.getLanguageLabel("UNABLE_TO_PROJECT_LABEL"),
        0
      );
    }
    if (error) {
      throw new Error(error.message);
    }
    return outGeometries[0];
  };

  projectToSpatialReference = async (geometries) => {
    if (!geometries || geometries.length === 0) return [];

    const { orgSpatialReference, propertySpatialReference } = this.props;

    const { outGeometries, warning, error } = await projectGeometries(
      geometries,
      propertySpatialReference,
      orgSpatialReference
    );

    if (warning) {
      NotificationManager.warning(
        `${this.getLanguageLabel(warning.message)}.\n${this.getLanguageLabel(
          "SPATIAL_REFERENCE_CALC_MESSAGE_LABEL",
          warning
        )}`,
        this.getLanguageLabel("UNABLE_TO_PROJECT_LABEL"),
        2000
      );
    } else if (error) {
      NotificationManager.error(
        this.getLanguageLabel(error.message),
        this.getLanguageLabel("ERROR_PROJECTING_SPATIAL_REFERENCE_LABEL"),
        0
      );
    }

    return outGeometries;
  };

  recalculateRadius = async (geometry) => {
    const centerPoint = [geometry.centroid.x, geometry.centroid.y];
    const outerPoint = geometry.rings[0][0];
    const radiusLine = new Polyline({
      paths: [centerPoint, outerPoint],
      spatialReference: geometry.spatialReference
    });
    const { locationUnit } = this.state;
    const projectedGeometry = await this.projectToSpatialReference([
      radiusLine
    ]);
    const radius = planarLength(projectedGeometry[0], locationUnit);
    return radius;
  };

  handleExecuteSearch = (geometry) => {
    const { searchGeometries } = this.state;
    const geometriesToSearch = geometry ? [geometry] : [...searchGeometries];
    this.setState({ searchGeometries: geometriesToSearch });
    if (geometriesToSearch.length === 0) return this.handleClearSearch();
    const geometriesToSearchUnion = union(geometriesToSearch);
    geometriesToSearchUnion.spatialReference.latestWkid = 3857;
    this.handleOnSearchStart(geometriesToSearchUnion);
  };

  handleUpdateSketch = async (event) => {
    const { graphics, toolEventInfo } = event;
    const { geometry } = graphics[0];
    if (
      toolEventInfo &&
      (toolEventInfo.type === "move-stop" ||
        toolEventInfo.type === "scale-stop")
    ) {
      this.searchController.abort();
      this.searchController = new AbortController();
      const projectedSearchGeometry = await this.projectSpatialReference(
        geometry
      );
      const projectedCentroid = await this.projectSpatialReference(
        geometry.centroid
      );
      const recreatedPoint = new Point({
        ...projectedCentroid
      });
      const { x, y, latitude, longitude } = recreatedPoint;
      const latValue = latitude ? latitude : y;
      const longValue = longitude ? longitude : x;
      this.setState({
        locationCenter: {
          lat: roundNumber(latValue, 2),
          long: roundNumber(longValue, 2)
        }
      });
      if (toolEventInfo.type === "scale-stop") {
        const radius = this.recalculateRadius({
          ...projectedSearchGeometry,
          centroid: projectedCentroid
        });
        this.setState({
          locationRadius: roundNumber(radius, 2)
        });
      }
      this.handleExecuteSearch(geometry);
    }
  };

  handleUpdateMouseCoordLabelGeometry = (e) => {
    const { mapView } = this.props;
    const { searchGraphicsLayer } = this.state;
    if (!searchGraphicsLayer) return false;
    const point = mapView.toMap({
      x: e.x,
      y: e.y
    });

    const labelGraphic = searchGraphicsLayer.graphics.items[0];
    if (!labelGraphic) return false;
    if (labelGraphic) {
      labelGraphic.geometry = point;
    }
  };

  handleMouseCoordLeave = () => {
    if (this.mouseMoveTimer) {
      clearTimeout(this.mouseMoveTimer);
      this.mouseMoveTimer = null;
    }
    this.mouseMoveTimer = null;
    this.geometryController.abort();
    this.geometryController = new AbortController();
    const { searchGraphicsLayer } = this.state;
    if (searchGraphicsLayer) searchGraphicsLayer.removeAll();
  };

  handleProjectedSearchCircle = async (geometry) => {
    const { locationRadius } = this.state;
    const projectedPoint = await this.projectSpatialReference(geometry);
    const locationBufferDistance = locationRadius > 0.0 ? locationRadius : 0.01;
    const searchGeometry = buffer(
      projectedPoint,
      locationBufferDistance,
      this.state.locationUnit
    );
    return searchGeometry;
  };

  handleUpdateMouseCoordLabel = (coords, point) => {
    const { searchGraphicsLayer } = this.state;
    const labelGraphic = searchGraphicsLayer.graphics.items[0];
    if (labelGraphic) {
      const symbol = labelGraphic.symbol.clone();
      symbol.text = coords.toString().replace(",", ", ");
      labelGraphic.symbol = symbol;
      labelGraphic.geometry = point;
    } else {
      const labelGraphic = new Graphic({
        geometry: point,
        symbol: {
          ...GENERIC_LABEL_SYMBOL,
          text: coords.toString().replace(",", ", "),
          xoffset: 20,
          yoffset: 20
        },
        visible: true
      });
      searchGraphicsLayer.add(labelGraphic);
    }
    searchGraphicsLayer.visible = true;
  };
  handleUnloadSearch = () => {
    const { removeFeatureSearchClickHandler, webMap } = this.props;
    const {
      searchClickHandler,
      locationMouseMove,
      locationMouseLeave,
      searchGraphicsLayer,
      searchCircleLayer,
      hitTestClickFunction
    } = this.state;
    removeFeatureSearchClickHandler(searchClickHandler);
    if (locationMouseMove) locationMouseMove.remove();
    if (locationMouseLeave) locationMouseLeave.remove();
    if (hitTestClickFunction) hitTestClickFunction.remove();
    if (searchGraphicsLayer) {
      webMap.remove(searchGraphicsLayer);
    }
    if (searchCircleLayer) {
      webMap.remove(searchCircleLayer);
    }
  };
  getMinLatValue = () => {
    const { locationSpatialReference } = this.state;
    return locationSpatialReference === 2193 ? NZTM_MIN_Y : LAT_MIN;
  };

  getMinLongValue = () => {
    const { locationSpatialReference } = this.state;
    return locationSpatialReference === 2193 ? NZTM_MIN_X : LONG_MIN;
  };

  getMaxLatValue = () => {
    const { locationSpatialReference } = this.state;
    return locationSpatialReference === 2193 ? NZTM_MAX_Y : LAT_MAX;
  };

  getMaxLongValue = () => {
    const { locationSpatialReference } = this.state;
    return locationSpatialReference === 2193 ? NZTM_MAX_X : LONG_MAX;
  };

  isValidLong = (value) => {
    return (
      value !== "" &&
      value >= this.getMinLongValue() &&
      value <= this.getMaxLongValue()
    );
  };

  isValidLat = (value) => {
    return (
      value !== "" &&
      value >= this.getMinLatValue() &&
      value <= this.getMaxLatValue()
    );
  };

  handleUpdateMouseCoords = async (e) => {
    const { mapView } = this.props;
    const latLong = mapView.toMap({
      x: e.x,
      y: e.y
    });
    const projectedPoint = await this.projectSpatialReference(latLong);
    const point = new Point(projectedPoint);
    const { x, y, latitude, longitude } = point;
    const value1 = latitude ? latitude : y;
    const value2 = longitude ? longitude : x;
    this.handleUpdateMouseCoordLabel(
      [roundNumber(value1, 2), roundNumber(value2, 2)],
      latLong
    );
  };

  handleMouseMoveCoord = (e) => {
    this.handleUpdateMouseCoordLabelGeometry(e);
    if (this.mouseMoveTimer) {
      clearInterval(this.mouseMoveTimer);
      this.mouseMoveTimer = null;
      this.geometryController.abort();
      this.geometryController = new AbortController();
    }
    this.mouseMoveTimer = setTimeout(
      () => this.handleUpdateMouseCoords(e),
      100
    );
  };

  handleCreateSearchCircle = async (searchCircleGeometry, point) => {
    const searchResults = [];
    this.handleSearchResult(searchResults, null, false);
    const { searchCircleLayer } = this.state;
    const { mapView } = this.props;
    searchCircleLayer.removeAll();
    let circle = searchCircleGeometry;

    if (
      searchCircleGeometry.spatialReference.wkid !==
      mapView.spatialReference.wkid
    ) {
      const reprojectedSearchCircle = await this.projectSpatialReference(
        searchCircleGeometry,
        mapView.spatialReference.wkid
      );
      const projectedCentroid = await this.projectSpatialReference(
        searchCircleGeometry.centroid
      );
      circle = new Polygon({
        rings: reprojectedSearchCircle.rings,
        spatialReference: reprojectedSearchCircle.spatialReference,
        centroid: point ? point : projectedCentroid
      });
    }

    const { items } = searchCircleLayer.graphics;
    if (items.length > 0) {
      items[0].geometry = circle;
    } else {
      const circleGraphic = new Graphic({
        attributes: {
          title: "feature search radius"
        },
        geometry: circle,
        symbol: DEFAULT_AREA_SELECTION_SYMBOL,
        visible: true
      });
      searchCircleLayer.add(circleGraphic);
    }
    this.handleExecuteSearch(circle);
  };

  handleSearchResult = (results, count = 0, passGeometries) => {
    let searchResults = results;
    const { onSearchResult } = this.props;
    const { searchGraphicsLayer, searchCircleLayer } = this.state;

    const searchGeometries =
      passGeometries === undefined ||
      passGeometries === null ||
      passGeometries === true
        ? searchGraphicsLayer &&
          searchGraphicsLayer.graphics &&
          searchCircleLayer &&
          searchCircleLayer.graphics
          ? [
              ...searchGraphicsLayer.graphics.items,
              ...searchCircleLayer.graphics.items
            ]
          : null
        : null;
    if (onSearchResult) onSearchResult(searchResults, searchGeometries, count);
    this.setState({
      isSearching: false
    });
  };

  handleUpdateLocationSpatialReference = async (e) => {
    if (!e || !e.currentTarget || !e.currentTarget.value) return;
    const { searchCircleLayer, locationCenter } = this.state;
    const newLocationSpatialReference = Number(e.currentTarget.value);
    this.setState(
      {
        locationSpatialReference: newLocationSpatialReference,
        isSearching: true
      },
      async () => {
        const { items } = searchCircleLayer.graphics; //project existing graphic into new spatial reference and update the values in the inputs
        if (items.length > 0) {
          const { geometry } = items[0];

          const updatedCircle = await this.handleProjectedSearchCircle(
            geometry.centroid
          );
          let longValue;
          let latValue;
          if (!updatedCircle) {
            latValue = locationCenter.lat;
            longValue = locationCenter.long;
          } else {
            const { latitude, longitude, x, y } = updatedCircle.centroid;
            latValue = latitude ? latitude : y;
            longValue = longitude ? longitude : x;
            latValue = roundNumber(latValue, 2);
            longValue = roundNumber(longValue, 2);
            this.handleCreateSearchCircle(updatedCircle);
          }

          this.setState({
            locationCenter: {
              lat: latValue,
              long: longValue
            },
            isSearching: false
          });
        }
      }
    );
  };

  locationSpatialReferenceOptions = () => {
    const { orgPreferences, propertySpatialReference } = this.props;
    const { defaultSpatialReference } = orgPreferences;
    const uniqueOptions = [
      ...new Set(
        [
          propertySpatialReference,
          defaultSpatialReference,
          DEFAULT_WKID
        ].filter((item) => !isNullOrUndefined(item) && item !== false)
      )
    ].map((item) => ({
      value: Number(item),
      title: item === DEFAULT_WKID ? "WGS84" : item
    }));
    return uniqueOptions;
  };

  handleLocationProjection = async (point) => {
    const { searchGraphicsLayer } = this.state;
    const projectedSearchGeometry = await this.handleProjectedSearchCircle(
      point
    );
    const { x, y, latitude, longitude } = projectedSearchGeometry.centroid;
    const latValue = latitude ? latitude : y;
    const longValue = longitude ? longitude : x;
    this.setState({
      locationCenter: {
        lat: roundNumber(latValue, 2),
        long: roundNumber(longValue, 2)
      }
    });
    this.handleCreateSearchCircle(projectedSearchGeometry, point);
    searchGraphicsLayer.removeAll();
  };

  handleRebufferLocationPoint = () => {
    const { searchCircleLayer } = this.state;
    this.setState({
      isSearching: true
    });
    const { items } = searchCircleLayer.graphics;
    if (items.length === 0) return;
    const centerPoint = items[0].geometry.centroid;
    this.handleLocationProjection(centerPoint);
  };

  handleUpdateLocationRadius = (e) => {
    if (!e || !e.currentTarget || !e.currentTarget.value) return;
    this.setState({
      locationRadius: e.currentTarget.value
    });
    if (this.locationTimer) {
      clearTimeout(this.locationTimer);
      this.locationTimer = null;
    }
    this.locationTimer = setTimeout(() => {
      this.searchController.abort();
      this.searchController = new AbortController();

      this.handleRebufferLocationPoint();
    }, 500);
  };

  handleUpdateLocationUnit = (e) => {
    if (!e || !e.target || !e.target.value) return;
    this.setState({
      locationUnit: e.target.value
    });
    if (this.locationTimer) {
      clearTimeout(this.locationTimer);
      this.locationTimer = null;
    }
    this.locationTimer = setTimeout(() => {
      this.searchController.abort();
      this.searchController = new AbortController();

      this.handleRebufferLocationPoint();
    }, 100);
  };

  handleSubmitLocationSearch = (e) => {
    if (!e) return;
    e.preventDefault();
    const { locationPoint } = this.state;
    if (locationPoint) {
      this.handleCreateSearchCircle(locationPoint);
    }
  };

  handleChangeLocationLatLong = async (lat, long) => {
    if (isNullOrUndefined(lat) || isNullOrUndefined(long)) return;
    this.searchController.abort();
    this.searchController = new AbortController();
    const { searchGraphicsLayer, locationSpatialReference } = this.state;
    const { mapView } = this.props;
    let point;
    if (locationSpatialReference === mapView.spatialReference.wkid) {
      point = new Point({
        latitude: lat,
        longitude: long,
        spatialReference: mapView.spatialReference
      });
    } else {
      const newPoint = new Point({
        longitude: long,
        latitude: lat,
        spatialReference: new SpatialReference({
          wkid: locationSpatialReference
        })
      });
      point = await this.projectSpatialReference(
        newPoint,
        mapView.spatialReference.wkid
      );
    }
    const projectedSearchGeometry = await this.handleProjectedSearchCircle(
      point
    );
    this.handleCreateSearchCircle(projectedSearchGeometry, point);
    searchGraphicsLayer.removeAll();
  };

  handleUpdateLocationLat = (e) => {
    if (!e || !e.currentTarget || !e.currentTarget.value) return;
    const value = Number(e.currentTarget.value);
    const { locationCenter } = this.state;
    this.setState({
      locationCenter: {
        ...locationCenter,
        lat: value
      }
    });
    if (this.locationTimer) {
      clearTimeout(this.locationTimer);
      this.locationTimer = null;
    }
    if (this.isValidLong(locationCenter.long) && this.isValidLat(value)) {
      this.locationTimer = setTimeout(() => {
        const latValue = value;
        const longValue = locationCenter.long;
        this.handleChangeLocationLatLong(latValue, longValue);
      }, 500);
    }
  };

  handleUpdateLocationLong = (e) => {
    if (!e || !e.currentTarget || !e.currentTarget.value) return;
    const value = Number(e.currentTarget.value);
    const { locationCenter } = this.state;
    this.setState({
      locationCenter: {
        ...locationCenter,
        long: value
      }
    });
    if (this.locationTimer) {
      clearTimeout(this.locationTimer);
      this.locationTimer = null;
    }
    if (this.isValidLat(locationCenter.lat) && this.isValidLong(value)) {
      this.locationTimer = setTimeout(() => {
        const longValue = value;
        const latValue = locationCenter.lat;
        this.handleChangeLocationLatLong(latValue, longValue);
      }, 800);
    }
  };

  handleOnCompleteSketch = (event) => {
    if (!event) return;
    const { state, graphic } = event;
    if (state && state === "complete" && graphic && graphic.geometry) {
      const { geometry } = graphic;
      this.handleLocationProjection(geometry);
    }
  };
  handleResetSketch = () => {
    const { searchGraphicsLayer, sketchTool, searchCircleLayer } = this.state;
    if (searchGraphicsLayer) searchGraphicsLayer.removeAll();
    if (searchCircleLayer) searchCircleLayer.removeAll();

    this.handleClearSearch();
    if (sketchTool) sketchTool.create("point");

    this.setState({
      locationCenter: {
        long: "",
        lat: ""
      }
    });
  };

  whenLoading = () => {
    const { loading } = this.props;
    return loading === true;
  };

  showInvalidCoordMessage = () => {
    const { locationCenter, searchCircleLayer, isSearching } = this.state;
    const { lat, long } = locationCenter;
    return (
      !isSearching &&
      !this.whenLoading() &&
      !isNullOrUndefined(searchCircleLayer) &&
      searchCircleLayer.graphics.items.length > 0 &&
      !this.isValidLong(long) &&
      !this.isValidLat(lat)
    );
  };
  searchForm = () => {
    const {
      locationRadius,
      locationUnit,
      locationCenter,
      locationSpatialReference,
      isSearching
    } = this.state;
    const { orgPreferences } = this.props;
    const { units } = orgPreferences;
    const distanceUnits =
      units && units.distanceUnits ? units.distanceUnits : ["meters"];

    return (
      <LocationSearchForm onSubmit={this.handleSubmitLocationSearch}>
        <Fieldset>
          <Legend appearsdisabled={this.whenLoading()}>
            {this.getLanguageLabel("SEARCH_RADIUS_LABEL")}
          </Legend>
          <LocationSearchFormGroup>
            <Input
              disabled={this.whenLoading()}
              aria-label="Search radius"
              id="search-radius-input"
              type="number"
              step="1"
              value={locationRadius}
              onChange={this.handleUpdateLocationRadius}
            />
            <InputGroup type="featureSearch" disabled={this.whenLoading()}>
              {distanceUnits.length > 1 ? (
                <DropDown
                  aria-label="Search radius unit"
                  options={distanceUnits.map((unit) => ({
                    value: unit.unit,
                    title: unit.shortname ? unit.shortname : unit.unit
                  }))}
                  value={locationUnit}
                  id="search-radius-unit-input"
                  onChange={this.handleUpdateLocationUnit}
                />
              ) : (
                <p>{locationUnit}</p>
              )}
            </InputGroup>
          </LocationSearchFormGroup>
        </Fieldset>
        <Fieldset>
          <Legend appearsdisabled={this.whenLoading()}>
            {this.getLanguageLabel("SEARCH_CENTER_LABEL")}
          </Legend>
          <LocationSearchFormGroup>
            <Input
              disabled={this.whenLoading()}
              aria-label="Location search latitude"
              id="search-center-lat-input"
              type="number"
              step="1"
              value={locationCenter.lat}
              onChange={this.handleUpdateLocationLat}
              min={this.getMinLatValue()}
              max={this.getMaxLatValue()}
              invalid={
                !isSearching &&
                locationCenter.lat !== "" &&
                !this.isValidLat(locationCenter.lat)
              }
              aria-invalid={
                !isSearching &&
                locationCenter.lat !== "" &&
                !this.isValidLat(locationCenter.lat)
              }
            />
            <span>,</span>
            <Input
              min={this.getMinLongValue()}
              disabled={this.whenLoading()}
              aria-label="Location search longitude"
              id="search-center-long-input"
              type="number"
              step="1"
              value={locationCenter.long}
              onChange={this.handleUpdateLocationLong}
              invalid={
                !isSearching &&
                locationCenter.long !== "" &&
                !this.isValidLong(locationCenter.long)
              }
              aria-invalid={
                !isSearching &&
                locationCenter.long !== "" &&
                !this.isValidLong(locationCenter.long)
              }
              max={this.getMaxLongValue()}
            />
          </LocationSearchFormGroup>
          {this.showInvalidCoordMessage() && (
            <Label type="formError">
              {this.getLanguageLabel("LOCATION_SEARCH_INVALID_COORD_MSG_LABEL")}
            </Label>
          )}
          {this.locationSpatialReferenceOptions().length && (
            <DropDown
              options={this.locationSpatialReferenceOptions()}
              aria-label="Search spatial reference"
              id="search-spatial-reference"
              value={locationSpatialReference}
              onChange={this.handleUpdateLocationSpatialReference}
            />
          )}
        </Fieldset>
      </LocationSearchForm>
    );
  };

  tooltipArea = () => {
    const { searchGeometries } = this.state;
    return (
      <SearchInputWrapper>
        {searchGeometries.length === 0 ? (
          <SearchAreaContainer data-name={"SearchAreaContainer"}>
            <p>{this.getLanguageLabel("LOCATION_SEARCH_DESC_LABEL")}</p>
            <ToolTip
              helpMessage={this.getLanguageLabel(
                "DEFAULT_SEARCH_LOCATION_TOOLTIP_LABEL"
              )}
              floatDirection={"right"}
            />
          </SearchAreaContainer>
        ) : (
          <SearchAreaContainer data-name={"SearchAreaContainer"}>
            <Button
              disabled={this.whenLoading()}
              onClick={this.handleResetSketch}
              type="reset"
              styletype={"formReset"}
            >
              <span>{this.getLanguageLabel("AREA_SEARCH_CLEAR_LABEL")}</span>
              <Icon
                data-name={"Icon"}
                iconColor={defaultTheme.agRed}
                type={"close"}
                bgHeight={"32px"}
                bgWidth={"32px"}
                iconHeight={"14px"}
                iconWidth={"14px"}
              />
            </Button>
          </SearchAreaContainer>
        )}
      </SearchInputWrapper>
    );
  };
  render() {
    return (
      <div>
        {this.tooltipArea()}
        {this.searchForm()}
        {this.state.drawingToolSettings && (
          <DrawingTool {...this.state.drawingToolSettings} />
        )}
      </div>
    );
  }
}
