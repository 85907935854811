import React, { Component } from "react";
import {
  QUERY_EXPRESSION,
  DELETEDDATE_QUERY_EXPRESSION,
  TEMP_LAYER_PREFIX,
  CROP_BLOCK_SELECTABLE_SYMBOL,
  CROP_SELECTION_SYMBOL
} from "../../../constants";
import {
  SearchInputWrapper,
  SearchAreaContainer
} from "../../../AgBoxUIKit/plugin/layout";
import { ToolTip } from "../../../AgBoxUIKit/plugin";
import { Button } from "../../../AgBoxUIKit/core/components";
import { Icon } from "../../../AgBoxUIKit/core";
import { defaultTheme } from "../../../AgBoxUIKit";
import GraphicsLayer from "@arcgis/core/layers/GraphicsLayer";
import Graphic from "@arcgis/core/Graphic";
import { equals, union } from "@arcgis/core/geometry/geometryEngine";
import { isNullOrUndefined } from "../../../App/utils";

export default class SelectGeometrySearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchClickHandler: null,
      searchGeometries: [],
      selectableLayer: null,
      selectableFeatures: [],
      selectedGraphics: [],
      isSearching: false
    };

    this.controller = new AbortController();
    this.geometryController = new AbortController();
  }

  componentDidMount() {
    this.setupSearch();
  }

  componentWillUnmount() {
    this.unloadSearch();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.layerTitle !== this.props.layerTitle) {
      this.unloadSearch();
      this.setupSearch();
    }
  }
  getSelectableFeatures = async () => {
    try {
      const { webMap, layerTitle } = this.props;
      const layer = webMap.layers.items.find(
        (layer) => layer.title === layerTitle
      );
      if (!layer) return [];

      const query = layer.createQuery();
      query.where = layer.fields.find((field) => field.name === "expiryDate")
        ? QUERY_EXPRESSION
        : DELETEDDATE_QUERY_EXPRESSION;
      const selectableFeatures = await layer
        .queryFeatures(query, { signal: this.controller.signal })
        .then((results) => {
          return results.features;
        });

      return selectableFeatures;
    } catch (e) {
      if (process.env.NODE_ENV === "development") console.log(e);
      return [];
    }
  };
  createSelectableGraphics = (selectableFeatures) => {
    const graphics = selectableFeatures.map((feature) => {
      const graphic = new Graphic({
        geometry: feature.geometry,
        symbol: CROP_BLOCK_SELECTABLE_SYMBOL,
        attributes: feature.attributes,
        visible: true
      });
      return graphic;
    });

    const selectableLayer = new GraphicsLayer({
      graphics,
      title: `${TEMP_LAYER_PREFIX}_CLICK_SEARCH`,
      visible: true
    });
    return selectableLayer;
  };

  isSelectedGraphic = (geometry) => {
    const { searchGeometries } = this.state;
    let selectedGraphic;
    if (!geometry || searchGeometries.length === 0) {
      selectedGraphic = false;
    } else {
      selectedGraphic = !isNullOrUndefined(
        searchGeometries.find((item) => equals(item, geometry))
      );
    }
    return selectedGraphic;
  };

  handleClearSearch = () => {
    const { onSearchCleared } = this.props;
    this.setState({ searchGeometries: [], isSearching: false });
    if (!onSearchCleared) return false;
    return onSearchCleared();
  };

  handleResetSearch = () => {
    const { selectableLayer } = this.state;
    if (!selectableLayer) return;
    const { items } = selectableLayer.graphics;
    items.forEach((graphic) => {
      graphic.symbol = CROP_BLOCK_SELECTABLE_SYMBOL;
    });
    this.handleClearSearch();
  };

  unloadSearch = () => {
    const { searchClickHandler, selectableLayer } = this.state;
    if (searchClickHandler) {
      const { removeFeatureSearchClickHandler } = this.props;
      removeFeatureSearchClickHandler(searchClickHandler);
      if (typeof searchClickHandler !== "string" && searchClickHandler.remove) {
        searchClickHandler.remove();
      }
    }
    if (selectableLayer) {
      const { webMap } = this.props;
      webMap.remove(selectableLayer);
    }
  };

  handleOnSearchStart = (geometry) => {
    const { onSearchStart } = this.props;
    if (!onSearchStart) return false;
    return onSearchStart({ geometry });
  };

  whenLoading = () => {
    const { loading } = this.props;
    return loading === true;
  };

  handleExecuteSearch = (geometry) => {
    const { searchGeometries } = this.state;
    const selectedGeometry = this.isSelectedGraphic(geometry);
    const geometriesToSearch = selectedGeometry
      ? searchGeometries.filter(
          (searchGeometry) => !equals(geometry, searchGeometry)
        )
      : geometry
      ? searchGeometries && searchGeometries.length > 0
        ? [...searchGeometries, geometry]
        : [geometry]
      : searchGeometries && searchGeometries.length > 0
      ? searchGeometries
      : [];
    this.setState({ searchGeometries: geometriesToSearch });
    if (geometriesToSearch.length === 0) return this.handleResetSearch();
    const geometriesToSearchUnion = union(geometriesToSearch);
    this.handleOnSearchStart(geometriesToSearchUnion);
  };

  onClickSearch = async (event) => {
    const { selectableLayer } = this.state;
    const { mapView } = this.props;
    const { results } = await mapView.hitTest(event);
    const isNoResult = results.length === 0;
    if (isNoResult) return;
    const features = results.filter((result) => {
      if (result.layer === null) return false;
      return result.layer.title === selectableLayer.title;
    });

    const noFeatures = features.length === 0;
    if (noFeatures) return this.handleResetSearch();
    const selected = this.isSelectedGraphic(features[0].graphic.geometry);
    if (selected) {
      features[0].graphic.symbol = CROP_BLOCK_SELECTABLE_SYMBOL;
    } else {
      features[0].graphic.symbol = CROP_SELECTION_SYMBOL;
    }

    this.handleExecuteSearch(features[0].graphic.geometry);
  };

  setupClickHandler = () => {
    const { mapView } = this.props;

    const searchClickHandler = mapView.on("click", this.onClickSearch);
    this.setState({ searchClickHandler });

    const { addFeatureSearchClickHandler } = this.props;
    addFeatureSearchClickHandler(searchClickHandler);
  };

  setupSearch = async () => {
    const { webMap } = this.props;
    const selectableFeatures = await this.getSelectableFeatures();
    const selectableLayer = await this.createSelectableGraphics(
      selectableFeatures
    );
    if (!selectableLayer) return;
    webMap.add(selectableLayer);

    this.setState(
      {
        selectableLayer
      },
      () => {
        this.setupClickHandler();
      }
    );
  };
  render() {
    return (
      <SearchInputWrapper data-name={"SearchInputWrapper"}>
        <SearchAreaContainer data-name={"SearchAreaContainer"}>
          {this.state.searchGeometries.length === 0 ? (
            <React.Fragment>
              <p>{this.props.searchDescription}</p>
              <ToolTip
                helpMessage={this.props.helpText}
                floatDirection={"right"}
              />
            </React.Fragment>
          ) : (
            <Button
              disabled={this.whenLoading()}
              onClick={this.handleResetSearch}
              type="button"
              styletype={"formReset"}
            >
              <span>{this.props.clearLabel}</span>
              <Icon
                data-name={"Icon"}
                iconColor={defaultTheme.agRed}
                type={"close"}
                bgHeight={"32px"}
                bgWidth={"32px"}
                iconHeight={"14px"}
                iconWidth={"14px"}
              />
            </Button>
          )}
        </SearchAreaContainer>
      </SearchInputWrapper>
    );
  }
}
