import React from "react";
import {
  FeatureListItemWrap,
  FeatureListItemButton,
  FeatureListItemTitleWrap,
  FeatureListItemTitle,
  FeatureListItemSubtitle,
  FeatureListItemContent,
  FeatureListItemContainer,
  LegendItemImage,
  FeatureListItemImageWrap
} from "../..";
import FeatureListEmphasis from "./FeatureListEmphasis";
import FeatureListItemColorBar from "./FeatureListItemColorBar";
import PropTypes from "prop-types";

export const ConditionalFeatureListItemContainer = (props) =>
  props.condition ? (
    <FeatureListItemButton {...props}>{props.children}</FeatureListItemButton>
  ) : (
    <FeatureListItemContainer {...props}>
      {props.children}
    </FeatureListItemContainer>
  );

const FeatureListItem = (props) => {
  const layerTitle =
    props.layer && props.layer.title ? props.layer.title : null;
  const dataTitle =
    props.attributes && props.attributes.title ? props.attributes.title : null;
  return (
    <FeatureListItemWrap data-name={"FeatureListItemWrap"}>
      <ConditionalFeatureListItemContainer
        tabIndex={0}
        data-id={props.id}
        data-name={"FeatureListItem"}
        data-title={dataTitle}
        data-layer-title={layerTitle}
        emphasis={props.emphasis}
        onClick={props.handleSelectItem}
        onMouseEnter={props.handleItemHoverEvent}
        onMouseLeave={props.handleRemoveHover}
        condition={props.isClickable}
      >
        <FeatureListItemContent
          selected={props.selected}
          highlight={props.highlight}
        >
          {props.image && (
            <FeatureListItemImageWrap>
              <LegendItemImage src={props.image} aria-hidden={true} />
            </FeatureListItemImageWrap>
          )}
          <FeatureListItemTitleWrap>
            <FeatureListItemTitle data-name={"FeatureListItemTitle"}>
              {props.title}
            </FeatureListItemTitle>
            {props.subtitles.map((subtitle, index) => (
              <FeatureListItemSubtitle
                data-name={"FeatureListItemSubtitle"}
                key={`${subtitle.value}-${index}`}
                textColor={subtitle.highlightColor}
                highlightSubtitle={subtitle.highlightSubtitle}
              >
                {subtitle.value}
              </FeatureListItemSubtitle>
            ))}
          </FeatureListItemTitleWrap>

          {props.emphasis && <FeatureListEmphasis emphasis={props.emphasis} />}
        </FeatureListItemContent>
        {props.emphasis && (
          <FeatureListItemColorBar
            emphasis={props.emphasis.filter(
              (emphItem) => !emphItem.hideColorBar
            )}
          />
        )}
      </ConditionalFeatureListItemContainer>
    </FeatureListItemWrap>
  );
};

FeatureListItem.propTypes = {
  /** Emphasis information to be passed down to color bar and emphasis icons */
  emphasis: PropTypes.arrayOf(
    PropTypes.shape({
      emphasisIconType: PropTypes.string,
      emphasisIconColor: PropTypes.string,
      hideColorBar: PropTypes.bool
    })
  ),
  /** The title to display on the list item */
  title: PropTypes.string,
  /** If the item is highlighted */
  highlight: PropTypes.bool,
  /** Subtitles to display on the list item */
  subtitles: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      highlightColor: PropTypes.string,
      highlightSubtitle: PropTypes.bool
    })
  ),
  /** Called on mouse leave */
  handleRemoveHover: PropTypes.func,
  /** Called on click */
  handleSelectItem: PropTypes.func,
  /** Called on mouse enter */
  handleItemHoverEvent: PropTypes.func,
  /** a unique id for the feature list item */
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** An image that will show to the left of the title */
  image: PropTypes.string
};

export default FeatureListItem;
