import React from "react";
import { TableHead, TableHeadCell, TableHeadRow } from "../..";
import PropTypes from "prop-types";

const Header = ({ headers = [] }) => {
  return (
    <TableHead>
      <TableHeadRow>
        {headers.map((header) => (
          <TableHeadCell
            key={`header-${header.attribute}`}
            colSpan={header.colSpan}
            width={header.width || "auto"}
          >
            {header.label}
          </TableHeadCell>
        ))}
      </TableHeadRow>
    </TableHead>
  );
};

Header.propTypes = {
  headers: PropTypes.arrayOf(
    PropTypes.shape({
      /** The attribute or other item name for the header. E.g. if this was displaying features in a table, the key would be the attribute name */
      attribute: PropTypes.string,
      /** The colSpan for the header cell */
      colSpan: PropTypes.number,
      /** The width of the header cell. Defaults to 'auto'. */
      width: PropTypes.string,
      /** The label for the header */
      label: PropTypes.string
    })
  )
};

export default Header;
