import { connect } from "react-redux";
import { getLabel } from "../../selectors";
import * as actions from "../../actions";
import Pagination from "./Pagination";

export default connect(
  (state, ownProps) => ({
    labels: getLabel(state)
  }),
  actions
)(Pagination);
