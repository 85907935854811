import React, { Component } from "react";
import { Icon } from "../../";
import { defaultTheme, loaderSizeRegular } from "../../../";

import {
  LoaderWrapper,
  LoaderContainer,
  LoadingTextWrapper,
  LoadingTextContainer
} from "../../layout";
import { LoadingText } from "../index";
import PropTypes from "prop-types";

/**
 * The loading spinner used throughout the app. This can be rendered in either regular or small size.
 */
class Loader extends Component {
  static propTypes = {
    /** The text that will show under the loading spinner */
    loadingText: PropTypes.string,
    /** What size the spinner is - can be LOADER_SIZE_SMALL or LOADER_SIZE_REGULAR - regular is a full space icon with text below, while small renders the loading text adjacent to the spinner*/
    size: PropTypes.string,
    /** The height of the loading wrapper component - should include the unit */
    height: PropTypes.string,
    /** Whether the loader is visible */
    visible: PropTypes.bool,
    /** Font Color of loadingText - defaults to black if not included  */
    textColor: PropTypes.string
  };
  static defaultProps = {
    size: loaderSizeRegular,
    height: "100%",
    visible: true
  };
  getLoadingText = () => {
    const { loadingText } = this.props;
    return loadingText && loadingText !== "" ? loadingText : false;
  };

  loadingSize = () => {
    const { size } = this.props;
    return size ? size : loaderSizeRegular;
  };

  loadingHeight = () => {
    const { height } = this.props;
    return height ? height : "100%";
  };

  render() {
    const { visible } = this.props;
    return (
      <LoaderWrapper
        data-name={"LoaderWrapper"}
        visible={visible}
        loaderHeight={this.loadingHeight()}
      >
        <LoaderContainer
          data-name={"LoaderContainer"}
          size={this.loadingSize()}
          visible={visible !== undefined ? visible : true}
        >
          <Icon
            data-name={"Icon_Loading"}
            type={"loading"}
            spinning
            iconWidth={
              this.loadingSize() === loaderSizeRegular ? "5em" : "2.5em"
            }
            iconHeight={
              this.loadingSize() === loaderSizeRegular ? "5em" : "2.5em"
            }
            bgColor={defaultTheme.agGreen}
            iconColor={defaultTheme.agWhite}
            bgShape="round"
          />
          {this.getLoadingText() ? (
            <LoadingTextWrapper data-name="LoadingTextWrapper">
              <LoadingTextContainer data-name="LoadingText">
                <LoadingText
                  textColor={this.props.textColor}
                  data-name="LoadingText"
                  size={this.loadingSize()}
                >
                  {this.getLoadingText()}
                </LoadingText>
              </LoadingTextContainer>
            </LoadingTextWrapper>
          ) : null}
        </LoaderContainer>
      </LoaderWrapper>
    );
  }
}

export default Loader;
