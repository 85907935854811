import React from "react";
import { TableTrashButton } from "../../..";
import { Icon } from "../../../../../core";
import { defaultTheme } from "../../../../..";
import PropTypes from "prop-types";

const TrashButton = (props) => {
  return (
    <TableTrashButton title={props.label} onClick={props.onClick}>
      <Icon
        type="trash"
        iconColor={defaultTheme.agBlue}
        iconHeight={"24px"}
        iconWidth={"24px"}
      />
    </TableTrashButton>
  );
};

TrashButton.propTypes = {
  /** Called on click of the button */
  onClick: PropTypes.func,
  /** Used for the element title of the button - important for a11y */
  label: PropTypes.string
};

export default TrashButton;
