import React, { Component } from "react";
import AccordionList from "./subcomponents/AccordionList";
import AccordionHeader from "./subcomponents/AccordionHeader";
import PropTypes from "prop-types";
import { toLocale } from "../../../../App/utils";

export default class AccordionComponent extends Component {
  static propTypes = {
    /** The label in the header of the accordian */
    headerLabel: PropTypes.string,
    /** Any other content in the header */
    headerContent: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.node,
    ]),
    /** An identifier for the accordian */
    accordionName: PropTypes.string,
    /** The list of items in content if applicable */
    list: PropTypes.array,
    /** Called on select of a list item */
    handleSelectItem: PropTypes.func,
    /** If not a list, content visible in the accordian body */
    bodyContent: PropTypes.node,
    /** Sets the accordian open state on mount */
    defaultOpenState: PropTypes.bool
  };

  static defaultProps = {
    defaultOpenState: false
  };

  constructor(props) {
    super(props);
    this.state = {
      isOpen: this.props.defaultOpenState === true
    };
  }

  toggleAccordion = () => {
    this.setState({
      isOpen: !this.state.isOpen
    });
  };

  getAccordionHeaderProps = () => {
    const { headerLabel, headerContent, headerDirection, accordionName } = this.props;
    return {
      headerLabel: toLocale(headerLabel),
      headerContent,
      headerDirection,
      isOpen: this.state.isOpen,
      onClick: this.toggleAccordion,
      accordionName
    };
  };

  getAccordionListItems = () => {
    const { list } = this.props;
    return list;
  };

  handleSelectListItem = (item) => {
    const { handleSelectItem } = this.props;
    if (handleSelectItem) handleSelectItem(item);
  };

  accordionName = () => {
    const { accordionName } = this.props;
    return accordionName;
  };

  accordionContent = () => {
    const { isOpen } = this.state;
    if (!isOpen) return null;
    const { bodyContent } = this.props;
    return bodyContent ? (
      bodyContent
    ) : (
      <AccordionList
        listItems={this.getAccordionListItems()}
        onClickListItem={this.handleSelectListItem}
        accordionName={this.accordionName()}
      />
    );
  };

  render() {
    return (
      <React.Fragment>
        <AccordionHeader {...this.getAccordionHeaderProps()} />
        {this.accordionContent()}
      </React.Fragment>
    );
  }
}
