import React, { Component } from "react";
import {
  VictoryChart,
  VictoryBar,
  VictoryAxis,
  VictoryGroup,
  VictoryLabel
} from "victory";
import PropTypes from "prop-types";
import { defaultTheme } from "../../../..";

export default class Bar extends Component {
  static propTypes = {
    /** The data to be rendered on the graph */
    data: PropTypes.arrayOf(
      PropTypes.shape({
        x: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        y: PropTypes.number
      })
    ),
    /** Called on hover of bar */
    handleHoverItem: PropTypes.func,
    /** Called on click of a bar */
    handleClickItem: PropTypes.func,
    /** An array of x values that are selected*/
    selectedSegments: PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    ),
    /** Checks if bar is hovered, returns true or false */
    isHoveredItem: PropTypes.func,
    /** determines whether to show labels above bars */
    showLabels: PropTypes.bool,
    /** Label to show on x axis */
    xLabel: PropTypes.string,
    /** Label to show on y axis */
    yLabel: PropTypes.string,
    /** Custom content to show inside graph */
    customContent: PropTypes.node,
    /** Custom y axis tick values */
    yTickValues: PropTypes.arrayOf(
      PropTypes.shape({
        /** the number to display */
        y: PropTypes.number,
        /** an optional string to display for the tick label */
        tickLabel: PropTypes.string
      })
    ),
    /** Custom x axis tick values */
    xTickValues: PropTypes.arrayOf(
      PropTypes.shape({
        /** the number to display */
        x: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        /** an optional string to display for the tick label */
        tickLabel: PropTypes.string
      })
    ),
    /** Sets the width of each bar */
    barWidth: PropTypes.number
  };
  getLabel = (datum) => {
    const { showLabels } = this.props;
    return showLabels ? datum.y : null;
  };
  getData = () => {
    const { data } = this.props;
    if (!data) return [];
    return data;
  };

  isArrayOfArrays(arr) {
    return Array.isArray(arr) && arr.length > 0 && Array.isArray(arr[0]);
  }

  isHoveredItem = (x) => {
    const { isHoveredItem } = this.props;
    if (!isHoveredItem) return false;
    return isHoveredItem(x);
  };
  handleClickItem = (name) => {
    const { handleClickItem } = this.props;
    if (!handleClickItem) return;
    return handleClickItem(name);
  };

  handleHoverItem = (name) => {
    const { handleHoverItem } = this.props;
    if (handleHoverItem) handleHoverItem(name);
  };
  selectedSegments = () => {
    const { selectedSegments } = this.props;
    return selectedSegments ? selectedSegments : [];
  };

  isSelectedSegment = (segment) => {
    const selectedSegments = this.selectedSegments();
    if (!selectedSegments.length) return false;
    return (
      selectedSegments.find(
        (selectedSegment) => selectedSegment === segment
      ) !== undefined
    );
  };

  getStroke = (datum) => {
    const isHovered = this.isHoveredItem(datum.x);
    return isHovered ? defaultTheme.agLightBlue : datum.fill;
  };

  getDomain = () => {
    const data = this.getData();
    if (!data || !data.length) return null;
    const uniqueXValues = [...new Set(data.map((item) => item.x))];
    const yValues = this.getYAxisValues();
    const maxY = yValues[yValues.length - 1];
    return { x: [0.5, uniqueXValues.length + 0.5], y: [yValues[0], maxY] };
  };

  getXAxisValues = () => {
    const { xTickValues } = this.props;
    if (xTickValues) return xTickValues.map(({ x }) => x);
    const data = this.getData();
    const uniqueX = [...new Set(data.map((datum) => datum.x))];
    return uniqueX;
  };

  showXValues = () => {
    const { showXValues } = this.props;
    return showXValues;
  };

  getYAxisValues = () => {
    const { yTickValues } = this.props;
    if (yTickValues) return yTickValues.map(({ y }) => y);
    const data = this.getData();
    if (!data.length) return [];
    const max = Math.max(...data.map((datum) => datum.y));
    return [0, max];
  };

  getXLabel = () => {
    const { xLabel } = this.props;
    return xLabel;
  };

  getYLabel = () => {
    const { yLabel } = this.props;
    return yLabel;
  };

  getCustomContent = () => {
    const { customContent } = this.props;
    return customContent;
  };

  getYTickLabel = (tick) => {
    const { yTickValues } = this.props;
    if (!yTickValues) return tick;
    const tickItem = yTickValues.find(({ y }) => y === tick);
    if (!tickItem || !tickItem.tickLabel) return tick;
    return tickItem.tickLabel;
  };

  getXTickLabel = (tick) => {
    if (!this.showXValues()) return "";
    const { xTickValues } = this.props;
    if (!xTickValues) return tick;
    const tickItem = xTickValues.find(({ x }) => x === tick);
    if (!tickItem || !tickItem.tickLabel) return tick;
    return tickItem.tickLabel;
  };

  getVictoryGroup = () => {
    const data = this.getData();
    const result = data.map((dataItem) => {
      return (
        <VictoryBar
          labels={({ datum }) => datum.managementArea}
          labelComponent={
            <VictoryLabel
              dy={5}
              dx={5}
              angle={-90}
              textAnchor="start"
              labelPlacement="vertical"
            />
          }
          data={dataItem}
          style={{
            labels: { fontSize: 12, fontWeight: "lighter" },
            data: {
              fill: ({ datum }) => datum.fill,
              fillOpacity: 1
            }
          }}
        />
      );
    });

    return (
      <div style={{ fontWeight: "lighter", fontSize: 5 }}>
        <VictoryChart>
          <VictoryAxis
            label={"KPIN"}
            style={{
              axisLabel: {
                fontSize: 12,
                fontWeight: "lighter",
                padding: 30
              },
              tickLabels: {
                angle: -45,
                fontSize: 10,
                fontWeight: "lighter"
              }
            }}
          />
          <VictoryAxis
            tickValues={this.getYAxisValues()}
            dependentAxis={true}
            label={"Fruit sq.m"}
            style={{
              tickLabels: {
                fontSize: 10,
                fontWeight: "lighter"
              },
              axisLabel: {
                fontSize: 12,
                fontWeight: "lighter",
                padding: 35
              }
            }}
          />
          <VictoryGroup offset={10} style={{ data: { width: 8 } }}>
            {result}
          </VictoryGroup>
        </VictoryChart>
      </div>
    );
  };

  render() {
    return (
      <>
        {this.isArrayOfArrays(this.getData()) ? (
          this.getVictoryGroup()
        ) : (
          <VictoryChart height={400} width={400} domain={this.getDomain()}>
            <VictoryAxis
              tickValues={this.getXAxisValues()}
              tickFormat={(tick) => this.getXTickLabel(tick)}
              label={this.getXLabel()}
              style={{ axisLabel: { fontSize: 20 } }}
            />
            <VictoryAxis
              dependentAxis={true}
              tickValues={this.getYAxisValues()}
              tickFormat={(tick) => this.getYTickLabel(tick)}
              label={this.getYLabel()}
              style={{ axisLabel: { fontSize: 20 } }}
            />
            <VictoryBar
              data={this.getData()}
              barWidth={this.props.barWidth}
              labels={({ datum }) => this.getLabel(datum)}
              style={{
                data: {
                  fill: ({ datum }) => datum.fill,
                  stroke: ({ datum }) => this.getStroke(datum),
                  strokeWidth: 2,
                  fillOpacity: ({ datum: { x } }) =>
                    this.selectedSegments().length > 0
                      ? this.isSelectedSegment(x)
                        ? 1
                        : 0.4
                      : 1
                },
                labels: {
                  fontSize: 16
                }
              }}
              events={[
                {
                  target: "data",
                  eventHandlers: {
                    onMouseEnter: () => {
                      return [
                        {
                          target: "data",
                          mutation: (props) => {
                            return this.handleHoverItem(props.datum.x);
                          }
                        }
                      ];
                    },
                    onMouseLeave: () => {
                      return [
                        {
                          target: "data",
                          mutation: (props) => {
                            return this.handleHoverItem(null);
                          }
                        }
                      ];
                    },
                    onClick: () => {
                      return [
                        {
                          target: "data",
                          mutation: (props) => {
                            return this.handleClickItem(props.datum.x);
                          }
                        }
                      ];
                    }
                  }
                }
              ]}
            />
          </VictoryChart>
        )}

        {this.getCustomContent()}
      </>
    );
  }
}
