import React, { Component } from "react";
import { LoginWrapper } from "../AgBoxUIKit/core/layout";

export default class Login extends Component {
  componentDidMount() {
    const {
      loadApp,
      setLoadingText,
      labels: { LOADING_AGBOX_LABEL }
    } = this.props;
    loadApp();
    setLoadingText(LOADING_AGBOX_LABEL);
  }
  render() {
    const { logoLoader } = this.props;
    return <LoginWrapper>{logoLoader}</LoginWrapper>;
  }
}
