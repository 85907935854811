import { handleActions } from "redux-actions";
import {
  ADD_FEATURE_SEARCH_CLICK_HANDLER,
  REMOVE_FEATURE_SEARCH_CLICK_HANDLER,
  DEFAULT_WORLD_EXTENT,
  SET_DEFAULT_FEATURE_LAYERS,
  SET_SHOW_MAP,
  SET_DATE_FILTER_TEXT,
  SET_EXTENT,
  SET_ELEVATION_ON,
  LOADED_IMAGERY_DATES,
  SET_IMAGERY_LOADING
} from "../constants";

export const defaultState = {
  mapClickHandler: null,
  extent: DEFAULT_WORLD_EXTENT,
  defaultVisibleLayers: [],
  showMap: false,
  dateFilterText: "",
  showElevation: false,
  imageryDates: {}
};

export default handleActions(
  {
    [ADD_FEATURE_SEARCH_CLICK_HANDLER]: (state, { payload }) => ({
      ...state,
      mapClickHandler: payload.handler
    }),
    [REMOVE_FEATURE_SEARCH_CLICK_HANDLER]: (state) => ({
      ...state,
      mapClickHandler: null
    }),
    [SET_DEFAULT_FEATURE_LAYERS]: (state, { payload }) => ({
      ...state,
      defaultVisibleLayers: payload.layerUrls
    }),
    [SET_SHOW_MAP]: (state, { payload }) => ({
      ...state,
      showMap: payload.showMap
    }),
    [SET_DATE_FILTER_TEXT]: (state, { payload }) => ({
      ...state,
      dateFilterText: payload.text
    }),
    [SET_EXTENT]: (state, { payload }) => ({
      ...state,
      extent: payload.extent ? payload.extent : DEFAULT_WORLD_EXTENT
    }),
    [SET_ELEVATION_ON]: (state, { payload }) => ({
      ...state,
      showElevation: payload.elevationOn ? payload.elevationOn : false
    }),
    [SET_IMAGERY_LOADING]: (state, { payload }) => ({
      ...state,
      imageryDates: {
        ...state.imageryDates,
        [payload.propId]: {
          ...(state.imageryDates[payload.propId] || {}),
          isLoading: payload.isLoading
        }
      }
    }),
    [LOADED_IMAGERY_DATES]: (state, { payload }) => ({
      ...state,
      imageryDates: {
        ...state.imageryDates,
        [payload.propId]: {
          ...(state.imageryDates[payload.propId] || {}),
          results: payload.results
        }
      }
    })
  },
  defaultState
);
