import React from "react";
import {
  Fieldset,
  Input,
  Label,
  Legend,
  RadioSubitem
} from "../../../../../core/components";
import PropTypes from "prop-types";
import { ContentContainer } from "../../..";

const RadioSetInput = (props) => {
  function onChange(e) {
    const { handleUpdateValue, attribute } = props;
    handleUpdateValue(attribute, getValue(e.currentTarget.value));
  }
  function getValue(value) {
    const { options } = props;
    const matchingValue = value
      ? options.find((option) => option.value.toString() === value.toString())
      : value;
    return matchingValue ? matchingValue.value : value;
  }
  return (
    <Fieldset>
      <Legend styletype="label" type={!props.isValid ? "formError" : ""}>
        {props.label}
      </Legend>
      {props.options.map((option) => (
        <ContentContainer key={`${props.attribute}-input-${option.value}`}>
          <Label
            appearsdisabled={option.disabled}
            htmlFor={`${props.attribute}-input-${option.value}`}
            type={!props.isValid ? "formError" : ""}
            styletype={props.labelStyle ? props.labelStyle : ""}
          >
            <Input
              id={`${props.attribute}-input-${option.value}`}
              type={"radio"}
              name={props.attribute}
              value={option.value}
              fieldLabel={option.title || option.value}
              required={props.required}
              checked={getValue(props.value) === option.value}
              onChange={onChange}
              disabled={option.disabled || props.disabled}
              aria-invalid={!props.isValid}
              aria-describedby={
                props.helpText
                  ? `InputWrapper-${props.attribute}-help-text`
                  : null
              }
            />
            <span>{option.title || String(option.value)}</span>
          </Label>
          {option.disabled && option.disabledMessage && (
            <Label type="error">{option.disabledMessage}</Label>
          )}
          {getValue(props.value) === option.value && option.content && (
            <RadioSubitem indent={option.indent}>{option.content}</RadioSubitem>
          )}
        </ContentContainer>
      ))}
    </Fieldset>
  );
};

RadioSetInput.propTypes = {
  labelStyle: PropTypes.string,
  attribute: PropTypes.string,
  isValid: PropTypes.bool,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  handleUpdateValue: PropTypes.func,
  required: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool
      ]),
      title: PropTypes.string,
      checked: PropTypes.bool
    })
  ),
  value: PropTypes.any
};

export default RadioSetInput;
