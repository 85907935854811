import React, { Component } from "react";
import { Modal } from "../../AgBoxUIKit/core";

export default class ProtectedRoute extends Component {
  modalTitle = () => {
    const {
      labels: { ACCESS_DENIED_TITLE }
    } = this.props;
    return ACCESS_DENIED_TITLE;
  };
  modalBody = () => {
    const {
      labels: { ACCESS_DENIED_BODY_LABEL }
    } = this.props;
    return ACCESS_DENIED_BODY_LABEL;
  };

  doesNotHavePermission = () => {
    const { hasPermission } = this.props;
    return hasPermission !== true;
  };

  redirectToRoot = () => {
    const { navigate, redirectRoute } = this.props;
    navigate(redirectRoute);
  };

  primaryButtonLabel = () => {
    const {
      labels: { CLOSE_BUTTON_LABEL }
    } = this.props;
    return CLOSE_BUTTON_LABEL;
  };
  render() {
    const { Component } = this.props;
    return this.doesNotHavePermission() ? (
      <Modal
        title={this.modalTitle()}
        body={this.modalBody()}
        isOpen={true}
        showCloseButton={false}
        primaryButtonAction={this.redirectToRoot}
        primaryButtonTitle={this.primaryButtonLabel()}
      />
    ) : (
      <Component {...this.props} />
    );
  }
}
