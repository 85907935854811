import proj4 from "proj4";
import moment from "moment";
import {
  MAX_WORD_LENGTH,
  MAX_SENTENCE_LENGTH,
  CROP_MERGE_SELECTED_SYMBOL,
  MEASURE_WIDGET_LINE_SYMBOL,
  CROP_POINT_BASIC_SYMBOL,
  MAX_QUERY_LENGTH,
  DATE_FORMAT,
  JOB_STATUS_RUNNING,
  ANY_FILTER_RULE,
  ALL_FILTER_RULE,
  GEOMETRYTYPE_FIELD_NAME,
  DATE_DELIMITER,
  DATE_TODAY,
  DEFAULT_SPATIAL_WKID,
  DEFAULT_WKID,
  ASSETS_PROXY_SUBSTRING,
  UNIT_HECTARES,
  UNIT_SQUARE_KILOMETERS,
  PRINTING_BUFFER_TO_SLIDER_VALUES,
  DEFAULT_INTERNATIONAL_DATE_FORMAT,
  NORMALISE_SORT_METHOD,
  LOCALE_COMPARE_SORT_METHOD
} from "../constants";
import Polygon from "@arcgis/core/geometry/Polygon";
import SpatialReference from "@arcgis/core/geometry/SpatialReference";

export const convertSignificantFigures = (
  inputNumber,
  numOfSignificantFigures
) => {
  if (
    isNullOrUndefined(inputNumber) ||
    isNullOrUndefined(numOfSignificantFigures) ||
    typeof inputNumber !== "number" ||
    typeof numOfSignificantFigures !== "number" ||
    numOfSignificantFigures > inputNumber
  )
    return inputNumber;

  const numString = inputNumber.toPrecision(numOfSignificantFigures);

  return parseFloat(numString);
};

export const roundNumber = (value, decimalPlaces) => {
  let decimalPlacesVal = decimalPlaces;
  if (
    isNullOrUndefined(decimalPlacesVal) ||
    typeof decimalPlacesVal !== "number"
  ) {
    decimalPlacesVal = value >= 0.01 ? 2 : 4;
  }

  return Number(
    Math.round(value + `e${decimalPlacesVal}`) + `e-${decimalPlacesVal}`
  );
};

export const sentenceCase = (str) => {
  if (typeof str !== "string") return str;
  const lowercase = str.toLowerCase();
  return `${lowercase.charAt(0).toUpperCase()}${lowercase
    .slice(1)
    .replace(/([!?.]\s+)([a-z])/g, function (m, $1, $2) {
      return $1 + $2.toUpperCase();
    })}`;
};

//helper function that sorts data alphaNumerically
//https://codepen.io/colster/pen/svhnF
export const normalizeMixedDataValue = (value) => {
  if (!value) {
    return;
  }
  const padding = "000000000000000";
  value = value.replace(/(\d+)((\.\d+)+)?/g, ($0, integer, decimal, $3) => {
    if (decimal !== $3) {
      return padding.slice(integer.length) + integer + decimal;
    }
    decimal = decimal || ".0";
    return (
      padding.slice(integer.length) +
      integer +
      decimal +
      padding.slice(decimal.length)
    );
  });
  return value;
};
export const escapeJSONNewLine = (json) => {
  if (typeof json !== "string") return json;
  return json.replace(/\n/g, "\\n");
};

export function escape(key, val) {
  if (typeof val !== "string") return val;
  return escapeJSONNewLine(val);
}

export const isValidJSON = (stringifiedJSON) => {
  try {
    const parsedJSON = JSON.parse(escapeJSONNewLine(stringifiedJSON));
    return true;
  } catch (e) {
    return false;
  }
};

export const getProjectionForWkid = async (wkid) => {
  if (!wkid) throw new Error("PROJECT_MISSING_SPATIAL_REFERENCE_LABEL");
  let sr = String(wkid) === String(DEFAULT_SPATIAL_WKID) ? DEFAULT_WKID : wkid;
  const savedValue = getSession(`wkid-${sr}`);
  if (savedValue) return savedValue;
  const response = await fetch(`https://epsg.io/?q=${sr}&format=json`);
  const resultsJSON = await response.json();
  if (
    !resultsJSON ||
    !resultsJSON.results ||
    !resultsJSON.results.length ||
    !resultsJSON.results[0].proj4
  )
    throw new Error("PROJECT_MISSING_SPATIAL_REFERENCE_PROJECTION_LABEL");
  const projection = resultsJSON.results[0].proj4;
  storeSession(`wkid-${sr}`, projection);
  return projection;
};

export const projectCoOrds = (
  coOrdArray,
  inputProjection,
  outputProjection
) => {
  if (!coOrdArray || !Array.isArray(coOrdArray))
    throw Error("PROJECT_MISSING_COORDS_ERROR_LABEL");
  try {
    return proj4(inputProjection, outputProjection, coOrdArray);
  } catch (e) {
    return e.message ? e.message : e;
  }
};

export const projectPoint = (geometry, inputProjection, outputProjection) => {
  if (
    !geometry ||
    isNullOrUndefined(geometry.x) ||
    isNullOrUndefined(geometry.y)
  )
    throw Error("PROJECT_INVALID_GEOMETRY_ERROR_LABEL");
  const convertedData = projectCoOrds(
    [geometry.x, geometry.y],
    inputProjection,
    outputProjection
  );
  return {
    x: convertedData[0],
    y: convertedData[1]
  };
};

export const projectLine = (geometry, inputProjection, outputProjection) => {
  if (!geometry || !geometry.paths)
    throw Error("PROJECT_INVALID_GEOMETRY_ERROR_LABEL");
  const convertedData = geometry.paths.map((path) => {
    return path.map((coOrds) =>
      projectCoOrds(coOrds, inputProjection, outputProjection)
    );
  });
  return {
    paths: convertedData
  };
};

export const projectPolygon = (geometry, inputProjection, outputProjection) => {
  if (!geometry || !geometry.rings)
    throw Error("PROJECT_INVALID_GEOMETRY_ERROR_LABEL");
  const convertedData = geometry.rings.map((ring) => {
    return ring.map((coOrds) =>
      projectCoOrds(coOrds, inputProjection, outputProjection)
    );
  });
  return {
    rings: convertedData
  };
};

export const projectGeometry = async (
  geometry,
  inputSP = "3857",
  outputSP = "2193"
) => {
  if (!geometry) throw Error("PROJECT_INVALID_GEOMETRY_ERROR_LABEL");
  const inputProjection = await getProjectionForWkid(inputSP);
  const outputProjection = await getProjectionForWkid(outputSP);
  let projectionMethod = projectPoint;
  if (geometry.rings) projectionMethod = projectPolygon;
  else if (geometry.paths) projectionMethod = projectLine;
  const projectedGeometry = projectionMethod(
    geometry,
    inputProjection,
    outputProjection
  );
  projectedGeometry.spatialReference = new SpatialReference({
    wkid: Number(outputSP)
  });
  return projectedGeometry;
};

export const getOutSR = (primaryOutSR, secondaryOutSR, errorSR) => {
  if (errorSR) {
    return errorSR === primaryOutSR ? secondaryOutSR : DEFAULT_WKID;
  }
  return primaryOutSR || secondaryOutSR || DEFAULT_WKID;
};

export const projectGeometries = async (
  geometries,
  primaryOutSR,
  secondaryOutSR,
  errorSR,
  warningMessage
) => {
  const outSR = getOutSR(primaryOutSR, secondaryOutSR, errorSR);
  try {
    if (!geometries || !geometries.length) return { outGeometries: [] };
    const outGeometries = await Promise.all(
      geometries.map((geometry) => {
        let inputSR = DEFAULT_WKID;
        if (
          geometry &&
          geometry.spatialReference &&
          geometry.spatialReference.latestWkid
        ) {
          inputSR = geometry.spatialReference.latestWkid;
        } else if (
          geometry &&
          geometry.spatialReference &&
          geometry.spatialReference.wkid
        ) {
          inputSR = geometry.spatialReference.wkid;
        }

        return projectGeometry(geometry, inputSR, outSR);
      })
    );
    return {
      outGeometries,
      warning: errorSR
        ? {
            errorSR,
            outSR,
            message: warningMessage
          }
        : null
    };
  } catch (error) {
    const isProjectionError =
      error.message === "PROJECT_MISSING_SPATIAL_REFERENCE_PROJECTION_LABEL" ||
      error.message === "PROJECT_MISSING_SPATIAL_REFERENCE_LABEL" ||
      error.message === "PROJECT_INVALID_GEOMETRY_ERROR_LABEL";
    if (isProjectionError) {
      return await projectGeometries(
        geometries,
        primaryOutSR,
        secondaryOutSR,
        outSR,
        error.message
      );
    } else {
      return { outGeometries: geometries, error };
    }
  }
};

export const sortList = (a, b) => {
  const aMixed = normalizeMixedDataValue(a);
  const bMixed = normalizeMixedDataValue(b);
  return aMixed > bMixed ? 1 : aMixed < bMixed ? -1 : 0;
};

export const sortMethod = (a, b) => {
  const aMixed = normalizeMixedDataValue(a.attributes.title);
  const bMixed = normalizeMixedDataValue(b.attributes.title);
  return aMixed < bMixed ? -1 : 1;
};

export const wordIsTooLong = (string) => string.length > MAX_WORD_LENGTH;

export const reduceFontSize = (string) => {
  if (!string) return false;
  const wholeStringIsMaxLength = wordIsTooLong(string);
  if (!wholeStringIsMaxLength) return false;
  const words = string.split(" ");
  const wordsAreLong = words.some((word) => wordIsTooLong(word));
  return wordsAreLong || string.replace(" ", "").length > MAX_SENTENCE_LENGTH;
};

export const decodeComputedString = (string, data) => {
  if (!data) return string;
  const computedLabel = (labelString, data) => {
    return new Function("return `" + labelString + "`;").call(data);
  };
  return computedLabel(string, data);
};

export const convertQueryToFormData = (query, isCount, layerGeometryType) => {
  const searchParams = new URLSearchParams();
  const {
    geometry,
    where,
    outFields,
    membership,
    offset,
    limit,
    returnCentroidsOnly,
    orderByDistance,
    start,
    num
  } = query;
  if (geometry) {
    geometry.spatialReference = {
      latestWkid: 3857
    };
    searchParams.append("geometry", JSON.stringify(geometry));
    //can't use object prototype method on esri objects
    searchParams.append(
      "geometryType",
      geometry.hasOwnProperty("rings")
        ? "esriGeometryPolygon"
        : "esriGeometryEnvelope"
    );
    searchParams.append("spatialRel", "esriSpatialRelIntersects");
  }
  if (where) {
    searchParams.append("where", where);
  }
  if (membership === true) {
    searchParams.append("membership", "true");
  }
  if (isCount) {
    searchParams.append("returnCountOnly", "true");
  }
  if (layerGeometryType) {
    searchParams.append("layerGeometryType", layerGeometryType);
  }
  if (outFields) {
    searchParams.append(
      "outFields",
      Array.isArray(outFields) ? outFields.join(",") : "*"
    );
  }
  if (returnCentroidsOnly) {
    searchParams.append("returnCentroidsOnly", "true");
    searchParams.append("returnGeometry", "false");
  }
  if (!isNullOrUndefined(offset)) {
    searchParams.append("resultOffset", offset);
  }
  if (!isNullOrUndefined(limit)) {
    searchParams.append("resultRecordCount", limit);
  }
  if (!isNullOrUndefined(start)) {
    searchParams.append("resultOffset", start);
  }
  if (!isNullOrUndefined(num)) {
    searchParams.append("resultRecordCount", num);
  }
  if (orderByDistance) {
    searchParams.append("orderByDistance", "true");
  }
  return searchParams;
};

export const getSymbolFromRenderer = async (renderer, graphic) => {
  const { geometry } = graphic;
  let defaultSymbol =
    geometry.type === "polygon"
      ? CROP_MERGE_SELECTED_SYMBOL
      : geometry.type === "polyline"
      ? MEASURE_WIDGET_LINE_SYMBOL
      : CROP_POINT_BASIC_SYMBOL;
  if (!renderer) return defaultSymbol;
  const { type } = renderer;
  let symbol = defaultSymbol;
  switch (type) {
    case "simple":
      symbol = renderer.symbol;
      break;
    case "class-breaks": {
      const info = await renderer.getClassBreakInfo(graphic);
      symbol = info ? info.symbol : renderer.defaultSymbol;
      break;
    }
    case "unique-value": {
      const uniqueValueInfo = await renderer.getUniqueValueInfo(graphic);
      symbol = uniqueValueInfo
        ? uniqueValueInfo.symbol
        : renderer.defaultSymbol;
      break;
    }
    default:
      symbol = defaultSymbol;
      break;
  }
  return symbol;
};

export const queryHasChanged = (originalQuery, newQuery) => {
  return JSON.stringify(originalQuery) !== JSON.stringify(newQuery);
};

export const maxTileSize = (geometryType, queryOptions) => {
  if (geometryType !== "point") return 1000;
  else if (
    queryOptions &&
    queryOptions.returnGeometry !== false &&
    queryOptions.outFields &&
    (queryOptions.outFields === "*" ||
      (Array.isArray(queryOptions.outFields) &&
        queryOptions.outFields.includes("customData")))
  )
    return 2500;
  return 7500;
};

export let processing = 0;
export let queue = [];

export const addToQueue = (item) => {
  queue.push(item);
};

export const createFunc = (requestMethod, successCallBack, errorCallBack) => {
  const func = async () => {
    try {
      const result = await requestMethod();
      successCallBack(result);
    } catch (e) {
      if (errorCallBack) errorCallBack(e);
    } finally {
      checkQueue();
    }
  };
  return func;
};

export const runRequest = async (func) => {
  try {
    if (!func) return;
    setProcessing(processing + 1);
    const result = await func();
    return result;
  } catch (e) {
    console.log(e);
  } finally {
    setProcessing(processing - 1);
  }
};

export const checkQueue = () => {
  if (queue.length === 0 || processing === MAX_QUERY_LENGTH) return;
  const func = queue[0];
  queue = queue.slice(1);
  return runRequest(func);
};

export const clearQueue = () => {
  queue = [];
};

export const clearProcessing = () => {
  processing = 0;
};

export const setProcessing = (num) => {
  processing = num;
};

export const handleRequest = (
  requestMethod,
  successCallBack,
  errorCallBack
) => {
  const func = createFunc(requestMethod, successCallBack, errorCallBack);
  if (processing < MAX_QUERY_LENGTH) {
    return runRequest(func);
  } else {
    return addToQueue(func);
  }
};

export const getUrlSearchParamByKey = (location, key) => {
  if (!location || !key || !location.search) return null;
  const params = new URLSearchParams(location.search);
  return params.get(key);
};

export const setUrlSearchParamByKey = (location, navigate, key, value) => {
  if (!location || !key || !navigate) return null;
  const { pathname } = location;
  const search = new URLSearchParams(
    location.search ? location.search : window.location.search
  );
  const currentValue = getUrlSearchParamByKey(location, key);
  if (value === currentValue || String(value) === currentValue) return;
  if (value) {
    search.set(key, value);
  } else if (!value && getUrlSearchParamByKey(location, key)) {
    search.delete(key);
  } else return;
  const searchString = search.toString() ? `?${search.toString()}` : "";
  navigate(
    `${pathname ? pathname : window.location.pathname}${searchString}${
      location.hash || ""
    }`
  );
};

export const setMultipleUrlSearchParamByKeys = (
  location,
  navigate,
  updates
) => {
  if (!location || !updates || !navigate) return null;
  const { pathname } = location;
  const search = new URLSearchParams(
    location.search ? location.search : window.location.search
  );

  const changes = updates.filter(({ key, value }) => {
    const currentValue = getUrlSearchParamByKey(location, key);
    return currentValue !== value && currentValue !== String(value);
  });
  if (!changes.length) return;

  changes.forEach(({ key, value }) => {
    if (value) {
      search.set(key, value);
    } else {
      search.delete(key);
    }
  });

  const searchString = search.toString() ? `?${search.toString()}` : "";
  navigate(
    `${pathname ? pathname : window.location.pathname}${searchString}${
      location.hash || ""
    }`
  );
};

export const explodeFeatures = (features) => {
  if (!features) return null;
  return features.reduce((result, feature) => {
    const {
      attributes,
      geometry: { rings, spatialReference }
    } = feature;
    if (rings.length === 1) return [...result, feature];
    return [
      ...result,
      ...rings.map((ringSet) => ({
        attributes,
        geometry: new Polygon({
          rings: ringSet,
          spatialReference
        })
      }))
    ];
  }, []);
};

export const getSession = (key) => {
  if (!key) return null;

  const noSession = getUrlSearchParamByKey(window.location, "noSession");
  if (noSession === "true") return null;

  const results = sessionStorage.getItem(key);
  if (results) {
    return JSON.parse(results);
  }
};

export const storeSession = (key, result) => {
  if (!key || !result || result.error) return;
  const sessionStorage = window.sessionStorage;
  sessionStorage.setItem(key, JSON.stringify(result));
};

export const clearSession = (key) => {
  if (!key) return;
  const sessionStorage = window.sessionStorage;
  sessionStorage.removeItem(key);
};

export const getMedian = (arr) => {
  if (!arr || !arr.length) return 0;
  const sorted = arr.sort((a, b) => a - b);
  const mid = Math.floor(sorted.length / 2);
  return sorted.length % 2 !== 0
    ? sorted[mid]
    : (sorted[mid - 1] + sorted[mid]) / 2;
};

export const getQuartile = (arr, q) => {
  if (!arr || !arr.length) return 0;
  const sorted = arr.sort((a, b) => a - b);
  const pos = (sorted.length - 1) * q;
  const base = Math.floor(pos);
  const rest = pos - base;
  if (sorted[base + 1] !== undefined) {
    return sorted[base] + rest * (sorted[base + 1] - sorted[base]);
  } else {
    return sorted[base];
  }
};

export const toLocaleDate = (value, language) => {
  const trimmedDate = value.replace(/[^0-9]+/g, "/");
  let options = { year: "numeric", month: "short", day: "numeric" };
  if (moment(value, "YYYY", true).isValid()) {
    const date = moment(value).toDate();
    return date.toLocaleDateString(language, { year: "numeric" });
  } else if (moment(trimmedDate, DATE_FORMAT, true).isValid()) {
    return moment(trimmedDate, DATE_FORMAT, true)
      .toDate()
      .toLocaleDateString(language, options);
  } else if (moment(value).isValid()) {
    return new Date(value).toLocaleDateString(language, options);
  } else return value;
};

export const toLocale = (value, language) => {
  if (!value) return value;

  if (Array.isArray(value))
    return value.map((item) => toLocale(item, language));

  if (typeof value === "string") {
    const dates = value.match(
      /(\d{4}([.\-/ ])\d{2}\2\d{2}|\d{2}([.\-/ ])\d{2}\3\d{4})|(\b\d{4}\b)/g
    );
    let localeString = value;
    let removedDates = value;
    if (dates && dates.length) {
      for (let date of dates) {
        removedDates = removedDates.replace(date, "");
        localeString = localeString.replace(date, toLocaleDate(date, language));
      }
    }
    const numbers = removedDates.match(/[-]{0,1}[\d]*[.]{0,1}[\d]+/g);
    if (!numbers || !numbers.length) return localeString;
    for (let number of numbers) {
      localeString = localeString.replace(
        number,
        Number(number).toLocaleString(language)
      );
    }
    return localeString;
  }

  if (typeof value === "number") return Number(value).toLocaleString(language);

  return value;
};

export const pollJob = async (url, token, signal) => {
  const response = await fetch(url, {
    headers: {
      Authorization: `Bearer ${token}`
    },
    signal
  });
  const result = await response.json();
  const { status, error } = result;
  if (error) {
    throw new Error(error);
  } else if (status === JOB_STATUS_RUNNING) {
    return pollJob(url, token, signal);
  } else return result;
};

export const parseProxyUrl = (url = "") => {
  if (
    url.includes(ASSETS_PROXY_SUBSTRING) ||
    url.includes(process.env.REACT_APP_ASSET_PROXY_URL)
  )
    return url;
  return `${process.env.REACT_APP_ASSET_PROXY_URL}/${url.replace(
    new RegExp("(https://|http://)", "g"),
    ""
  )}`;
};

export const parseSymbol = (symbol = {}) => {
  if (!symbol || !symbol.url) {
    return symbol;
  } else {
    return {
      ...symbol,
      url: parseProxyUrl(symbol.url)
    };
  }
};

export const isContextual = (item) => {
  if (!item) return false;
  return (
    Object.prototype.hasOwnProperty.call(item, "filters") ||
    (Object.prototype.hasOwnProperty.call(item, "filterField") &&
      Object.prototype.hasOwnProperty.call(item, "filterValue"))
  );
};

export const getFieldValue = (feature, attr) => {
  if (!feature || !feature.attributes || !attr) return null;
  else if (Object.prototype.hasOwnProperty.call(feature.attributes, attr))
    return feature.attributes[attr];
  else if (
    feature.attributes.customData &&
    isValidJSON(feature.attributes.customData)
  ) {
    const customData = JSON.parse(
      escapeJSONNewLine(feature.attributes.customData)
    );
    return customData[attr] &&
      Object.prototype.hasOwnProperty.call(customData[attr], "value")
      ? customData[attr].value
      : null;
  }
  return null;
};

export const attributeFilterApplies = (
  feature,
  filter,
  filterRule = ALL_FILTER_RULE,
  geometryType
) => {
  const { filterField, filterValue } = filter;
  const filterFields = Array.isArray(filterField) ? filterField : [filterField];
  const filterValues = Array.isArray(filterValue) ? filterValue : [filterValue];

  if (filterRule === ANY_FILTER_RULE) {
    return filterFields.some((field) => {
      if (field === GEOMETRYTYPE_FIELD_NAME)
        return filterValues.includes(geometryType);
      else {
        return filterValues.includes(getFieldValue(feature, field));
      }
    });
  } else {
    if (filterFields.length === filterValues.length) {
      return filterFields.every((field, i) => {
        if (field === GEOMETRYTYPE_FIELD_NAME)
          return geometryType === filterValues[i];
        else {
          return getFieldValue(feature, field) === filterValues[i];
        }
      });
    } else
      return filterFields.every((field) => {
        if (field === GEOMETRYTYPE_FIELD_NAME)
          return filterValues.includes(geometryType);
        else {
          return filterValues.includes(getFieldValue(feature, field));
        }
      });
  }
};

export const contextualItemApplies = (
  feature,
  contextualItem,
  geometryType
) => {
  if (!feature) return true;
  const {
    filterField,
    filterValue,
    filters,
    filterRule,
    effect = "show"
  } = contextualItem;
  let featureGeometryType = geometryType;
  if (!featureGeometryType) {
    if (feature.geometry && feature.geometry.type)
      featureGeometryType = feature.geometry.type;
    else if (feature.layer && feature.layer.geometryType)
      featureGeometryType = feature.layer.geometryType;
  }
  let filtersToCheck = filters;
  if (!filters) {
    filtersToCheck = [
      {
        filterField,
        filterValue
      }
    ];
  }
  let applies = false;
  if (filterRule === ANY_FILTER_RULE)
    applies = filtersToCheck.some((filter) =>
      attributeFilterApplies(
        feature,
        filter,
        ANY_FILTER_RULE,
        featureGeometryType
      )
    );
  else {
    applies = filtersToCheck.every((filter) =>
      attributeFilterApplies(
        feature,
        filter,
        ALL_FILTER_RULE,
        featureGeometryType
      )
    );
  }
  if (effect === "show") return applies;
  else return !applies;
};

export const getApplicableItems = (feature, items, geometryType) => {
  if (Array.isArray(items)) {
    return items.filter((item) =>
      isContextual(item)
        ? contextualItemApplies(feature, item, geometryType)
        : true
    );
  } else if (isContextual(items)) {
    return contextualItemApplies(feature, items, geometryType) ? items : null;
  } else return items;
};

export const getFormattedDefaultValue = (
  feature,
  defaultValues,
  geometryType,
  domainValues,
  graphicsSelected
) => {
  if (isNullOrUndefined(defaultValues)) return null;
  const applicableDefaultValue = getApplicableItems(
    feature,
    defaultValues,
    geometryType
  );
  if (isNullOrUndefined(applicableDefaultValue)) return null;
  let defaultValue = applicableDefaultValue;
  if (Array.isArray(applicableDefaultValue)) {
    defaultValue = applicableDefaultValue.length
      ? applicableDefaultValue[0].value
      : null;
  }

  if (
    typeof defaultValue === "string" &&
    defaultValue.includes(DATE_DELIMITER)
  ) {
    const dateValue = defaultValue.substring(8, defaultValue.length);

    if (dateValue === DATE_TODAY) {
      defaultValue = moment();
    } else {
      defaultValue = moment(dateValue).isValid() ? moment(dateValue) : null;
    }
  }
  if (typeof defaultValue === "string" && domainValues && domainValues.length) {
    const relatedDomainValue = domainValues.find(
      (domainValue) => domainValue.value === defaultValue
    );
    if (!relatedDomainValue) defaultValue = null;
  }

  if (defaultValue === "graphicsSelected") {
    defaultValue = graphicsSelected ? graphicsSelected : null;
  }

  return defaultValue;
};

export const getExplicitValidationRulesForField = (
  feature,
  rules,
  geometryType
) => {
  if (!rules) return {};
  return Object.keys(rules).reduce((result, ruleKey) => {
    const rule = rules[ruleKey];
    if (!feature)
      return {
        ...result,
        [ruleKey]: rule
      };
    const applicableItem = getApplicableItems(feature, rule, geometryType);
    if (!applicableItem) return result;
    let formattedRule = applicableItem;
    if (Array.isArray(applicableItem)) {
      if (!applicableItem.length) return result;
      const { value, soft } = applicableItem[0];
      if (soft) return result;
      formattedRule = value;
    } else if (Object.prototype.hasOwnProperty.call(applicableItem, "value")) {
      const { soft, value } = applicableItem;
      if (soft) return result;
      formattedRule = value;
    }
    return {
      ...result,
      [ruleKey]: formattedRule
    };
  }, {});
};

export const getSoftValidationRulesForField = (
  feature,
  rules,
  geometryType
) => {
  if (!rules) return {};
  return Object.keys(rules).reduce((result, ruleKey) => {
    const rule = rules[ruleKey];
    if (!feature) {
      let currentRule = Array.isArray(rule) ? rule[0] : rule;
      return Object.prototype.hasOwnProperty.call(currentRule, "soft") &&
        currentRule.soft
        ? {
            ...result,
            [ruleKey]: currentRule
          }
        : result;
    }

    const applicableItem = getApplicableItems(feature, rule, geometryType);
    if (!applicableItem) return result;
    let formattedRule = applicableItem;
    if (Array.isArray(applicableItem)) {
      if (!applicableItem.length) return result;
      const { value, soft } = applicableItem[0];
      if (!soft) return result;
      formattedRule = value;
    } else if (Object.prototype.hasOwnProperty.call(applicableItem, "soft")) {
      const { soft, value } = applicableItem;
      if (!soft) return result;
      formattedRule = value;
    } else return result;
    return {
      ...result,
      [ruleKey]: formattedRule
    };
  }, {});
};

export const dateFieldIsValid = (value, rule) => {
  if (!rule || (!rule.minDate && !rule.maxDate)) return true;
  const { minDate, maxDate } = rule;
  if (!moment(value).isValid()) {
    return false;
  } else if (minDate && !maxDate) {
    return moment(value).isSameOrAfter(moment(minDate).toDate()) ? true : false;
  } else if (maxDate && !minDate) {
    return moment(value).isSameOrBefore(moment(maxDate).toDate())
      ? true
      : false;
  } else if (minDate && maxDate) {
    return moment(value).isSameOrAfter(moment(minDate).toDate()) &&
      moment(value).isSameOrBefore(moment(maxDate).toDate())
      ? true
      : false;
  }
};

export const numberFieldIsValid = (value, rule) => {
  if (!rule || (!rule.min && !rule.max)) return true;
  const { max, min } = rule;
  if (max && min)
    return !isNullOrUndefined(value) ? value <= max && value >= min : true;
  else if (max && !min) {
    return !isNullOrUndefined(value) ? value <= max : true;
  } else if (min && !max)
    return !isNullOrUndefined(value) ? value >= min : true;
};

export const stringFieldIsValid = (value, rule) => {
  if (!rule || (!rule.maxLength && !rule.minLength && !rule.pattern))
    return true;
  const { maxLength, pattern, minLength } = rule;
  const matchesMaxLength =
    value && maxLength ? value.length <= maxLength : true;
  const matchesMinLength =
    value && !isNullOrUndefined(minLength) ? value.length >= minLength : true;
  const matchesPattern =
    value && pattern ? new RegExp(pattern).test(value) : true;
  return matchesMaxLength && matchesMinLength && matchesPattern;
};

export const isBlankValue = (value, type) => {
  if (isNullOrUndefined(value) || value === "-" || value === "") return true;
  else if (type === "date") return !moment(value).isValid();
  else if (type === "checkbox")
    return (Array.isArray(value) && !value.length) || isNullOrUndefined(value);
  return false;
};

export const isValid = (value, rules, type) => {
  if (!rules || !Object.keys(rules).length) return true;
  const { required } = rules;
  if (required && isBlankValue(value, type)) return false;
  else {
    switch (type) {
      case "percentage":
      case "integer":
      case "double":
      case "single":
      case "float":
        return numberFieldIsValid(value, rules);
      case "date":
        return dateFieldIsValid(value, rules);
      case "string":
      default:
        return stringFieldIsValid(value, rules);
    }
  }
};

export const getUserOS = () => {
  let os = "";
  const { userAgent } = navigator;
  const clientStrings = [
    { s: "Windows 10", r: /(Windows 10.0|Windows NT 10.0)/ },
    { s: "Windows 8.1", r: /(Windows 8.1|Windows NT 6.3)/ },
    { s: "Windows 8", r: /(Windows 8|Windows NT 6.2)/ },
    { s: "Windows 7", r: /(Windows 7|Windows NT 6.1)/ },
    { s: "Windows Vista", r: /Windows NT 6.0/ },
    { s: "Windows Server 2003", r: /Windows NT 5.2/ },
    { s: "Windows XP", r: /(Windows NT 5.1|Windows XP)/ },
    { s: "Windows 2000", r: /(Windows NT 5.0|Windows 2000)/ },
    { s: "Windows ME", r: /(Win 9x 4.90|Windows ME)/ },
    { s: "Windows 98", r: /(Windows 98|Win98)/ },
    { s: "Windows 95", r: /(Windows 95|Win95|Windows_95)/ },
    { s: "Windows NT 4.0", r: /(Windows NT 4.0|WinNT4.0|WinNT|Windows NT)/ },
    { s: "Windows CE", r: /Windows CE/ },
    { s: "Windows 3.11", r: /Win16/ },
    { s: "Android", r: /Android/ },
    { s: "Open BSD", r: /OpenBSD/ },
    { s: "Sun OS", r: /SunOS/ },
    { s: "Chrome OS", r: /CrOS/ },
    { s: "Linux", r: /(Linux|X11(?!.*CrOS))/ },
    { s: "iOS", r: /(iPhone|iPad|iPod)/ },
    { s: "Mac OS X", r: /Mac OS X/ },
    { s: "Mac OS", r: /(Mac OS|MacPPC|MacIntel|Mac_PowerPC|Macintosh)/ },
    { s: "QNX", r: /QNX/ },
    { s: "UNIX", r: /UNIX/ },
    { s: "BeOS", r: /BeOS/ },
    { s: "OS/2", r: /OS\/2/ },
    {
      s: "Search Bot",
      r: /(nuhk|Googlebot|Yammybot|Openbot|Slurp|MSNBot|Ask Jeeves\/Teoma|ia_archiver)/
    }
  ];
  const userClientString = clientStrings.find(({ s, r }) => r.test(userAgent));
  if (userClientString) os = userClientString.s;

  if (/Windows/.test(os)) {
    os = "Windows";
  }
  return os;
};

export const isNullOrUndefined = (param) => {
  return param === null || param === undefined ? true : false;
};

export const getUserBrowser = () => {
  // browser
  const { userAgent, appName, appVersion } = navigator;
  let browser = appName;
  let fullVersion = "" + parseFloat(appVersion);
  let version = parseInt(appVersion, 10);
  let nameOffset, verOffset, ix;
  // Opera
  if ((verOffset = userAgent.indexOf("Opera")) !== -1) {
    browser = "Opera";
    fullVersion = userAgent.substring(verOffset + 6);
    if ((verOffset = userAgent.indexOf("Version")) !== -1) {
      fullVersion = userAgent.substring(verOffset + 8);
    }
  }
  // Opera Next
  if ((verOffset = userAgent.indexOf("OPR")) !== -1) {
    browser = "Opera";
    fullVersion = userAgent.substring(verOffset + 4);
  }
  // Legacy Edge
  else if ((verOffset = userAgent.indexOf("Edge")) !== -1) {
    browser = "Microsoft Legacy Edge";
    fullVersion = userAgent.substring(verOffset + 5);
  }
  // Edge (Chromium)
  else if ((verOffset = userAgent.indexOf("Edg")) !== -1) {
    browser = "Microsoft Edge";
    fullVersion = userAgent.substring(verOffset + 4);
  }
  // MSIE
  else if ((verOffset = userAgent.indexOf("MSIE")) !== -1) {
    browser = "Microsoft Internet Explorer";
    fullVersion = userAgent.substring(verOffset + 5);
  }
  // Chrome
  else if ((verOffset = userAgent.indexOf("Chrome")) !== -1) {
    browser = "Chrome";
    fullVersion = userAgent.substring(verOffset + 7);
  }
  // Safari
  else if ((verOffset = userAgent.indexOf("Safari")) !== -1) {
    browser = "Safari";
    fullVersion = userAgent.substring(verOffset + 7);
    if ((verOffset = userAgent.indexOf("Version")) !== -1) {
      fullVersion = userAgent.substring(verOffset + 8);
    }
  }
  // Firefox
  else if ((verOffset = userAgent.indexOf("Firefox")) !== -1) {
    browser = "Firefox";
    fullVersion = userAgent.substring(verOffset + 8);
  }
  // MSIE 11+
  else if (userAgent.indexOf("Trident/") !== -1) {
    browser = "Microsoft Internet Explorer";
    fullVersion = userAgent.substring(userAgent.indexOf("rv:") + 3);
  }
  // Other browsers
  else if (
    (nameOffset = userAgent.lastIndexOf(" ") + 1) <
    (verOffset = userAgent.lastIndexOf("/"))
  ) {
    browser = userAgent.substring(nameOffset, verOffset);
    fullVersion = userAgent.substring(verOffset + 1);
    if (browser.toLowerCase() === browser.toUpperCase()) {
      browser = appName;
    }
  }
  // trim the version string
  if ((ix = fullVersion.indexOf(";")) !== -1)
    fullVersion = fullVersion.substring(0, ix);
  if ((ix = fullVersion.indexOf(" ")) !== -1)
    fullVersion = fullVersion.substring(0, ix);
  if ((ix = fullVersion.indexOf(")")) !== -1)
    fullVersion = fullVersion.substring(0, ix);

  version = parseInt("" + fullVersion, 10);
  if (isNaN(version)) {
    fullVersion = "" + parseFloat(appVersion);
    version = parseInt(appVersion, 10);
  }

  return { browser, version };
};
//assumes that the area is in sqm to begin with
export const getConvertedFeatureArea = (area, unit) => {
  let convertedArea = area;
  if (unit === UNIT_HECTARES) convertedArea = area / 10000;
  else if (unit === UNIT_SQUARE_KILOMETERS) convertedArea = area / 1000000;
  return roundNumber(convertedArea, convertedArea >= 0.01 ? 2 : 4);
};

export const rgbaToHex = (rgbaArray) => {
  if (!rgbaArray || !Array.isArray(rgbaArray)) {
    throw new Error("RGBA_INPUT_NOT_ARRAY_LABEL");
  }
  if (rgbaArray.length !== 3 && rgbaArray.length !== 4) {
    throw new Error("RGBA_INPUT_INVALID_LENGTH_LABEL");
  }

  const r = rgbaArray[0];
  const g = rgbaArray[1];
  const b = rgbaArray[2];

  if (typeof r !== "number" || typeof g !== "number" || typeof b !== "number") {
    throw new Error("RGBA_INPUT_INVALID_VALUE_LABEL");
  }

  const hexR = Math.round(r).toString(16).padStart(2, "0");
  const hexG = Math.round(g).toString(16).padStart(2, "0");
  const hexB = Math.round(b).toString(16).padStart(2, "0");

  const hexCode = `#${hexR}${hexG}${hexB}`;

  return hexCode;
};

export const hexToRgba = (hex) => {
  if (!hex) {
    throw new Error("HEX_INPUT_MISSING_LABEL");
  }

  hex = hex.replace(/^#/, "");

  if (hex.length === 3) {
    hex = hex
      .split("")
      .map((char) => char + char)
      .join("");
  }

  if (hex.length !== 6) {
    throw new Error("HEX_INPUT_INVALID_LENGTH_LABEL");
  }

  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  if (isNaN(r) || isNaN(g) || isNaN(b)) {
    throw new Error("HEX_INPUT_UNABLE_TO_PARSE_LABEL");
  }

  return [r, g, b, 255];
};

export const hslToRgb = (h, s, l) => {
  s /= 100;
  l /= 100;
  let c = (1 - Math.abs(2 * l - 1)) * s,
    x = c * (1 - Math.abs(((h / 60) % 2) - 1)),
    m = l - c / 2,
    [r, g, b] = [0, 0, 0];

  if (h < 60) [r, g, b] = [c, x, 0];
  else if (h < 120) [r, g, b] = [x, c, 0];
  else if (h < 180) [r, g, b] = [0, c, x];
  else if (h < 240) [r, g, b] = [0, x, c];
  else if (h < 300) [r, g, b] = [x, 0, c];
  else [r, g, b] = [c, 0, x];

  [r, g, b] = [r, g, b].map((n) => Math.round((n + m) * 255));
  return [r, g, b];
};

export const stringIsWiderThanContainer = (string, container, fontSize) => {
  if (!string || !container || !container.getBoundingClientRect || !fontSize)
    return false;
  const tempElement = document.createElement("span");
  tempElement.style.visibility = "hidden";
  tempElement.style.whiteSpace = "normal";
  tempElement.style.overflowWrap = "break-word";
  tempElement.style.font = `600 ${fontSize} / 35.6438px Poppins, sans-serif`;
  tempElement.style.width = `${container.getBoundingClientRect().width}px`;
  tempElement.innerText = string;
  document.body.appendChild(tempElement);

  const stringWidth = tempElement.getBoundingClientRect().width;
  const containerWidth = container.getBoundingClientRect().width;
  document.body.removeChild(tempElement);

  return stringWidth > containerWidth;
};

export const getResponsiveFontSize = (
  container,
  string,
  maxFontSize,
  minFontSize
) => {
  let fontSizeNumber = maxFontSize;
  let fontSize;

  const longestSubstring = string.split(" ").reduce((longest, current) => {
    return current.length > longest.length ? current : longest;
  }, "");

  do {
    fontSizeNumber -= 0.5;
    fontSize = `${fontSizeNumber}px`;
  } while (
    stringIsWiderThanContainer(longestSubstring, container, fontSize) &&
    fontSizeNumber > minFontSize
  );

  return fontSize;
};

export const extractFeatureAttributes = (features, attribute) => {
  if (!Array.isArray(features)) {
    features = [features];
  }

  return features.map((item) => {
    if (item.attributes[attribute]) return item.attributes[attribute];
    const parsedData = isValidJSON(item.attributes.customData)
      ? JSON.parse(item.attributes.customData)
      : null;
    const value = parsedData?.[attribute]?.value;
    if (typeof value === "number") {
      return Number(value);
    } else if (typeof value === "string") {
      return String(value);
    } else if (value instanceof Date) {
      return new Date(value);
    } else if (typeof value === "boolean") {
      return Boolean(value);
    } else {
      return 0;
    }
  });
};

export const transformPrintBufferValue = (bufferValue) => {
  bufferValue = Math.min(Math.max(bufferValue, 10), 200);
  const power = 1 - bufferValue / 100;

  if (bufferValue === 100) {
    return 1;
  } else if (bufferValue < 100) {
    return Math.pow((100 - bufferValue) / 10 + 1, power);
  } else {
    return 100 / bufferValue;
  }
};

export const getTruePropertyBuffer = (bufferValue) => {
  const closest = Object.keys(PRINTING_BUFFER_TO_SLIDER_VALUES).reduce(
    (prev, curr) =>
      Math.abs(curr - bufferValue) < Math.abs(prev - bufferValue) ? curr : prev
  );
  return PRINTING_BUFFER_TO_SLIDER_VALUES[closest];
};

export const openInNewTab = (url) => {
  if (!url) return;
  const newWindow = window.open(url, "_blank", "noopener,noreferrer");
  if (newWindow) newWindow.opener = null;
};

export const formatDateInTimeZone = (date, timeZone = "NZ") => {
  if (!date) {
    return null;
  }
  if (!window.Intl) {
    return date;
  }
  const formatter = new Intl.DateTimeFormat("en-US", {
    ...DEFAULT_INTERNATIONAL_DATE_FORMAT,
    timeZone: timeZone
  });
  return formatter.format(new Date(date));
};

export const sortListMethod = (
  ascending = true,
  method = NORMALISE_SORT_METHOD,
  fields = ["title"]
) => {
  if (typeof fields === "string") {
    fields = [fields];
  }

  return (a, b) => {
    for (let field of fields) {
      let aValue =
        a.attributes && a.attributes[field]
          ? a.attributes[field]
          : typeof a === "string"
          ? a
          : "";
      let bValue =
        b.attributes && b.attributes[field]
          ? b.attributes[field]
          : typeof b === "string"
          ? b
          : "";

      if (method === NORMALISE_SORT_METHOD) {
        aValue = normalizeMixedDataValue(aValue);
        bValue = normalizeMixedDataValue(bValue);
      }

      let comparison = 0;
      if (method === LOCALE_COMPARE_SORT_METHOD) {
        comparison = aValue.localeCompare(bValue);
      } else {
        comparison = aValue > bValue ? 1 : aValue < bValue ? -1 : 0;
      }

      if (comparison !== 0) {
        return ascending ? comparison : -comparison;
      }
    }
    return 0;
  };
};
