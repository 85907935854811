import { connect } from "react-redux";
import Main from "./Main";
import * as actions from "../actions";
import {
  getSelectedWorkflow,
  getWorkflowLoading,
  getHasLoadedProperties,
  getHasLoadedPropertyGroups
} from "../selectors";

export default connect(
  (state) => ({
    selectedWorkflow: getSelectedWorkflow(state),
    workflowLoading: getWorkflowLoading(state),
    hasLoadedProperties: getHasLoadedProperties(state),
    hasLoadedPropertyGroups: getHasLoadedPropertyGroups(state)
  }),
  actions
)(Main);
