import { createActions } from "redux-actions";
import {
  LOADED_WORKFLOWS,
  ERROR_WORKFLOWS,
  LOADED_WORKFLOW,
  ERROR_WORKFLOW,
  SELECT_WORKFLOW,
  LOADED_WORKFLOW_PLUGINS,
  ERROR_WORKFLOW_PLUGINS,
  SET_WORKFLOW_STATE,
  UPDATE_WORKFLOW_STATE,
  ADD_GRAPHIC_TO_WORKFLOW_STATE,
  REMOVE_GRAPHIC_FROM_WORKFLOW_STATE,
  UPDATE_GRAPHIC_IN_WORKFLOW_STATE,
  UPDATE_WORKFLOW_EVENT_HANDLERS,
  ADD_WORKFLOW_EVENT_HANDLER,
  REMOVE_WORKFLOW_EVENT_HANDLER,
  LOAD_WORKFLOW,
  ADD_FEATURE_LIST_ITEMS,
  UPDATE_FEATURE_LIST_ITEM,
  CLEAR_FEATURE_LIST_ITEMS,
  SET_WORKFLOW_SIZE,
  SET_FEATURE_LIST_ITEMS,
  LOAD_PROPERTY_GROUP_WORKFLOWS,
  LOADED_PROPERTY_GROUP_WORKFLOWS,
  CLEAR_WORKFLOW,
  CLEAR_WORKFLOW_PLUGINS,
  SET_WORKFLOW_PANEL_OPEN,
  LOAD_WORKFLOWS,
  DESELECT_WORKFLOW,
  LOAD_WORKFLOW_PLUGINS,
  ADD_FEATURE_SEARCH_CLICK_HANDLER,
  REMOVE_FEATURE_SEARCH_CLICK_HANDLER,
  SET_DATE_FILTER_TEXT,
  UPDATE_PRINT_RESULTS,
  SEND_RESPONSE,
  GET_WORKFLOW_STATE_VALUE
} from "../constants";

export const {
  loadedWorkflows,
  errorWorkflows,
  loadedWorkflow,
  errorWorkflow,
  selectWorkflow,
  loadedWorkflowPlugins,
  errorWorkflowPlugins,
  setWorkflowState,
  updateWorkflowState,
  addGraphicToWorkflowState,
  removeGraphicFromWorkflowState,
  updateGraphicInWorkflowState,
  updateWorkflowEventHandlers,
  addWorkflowEventHandler,
  removeWorkflowEventHandler,
  loadWorkflow,
  addFeatureListItems,
  updateFeatureListItem,
  clearFeatureListItems,
  setWorkflowSize,
  setFeatureListItems,
  loadWorkflows,
  deselectWorkflow,
  loadWorkflowPlugins,
  addFeatureSearchClickHandler,
  removeFeatureSearchClickHandler,
  setDateFilterText,
  loadPropertyGroupWorkflows,
  loadedPropertyGroupWorkflows,
  clearWorkflow,
  clearWorkflowPlugins,
  setWorkflowPanelOpen,
  updatePrintResults,
  sendResponse,
  getWorkflowStateValue
} = createActions({
  [LOADED_WORKFLOWS]: (workflows) => workflows,
  [ERROR_WORKFLOWS]: (error) => error,
  [LOADED_WORKFLOW]: (workflow) => workflow,
  [ERROR_WORKFLOW]: (error) => error,
  [SELECT_WORKFLOW]: (workflowId, propId, groupId, orgId) => ({
    workflowId,
    propId,
    groupId,
    orgId
  }),
  [LOADED_WORKFLOW_PLUGINS]: (plugins) => plugins,
  [ERROR_WORKFLOW_PLUGINS]: (error) => error,
  [SET_WORKFLOW_STATE]: (newState, workflowId) => ({ newState, workflowId }),
  [UPDATE_WORKFLOW_STATE]: (stateUpdate, callback) => ({
    stateUpdate,
    callback
  }),
  [ADD_GRAPHIC_TO_WORKFLOW_STATE]: (newGraphic) => ({ newGraphic }),
  [REMOVE_GRAPHIC_FROM_WORKFLOW_STATE]: (graphicId) => ({
    graphicId
  }),
  [UPDATE_GRAPHIC_IN_WORKFLOW_STATE]: (graphicId, updatedGraphic) => ({
    graphicId,
    updatedGraphic
  }),
  [UPDATE_WORKFLOW_EVENT_HANDLERS]: (eventHandlers) => ({
    eventHandlers
  }),
  [ADD_WORKFLOW_EVENT_HANDLER]: (handle, method) => ({
    handle,
    method
  }),
  [REMOVE_WORKFLOW_EVENT_HANDLER]: (eventHandlerId) => ({
    eventHandlerId
  }),
  [LOAD_WORKFLOW]: (workflowId, propId, groupId, orgId) => ({
    workflowId,
    propId,
    groupId,
    orgId
  }),
  [ADD_FEATURE_LIST_ITEMS]: (featureListItems, signal) => ({
    featureListItems,
    signal
  }),
  [UPDATE_FEATURE_LIST_ITEM]: (feature) => ({ feature }),
  [CLEAR_FEATURE_LIST_ITEMS]: () => {},
  [SET_WORKFLOW_SIZE]: (size) => size,
  [SET_FEATURE_LIST_ITEMS]: (featureListItems) => ({ featureListItems }),
  [LOAD_WORKFLOWS]: (orgId, propId) => ({ orgId, propId }),
  [DESELECT_WORKFLOW]: () => {},
  [LOAD_WORKFLOW_PLUGINS]: (workflow) => workflow,
  [ADD_FEATURE_SEARCH_CLICK_HANDLER]: (handler) => ({ handler }),
  [REMOVE_FEATURE_SEARCH_CLICK_HANDLER]: () => {},
  [SET_DATE_FILTER_TEXT]: (text) => ({ text }),
  [LOAD_PROPERTY_GROUP_WORKFLOWS]: (orgId, groupId) => ({
    orgId,
    groupId
  }),
  [LOADED_PROPERTY_GROUP_WORKFLOWS]: (workflows) => workflows,
  [CLEAR_WORKFLOW]: () => {},
  [CLEAR_WORKFLOW_PLUGINS]: () => {},
  [SET_WORKFLOW_PANEL_OPEN]: (isOpen) => isOpen,
  [UPDATE_PRINT_RESULTS]: (propId, pdfResult) => ({ propId, pdfResult }),
  [SEND_RESPONSE]: (messageType, message) => ({
    messageType,
    message
  }),
  [GET_WORKFLOW_STATE_VALUE]: (key) => key
});
