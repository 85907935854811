import React, { Component } from "react";

import {
  MapViewWrapper,
  MapViewContainer
} from "@agBoxUiKit/core/layout";
import { AttributionText } from "@agBoxUiKit/plugin";
import { PLUGIN_SIZE_REG, MAP_CONTAINER_ID } from "../constants";
import MapReader from "../MapReader";

/**
 * Wraps the [ESRI MapView](https://developers.arcgis.com/javascript/latest/api-reference/esri-views-MapView.html).
 *
 * Resizes the MapView based on window height and whether a workflow is open
 */
class MapView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      viewHeight: window.innerHeight
    };
  }
  resizeScreen = () => {
    this.setState({
      viewHeight: window.innerHeight
    });
  };
  componentDidMount() {
    window.addEventListener("resize", () => {
      return this.resizeScreen();
    });
  }
  isLoading = () => {
    const { webMapIsLoading } = this.props;
    return webMapIsLoading;
  };

  getWorkflowSelected = () => {
    const { hasSelectedWorkflow } = this.props;
    return hasSelectedWorkflow;
  };

  getPluginErrors = () => {
    const { pluginErrors } = this.props;
    return pluginErrors;
  };

  handleCloseModal = () => {
    const { closePropertiesModal } = this.props;
    closePropertiesModal();
  };

  hasExtent = () => {
    const { hasMapViewExtent, showMap } = this.props;
    return hasMapViewExtent && showMap;
  };

  dateFilterText = () => {
    const { dateFilterText } = this.props;
    return dateFilterText;
  };

  getWorkflowSize = () => {
    const { workflowSize } = this.props;
    return workflowSize ? workflowSize : PLUGIN_SIZE_REG;
  };

  workflowLoading = () => {
    const { workflowIsLoading } = this.props;
    return workflowIsLoading;
  };

  getAttributionText = () => {
    const { attribution } = this.props;
    return attribution;
  };

  isMapLoaded = () => {
    const { showMap } = this.props;
    return showMap;
  };

  workflowPanelOpen = () => {
    const { workflowPanelOpen } = this.props;
    return workflowPanelOpen === true;
  };

  workflowIsClosed = () => {
    return (
      this.workflowLoading() ||
      this.getWorkflowSelected() === false ||
      (this.getWorkflowSelected() !== false &&
        this.getPluginErrors() !== false) ||
      (this.getWorkflowSelected() !== false && !this.workflowPanelOpen())
    );
  };

  render() {
    return (
      <MapReader>
        <MapViewWrapper
          data-name={"MapViewWrapper"}
          size={this.getWorkflowSize()}
          workflowClosed={this.workflowIsClosed()}
        >
          <MapViewContainer
            data-name={"MapViewContainer"}
            data-loading={this.isLoading()}
          >
            <div
              id={MAP_CONTAINER_ID}
              style={{
                opacity: this.hasExtent() ? 1 : 0,
                height: `${this.state.viewHeight}px`
              }}
            />
            {this.isMapLoaded() ? (
              <AttributionText
                attributionText={this.getAttributionText()}
                workflowClosed={this.workflowIsClosed()}
              />
            ) : (
              ""
            )}
          </MapViewContainer>
        </MapViewWrapper>
      </MapReader>
    );
  }
}

export default MapView;
