import { connect } from "react-redux";
import {
  getAppBusy,
  getAvailableProperties,
  getAvailablePropertyGroups,
  getAvailableWorkflows,
  getFirstName,
  getLabel,
  getLastName,
  getOrganisationWorkflowOrder,
  getOrgLoading,
  getOrgPropertySelector,
  getPropertiesLoading,
  getPropertiesPagination,
  getPropertiesSearchError,
  getPropertiesSelecting,
  getPropertyFavorites,
  getPropertyGroupSelecting,
  getPropertyGroupsLoading,
  getPropertySearchSuggestions,
  getSearchPropertiesBy,
  getSelectedOrg,
  getSelectedProperty,
  getSelectedPropertyGroup,
  getSelectedPropertySearchSuggestion,
  getSelectedWorkflowId,
  getUpdatingUser,
  getUserId,
  getUserPreferencesForOrg,
  getUserWorkflowOrder,
  getWorkflowPanelOpen,
  getZoomToSearchResults,
  getHasAdminAccess,
  getProfileDropDownOpen
} from "../../selectors";
import * as actions from "../../actions";
import Sidebar from "./Sidebar";

export default connect(
  (state) => ({
    labels: getLabel(state),
    selectedOrganisation: getSelectedOrg(state),
    loadingOrg: getOrgLoading(state),
    selectedProperty: getSelectedProperty(state),
    selectedPropertyGroup: getSelectedPropertyGroup(state),
    searchPropertiesBy: getSearchPropertiesBy(state),
    busy: getAppBusy(state),
    propertySearchSuggestions: getPropertySearchSuggestions(state),
    selectedPropertySearchSuggestion:
      getSelectedPropertySearchSuggestion(state),
    availableProperties: getAvailableProperties(state),
    selectingPropertyGroup: getPropertyGroupSelecting(state),
    selectingProperty: getPropertiesSelecting(state),
    propertySelectorLabel: getOrgPropertySelector(state),
    loadingProperties: getPropertiesLoading(state),
    propertiesSearchError: getPropertiesSearchError(state),
    propertiesPagination: getPropertiesPagination(state),
    availablePropertyGroups: getAvailablePropertyGroups(state),
    loadingPropertyGroups: getPropertyGroupsLoading(state),
    shouldZoomToSearchResults: getZoomToSearchResults(state),
    availableWorkflows: getAvailableWorkflows(state),
    orgWorkflowOrder: getOrganisationWorkflowOrder(state),
    userWorkflowOrder: getUserWorkflowOrder(state),
    selectedWorkflowId: getSelectedWorkflowId(state),
    firstName: getFirstName(state),
    lastName: getLastName(state),
    workflowPanelOpen: getWorkflowPanelOpen(state),
    userId: getUserId(state),
    getPropertyFavorites: (orgId, userId) =>
      getPropertyFavorites(state, orgId, userId),
    updatingUser: getUpdatingUser(state),
    getUserPreferencesForOrg: (orgId) => getUserPreferencesForOrg(state, orgId),
    getHasAdminAccess: (orgId) => getHasAdminAccess(state, orgId),
    getProfileDropDownOpen: getProfileDropDownOpen(state)
  }),
  actions
)(Sidebar);
