import React, { Component } from "react";
import { defaultTheme } from "../../AgBoxUIKit";
import { Icon, TableComponent } from "../../AgBoxUIKit/core";
import {
  Button,
  ButtonLabel,
  TableBodyCellItem
} from "../../AgBoxUIKit/core/components";
import {
  FeatureTableWrapper,
  TableTrashButton,
  TableViewButtonWrap,
  FeatureTableContainer
} from "../../AgBoxUIKit/plugin/components";
import {
  getApplicableItems,
  getExplicitValidationRulesForField,
  getFieldValue,
  getFormattedDefaultValue,
  isBlankValue,
  isNullOrUndefined,
  isValid,
  isValidJSON
} from "../../App/utils";

export default class FeatureTable extends Component {
  getHeaders = () => {
    const tableAttributes = this.getTableAttributes();
    if (!tableAttributes.length) return null;
    const headers = tableAttributes.filter((item) => !item.level);
    if (this.allowDeleteRow())
      headers.push({
        width: "60px"
      });
    return headers;
  };

  getUpdatedFeatures = (
    objectId,
    attr,
    newValues,
    shouldUpdateDefaultValues = false
  ) => {
    const features = this.getFeatures();
    const value = newValues.join("||");
    return features.map((feature) => {
      let featureCopy = feature.clone ? feature.clone() : { ...feature };
      if (featureCopy.attributes.objectId == objectId) {
        if (
          featureCopy.attributes.customData &&
          isValidJSON(featureCopy.attributes.customData) &&
          JSON.parse(featureCopy.attributes.customData)[attr]
        ) {
          const customData = JSON.parse(featureCopy.attributes.customData);
          customData[attr] = {
            ...customData[attr],
            value: value
          };
          featureCopy.attributes.customData = JSON.stringify(customData);
        } else featureCopy.attributes[attr] = value;
        if (shouldUpdateDefaultValues)
          featureCopy = this.updateDefaultValues(featureCopy);
      }
      return featureCopy;
    });
  };

  removeCellItem = (cellItem) => {
    const { handleOnChange } = this.props;
    if (!handleOnChange) return;
    const { values, valueIndex, objectId, attribute } = cellItem;
    values.splice(valueIndex, 1);
    handleOnChange(this.getUpdatedFeatures(objectId, attribute, values));
  };

  handleAddValueItem = (cell) => {
    const { handleOnChange } = this.props;
    if (!handleOnChange) return;
    const features = this.getFeatures();
    const { values, objectId, attribute } = cell;
    const defaultValue = this.getDefaultValueForAttribute(
      attribute,
      features.find((feature) => feature.attributes.objectId == objectId)
    );
    const newValues = [
      ...values,
      !isNullOrUndefined(defaultValue) ? defaultValue : ""
    ];
    handleOnChange(this.getUpdatedFeatures(objectId, attribute, newValues));
  };

  allowAddMultiple = (cell) => {
    const { allowAddMultiple, editable, values = [] } = cell;
    return allowAddMultiple &&
      editable &&
      values.length &&
      !values.every((item) => item === "")
      ? true
      : false;
  };

  allowDeleteRow = () => {
    const { allowDeleteRow, nonEditableFeatures = [] } = this.props;
    const features = this.getFeatures();
    return features.length > 1 &&
      features.filter(
        (feature) => !nonEditableFeatures.includes(feature.attributes.objectId)
      ).length &&
      allowDeleteRow
      ? true
      : false;
  };

  handleDeleteRow = (e) => {
    const { handleOnChange } = this.props;
    if (!handleOnChange) return;
    const features = this.getFeatures();
    const objectId = e.currentTarget.dataset.objectid;
    const updatedFeatures = features.filter(
      (feature) => feature.attributes.objectId != objectId
    );
    handleOnChange(updatedFeatures);
  };

  getDeleteButton = (row) => {
    const {
      nonEditableFeatures = [],
      labels: { DELETE_TABLE_ROW_LABEL }
    } = this.props;
    if (!this.allowDeleteRow() || !row || !row.length) return null;
    if (nonEditableFeatures.includes(row[0].objectId))
      return <TableBodyCellItem />;
    return (
      <TableTrashButton
        title={DELETE_TABLE_ROW_LABEL}
        onClick={this.handleDeleteRow}
        data-objectid={row[0].objectId}
      >
        <Icon
          type="trash"
          iconColor={defaultTheme.agBlue}
          iconHeight={"24px"}
          iconWidth={"24px"}
        />
      </TableTrashButton>
    );
  };

  addRowLabel = () => {
    const {
      addRowLabel,
      labels: { GENERIC_ADD_ROW_LABEL }
    } = this.props;
    return addRowLabel ? addRowLabel : GENERIC_ADD_ROW_LABEL;
  };

  getAddRowButton = () => {
    const { allowAddRow } = this.props;
    if (!allowAddRow) return null;
    return (
      <TableViewButtonWrap>
        <Button styletype="iconButton" onClick={this.addNewRow}>
          <Icon
            type="add"
            iconColor={defaultTheme.agDarkBlue}
            iconHeight="50px"
            iconWidth="50px"
          />
          <ButtonLabel>{this.addRowLabel()}</ButtonLabel>
        </Button>
      </TableViewButtonWrap>
    );
  };

  addNewRow = async () => {
    const { handleAddNewRow } = this.props;
    if (!handleAddNewRow) return;
    await handleAddNewRow();
    const rows = this.getRows();
    const lastRow = rows[rows.length - 1];
    const firstEditableItemIndex = lastRow.cells.findIndex(
      (cell) => cell.editable === true
    );
    if (firstEditableItemIndex === -1) return;
    const { attribute } = lastRow.cells[firstEditableItemIndex];
    const input = document.querySelector(
      `[id^="${attribute}-row-${
        rows.length - 1
      }-cell-${firstEditableItemIndex}-0"]`
    );
    if (!input) return;
    input.focus();
  };

  handleUpdateCustomItem = (cellInfo, newValue) => {
    const { handleUpdateCustomItem } = this.props;
    if (!handleUpdateCustomItem) return handleUpdateCustomItem;
    handleUpdateCustomItem(cellInfo, newValue);
  };

  handleOnChangeValue = (cellInfo, newValue) => {
    const { handleOnChange } = this.props;
    if (cellInfo.isCustom)
      return this.handleUpdateCustomItem(cellInfo, newValue);
    else if (!handleOnChange) return;
    const { valueIndex, values, attribute, objectId } = cellInfo;
    handleOnChange(
      this.getUpdatedFeatures(
        objectId,
        attribute,
        values.map((value, i) => (i === valueIndex ? newValue : value)),
        true
      )
    );
  };

  getFeatures = () => {
    const { features } = this.props;
    return features || [];
  };

  getTableAttributes = () => {
    const { attributes } = this.props;
    return attributes
      ? Object.keys(attributes)
          .map((attribute) => ({
            ...attributes[attribute],
            attribute,
            label: attributes[attribute].label || this.getLabel(attribute)
          }))
          .sort((a, b) => {
            if (a.order && b.order) return a.order > b.order ? 1 : -1;
            return 1;
          })
      : [];
  };

  validationRules = (attr, feature) => {
    if (!attr) return false;
    const { validationRules } = this.props;
    if (!validationRules || !validationRules[attr]) return {};
    return getExplicitValidationRulesForField(feature, validationRules[attr]);
  };

  getDomainValuesForItem = (attr, item, fieldType) => {
    const {
      domainValues = {},
      labels: { LABEL_YES, LABEL_NO, LABEL_NA }
    } = this.props;
    let attributeDomainValues = domainValues[attr]
      ? getApplicableItems(item, domainValues[attr])
      : [];
    if (fieldType === "boolean" && !domainValues.length) {
      attributeDomainValues = [
        {
          value: true,
          label: LABEL_YES
        },
        {
          value: false,
          label: LABEL_NO
        }
      ];
    } else if (fieldType === "troolean" && !domainValues.length) {
      attributeDomainValues = [
        {
          value: true,
          label: LABEL_YES
        },
        {
          value: false,
          label: LABEL_NO
        },
        {
          value: null,
          label: LABEL_NA
        }
      ];
    }
    return attributeDomainValues;
  };

  getDefaultValueForAttribute = (attr, item) => {
    const { defaultValues } = this.props;
    if (!defaultValues || !attr || !defaultValues[attr]) return null;

    const domainValues = this.getDomainValuesForItem(attr, item);
    return getFormattedDefaultValue(
      item,
      defaultValues[attr],
      null,
      domainValues
    );
  };

  getLabel = (attr) => {
    const { displayTitles = {}, hideLabels = [] } = this.props;
    if (hideLabels.includes(attr)) return "";
    else if (Object.prototype.hasOwnProperty.call(displayTitles, attr)) {
      return displayTitles[attr];
    }
    return attr;
  };

  isNonEditableAttribute = (attribute, item) => {
    const { nonEditableAttributes, nonEditableFeatures } = this.props;
    if (!attribute || (!nonEditableAttributes && !nonEditableFeatures))
      return false;

    if (
      nonEditableFeatures &&
      nonEditableFeatures.includes(item.attributes.objectId)
    )
      return true;

    if (Array.isArray(nonEditableAttributes)) {
      return nonEditableAttributes.includes(attribute);
    } else if (nonEditableAttributes[attribute]) {
      const applicableItems = getApplicableItems(
        item,
        nonEditableAttributes[attribute]
      );
      if (!applicableItems) return false;
      else if (Array.isArray(applicableItems)) {
        if (!applicableItems.length) return false;
        return !isNullOrUndefined(applicableItems[0].value)
          ? applicableItems[0].value
          : true;
      } else if (
        Object.prototype.hasOwnProperty.call(applicableItems, "value")
      ) {
        return applicableItems.value;
      } else return applicableItems ? true : false;
    }
    return false;
  };

  updateDefaultValues = (feature) => {
    let featureCopy = feature.clone ? feature.clone() : { ...feature };
    const { attributes } = featureCopy;
    const { customAttributes } = this.props;
    const customData =
      attributes.customData && isValidJSON(attributes.customData)
        ? JSON.parse(attributes.customData)
        : {};
    const singleSelectTypes = ["boolean", "troolean", "select"];

    Object.keys({ ...attributes, ...customData }).forEach((attr) => {
      if (attr === "customData") return;
      let defaultValue = this.getDefaultValueForAttribute(attr, featureCopy);
      const fieldType = this.getFieldType(featureCopy, attr);
      const domainValues = this.getDomainValuesForItem(
        attr,
        featureCopy,
        fieldType
      );

      if (
        isNullOrUndefined(defaultValue) &&
        singleSelectTypes.includes(fieldType) &&
        domainValues.length <= 1
      ) {
        defaultValue = domainValues.length === 1 ? domainValues[0].value : null;
      }

      const currentValue = getFieldValue(featureCopy, attr);
      let updatedValue = currentValue;

      //handle multiselect checkboxes
      if (fieldType === "checkbox") {
        if (isNullOrUndefined(defaultValue)) return;
        else {
          updatedValue = currentValue.length
            ? currentValue.map((value) =>
                isBlankValue(value, fieldType) ? defaultValue : value
              )
            : [defaultValue];
        }
      } else if (fieldType === "select") {
        const isInvalidDomainValue = (valueItem) => {
          return (
            isBlankValue(valueItem) ||
            !domainValues.find((domainValue) => domainValue.value === valueItem)
          );
        };
        if (typeof currentValue === "string") {
          const values = currentValue.split("||");
          updatedValue = values
            .map((valueItem) =>
              isInvalidDomainValue(valueItem) ? defaultValue : valueItem
            )
            .join("||");
        } else {
          updatedValue = isInvalidDomainValue(currentValue)
            ? defaultValue
            : currentValue;
        }
      } else if (isBlankValue(currentValue, fieldType)) {
        updatedValue = defaultValue;
      }

      if (Object.prototype.hasOwnProperty.call(featureCopy.attributes, attr))
        featureCopy.attributes[attr] = updatedValue;
      else if (Object.prototype.hasOwnProperty.call(customAttributes, attr))
        customData[attr] = {
          ...customAttributes[attr],
          value: updatedValue
        };
    });

    featureCopy.attributes.customData = JSON.stringify(customData);

    return featureCopy;
  };

  getFieldType = (feature, attr) => {
    const {
      attributeTypes = {},
      customAttributes = {},
      attributes = {}
    } = this.props;
    if (attributes[attr] && attributes[attr].type) return attributes[attr].type;
    else if (attributeTypes[attr]) return attributeTypes[attr];
    else {
      let type = "string";
      const { layer, attributes } = feature;
      if (customAttributes[attr] && customAttributes[attr].type) {
        type = customAttributes[attr].type;
      } else if (layer && layer.fields && layer.fields.length) {
        const field = layer.fields.find((field) => field.name === attr);
        if (field) return field.type;
      } else if (attributes.customData && isValidJSON(attributes.customData)) {
        const customData = JSON.parse(attributes.customData);
        if (customData[attr] && customData[attr].type)
          type = customData[attr].type;
      }

      return type === "string" &&
        this.getDomainValuesForItem(attr, feature).length
        ? "select"
        : type;
    }
  };

  getCellValues = (feature, attr, editable, allowEmptyCells) => {
    const {
      labels: { FEATURE_ATTRIBUTE_NO_VALUE_LABEL }
    } = this.props;
    let cellValue = getFieldValue(feature, attr);
    if (cellValue === "" || isNullOrUndefined(cellValue)) {
      if (!editable && !allowEmptyCells)
        return [FEATURE_ATTRIBUTE_NO_VALUE_LABEL];
      else if (isNullOrUndefined(cellValue)) cellValue = "";
    }
    return typeof cellValue === "string" ? cellValue.split("||") : [cellValue];
  };

  checkForInvalidValues = (cellInfo) => {
    const { handleOnChange } = this.props;
    if (!handleOnChange) return;
    const { options, values, fieldType, objectId, attribute, parentAttribute } =
      cellInfo;
    const selectTypes = ["checkbox", "select", "boolean", "troolean"];
    if (!selectTypes.includes(fieldType)) return;

    let updatedValues;

    //handle multi select because these are arrays
    if (fieldType === "checkbox") {
      if (
        values.every(
          (value) =>
            isBlankValue(value, fieldType) ||
            options.find((option) => value.includes(option.value))
        )
      )
        return;
      updatedValues = values.reduce((result, value) => {
        const relatedValue = options.find((option) =>
          value.includes(option.value)
        );
        if (relatedValue) return [...result, value];
        else {
          let newValue = [];
          if (options.length === 1 && isNullOrUndefined(value))
            newValue = [options[0].value];
          return [...result, newValue];
        }
      }, []);
    } else {
      if (
        values.every(
          (value) =>
            value === "" ||
            isNullOrUndefined(value) ||
            options.find((item) => item.value === value)
        )
      )
        return;
      updatedValues = values.reduce((result, value) => {
        const relatedValue = options.find((item) => item.value === value);
        if (relatedValue) return [...result, value];
        else {
          let newValue = "";
          if (options.length === 1 && isNullOrUndefined(value))
            newValue = options[0].value;
          return [...result, newValue];
        }
      }, []);
    }

    handleOnChange(
      this.getUpdatedFeatures(objectId, attribute, updatedValues, false)
    );
  };

  getCellData = (feature, tableAttribute) => {
    const {
      labels: {
        DEFAULT_OPTION_VALUE_LABEL,
        GENERIC_ADD_MULTIPLE_TEXT_LABEL,
        DELETE_CELL_ITEM
      },
      editable
    } = this.props;
    const {
      colSpan,
      rowSpan,
      attribute,
      level,
      label,
      required,
      allowAddMultiple,
      addMultipleText,
      allowEmptyCells
    } = tableAttribute;
    const fieldType = this.getFieldType(feature, attribute);
    const validationRules = this.validationRules(attribute, feature);
    const options = this.getDomainValuesForItem(attribute, feature, fieldType);

    const isEditable =
      editable &&
      !this.isNonEditableAttribute(attribute, feature) &&
      (fieldType !== "select" || options.length > 1)
        ? true
        : false;

    const values = this.getCellValues(
      feature,
      attribute,
      isEditable,
      allowEmptyCells
    );
    const buttons = [];
    if (
      this.allowAddMultiple({
        allowAddMultiple,
        editable: isEditable,
        values
      })
    ) {
      buttons.push({
        label: addMultipleText || GENERIC_ADD_MULTIPLE_TEXT_LABEL,
        onClick: this.handleAddValueItem,
        align: "vertical"
      });
      if (values.length >= 2) {
        buttons.push({
          title: DELETE_CELL_ITEM,
          onClick: this.removeCellItem,
          align: "horizontal",
          icon: {
            type: "close",
            iconWidth: "1.5em",
            iconHeight: "1.5em",
            iconColor: defaultTheme.agBlue
          }
        });
      }
    }

    const cellInfo = {
      ...validationRules,
      required: required || validationRules.required || false,
      label: level ? label : null,
      fieldType,
      editable: isEditable,
      values,
      options,
      objectId: feature.attributes.objectId,
      layer: feature.layer,
      attribute,
      rowSpan,
      colSpan,
      isValid: values.every((value) =>
        isValid(value, validationRules, fieldType)
      ),
      defaultOptionLabel: DEFAULT_OPTION_VALUE_LABEL,
      verticalAlign: level ? "bottom" : "top",
      buttons
    };
    if (isEditable && values.some((value) => value !== "")) {
      this.checkForInvalidValues(cellInfo);
    }
    return cellInfo;
  };

  getLevelRows = (feature) => {
    const tableAttributes = this.getTableAttributes();
    const levels = tableAttributes
      .reduce((result, tableAttribute) => {
        const { level = 0 } = tableAttribute;
        if (result.includes(level)) return result;
        return [...result, level];
      }, [])
      .sort((a, b) => (a > b ? 1 : -1));
    const rows = levels.map((attrLevel) => {
      const levelAttributes = tableAttributes.filter(
        ({ level = 0 }) => level === attrLevel
      );
      const cells = levelAttributes.map((tableAttribute) =>
        this.getCellData(feature, tableAttribute)
      );
      if (attrLevel === 0) {
        const deleteButton = this.getDeleteButton(cells);
        if (deleteButton)
          return {
            cells: [
              ...cells,
              {
                fieldType: "node",
                attribute: "delete",
                values: [deleteButton]
              }
            ]
          };
      }
      return {
        cells
      };
    });
    return rows;
  };

  getRows = () => {
    const { getCustomFeatureContent } = this.props;
    const features = this.getFeatures();
    const tableAttributes = this.getTableAttributes();
    if (!tableAttributes.length) return [];
    const rows = features.reduce((rowResult, feature) => {
      const formattedRows = this.getLevelRows(feature);

      const customContent = getCustomFeatureContent
        ? getCustomFeatureContent(feature)
        : [];
      if (formattedRows.length)
        rowResult.push(
          ...formattedRows.map((row, i) => {
            return {
              ...row,
              showBorder:
                i === formattedRows.length - 1 && !customContent.length
            };
          })
        );

      if (customContent.length) rowResult.push(...customContent);
      return rowResult;
    }, []);
    return rows;
  };

  getNoDataLabel = () => {
    const {
      noDataLabel,
      labels: { DEFAULT_NO_FEATURES_TO_DISPLAY_LABEL }
    } = this.props;
    return noDataLabel || DEFAULT_NO_FEATURES_TO_DISPLAY_LABEL;
  };

  render() {
    const { allowAddRow, minWidth } = this.props;
    return (
      <FeatureTableWrapper>
        <FeatureTableContainer scrollable={allowAddRow}>
          <TableComponent
            headers={this.getHeaders()}
            rows={this.getRows()}
            handleOnChangeValue={this.handleOnChangeValue}
            minWidth={minWidth}
            noDataLabel={this.getNoDataLabel()}
          />
        </FeatureTableContainer>
        {this.getAddRowButton()}
      </FeatureTableWrapper>
    );
  }
}
