import { createActions } from "redux-actions";
import {
  LOADED_ORGANISATIONS,
  ERROR_ORGANISATIONS,
  LOADED_ORGANISATION,
  ERROR_ORGANISATION,
  SELECT_ORGANISATION,
  LOADED_ORGANISATION_CUSTOM_LAYERS,
  ERROR_ORGANISATION_CUSTOM_LAYERS,
  LOAD_ORGANISATIONS,
  LOAD_ORGANISATION,
  LOAD_ORGANISATION_CUSTOM_LAYERS,
  UPDATE_ORG_RENDERERS,
  SEND_LOAD_ORG_ACTION_LOG
} from "../constants";

export const {
  loadedOrganisations,
  errorOrganisations,
  loadedOrganisation,
  errorOrganisation,
  selectOrganisation,
  loadedOrganisationCustomLayers,
  errorOrganisationCustomLayers,
  loadOrganisations,
  loadOrganisation,
  loadOrganisationCustomLayers,
  updateOrgRenderers,
  sendLoadOrgActionLog
} = createActions({
  [LOADED_ORGANISATIONS]: (organisations) => organisations,
  [ERROR_ORGANISATIONS]: (error) => error,
  [LOADED_ORGANISATION]: (organisation) => organisation,
  [ERROR_ORGANISATION]: (error) => error,
  [SELECT_ORGANISATION]: (org) => org,
  [LOADED_ORGANISATION_CUSTOM_LAYERS]: (layers) => ({ layers }),
  [ERROR_ORGANISATION_CUSTOM_LAYERS]: (error) => ({ error }),
  [LOAD_ORGANISATIONS]: () => {},
  [LOAD_ORGANISATION]: (orgId) => ({ orgId }),
  [LOAD_ORGANISATION_CUSTOM_LAYERS]: () => {},
  [UPDATE_ORG_RENDERERS]: (renderers) => renderers,
  [SEND_LOAD_ORG_ACTION_LOG]: (orgId) => ({ orgId })
});
