import React from "react";
import PropTypes from "prop-types";
import { Button } from "../..";
import { defaultTheme } from "../../../..";
import Icon from "../../Icon/Icon";

const SidebarCloseButton = ({ description, onClick }) => {
    return (
        <Button
            type="button"
            styletype="sidebarCloseButton"
            data-name={"Button"}
            title={description}
            onClick={onClick}
        >
            <Icon
                data-name={"Icon"}
                iconColor={defaultTheme.agWhite}
                type={"close"}
                iconHeight={"1.25em"}
                iconWidth={"1.25em"}
                bgShape="square"
            />
        </Button>
    );
};

SidebarCloseButton.propTypes = {
    /** Accesible label for the button */
    description: PropTypes.string,
    /** Called on click of button */
    onClick: PropTypes.func
};

export default SidebarCloseButton;