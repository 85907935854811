import React from "react";
import { Fieldset, Legend } from "../../../../../core/components";
import PropTypes from "prop-types";
import {
  CompoundInputContentWrapper,
  CompoundInputFieldWrapper,
  CompoundInputContainer,
  FeatureDetailsItemInputHelpText
} from "../../..";

const CompoundInput = (props) => {
  return (
    <CompoundInputContainer>
      <Fieldset>
        <Legend styletype="label" type={!props.isValid ? "formError" : ""}>
          {props.label}
        </Legend>
        <CompoundInputContentWrapper>
          {props.inputs.map((item, index) => (
            <CompoundInputFieldWrapper key={`${props.attribute}${index}`}>
              {item}
            </CompoundInputFieldWrapper>
          ))}
        </CompoundInputContentWrapper>
        {props.helpText && (
          <FeatureDetailsItemInputHelpText
            id={`${props.attribute}-help-text`}
            invalid={!props.isValid}
            aria-live="assertive"
          >
            {props.helpText}
          </FeatureDetailsItemInputHelpText>
        )}
      </Fieldset>
    </CompoundInputContainer>
  );
};

CompoundInput.propTypes = {
  /** The label for the compound input */
  label: PropTypes.string,
  /** If the overall field is valid */
  isValid: PropTypes.bool,
  /** The inputs to render */
  inputs: PropTypes.arrayOf(PropTypes.node)
};

export default CompoundInput;
