import { connect } from "react-redux";
import AreaSearch from "./AreaSearch";
import { getWebMap, getLabel } from "../../../selectors";
import * as actions from "../../../actions";

export default connect(
  (state) => ({
    webMap: getWebMap(state),
    labels: getLabel(state)
  }),
  actions
)(AreaSearch);
