import React, { Component } from "react";
import { ButtonLink, P } from "../AgBoxUIKit/core/components";
import {
  LogoImage,
  AuthenticationContainer,
  AuthenticationContentWrap,
  AuthenticationWrapper
} from "../AgBoxUIKit/core/layout";

export default class Logout extends Component {
  componentDidMount() {
    const { clearAuthentication } = this.props;
    clearAuthentication();
  }

  resetLoggingOut = () => {
    const { resetLoggingOut } = this.props;
    resetLoggingOut();
  };

  render() {
    const {
      LOG_OUT_SUCCESSFUL_LABEL,
      LOG_OUT_CLOSE_BROWSER_LABEL,
      LOG_BACK_IN_LABEL
    } = this.props.labels;
    return (
      <AuthenticationWrapper>
        <AuthenticationContainer logout={true}>
          <AuthenticationContentWrap>
            <LogoImage
              alt="Landkind logo"
              src={
                "https://agbox-assets.s3-us-west-2.amazonaws.com/logos/landkind.svg"
              }
            />
            <P>{LOG_OUT_SUCCESSFUL_LABEL}</P>
            <P>{LOG_OUT_CLOSE_BROWSER_LABEL}</P>
            <ButtonLink
              type="primary"
              to={"../"}
              onClick={this.resetLoggingOut}
            >
              {LOG_BACK_IN_LABEL}
            </ButtonLink>
          </AuthenticationContentWrap>
        </AuthenticationContainer>
      </AuthenticationWrapper>
    );
  }
}
