import React, { Component } from "react";
import { PaginationComponent, Loader } from "../../AgBoxUIKit/core";
import { PaginationWrapper } from "../../AgBoxUIKit/core/layout";
import {
  DEFAULT_PAGINATION_STRING,
  DEFAULT_PAGINATION_VALUE,
  LOADER_SIZE_SMALL
} from "../../constants";
import { defaultTheme } from "../../AgBoxUIKit";

export default class Pagination extends Component {
  isLoading = () => {
    const { loading } = this.props;
    return loading;
  };
  getLanguageLabel = (label, data) => {
    const { getLanguageLabel } = this.props.core;
    return getLanguageLabel(label, data);
  };
  firstPageDisabled = () => {
    const { page } = this.props;
    return page === 1;
  };
  lastPageDisabled = () => {
    const currentPage = this.currentPage();
    const totalPages = this.pageCount();
    return currentPage === totalPages;
  };
  pageCount = () => {
    const { limit, total } = this.props;
    const parsedLimit =
      limit && limit !== DEFAULT_PAGINATION_STRING
        ? parseInt(limit)
        : DEFAULT_PAGINATION_VALUE;
    if (!total || !parsedLimit) return 0;
    return Math.ceil(total / parsedLimit);
  };
  currentPage = () => {
    const { page } = this.props;
    return page ? page : 1;
  };
  pageNumbers = () => {
    const pageCount = this.pageCount();
    return new Array(pageCount).fill(null).map((v, i) => i + 1);
  };
  visiblePageNumbers = () => {
    const { page } = this.props;
    const pageCount = this.pageCount();
    const pagesNumbers = this.pageNumbers();
    const currentIndex = page - 1;

    let startingIndex;
    let endingIndex;

    if (page === 1) {
      startingIndex = 0;
      endingIndex = 3;
    } else if (page === pageCount) {
      startingIndex = currentIndex > 1 ? currentIndex - 2 : 0;
      endingIndex = pageCount;
    } else {
      startingIndex = currentIndex - 1;
      endingIndex = currentIndex + 2;
    }
    const visiblePageNumbers = pagesNumbers.slice(startingIndex, endingIndex);
    return visiblePageNumbers;
  };
  isActivePage = (pageNumber) => {
    return pageNumber === this.currentPage();
  };
  handleSelectPage = (pageNumber) => {
    const { limit } = this.props;
    const newOffset = limit * (pageNumber - 1);

    const { onPageSelected } = this.props;
    if (!onPageSelected) return;
    onPageSelected({ offset: newOffset, limit, page: pageNumber });
  };
  handleSelectFirstPage = () => {
    this.handleSelectPage(1);
  };
  handleSelectLastPage = () => {
    const totalPages = this.pageCount();
    this.handleSelectPage(totalPages);
  };
  buttonColor = () => {
    const { buttonColor } = this.props;
    return buttonColor;
  };
  render() {
    return this.isLoading() ? (
      <Loader
        visible={true}
        size={LOADER_SIZE_SMALL}
        loadingText={this.props.labels.LOADING_PROPERTIES_LABEL}
        textColor={defaultTheme.agWhite}
      />
    ) : (
      <PaginationComponent
        pageCount={this.pageCount()}
        firstPageDisabled={this.firstPageDisabled()}
        lastPageDisabled={this.lastPageDisabled()}
        visiblePages={this.visiblePageNumbers()}
        handleSelectFirstPage={this.handleSelectFirstPage}
        handleSelectLastPage={this.handleSelectLastPage}
        buttonColor={this.buttonColor()}
        firstPageLabel={this.props.labels.FIRST_PAGE_LABEL}
        lastPageLabel={this.props.labels.LAST_PAGE_LABEL}
        handleSelectPage={this.handleSelectPage}
        isActivePage={this.isActivePage}
        limitOptions={this.props.limitOptions}
        onChangeLimit={this.props.onChangeLimit}
        limit={this.props.limit}
        limitLabel={this.props.labels.SHOW_PER_PAGE_LABEL}
        showLimitDropdown={this.props.showLimitDropdown}
      />
    );
  }
}
