import React, { Component } from "react";
import { Announcement } from "../AgBoxUIKit/core/layout";
import { isNullOrUndefined, roundNumber } from "../App/utils";

export default class MapReader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rotation: 0,
      center: null,
      scale: null,
      zoom: null,
      rotationListener: null,
      zoomListener: null,
      centerListener: null,
      rotationAnnouncement: null
    };
    this.rotationTimer = null;
    this.zoomTimer = null;
    this.centerTimer = null;
  }
  componentDidMount() {
    this.setupListeners();
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.mapView && this.props.mapView) {
      this.setupListeners();
    }
  }

  componentWillUnmount() {
    this.unloadListeners();
  }

  setupListeners = () => {
    this.setupZoomListener();
    this.setupRotationListener();
    this.setupCenterListener();
  };

  setupRotationListener = () => {
    const { mapView, has3D } = this.props;
    if (!mapView) return;
    if (mapView.camera || !isNullOrUndefined(mapView.rotation)) {
      this.updateRotation(
        has3D ? 360 - mapView.camera.heading : mapView.rotation
      );
    }

    const rotationListenerObject = has3D ? "camera" : "rotation";
    const rotationListener = mapView.watch(
      rotationListenerObject,
      (newValue) => {
        if (has3D) {
          this.updateRotation(roundNumber(360 - newValue.heading, 2));
        } else {
          this.updateRotation(roundNumber(newValue, 2));
        }
      }
    );
    this.setState({
      rotationListener
    });
  };

  updateRotation = (rotation) => {
    if (isNullOrUndefined(rotation)) return;
    if (this.rotationTimer) {
      clearTimeout(this.rotationTimer);
      this.rotationTimer = null;
    }
    this.rotationTimer = setTimeout(() => {
      this.setState({
        rotation
      });
    }, 500);
  };

  setupZoomListener = () => {
    const { mapView } = this.props;
    if (!mapView) return;
    this.updateZoom(mapView.zoom);
    const zoomListener = mapView.watch("zoom", (newValue) => {
      this.updateZoom(newValue);
    });
    this.setState({
      zoomListener
    });
  };

  updateZoom = (zoom) => {
    if (isNullOrUndefined(zoom)) return;
    if (this.zoomTimer) {
      clearTimeout(this.zoomTimer);
      this.zoomTimer = null;
    }
    this.zoomTimer = setTimeout(() => {
      this.setState({
        zoom
      });
    }, 500);
  };

  setupCenterListener = () => {
    const { mapView } = this.props;
    if (!mapView) return;
    this.updateCenter(mapView.center);
    const centerListener = mapView.watch("center", (newValue) => {
      this.updateCenter(newValue);
    });
    this.setState({
      centerListener
    });
  };

  updateCenter = (center) => {
    if (!center) return;
    if (this.centerTimer) {
      clearTimeout(this.centerTimer);
      this.centerTimer = null;
    }
    this.centerTimer = setTimeout(() => {
      this.setState({
        center: {
          latitude: roundNumber(center.latitude, 2),
          longitude: roundNumber(center.longitude, 2)
        }
      });
    }, 500);
  };

  unloadListeners = () => {
    const { zoomListener, centerListener, rotationListener } = this.state;
    if (zoomListener) zoomListener.remove();
    if (centerListener) centerListener.remove();
    if (rotationListener) rotationListener.remove();
  };

  render() {
    const { MAP_CENTER_TEXT, MAP_ZOOM_TEXT, MAP_ROTATION_TEXT } =
      this.props.labels;
    return (
      <div>
        <Announcement aria-live="polite" aria-atomic="true">
          {!isNullOrUndefined(this.state.rotation) && (
            <p>
              {MAP_ROTATION_TEXT}
              {this.state.rotation}.
            </p>
          )}
          {!isNullOrUndefined(this.state.zoom) && (
            <p>
              {MAP_ZOOM_TEXT}
              {this.state.zoom}.
            </p>
          )}
          {this.state.center && (
            <p>
              {MAP_CENTER_TEXT}
              {this.state.center.latitude},{this.state.center.longitude}.
            </p>
          )}
        </Announcement>
        {this.props.children}
      </div>
    );
  }
}
