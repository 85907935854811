import { connect } from "react-redux";
import Pack from "./Pack";
import * as actions from "../actions";
import {
  getSelectedProperty,
  getSelectedPropertyGroup,
  getPropertiesError,
  getPropertyGroupsError,
  getWorkflowPanelOpen,
  getPropertiesSelecting
} from "../selectors";

export default connect(
  (state) => ({
    selectedProperty: getSelectedProperty(state),
    selectedPropertyGroup: getSelectedPropertyGroup(state),
    propertyError: getPropertiesError(state),
    propertyGroupError: getPropertyGroupsError(state),
    workflowPanelOpen: getWorkflowPanelOpen(state),
    selectingProperty: getPropertiesSelecting(state)
  }),
  actions
)(Pack);
