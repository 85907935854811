import React, { Component } from "react";
import {
  SuggestionList,
  SuggestionListItem,
  SearchInputWrapper,
  SearchInputContainer
} from "../../layout";
import PropTypes from "prop-types";
import { Icon } from "../..";
import { defaultTheme } from "../../..";
import { Button, Input } from "..";

export default class SearchCombobox extends Component {
  static propTypes = {
    /** suggestions items must have a property 'text' and then can have anything else in the object */
    suggestions: PropTypes.arrayOf(PropTypes.object),
    onSelectSuggestion: PropTypes.func,
    selectedSuggestion: PropTypes.object,
    searchTerm: PropTypes.string,
    searchInputDisabled: PropTypes.bool,
    clearSearchTerm: PropTypes.func,
    showSuggestions: PropTypes.bool,
    id: PropTypes.string,
    ariaLabel: PropTypes.string
  };
  suggestions = () => {
    const { suggestions } = this.props;
    return suggestions ? suggestions : [];
  };

  onSelectSuggestion = (e) => {
    const index = Number(e.currentTarget.dataset.index);
    const { onSelectSuggestion } = this.props;
    if (onSelectSuggestion) onSelectSuggestion(this.suggestions()[index]);
  };

  selectedSuggestion = () => {
    const { selectedSuggestion } = this.props;
    return selectedSuggestion ? selectedSuggestion : {};
  };

  suggestionIsSelected = (suggestion) => {
    return this.selectedSuggestion().text === suggestion.text;
  };

  searchTerm = () => {
    const { searchTerm } = this.props;
    return searchTerm ? searchTerm : "";
  };

  handleUpdateSearchTerm = (e) => {
    const { updateSearchTerm } = this.props;
    if (updateSearchTerm) updateSearchTerm(e.target.value);
  };

  searchInputDisabled = () => {
    const { searchInputDisabled } = this.props;
    return searchInputDisabled === true;
  };

  handleClearSearchTerm = () => {
    const { clearSearchTerm } = this.props;
    if (clearSearchTerm) clearSearchTerm();
  };

  showSuggestions = () => {
    const { showSuggestions } = this.props;
    return showSuggestions === true;
  };

  getAriaLabel = () => {
    const { ariaLabel } = this.props;
    return ariaLabel ? ariaLabel : "";
  };

  getAriaExpanded = () => {
    const { ariaExpanded } = this.props;
    return ariaExpanded ? ariaExpanded : "";
  };

  render() {
    return (
      <SearchInputWrapper data-name={"SearchInputWrapper"}>
        <SearchInputContainer data-name={"SearchInputContainer"}>
          <Icon
            data-name={"Icon"}
            iconColor={defaultTheme.agBlue}
            type={"search"}
            bgHeight={"2.5em"}
            bgWidth={"2.5em"}
            iconHeight={"1.1em"}
            iconWidth={"1.1em"}
          />
          <Input
            id={this.props.id}
            type={"text"}
            value={this.searchTerm()}
            onChange={this.handleUpdateSearchTerm}
            disabled={this.searchInputDisabled()}
            aria-owns={
              this.showSuggestions() ? `${this.props.id}-autocomplete` : null
            }
            aria-autocomplete={this.showSuggestions() ? "list" : null}
            role="combobox"
            aria-label={this.getAriaLabel()}
            aria-expanded={this.getAriaExpanded()}
          />
          <Button
            data-name={"Button"}
            onClick={this.handleClearSearchTerm}
            title="Clear search"
            styletype={"clearSearch"}
            type="reset"
            hidden={this.searchTerm() === ""}
            disabled={this.searchInputDisabled()}
          >
            <Icon
              data-name={"Icon"}
              iconColor={defaultTheme.agRed}
              type="close"
              bgHeight={"2.5em"}
              bgWidth={"2.5em"}
              iconHeight={"0.8em"}
              iconWidth={"0.8em"}
            />
          </Button>
        </SearchInputContainer>
        <SuggestionList role="listbox" id={`${this.props.id}-autocomplete`}>
          {this.suggestions().map((suggestion, i) => (
            <SuggestionListItem
              key={`${suggestion.text}_${i}`}
              role="option"
              tabIndex={0}
              aria-selected={this.suggestionIsSelected(suggestion)}
              onClick={this.onSelectSuggestion}
              selected={this.suggestionIsSelected(suggestion)}
              hidden={!this.showSuggestions()}
              data-index={i}
            >
              {suggestion.text}
            </SuggestionListItem>
          ))}
        </SuggestionList>
      </SearchInputWrapper>
    );
  }
}
