import React, { Component } from "react";
import {
  WidgetCloseButton,
  Button,
  P,
  MeasureWidgetText,
  MeasureWidgetLabel
} from "@agBoxUiKit/core/components";
import {
  WidgetWrapper,
  WidgetContainer,
  WidgetPanelWrapper,
  WidgetPanelContainer,
  WidgetLoaderWrapper,
  WidgetLoaderContainer,
  MeasureWidgetTextWrapper,
  MeasureWidgetTextContainer,
  WidgetHeading
} from "@agBoxUiKit/core/layout";
import { Loader } from "@agBoxUiKit/core";
import { Icon } from "@agBoxUiKit/core";
import { defaultTheme } from "@agBoxUiKit";
import {
  sentenceCase,
  roundNumber,
  toLocale,
  decodeComputedString,
  projectGeometries,
  isNullOrUndefined
} from "../../App/utils";
import {
  TEMP_LAYER_PREFIX,
  MEASURE_WIDGET_TEXT_SYMBOL,
  MEASURE_WIDGET_POINT_SYMBOL,
  MEASURE_WIDGET_NAME,
  DEFAULT_UNIT_HECTARES,
  MEASURE_WIDGET_POLY_SYMBOL,
  MEASURE_WIDGET_LINE_SYMBOL,
  UNIT_METERS,
  UNIT_HECTARES,
  METERS_SHORT_UNIT
} from "../../constants";
import { DrawingTool } from "../../Components";
import { NotificationManager } from "react-notifications";
import DropDown from "../../AgBoxUIKit/core/components/FormComponents/DropDown";
import GraphicsLayer from "@arcgis/core/layers/GraphicsLayer";
import Graphic from "@arcgis/core/Graphic";
import { planarArea, planarLength } from "@arcgis/core/geometry/geometryEngine";
import Polygon from "@arcgis/core/geometry/Polygon";
import Polyline from "@arcgis/core/geometry/Polyline";
import PropTypes from "prop-types";

/**
 * A widget to allow the user to measure area, distance, and location.
 *
 * Projects geometries to the specified spatial reference as set in property and/or organisation, and defaults to 102100 if both of these are invalid. Shows a notification when spatial reference is not valid for calculations.
 *
 */
class Measure extends Component {
  static propTypes = {
    /** Whether to remove the graphics from the sketchLayer on mount */
    activeWidget: PropTypes.string,
    openWidget: PropTypes.func,
    closeWidget: PropTypes.func,
    /** The webmap object */
    webMap: PropTypes.object,
    /** The property spatial reference */
    propertySpatialReference: PropTypes.number,
    /** The organisation spatial reference */
    orgSpatialReference: PropTypes.number,
    /** The preferences object */
    preferences: PropTypes.object,
    /** The labels object */
    labels: PropTypes.object,
    /** Whether the webmap is loading */
    webMapLoading: PropTypes.bool,
    /** Whether the widget is disabled */
    disabled: PropTypes.bool
  };

  static defaultProps = {
    activeWidget: MEASURE_WIDGET_NAME,
    disabled: false
  };

  constructor(props) {
    super(props);

    this.state = {
      activeTool: null,
      unit: null,
      measurementValues: null,
      sketchTool: null,
      graphic: null,
      hasWarningNotification: false
    };

    this.timer = null;
    this.controller = new AbortController();
    this.widgetWrap = React.createRef();
  }

  componentWillUnmount() {
    this.controller.abort();
    document.removeEventListener("keydown", this.escapeToClose);
  }

  componentDidMount() {
    document.addEventListener("keydown", this.escapeToClose);
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.activeWidget === MEASURE_WIDGET_NAME &&
      this.props.activeWidget !== MEASURE_WIDGET_NAME
    ) {
      this.handleDestroyTool();
    }
  }

  escapeToClose = (e) => {
    if (e.key !== "Escape" || !this.showPanel()) return;
    this.handleTogglePanel();
  };

  showPanel = () => {
    const { activeWidget } = this.props;
    return activeWidget === MEASURE_WIDGET_NAME;
  };

  handleTogglePanel = () => {
    const { openWidget, closeWidget } = this.props;
    if (this.showPanel()) {
      closeWidget();
    } else openWidget(MEASURE_WIDGET_NAME);
  };

  handleDestroyTool = () => {
    const { webMap } = this.props;
    const sketchLayer = webMap.layers.items.find(
      (layer) => layer.title === `${TEMP_LAYER_PREFIX}_MEASURE_SKETCH_TOOL`
    );
    const labelLayer = this.getLabelLayer();
    const layersToRemove = [sketchLayer, labelLayer].filter(
      (layer) => layer !== null && layer !== undefined
    );
    webMap.removeMany(layersToRemove);
    this.setState({
      measurementValues: null,
      activeTool: null,
      hasWarningNotification: false
    });
  };

  removeLabels = () => {
    const labelLayer = this.getLabelLayer();
    if (!labelLayer) return;
    labelLayer.removeAll();
  };

  handleUnitChange = (key, unit) => {
    const { graphic } = this.state;
    const units = this.getUnitOptions();
    const matchingUnit = units[key].find((item) => item.unit === unit);
    // when the user changes the unit of measurement, tell the tool's viewModel
    this.setState(
      {
        unit: {
          ...this.state.unit,
          [key]: matchingUnit
        }
      },
      () => {
        if (graphic) {
          this.handleMeasurements(graphic);
        }
      }
    );
  };

  getMeasurementValues = () => {
    const { measurementValues } = this.state;
    return measurementValues ? measurementValues : null;
  };

  getActiveTool = () => {
    const { activeTool } = this.state;
    return activeTool;
  };

  getUnit = () => {
    const { unit } = this.state;
    return unit;
  };

  getUnitOptions = () => {
    const { preferences } = this.props;

    if (!preferences || !preferences.units) {
      return {
        areaUnits: [
          {
            unit: UNIT_HECTARES,
            shortname: DEFAULT_UNIT_HECTARES
          }
        ],
        distanceUnits: [
          {
            unit: UNIT_METERS,
            shortname: METERS_SHORT_UNIT
          }
        ]
      };
    } else {
      return {
        areaUnits: preferences.units.areaUnits,
        distanceUnits: preferences.units.distanceUnits
      };
    }
  };

  setUnits = () => {
    const { preferences } = this.props;
    const { units } = preferences;
    const { areaUnits, distanceUnits } = units;
    const firstAreaUnit = this.getMatchingUnit("areaUnits")
      ? this.getMatchingUnit("areaUnits")
      : areaUnits[0];
    const firstDistanceUnit = this.getMatchingUnit("distanceUnits")
      ? this.getMatchingUnit("distanceUnits")
      : distanceUnits[0];
    this.setState({
      unit: {
        areaUnits: firstAreaUnit,
        distanceUnits: firstDistanceUnit
      }
    });
  };

  drawingTools = () => {
    const { AREA_TOOL_LABEL, DISTANCE_TOOL_LABEL, LOCATION_LABEL } =
      this.props.labels;
    return [
      {
        label: AREA_TOOL_LABEL,
        param: "polygon",
        styletype: "measureWidgetButton"
      },
      {
        label: DISTANCE_TOOL_LABEL,
        param: "polyline",
        styletype: "measureWidgetButton"
      },
      {
        label: LOCATION_LABEL,
        param: "point",
        styletype: "measureWidgetButton"
      }
    ];
  };

  onSetupSketchTool = (sketchTool) => {
    this.setState({
      sketchTool
    });
  };

  onUpdateSketchDraw = (event) => {
    const { graphics } = event;
    if (this.timer) clearTimeout(this.timer);
    this.timer = setTimeout(() => this.handleMeasurements(graphics[0]), 100);
  };

  handleMeasurements = (graphic) => {
    this.timer = null;
    this.controller.abort();
    this.controller = new AbortController();
    const { activeTool } = this.state;
    this.setState({
      graphic
    });
    if (activeTool === "polygon") {
      this.measureArea(graphic);
    } else if (activeTool === "polyline") {
      this.measureDistance(graphic);
    } else {
      this.measureLocation(graphic);
    }
  };

  onCompleteSketch = (event) => {
    if (this.timer) clearTimeout(this.timer);
    this.timer = setTimeout(() => this.handleMeasurements(event.graphic), 5);
  };

  onResetSketch = () => {
    this.setState({
      measurementValues: null,
      graphic: null
    });
    this.removeLabels();
  };

  onCreateTool = (toolType) => {
    if (this.timer) {
      clearTimeout(this.timer);
    }
    this.controller.abort();
    this.controller = new AbortController();
    this.removeLabels();
    if (toolType === this.state.activeTool) return;
    this.setState({
      activeTool: toolType
    });
    if (!this.state.unit) this.setUnits();
  };

  getLanguageLabel = (stringConstant, data) => {
    const { labels } = this.props;
    const label = labels[stringConstant];
    if (!label) return stringConstant;
    return decodeComputedString(label, data);
  };

  projectGraphics = async (geometry) => {
    const { orgSpatialReference, propertySpatialReference } = this.props;

    const { outGeometries, warning, error } = await projectGeometries(
      [geometry],
      propertySpatialReference,
      orgSpatialReference
    );

    if (warning && !this.state.hasWarningNotification) {
      this.setState({
        hasWarningNotification: true
      });
      NotificationManager.warning(
        `${sentenceCase(
          this.getLanguageLabel("SPATIAL_REFERENCE_CALC_MESSAGE_LABEL", warning)
        )}`,
        this.getLanguageLabel("UNABLE_TO_PROJECT_LABEL"),
        0
      );
    } else if (error) {
      NotificationManager.error(
        this.getLanguageLabel(error.message),
        this.getLanguageLabel("ERROR_PROJECTING_SPATIAL_REFERENCE_LABEL"),
        0
      );
    }
    return outGeometries[0];
  };

  calculatePerimeter = (geometry) => {
    const { unit } = this.state;
    const { rings } = geometry;
    if (!rings || rings.length === 0) return;
    const ringPairs = rings[0];
    const lines = ringPairs
      .map((ring, i) => {
        if (i + 1 !== ringPairs.length) {
          const line = new Polyline({
            paths: [ring, ringPairs[i + 1]],
            spatialReference: geometry.spatialReference
          });
          return line;
        } else return false;
      })
      .filter((line) => line !== false);

    const lineLengths = lines.map((line) =>
      planarLength(line, unit.distanceUnits.unit)
    );

    const sum = lineLengths.reduce(
      (a, b) => (!isNullOrUndefined(b) ? a + b : a),
      0
    );
    if (
      lineLengths.filter((item) => isNullOrUndefined(item)).length ===
      lines.length
    )
      return false;

    return sum;
  };

  measureArea = async (graphic) => {
    const { labels } = this.props;
    const {
      AREA_TOOL_LABEL,
      PERIMETER_TOOL_LABEL,
      TOTAL_AREA_ERROR_MESSAGE_LABEL,
      PERIMETER_ERROR_MESSAGE_LABEL
    } = labels;
    const { unit } = this.state;
    const geometry = await this.projectGraphics(graphic.geometry);
    const area = planarArea(geometry, unit.areaUnits.unit);
    const perimeter =
      geometry.rings.length > 0 ? this.calculatePerimeter(geometry) : 0.0;
    this.setState({
      measurementValues: [
        {
          label: AREA_TOOL_LABEL,
          value: !isNullOrUndefined(area)
            ? `${toLocale(roundNumber(area, 2))} ${this.getShortUnit(
                unit.areaUnits
              )}`
            : TOTAL_AREA_ERROR_MESSAGE_LABEL
        },
        {
          label: PERIMETER_TOOL_LABEL,
          value:
            perimeter !== false
              ? `${toLocale(roundNumber(perimeter, 2))} ${this.getShortUnit(
                  unit.distanceUnits
                )}`
              : PERIMETER_ERROR_MESSAGE_LABEL
        }
      ]
    });

    if (!isNullOrUndefined(area) && area !== 0) {
      this.createLabels(graphic.geometry, [
        `${toLocale(roundNumber(area, 2))} ${this.getShortUnit(unit.areaUnits)}`
      ]);
    }
  };

  getMatchingUnit = (key) => {
    const { preferences } = this.props;
    const { units } = preferences;
    const {
      preferredAreaUnit,
      areaUnits,
      distanceUnits,
      preferredDistanceUnit
    } = units;
    if (key === "distanceUnits")
      return preferredDistanceUnit
        ? distanceUnits.find((item) => item.unit === preferredDistanceUnit)
        : null;
    return preferredAreaUnit
      ? areaUnits.find((item) => item.unit === preferredAreaUnit)
      : null;
  };

  getShortUnit = (unit) => {
    return unit.shortname ? unit.shortname : unit.unit ? unit.unit : unit;
  };

  measureDistance = async (graphic) => {
    const { labels } = this.props;
    const { DISTANCE_ERROR_MESSAGE_LABEL, DISTANCE_TOOL_LABEL } = labels;
    const { unit } = this.state;
    const geometry = await this.projectGraphics(graphic.geometry);
    const distance = planarLength(geometry, unit.distanceUnits.unit);
    this.setState({
      measurementValues: [
        {
          label: DISTANCE_TOOL_LABEL,
          value: !isNullOrUndefined(distance)
            ? `${toLocale(roundNumber(distance, 2))} ${this.getShortUnit(
                unit.distanceUnits
              )}`
            : DISTANCE_ERROR_MESSAGE_LABEL
        }
      ]
    });

    if (!isNullOrUndefined(distance)) {
      this.createLabels(graphic.geometry, [
        `${toLocale(roundNumber(distance, 2))} ${this.getShortUnit(
          unit.distanceUnits
        )}`
      ]);
    }
  };

  measureLocation = (graphic) => {
    const { webMap, labels } = this.props;
    const { LATITUDE_LABEL, LONGITUDE_LABEL } = labels;
    const sketchLayer = webMap.layers.items.find(
      (layer) => layer.title === `${TEMP_LAYER_PREFIX}_MEASURE_SKETCH_TOOL`
    );
    sketchLayer.removeAll();
    sketchLayer.add(graphic);
    const { sketchTool } = this.state;
    const lat = roundNumber(graphic.geometry.latitude, 5);
    const long = roundNumber(graphic.geometry.longitude, 5);
    this.setState({
      measurementValues: [
        { label: LATITUDE_LABEL, value: lat },
        { label: LONGITUDE_LABEL, value: long }
      ]
    });
    this.createLabels(graphic.geometry, toLocale([lat, long]));
    sketchTool.create("point");
  };

  createLabels = (geometry, values) => {
    let labelLayer = this.getLabelLayer();
    if (labelLayer && labelLayer.graphics.items.length > 0)
      labelLayer.removeAll();
    if (!labelLayer) labelLayer = this.createLabelLayer();
    let textSymbol = MEASURE_WIDGET_TEXT_SYMBOL;
    textSymbol.text = values.map((value) => `${value}`).join(",");

    const labelGeometry =
      geometry.type === "polyline"
        ? Polygon.fromExtent(geometry.extent)
        : geometry;
    const labelGraphic = new Graphic({
      geometry: labelGeometry,
      symbol: textSymbol,
      visible: true
    });
    labelLayer.add(labelGraphic);
  };

  getLabelLayer = () => {
    const { webMap } = this.props;
    return webMap.layers.items.find(
      (layer) => layer.title === `${TEMP_LAYER_PREFIX}_MEASURE_LABELS`
    );
  };

  createLabelLayer = () => {
    const { webMap } = this.props;
    const labelLayer = new GraphicsLayer({
      title: `${TEMP_LAYER_PREFIX}_MEASURE_LABELS`,
      visible: true
    });

    webMap.add(labelLayer);
    return labelLayer;
  };

  customSymbols = () => {
    return {
      pointSymbol: MEASURE_WIDGET_POINT_SYMBOL,
      polygonSymbol: MEASURE_WIDGET_POLY_SYMBOL,
      lineSymbol: MEASURE_WIDGET_LINE_SYMBOL
    };
  };

  showUnitOptionsByType = (type) => {
    const { activeTool } = this.state;
    if (!activeTool || activeTool === "point") return false;
    else if (activeTool === "polygon") return true;
    else if (type === "areaUnits") return false;
    else return true;
  };

  getSelectedUnit = (key) => {
    const { unit } = this.state;
    return unit && unit[key] ? unit[key].unit : null;
  };

  render() {
    const { webMapLoading, labels } = this.props;
    const {
      MEASURE_WIDGET_HEADING_LABEL,
      MEASURE_WIDGET_HELP_TEXT_LABEL,
      NEW_MEASURE_BUTTON_LABEL,
      AREA_UNIT_LABEL,
      DISTANCE_UNIT_LABEL
    } = labels;
    return (
      <WidgetWrapper
        data-name={"WidgetWrapper"}
        type="measure"
        disabled={this.props.disabled}
        ref={this.widgetWrap}
      >
        <WidgetContainer data-name={"WidgetContainer"}>
          <Button
            onClick={this.handleTogglePanel}
            title="Measure"
            type="button"
            styletype="widgetButton"
            disabled={this.props.disabled}
            tabIndex={0}
          >
            <Icon
              type="measure"
              data-name={"Icon"}
              iconColor={defaultTheme.agWhite}
              iconHeight={"2em"}
              iconWidth={"2em"}
            />
          </Button>

          {this.showPanel() ? (
            <WidgetPanelWrapper
              type="measure"
              data-name={"WidgetPanelWrapper"}
              role="dialog"
              tabIndex={0}
            >
              {webMapLoading ? (
                <WidgetLoaderWrapper data-name={"WidgetLoaderWrapper"}>
                  <WidgetLoaderContainer data-name={"WidgetLoaderContainer"}>
                    <Loader />
                  </WidgetLoaderContainer>
                </WidgetLoaderWrapper>
              ) : null}
              <WidgetPanelContainer
                type="measure"
                data-name={"WidgetPanelContainer"}
              >
                <WidgetHeading>{MEASURE_WIDGET_HEADING_LABEL}</WidgetHeading>
                <WidgetCloseButton
                  onClick={this.handleTogglePanel}
                  data-name={"WidgetCloseButton"}
                  type="button"
                >
                  <Icon
                    type="close"
                    data-name={"Icon"}
                    iconColor={defaultTheme.agBlack}
                    bgHeight={"1.8em"}
                    bgWidth={"1.8em"}
                    iconHeight={"0.8em"}
                    iconWidth={"0.8em"}
                  />
                </WidgetCloseButton>
                <hr />
                <P>{MEASURE_WIDGET_HELP_TEXT_LABEL}</P>

                <DrawingTool
                  tools={this.drawingTools()}
                  onComplete={this.onCompleteSketch}
                  onUpdate={this.onUpdateSketchDraw}
                  onReset={this.onResetSketch}
                  customResetText={NEW_MEASURE_BUTTON_LABEL}
                  onCreate={this.onCreateTool}
                  keepSelectionOnReset={true}
                  onSketchToolCreate={this.onSetupSketchTool}
                  watchStates={["active", "complete"]}
                  sketchLayerTitle={`${TEMP_LAYER_PREFIX}_MEASURE_SKETCH_TOOL`}
                  customSymbols={this.customSymbols()}
                  resetAfterCancel={false}
                />
                {Object.keys(this.getUnitOptions()).map((key) => {
                  return (
                    this.showUnitOptionsByType(key) && (
                      <DropDown
                        key={key}
                        label={
                          key === "areaUnits"
                            ? AREA_UNIT_LABEL
                            : DISTANCE_UNIT_LABEL
                        }
                        id={`select-${key}`}
                        onChange={(e) =>
                          this.handleUnitChange(key, e.target.value)
                        }
                        value={this.getSelectedUnit(key)}
                        options={this.getUnitOptions()[key].map((unit) => ({
                          value: unit.unit,
                          title: unit.unit
                        }))}
                      />
                    )
                  );
                })}

                {this.getMeasurementValues() ? (
                  <MeasureWidgetTextWrapper>
                    {this.getMeasurementValues().map((measurementItem) => {
                      return (
                        <MeasureWidgetTextContainer key={measurementItem.label}>
                          <MeasureWidgetLabel>
                            {measurementItem.label}
                          </MeasureWidgetLabel>
                          <MeasureWidgetText>
                            {measurementItem.value}
                          </MeasureWidgetText>
                        </MeasureWidgetTextContainer>
                      );
                    })}
                  </MeasureWidgetTextWrapper>
                ) : null}
              </WidgetPanelContainer>
            </WidgetPanelWrapper>
          ) : null}
        </WidgetContainer>
      </WidgetWrapper>
    );
  }
}

export default Measure;
