import React from "react";
import {
  FeatureDetailsFormItem,
  FeatureDetailsItemInputHelpText,
  FeatureDetailsItemInputRequired
} from "../..";
import { InputGroup } from "../../../../core/components";
import PropTypes from "prop-types";

const InputWrapper = (props) => (
  <FeatureDetailsFormItem
    isHighlighted={props.isHighlighted}
    styleType={props.styleType}
  >
    <InputGroup flexDirection={props.flexDirection}>
      {props.children}
      {props.required && !props.isValid && (
        <FeatureDetailsItemInputRequired invalid={true}>
          {props.requiredLabel}
        </FeatureDetailsItemInputRequired>
      )}
      {props.helpText && (
        <FeatureDetailsItemInputHelpText
          id={`InputWrapper-${props.attribute}-help-text`}
          invalid={!props.isValid}
          aria-live="assertive"
        >
          {props.helpText}
        </FeatureDetailsItemInputHelpText>
      )}
    </InputGroup>
  </FeatureDetailsFormItem>
);

InputWrapper.propTypes = {
  helpText: PropTypes.string,
  required: PropTypes.bool,
  isValid: PropTypes.bool,
  requiredLabel: PropTypes.string,
  isHighlighted: PropTypes.bool,
  flexDirection: PropTypes.string,
  styleType: PropTypes.string
};

export default InputWrapper;
