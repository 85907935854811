import { connect } from "react-redux";
import {
  getWorkflowSelecting,
  getWorkflowLoading,
  getLoadablePlugins,
  getWorkflowState,
  getWorkflowCommand,
  getPluginsError,
  getWebMap,
  getMapView,
  getSelectedOrg,
  getSelectedProperty,
  getToken,
  getFirstName,
  getLastName,
  getAvailableWorkflows,
  getOrganisationPreferences,
  getDefaultSpatialReference,
  getWorkflowError,
  getFeatureListItems,
  getWorkflowSize,
  getPropertySpatialReference,
  getSelectedPropertyGroup,
  getDisableCache,
  getUserId,
  getBasemapId,
  getOrgRenderers,
  getLabel,
  getLayerName,
  getObjectIdField,
  getWorkflowPanelOpen,
  getHas3D,
  getRequestInfo,
  getSelectedWorkflow,
  getPdfResults,
  getPropertyUsers,
  getPropertyUsersLoading,
  getAvailablePropertyGroups,
  getPropertyGroupsData,
  getImageryDatesForProperty
} from "../selectors";
import * as actions from "../actions";
import { NotificationManager } from "react-notifications";
import Workflows from "./Workflows";

export default connect(
  (state) => ({
    loadingWorkflows: getWorkflowLoading(state),
    selectingWorkflow: getWorkflowSelecting(state),
    loadablePlugins: getLoadablePlugins(state),
    workflowState: getWorkflowState(state),
    workflowCommand: getWorkflowCommand(state),
    error: getPluginsError(state),
    webMap: getWebMap(state),
    mapView: getMapView(state),
    selectedOrganisation: getSelectedOrg(state),
    selectedProperty: getSelectedProperty(state),
    user: {
      token: getToken(state),
      firstName: getFirstName(state),
      lastName: getLastName(state),
      userId: getUserId(state)
    },
    NotificationManager,
    availableWorkflows: getAvailableWorkflows(state),
    preferences: getOrganisationPreferences(state),
    orgSpatialReference: getDefaultSpatialReference(state),
    propertySpatialReference: getPropertySpatialReference(state),
    workflowsError: getWorkflowError(state),
    featureListItems: getFeatureListItems(state),
    workflowSize: getWorkflowSize(state),
    selectedPropertyGroup: getSelectedPropertyGroup(state),
    shouldDisableCache: getDisableCache(state),
    basemapId: getBasemapId(state),
    renderers: getOrgRenderers(state),
    labels: getLabel(state),
    getLayerName: (layerName) => getLayerName(state, layerName),
    objectIdField: getObjectIdField(state),
    workflowPanelOpen: getWorkflowPanelOpen(state),
    has3D: getHas3D(state),
    requestInfo: getRequestInfo(state),
    selectedWorkflow: getSelectedWorkflow(state),
    pdfResults: getPdfResults(state),
    selectedPropertyUsersLoading: getPropertyUsersLoading(state),
    selectedPropertyUsers: getPropertyUsers(state),
    availablePropertyGroups: getAvailablePropertyGroups(state),
    getPropertyGroupsData: getPropertyGroupsData(state),
    getImageryDatesForProperty: (propId) =>
      getImageryDatesForProperty(state, propId)
  }),
  actions
)(Workflows);
