import { connect } from "react-redux";
import Errors from "./Errors";
import * as actions from "../actions";
import {
  getAvailableWorkflows,
  getAvailablePropertyGroups,
  getLabel,
  getError,
  getSelectedPropertyGroup,
  getAvailableProperties,
  getSelectedProperty,
  getAvailableOrgs,
  getSelectedOrg
} from "../selectors";

export default connect(
  (state) => ({
    labels: getLabel(state),
    error: getError(state),
    availableOrgs: getAvailableOrgs(state),
    availableProperties: getAvailableProperties(state),
    selectedProperty: getSelectedProperty(state),
    selectedPropertyGroup: getSelectedPropertyGroup(state),
    selectedOrg: getSelectedOrg(state),
    availableWorkflows: getAvailableWorkflows(state),
    availablePropertyGroups: getAvailablePropertyGroups(state)
  }),
  actions
)(Errors);
