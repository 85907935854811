import React, { Component } from "react";
import { IconSymbol, IconBackground } from "../";
import PropTypes from "prop-types";
import { defaultTheme } from "../../..";

/** Renders an icon at the specified size and colour according to props. */
class Icon extends Component {
  static propTypes = {
    /** The type of icon, for example 'crops' */
    type: PropTypes.string,
    /** The css color (rgb, hex, color name) for the background of the icon */
    bgColor: PropTypes.string,
    /** The shape around the icon, e.g. round */
    bgShape: PropTypes.string,
    /** The width, in pixels, of the background */
    bgWidth: PropTypes.string,
    /** The height, in pixels, of the background */
    bgHeight: PropTypes.string,
    /** The height, in pixels, of the icon */
    iconHeight: PropTypes.string,
    /** The width, in pixels, of the icon */
    iconWidth: PropTypes.string,
    /** The css color (rgb, hex, color name) for the icon. Can also be a function that returns a string */
    iconColor: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    /** If the icon should be spinning or not */
    spinning: PropTypes.bool,
    /** The path to the icon file, if not the standard. If the file does not exist, should default to a generic error icon*/
    path: PropTypes.string,
    /** the rotation number in degrees for the icon */
    rotation: PropTypes.number
  };
  static defaultProps = {
    bgShape: "round",
    bgColor: "none",
    spinning: false,
    rotation: 0,
    iconColor: defaultTheme.agBlack
  };
  render() {
    const {
      type,
      bgColor,
      bgShape,
      bgWidth,
      bgHeight,
      iconHeight,
      iconWidth,
      iconColor,
      spinning,
      path,
      rotation
    } = this.props;

    return (
      <IconBackground
        data-name={"IconBackground"}
        type={type}
        backgroundColor={bgColor}
        shape={bgShape}
        width={bgWidth ? bgWidth : iconWidth}
        height={bgHeight ? bgHeight : iconHeight}
      >
        <IconSymbol
          rotation={rotation !== undefined ? rotation : 0}
          data-name={"IconSymbol"}
          type={type}
          path={path}
          width={iconWidth}
          height={iconHeight}
          iconColor={iconColor}
          spinning={spinning}
        />
      </IconBackground>
    );
  }
}

export default Icon;
