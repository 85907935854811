import { connect } from "react-redux";
import SelectGeometrySearch from "./SelectGeometrySearch";
import { getMapView, getWebMap } from "../../../selectors";
import * as actions from "../../../actions";

export default connect(
  (state) => ({
    mapView: getMapView(state),
    webMap: getWebMap(state)
  }),
  actions
)(SelectGeometrySearch);
