import React from "react";
import {
  FeatureListEmphasisColourBarWrap,
  FeatureListEmphasisColourBar
} from "../..";
import PropTypes from "prop-types";

const FeatureListItemColorBar = (props) => {
  return (
    <FeatureListEmphasisColourBarWrap>
      {props.emphasis.map((item) => (
        <FeatureListEmphasisColourBar
          key={`colorbar-${item.emphasisIconType}-${item.emphasisIconColor}`}
          color={item.emphasisIconColor}
        />
      ))}
    </FeatureListEmphasisColourBarWrap>
  );
};

FeatureListItemColorBar.propTypes = {
  emphasis: PropTypes.arrayOf(
    PropTypes.shape({
      emphasisIconType: PropTypes.string,
      emphasisIconColor: PropTypes.string
    })
  )
};

export default FeatureListItemColorBar;
