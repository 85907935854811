export const getPropertyGroupsLoading = (state) =>
  state.propertyGroups.isLoading;
export const getAvailablePropertyGroups = (state) =>
  state.propertyGroups.available;
export const getPropertyGroupSelecting = (state) =>
  state.propertyGroups.isSelecting;
export const getSelectedPropertyGroup = (state) =>
  state.propertyGroups.selected;
export const getSelectedPropertyGroupId = (state) =>
  state.propertyGroups.selected.groupId;
export const getPropertyGroupsError = (state) => state.propertyGroups.error;
export const getHasLoadedPropertyGroups = (state) =>
  state.propertyGroups.finishedLoading;
export const getPropertyGroupsData = (state) => {
  if (
    !state.propertyGroups.available ||
    !state.propertyGroups.available.length
  ) {
    return {};
  }
  return state.propertyGroups.available.reduce((results, group) => {
    if (group.groupId === "MEMBERSHIP") return results;
    return group.data ? { ...results, ...group.data } : results;
  }, {});
};
