import React, { Component } from "react";
import {
  GraphLegendCircle,
  GraphLegendText,
  GraphLegendButton,
  GraphLegendWrapper,
  GraphLegendItem
} from "../../../layout";
import PropTypes from "prop-types";
import {
  LegendItemLabel,
  LegendStepColor,
  LegendStepContainer,
  LegendStepItem
} from "../../../../plugin/components";
import { Input } from "../..";

/** Legend component for graphs */
export default class Legend extends Component {
  static propTypes = {
    /** The data to be rendered in the legend */
    legendData: PropTypes.arrayOf(
      PropTypes.shape({
        x: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        name: PropTypes.string,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        color: PropTypes.string
      })
    ),
    /** Called on mouseover and focus of legend item */
    handleHoverItem: PropTypes.func,
    /** Returns a boolean of whether the legend item is hovered */
    isHoveredItem: PropTypes.func,
    /** Determines which type of legend to show */
    legendType: PropTypes.oneOf(["scale", "uniqueValue"]),
    /** If set to true, shows checkboxes for legend selection */
    showCheckboxes: PropTypes.bool,
    /** Called when a legend checkbox is checked or unchecked */
    onChangeSelectedLegend: PropTypes.func,
    /** Custom styling to pass onto component */
    styles: PropTypes.object
  };
  static defaultProps = {
    legendType: "uniqueValue",
    showCheckboxes: false
  };
  constructor(props) {
    super(props);
    this.state = {
      selectedLegendItems: []
    };
  }
  componentDidMount() {
    this.setInitialSelectedLegend();
  }
  componentDidUpdate(prevProps) {
    const prevLegendData = prevProps.legendData || [];
    const currentLegendData = this.props.legendData || [];
    if (
      JSON.stringify(prevLegendData.map(({ name }) => name)) !==
      JSON.stringify(currentLegendData.map(({ name }) => name))
    ) {
      this.setInitialSelectedLegend();
    }
  }
  setInitialSelectedLegend = () => {
    const { showCheckboxes, onChangeSelectedLegend } = this.props;
    if (!showCheckboxes) return;
    const legendData = this.getLegendData();
    this.setState({
      selectedLegendItems: legendData.map(({ name, value }) => name || value)
    });
    if (onChangeSelectedLegend) onChangeSelectedLegend(legendData);
  };
  getLegendData = () => {
    const { legendData } = this.props;
    return legendData ? legendData : [];
  };

  handleHoverLegendItem = (e) => {
    const { showCheckboxes, handleHoverItem } = this.props;
    if (showCheckboxes || !handleHoverItem) return;
    const name = e.currentTarget.dataset.name;
    if (!name) return;
    handleHoverItem(name);
  };
  handleUnhoverLegendItem = (e) => {
    const { showCheckboxes, handleHoverItem } = this.props;
    if (showCheckboxes || !handleHoverItem) return;
    handleHoverItem(null);
  };

  isHoveredLegendItem = (name) => {
    const { showCheckboxes, isHoveredItem } = this.props;
    if (showCheckboxes || !isHoveredItem) return false;
    return isHoveredItem(name);
  };

  isSelectedItem = (name) => {
    const { isSelectedItem } = this.props;
    if (!isSelectedItem) return false;
    return isSelectedItem(name);
  };

  handleSelectLegendItem = (e) => {
    const { showCheckboxes, handleSelectItem } = this.props;
    if (showCheckboxes || !handleSelectItem) return false;
    const name = e.currentTarget.dataset.name;
    if (!name) return;
    handleSelectItem(name);
  };

  handleUnselectLegendItem = () => {
    const { showCheckboxes, handleSelectItem } = this.props;
    if (showCheckboxes || !handleSelectItem) return false;
    handleSelectItem(null);
  };

  getUniqueValueLegendItemComponent = () => {
    const { showCheckboxes } = this.props;
    return showCheckboxes ? GraphLegendItem : GraphLegendButton;
  };

  getLegendCheckbox = (value) => {
    const { showCheckboxes } = this.props;
    if (!showCheckboxes) return null;
    return (
      <Input
        id={value}
        type={"checkbox"}
        name={value}
        value={value}
        checked={this.legendItemChecked(value)}
        onChange={this.onChangeSelectedLegend}
        aria-label={value}
      />
    );
  };

  uniqueValueLegend = () => {
    const { showCheckboxes } = this.props;
    return this.getLegendData().map((legendItem) => {
      const { name, x, value } = legendItem;
      const itemValue = name || x || value;
      const Component = this.getUniqueValueLegendItemComponent();
      return (
        <Component
          key={itemValue}
          tabIndex={0}
          onMouseEnter={this.handleHoverLegendItem}
          onMouseLeave={this.handleUnhoverLegendItem}
          onClick={this.handleSelectLegendItem}
          onBlur={this.handleUnselectLegendItem}
          data-name={legendItem.x}
          disabled={showCheckboxes}
        >
          {this.getLegendCheckbox(itemValue)}
          <GraphLegendCircle
            color={legendItem.color}
            hovered={this.isHoveredLegendItem(legendItem.x)}
            selected={this.isSelectedItem(legendItem.x)}
          />
          <GraphLegendText
            hovered={this.isHoveredLegendItem(legendItem.x)}
            selected={this.isSelectedItem(legendItem.x)}
          >
            {`${legendItem.value} ${legendItem.name ? legendItem.name : ""}`}
          </GraphLegendText>
        </Component>
      );
    });
  };

  onChangeSelectedLegend = (e) => {
    const { value } = e.target;
    const { selectedLegendItems } = this.state;
    const legendData = this.getLegendData();
    const { onChangeSelectedLegend } = this.props;
    const newSelectedItems = selectedLegendItems.includes(value)
      ? selectedLegendItems.filter((item) => item !== value)
      : [...selectedLegendItems, value];
    this.setState({
      selectedLegendItems: newSelectedItems
    });
    if (onChangeSelectedLegend)
      onChangeSelectedLegend(
        legendData.filter(
          (item) =>
            newSelectedItems.includes(item.name) ||
            newSelectedItems.includes(item.value)
        )
      );
  };

  legendItemChecked = (value) => {
    const { selectedLegendItems } = this.state;
    return selectedLegendItems.includes(value);
  };

  scaleLegend = () => {
    const legendItems = this.getLegendData();
    return (
      <LegendStepContainer compressed={true}>
        {legendItems.map(({ color, name, value }) => (
          <LegendStepItem key={color}>
            {this.getLegendCheckbox(name || value)}
            <LegendStepColor color={color} />
            {name ? <LegendItemLabel>{name}</LegendItemLabel> : null}
          </LegendStepItem>
        ))}
      </LegendStepContainer>
    );
  };

  getLegend = () => {
    const { legendType } = this.props;
    if (legendType === "uniqueValue") return this.uniqueValueLegend();
    return this.scaleLegend();
  };

  getStyles = () => {
    const { styles } = this.props;
    return styles ? styles : {};
  };
  render() {
    return (
      <GraphLegendWrapper styles={this.getStyles()}>
        {this.getLegend()}
      </GraphLegendWrapper>
    );
  }
}
