import React from "react";
import { ButtonLabel, PaginationPageNumber, Button } from "../..";
import { Icon } from "../../../";
import PropTypes from "prop-types";

const PaginationIconButton = (props) => {
  return (
    <PaginationPageNumber data-name={"PaginationPageNumber"}>
      <Button
        type="button"
        data-name={"Button"}
        styletype="pagination-first"
        disabled={props.disabled}
        onClick={props.onClick}
        title={props.label}
      >
        <Icon
          data-name={"Icon"}
          iconColor={props.color}
          type={props.type}
          bgHeight={"2em"}
          bgWidth={"2em"}
          iconHeight={"0.6em"}
          iconWidth={"0.6em"}
        />
        <ButtonLabel type="sr-only">{props.label}</ButtonLabel>
      </Button>
    </PaginationPageNumber>
  );
};

PaginationIconButton.propTypes = {
  /** The color of the button icon */
  color: PropTypes.string,
  /** The label the screen reader uses for the button */
  label: PropTypes.string,
  /** Whether the button is disabled */
  disabled: PropTypes.bool,
  /** Called on click of the button */
  onClick: PropTypes.func
};

export default PaginationIconButton;
