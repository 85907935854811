import React from "react";
import {
  InputContainer,
  Label,
  Textarea
} from "../../../../../core/components";
import RequiredSymbol from "../RequiredSymbol";
import PropTypes from "prop-types";

const TextAreaInput = (props) => {
  function onChange(e) {
    const { handleUpdateValue, attribute } = props;
    handleUpdateValue(attribute, e.target.value);
  }
  return (
    <React.Fragment>
      <Label
        htmlFor={`${props.attribute}-input`}
        type={!props.isValid ? "formError" : ""}
        styletype="form-label"
      >
        {props.label}
      </Label>
      <InputContainer>
        <Textarea
          maxLength={props.maxlength}
          minLength={props.minLength}
          id={`${props.attribute}-input`}
          value={props.value}
          placeholder={props.placeholder}
          onChange={onChange}
          invalid={!props.isValid}
          disabled={props.disabled}
          required={props.required}
          aria-invalid={!props.isValid}
          aria-describedby={
            props.helpText ? `InputWrapper-${props.attribute}-help-text` : null
          }
        />
        {!props.isValid && props.required && <RequiredSymbol />}
      </InputContainer>
    </React.Fragment>
  );
};

TextAreaInput.propTypes = {
  attribute: PropTypes.string,
  isValid: PropTypes.bool,
  label: PropTypes.string,
  maxlength: PropTypes.number,
  minlength: PropTypes.number,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  handleUpdateValue: PropTypes.func,
  required: PropTypes.bool
};

export default TextAreaInput;
