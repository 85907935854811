import React, { Component } from "react";
import {
  Tabs,
  Tab,
  TabContentContainer,
  TabsWrapper
} from "../../../core/components";
import PropTypes from "prop-types";

class TabsComponent extends Component {
  static propTypes = {
    tabValues: PropTypes.array,
    activeTab: PropTypes.string,
    onClickTab: PropTypes.func,
    content: PropTypes.node
  };

  handleClick = (e) => {
    const { onClickTab } = this.props;
    const value = e.target.value;
    onClickTab(value);
  };

  renderTabs = () => {
    const { activeTab, tabValues } = this.props;
    return tabValues.map((item) => {
      return (
        <Tab
          onClick={this.handleClick}
          active={item == activeTab ? true : false}
          value={item}
          key={item}
        >
          {item}
        </Tab>
      );
    });
  };

  render() {
    const { content } = this.props;
    return (
      <TabsWrapper>
        <Tabs>{this.renderTabs()}</Tabs>
        <TabContentContainer>{content}</TabContentContainer>
      </TabsWrapper>
    );
  }
}

export default TabsComponent;
