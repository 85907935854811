import { handleActions } from "redux-actions";
import {
  LOAD_USER,
  LOADED_USER,
  ERROR_USER,
  CLEAR_ERRORS,
  LOAD_INVITATIONS,
  LOADED_INVITATIONS,
  ACCEPT_INVITATION,
  ACCEPTED_INVITATION,
  LOAD_INVITATION,
  LOADED_INVITATION,
  ACCEPT_INVITATION_PUBLIC,
  UPDATE_USER_PREFERENCES,
  UPDATED_USER_PREFERENCES,
  SET_GENERIC_ERROR
} from "../constants";

export const initialState = {
  loadedUser: false,
  userId: null,
  emailAddress: null,
  firstName: null,
  lastName: null,
  organisations: null,
  error: false,
  invitations: [],
  isLoading: false,
  isAccepting: false,
  invitation: null,
  preferences: null,
  isUpdating: false
};

// @ts-ignore
export default handleActions(
  {
    [LOAD_USER]: (state, { payload }) => ({
      ...state,
      userId: payload.userId,
      error: false
    }),
    [LOADED_USER]: (state, { payload }) => ({
      ...state,
      loadedUser: true,
      userId: payload.userId,
      emailAddress: payload.emailAddress,
      firstName: payload.firstName,
      lastName: payload.lastName,
      organisations: payload.organisations,
      preferences: payload.preferences,
      error: false
    }),
    [ERROR_USER]: (state, { payload }) => ({
      ...state,
      loadedUser: false,
      userId: null,
      emailAddress: null,
      firstName: null,
      lastName: null,
      organisations: null,
      error: payload.message,
      isAccepting: false,
      isLoading: false,
      invitation: null
    }),
    [CLEAR_ERRORS]: (state) => ({
      ...state,
      error: false
    }),
    [LOAD_INVITATIONS]: (state) => ({
      ...state,
      isLoading: true
    }),
    [LOADED_INVITATIONS]: (state, { payload }) => ({
      ...state,
      isLoading: false,
      invitations: payload.items
    }),
    [ACCEPT_INVITATION]: (state) => ({
      ...state,
      isAccepting: true
    }),
    [ACCEPTED_INVITATION]: (state, { payload }) => ({
      ...state,
      isAccepting: false,
      invitations: state.invitations.filter(
        (invitation) => invitation.invitedId !== payload.invitedId
      )
    }),
    [LOAD_INVITATION]: (state, { payload }) => ({
      ...state,
      isLoading: true
    }),
    [LOADED_INVITATION]: (state, { payload }) => ({
      ...state,
      isLoading: false,
      invitation: payload
    }),
    [ACCEPT_INVITATION_PUBLIC]: (state) => ({
      ...state,
      isAccepting: true
    }),
    [UPDATE_USER_PREFERENCES]: (state) => ({
      ...state,
      isUpdating: true
    }),
    [UPDATED_USER_PREFERENCES]: (state, { payload }) => ({
      ...state,
      isUpdating: false,
      preferences: payload.preferences
    }),
    [SET_GENERIC_ERROR]: (state) => ({
      ...state,
      isUpdating: false
    })
  },
  initialState
);
