import React from "react";
import { Button, ButtonLabel } from "../..";
import { defaultTheme } from "../../../..";
import { ChangePropertyContainer } from "../../../layout";
import Icon from "../../Icon/Icon";
import PropTypes from "prop-types";

const SidebarHomeButton = ({ homeButton, dropdown }) => {
  return (
    <ChangePropertyContainer>
      {homeButton && (
        <Button
          data-name={"HomeButton"}
          styletype={"homeLink"}
          type="button"
          title={homeButton.title}
          onClick={homeButton.onClick}
        >
          <Icon
            data-name={"Icon"}
            iconColor={defaultTheme.agWhite}
            type={"home"}
            iconHeight={"1.5em"}
            iconWidth={"1.5em"}
          />
          <ButtonLabel data-name={"ButtonLabel"} type={"sideBar"}>
            {homeButton.label}
          </ButtonLabel>
        </Button>
      )}
      {dropdown && (
        <Button
          data-name={"DropdownButton"}
          styletype="iconButton"
          title={dropdown.label}
          onClick={dropdown.onClick}
          disabled={dropdown.disabled}
          type="button"
        >
          <Icon
            data-name={"Icon"}
            iconColor={defaultTheme.agWhite}
            type={"drop-down-menu"}
            iconHeight={"1.1em"}
            iconWidth={"1.1em"}
          />
        </Button>
      )}
    </ChangePropertyContainer>
  );
};

SidebarHomeButton.propTypes = {
  /** Main button that displays the property/group title */
  button: PropTypes.shape({
    /** Accessible title that describes exactly what the button does e.g. 'Zoom to property boundary' */
    title: PropTypes.string,
    /** Called on click of button */
    onClick: PropTypes.func,
    /** The display label in the button, usually the selected property or group name */
    label: PropTypes.string
  }),
  /** The dropdown control for the property selector. If set, this will display a button to control the dropdown menu */
  dropdown: PropTypes.shape({
    /** An accessible title for the button, e.g. 'Open property selector' */
    label: PropTypes.string,
    /** Called on click of dropdown button */
    onClick: PropTypes.func,
    /** Whether the button is disabled */
    disabled: PropTypes.bool
  })
};

export default SidebarHomeButton;
