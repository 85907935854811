import { handleActions } from "redux-actions";
import {
  LOAD_PROPERTY_GROUPS,
  LOADED_PROPERTY_GROUPS,
  ERROR_PROPERTY_GROUPS,
  LOAD_PROPERTY_GROUP,
  LOADED_PROPERTY_GROUP,
  ERROR_PROPERTY_GROUP,
  UNLOAD_PROPERTY_GROUP,
  LOADED_PROPERTY,
  SELECT_PROPERTY_GROUP,
  CLEAR_ERRORS,
  SET_PROPERTY_GROUP_DATA
} from "../constants";

export const defaultState = {
  isLoading: false,
  search: false,
  available: [],
  isSelecting: false,
  selected: false,
  error: false
};

// @ts-ignore
export default handleActions(
  {
    [LOAD_PROPERTY_GROUPS]: (state, { payload }) => ({
      ...state,
      isLoading: true,
      available: [],
      finishedLoading: false
    }),
    [LOADED_PROPERTY_GROUPS]: (state, { payload }) => ({
      ...state,
      finishedLoading: true,
      isLoading: false,
      available: payload.properties
    }),
    [ERROR_PROPERTY_GROUPS]: (state, { payload }) => ({
      ...state,
      isLoading: false,
      available: [],
      error: payload,
      isSelecting: false
    }),
    [LOAD_PROPERTY_GROUP]: (state, { payload }) => ({
      ...state,
      isSelecting: true,
      selected: false
    }),
    [LOADED_PROPERTY_GROUP]: (state, { payload }) => ({
      ...state,
      isSelecting: false,
      selected: payload
    }),
    [ERROR_PROPERTY_GROUP]: (state, { payload }) => ({
      ...state,
      isSelecting: false,
      selected: false,
      error: payload
    }),
    [UNLOAD_PROPERTY_GROUP]: (state, { payload }) => ({
      ...state,
      isSelecting: false,
      selected: false
    }),
    [LOADED_PROPERTY]: (state, { payload }) => ({
      ...state,
      isSelecting: false,
      selected: false
    }),
    [SELECT_PROPERTY_GROUP]: (state, { payload }) => ({
      ...state,
      isSelecting: true
    }),
    [CLEAR_ERRORS]: (state) => ({
      ...state,
      error: false
    }),
    [SET_PROPERTY_GROUP_DATA]: (state, { payload }) => {
      const { groupId, data } = payload;
      const updatedPropertyGroupData = state.available.map((group) =>
        group.groupId === groupId ? { ...group, data } : group
      );
      return {
        ...state,
        finishedLoading: true,
        isLoading: false,
        available: updatedPropertyGroupData
      };
    }
  },
  defaultState
);
