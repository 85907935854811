import { handleActions } from "redux-actions";
import {
  LOAD_ORGANISATIONS,
  LOADED_ORGANISATIONS,
  ERROR_ORGANISATIONS,
  LOAD_ORGANISATION,
  LOADED_ORGANISATION,
  ERROR_ORGANISATION,
  LOAD_ORGANISATION_CUSTOM_LAYERS,
  LOADED_ORGANISATION_CUSTOM_LAYERS,
  ERROR_ORGANISATION_CUSTOM_LAYERS,
  SELECT_ORGANISATION,
  CLEAR_ERRORS,
  UPDATE_ORG_RENDERERS,
  UPDATE_LAYER_LABELS,
  ERROR_PROPERTIES,
  ACCEPTED_INVITATION
} from "../constants";

export const defaultState = {
  isLoading: true,
  available: [],
  isSelecting: false,
  selected: false,
  roles: [],
  customLayers: [],
  error: false,
  spatialReference: null,
  renderers: {},
  layerLabels: {}
};

// @ts-ignore
export default handleActions(
  {
    [LOAD_ORGANISATIONS]: (state, { payload }) => ({
      ...state,
      isLoading: true,
      available: []
    }),
    [SELECT_ORGANISATION]: (state, { payload }) => ({
      ...state,
      isLoading: true,
      isSelecting: true
    }),
    [LOADED_ORGANISATIONS]: (state, { payload }) => ({
      ...state,
      isLoading: false,
      available: payload
    }),
    [ERROR_ORGANISATIONS]: (state, { payload }) => ({
      ...state,
      isLoading: false,
      available: [],
      error: payload
    }),

    [LOAD_ORGANISATION]: (state, { payload }) => ({
      ...state,
      isLoading: true,
      isSelecting: true,
      selected: false,
      roles: []
    }),
    [LOADED_ORGANISATION]: (state, { payload }) => ({
      ...state,
      error: false,
      isLoading: false,
      isSelecting: false,
      selected: payload,
      spatialReference: payload.preferences.defaultSpatialReference
        ? payload.preferences.defaultSpatialReference
        : null
    }),
    [ERROR_ORGANISATION]: (state, { payload }) => ({
      ...state,
      isLoading: false,
      isSelecting: false,
      selected: false,
      error: payload,
      renderers: {},
      layerLabels: {}
    }),
    [ERROR_PROPERTIES]: (state) => ({
      ...state,
      error: false
    }),
    [LOAD_ORGANISATION_CUSTOM_LAYERS]: (state) => ({
      ...state,
      customLayers: []
    }),
    [LOADED_ORGANISATION_CUSTOM_LAYERS]: (state, { payload }) => ({
      ...state,
      customLayers: payload.layers
    }),
    [ERROR_ORGANISATION_CUSTOM_LAYERS]: (state) => ({
      ...state,
      customLayers: []
    }),
    [CLEAR_ERRORS]: (state) => ({
      ...state,
      error: false
    }),
    [UPDATE_ORG_RENDERERS]: (state, { payload }) => ({
      ...state,
      renderers: payload
    }),
    [UPDATE_LAYER_LABELS]: (state, { payload }) => ({
      ...state,
      layerLabels: payload
    }),
    [ACCEPTED_INVITATION]: (state, { payload }) => ({
      ...state,
      available: [...state.available, { orgId: payload.orgId }]
    })
  },
  defaultState
);
