import React from "react";
import { TableSelectWrap, TableCellContentWrap } from "../../..";
import DeleteCellValueButton from "../Buttons/DeleteCellValueButton";
import PropTypes from "prop-types";
import DropDown from "../../../../../core/components/FormComponents/DropDown";

const SelectCell = (props) => {
  return (
    <TableSelectWrap>
      <TableCellContentWrap>
        <DropDown
          id={props.id}
          value={props.cellValue}
          aria-labelledby={props.headerId}
          required={props.required}
          invalid={props.invalid}
          aria-invalid={props.invalid}
          aria-describedby={props.describedBy}
          onChange={(e) => props.handleOnChangeValue(props, e.target.value)}
          options={props.options}
        />
        {props.showDeleteButton && <DeleteCellValueButton {...props} />}
      </TableCellContentWrap>
    </TableSelectWrap>
  );
};

SelectCell.propTypes = {
  /** The id that is on the corresponding header to the cell */
  headerId: PropTypes.string,
  /** If the field is required */
  required: PropTypes.bool,
  /** An id that refers to a node that has a description for the field. Is important for a11y */
  describedBy: PropTypes.string,
  /** Whether to show the delete value button */
  showDeleteButton: PropTypes.bool,
  /** The id to put on the input */
  id: PropTypes.string,
  /** If the value is invalid */
  invalid: PropTypes.bool,
  /** Is called on change of value */
  handleOnChangeValue: PropTypes.func,
  /** Renders one option node for each item in this array */
  options: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.bool,
        PropTypes.number
      ]),
      disabled: PropTypes.bool
    })
  )
};

export default SelectCell;
