import React, { Component } from "react";
import { AttributeTextContainer } from "../../layout";

class AttributionText extends Component {
  render() {
    const { attributionText, workflowClosed } = this.props;
    return (
      <AttributeTextContainer
        data-name={"AttributeTextContainer"}
        workflowClosed={workflowClosed}
      >
        <p>{attributionText}</p>
      </AttributeTextContainer>
    );
  }
}

export default AttributionText;
