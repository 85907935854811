import { connect } from "react-redux";
import ElevationToggle from "./ElevationToggle";
import * as actions from "../../actions";
import { getLabel, getShowElevation } from "../../selectors";

export default connect(
  (state) => ({
    labels: getLabel(state),
    showElevation: getShowElevation(state)
  }),
  actions
)(ElevationToggle);
