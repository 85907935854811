import { handleActions } from "redux-actions";
import {
  START_LOADING,
  FINISH_LOADING,
  LOADED_MAP,
  LOAD_WEB_MAP,
  LOADED_WEB_MAP,
  ERROR_LOADING_WEB_MAP,
  OPEN_SIDEBAR,
  CLOSE_SIDEBAR,
  OPEN_PROPERTY_SELECTOR_DROP_DOWN,
  CLOSE_PROPERTY_SELECTOR_DROP_DOWN,
  OPEN_PROFILE_DROP_DOWN,
  CLOSE_PROFILE_DROP_DOWN,
  OPEN_WIDGET,
  CLOSE_WIDGET,
  APP_BUSY,
  LOADING_MAP_VIEW,
  SELECT_PROPERTY,
  LOAD_WORKFLOW,
  LOADING_WEB_MAP,
  ABORT_SIGNAL_LOADED,
  UNLOAD_PROPERTY,
  SET_LOADING_TEXT,
  UNLOAD_PROPERTY_GROUP,
  DISABLE_CACHE,
  SET_BASEMAP_ID,
  SET_ATTRIBUTION,
  DEFAULT_BASEMAP,
  SET_REQUEST_ERROR,
  REQUEST_STATUS_ERROR,
  COMPLETED_REQUEST,
  REQUEST_STATUS_COMPLETED,
  START_REQUEST,
  REQUEST_STATUS_LOADING,
  SET_DOC_TITLE,
  SET_GENERIC_ERROR,
  CLEAR_ERRORS
} from "../constants";

export const defaultState = {
  isLoading: false,
  webMapId: "",
  mapView: false,
  webMapLoading: true,
  webMap: false,
  webMapError: false,
  sidebarOpen: false,
  propertyDropDownOpen: false,
  profileDropDownOpen: false,
  activeWidget: null,
  busy: false,
  abortController: null,
  loadingText: "",
  disableCache: false,
  basemapId: "None",
  attribution: DEFAULT_BASEMAP[0].attribution,
  requestInfo: {},
  docTitle: "",
  error: null
};

// @ts-ignore
export default handleActions(
  {
    [START_LOADING]: (state, { payload }) => ({
      ...state,
      isLoading: true
    }),
    [FINISH_LOADING]: (state, { payload }) => ({
      ...state,
      isLoading: false
    }),
    [LOADING_MAP_VIEW]: (state, { payload }) => ({
      ...state,
      mapViewLoading: payload
    }),
    [LOADING_WEB_MAP]: (state, { payload }) => ({
      ...state,
      webMapLoading: payload
    }),
    [LOADED_MAP]: (state, { payload }) => ({
      ...state,
      mapView: payload
    }),
    [LOAD_WEB_MAP]: (state, { payload }) => ({
      ...state,
      webMapId: payload,
      webMapLoading: true,
      webMapError: false
    }),
    [LOADED_WEB_MAP]: (state, { payload }) => ({
      ...state,
      webMap: payload,
      webMapLoading: false
    }),
    [ERROR_LOADING_WEB_MAP]: (state, { payload }) => ({
      ...state,
      webMapError: payload,
      webMapLoading: false
    }),
    [OPEN_SIDEBAR]: (state, { payload }) => ({
      ...state,
      sidebarOpen: true
    }),
    [CLOSE_SIDEBAR]: (state, { payload }) => ({
      ...state,
      sidebarOpen: false,
      propertyDropDownOpen: false,
      profileDropDownOpen: false
    }),
    [OPEN_PROPERTY_SELECTOR_DROP_DOWN]: (state, { payload }) => ({
      ...state,
      propertyDropDownOpen: true
    }),
    [CLOSE_PROPERTY_SELECTOR_DROP_DOWN]: (state, { payload }) => ({
      ...state,
      propertyDropDownOpen: false
    }),
    [OPEN_PROFILE_DROP_DOWN]: (state, { payload }) => ({
      ...state,
      profileDropDownOpen: true
    }),
    [CLOSE_PROFILE_DROP_DOWN]: (state, { payload }) => ({
      ...state,
      profileDropDownOpen: false
    }),
    [OPEN_WIDGET]: (state, { payload }) => ({
      ...state,
      activeWidget: payload.widget
    }),
    [CLOSE_WIDGET]: (state) => ({
      ...state,
      activeWidget: null
    }),
    [APP_BUSY]: (state, { payload }) => ({
      ...state,
      busy: payload
    }),
    [SELECT_PROPERTY]: (state) => ({
      ...state,
      sidebarOpen: false,
      propertyDropDownOpen: false,
      profileDropDownOpen: false
    }),
    [LOAD_WORKFLOW]: (state) => ({
      ...state,
      sidebarOpen: false,
      propertyDropDownOpen: false,
      profileDropDownOpen: false
    }),
    [ABORT_SIGNAL_LOADED]: (state, { payload }) => ({
      ...state,
      abortController: payload.controller
    }),
    [UNLOAD_PROPERTY]: (state, { payload }) => ({
      ...state,
      sidebarOpen: false,
      propertyDropDownOpen: false,
      profileDropDownOpen: false
    }),
    [UNLOAD_PROPERTY_GROUP]: (state, { payload }) => ({
      ...state,
      sidebarOpen: false,
      propertyDropDownOpen: false,
      profileDropDownOpen: false
    }),
    [SET_LOADING_TEXT]: (state, { payload }) => ({
      ...state,
      loadingText: payload.loadingText
    }),
    [DISABLE_CACHE]: (state, { payload }) => ({
      ...state,
      disableCache: payload.shouldDisableCache
    }),
    [SET_BASEMAP_ID]: (state, { payload }) => ({
      ...state,
      basemapId: payload
    }),

    [SET_ATTRIBUTION]: (state, { payload }) => ({
      ...state,
      attribution: payload
    }),
    [START_REQUEST]: (state, { payload }) => ({
      ...state,
      requestInfo: {
        ...state.requestInfo,
        [payload.requestName]: {
          status: REQUEST_STATUS_LOADING,
          errorMessage: null,
          results: null
        }
      }
    }),
    [SET_REQUEST_ERROR]: (state, { payload }) => ({
      ...state,
      requestInfo: {
        ...state.requestInfo,
        [payload.requestName]: {
          status: REQUEST_STATUS_ERROR,
          errorMessage: payload.error,
          results: null
        }
      }
    }),
    [COMPLETED_REQUEST]: (state, { payload }) => ({
      ...state,
      requestInfo: {
        ...state.requestInfo,
        [payload.requestName]: {
          status: REQUEST_STATUS_COMPLETED,
          errorMessage: null,
          results: payload.results
        }
      }
    }),
    [SET_DOC_TITLE]: (state, { payload }) => ({
      ...state,
      docTitle: payload.docTitle
    }),
    [SET_GENERIC_ERROR]: (state, { payload }) => ({
      ...state,
      error: payload
    }),
    [CLEAR_ERRORS]: (state) => ({
      ...state,
      error: null
    })
  },
  defaultState
);
